import accountServiceCommon from "./account-service-common.yaml";
import commandCommon from "./command-common.yaml";
import filtersCommon from "./filters-common.yaml";
import ftcpCommon from "./ftcp-common.yaml";
import iam2Common from "./iam2-common.yaml";
import iamCommon from "./iam-common.yaml";
import tmcAuditsCommon from "./tmc-audits-common.yaml";
import rawMessageCommon from "./raw-message-common.yaml";

export default {
  ...accountServiceCommon,
  ...commandCommon,
  ...filtersCommon,
  ...ftcpCommon,
  ...iam2Common,
  ...iamCommon,
  ...tmcAuditsCommon,
  ...rawMessageCommon
};
