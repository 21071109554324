import { connect } from 'react-redux';

import UsernameEntityLink from '../components/UsernameEntityLink';
import { defaultMapDispatchToProps } from '../utils/ConnectDefaults';


export default connect(
  null,
  defaultMapDispatchToProps
)(UsernameEntityLink);
