import { fromJS } from 'immutable';
import { entitiesToMap } from '@au/core/lib/utils/immutableHelpers';

export default {
  LIST_ENTITIES_SUCCESS: (state, action) => {
    const { data, pkField, path, replace } = action;

    if (Array.isArray(data)) {
      const entities = entitiesToMap(data, pkField);

      // In some instances we want to swap (`replace`) the full collection
      if (state.getIn(path) === undefined || replace) {
        return state.setIn(path, entities);
      }
      else {
        return state.mergeIn(path, entities);
      }
    }

    /* eslint-disable no-console */
    console.warn(
      'LIST_ENTITIES_SUCCESS expected `data` to be an Array, received "%s"',
      typeof data
    );
    /* eslint-enable no-console */

    return state;
  },
  GET_ENTITY_SUCCESS: (state, action) => {
    const { data: entity, path } = action;

    return state.setIn(path, fromJS(entity));
  },
  CREATE_ENTITY_SUCCESS: (state, action) => {
    const { data: entity, path } = action;

    return state.setIn(path, fromJS(entity));
  },
  UPDATE_ENTITY_SUCCESS: (state, action) => {
    const { data: entity, replace, path } = action;

    if (replace) {
      return state.setIn(path, fromJS(entity));
    }
    return state.mergeIn(path, fromJS(entity));
  },
  DELETE_ENTITY_SUCCESS: (state, action) => {
    return state.deleteIn(action.path);
  },
  CLEAR_ENTITIES: (state, action) => {
    if (Array.isArray(action.services)) {
      action.services.forEach(service => {
        if (state.hasIn(['services', service])) {
          state = state.setIn(['services', service, 'entities'], fromJS({}));
        } else {
          /* eslint-disable no-console */
          console.warn(
            'CLEAR_ENTITIES expected "%s" to be defined in serviceDefs. Entry will be ignored.',
            service
          );
          /* eslint-enable no-console */
        }
      });
    } else {
      /* eslint-disable no-console */
      console.warn(
        'CLEAR_ENTITIES expected `services` to be an Array, received "%s"',
        typeof action.services
      );
      /* eslint-enable no-console */
    }
    return state;
  }
};
