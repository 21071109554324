import React from "react";
import * as T from 'prop-types';

import { SelectableListProps } from "../../AuPropTypes";
import { deviceType } from '../../constants';
import FilterSelectableList from "./FilterSelectableList";

const deviceTypes = Object.values(deviceType).map(type => ({displayId: `au.devices.types.${type}`, value: type}));

export default class DeviceType extends React.Component {
  static propTypes = {
    defaultValue: SelectableListProps.defaultValue,
    selection: SelectableListProps.selection,
    sort: SelectableListProps.sort,
    multiSelect: T.bool,
  };

  static defaultProps = {
    selection: []
  };

  render() {

    return (<FilterSelectableList
      {...this.props}
      items={deviceTypes}
      selection={this.props.selection}
      onChange={this.props.onChange}
      onInit={this.props.onInit}
    />);
  }
}
