import { get } from '../../../utils/api';

import DefaultList from '../List';

export default class PermissionsList extends DefaultList {

    list() {
        return this.fetchHistory();
    }

    fetchHistory = this.fetchHistory.bind(this)
    async fetchHistory() {

        return get(`v1alpha/iam/permissions:inspect?objectAui=${this.props.parentEntity.entityDef.arn}/${this.props.parentEntity.entityId}`)
        .then(resp => resp, (err) => {
            if (err.data.code === 404) {
                //404s will be handled as a "No data response"
                return err;
            }
            else {
                this.setState({ errorStatusCode: err.data.code, errorStatusMessage: err.data.message });
                throw err;
            }
        });
    }
}