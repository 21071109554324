import React, { createRef } from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import AuInput from '@au/core/lib/components/elements/AuInput';
import AuToggle, { TOGGLE_SIZE_MEDIUM, TOGGLE_TYPE_PRIMARY } from '@au/core/lib/components/elements/AuToggle';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { NOOP, policyType, statementEffect } from '../constants';
import EntitlementEditor from './EntitlementEditor';
import { formatMessage } from '../utils/reactIntl';
import { ruleRunner, run } from '../utils/validationRuleRunner';
import { required, minLength, noListDuplicates } from '../utils/validationRules';

import styles from '../css/components/policy_statement_editor.module.scss';
import fieldset from '../css/fieldset.module.scss';

const validationRules = Object.freeze({
  [policyType.SUBJECT]: [
    ruleRunner('resourceAui', 'au.policies.resourceAui', required),
    ruleRunner('entitlements', 'au.policies.entitlements', minLength(1, 'au.validation.areRequired'), noListDuplicates(''))
  ],
  [policyType.RESOURCE]: [
    ruleRunner('subjectAui', 'au.policies.subjectAui', required),
    ruleRunner('entitlements', 'au.policies.entitlements', minLength(1, 'au.validation.areRequired'), noListDuplicates(''))
  ]
});

class Statement extends React.Component {

  static propTypes = {
    createMode: T.bool,
    policyType: T.string.isRequired,
    data: T.shape({
      resourceAui: T.string,
      subjectAui: T.string,
      description: T.string,
      entitlements: T.arrayOf(T.string)
    }).isRequired,
    showErrors: T.bool,
    errors: T.object,
    single: T.bool,
    onChange: T.func,
    onDelete: T.func,
    statementRef: T.oneOfType([T.func, T.shape({ current: T.instanceOf(Element) })])
  }

  static defaultProps = {
    createMode: false,
    onChange: NOOP,
    onDelete: NOOP
  }

  state = {
    subjectAui: '',
    resourceAui: '',
    description: '',
    entitlements: [],
    showErrors: {},
    single: true,
    ...this.props.data,
  }

  onEntitlementsChange = this.onEntitlementsChange.bind(this);
  onEntitlementsChange(entitlements) {
    this.onFieldChange('entitlements', entitlements);
  }

  onFieldChange = this.onFieldChange.bind(this);
  onFieldChange(field, value) {
    const { policyType: type } = this.props;

    this.setState(prevState => {
      const data = { ...prevState, [field]: value };
      return { ...data, validationErrors: run(data, validationRules[type]) };
    }, this.commitChanges);
  }

  onDelete = this.onDelete.bind(this);
  onDelete() {
    if (this.state.isNew) {
      this.props.onDelete();
    } else {
      this.setState({ deleted: true }, this.commitChanges);
    }
  }

  onUndo = this.onUndo.bind(this);
  onUndo() {
    this.setState({ deleted: undefined }, this.commitChanges);
  }

  commitChanges() {
    this.props.onChange(this.state);
  }

  showError = this.showError.bind(this);
  showError(field) {
    this.setState(prevState => ({
      showErrors: { ...prevState.showErrors, [field]: true }
    }));
  }

  render() {
    const { createMode, policyType: type, single, errors, showErrors, statementRef } = this.props;
    const { effect, description, entitlements, deleted, condition } = this.state;
    const checked = effect !== statementEffect.DENY;
    const auiAttrName = type === policyType.SUBJECT ? 'resourceAui' : 'subjectAui';
    const allowDelete = this.state[auiAttrName] || description || entitlements.length || !single;
    const v1Permission = Boolean(this.state._permissionId);

    const auiError = errors && errors[auiAttrName];
    const entitlementsError = errors && errors.entitlements;

    return (
      <div className={styles.statement} ref={statementRef}>
        { deleted &&
          <div className={styles.deleted}>
            <div className={cn(styles.deleted_aui, { [styles.v1_icon]: v1Permission })}>
              { formatMessage({ id: `au.policies.${auiAttrName}` }) }
              { " : " }
              { this.state[auiAttrName] }
            </div>
            <div>
              <AutoIntl
                className={styles.deleted_msg}
                displayId="au.statementEditor.markedForDeletion"
              />
              <AutoIntl
                className={styles.undo}
                onClick={this.onUndo}
                displayId="au.statementEditor.undo"
                tag="a"
              />
            </div>
          </div>
        }
        { !deleted &&
          <div className={cn(fieldset.table, styles.fields)}>
            { v1Permission &&
              <AutoIntl className={styles.v1_warning} displayId="au.policies.v1Permission.warning" />
            }
            <div className={fieldset.panel}>
              <div className={fieldset.row}>
                <AutoIntl displayId={`au.policies.${auiAttrName}`} className={fieldset.label} />
                <div className={fieldset.control}>
                  <AuInput
                    key={`input_${auiAttrName}`}
                    type="text"
                    name={auiAttrName}
                    value={this.state[auiAttrName]}
                    createMode={createMode}
                    disabled={!createMode || v1Permission}
                    onChange={e => this.onFieldChange(e.target.name, e.target.value)}
                    onBlur={() => this.showError(auiAttrName)}
                    showError={(showErrors || auiAttrName in this.state.showErrors) && Boolean(auiError)}
                    error={auiError}
                  />
                </div>
              </div>
              <div className={fieldset.row}>
                <div className={cn(fieldset.label, fieldset.label_with_hint)}>
                  <AutoIntl displayId="au.policies.description" />
                  <AutoIntl displayId={'au.statementEditor.optional'} className={fieldset.hint}/>
                </div>
                <div className={fieldset.control}>
                  <AuInput
                    type="textarea"
                    name="description"
                    value={description}
                    className={styles.input}
                    createMode={createMode}
                    disabled={v1Permission}
                    onChange={e => this.onFieldChange(e.target.name, e.target.value)}
                  />
                </div>
              </div>
              <div className={fieldset.row}>
                <div className={cn(fieldset.label, fieldset.label_with_hint)}>
                  <AutoIntl displayId="au.policies.condition" />
                  <AutoIntl displayId={'au.statementEditor.optional'} className={fieldset.hint}/>
                </div>
                <div className={fieldset.control}>
                  <AuInput
                    type="textarea"
                    name="condition"
                    value={condition}
                    className={styles.input}
                    createMode={createMode}
                    disabled={v1Permission}
                    onChange={e => this.onFieldChange(e.target.name, e.target.value)}
                  />
                </div>
              </div>
              <div className={fieldset.row}>
                <AutoIntl
                  ref={ref => this.labelRef = ref}
                  displayId="au.policies.entitlements"
                  className={fieldset.label}
                />
                <div className={fieldset.control}>
                  <EntitlementEditor
                    createMode={createMode}
                    subjectAui={this.state.subjectAui}
                    resourceAui={this.state.resourceAui}
                    entitlements={entitlements}
                    disabled={v1Permission}
                    onChange={this.onEntitlementsChange}
                    error={entitlementsError}
                    showError={showErrors}
                  />
                </div>
              </div>
              { !v1Permission &&
                <div className={fieldset.row}>
                  <AutoIntl
                    displayId="au.policies.effect"
                    className={fieldset.label}
                  />
                  <div className={fieldset.control}>
                    <AuToggle
                      type={TOGGLE_TYPE_PRIMARY}
                      size={TOGGLE_SIZE_MEDIUM}
                      name="effect"
                      value={checked ? statementEffect.ALLOW : statementEffect.DENY}
                      checked={checked}
                      disabled={!createMode}
                      showLabels={true}
                      minimalistic={true}
                      className={styles.effect}
                      onDisplayId="au.policies.effects.ALLOW"
                      offDisplayId="au.policies.effects.DENY"
                      onChange={e => this.onFieldChange(
                        e.target.name,
                        e.target.checked ? statementEffect.ALLOW : statementEffect.DENY
                      )}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        }
        { !deleted &&
          <div className={styles.actions}>
            <a
              className={cn(styles.delete, { [styles.disabled]: !allowDelete })}
              onClick={this.onDelete}
              title={formatMessage({ id: 'au.statementEditor.delete' })}
            />
          </div>
        }
      </div>
    );
  }

}

export default class PolicyStatementEditor extends React.Component {

  static propTypes = {
    subjectAui: T.string,
    resourceAui: T.string,
    policyType: T.string,
    statements: T.object,
    createMode: T.bool,
    showErrors: T.bool,
    errors: T.object,
    onChange: T.func
  }

  constructor(props) {
    super(props);
    // skip statements based on v1 permissions for create/replicate page
    const statements = Object.values(props.statements).filter(s =>
      !props.createMode || !s._permissionId
    );

    this.state = {
      counter: statements.length,
      statements
    };

    this.lastStatementRef = createRef();
  }

  componentDidMount() {
    // initial page load
    if (!this.state.statements.length) {
      this.addNewStatement();
    }
  }

  componentDidUpdate() {
    // when the last statement gets deleted
    if (!this.state.statements.length) {
      this.addNewStatement();
    }
  }

  setRef = this.setRef.bind(this);
  setRef(ref) {
    this.editorRef = ref;
  }

  commitChangesAndScroll = this.commitChangesAndScroll.bind(this);
  commitChangesAndScroll() {
    this.commitChanges();

    if(this.lastStatementRef && this.lastStatementRef.current){
      this.lastStatementRef.current.scrollIntoView({block: 'end', behavior: 'smooth'});
    }
  }

  addNewStatement = this.addNewStatement.bind(this);
  addNewStatement() {
    const { policyType: type } = this.props;
    const auiAttrName = type === policyType.SUBJECT ? 'subjectAui' : 'resourceAui';

    this.setState(prevState => ({
      statements: [
        ...prevState.statements, {
          _id: prevState.counter,
          [auiAttrName]: this.props[auiAttrName],
          isNew: true
        }
      ],
      counter: prevState.counter + 1
    }), this.commitChangesAndScroll);
  }

  deleteStatement(idx) {
    this.setState(prevState => {
      const statements = [...prevState.statements];

      if (prevState.statements[idx].isNew) {
        statements.splice(idx, 1);
      } else {
        statements[idx].deleted = true;
      }

      return { statements };
    }, this.commitChanges);
  }

  undoStatement(idx) {
    this.setState(prevState => {
      const statements = [...prevState.statements];
      delete statements[idx].deleted;
      return { statements };
    });
  }

  handleOnChange(idx, statement) {
    this.setState(prevState => {
      const statements = [...prevState.statements];
      statements.splice(idx, 1, statement);
      return { statements };
    }, this.commitChanges);
  }

  commitChanges() {
    const { statements } = this.state;
    // convert back to object
    const editedStatements = statements.reduce((acc, statement) => {
      acc[statement._id] = statement;
      return acc;
    }, {});
    this.props.onChange(editedStatements);
  }

  render() {
    const { createMode, policyType: type, errors, showErrors } = this.props;
    const { statements } = this.state;

    return (
      <div className={styles.container} onKeyDown={this.onKeyPressed} ref={this.setRef}>
        <AutoIntl
          className={styles.add_top}
          displayId="au.policies.addStatement"
          onClick={this.addNewStatement}
          tag="a"
        />
        { statements.map((statement, idx) =>
          <Statement
            key={`statement_${statement._id}`}
            data={statement}
            policyType={type}
            createMode={createMode || statement.isNew}
            onChange={this.handleOnChange.bind(this, idx)}
            onDelete={this.deleteStatement.bind(this, idx)}
            single={statements.length === 1}
            errors={errors[statement._id]}
            showErrors={showErrors}
            statementRef={idx === statements.length - 1 ? this.lastStatementRef : undefined}
          />
        )}
        <AutoIntl
          className={styles.add_bottom}
          displayId="au.policies.addStatement"
          onClick={this.addNewStatement}
          tag="a"
        />
      </div>
    );
  }
}
