import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import transit from 'transit-immutable-js';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import { BUTTON_TYPE_SECONDARY} from '@au/core/lib/components/elements/AuButton';
import AuDropDown from '@au/core/lib/components/elements/AuDropDown';
import ConfirmationDialog from '@au/core/lib/components/objects/ConfirmationDialog';

import { NOOP } from '../../constants';
import shared from '../../shared';
import { history as browserHistory } from '../../history';
import { setPartition } from '../../utils/linkHelper';

import styles from '../../css/components/entity_replicate_dialog.module.scss';

export default class ReplicateDialog extends React.Component {

  static propTypes = {
    entity: IPT.map,
    partition: T.string,
    prevUrl: T.string,
    nextUrl: T.string,
    noticeDisplayId: T.string,
    onCancel: T.func,
    onBeforeReplicate: T.func
  }

  static defaultProps = {
    onCancel: NOOP
  }

  state = { partition: this.props.partition }

  handleOnChange = this.handleOnChange.bind(this);
  handleOnChange(partition) {
    this.setState({ partition });
  }

  generateOptions() {
    const partitions = shared.config.partitions ?? {};

    return (
      Object.entries(partitions)
            .map(([val, entry]) => ({ displayString: entry.label, val }))
    );
  }

  replicateEntity = this.replicateEntity.bind(this);
  async replicateEntity() {
    const { partition: currentPartition, prevUrl, nextUrl, onBeforeReplicate } = this.props;
    const { partition } = this.state;

    if (!partition) return;

    let entity;
    if (typeof onBeforeReplicate === 'function') {
      entity = await onBeforeReplicate(this.props.entity, partition === currentPartition);
      if (!entity) {
        /* eslint-disable no-console */
        console.error('Error: "onBeforeReplicate" must return an entity object.');
        /* eslint-enable no-console */
        return;
      }
    }
    else {
      entity = this.props.entity;
    }

    sessionStorage.setItem('entityToReplicate', transit.toJSON(entity));

    if (partition !== currentPartition) {
      setPartition(partition);

      const url = new URL(nextUrl, new URL(window.location.origin));
      url.search = window.location.search;
      window.location.assign(url);
    } else {
      browserHistory.push({ pathname: nextUrl, state: { prevUrl } });
    }
  }

  render() {
    const { partition, noticeDisplayId, onCancel } = this.props;
    const options = this.generateOptions();
    const currentPartition = options.find(o => o.val === partition);

    return (
      <ConfirmationDialog
        titleId="au.entity.replicate"
        confirmDisplayId="au.entity.replicate"
        headerClassName={styles.header}
        buttonType={BUTTON_TYPE_SECONDARY}
        onConfirm={this.replicateEntity}
        onCancel={onCancel}
      >
        <AutoIntl
          displayId="au.entity.replicate.message"
          tag="div"
        />
        <div className={styles.section}>
          <AutoIntl
            className={styles.current}
            displayId="au.entity.replicate.currentPartition"
            tag="div"
          />
          <div className={styles.value}>
            {currentPartition?.displayString}
          </div>
        </div>
        <div className={styles.section}>
          <AutoIntl
            className={styles.destination}
            displayId="au.entity.replicate.destinationPartition"
            tag="div"
          />
          <AuDropDown
            options={options}
            createMode={true}
            selection={this.state.partition}
            selectOption={this.handleOnChange}
            className={styles.dropdown}
          />
        </div>
        { Boolean(noticeDisplayId) &&
          <AutoIntl
            className={styles.notice}
            displayId={noticeDisplayId}
            tag="div"
          />
        }
      </ConfirmationDialog>
    );
  }

}
