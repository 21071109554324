import React from 'react';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import { BUTTON_TYPE_SECONDARY } from '@au/core/lib/components/elements/AuButton';
import ConfirmationDialog from '@au/core/lib/components/objects/ConfirmationDialog';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { formatMessage } from '../../../utils/reactIntl';
import { getParentEntityAwarePath } from '../../../utils/entity';
import { history as browserHistory } from '../../../history';
import apiFactory from '../../../utils/api';
import shared from '../../../../src/shared';
import SuccessDialog from '../SuccessDialog';
import DefaultList from '../List';

import styles from '../../../css/components/user_group_list.module.scss';

export default class UserGroupList extends DefaultList {

  accountsEndpoint = apiFactory(
    'accounts-service', 'accounts', this.props.actions
  );

  componentDidMount() {
    super.componentDidMount();

    const { location } = this.props;
    const { showSuccessDeleteUserGroupDialog, groupName } = location?.state ?? {};

    if (showSuccessDeleteUserGroupDialog) {
      this.showSuccessDeleteUserGroupDialog(groupName);
    }

    // reset location state
    browserHistory.replace({ state: {} });
  }

  onCreateBtnClick = this.onCreateBtnClick.bind(this);
  onCreateBtnClick() {
    const { entities } = this.props;

    this.accountsEndpoint.get(shared.accountId).then(res => {
      if (entities.size >= res.data.maxUserGroups) {
        createResponseAlertMessage({ 
          data: { message: formatMessage({ id: 'au.userGroup.limitExceed' }) } 
        });
      } else {
        //FIXME - MOVE TO linkHelper
        browserHistory.push(this.baseUrl + '/create');
      }
    }, createResponseAlertMessage);
  }

  onViewBtnClick(id) {
    const { parentEntity, serviceAlias } = this.props;
    
    if (parentEntity) {
      browserHistory.push(`/services/${serviceAlias}/userGroups/${id}/view`);
    } else {
      super.onViewBtnClick(id);
    }
  }

  onDeleteBtnClick(id) {
    const { entities } = this.props;
    const groupDisplayName = entities.getIn([id, 'displayName']);
    this.setState({ groupBaseId: id, groupDisplayName });

    this.showConfirmDeleteUserGroupDialog();
  }

  onDeleteMemberBtnClick(id) {
    const { entities } = this.props;
    const groupDisplayName = entities.getIn([id, 'displayName']);
    this.setState({ groupBaseId: id, groupDisplayName });
    this.showConfirmDeleteMemberSubViewDialog();
  }

  getRowActions({ id, canWrite, canReplicate, permissions }) {
    const { parentEntity } = this.props;
    let actions = super.getRowActions({ id, canWrite, canReplicate, permissions });

    if (parentEntity) {
      actions = actions.filter((x => x.displayId === 'au.entity.viewDetails'));
      if (canWrite && permissions.canDelete) {
        const removeAction = {
          key: 'remove_btn',
          displayId: 'au.entity.remove',
          onClick: this.onDeleteMemberBtnClick.bind(this, id)
        };
        actions.push(removeAction);
      }
    }

    return actions;
  }

  getAddons() {
    const { parentEntity } = this.props;
    const addons = super.getAddons();
    return parentEntity ? addons.filter((x => x.key != 'entity_buttons')) : addons;
  }

  renderConfirmDeleteUserGroupDialog() {
    const { showConfirmDeleteUserGroupDialog, groupDisplayName } = this.state;

    if (!showConfirmDeleteUserGroupDialog) {
      return false;
    }

    return (
      <ConfirmationDialog
        key="ConfirmDeleteUserGroup"
        titleId="au.userGroup.confirmDeleteTitle"
        confirmDisplayId="au.entity.delete.confirm"
        type="warning"
        buttonType={BUTTON_TYPE_SECONDARY}
        className={styles.container}
        headerClassName={styles.confirmation_header}
        onConfirm={this.handleOnDeleteUserGroupConfirm}
        onCancel={this.hideConfirmDeleteUserGroupDialog}
      >
        <AutoIntl
          className={styles.info}
          displayId="au.userGroup.dialogs.displayName" values={{
            b: chunks => <strong className={styles.display_name}>{chunks}</strong>,
            displayName: groupDisplayName
          }}
          tag="div"
        />
        <AutoIntl
          className={styles.description}
          displayId="au.userGroup.confirmDelete"
          values={{ b: chunks => { chunks; }, br: <br /> }}
          tag="div"
        />
      </ConfirmationDialog>
    );
  }

  renderSuccessDeleteUserGroupDialog() {
    const { showSuccessDeleteUserGroupDialog, groupDisplayName } = this.state;

    if (!showSuccessDeleteUserGroupDialog) {
      return false;
    }

    return (
      <SuccessDialog
        key="SuccessDeleteUserGroup"
        nameDisplayId="au.userGroup.dialogs.displayName"
        nameValues={{
          b: chunks => <strong className={styles.display_name}>{chunks}</strong>,
          displayName: groupDisplayName
        }}
        messageDisplayId="au.userGroup.deleteSuccess"
        onClose={this.hideSuccessDeleteUserGroupDialog}
      />
    );
  }

  renderConfirmDeleteMemberSubViewDialog() {
    const { parentEntity } = this.props;
    const { showDeleteSubView, groupDisplayName } = this.state;

    if (!showDeleteSubView) {
      return false;
    }

    return (
      <ConfirmationDialog
        type="alert"
        key="ConfirmDeleteUserGroupSubview"
        titleString={formatMessage(
          { id: 'au.membership.userGroup.delete.title' },
          {
            entityName: formatMessage(
              { id: `au.entity.name.${parentEntity.entityDef.type}` }
            )
          }
        )}
        confirmDisplayId='au.entity.proceed'
        onConfirm={this.handleOnMemberDeleteConfirm}
        onCancel={this.hideConfirmDeleteMemberSubViewDialog}
        className={styles.container}
      >
        <AutoIntl
          className={styles.info}
          displayId="au.userGroup.dialogs.displayName" values={{
            b: chunks => <strong className={styles.display_name}>{chunks}</strong>,
            displayName: groupDisplayName
          }}
          tag="div"
        />
        <AutoIntl
          className={styles.description}
          displayId="au.membership.userGroup.delete.description"
          values={{
            entityName: formatMessage({
              id: `au.entity.name.${parentEntity.entityDef.type}`
            }).toLowerCase()
          }}
          tag="div"
        />
      </ConfirmationDialog>
    );
  }

  renderDeleteMemberSuccessSubviewDialog() {
    const { parentEntity } = this.props;
    const { showDeleteSubViewSuccess, groupDisplayName } = this.state;

    if (!showDeleteSubViewSuccess) {
      return false;
    }

    return (
      <SuccessDialog
        key="successdeleteMemberOfUserGroup"
        nameDisplayId="au.userGroup.dialogs.displayName"
        nameValues={{
          b: chunks => <strong className={styles.display_name}>{chunks}</strong>,
          displayName: groupDisplayName
        }}
        messageDisplayId="au.membership.userGroup.deleteSuccess"
        messageValues={{ entityName: parentEntity.entityDef.type }}
        onClose={this.hideDeleteSuccessSubViewDialog}
      />
    );
  }

  renderDialogs() {
    const dialogs = super.renderDialogs();

    if (this.props.parentEntity) {
      // user/client -> <id> -> userGroups
      dialogs.push(this.renderConfirmDeleteMemberSubViewDialog());
      dialogs.push(this.renderDeleteMemberSuccessSubviewDialog());
    } else {
      // home/userGroups
      dialogs.push(this.renderConfirmDeleteUserGroupDialog());
      dialogs.push(this.renderSuccessDeleteUserGroupDialog());
    }
    return dialogs;
  }

  showConfirmDeleteUserGroupDialog = this.showConfirmDeleteUserGroupDialog.bind(this);
  showConfirmDeleteUserGroupDialog() {
    this.setState({ showConfirmDeleteUserGroupDialog: true });
  }

  hideConfirmDeleteUserGroupDialog = this.hideConfirmDeleteUserGroupDialog.bind(this);
  hideConfirmDeleteUserGroupDialog() {
    this.setState({ showConfirmDeleteUserGroupDialog: false });
  }

  showSuccessDeleteUserGroupDialog = this.showSuccessDeleteUserGroupDialog.bind(this);
  showSuccessDeleteUserGroupDialog(groupDisplayName) {
    this.setState({ showSuccessDeleteUserGroupDialog: true, groupDisplayName });
  }

  hideSuccessDeleteUserGroupDialog = this.hideSuccessDeleteUserGroupDialog.bind(this);
  hideSuccessDeleteUserGroupDialog() {
    this.setState({ showSuccessDeleteUserGroupDialog: false });
  }

  showConfirmDeleteMemberSubViewDialog = this.showConfirmDeleteMemberSubViewDialog.bind(this);
  showConfirmDeleteMemberSubViewDialog() {
    this.setState({ showDeleteSubView: true });
  }

  hideConfirmDeleteMemberSubViewDialog = this.hideConfirmDeleteMemberSubViewDialog.bind(this);
  hideConfirmDeleteMemberSubViewDialog() {
    this.setState({ showDeleteSubView: false });
  }

  showDeleteMemberSuccessSubViewDialog = this.showDeleteMemberSuccessSubViewDialog.bind(this);
  showDeleteMemberSuccessSubViewDialog() {
    this.setState({ showDeleteSubViewSuccess: true });
  }

  hideDeleteSuccessSubViewDialog = this.hideDeleteSuccessSubViewDialog.bind(this);
  hideDeleteSuccessSubViewDialog() {
    this.setState({ showDeleteSubViewSuccess: false });
  }
 
  handleOnDeleteUserGroupConfirm = this.handleOnDeleteUserGroupConfirm.bind(this);
  handleOnDeleteUserGroupConfirm() {
    const { endpoint } = this.props;
    const { groupBaseId } = this.state;
    endpoint.delete(groupBaseId).then(response => {
      this.hideConfirmDeleteUserGroupDialog();
      this.showSuccessDeleteUserGroupDialog(response.data.displayName);
    }, createResponseAlertMessage);
  }

  handleOnMemberDeleteConfirm = this.handleOnMemberDeleteConfirm.bind(this);
  handleOnMemberDeleteConfirm() {
    const { parentEntity, endpoint, actions } = this.props;
    const { groupBaseId } = this.state;
    
    const id = parentEntity.entityAlias === 'clients' 
      ? "service-account-" + parentEntity.entity.get('clientId') 
      : parentEntity.entity.get('username');

    endpoint.getMembersEndpoint(groupBaseId).delete(id).then(() => {
      this.hideConfirmDeleteMemberSubViewDialog();
      this.showDeleteMemberSuccessSubViewDialog();
      const { entityAlias } = this.props.match.params;
      const path = getParentEntityAwarePath(parentEntity, entityAlias);
      actions.deleteEntitySuccess({ path: [...path, groupBaseId] });
    }, createResponseAlertMessage);
  }
}
