import get from 'lodash/get';

import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { policyType, knownPolicyProps, SERVICE_NAMES } from '../../../constants';
import { history as browserHistory } from '../../../history';
import { cleanupPolicy } from '../../../utils/policies';
import { statementIdSerializer, statementIdDeserializer } from '../../../utils/serializers';
import { guaranteeUndefinedOrArray } from '../../utils/dataUtils';
import DefaultList from '../List';

export default class StatementList extends DefaultList {

  deleteEntity() {
    const { entity } = this.state;
    const pkField = this.tableDef.tablePkField;
    const entityId = get(entity, pkField);

    if (entityId) {
      const { subjectAui, resourceAui } = statementIdDeserializer(entityId);

      if (subjectAui && resourceAui) {
        const { endpoint, match, actions } = this.props;
        const policy = cleanupPolicy(
          {
            type: policyType.SUBJECT,
            statements: [entity],
            subjectAui
          },
          [...knownPolicyProps, 'subjectAui']
        );
        endpoint.delete(policy).then(() => {
          const { entityAlias } = match.params;
          const path = ['services', SERVICE_NAMES.IAM2, 'entities', entityAlias];
          actions.deleteEntitySuccess({ path: [...path, entityId] });
          browserHistory.push(this.parentUrl);
        }, createResponseAlertMessage);
      }
    }
  }

  list() {
    const { endpoint, pageSize } = this.props;
    // Handle policy statement entity using inspect/find endpoints
    let normalizedFilterParams = this.getNormalizedFilterParams();
    if (normalizedFilterParams === null || normalizedFilterParams === undefined || Object.keys(normalizedFilterParams).length === 0) {
      return endpoint.inspect({ pageSize });
    } else {
      return endpoint.find({ pageSize, ...normalizedFilterParams });
    }
  }

  getNormalizedFilterParams = this.getNormalizedFilterParams.bind(this);
  getNormalizedFilterParams() {
    const subjectAuisKey = 'subjectAuis';
    let normalized = {};
    for (const [key, rawValues] of Object.entries(this.state.filterQueryParams)) {
      if (rawValues === null || rawValues === undefined || rawValues.length === 0) {
        continue;
      }
      let values = guaranteeUndefinedOrArray(rawValues);
      if (key === 'subjectUsers' || key === 'subjectClients' || key === 'subjectUserGroups') {
        const subjectAuis = normalized[subjectAuisKey] ?? [];
        normalized[subjectAuisKey] = subjectAuis.concat(values);
      } else {
        normalized[key] = values;
      }
    }
    return normalized;
  }

  onListSuccess = this.onListSuccess.bind(this);
  onListSuccess(resp) {
    const { entityDef, match, actions } = this.props;
    const { entityAlias } = match.params;
    const { itemsKey } = this.props.endpoint.constructor;

    actions.listEntitiesSuccess({
      path: ['services', SERVICE_NAMES.IAM2, 'entities', entityAlias],
      data: (resp.data[itemsKey] || []).map(entry => {
        entry._id = statementIdSerializer(entry);
        return entry;
      }),
      pkField: entityDef.pkField,
      replace: resp.isFirstPage
    });
  }

}
