import React, { useEffect, useRef, useState } from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import { ENTER_KEY } from "@au/core/lib/constants";
import AuChips from '@au/core/lib/components/elements/AuChips';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { formatMessage } from '../utils/reactIntl';
import { noListDuplicates } from '../utils/validationRules';
import { run } from '../utils/validationRuleRunner';

import styles from '../css/components/multi_entry_editor.module.scss';

const validationRules = [ noListDuplicates('') ];
// currently supported separators are: comma, semicolon and white space
const SEPARATOR_RE = /[,;\s]/;

const SPACE_KEY = " ";
const SEMICOLON_KEY = ";";
const COMMA_KEY = ",";

export default function MultiEntryEditor(props) {
  const { className, createMode, disabled, entryLabelId } = props;
  const [errors, setErrors] = useState([]);
  const [showError, setShowError] = useState(false);
  const [entries, setEntries] = useState(props.entries);
  const [storedEntries, setStoredEntries] = useState({});
  const [scrollHeight, setScrollHeight] = useState(0);
  const containerRef= useRef(null);

  useEffect(() => {
    if (typeof storedEntries === 'null' && !createMode) {
      // initialize stored entries once on mount
      setStoredEntries(
        entries.reduce((acc, entry) => (acc[entry] = '', acc), {})
      );
    }
  }, []);

  useEffect(() => {
    const chipsContainer = containerRef.current.querySelector('[class*="chips-module__chips_container"]');
    if (chipsContainer) {
      if (scrollHeight < chipsContainer.scrollHeight) {
        chipsContainer.style.height = `${chipsContainer.scrollHeight + 2}px`;
        setScrollHeight(chipsContainer.scrollHeight);
      } else {
        setScrollHeight(chipsContainer.scrollHeight);
      }
    }
    props.onChange(entries?.length ? entries : null, errors);
  }, [entries]);

  const isValidEntry = () => {
    return null; // valid [placeholder for now]
  };

  const onChipsChange = ([...entries]) => {
    const userInput = entries.pop();
    const nextEntries = [...entries, ...(userInput || '').trim().split(SEPARATOR_RE).filter(text => text.trim())]
    const validationErrors = run(nextEntries, validationRules);
    const errors = (validationErrors || {}).errors || [];

    const field = formatMessage({ id: entryLabelId });
    for (let i = 0, len = nextEntries.length; i < len; i++) {
      let vErr = run(nextEntries[i], [isValidEntry]);
      if (vErr.errDisplayId) {
        errors[i] = { ...vErr, values: { field } };
      }
    }

    setEntries(nextEntries);
    setErrors(errors);
    setShowError(true);
  };

  const fieldError = Boolean((props.showErrors || showError) && props.error?.errDisplayId);
  const totalErrors = errors.filter(e => e).length;

  return(
    <div className={cn(styles.container, className)} ref={containerRef}>
      <AuChips
        chips={entries}
        errors={errors}
        disabled={disabled}
        className={styles.chips}
        storedChips={storedEntries}
        placeholderId="au.multiEntryEditor.type.placeholder"
        handleOnPaste={true}
        handleOnBlur={true}
        createChipKeys={[ENTER_KEY,SPACE_KEY,SEMICOLON_KEY,COMMA_KEY]}
        onChange={onChipsChange}
      />
      { totalErrors > 0 && !fieldError &&
        <AutoIntl
          className={styles.error}
          displayId="au.multiEntryEditor.invalidEntries"
          values={{ count: totalErrors }}
        />
      }
      { fieldError &&
        <AutoIntl
          className={styles.error}
          displayId={props.error.errDisplayId}
          values={{ ...props.error.values, field: formatMessage({ id: props.error.fieldDisplayId })}}
        />
      }
      <AutoIntl className={styles.note} displayId="au.multiEntryEditor.separationNote" />
    </div>
  )
}

MultiEntryEditor.propTypes = {
  entries: T.array,
  className: T.string,
  fieldLabelId: T.string,
  onChange: T.func,
  createMode: T.bool,
  disabled: T.bool,
  showErrors: T.bool,
  error: T.object,
}