import React from 'react';
import { fromJS } from 'immutable';

import { BUTTON_TYPE_TERTIARY } from '@au/core/lib/components/elements/AuButton';
import AuAnalytics from '@au/core/lib/utils/AuAnalytics';

import { policyGroup, policyType, knownPolicyProps, SERVICE_NAMES } from '../../../constants';
import { history as browserHistory } from '../../../history';
import SidebarSubviews from "../../SidebarSubview";
import { cleanupPolicy } from '../../../utils/policies';
import { statementIdSerializer, statementIdDeserializer } from '../../../utils/serializers';
import { Policy } from '../Policies';
import DefaultView from './PolicyView';

import styles from '../../../css/components/entity_view.module.scss';

export default class StatementView extends DefaultView {

  loadData = this.loadData.bind(this);
  loadData() {
    if (this.entityId) {
      const { subjectAui, resourceAui } = statementIdDeserializer(this.entityId);

      if (subjectAui && resourceAui) {
        const { entity, entityDef, endpoint, match, actions } = this.props;
        const queryParams = { subjectAui, resourceAui };

        if (!(entity.size && entity.has(entityDef.pkField))) {
          const { entityAlias } = match.params;

          return endpoint.inspect(queryParams).then(resp => {
            if (resp.data?.statements?.length) {
              actions.listEntitiesSuccess({
                path: ['services', SERVICE_NAMES.IAM2, 'entities', entityAlias],
                data: resp.data.statements.map(entry => {
                  entry._id = statementIdSerializer(entry);
                  return entry;
                }),
                pkField: entityDef.pkField
              });
            } else {
              this.setState({ found: false });
            }
          }, this.onAfterFetch, this.genericErrorHandler);
        }
      }
    }
  }

  getActions() {
    const actions = super.getActions();

    actions.push({
      key: 'statement_delete',
      type: BUTTON_TYPE_TERTIARY,
      displayId: 'au.entity.delete',
      onClick: this.onDeleteBtnClick
    });

    return actions;
  }

  getPolicyData() {
    return fromJS({
      type: policyType.SUBJECT,
      group: policyGroup.DIRECT,
      statements: [this.props.entity.toJS()]
    });
  }

  onViewJsonBtnClick = this.onViewJsonBtnClick.bind(this);
  onViewJsonBtnClick() {
    AuAnalytics.trackEvent({
      category: 'Button',
      action: 'ViewJson',
      label: 'StatementView'
    });

    const { actions } = this.props;
    const policyData = this.getPolicyData();

    actions.openEntityJsonViewer(
      this.entityId,
      cleanupPolicy(policyData).toJS(),
      {
        tabsClassName: styles.json_viewer_tabs,
        panelClassName: styles.json_viewer_panel
      }
    );
  }

  deleteEntity = this.deleteEntity.bind(this);
  deleteEntity() {
    let entityId = this.entityId;

    if (entityId) {
      const { subjectAui, resourceAui } = statementIdDeserializer(entityId);

      if (subjectAui && resourceAui) {
        const { endpoint, entity, match, actions } = this.props;
        const policy = cleanupPolicy(
          {
            type: policyType.SUBJECT,
            statements: [ entity ],
            subjectAui
          },
          [ ...knownPolicyProps, 'subjectAui' ]
        );
        endpoint.delete(policy).then(() => {
          const { entityAlias } = match.params;
          const path = ['services', SERVICE_NAMES.IAM2, 'entities', entityAlias];
          actions.deleteEntitySuccess({ path: [...path, this.entityId.toString()] });
          browserHistory.push(this.parentUrl);
        }, this.genericErrorHandler);
      }
    }
    // hide the dialog
    this.toggleDeleteDialog();
  }

  renderContentAboveTable() {
    if (this.props.history.location.pathname.includes('history')) {
      return;
    } else {
      {this.renderActions()}
    }
  }

  renderContent() {
    const { actions, screenWidth } = this.props;
    const isMobile = screenWidth !== "desktop";

    return (
      <div className={styles.content} ref={ref => this.containerRef = ref}>
        <div className={styles.content_flex}>
          <SidebarSubviews
            navLinks={this.getNavLinks()}
            portalRef={this._leftSidebarRef}
            open={this.state.subviewSidebarOpen}
            setOpen={this.setSubviewSidebar}
            isMobile={isMobile}
          />
          <Policy
            pkField="subjectAui"
            data={this.getPolicyData()}
            showButtons={false}
            statementsOnly={true}
            actions={actions}
            onDelete={this.onDeleteBtnClick}
            detailView={true}
            className={styles.hide_before}
          />
        </div>
        { this.renderDialogs() }
      </div>
    );
  }
}