import { generateEntityPath } from '../../../utils/entity';
import DefaultEdit from '../Edit';
export default class DeviceEdit extends DefaultEdit {

  queryParams = { includeRelations: 'binding', expandRelations: 'binding' }

  componentDidMount() {
    super.componentDidMount();
    this.parentUrl = this.props.match.url.split('/').slice(0, -1).join('/') + `/view`;
  }

  findUpdatedTags(tags) {
    const { entity } = this.props;
    const currentTags = entity.get('tags').toJS();
    // find new tags or empty (aka flagged to be removed)

    Object.keys(currentTags).forEach(tag => {
      if (!Object.keys(tags).includes(tag)) {
        tags[tag] = null;
      }
    })
    return tags;
  }

  handleOnSave = this.handleOnSave.bind(this);
  handleOnSave(entity) {
    const { endpoint, serviceAlias, match, actions, history } = this.props;
    const { entityId } = match.params;
    const tagsToUpdate = this.findUpdatedTags(entity.tags);

    return endpoint.updateTags(entityId, tagsToUpdate).then(resp => {
      const path = [...generateEntityPath(endpoint, serviceAlias), entityId];
      actions.patchEntitySuccess({ path, data: resp.data });
      history.push(this.parentUrl);
    });
  }
}
