import React from 'react';
import * as T from 'prop-types';

import styles from '../css/components/panel_content.module.scss';

const PanelSectionItem = ({title="", details=[]}) => (
  <div className={styles.container}>
    <div className={styles.title}>{title}</div>
    {details?.map(
      ({ name, value }, idx) => 
        <div key={idx} className={styles.content}>
          { name && <span className={styles.name}>{name}:</span>}
          <span className={styles.value}>{value}</span>
        </div>
    )}
  </div>
);

export default function PanelContent({className, sections=[], children=null}) {
  return (
    <div className={className}>
      {sections?.map(
        (section, idx) => <PanelSectionItem key={idx} {...section} />
      )}
      {children}
    </div>
  );
}

PanelSectionItem.propTypes = {
  title: T.oneOfType([T.string, T.element]),
  details: T.arrayOf(T.shape({
    name: T.string,
    value: T.oneOfType([T.string, T.number, T.element])
  }))
};

PanelSectionItem.defaultProps = {
  title: "",
  details: []
};

PanelContent.propTypes = {
  sections: T.arrayOf(T.shape(PanelSectionItem.propTypes)),
  children: T.oneOfType([T.element, T.arrayOf(T.element)])
};

PanelContent.defaultProps = {
  sections: [],
  children: null
};
