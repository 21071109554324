import moment from 'moment-timezone';

import { ARN_RE, AUI_RE, UUID_RE, dateTimeFormatToken } from '../constants';
import { formatMessage } from './reactIntl';

// produces an array of 12 short month names: [Jan, Feb, ..., Dec]
const MONTHS_SHORT = [...Array(12).fill(0).keys()].map(m => moment().month(m).format('MMM'));
// produces a string: `(Jan|Feb|...|Dec)`
const MONTHS_SHORT_RULE = `(${MONTHS_SHORT.join('|')})`;
// regexp for short month names
const MONTHS_SHORT_RE = new RegExp(`^(${MONTHS_SHORT_RULE}[,-]?)+$`);
// regexp to match string like: 12d, 1M, 24h, 30m, etc.
const LAST_N_DATES_RE = new RegExp(`^(\\d+)([${Object.keys(dateTimeFormatToken).join('|')}])$`);

/**
 * Parses input string into an array of objects.
 * Example:
 *    'foo["bar"]' => [{ func: 'foo', args: ['bar'] }]
 *
 * @param  {String} inputStr String to be parsed.
 * @return {Array}           A parsed array.
 */
export function parseFunctions(inputStr) {
  if (typeof inputStr !== 'string') {
    return [];
  }

  // Because we support regex we need to be able to respect escape characters in our split
  // Delimiter we use matches OR('|') term in regex. So in order to support this when we have a match
  // we will check for the escaped '\\|' and remove the double escape in order to parse the json.
  
  // NOTE: Regex negative lookbehind is not supported on safari browser which breaks app, but negative lookahead is suported
  // hence reversing the input string and passing through the regex
  return inputStr.split('').reverse().join('').split(/\|(?!\\)/).reverse().map(str => {
    const match = /^([a-z][a-zA-Z]*){1}(\[.+\]){0,1}$/.exec(str.split('').reverse().join(''));
    if (match) {
      // eslint-disable-next-line no-useless-escape
      return { func: match[1], args: match[2] && JSON.parse(match[2].replace('\\|', '|')) || [] };
    }
    return '';
  }).filter(v => v);
}

/**
 * Parses input data into an array of objects.
 * Example:
 *    { foo: 'bar' } => [{ func: 'foo', args: ['bar'] }]
 *
 * @param  {Array|String} input Data to be parsed.
 * @return {Array}        A parsed array.
 */
export function parseCallable(input) {
  if (Array.isArray(input)) {
    return input.map(el => {
      if (typeof el === 'string') {
        return parseFunctions(el)[0];
      }
      const [func, args] = Object.entries(el)[0];
      return { func, args };
    });
  } else if (typeof input === 'string') {
    return parseFunctions(input);
  }
  return [];
}

/**
 * Parses strings like `6M|Jan-Dec|prev_month|2d,30m` to an actual date ranges
 * @param  {String} inputStr      String to be parsed.
 * @param  {Boolean} excludeToday If true - skips today's date.
 * @return {Array}                A parsed array.
 */
export function parseRanges(inputStr, excludeToday) {
  if (typeof inputStr !== 'string') {
    return [];
  }

  const groups = inputStr.split('|');
  const dateRanges = [];

  for (let [groupIdx, group] of groups.entries()) {
    const entries = group.split(',');
    for (let value of entries) {
      let match;
      // captures last N seconds/minutes/hours/days/months: 12m, 6m, 3m, 30d, 7d, etc.
      if (match = value.match(LAST_N_DATES_RE), match) {
        let tokenValue = dateTimeFormatToken[match[2]];
        const precise = ['s', 'm', 'h'].includes(match[2]);
        dateRanges.push({
          displayString: formatMessage(
            { id: `au.dateRange.lastN${tokenValue}` },
            { value: match[1] }
          ),
          get from() {
            return (
              (precise
                ? moment().milliseconds(0)
                : moment().startOf('date')
              )
                .subtract(match[2] === 'd' ? match[1] - 1 : match[1], tokenValue)
                .subtract(excludeToday && !precise && ['M', 'd'].includes(match[2]) ? 1 : 0, 'days')
                .toDate()
            );
          },
          get to() {
            return (
              excludeToday && !precise ? moment().startOf('date').subtract(1, 'ms').toDate() :
                moment().milliseconds(0).toDate()
            );
          },
          value,
          groupIdx
        });
      }
      // captures quarters: Q1 - Q4
      else if (match = value.match(/^[Q]([1-4])$/), match) {
        dateRanges.push({
          displayString: formatMessage(
            { id: 'au.dateRange.quarterN' },
            { value: match[1] }
          ),
          get from() {
            return moment()
              .month(3 * (match[1] - 1))
              .startOf('month')
              .startOf('date')
              .toDate();
          },
          get to() {
            return moment()
              .month(3 * (match[1] - 1) + 2)
              .endOf('month')
              .endOf('date')
              .toDate();
          },
          value,
          groupIdx
        });
      }
      // captures months: Jan, Feb, Mar-Apr, etc.
      else if (match = value.match(MONTHS_SHORT_RE), match) {
        value.split(',').forEach(item => {
          let [start, end = start] = item.split('-');
          for (let m = moment().month(start); m.isSameOrBefore(moment().month(end)); m.add(1, 'months')) {
            dateRanges.push({
              displayString: m.format('MMMM YYYY'),
              from: moment(m).startOf('month').startOf('date').toDate(),
              to: moment(m).endOf('month').endOf('date').toDate(),
              value: m.format('MMM'),
              groupIdx
            });
          }
        });
      }
      // captures prev_month
      else if (value === 'prev_month') {
        dateRanges.push({
          displayId: 'au.dateRange.prevMonth',
          get from() {
            return moment().subtract(1, 'months').startOf('month').startOf('date').toDate();
          },
          get to() {
            return moment().subtract(1, 'months').endOf('month').endOf('date').toDate();
          },
          value,
          groupIdx
        });
      }
      // captures month_to_date
      else if (value === 'month_to_date') {
        dateRanges.push({
          displayId: 'au.dateRange.monthToDate',
          get from() {
            return moment()
              .startOf('month')
              .startOf('date')
              .toDate();
          },
          get to() {
            return moment()
              .milliseconds(0)
              .subtract(excludeToday ? 1 : 0, 'days')
              .toDate();
          },
          value,
          groupIdx
        });
      }
    }
    ++groupIdx;
  }

  return dateRanges;
}

/**
 * Removes ^ and $ from a provided regular expression
 * @param  {object} re Regular expression
 * @return {string} String representation of a reular expresssion (provided)
 *                  withouh ^ and $
 */
function removeRegexBoundaries(re) {
  const parts = re.toString().split(/^\/\^?|\$\/?/g);
  // get the expression between ^ and $ (excluding), if those are present
  return parts[1] ?? parts[0];
}

/**
 * Parses provided string and returns an `arn` when matched
 * @param  {String} inputStr Input string
 * @return {String|null} Parsed arn (e.g.: `aui:iam:user`) or undefined
 */
export function parseArn(inputStr) {
  const match = inputStr.match(new RegExp(removeRegexBoundaries(ARN_RE)));
  return match && match[0];
}

/**
 * Parses provided string and returns a UUID if matched
 * @param  {String} inputStr Input string
 * @return {String|null} Parsed UUID or undefined
 */
export function parseUuid(inputStr) {
  const match = inputStr.match(new RegExp(removeRegexBoundaries(UUID_RE), 'i'));
  return match && match[0];
}

/**
 * Parses provided string into `arn`, `uuid` and `resource`
 * @param  {String} inputStr Input string
 * @param  {Boolean} strict When `false` - partial UUID will be also returned
 * @return {Object} Object containing `arn`, `uuid` and `resource` fields
 */
export function parseAui(inputStr, strict = true) {
  if (!AUI_RE.test(inputStr)) {
    // cannot parse invalid Aui
    return { arn: null, uuid: null, resource: null };
  }

  const resource = inputStr.split(/\/(.+)/)[1] ?? null;
  const uuid = parseUuid(inputStr);

  return {
    uuid: uuid === resource || !strict ? uuid : null,
    arn: parseArn(inputStr),
    resource
  };
}
