import React from "react";
import * as T from "prop-types";
import IPT from 'react-immutable-proptypes';
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";

import styles from "../css/components/truncated_list.module.scss";

export default class TruncatedListWithPopup extends React.PureComponent {

  static propTypes = {
    items: T.oneOfType([IPT.listOf(T.element), T.arrayOf(T.element)]).isRequired,
    elementsToShow: T.number,
  };

  static defaultProps = {
    elementsToShow: 3,
  }

  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  render() {
    const { elementsToShow, type } = this.props;
    let { items } = this.props;
    const numItems = items?.size || items?.length || 0;
    let newItems;

    if (!Array.isArray(items)) {
      items = Object.keys(items.toJS());
    }

    if (type === 'property') {
      newItems = [];
      items.map((item) => {
        newItems.push(<AutoIntl className={styles.item} displayString={item}/>)
      })
    }
    else if (type === 'resource') {
      newItems = [];
      items.map((item) => {
        newItems.push(<AutoIntl className={styles.item} displayString={item}/>)
      })
    }
    else {
      newItems = items
    }

    if (numItems > elementsToShow) {
      const firstNElements = newItems.slice(0, elementsToShow);
      const truncatedElements = newItems.slice(elementsToShow);

      return <div className={styles.entity_link_list}>
        <div className={styles.entity_link_list_sub}>
          {firstNElements}
          <div
            onMouseEnter={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
          >
            <AutoIntl
              className={styles.plus}
              displayId="au.entity.more" values={{ number: numItems - elementsToShow }}
            />
            { this.state.hover &&
              <div className={styles.popup_container}>
                <div className={styles.popup}>
                  <div className={styles.popup_list}>
                    {truncatedElements}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>;
    }

    return <div className={styles.entity_link_list}>
      <div className={styles.entity_link_list_sub}>
        {newItems}
      </div>
    </div>;
  }
}
