import React from 'react';
import * as T from 'prop-types';
import { Link } from 'react-router-dom';

import styles from '../css/components/popout.module.scss';

export default class SubEntityLink extends React.Component {

  static propTypes = {
    entityId: T.oneOfType([T.number, T.string]),
    entityType: T.string,
    className: T.string,
    viewInPopout: T.bool,
    popoutContained: T.bool
  }

  static defaultProps = {
    popoutContained: false
  }

  handleOnClick = this.handleOnClick.bind(this);
  handleOnClick(e) {
    // Cleanup popout if we're navigating away from one
    if (this.props.popoutContained) {
      this.props.actions.closePopout();
    }

    // noop here, using handleOnClick for tracking
    if (this.props.url && !this.props.viewInPopout) return;

    const { entityType, viewInPopout } = this.props;
    const entityId = this.props.entityId.toString();

    e.preventDefault();

    const props = {
      props: { viewInPopout, nuevo: true, initialWidth: 600, className: styles[`detail_popout`]},
      componentName: `ConnectedSmsHistoryView`,
      componentProps: {
        params: {
          [`${entityType}Id`]: entityId,
        },
        key: 'detail',
        popoutProps: {data: this.props.data}
      }
    }

    this.props.actions.openPopout(props);
  }


  render() {
    const { entityId, className, children } = this.props;

    return (
      <Link
        key={`subEntityLink_${entityId}`}
        innerRef={el => this.linkRef = el}
        className={className}
        onClick={this.handleOnClick}
      >{children}</Link>
    );
  }

}