import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import { Map as imMap } from 'immutable';
import cn from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';

import TMC from "@autonomic/browser-sdk";
import AuChips from '@au/core/lib/components/elements/AuChips';
import AuDropDown from '@au/core/lib/components/elements/AuDropDown';
import AuInput from '@au/core/lib/components/elements/AuInput';
import AuToggle, {
  TOGGLE_SIZE_MEDIUM,
  TOGGLE_TYPE_PRIMARY
} from '@au/core/lib/components/elements/AuToggle';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import AuDateTimeInput from '../AuDateTimeInput';
import EntitlementEditor from '../EntitlementEditor';
import PolicyStatementEditor from '../PolicyStatementEditor';
import TagEditor from '../TagEditor';
import UserGroupMembersEditor from '../../containers/UserGroupMembersEditor';
import KeyValueEditor from '../KeyValueEditor';
import OutputEditor from '../../containers/OutputEditor';
import MultiEntryEditor from '../MultiEntryEditor';
import FlowEditor from '../../containers/FlowEditor';
import {
  tapFilterType,
  outputFilterType,
  TAP_FILTERS_MODES,
} from '../../constants';

import { run } from '../../utils/validationRuleRunner';
import { required, shouldBeInList, validEmail } from '../../utils/validationRules';
import auFormatters from '../../utils/formatters';
import { formatMessage } from '../../utils/reactIntl';

import fieldset from '../../css/fieldset.module.scss';
/* Custom Inputs */
export * from './customInputs/';


export function formatOptions(data) {
  const dataMap = {};
  data.forEach(val => {
    dataMap[val.name] = val.name;
  });
  const options = [];
  Object.values(dataMap).forEach(option => {
    options.push({ val: option, displayString: option });
  });
  return options;
}

class GenericInput extends React.PureComponent {
  render() {
    const {
      field, handleFieldChange, handleOnBlur, handleOnKeyDown, labelId,
      placeholder, placeholderId, type, value, createMode, readOnly, autoFocus,
      disabled, showError, validationError, handleOnClear, labelHintId
    } = this.props;

    return (
      //TODO - SHOULD THE ROW MOVE TO THE LAYOUT?
      <div className={fieldset.row}>
        <label className={cn(fieldset.label, { [fieldset.label_with_hint]: labelHintId })}>
          <FormattedMessage id={labelId} />
          {labelHintId && <span className={fieldset.hint}>
            {formatMessage({ id: labelHintId })}
          </span>}
        </label>
        <div className={fieldset.control}>
          <AuInput
            type={type}
            name={field}
            value={value}
            placeholder={placeholder}
            placeholderId={placeholderId}
            showError={showError}
            onChange={handleFieldChange}
            onClear={handleOnClear}
            onBlur={handleOnBlur}
            onKeyDown={handleOnKeyDown}
            error={validationError}
            className={fieldset.input}
            createMode={createMode}
            autoFocus={autoFocus}
            readOnly={readOnly}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }

}

export class TextInput extends React.Component {
  static propTypes = {
    entity: T.object,
    field: T.string,
    readOnly: T.bool,
    disabled: T.bool,
    fieldChangeCallback: T.func,
    labelId: T.string,
    placeholder: T.string,
    placeholderId: T.string,
    createMode: T.bool,
    validationRegistration: T.func,
    validationRules: T.array,
    validationErrors: T.object
  }

  constructor(props) {
    super(props);

    const { entity, field, defaultValue } = this.props;

    this.state = {
      value: get(entity, field, defaultValue || '')
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { entity, field } = props;

    if (entity && entity[field]) {
      if (state.value === undefined || state.value === "") {
        return {
          value: entity[field]
        };
      }
    }
    return null;
  }

  componentDidMount() {
    const { field, labelId, disabled, registerValidation, validationRules } = this.props;
    if (registerValidation && validationRules && !disabled) {
      registerValidation(field, labelId, validationRules);
    }
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(ev) {
    const { value } = ev.target;
    this.onChange(value);
  }

  handleOnClear = this.handleOnClear.bind(this);
  handleOnClear() {
    this.onChange('');
  }

  onChange = this.onChange.bind(this);
  onChange(value) {
    const { fieldChangeCallback, field } = this.props;

    this.setState({ value });

    if (fieldChangeCallback) {
      fieldChangeCallback(field, value);
    }
  }

  handleOnBlur = this.handleOnBlur.bind(this);
  handleOnBlur() {
    this.setState({ showError: true });
  }

  render() {
    const {
      field, labelId, placeholder, placeholderId, createMode, readOnly,
      autoFocus, disabled, onKeyDown, validationErrors, showErrors
    } = this.props;
    const { value } = this.state;

    const validationError = validationErrors && (field in validationErrors) ? validationErrors[field] : null;

    return (
      <GenericInput
        type="text"
        field={field}
        value={value}
        readOnly={readOnly}
        disabled={disabled}
        autoFocus={autoFocus}
        createMode={createMode}
        labelId={labelId}
        placeholder={placeholder}
        placeholderId={placeholderId}
        showError={validationError && (showErrors || this.state.showError)}
        validationError={validationError}
        handleOnBlur={this.handleOnBlur}
        handleOnClear={this.handleOnClear}
        handleFieldChange={this.handleFieldChange}
        handleOnKeyDown={onKeyDown}
      />
    );
  }
}

export class TextDisplay extends React.PureComponent {
  static propTypes = {
    entity: T.object,
    field: T.string,
    labelId: T.string,
  }

  render() {
    const { entity, field, labelId } = this.props;
    const value = entity && (field in entity) ? entity[field] : '';

    return (
      <div className={fieldset.row}>
        <label className={fieldset.label}>
          <FormattedMessage id={labelId} />
        </label>
        <div className={fieldset.control}>
          <div>{value}</div>
        </div>
      </div>
    );
  }
}

export class TextArea extends React.PureComponent {
  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    labelId: T.string,
    placeholder: T.string,
    placeholderId: T.string,
    createMode: T.bool,
    validationRegistration: T.func,
    validationRules: T.array,
    validationErrors: T.object
  }

  constructor(props) {
    super(props);

    const { entity, field } = this.props;

    this.state = {
      value: entity && (field in entity) ? entity[field] : undefined
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { entity, field } = props;

    if (state.value === undefined && entity && entity[field]) {
      return {
        value: entity[field]
      };
    }
    return null;
  }

  componentDidMount() {
    const { field, labelId, registerValidation, validationRules } = this.props;

    if (registerValidation && validationRules) {
      registerValidation(field, labelId, validationRules);
    }
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(ev) {
    const target = ev.target;
    this.setState({ value: target.value });
  }

  handleOnBlur = this.handleOnBlur.bind(this);
  handleOnBlur(ev) {
    const target = ev.target;
    const { fieldChangeCallback, field } = this.props;

    if (fieldChangeCallback) {
      fieldChangeCallback(field, target.value);
    }
  }

  render() {
    const { value } = this.state;
    const { field, labelId, placeholder, placeholderId, createMode, validationErrors, labelHintId } = this.props;

    const validationError = validationErrors && (field in validationErrors) ? validationErrors[field] : null;
    const showError = Boolean(validationError);

    return (
      <GenericInput
        field={field}
        handleOnBlur={this.handleOnBlur}
        handleFieldChange={this.handleFieldChange}
        labelId={labelId}
        labelHintId={labelHintId}
        placeholder={placeholder}
        placeholderId={placeholderId}
        showError={showError}
        type="textarea"
        value={value}
        validationError={validationError}
        createMode={createMode}
      />
    );
  }
}

/*
 * Although FB prefers composition, this just seems much cleaner in this case.
 */
export class EmailInput extends React.PureComponent {
  render() {
    let { validationRules } = this.props;

    if (validationRules && Array.isArray(validationRules)) {
      validationRules.push(validEmail);
    }
    else {
      validationRules = [validEmail];
    }

    let updatedProps = Object.assign({}, this.props, { validationRules });

    return (
      <TextInput {...updatedProps} />
    );
  }
}

export class BooleanInput extends React.PureComponent {
  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    labelId: T.string,
    captionId: T.string,
    validationErrors: T.object,
    disabled: T.bool
  }

  constructor(props) {
    super(props);

    const { entity, field, value } = this.props;

    this.state = {
      checked: field in entity ? entity[field] === value : false
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { entity, field, value } = props;

    if (state.checked === false && field in entity) {
      return {
        checked: entity[field] === value
      };
    }
    return null;
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(ev) {
    const target = ev.target;
    const { fieldChangeCallback, field } = this.props;

    this.setState({ checked: target.checked });

    if (fieldChangeCallback) {
      fieldChangeCallback(field, target.checked ? target.value : null);
    }
  }

  render() {
    const { checked } = this.state;
    const { field, value, disabled, validationErrors, captionId, className } = this.props;

    const validationError = validationErrors && (field in validationErrors) ? validationErrors[field] : null;
    const showError = Boolean(validationError);

    const caption = captionId ? <AutoIntl displayString={captionId} /> : '';

    return (
      <AuInput
        type="checkbox"
        name={field}
        caption={caption}
        value={value}
        checked={checked}
        disabled={disabled}
        showError={showError}
        onChange={this.handleFieldChange}
        error={validationError}
        className={cn(fieldset.input, className)} />
    );
  }
}

export class ToggleInput extends React.PureComponent {
  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    labelId: T.string,
    onValue: T.any.isRequired,
    offValue: T.any.isRequired,
    className: T.string,
    captionLeftId: T.string,
    captionRightId: T.string,
    validationErrors: T.object,
    defaultValue: T.bool,
    disabled: T.bool,
    toggleType: T.string
  }

  static defaultProps = {
    defaultValue: true,
    toggleType: TOGGLE_TYPE_PRIMARY
  }

  constructor(props) {
    super(props);

    const { field, entity, onValue, defaultValue } = props;

    this.state = {
      checked: entity && field in entity ? entity[field] === onValue : defaultValue
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { entity, field, onValue } = props;

    if (state.checked === undefined && field in entity) {
      return {
        checked: entity[field] === onValue
      };
    }

    return null;
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(ev) {
    const target = ev.target;
    const { fieldChangeCallback, field, onValue, offValue } = this.props;

    this.setState({ checked: target.checked });

    if (fieldChangeCallback) {
      fieldChangeCallback(field, target.checked ? onValue : offValue);
    }
  }

  render() {
    const { checked } = this.state;
    const {
      field,
      labelId,
      disabled,
      className,
      showLabels,
      minimalistic,
      onDisplayId,
      offDisplayId,
      toggleType
    } = this.props;

    return (
      <div className={fieldset.row}>
        {labelId &&
          <label className={fieldset.label}>
            <AutoIntl displayId={labelId} />
          </label>
        }
        <div className={cn(fieldset.control, className)}>
          <AuToggle
            type={toggleType}
            size={TOGGLE_SIZE_MEDIUM}
            name={field}
            checked={checked}
            disabled={disabled}
            showLabels={showLabels}
            minimalistic={minimalistic}
            onChange={this.handleFieldChange}
            className={fieldset.toggle}
            onDisplayId={onDisplayId}
            offDisplayId={offDisplayId}
          />
        </div>
      </div>
    );
  }
}

export class SelectInput extends React.Component {
  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    labelId: T.string,
    placeholder: T.oneOfType([T.number, T.string]),
    placeholderId: T.string,
    validationErrors: T.object,
    defaultValue: T.oneOfType([T.number, T.string]),
    options: T.arrayOf(
      T.shape({
        val: T.oneOfType([T.string, T.number]).isRequired,
        displayId: T.string,
        displayString: T.string
      })
    ),
    source: T.object,
    resource: IPT.map,
    allowMismatch: T.bool,
    toggleClassName: T.string,
    optionsClassName: T.string,
    className: T.string,
    selectionClassName: T.string
  }

  static defaultProps = {
    options: [],
    resource: imMap()
  }

  constructor(props) {
    super(props);

    const { entity, field } = props;

    this.state = {
      value: entity && (field in entity) ? entity[field] : null
    };
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.handlePageClick);

    const { field, labelId, defaultPropValue, allowMismatch, registerValidation, entity } = this.props;
    let validationRules = [...this.props.validationRules];

    if (!allowMismatch) {
      validationRules.push(this.isValidSelection);
    }

    if (registerValidation && validationRules.length) {
      registerValidation(field, labelId, validationRules);
    }

    if (!this.state.value) {
      if (entity && defaultPropValue) {
      // set initial selection, mapped to a specific property
      this.selectOptionValue(this.props.entity[defaultPropValue])
      } else if (this.props.defaultValue) {
        this.selectOptionValue(this.props.defaultValue);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { entity, defaultPropValue } = this.props;
    const { value, userEntry } = this.state;

    if (entity && defaultPropValue) {
      const nextValue = entity[defaultPropValue];
      if (!userEntry && value === prevProps.entity[defaultPropValue] && value !== nextValue) {
        // update selection based on a mapped property
        this.selectOptionValue(nextValue);
      }
      else if (userEntry && value === '' && nextValue === '') {
        // reset userEntry flag when both fields are empty, enables sync
        this.setState({ userEntry: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handlePageClick);
  }

  isValidSelection = this.isValidSelection.bind(this);
  isValidSelection(selection) {
    return (
      selection !== '' &&
      shouldBeInList(
        this.getOptions().map(({ val }) => val),
        'au.validation.notValid'
      )(selection)
    );
  }

  selectOptionValue(value) {
    const option = this.getOptions().find(o => o.displayString === value || o.val === value);
    if (option) {
      this.handleSelectionChange(option.val);
    } else {
      this.handleSelectionChange(value);
    }
  }

  getOptions() {
    const { options, source, resource } = this.props;

    if (!options.length && source) {
      return resource.map((obj, key) => {
        let displayString = source.textProperty && obj.get(source.textProperty) ||
          obj.get('name') ||
          obj.get('id') ||
          key;
        return {
          val: source.idProperty ? obj.get(source.idProperty) : key,
          displayString
        };
      }).valueSeq().toArray();
    }

    return options;
  }

  handleSelectionChange = this.handleSelectionChange.bind(this);
  handleSelectionChange(value) {
    const { fieldChangeCallback, field } = this.props;

    this.setState({ value });

    if (fieldChangeCallback) {
      fieldChangeCallback(field, value);
    }
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(value) {
    // set userEntry flag, disables sync
    this.setState({ userEntry: true, value });

    const { fieldChangeCallback, field } = this.props;

    if (fieldChangeCallback) {
      fieldChangeCallback(field, value);
    }
  }

  handlePageClick = this.handlePageClick.bind(this);
  handlePageClick() {
    this.setState({ showError: true });
  }

  render() {
    const { field, defaultValue, validationErrors, allowMismatch } = this.props;
    const { mismatchMessage, showErrors } = this.props;
    const { value } = this.state;
    const options = this.getOptions();
    const selection = this.state.value !== null ? value : defaultValue ? defaultValue : null;
    const showMismatchNotice = (
      allowMismatch && selection && options.findIndex(o => o.val === selection || o.displayString === selection) === -1
    );

    const validationError = validationErrors && (field in validationErrors) ? validationErrors[field] : null;

    return (
      //TODO - SHOULD THE ROW MOVE TO THE LAYOUT?
      <div className={fieldset.row}>
        <label className={fieldset.label}>
          <FormattedMessage id={this.props.labelId} />
        </label>
        <div className={fieldset.control}>
          <AuDropDown
            className={cn(fieldset.dropdown, this.props.className)}
            createMode={this.props.createMode}
            placeholder={this.props.placeholder}
            placeholderId={this.props.placeholderId}
            selection={selection}
            selectOption={this.handleSelectionChange}
            disabled={this.props.disabled}
            onChange={this.handleFieldChange}
            options={options}
            toggleClassName={this.props.toggleClassName}
            allowTyping={this.props.allowTyping}
            showError={validationError && (showErrors || this.state.showError)}
            error={validationError}
            optionsClassName={this.props.optionsClassName}
            hint={this.props.hint}
            defaultValue={this.props.defaultValue}
            selectionClassName={this.props.selectionClassName}
          />
          {showMismatchNotice && mismatchMessage &&
            <AutoIntl
              className={fieldset.notice}
              displayId={mismatchMessage.displayId}
              displayString={mismatchMessage.displayString}
              values={{
                entityName: <AutoIntl displayId={`au.entity.name.${mismatchMessage.entityType}`} />
              }}
              tag="div"
            />
          }
        </div>
      </div>
    );
  }
}

export class BusinessUnitInput extends React.Component {
  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    labelId: T.string,
    placeholder: T.string,
    placeholderId: T.string,
    validationErrors: T.object,
    defaultValue: T.string,
    options: T.arrayOf(
      T.shape({
        displayId: T.string,
        displayString: T.string
      })
    ),
    allowTyping: T.bool
  }

  state = { options: [] };
  accountsEndpoint = new TMC.services.Accounts({ apiVersion: "1" }).accounts;

  componentDidMount() {
    this.accountsEndpoint.settings().then(result => {
      const businessUnit = result.data.requiredTags.find((item) => item.key === 'BusinessUnit');
      if (businessUnit) {
        const options = businessUnit.values.map(val => {
          return { val, displayString: val };
        });
        this.setState({ options });
      }
    }).catch(r=>r);
  }

  render() {
    const validationRules = [...this.props.validationRules];

    if (this.state.options.length) {
      validationRules.push(required);
    }

    const hasOptions = this.state.options.length !== 0;

    return (<SelectInput
      {...this.props}
      validationRules={validationRules}
      allowMismatch={!hasOptions}
      key={this.props.field + this.state.options.length}
      options={this.state.options}
      allowTyping={hasOptions}
      createMode={!hasOptions ? false : this.props.createMode}
      disabled={!hasOptions}
    />);
  }
}

export class RegionInput extends React.Component {
  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    labelId: T.string,
    placeholder: T.string,
    placeholderId: T.string,
    validationErrors: T.object,
    defaultValue: T.string,
    options: T.arrayOf(
      T.shape({
        displayId: T.string,
        displayString: T.string
      })
    ),
    allowTyping: T.bool
  }

  state = { options: [] };
  accountsEndpoint = new TMC.services.Accounts({ apiVersion: "1" }).accounts;

  componentDidMount() {
    this.accountsEndpoint.settings().then(result => {
      const region = result.data.requiredTags.find((item) => item.key === 'Region');
      if (region) {
        const options = region.values.map(val => {
          return { val, displayString: val };
        });
        this.setState({ options });
      }
    }).catch(r=>r);
  }

  render() {
    const validationRules = [...this.props.validationRules];

    if (this.state.options.length) {
      validationRules.push(required);
    }

    const hasOptions = this.state.options.length !== 0;
    return (<SelectInput
      {...this.props}
      validationRules={validationRules}
      key={this.props.field + this.state.options.length}
      allowMismatch={!hasOptions}
      options={this.state.options}
      allowTyping={hasOptions}
      createMode={!hasOptions ? false : this.props.createMode}
      disabled={!hasOptions}
    />);
  }
}

export class FlowCreateInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      flows: [],
      options: [],
      lastKnownIdx: 0,
      foundSelection: false,
      flow: {}
    }
  }

  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    labelId: T.string,
    placeholder: T.oneOfType([T.number, T.string]),
    placeholderId: T.string,
    defaultValue: T.number,
    allowTyping: T.bool,
    selectionClassName: T.string
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(outputFlow) {
    const { fieldChangeCallback, field } = this.props;
    if (fieldChangeCallback) {
      fieldChangeCallback(field, outputFlow);
    }
  }

  render() {
    const { entity, showErrors, createMode, defaultValue, defaultPropValue } = this.props

    return (
      <div className={fieldset.row}>
        <label className={fieldset.label}>
          <FormattedMessage id={this.props.labelId} />
        </label>
        <div className={fieldset.control}>
          <FlowEditor
            onChange={this.handleFieldChange}
            showError={showErrors}
            inputFlow={entity?.inputFlow}
            createMode={createMode}
            defaultValue={defaultValue}
            defaultPropValue={defaultPropValue}
            idx={0}
            entity={entity}
          />
        </div>
        <div>
        </div>
      </div>
    )
  }
}

export class ShardCountInput extends React.Component {
  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    labelId: T.string,
    placeholder: T.oneOfType([T.number, T.string]),
    placeholderId: T.string,
    defaultValue: T.string,
    options: T.arrayOf(
      T.shape({
        displayId: T.string,
        displayString: T.string
      })
    ),
    allowTyping: T.bool,
    selectionClassName: T.string
  }

  state = { options: [] };


  componentDidMount() {
    const { field, labelId, registerValidation, validationRules } = this.props;

    if (registerValidation && validationRules) {
      registerValidation(field, labelId, validationRules);
    }

    const options = [
        {val: 12, displayString: formatMessage({id: `au.shardCount.12`}), className: fieldset.option_padding_first},
        {val: 24, displayString: formatMessage({id: `au.shardCount.24`}), className: fieldset.option_hint},
        {val: 48, displayString: formatMessage({id: `au.shardCount.48`}), className: fieldset.option_padding},
        {val: 96, displayString: formatMessage({id: `au.shardCount.96`}), className: fieldset.option_padding}
    ]
    this.setState({ options });
  }

  render() {
    const validationRules = [...this.props.validationRules];

    if (this.state.options.length) {
      validationRules.push(required);
    }

    return (<SelectInput
      {...this.props}
      options={this.state.options}
      optionsClassName={fieldset.number_options}
      className={fieldset.number_dropdown}
      hint={<AutoIntl key={this.state.options.length} displayId={'au.shardCount.hint'} className={fieldset.options_hint}/>}
      defaultValue={this.props.defaultValue}
      validationRules={validationRules}
      selectionClassName={fieldset.number_selection}
    />)
  }
}

export class OutputsInput extends React.Component {
  constructor(props) {
    super(props);

    const { entity, field } = props;
    this.storedTapFilters = entity && (field in entity) ? entity[field] : [];
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(tapFilters) {
    const { fieldChangeCallback, field } = this.props;

    if (fieldChangeCallback) {
      fieldChangeCallback(field, tapFilters);
    }
  }


  render() {
    const {
      labelId,
      entity,
      showErrors,
      createMode,
      hideSequenceColumn,
      validationModes,
      showOverride,
      maxOutputs,
      defaultValue,
      defaultPropValue,
      entityType,
      filterLimit,
      filterType } = this.props;

    const filterTypeOptions = Object.values(filterType == 'outputFilterType' ? outputFilterType : tapFilterType).filter(type => type !== outputFilterType.TAG).map(type => ({
      displayId: `au.taps.filters.${type}`,
      displayString: type,
      val: type
    }));

    return (
      <div className={fieldset.row}>
        <label className={fieldset.label}>
          <FormattedMessage id={labelId} />
        </label>
        <div className={fieldset.control}>
          <OutputEditor
            createMode={createMode}
            mode={entity ? TAP_FILTERS_MODES.EDIT : TAP_FILTERS_MODES.CREATE}
            entity={entity}
            filters={this.storedTapFilters}
            formatters={auFormatters}
            onChange={this.handleFieldChange}
            filterTypeOptions={filterTypeOptions}
            businessUnit={entity?.businessUnit}
            region={entity?.region}
            inputFlow={entity?.inputFlowId}
            showErrors={showErrors}
            validationModes={validationModes}
            hideSequenceColumn={hideSequenceColumn}
            showOverride={showOverride}
            maxOutputs={maxOutputs}
            defaultValue={defaultValue}
            defaultPropValue={defaultPropValue}
            entityType={entityType}
            filterLimit={filterLimit}
          />
        </div>
      </div>
    );
  }
}
export class ChipsInput extends React.Component {

  static propTypes = {
    entity: T.object,
    field: T.string,
    suggestions: T.array,
    fieldChangeCallback: T.func,
    labelId: T.string,
    validationRules: T.array
  }

  static defaultProps = {
    suggestions: []
  }

  constructor(props) {
    super(props);

    const { entity, field } = props;
    const chips = entity && (field in entity) ? entity[field] : [];
    // chips that are stored on the backend
    this.storedChips = chips.reduce((acc, chip) => {
      acc[chip] = true;
      return acc;
    }, {});

    this.state = {
      chips,
      validationRules: [],
      validationErrors: [],
    };
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(chips) {
    const { field, fieldChangeCallback, validationRules } = this.props;
    // space is not a valid first character, lets trim
    const trimmedChips = chips.map(c => c.trim());
    let validationErrors = {};

    if (validationRules) {
      validationErrors = run(trimmedChips, validationRules);
    }

    this.setState({
      chips: trimmedChips,
      validationErrors: validationErrors ? validationErrors.errors : []
    });

    if (fieldChangeCallback) {
      fieldChangeCallback(field, trimmedChips);
    }
  }

  render() {
    const { validationErrors } = this.state;
    const { suggestions, labelId, placeholderId } = this.props;

    const errors = validationErrors ? validationErrors : [];

    return (
      <div className={fieldset.row}>
        <label className={fieldset.label}>
          <FormattedMessage id={labelId} />
        </label>
        <div className={fieldset.control}>
          <AuChips
            chips={this.state.chips}
            storedChips={this.storedChips}
            errors={errors}
            suggestions={suggestions}
            onChange={this.handleFieldChange}
            placeholderId={placeholderId}
          />
        </div>
      </div>
    );
  }
}

export class DateTimeInput extends React.Component {

  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    labelId: T.string,
    timezone: T.string,
    createMode: T.bool,
    disabledDays: T.object,
    defaultValue: T.instanceOf(Date),
    validationRegistration: T.func,
    validationRules: T.array,
    validationErrors: T.object
  }

  constructor(props) {
    super(props);

    const { entity, field, defaultValue } = props;

    this.state = {
      value: entity && (field in entity) ? new Date(entity[field]) : defaultValue
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { entity, field, timezone } = props;

    if (state.value === undefined && field in entity) {
      return {
        value: moment.tz(entity[field], timezone).toDate()
      };
    }
    return null;
  }

  componentDidMount() {
    const { field, labelId, registerValidation, validationRules } = this.props;
    if (registerValidation && validationRules) {
      registerValidation(field, labelId, validationRules);
    }
  }

  handleFieldChange = (value) => {
    const { fieldChangeCallback, field } = this.props;

    this.setState({ value });

    if (fieldChangeCallback) {
      fieldChangeCallback(field, value);
    }
  }

  render() {
    const { value } = this.state;
    const { field, labelId, timezone, createMode, disabledDays, validationErrors } = this.props;

    const validationError = validationErrors && (field in validationErrors) ? validationErrors[field] : null;
    const showError = Boolean(validationError);

    return (
      <div className={fieldset.row}>
        <label className={fieldset.label}>
          <FormattedMessage id={labelId} />
        </label>
        <div className={fieldset.control}>
          <AuDateTimeInput
            name={field}
            value={value}
            timezone={timezone}
            createMode={createMode}
            disabledDays={disabledDays}
            onChange={this.handleFieldChange}
            showError={showError}
            error={validationError}
          />
        </div>
      </div>
    );
  }

}

export class TagsInput extends React.Component {

  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    labelId: T.string,
    validationRules: T.array,
    labelHintId: T.string
  }

  constructor(props) {
    super(props);

    const { entity, field } = props;
    this.storedTags = entity && (field in entity) ? entity[field] : {};

  }

  componentDidMount() {
    const { field, labelId, registerValidation, validationRules } = this.props;

    if (registerValidation && validationRules) {
      registerValidation(field, labelId, validationRules);
    }
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(tags) {
    const { fieldChangeCallback, field } = this.props;

    if (fieldChangeCallback) {
      fieldChangeCallback(field, tags);
    }
  }

  render() {
    const { labelId, validationRules, labelHintId } = this.props;

    return (
      <div className={fieldset.row}>
        <label className={cn(fieldset.label, { [fieldset.label_with_hint]: labelHintId })}>
          <FormattedMessage id={labelId} />
          {labelHintId && <span className={fieldset.hint}>
            {formatMessage({ id: labelHintId })}
          </span>}
        </label>
        <div className={fieldset.control}>
          <TagEditor
            tags={this.storedTags}
            onChange={this.handleFieldChange}
            validationRules={validationRules}
          />
        </div>
      </div>
    );
  }

}
export class KeyValueInput extends React.Component {

  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    labelId: T.string,
    supportedProperties: T.array,
    max: T.number
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(properties) {
    const { fieldChangeCallback, field } = this.props;
    if (fieldChangeCallback) {
      fieldChangeCallback(field, properties);
    }
  }

  render() {
    const { labelId, supportedProperties, entity, createMode, max } = this.props;

    let assignedProperties;
    let assignedEntries;
    if (entity?.properties) {
      assignedProperties = Object.keys(entity.properties);
      assignedEntries = Object.entries(entity.properties);
    }
    let assignedKeys = [];
    let propertyValue = [];
    let unassignedOptions = [];
    let assignedSupportedEntries = [];

    assignedEntries?.forEach(property => {
      if (supportedProperties.includes(property[0])) {
        assignedKeys.push({ val: property[0], displayString: property[0] });
        propertyValue.push({ val: property[1], displayString: property[1] });
        assignedSupportedEntries.push({ key: property[0], val: property[1] });
      }
    });

    supportedProperties.forEach(property => {
      if (assignedProperties && !assignedProperties.includes(property)) {
        unassignedOptions.push({ val: property, displayString: property });
      } else {
        unassignedOptions.push({ val: property, displayString: property });
      }
    });

    return (
      <div className={fieldset.row}>
        <label className={fieldset.label}>
          <FormattedMessage id={labelId} />
        </label>
        <div className={fieldset.control}>
          <KeyValueEditor
            createMode={createMode}
            keys={assignedKeys}
            values={propertyValue}
            keyPlaceholderId={'au.vehicles.properties.namePlaceholder'}
            valuePlaceholderId={'au.vehicles.properties.valuePlaceholder'}
            addMoreDisplayId={'au.addProperty'}
            options={unassignedOptions}
            entries={assignedSupportedEntries}
            removeBtnDisplayId={'au.vehicles.properties.remove'}
            onChange={this.handleFieldChange}
            max={max}
          />
        </div>
      </div>
    );
  }

}
export class PolicyStatementsInput extends React.Component {

  constructor(props) {
    super(props);
    const { entity, field } = props;
    this.storedStatements = entity && (field in entity) ? entity[field] : {};
  }

  componentDidMount() {
    const { field, labelId, registerValidation } = this.props;
    // delegate field validation to sub fields
    registerValidation(field, labelId, [this.validStatements]);
  }

  validStatements = this.validStatements.bind(this);
  validStatements(statements) {
    const errors = {};

    for (let [id, { validationErrors }] of Object.entries(statements)) {
      if (!isEmpty(validationErrors)) {
        errors[id] = validationErrors;
      }
    }

    return isEmpty(errors) ? null : errors;
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(statements) {
    const { fieldChangeCallback, field } = this.props;

    if (fieldChangeCallback) {
      fieldChangeCallback(field, statements);
    }
  }

  render() {
    const { field, labelId, entity, policyType, createMode, showErrors, validationErrors } = this.props;
    const errors = validationErrors && (field in validationErrors) ? validationErrors[field] : {};

    return (
      <div className={fieldset.row}>
        <label className={fieldset.label}>
          <FormattedMessage id={labelId} />
        </label>
        <div className={fieldset.control}>
          <PolicyStatementEditor
            policyType={policyType}
            createMode={createMode}
            statements={this.storedStatements}
            subjectAui={entity.subjectAui}
            resourceAui={entity.resourceAui}
            onChange={this.handleFieldChange}
            showErrors={showErrors}
            errors={errors}
          />
        </div>
      </div>
    );
  }

}

export class EntitlementsInput extends React.Component {

  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    labelId: T.string,
    createMode: T.bool,
    registerValidation: T.func,
    validationRules: T.array,
    validationErrors: T.object
  }

  constructor(props) {
    super(props);

    const { entity, field } = props;
    this.storedEntitlements = entity && (field in entity) ? entity[field] : [];
  }

  componentDidMount() {
    const { field, labelId, registerValidation, validationRules } = this.props;
    if (registerValidation && validationRules) {
      registerValidation(field, labelId, validationRules);
    }
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(tags) {
    const { fieldChangeCallback, field } = this.props;

    if (fieldChangeCallback) {
      fieldChangeCallback(field, tags);
    }
  }

  render() {
    const { entity, field, labelId, createMode, validationErrors, showErrors } = this.props;
    const error = validationErrors && (field in validationErrors) ? validationErrors[field] : null;
    const { resourceAui, subjectAui } = entity;

    return (
      <div className={fieldset.row}>
        <label className={fieldset.label}>
          <FormattedMessage id={labelId} />
        </label>
        <div className={fieldset.control}>
          <EntitlementEditor
            createMode={createMode}
            entitlements={this.storedEntitlements}
            resourceAui={resourceAui}
            subjectAui={subjectAui}
            onChange={this.handleFieldChange}
            showError={showErrors}
            error={error}
          />
        </div>
      </div>
    );
  }

}

export class UserGroupsInput extends React.Component {

  static propTypes = {
    registerValidation: T.func,
    validationRules: T.array,
    validationErrors: T.object,
    fieldId: T.string,
    field: T.string,
    entity: T.object,
    fieldChangeCallback: T.func,
    labelId: T.string,
    createMode: T.bool,
    showErrors: T.bool
  }

  componentDidMount() {
    const { field, labelId, registerValidation, validationRules } = this.props;
    if (registerValidation && validationRules) {
      registerValidation(field, labelId, validationRules);
    }
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(members) {
    const { fieldChangeCallback, field } = this.props;

    if (fieldChangeCallback) {
      fieldChangeCallback(field, members);
    }
  }

  render() {
    const { entity, field, labelId, createMode, validationErrors, showErrors } = this.props;
    const error = validationErrors && (field in validationErrors) ? validationErrors[field] : null;
    return (
      <div className={fieldset.row}>
        <label className={fieldset.label}>
          <FormattedMessage id={labelId} />
        </label>
        <div className={fieldset.control}>
          <UserGroupMembersEditor
            field={field}
            showError={showErrors}
            createMode={createMode}
            values={entity[field] || []}
            error={error}
            onChange={this.handleFieldChange}
          />
        </div>
      </div>
    );
  }
}

export function FormattedChildrenInput({ labelId, children }) {
  return (
    <div className={fieldset.row}>
      <label className={fieldset.label}>
        <FormattedMessage id={labelId} />
      </label>
      <div className={fieldset.control}>
        <div className={fieldset.mock_input}>
          {children}
        </div>
      </div>
    </div>
  );
}

export class MultiEntryInput extends React.Component {

  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    labelId: T.string,
    createMode: T.bool,
    registerValidation: T.func,
    validationRules: T.array,
    validationErrors: T.object
  }

  constructor(props) {
    super(props);

    const { entity, field } = props;
    this.storedEntries = entity && (field in entity) ? entity[field] : [];
  }

  componentDidMount() {
    const { field, labelId, registerValidation, validationRules } = this.props;
    if (registerValidation && validationRules) {
      registerValidation(field, labelId, validationRules);
    }
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(tags) {
    const { fieldChangeCallback, field } = this.props;

    if (fieldChangeCallback) {
      fieldChangeCallback(field, tags);
    }
  }

  render() {
    const { field, labelId, entryLabelId, createMode, validationErrors, showErrors } = this.props;
    const error = validationErrors && (field in validationErrors) ? validationErrors[field] : null;

    return (
      <div className={fieldset.row}>
        <label className={fieldset.label}>
          <FormattedMessage id={labelId} />
        </label>
        <div className={fieldset.control}>
          <MultiEntryEditor
            createMode={createMode}
            entries={this.storedEntitlements}
            onChange={this.handleFieldChange}
            showErrors={showErrors}
            error={error}
            entryLabelId={entryLabelId ?? labelId}
          />
        </div>
      </div>
    );
  }

}
