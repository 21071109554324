import React from "react";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import { wrapActionWithTracking } from "../../../utils/analyticsHelpers";
import { BUTTON_TYPE_SECONDARY } from "@au/core/lib/components/elements/AuButton";
import ConfirmationDialog from "@au/core/lib/components/objects/ConfirmationDialog";
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';
import { history as browserHistory} from "../../../history";
import EmbeddedDeviceLookup from "./EmbeddedDeviceLookup";
import DefaultList from "../List";

import customStyles from "../../../css/components/device_view.module.scss";

export default class DeviceList extends DefaultList {

  getRowActions({id, canWrite, canDuplicate, permissions}) {
    const actions = super.getRowActions({id, canWrite, canDuplicate, permissions});

    const entity = this.props.entities.get(id);
    if(!entity){
      return actions
    }

    const binded = entity.hasIn(['relations', 'binding']);

    actions.splice(1, 0, wrapActionWithTracking({
      key: `${binded ? 'un' : ''}bind_device`,
      type: BUTTON_TYPE_SECONDARY,
      // className: styles.button,
      displayId: `au.device.${binded ? 'un' : ''}bindDevice`,
      onClick: () => binded ? this.onUnbindBtnClick(id) : this.onBindBtnClick(id)
    }, 'Device', 'List'));

    return actions;
  }

  onBindBtnClick = this.onBindBtnClick.bind(this);
  onBindBtnClick(entityId) {
    //FIXME - MOVE TO linkHelper
    browserHistory.push({
      pathname: this.baseUrl + `/${entityId}/bind`,
      state: {
        prevUrl: this.props.match.url
      }
    });
  }

  onUnbindBtnClick = this.onUnbindBtnClick.bind(this);
  onUnbindBtnClick(entityId) {
    this.setState({showUnbindDialog: true, unbindEntityId: entityId});
  }

  hideUnbindDialog = this.hideUnbindDialog.bind(this);
  hideUnbindDialog() {
    this.setState({showUnbindDialog: false, unbindEntityId: undefined});
  }

  unbindDevice = this.unbindDevice.bind(this);
  unbindDevice() {
    const {endpoint} = this.props;

    endpoint.unbind(this.state.unbindEntityId).then(
      this.onDeviceUnbindSuccess,
      createResponseAlertMessage
    );
  }

  onDeviceUnbindSuccess = this.onDeviceUnbindSuccess.bind(this);
  onDeviceUnbindSuccess() {
    this.list();
    this.setState({showUnbindDialog: false, unbindSucceeded: null});
  }

  renderUnbindDialog() {
    const {showUnbindDialog, unbindSucceeded} = this.state;

    if (!showUnbindDialog) {
      return false;
    }

    return (
      <ConfirmationDialog
        key="device_unbind_dialog"
        type="alert"
        titleId="au.device.unbind.title"
        confirmDisplayId="au.device.unbind.confirm"
        className={customStyles.unbind_dialog}
        headerClassName={customStyles.header}
        succeded={unbindSucceeded}
        onConfirm={this.unbindDevice}
        onCancel={this.hideUnbindDialog}
      >
        <AutoIntl displayId="au.device.unbind.message1" tag="div"/>
        <AutoIntl displayId="au.device.unbind.message2" tag="div"/>
      </ConfirmationDialog>
    );
  }

  renderDialogs() {
    return (
      <>
        {super.renderDialogs()}
        {this.renderUnbindDialog()}
      </>
    );
  }

  renderContent() {
    return (
      <>
        <EmbeddedDeviceLookup
          endpoint={this.props.endpoint}
          entityDef={this.props.entityDef}
          match={this.props.match}
        />
        {super.renderContent()}
      </>
    );
  }
}