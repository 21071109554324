/**
 * Constructs a runner function and runs the specified validations.
 * @param {string} field Field to be validated
 * @param {string} displayId Localized field displayId
 * @param {object} ...validations List of validation functions to process
 */
export function ruleRunner(field, displayId, ...validations) {
  return (state) => {
    for (let v of validations) {
      let errorMsgSpec = v(field in state ? state[field] : '', state);
      if (errorMsgSpec) {
        return {[field]: Object.assign(errorMsgSpec, {'fieldDisplayId': displayId})};
      }
    }
    return null;
  };
}



/**
 * Calls all the validation rule runners and aggregates their results into a
 * single object.
 * @param {object} state Current state
 * @param {object} runners Array of ruleRunner
 */
export function run(state, runners) {
  return runners.reduce((memo, runner) => {
    return Object.assign(memo, runner(state));
  }, {});
}
