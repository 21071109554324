import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import cloneDeep from 'lodash.clonedeep';
import AuComponent from '@au/core/lib/components/elements/AuComponent';
import { formatMessage } from '../utils/reactIntl';
import { ScreenWidthContext} from '../contexts/screenWidthContext';
import tableDefs from '../tableDefs';

import SimpleTable, { Row, Cell, HeaderCell } from './SimpleTable';

import styles from '../css/components/tap_filters.module.scss';

class InputOutputTable extends AuComponent {

  static propTypes = {
    values: T.array,
    resources: T.instanceOf(Map),
    className: T.string,
    screenWidth: T.string.isRequired
  }

  static defaultProps = {
    values: [],
    resources: new Map(),
  }

  componentDidMount() {
    this.setState({ values: this.props.values });
  }

  componentDidUpdate(prevProps) {
    const { screenWidth } = this.props;

    if (screenWidth == prevProps.screenWidth || this.props.values !== prevProps.values) {
      this.setState({ values: this.props.values });
    }
  }

  get columnDefs() {
    let columnDefs;
    columnDefs = cloneDeep(tableDefs.flowTableView.columnDefs);
    return columnDefs;
  }

  renderHead() {
    const content = [];
    this.columnDefs.forEach(col => {
        content.push(
          <HeaderCell key={`th_${col.property}`} width={col.width} className={cn(styles.cell, styles.flow_table_header_cell)}>
            {formatMessage({ id: col.labelId })}
          </HeaderCell>
        );
    });

    return <Row className={styles.header_row}>{content}</Row>;
  }

  renderContent(index, col, rowData) {
    let value = rowData[col.property] || '';
    return <div className={styles.inner}>{value}</div>;
  }

    renderRows() {
      const rows = [];
  
      this.props.values.forEach((rowData, index) => {
        const cells = [];
  
        this.columnDefs.forEach(col => {
          if (col.display !== false) {
            cells.push(
              <Cell key={`td_${rowData.id}_${col.property}`} className={styles.cell} width={col.width}>
                {this.renderContent(index, col, rowData)}
              </Cell>
            );
          }
        });
  
        rows.push(
          <Row
            key={`tr_${rowData.id}_${rowData.filterType}_${index}`}
            className={styles.row}
          >{cells}</Row>
        );
      });
  
      return rows;
    }


  render() {
    const { className } = this.props;
    return (
      <div className={cn(styles.container, className)}>
        <SimpleTable className={cn(styles.table, [styles.view])}>
          {this.renderHead()}
          {this.renderRows()}
        </SimpleTable>
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => {
  return (
    <ScreenWidthContext.Consumer>
      {context => <InputOutputTable {...props} screenWidth={context} ref={ref} />}
    </ScreenWidthContext.Consumer>
  );
});