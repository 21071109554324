
import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import styles from '../css/components/bound_state.module.scss';

export default class BoundState extends React.Component {
  static propTypes = {
    bindings: T.oneOfType([IPT.list, IPT.map])
  }

  render() {
    const { bindings } = this.props;
    const isBound = bindings && bindings.size;

      return (
        <AutoIntl
          displayId={`au.entity.${isBound ? 'bound' : 'unbound'}`}
          className={isBound ? styles.bound : styles.unbound}
          tag="div" />
      );
  }
}
