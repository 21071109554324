import TMC from "@autonomic/browser-sdk";

import { history as browserHistory } from '../../../history';
import DefaultCreate from "../Edit";

export default class CommandManagementCreate extends DefaultCreate {
  caasService = new TMC.services.Command({ apiVersion: "1-beta" });
  definitionsEndpoint = this.caasService.definitions;
  payloadEndpoint = this.caasService.payloads;

  handleOnSave = this.handleOnSave.bind(this);
  handleOnSave(modifiedEntity) {
    return this.definitionsEndpoint.forceSave({
      type: modifiedEntity?.type,
      fsmName: modifiedEntity?.fsmName,
      version: modifiedEntity?.version,
      description: modifiedEntity?.description
    }).then(({ data }) => {
      browserHistory.push(`${this.baseUrl}/${encodeURIComponent(`${data.id}`)}/define`);
    }).catch(( err ) => {
      const e = {data: { error: '', message: this.getErrorMessage(err._data) }};
      this.genericErrorHandler(e);
    })
  }

  getErrorMessage = ( errData ) => {
    return (errData.details && errData.details[0]) ? errData.details[0].message : errData.message;
  }

  getActionTextId() {
    return 'au.entity.register';
  }

  renderForm() {
    if (this.state.hideForm) {
        return false;
    }
    
    return super.renderForm();
  }
}
