import React from "react";
import ReactDOM from "react-dom";
import cn from "classnames";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import { NavLink } from "react-router-dom";
import * as T from 'prop-types';
import styles from "../css/components/entity_subview_sidebar.module.scss";

export default class SidebarSubviews extends React.Component {

  static propTypes = {
    navLinks: T.arrayOf(T.object).isRequired,
    portalRef: T.object,
    tableLoaded: T.bool,
    open: T.bool,
    setOpen: T.func,
    isMobile: T.bool
  }

  static defaultProps = {
    tableLoaded: true
  }


  componentDidMount(){
    const activeTab = document.querySelector(`.${styles.selected}`);
    if(activeTab?.getBoundingClientRect().bottom > window.innerHeight) {
      activeTab.scrollIntoView();
    }
  }

  renderContent() {
    const { navLinks, open, setOpen } = this.props;

    if (!open) {
      return (
        <button
          className={cn(styles.sidebar_subviews, {[styles.closed]: !open})}
          onClick={() => setOpen(true)}
        >
          <div className={styles.close_icon}/>
        </button>
      );
    }

    return <div className={cn(styles.sidebar_subviews, { [styles.closed]: !open })}>
      <div className={styles.sidebar_subviews_table}>
        <AutoIntl
          className={styles.subview_header}
          displayId="au.sidebarSubview"
          tag="button"
          onClick={() => setOpen(false)}
        />
        {navLinks && navLinks.length > 0 &&
          navLinks.sort(function (a, b) {
            if (a.labelId < b.labelId) {
              return -1;
            }
            if (a.labelId > b.labelId) {
              return 1;
            }
            return 0;
          }) &&
          navLinks.map(navLink => {
            return (
              <NavLink
                key={navLink.labelId}
                to={navLink.destination}
                className={cn(navLink.classNames, { [styles.section_end]: navLink.isEndOfSection })}
                activeClassName={styles.selected}>
                <AutoIntl
                  displayId={navLink.labelId}
                  tag="span"
                />
              </NavLink>
            );
          })
        }
      </div>
    </div>;
  }

  render() {
    const {isMobile, portalRef, navLinks} = this.props;

    if (navLinks.length <= 1) {
      return null;
    }

    if (isMobile && portalRef?.current) {
      return ReactDOM.createPortal(this.renderContent(), portalRef.current);
    }
    return this.renderContent();
  }
}
