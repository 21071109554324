import React from 'react';
import * as T from 'prop-types';
import { fromJS } from 'immutable';
import TreeView from '@au/core/lib/components/elements/TreeView';
import { formatMessage } from '../utils/reactIntl';
import StateIcon from './StateIcon';

import styles from '../css/components/publishing_destination.module.scss';

export default class PublishingDefinition extends React.Component {

  static propTypes = { value: T.object }

  render() {
    const { value } = this.props;

    let definitions = {};

    value.toJS().forEach(item => {
      let aui = formatMessage({ id: `au.${item.publishingDestinationAui}` });
      delete item['publishingDestinationAui']
      if (item.enabled == false) {
        item.enabled = <StateIcon icon='invalid' displayId='au.false' className={styles.false} />
      } else if (item.enabled == true) {
        item.enabled = <StateIcon icon='enabled' displayId='au.true' className={styles.true} />
      }
      let destination;
      if (item.gcpPubSubDestination) {
        destination = formatMessage({ id: 'au.GCP' });
        item.customClassName = styles.gcp;
        item[destination] = item.gcpPubSubDestination;
        delete item.gcpPubSubDestination;
      } else if (item.kafkaDestination) {
        destination = formatMessage({ id: 'au.Kafka' });
        item.customClassName = styles.kafka
        item[destination] = item.kafkaDestination;
        delete item.kafkaDestination;
      }
      definitions[aui] = item;
    })

    return <TreeView
      labelClassName={styles.first_key}
      data={fromJS(definitions)}
      expandFirstItem={true}
      className={styles.container}
    />;
  }
}