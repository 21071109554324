import WrappedMap from './wrappedMap';


// const indexedMapHandler = {
//   get(iMap, prop) {
//     if (prop in iMap) {
//       return iMap[prop];
//     }
//     if (prop in iMap._map) {
//       if (typeof iMap._map[prop] === 'function') {
//         // needed because native code cannot be called with `this` being the proxy object
//         return iMap._map[prop].bind(iMap._map);
//       }
//       return iMap._map[prop];
//     }
//     let index;
//     try {
//       index = parseInt(prop, 10);
//     } catch (e) { /**/ }
//     if (Number.isInteger(index)) {
//       return iMap.getValueAt(index);
//     }
//     return iMap[prop];
//   }
// };

export default class IndexedMap extends WrappedMap {
  constructor(...args) {
    super(...args);
    // return new Proxy(this, indexedMapHandler);
  }

  clear() {
    this._index = null;
    return super.clear();
  }

  delete(key) {
    this._index = null;
    return super.delete(key);
  }

  set(key, value) {
    this._index = null;
    return super.set(key, value);
  }

  get index() {
    if (!this._index) {
      this._index = [...this.entries()];
    }
    return this._index;
  }

  getValueAt(index) {
    return this.index[index][1];
  }

  getKeyAt(index) {
    return this.index[index][0];
  }
}
