import React from "react";

import { createResponseAlertMessage } from "@au/core/lib/components/objects/AlertMessage";

import { generateEntityPath } from "../../../utils/entity";
import { wrapActionWithTracking } from '../../../utils/analyticsHelpers';
import StartDialog from "../StartDialog";
import DefaultList from '../List';

export default class ForkList extends DefaultList {

  state = {
    id: undefined,
    showStartDialog: false
  }

  updateFork = this.updateFork.bind(this);
  updateFork(resp, fieldPath=[]) {
    const { endpoint, serviceAlias, actions } = this.props;
    const path = [...generateEntityPath(endpoint, serviceAlias), this.state.id, ...fieldPath];
    return actions.patchEntitySuccess({ path, data: {state: resp.data.state} });
  }

  confirmStart = this.confirmStart.bind(this);
  confirmStart(selection) {
    const {endpoint} = this.props;
    const {id} = this.state;

    endpoint.start(id, selection)
      .then(
        this.updateFork,
        createResponseAlertMessage
      ).then(() => this.setState({showStartDialog: false, id: undefined}));
  }

  renderDialogs() {
    const dialogs = super.renderDialogs();
    const { entityDef } = this.props;
    dialogs.push(
      <StartDialog
        key={"start-fork-dialog"}
        show={this.state.showStartDialog}
        onCancel={() => this.setState({showStartDialog: false})}
        onConfirm={this.confirmStart}
        titleDisplayId={"au.fork.start"}
        confirmDisplayId={"au.fork.start"}
        headerDisplayId={"au.fork.start"}
        messageDisplayId={"au.fork.message"}
        entityType={entityDef.type}
      />
    );
    return dialogs;
  }

  pauseForkBtn(id) {
    this.setState({ id });
    return this.props.endpoint.pause(id)
      .then(this.updateFork, createResponseAlertMessage)
      .then(() => this.setState({id: undefined}));
  }

  startForkBtn(id) {
    this.setState({ showStartDialog: true, id });
    return this.renderDialogs;
  }

  getRowActions({id, canWrite, permissions, ...otherProps}) {
    const forkState = this.props.entities.getIn([id, "state"]);
    const actions = super.getRowActions({id, canWrite, permissions, ...otherProps});

    const pauseFork = wrapActionWithTracking(
      {
        key: 'pause_fork',
        displayId: 'au.fork.pause',
        onClick: this.pauseForkBtn.bind(this, id)
      },
      'Fork',
      'View'
    );
    const startFork = wrapActionWithTracking(
      {
        key: 'start_fork',
        displayId: 'au.fork.start',
        onClick: this.startForkBtn.bind(this, id)
      },
      'Fork',
      'View'
    );

    if (forkState === 'RUNNING' || forkState === 'REPLAY') {
      actions.splice(1, 0, pauseFork);
    }
    else if (forkState === 'PAUSED') {
      actions.splice(1, 0, startFork);
    }

    if (forkState === 'REPLAY') {
      const index = actions.findIndex(element => element.key === 'entity_edit_btn');
      if (index > -1) {
        actions.splice(index, 1);
      }
    }

    return actions;
  }
}
