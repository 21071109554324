import React from 'react';
import * as T from 'prop-types';
import { FormattedMessage } from 'react-intl';
import entries from 'lodash/entries';

import CheckListItem from '@au/core/lib/components/elements/CheckListItem';

import styles from '../css/components/selection_summary.module.scss';

// TODO: update with graphics from Helen
//  We need SVGs that are transparent or just make our own font
class InvalidListItem extends React.PureComponent {
    static propTypes = {
    onClick: T.func.isRequired,
    val: T.string,
    msg: T.string,
    display: T.string,
    displayId: T.string
  };

  renderLi(display, onClick) {
    // Would use the <svg> <use ... /></svg> tags, but MDN reports IE does not support this
    // Otherwise we have to use the current color in the SVG and cannot modify with CSS
    return <li className={styles.invalid}>
      <span className={styles.icon} onClick={onClick} />
      <span className={styles.label}>{display}</span>
    </li>;
  }

  render() {
    const { displayId, display, onClick, val } = this.props;

    if (displayId) {
      return <FormattedMessage id={displayId} children={msg => this.renderLi(msg, onClick)} />;
    } else {
      return this.renderLi(display || val, onClick);
    }
  }
}

export default class SelectionSummary extends React.Component {

  commonListItemProps(categoryDisplayId, val, optionData) {
    return {
      key: val,
      val,
      checked: true, // always checked, otherwise it will be removed
      ...optionData
    };
  }

  render() {
    const categories = [];
    for (let [categoryDisplayId, categoryData] of entries(this.props.allOptions)) {
      const listItems = [];

      for (let [val, optionData] of entries(categoryData.validOptions)) {
        listItems.push(
          <CheckListItem
            onClick={() => this.props.removeSelection(categoryDisplayId, val)}
            {...this.commonListItemProps(categoryDisplayId, val, optionData)}
          />
        );
      }

      for (let [val, optionData] of entries(categoryData.invalidOptions)) {
        listItems.push(
          <InvalidListItem
            onClick={() => this.props.removeSelection(categoryDisplayId, val)}
            {...this.commonListItemProps(categoryDisplayId, val, optionData)}
          />
        );
      }

      if (listItems.length) {
        categories.push(
          <div key={categoryDisplayId}>
            <FormattedMessage id={categoryDisplayId} children={msg => <h3>{msg}</h3>} />
            <ul className="o-list-bare">{listItems}</ul>
          </div>
        );
      }
    }

    return <div className={styles.container} ref={container => this.container = container}>
      {categories}
    </div>;
  }

  componentDidUpdate() {
    // Determine if the height should be changed because we are overflowing
    if (this.container.scrollHeight > this.container.clientHeight) {
      const yDiff = this.container.scrollHeight - this.container.clientHeight;
      this.container.style.height = (this.container.clientHeight + yDiff) + 'px';
    }

    // The non-desktop version likes to scroll along the x-axis
    let xDiff = this.container.scrollWidth - this.container.clientWidth;
    let safteyCheck = 0;
    while (xDiff > 0 && safteyCheck < 3000) {
      this.container.style.height = (this.container.clientHeight + 40) + 'px';
      xDiff = this.container.scrollWidth - this.container.clientWidth;
      ++safteyCheck;
    }
  }

  componentDidMount() {
    this.componentDidUpdate(); // So we set the summy height correctly when initialized with a lot of filters
  }

  static propTypes = {
    allOptions: T.objectOf(
      T.shape({
        validOptions: T.objectOf(
          T.shape({
            display: T.string,
            displayId: T.string,
            subDisplay: T.string,
            subDisplayId: T.string
          })
        ),
        invalidOptions: T.objectOf(
          T.shape({
            display: T.string,
            displayId: T.string,
            subDisplay: T.string,
            subDisplayId: T.string
          })
        )
      })
    ).isRequired,
    removeSelection: T.func
  }
}
