import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import { fromJS } from 'immutable';
import cloneDeep from 'lodash.clonedeep';

import TMC from '@autonomic/browser-sdk';
import AuButton from '@au/core/lib/components/elements/AuButton';
import AuComponent from '@au/core/lib/components/elements/AuComponent';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuToggle, { TOGGLE_SIZE_MEDIUM, TOGGLE_TYPE_PRIMARY } from '@au/core/lib/components/elements/AuToggle';
import TreeView from '@au/core/lib/components/elements/TreeView';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { NOOP, SERVICE_NAMES, tapFilterType, TAP_FILTERS_MODES } from '../constants';
import tableDefs from '../tableDefs';
import { formatMessage } from '../utils/reactIntl';
import { formatFilter } from '../components/utils/taps';
import EntityLookup from '../containers/EntityLookup';
import { history as browserHistory } from '../history';
import loadingIcon from '../images/spinner_white.gif';
import { trackEvent, trackableElements, trackableActions } from '../utils/analyticsHelpers';
import { ScreenWidthContext} from '../contexts/screenWidthContext';
import apiFactory, { enhanceSdkEndpoint } from '../utils/api';
import TapFilterTypeSelector from './TapFilterTypeSelector';
import ForkFilterTypeSelector from './ForkFilterTypeSelector';
import TapFilterDetailsEditor from './TapFilterDetailsEditor';
import VinEditor from './VinEditor';
import SimpleTable, { Row, DraggableRow, Cell, HeaderCell, DragHandle } from './SimpleTable';
import EntityLink from './EntityLink';
import TapValidationMessage from './TapValidationMessage';
import ValidationMessage from './ValidationMessage';

import styles from '../css/components/tap_filters.module.scss';

const PERMISSION_PERMIT = 'PERMIT';
const PERMISSION_DENY   = 'DENY';

const tapFilterTypes = Object.values(tapFilterType);

class TapFilters extends AuComponent {

  static modes = [TAP_FILTERS_MODES.VIEW, TAP_FILTERS_MODES.EDIT, TAP_FILTERS_MODES.CREATE]

  static propTypes = {
    filters: T.array,
    denyAttributeTags: T.bool,
    resources: T.instanceOf(Map),
    formatters: T.object,
    mode: T.oneOf(TapFilters.modes),
    className: T.string,
    hideValidationMessage: T.bool,
    onCancelNewFilter: T.func,
    onAddVins: T.func,
    onDeleteVins: T.func,
    onChange: T.func,
    onDelete: T.func,
    onCreate: T.func,
    screenWidth: T.string.isRequired,
    registerClearFn: T.func,
    registerAddNewFn: T.func,
    rowLimit: T.number,
    validationModes: T.array.isRequired
  }

  static defaultProps = {
    mode: TAP_FILTERS_MODES.VIEW,
    filters: [],
    resources: new Map(),
    formatters: {},
    onCancelNewFilter: NOOP,
    onAddVins: NOOP,
    onDeleteVins: NOOP,
    onChange: NOOP,
    onCreate: NOOP,
    onDelete: NOOP
  }

  // counter is used to uniqely identify rows as a part of their keys
  state = { counter: 0 }

  formatters = {
    ...this.props.formatters,
    tapFilterType: ({ value, rowData, property, index }) => {
      const { filters, vinEditor } = this.state;
      const disabled = Boolean(vinEditor) || filters.some(f => f.confirm || f.deleted || f.saving);
      let filterType;

      if (this.props.validationModes.length == 1) {
        if (rowData.isNew) {
          filterType = (
            <ForkFilterTypeSelector 
              createMode={true}
              selection={value}
              disabled={disabled}
              className={styles.filter_type}
              onChange={selection => this.updateFilter(index, { [property]: selection })}
              filterTypeOptions={this.props.filterTypeOptions}
            />
          )
        }
        else {
          filterType = formatMessage({ id: `au.taps.filters.${value}` })
        }
      }
      else {
        if (rowData.isNew) {
          filterType = (
            <TapFilterTypeSelector
              createMode={true}
              selection={value}
              disabled={disabled}
              onChange={value => this.updateFilter(index, { [property]: value })}
              filterTypeOptions={this.props.filterTypeOptions}
            />
          );
        } else {
          filterType = formatMessage({ id: `au.taps.filters.${value}` });
        }
      }

      return filterType;
    },
    tapFilterDetails: ({ value, rowData, property, index }) => {
      const { isNew, filterType } = rowData;
      let tapDetails;

      if (isNew && filterType) {
        tapDetails = (
          <TapFilterDetailsEditor
            createMode={false}
            details={value}
            filterType={filterType}
            resources={this.props.resources}
            onChange={value => this.updateFilter(index, { [property]: value })}
            showVinLimit={this.props.showVinLimit}
          />
        );
      } else if (value) {
        const { filters, vinEditor } = this.state;
        let { fancyKeyValue, localizeKeys } = this.props.formatters;
        const disabled = (
          Boolean(vinEditor) ||
          filters.some(f => f.confirm || f.deleted || f.saving || f.isNew && f.filterType)
        );

        tapDetails = (
          <React.Fragment>
            {filterType === tapFilterType.GROUP && <div>
              <AutoIntl displayId="au.taps.filter.details.name" tag="span" />:{' '}
              <EntityLookup
                endpoint={this.groupEndpoint}
                entityId={value.groupId}
                lookupField={'displayName'}
                serviceAlias={SERVICE_NAMES.INVENTORY}
              />
            </div>}
            {fancyKeyValue({ value: localizeKeys({ prefix: 'au.taps.filter.details', value }) })}
            { filterType === tapFilterType.VIN && (vinEditor || {}).index !== index &&
              <div className={styles.vin_actions}>
                <AuButton
                  type="tertiary"
                  size="medium"
                  className={styles.add_vins}
                  disabled={disabled}
                  displayId="au.taps.filters.addVins"
                  onClick={this.showVinEditor.bind(this, index, 'add')}
                  tracking={{
                    element: trackableElements.BUTTON,
                    action: 'AddVins',
                    page: 'ManageFilters'
                  }}
                />
                <AuButton
                  type="tertiary"
                  size="medium"
                  className={styles.delete_vins}
                  disabled={disabled}
                  displayId="au.taps.filters.deleteVins"
                  onClick={this.showVinEditor.bind(this, index, 'delete')}
                  tracking={{
                    element: trackableElements.BUTTON,
                    action: 'DeleteVins',
                    page: 'ManageFilters'
                  }}
                />
              </div>
            }
          </React.Fragment>
        );
      }

      return tapDetails;
    },
    tapFilterOrderBtn: ({ rowData }) => {
      const { filters, vinEditor } = this.state;
      const disabled = (
        Boolean(vinEditor) ||
        !rowData.isNew ||
        filters.length < 2 ||
        filters.some(f => f.confirm || f.deleted || f.saving)
      );

      return (
        <DragHandle
          className={cn(styles.drag, { [styles.disabled]: disabled })}
          title={formatMessage({ id: 'au.taps.filter.moveFilter' })}
        />
      );
    },
    tapFilterDeleteBtn: ({ index, rowData }) => {
      const { mode } = this.props;
      const { filters, vinEditor } = this.state;
      const canDelete = (
        !vinEditor &&
        filters[index].filterType &&
        (mode === TAP_FILTERS_MODES.CREATE || !filters.some(f => f.isNew && f.filterType || f.deleted || f.saving))
      );

      return (
        <button
          type="button"
          title={formatMessage({ id: 'au.taps.filter.deleteFilter' })}
          className={cn(styles.delete, { [styles.disabled]: !canDelete })}
          onClick={canDelete ? () => {
            if (rowData.isNew) {
              this.removeRow(index, this.commitChanges);
            } else {
              this.handleFilterDelete(index);
            }
          } : NOOP}
        />
      );
    },
    tapFilterPermission: ({ value, property, rowData, index }) => {
      const { filters, vinEditor } = this.state;
      const passThrough = rowData.filterType === tapFilterType['MEMBER_PASS_THROUGH'] || rowData.filterType === tapFilterType['SIGNAL_PASS_THROUGH'];
      const disabled = Boolean(vinEditor) || filters.some(f => f.confirm || f.deleted || f.saving) || passThrough;

      if (rowData.isNew) {
        if (!rowData.filterType) return;
        return (
          <AuToggle
            type={TOGGLE_TYPE_PRIMARY}
            size={TOGGLE_SIZE_MEDIUM}
            name="permit_deny"
            disabled={disabled}
            showLabels={true}
            minimalistic={true}
            className={styles.type_toggle}
            labelClassName={styles.toggle_label}
            checked={value === PERMISSION_PERMIT}
            onDisplayId={`au.taps.filter.types.${PERMISSION_PERMIT}`}
            offDisplayId={`au.taps.filter.types.${PERMISSION_DENY}`}
            onChange={e => this.updateFilter(index, { [property]: e.target.checked ? PERMISSION_PERMIT : PERMISSION_DENY })}
            tracking={{
              element: trackableElements.TOGGLE,
              page: 'ManageFilters'
            }}
          />
        );
      }

      return formatMessage({ id: `au.taps.filter.types.${value}` });
    },
    tapFilterActions: ({ rowData, index }) => {
      const filter = formatMessage({ id: `au.entity.attr.filter` }).toLowerCase();
      let actions;

      if (rowData.deleted) {
        actions = (
          <div className={styles.deleting}>
            <AuButton
              type="alert"
              size="medium"
              disabled={true}
              className={styles.deleting_btn}
              tracking={{
                element: trackableElements.FILTER,
                action: trackableActions.DELETE,
                page: 'ManageFilters'
              }}
            >
              <img src={loadingIcon} alt={formatMessage({ id: 'au.taps.filters.deleting' })} />
            </AuButton>
          </div>
        );
      }
      else if (rowData.confirm) {
        actions = (
          <div className={styles.confirmation}>
            <AutoIntl className={styles.msg} displayId="au.entity.delete.confirmation" values={{ entity: filter }}/>
            <AuButton
              className={styles.proceed}
              type="alert"
              size="medium"
              displayId="au.entity.proceed"
              onClick={() => this.proceedFilterDelete(index)}
            />
            <AuButton
              type="plain"
              size="medium"
              className={styles.cancel}
              displayId="au.entity.cancel"
              onClick={() => this.updateFilter(index, { confirm: false }, false)}
            />
          </div>
        );
      }
      else if (rowData.isNew) {
        actions = (
          <div className={styles.buttons}>
            { !rowData.saving &&
              <AuButton
                type="secondary"
                size="medium"
                disabled={!rowData.filterType}
                className={styles.save_btn}
                displayId="au.taps.filter.save"
                onClick={this.handleFilterCreate.bind(this, index)}
              />
            }
            { rowData.saving &&
              <AuButton
                type="secondary"
                size="medium"
                disabled={true}
                className={styles.save_btn}
              >
                <img src={loadingIcon} alt={formatMessage({ id: 'au.taps.filter.saving' })} />
              </AuButton>
            }
            <AuButton
              type="plain"
              size="medium"
              disabled={rowData.saving}
              className={styles.cancel_btn}
              displayId="au.taps.filter.cancel"
              onClick={() => this.removeRow(index, this.props.onCancelNewFilter)}
            />
          </div>
        );
      }
      else {
        actions = this.formatters.tapFilterDeleteBtn({ index, rowData });
      }

      return <div className={cn(styles.actions, { [styles.fixed]: rowData.confirm || rowData.deleted })}>{ actions }</div>;
    },
    groupFilterDetails: ({ value, isNew, filterType, index, property }) => {
      const { popoutContained } = this.props;
      if (isNew) {
        return (
          <TapFilterDetailsEditor
            createMode={true}
            details={value}
            filterType={filterType}
            resources={this.props.resources}
            onChange={value => this.updateFilter(index, { [property]: value })}
          />
        );

      }
      return (
        <React.Fragment>
          <div className={styles.label_container}>
            <AutoIntl className={styles.label} displayId="au.taps.filter.details.name" tag="span" />
            <div className={styles.value}>
              <EntityLookup
                endpoint={this.groupEndpoint}
                entityId={value.groupId}
                lookupField={'displayName'}
                serviceAlias={SERVICE_NAMES.INVENTORY}
              />
            </div>
          </div>
          <div className={styles.label_container}>
            <AutoIntl className={styles.label} displayId="au.taps.filter.details.groupId" tag="span" />
            <EntityLink
              entityId={value.groupId}
              url={`/services/${SERVICE_NAMES.INVENTORY}/groups`}
              action='view'
              tracking={{ page: 'ManageFilters', action: 'group' }}
              popoutContained={popoutContained}
              className={styles.value}
            >
              { value.groupId }
            </EntityLink>
          </div>
        </React.Fragment>
      );
    }
  }

  filterFormatters = {
    'groupFilter': {
        details: [{func: 'groupFilterDetails'}]
    }
  }

  constructor(props) {
    super(props);

    this.groupEndpoint = enhanceSdkEndpoint(
      new TMC.services.Inventory(),
      'groups',
      this.props.actions
    );
  }

  componentDidMount() {
    const { mode, registerClearFn, registerAddNewFn } = this.props;

    this.setStateOnFiltersFromProps();

    if ([TAP_FILTERS_MODES.EDIT, TAP_FILTERS_MODES.CREATE].includes(mode)) {
      this.loadResourcesData().catch(createResponseAlertMessage);
    }

    if (typeof registerClearFn === 'function') {
      registerClearFn(this.removeAll);
    }

    if (typeof registerAddNewFn === 'function') {
      registerAddNewFn(this.addNewRow);
    }
  }

  componentDidUpdate(prevProps) {
    const { mode, screenWidth } = this.props;

    if (mode == prevProps.mode || screenWidth == prevProps.screenWidth) {
      if (mode === TAP_FILTERS_MODES.CREATE && !this.state.filters.some(f => f.filterType === undefined)) {
        this.addNewRow();
      }
    }

    if (this.state.counter < this.props.filters.length) {
      this.setStateOnFiltersFromProps();
    }
  }

  get columnDefs() {
    const { mode } = this.props;
    let columnDefs;

    switch (mode) {
      case TAP_FILTERS_MODES.EDIT:
        columnDefs = cloneDeep(tableDefs.tapFiltersEdit.columnDefs);
        break;
      case TAP_FILTERS_MODES.CREATE:
        columnDefs = cloneDeep(tableDefs.tapFiltersCreate.columnDefs);
        break;
      default:
        columnDefs = cloneDeep(tableDefs.tapFiltersView.columnDefs);
        break;
    }

    columnDefs.filter(col => !['order', 'actions'].includes(col.property)).forEach(col => col.display = true);

    return columnDefs;
  }

  setStateOnFiltersFromProps() {
    const { mode, filterTypes=tapFilterTypes } = this.props;
    const filters = this.props.filters.map((filter, id) => {
      for (let filterType of filterTypes) {
        if (filterType in filter) {
          filter = formatFilter({
            id, isNew: mode === TAP_FILTERS_MODES.CREATE, filterType, filter,
            filterFormatters: mode === TAP_FILTERS_MODES.VIEW ? this.filterFormatters[filterType] : null
          });
          break;
        }
      }
      return filter;
    });

    this.setState({ filters, counter: filters.length });
  }

  loadResourcesData() {
    const { joinResources, resources, actions } = this.props;
    const promises = [];

    for (let { service, entity } of joinResources) {
      let resource = resources.get(`${service}-${entity}`);
      if (!(resource || {}).size) {
        let resEndpoint = apiFactory(service, entity, actions);
        if (resEndpoint) {
          promises.push(resEndpoint.listPages({ pagesToLoad: Infinity }));
        }
      }
    }

    return Promise.all(promises);
  }

  addNewRow = this.addNewRow.bind(this);
  addNewRow(index = Number.MAX_VALUE) {
    this.setState(prevState => {
      const filters = [...prevState.filters];
      const newFilter = { sequence: filters.length + 1, type: PERMISSION_PERMIT, isNew: true, id: prevState.counter, details: {} };
      filters.splice(index, 0, newFilter);
      return { filters: this.updateSequences(filters), counter: prevState.counter + 1};
    });
  }

  removeRow = this.removeRow.bind(this);
  removeRow(index, callback) {
    this.setState(prevState => {
      const filters = [...prevState.filters];
      filters.splice(index, 1);
      return { filters: this.updateSequences(filters, true) };
    }, callback);
  }

  removeAll = this.removeAll.bind(this);
  removeAll() {
    this.setState({ filters: [] });
  }

  updateSequences(filters, rewriteOrigSequences=false) {
    return filters.map((filter, i) => {
      filter.sequence = i + 1;
      if (rewriteOrigSequences) {
        filter.origSequence = filter.sequence;
      }
      return filter;
    });
  }

  onDragStart = this.onDragStart.bind(this);
  onDragStart() {
    this.setState({ dragging: true });
  }

  onDragEnd = this.onDragEnd.bind(this);
  onDragEnd(result) {
    if (result.destination) {
      const { destination, source } = result;

      trackEvent({
        element: trackableElements.FILTER,
        action: destination.index < source.index ? 'dragUp' : 'dragDown',
        page: 'ManageFilters'
      });

      this.setState(prevState => {
        const filters = [...prevState.filters];
        const dragRow = filters.splice(source.index, 1)[0];
        filters.splice(destination.index, 0, dragRow);
        return { filters: this.updateSequences(filters), dragging: false };
      });
    }
  }

  trimFilterDetails(filter) {
    const { details } = filter;
    let trimmedDetails = {};

    for (let prop in details) {
      trimmedDetails[prop] = details[prop].trim();
    }

    return {
      ...filter,
      details: trimmedDetails
    };
  }

  async handleFilterCreate(index) {
    const {filters} = this.state;
    const filter = filters[index];

    if (filter.filterType === tapFilterType.GROUP) {
      if (filter.details.groupId) {
        delete filter.details.groupName;
      }
    }

    this.updateFilter(index, {saving: true}, false);
    this.props.onCreate({
      type: filter.type,
      sequence: filter.sequence,
      [filter.filterType]: filter.details || {}
    })
      .then(
        () => this.updateFilter(index, {
          isNew: false,
          saving: false,
          origSequence: filter.sequence,
          [filter.filterType]: filter.details || {}
        }),
        () => this.updateFilter(index, {saving: false})
      );
  }

  handleFilterDelete(index) {
    const { filters } = this.state;
    filters.forEach((filter, index) => {
      if (filter.confirm) {
        this.updateFilter(index, { confirm: false }, false);
      }
    });

    this.updateFilter(index, { confirm: true }, false);
  }

  showVinEditor(index, type) {
    this.setState({ vinEditor: { index, type } });
  }

  hideVinEditor() {
    this.setState({ vinEditor: null });
  }

  onVinListChange = this.onVinListChange.bind(this);
  onVinListChange(vins, errors) {
    this.setState(prevState => ({
      vinEditor: { ...prevState.vinEditor, vins, hasErrors: errors.filter(e => e).length > 0 },
    }));
  }

  handleAddVins = this.handleAddVins.bind(this);
  handleAddVins() {
    const { filters, vinEditor } = this.state;
    const { index, vins } = vinEditor;
    const filter = filters[index];
    const uniqueVinsToAdd = [...new Set(vins)];

    this.setState(prevState => ({ vinEditor: { ...prevState.vinEditor, busy: true }}));
    this.props.onAddVins(filter, uniqueVinsToAdd).then(
      () => {
        let details = filter.details;
        // create a Set to eliminate duplicates and then convert back to an array
        details.vins = [...new Set([...details.vins, ...uniqueVinsToAdd])];
        this.updateFilter(index, { details }, false);
        this.hideVinEditor();
      },
      () => {
        this.setState(prevState => ({ vinEditor: { ...prevState.vinEditor, busy: false }}));
      }
    );
  }

  handleDeleteVins = this.handleDeleteVins.bind(this);
  handleDeleteVins() {
    const { filters, vinEditor } = this.state;
    const { index, vins } = vinEditor;
    const filter = filters[index];
    const uniqueVinsToDelete = [...new Set(vins)];

    this.setState(prevState => ({ vinEditor: { ...prevState.vinEditor, busy: true }}));
    this.props.onDeleteVins(filter, uniqueVinsToDelete).then(
      () => {
        let details = filter.details;
        details.vins = details.vins.filter(vin => !uniqueVinsToDelete.includes(vin));
        this.updateFilter(index, { details }, false);
        this.hideVinEditor();
      },
      () => {
        this.setState(prevState => ({ vinEditor: { ...prevState.vinEditor, busy: false }}));
      }
    );
  }

  proceedFilterDelete = this.proceedFilterDelete.bind(this);
  proceedFilterDelete(index) {
    const filter = this.state.filters[index];
    this.updateFilter(index, { deleted: true, confirm: false }, false);
    this.props.onDelete(filter).then(
      () => this.removeRow(index),
      () => this.updateFilter(index, { deleted: false }, false)
    );
  }

  updateFilter = this.updateFilter.bind(this);
  updateFilter(index, fields, commit=true) {
    this.setState(prevState => {
      const filters = [...prevState.filters];
      let filter = {...filters.splice(index, 1)[0], ...fields };
      filters.splice(index, 0, filter);
      return { filters };
    }, () => commit && this.commitChanges());
  }

  commitChanges() {
    const filters = this.state.filters.reduce((acc, { sequence, filterType, type, details={} }) => {
      if (filterType) {
        acc.push({ sequence, type, [filterType]: details });
      }
      return acc;
    }, []);

    this.props.onChange(filters);
  }

  renderHead() {
    const { mode } = this.props;
    const content = [];
    this.columnDefs.forEach(col => {
      if (col.display !== false) {
        content.push(
          <HeaderCell key={`th_${col.property}`} width={col.width} className={cn(styles.cell, {[styles.sticky_header]: mode === 'create', [styles.hide_column]: this.props.hideSequenceColumn})}>
            {formatMessage({ id: col.labelId })}
          </HeaderCell>
        );
      }
    });

    return <Row className={styles.header_row}>{content}</Row>;
  }

  renderBody() {
    const { mode } = this.props;
    return mode === TAP_FILTERS_MODES.VIEW ? this.renderRows() : this.renderDraggableRows();
  }

  renderRows() {
    const { filters } = this.state;
    const rows = [];

    filters.forEach((rowData, index) => {
      const cells = [];

      this.columnDefs.forEach(col => {
        if (col.display !== false) {
          cells.push(
            <Cell key={`td_${rowData.id}_${col.property}`} className={styles.cell} width={col.width}>
              {this.renderContent(index, col, rowData)}
            </Cell>
          );
        }
      });

      let classNames = cn(
        styles.row,
        {
          [styles.deleted]: rowData.deleted,
          [styles.confirm]: rowData.confirm
        }
      );

      rows.push(
        <Row
          key={`tr_${rowData.id}_${rowData.filterType}_${index}`}
          className={classNames}
        >{cells}</Row>
      );
    });

    return rows;
  }

  renderDraggableRows() {
    const { filters, vinEditor } = this.state;
    const { rowLimit } = this.props;
    const rows = [];

    filters.forEach((rowData, index) => {
      let cells = [];

      this.columnDefs.forEach(col => {
        if (col.display !== false) {
          cells.push(
            <Cell key={`td_${rowData.sequence}_${col.property}`} className={cn(styles.cell, {[styles.hide_column]: this.props.hideSequenceColumn})} width={col.width}>
              {this.renderContent(index, col, rowData)}
            </Cell>
          );
        }
      });

      let classNames = cn(
        styles.row,
        {
          [styles.new]: rowData.isNew,
          [styles.confirm]: rowData.confirm,
          [styles.deleted]: rowData.deleted
        }
      );

      if (rowLimit) {
        if (rows.length < rowLimit) {
          rows.push(
            <DraggableRow
              key={`tr_${rowData.id}_${rowData.filterType}_${index}`}
              id={`${rowData.id}`}
              index={index}
              className={classNames}
              isDragDisabled={!rowData.isNew || vinEditor}
            >{cells}</DraggableRow>
          );
        }
      } else {
        rows.push(
          <DraggableRow
            key={`tr_${rowData.id}_${rowData.filterType}_${index}`}
            id={`${rowData.id}`}
            index={index}
            className={classNames}
            isDragDisabled={!rowData.isNew || vinEditor}
          >{cells}</DraggableRow>
        );
      }
    });

    if (vinEditor) {
      const { screenWidth } = this.props;
      const isMobile = ['tabletLandscape', 'tabletPortrait'].includes(screenWidth);

      rows.splice(vinEditor.index + 1, 0, (
        <Row key={`tr_vin_editor`} className={cn(styles.row, styles.extra, { [styles.mobile]: isMobile })}>
          <Cell className={styles.cell} />
          <Cell className={styles.cell} />
          <Cell className={styles.cell} >
            <div className={styles.wrapper}>
              <VinEditor
                className={styles.vin_editor}
                onChange={this.onVinListChange}
              />
              <div className={styles.vin_editor_actions}>
                { vinEditor.type === 'add' &&
                  <AuButton
                    type="secondary"
                    className={styles.vin_add_btn}
                    disabled={!(vinEditor.vins || []).length || vinEditor.hasErrors || vinEditor.busy}
                    displayId={vinEditor.busy ? null : "au.taps.filters.addVins"}
                    onClick={this.handleAddVins}
                  >
                    { vinEditor.busy && <img src={loadingIcon} alt={formatMessage({ id: 'au.taps.filters.vinEditor.adding' })} /> }
                  </AuButton>
                }
                { vinEditor.type === 'delete' &&
                  <AuButton
                    type="alert"
                    className={styles.vin_delete_btn}
                    disabled={!(vinEditor.vins || []).length || vinEditor.hasErrors || vinEditor.busy}
                    displayId={vinEditor.busy ? null : "au.taps.filters.deleteVins"}
                    onClick={this.handleDeleteVins}
                  >
                    { vinEditor.busy && <img src={loadingIcon} alt={formatMessage({ id: 'au.taps.filters.vinEditor.deleting' })} /> }
                  </AuButton>
                }
                { !vinEditor.busy &&
                  <AuButton
                    type="plain"
                    displayId="au.taps.filters.vinEditor.cancel"
                    className={styles.vin_editor_cancel}
                    onClick={this.hideVinEditor.bind(this)}
                  />
                }
              </div>
            </div>
          </Cell>
          <Cell className={styles.cell} />
          <Cell className={styles.cell} />
          <Cell className={styles.cell} />
        </Row>
      ));
    }

    return rows;
  }

  getFormattedValue(value, formatters, data) {
    if (formatters.length) {
      for (let { func, args } of formatters) {
        if (typeof value !== 'undefined' && typeof value[func] === 'function') {
          value = value[func]();
        } else if (typeof this.formatters[func] === 'function') {
          if (!args) {
            args = {};
          }
          value = this.formatters[func]({ ...args, ...data, value });
        }
      }
    } else if (typeof value === 'object') {
      value = <TreeView data={fromJS(value)} />;
    }
    return value;
  }

  renderContent(index, col, rowData) {
    const { property } = col;
    // Use specified formatters per column type or default
    let formatters = (rowData.filterFormatters && rowData.filterFormatters[col.property]) || col.formatters || [];
    let value = rowData[col.property] || '';
    let data = { property, index, rowData };

    return <div className={styles.inner}>{this.getFormattedValue(value, formatters, data)}</div>;
  }

  render() {
    const { mode, className, denyAttributeTags, validationModes, hideValidationMessage, entityType } = this.props;
    const { dragging, filters } = this.state;

    if (!filters) {
      return false;
    }

    return (
      <div className={cn(styles.container, className)}>
        { mode !== TAP_FILTERS_MODES.VIEW && 
          <TapValidationMessage entityType={entityType} filters={filters} mode={mode} denyAttributeTags={denyAttributeTags} validationModes={validationModes} />
        }
        { mode === TAP_FILTERS_MODES.EDIT && !hideValidationMessage &&
          <ValidationMessage {...{
            prefixMsg: "au.entity.taps.validation.topology.message",
            infoMsg: "info.withLink",
            values: {
              b: (txt) => <strong>{txt}</strong>,
              a: txt => (
                <a onClick={() =>
                  browserHistory.push({
                    pathname: window.location.pathname.split('/').slice(0, -1).join('/') + '/validation',
                    state: { runValidation: true }
                  })
                }>{txt}</a>
              )
            }
          }} />
        }
        <SimpleTable className={cn(styles.table, [styles[mode]], { [styles.dragging]: dragging })} onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
          { this.renderHead() }
          { Boolean(filters.length) && this.renderBody() }
        </SimpleTable>
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => {
  return (
    <ScreenWidthContext.Consumer>
      {context => <TapFilters {...props} screenWidth={context} ref={ref} />}
    </ScreenWidthContext.Consumer>
  );
});
