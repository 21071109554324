import React from 'react';
import * as T from 'prop-types';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import CollapsiblePanel from '@au/core/lib/components/elements/CollapsiblePanel';

import AuButton, {
  BUTTON_TYPE_TERTIARY,
  BUTTON_SIZE_MEDIUM
} from '@au/core/lib/components/elements/AuButton';

import { formatMessage } from '../../../utils/reactIntl';
import { STATEMENT_HISTORY_ENDPOINT_URI, STATEMENT_HISTORY_VIEW_PATH } from '../../../constants';
import DefaultView from '../View';
import auFormatters from '../../../utils/formatters';
import { get } from '../../../utils/api';
import { statementIdSerializer } from '../../../utils/serializers';
import { history as browserHistory } from '../../../history';
import { StatementDiffTable } from './StatementHistory';
import styles from '../../../css/components/audit_details_view.module.scss';

const policyActions = ['iam:grantPolicy', 'iam:revokePolicy'];

class AuditPolicyStatement extends React.Component {
  static propTypes = {
    data: T.object.isRequired,
    index: T.number.isRequired,
  }

  constructor(props) {
    super(props);
  }

  onViewHistoryBtnClick = this.onViewHistoryBtnClick.bind(this);
  onViewHistoryBtnClick() {
    const { data: { event } } = this.props;
    const entityId = statementIdSerializer(event);
    browserHistory.push(`${STATEMENT_HISTORY_VIEW_PATH}/${entityId}/history`);
  }

  render() {
    const { data, index } = this.props;
    const { event, actor } = data;

    const actionButtonsElement = (
      <div className={styles.buttons}>
        <AuButton 
          type={BUTTON_TYPE_TERTIARY}
          size={BUTTON_SIZE_MEDIUM}
          displayId='au.entity.viewHistory'
          onClick= {() => this.onViewHistoryBtnClick()}
        />
      </div>
    );

    return (
      <CollapsiblePanel
        displayString={formatMessage({id: 'au.audit.changedPolicyStatement'}, {number:index+1})}
        className={styles.panel}
        collapsedClassName={styles.collapsed}
        actionButtonsElement={actionButtonsElement}
      >
        <div>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.row}>
                <td className={styles.fieldname}>
                  <AutoIntl displayId={`au.policies.subjectAui`} />
                </td>
                <td className={styles.fieldvalue}>
                  {auFormatters.policyEntityLink({value: event.subjectAui})}
                </td>
              </tr>
              <tr className={styles.row}>
                <td className={styles.fieldname}>
                  <AutoIntl displayId={`au.policies.resourceAui`} />
                </td>
                <td className={styles.fieldvalue}>
                  {event.resourceAui}
                </td>
              </tr>
            </tbody>
          </table>
          <StatementDiffTable statement={event?.statement} prevStatement={event?.previousStatement} actor={actor}/>
        </div>
      </CollapsiblePanel>
    );
  }
}

export default class AuditView extends DefaultView {

  async onAfterFetch(data) {
    super.onAfterFetch(data);

    const { entity } = this.props;
    const correlationId = entity.get('correlationId');
    const action = entity.get('event')?.get('action');
    if (Boolean(action) && policyActions.includes(action)) {
      const resp = await get(`${STATEMENT_HISTORY_ENDPOINT_URI}?correlationId=${correlationId}`)
          .catch(r => r);

      if (resp?.data?.items) {
        this.setState({historyItems: resp.data.items, policyHistoryPresent: true});
      }
    }
  }

  getCrumbs() {
    // NOTE: It would be ideal to have breadcrumb value and format set in entity definition yaml file
    // Since we dont have formators support in breadcrumb, this is a manual way to format timestamp in breadcrumb
    // FIXME: move this to entity definition when we have breadcrumb formator support
    const { entity, breadcrumbs } = this.props;
    const sessionTimezone = localStorage.getItem('timezone');
    breadcrumbs[breadcrumbs.length - 1].displayString = auFormatters.date({ value: entity.get('timestamp'), timezone: sessionTimezone })
      || '';

      return [...breadcrumbs];
  }

  renderAdditionalContent() {
    const { policyHistoryPresent, historyItems } = this.state;
    if (!policyHistoryPresent) {
      return '';
    }

    return (
      <div className={styles.section}>
        {historyItems && historyItems.map((item, index) => (
          <AuditPolicyStatement key={item.id} data={item} index={index}/>
        ))}
      </div>
    );
  }
}
