import React from 'react';
import * as T from 'prop-types';
import moment from 'moment-timezone';

import { DATETIME_FORMAT_FULL } from '../constants';

const DEFAULT_TIMEZONE = moment.tz.guess();

export default class AuFormatRelative extends React.PureComponent {

  static propTypes = {
    date: T.instanceOf(Date).isRequired,
    timezone: T.string,
    momentFormat: T.string
  }

  static defaultProps = {
    timezone: DEFAULT_TIMEZONE,
    momentFormat: DATETIME_FORMAT_FULL
  }

  render() {
    const { date, timezone, momentFormat } = this.props;
    const momentDate = moment.tz(date, timezone);

    return (
      <span title={momentDate.format(momentFormat)}>
        {momentDate.fromNow()}
      </span>
    );
  }

}
