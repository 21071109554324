import React from 'react';
import moment from 'moment-timezone';
import cn from 'classnames';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import LoadingIndicator from '@au/core/lib/components/elements/LoadingIndicator';
import BinaryFontButton from '@au/core/lib/components/elements/BinaryFontButton';

import { get } from '../../../utils/api';

import DefaultView from '../View';
import SidebarSubviews from "../../SidebarSubview";

import styles from '../../../css/components/entity_view.module.scss';
import customStyles from '../../../css/components/entity/custom/archived_rule_view.module.scss';

export default class UpdateHistoryList extends DefaultView {

  constructor(props) {
    super(props);

    this.state = {
      ...super.state,
      expanded: {}
    };
  }

  async loadData() {
    if (this.entityId) {
      return await get(`/1/processing-crud/archivedrules`)
      .then((resp) => {
        this.setState({data: resp?.data, ready: true, found: true});
        const rules = resp?.data.filter((rule) => rule.ruleId.toString() === this.props.parentEntity.entityId);
        this.setState({archivedRules: rules});
        return resp
      }, (err) => {
        if (err.data.code === 404) {
          return err;
        }
        else {
          this.setState({ errorStatusCode: err.data.code, errorStatusMessage: err.data.message });
          throw err;
        }
      });
    }
  }

  getNavLinks() {

    const navLinks = [
        {labelId: 'au.entity.details', destination: `/services/processing-crud-service/rules/${encodeURIComponent(this.props.parentEntity.entityId)}/view`, isEndOfSection: true},
        {labelId: 'au.entity.title.permittedFields', destination: `/services/processing-crud-service/rules/${encodeURIComponent(this.props.parentEntity.entityId)}/permittedFields/list`},
        {labelId: 'au.entity.title.updateHistory', destination: `/services/processing-crud-service/rules/${encodeURIComponent(this.props.parentEntity.entityId)}/updateHistory/list`}
    ]

    return navLinks;
  }

  parseCondition(cond) {
    cond = cond.replace('{', "{\n");
    cond = cond.replace('}', "\n}");
    cond = cond.replace('(', "{\n");
    cond = cond.replace(')', "\n}");
    cond = cond.replace('and ', "\nand\n");
    cond = cond.replace('and not', "\nand not\n");
    return cond;
  }

  handleClick = this.handleClick.bind(this);
  handleClick(ind) {
    this.setState((prevState) => {
      let expanded = prevState.expanded;
      expanded[ind] = !prevState.expanded[ind];
      return expanded;
    })
  }

  collapseAll = this.collapseAll.bind(this);
  collapseAll() {
    this.setState((prevState) => {
      let expanded = prevState.expanded;
      for (let i = 0; i < Object.values(expanded).length; i++) {
        expanded[i] = false;
      }
      return expanded;
    })
  }

  expandAll = this.expandAll.bind(this);
  expandAll() {
    this.setState((prevState) => {
      let expanded = prevState.expanded;
      for (let i = 0; i < Object.values(expanded).length; i++) {
        expanded[i] = true;
      }
      return expanded;
    })
  }

  renderRule(rule, ind) {
    const { expanded } = this.state;
    if (this.state.expanded[ind] === undefined) {
      expanded[ind] = false;
      this.setState({expanded: expanded});
    }
    const cond = this.parseCondition(rule.condition);
    const sessionTimezone = localStorage.getItem('timezone');
    const validUntil = moment.tz(rule.validUntil, sessionTimezone).format('MMM DD YYYY hh:mm:ss.SSS a z');
    const open = this.state.expanded[ind];

    return (
      <div className={open ? cn(customStyles.rule_section, customStyles.expanded) : customStyles.rule_section}>
        <div>
          <BinaryFontButton
            onClick={() => this.handleClick(ind)}
            onInitial={!this.state.expanded[ind]}
            initial="collapse" next="minus-square" />
          <AutoIntl displayId='au.archivedRules.validUntil' values={{timestamp: validUntil}} className={customStyles.time_header}/>
        </div>
        {this.state.expanded[ind] &&
          <div className={customStyles.section} ref={ref => this.tableRef = ref}>
            <table className={styles.rows} ref={ref => this.tableRef = ref}>
              <tbody>
                <tr key={`field_ruleId`} className={styles.row} ref={ref => this.rowRef = ref} >
                  <td className={styles.fieldname}>
                    <AutoIntl displayId={'au.entity.attr.version'} />
                  </td>
                  <td className={styles.fieldvalue}>
                    {rule.ruleVersion}
                  </td>
                </tr>
                <tr key={`field_name`} className={styles.row} ref={ref => this.rowRef = ref} >
                  <td className={styles.fieldname}>
                    <AutoIntl displayId={'au.entity.attr.condition'} />
                  </td>
                  <td className={cn(styles.fieldvalue, styles.pre_line)}>
                    {cond}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      </div>
    )
  }
    
  render() {
    const { screenWidth } = this.props;
    const { ready, subviewSidebarOpen, showError, archivedRules } = this.state;
    const isMobile = screenWidth !== "desktop";
    const subviewVal = this.state.subviewSidebarOpen === undefined && !isMobile;

    return(
      <>
        <div ref={this._leftSidebarRef} />
        <div className={cn("o-wrapper", styles.container, this.containerSubviewStyle(subviewVal || subviewSidebarOpen))}>
          { this.renderBreadcrumbs() }
          <div className={styles.content} ref={ref => this.containerRef = ref}>
            <div className={cn(styles.content_flex, {[styles.no_margin_top]: this.props.popoutContained} )}>
              {!this.props.popoutContained && <SidebarSubviews
                navLinks={this.getNavLinks()}
                portalRef={this._leftSidebarRef}
                open={subviewVal || this.state.subviewSidebarOpen}
                setOpen={this.setSubviewSidebar}
                isMobile={isMobile}
              />}
              <div className={styles.table}>
                <div className={styles.section}>
                  {!showError && <LoadingIndicator className={styles.loader} display={!ready} /> }
                  {ready && 
                    <>
                      <div className={customStyles.actions_wrapper}>
                        <div className={customStyles.actions}>
                          <div className={customStyles.action_buttons} onClick={this.expandAll}>
                            <AutoIntl displayId="au.entity.taps.validation.expandAll"/>
                          </div>
                          <span className={customStyles.separator} />
                          <div className={customStyles.action_buttons} onClick={this.collapseAll}>
                            <AutoIntl displayId="au.entity.taps.validation.collapseAll"/>
                          </div>
                        </div>
                      </div>
                      <div>
                        {archivedRules && archivedRules.map((rule, ind) => {
                          return this.renderRule(rule, ind);
                        })}
                        {!archivedRules || archivedRules?.length == 0 &&
                          <div className={customStyles.empty_message}>
                            <AutoIntl displayId={'au.table.dataEmptyMessage'}/>
                          </div>
                        }
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}