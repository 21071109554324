import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import { injectIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import ProcessingButton from '@au/core/lib/components/elements/ProcessingButton';
import AuComponent from '@au/core/lib/components/elements/AuComponent';
import { createResponseAlertMessage, alertCategory } from '@au/core/lib/components/objects/AlertMessage';

import { entityEvent, NOOP } from '../../constants';
import { history as browserHistory } from '../../history';
import knownParsers from '../../utils/parsers';
import { ruleRunner, run } from '../../utils/validationRuleRunner';
import loadingIcon from '../../images/spinner_white.gif';
import { trackableElements, trackableActions } from '../../utils/analyticsHelpers';
import { redirectOnSaveSuccess } from '../../utils/linkHelper';

import fieldset from '../../css/fieldset.module.scss';
export class _EntityEdit extends AuComponent {

  static propTypes = {
    action: T.string.isRequired,
    entityType: T.string.isRequired,
    entityDef: T.object.isRequired,
    className: T.string,
    panelClassName: T.string,
    buttonsClassName: T.string,
    layout: T.oneOf(["list", "table"]),
    entityExists: T.oneOfType([T.bool, T.func]),
    entityProcessors: T.oneOfType([T.arrayOf(T.func), T.func]),
    // getEntityProcessors is used for lazy loading
    // apply entity data processors if any.
    // useful if you need to modify the data before sending to the backend
    endpoint: T.object.isRequired,
    formRef: T.func,
    saveHandler: T.func,
    saveErrorId: T.string,
    saveSuccessDestination: T.string,
    cancelHandler: T.func,
    cancelDestination: T.string,
    errorHandler: T.func,
    disableSaveBtn: T.func
  }

  static defaultProps = {
    layout: 'list',
    entityProcessors: [],
    formRef: NOOP
  }

  constructor(props) {
    super(props);

    const entity = props.entity ? props.entity.toJS() : {};

    this.state = {
      validationErrors: {},
      validationRules: {},
      saving: false,
      entity,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { entity } = props;

    if (entity && isEmpty(state.entity)) {
      return { entity: entity.toJS() };
    }

    return null;
  }

  componentDidMount() {
    window.addEventListener(entityEvent.CANCEL_BTN_CLICK, this.onCancelBtnClick);
  }

  componentWillUnmount(){
    window.removeEventListener(entityEvent.CANCEL_BTN_CLICK, this.onCancelBtnClick);
  }

  registerFieldValidationRules = this.registerFieldValidationRules.bind(this);
  registerFieldValidationRules(field, labelId, rules){
    this.setState((prevState) => {
      let validationRules = Object.assign({}, prevState.validationRules);
      if (rules && Array.isArray(rules)){
        validationRules[field] = [ ruleRunner(field, labelId, ...rules) ];
        return { validationRules };
      }
    });
  }

  // validates only previously errored fields
  reValidate() {
    if (this.doNotValidate) return;

    this.setState(prevState => {
      const { entity, validationRules, validationErrors } = prevState;
      let retVal = { };

      for (let field of Object.keys(validationErrors)) {
        let vErr = run(entity, validationRules[field]);

        if (isEmpty(vErr)) {
          retVal.validationErrors = Object.assign({}, prevState.validationErrors);
          delete(retVal.validationErrors[field]);
        }
        else {
          retVal.validationErrors = Object.assign({}, prevState.validationErrors, vErr);
        }
      }

      return retVal;
    });
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(field, value) {

    this.setState(prevState => {
      const rules = prevState.validationRules[field];

      let retVal = { entity: { ...prevState.entity, [field]: value } };
      // validate only the field that has changed
      if (!this.doNotValidate && rules) {
        let vErr = run(retVal.entity, rules);

        if (isEmpty(vErr)) {
          retVal.validationErrors = Object.assign({}, prevState.validationErrors);
          delete(retVal.validationErrors[field]);
        }
        else {
         retVal.validationErrors = Object.assign({}, prevState.validationErrors, vErr);
        }
      }
      
      return retVal;
    }, this.reValidate);
  }

  genericErrorHandler = this.genericErrorHandler.bind(this);
  genericErrorHandler(error) {
    this.setState({ saving: false });

    if (this.props.errorHandler) {
      return this.props.errorHandler(error);
    }

    createResponseAlertMessage({
      category: alertCategory.error,
      titleId: error.data.error ? undefined : 'au.errors.400.title',
      titleString: error.data.error ? error.data.error : '',
      messageId: error.data.message ? undefined : 'au.error.generic',
      messageString: error.data.message ? error.data.message : ''
    });
  }

  saveSuccessHandler = this.saveSuccessHandler.bind(this);
  saveSuccessHandler(response) {
    const { endpoint, saveSuccessDestination, popoutContained, actions } = this.props;

    if (popoutContained) {
      actions.closePopout();
    }
    else {
      redirectOnSaveSuccess(response, endpoint, saveSuccessDestination);
    }
  }

  onSaveBtnClick = this.onSaveBtnClick.bind(this);
  onSaveBtnClick() {
    let { entity, validationRules } = this.state;
    const { saveHandler, entityDef } = this.props;
    const entityProcessors = typeof this.props.entityProcessors === 'function'
                             ? this.props.entityProcessors()
                             : this.props.entityProcessors;
    //Double check validation just in case something didn't trigger, for example
    //no field was ever touched and user clicked the "Save" button
    let validationErrors = {};
    for (let rules of Object.values(validationRules)) {
      validationErrors = Object.assign(validationErrors, run(entity, rules));
    }

    if (!isEmpty(validationErrors)) {
      this.setState({ validationErrors, showErrors: true });
      return;
    }

    for (let [attr, { parser }] of Object.entries(entityDef.attributes)) {
      if (parser && parser.name && parser.name in knownParsers) {
        entity[attr] = knownParsers[parser.name]({ value: entity[attr], ...parser.props });
      }
    }

    // apply entity data processors if any.
    // useful if you need to modify the data before sending to the backend
    for (let processor of entityProcessors) {
      entity = processor(entity);
    }

    // invoke save callback
    if (saveHandler) {
      this.setState({ saving: true });
      return saveHandler(entity, this.doSave).then(resp => {
        this.setState({ saving: false });
        return resp;
      }).catch(this.genericErrorHandler);
    }

    this.doSave(entity);
  }

  doSave = this.doSave.bind(this);
  doSave(entity, onSuccess=this.saveSuccessHandler, onError=this.genericErrorHandler) {
    const { entityExists } = this.props;
    const isExistingEntity = typeof entityExists === 'function' ? entityExists() : entityExists;

    this.setState({ saving: true });
    const onResolve = resp => {
      this.setState({ saving: false });
      return resp;
    };
    const onReject = err => {
      this.setState({ saving: false });
      return Promise.reject(err);
    };

    // NOTE this exception handling is very vague.
    // genericErrorHandler() expects error object of a specific format
    // but that's only the case for network related exceptions (generated by SDK)
    // all other exceptions will result in a new exception
    try {
      if (isExistingEntity) {
        return this.updateEntity(entity)
          .then(onResolve, onReject)
          .then(onSuccess, onError);
      }
      else {
        return this.createEntity(entity)
          .then(onResolve, onReject)
          .then(onSuccess, onError);
      }
    } catch (e) {
      // We'll end up here when endpoint doesn't provide create/replace/patch
      // action. This means, that there is a problem with detection whether
      // user can create/edit entity.
      /* eslint-disable no-console */
      console.error(`Error: ${e}`);
      /* eslint-enable no-console */
      return onError(e);
    }
  }

  createEntity = this.createEntity.bind(this);
  createEntity(entity) {
    return this.props.endpoint.create(entity);
  }

  updateEntity = this.updateEntity.bind(this);
  updateEntity(entity) {
    const { endpoint, entityDef } = this.props;

    if (endpoint.actions.includes('replace')) {
      return endpoint.replace(entity);
    }
    else {
      const origEntity = this.props.entity.toJS();
      let editedFields = {};
      for (let [attr, val] of Object.entries(origEntity)) {
        if ((entityDef.attributes[attr] || {}).readonly) {
          continue;
        }
        if (!isEqual(val, entity[attr])) {
          editedFields[attr] = entity[attr];
        }
      }

      if (Object.keys(editedFields).length) {
        // include eTag if any
        if (entity.etag) {
          editedFields.etag = entity.etag;
        }
        // if any field were modified - do patch
        return endpoint.patch(entity[endpoint.idProp], editedFields);
      }
      else {
        // nothing changed - skip the request, execute success handler right away
        return Promise.resolve({ data: entity });
      }
    }
  }

  onCancelBtnClick = this.onCancelBtnClick.bind(this);
  onCancelBtnClick(ev){
    if (this.props.cancelHandler){
      return this.props.cancelHandler(ev);
    }

    if (this.props.popoutContained) {
      this.props.actions.closePopout();
    }
    else {
      browserHistory.push(this.props.cancelDestination);
    }
  }

  disableSaveBtn = this.disableSaveBtn.bind(this);
  disableSaveBtn() {
    if (this.props.disableSaveBtn) {
      return this.props.disableSaveBtn(this.state.entity)
    } else {
      const attributes = this.props.entityDef.attributes;
      let requiredFields = [];
      let requiredEntityFieldsObject = {};
      let emptyValue;

      Object.entries(attributes).forEach(attribute => {
        if (attribute[1].rules && attribute[1].rules.includes('required') && attribute[1].display?.create !== false) {
          requiredFields.push(attribute[0])
          requiredEntityFieldsObject[attribute[0]] = this.state.entity[attribute[0]];
        }
      });
      Object.values(requiredEntityFieldsObject).forEach(field => {
        if (typeof field === 'object') {
          emptyValue = field.length === 0
        }
      });

      return Object.values(requiredEntityFieldsObject).includes(undefined) ||
        Object.values(requiredEntityFieldsObject).includes('') ||
        emptyValue ||
        Object.values(this.state.validationErrors).length !== 0
    }
  }

  render(){
    const { children, layout, entityType, entityExists, formRef, action, intl, entityDef } = this.props;
    const { className, panelClassName, buttonsClassName } = this.props;
    const { showErrors, validationErrors, entity } = this.state;
    const isExistingEntity = typeof entityExists === 'function' ? entityExists() : entityExists;
    const savingMsg = intl.formatMessage({ id: `au.entity.${isExistingEntity ? 'save' : 'create'}.wait` });
    const trackingPage = (
      (this.props.entityType || this.props.entityDef.type) + (isExistingEntity ? trackableActions.UPDATE : trackableActions.CREATE)
    );

    return (
      <form ref={formRef} className={cn(fieldset[layout], className)} noValidate onSubmit={e => e.preventDefault()}>
        <div className={cn(fieldset.panel, panelClassName)}>
          { React.Children.map(children, child => child && (
            React.cloneElement(
              child,
              {
                intl,
                entity,
                showErrors,
                validationErrors,
                fieldChangeCallback: this.handleFieldChange,
                registerValidation: this.registerFieldValidationRules
              }
            )
          )) }
        </div>

        <div className={cn(fieldset.buttons, buttonsClassName)}>
          { !this.state.saving &&
            <ProcessingButton
              type="primary"
              onClick={this.onSaveBtnClick}
              disabled={this.disableSaveBtn()}
              displayString={isExistingEntity
                ? intl.formatMessage({ id: 'au.entity.save' })
                : entityDef.saveBtnDisplayId ? intl.formatMessage({ id: entityDef.saveBtnDisplayId }) : intl.formatMessage({ id: `au.entity.action.${['create', 'replicate'].includes(action) ? 'create' : action}` }, {
                  entityName: intl.formatMessage({ id: `au.entity.name.${entityType}` })
                })
              }
              tracking={{
                element: trackableElements.BUTTON,
                action: isExistingEntity ? trackableActions.UPDATE : trackableActions.CREATE,
                page: trackingPage
              }}
            />
          }
          { this.state.saving &&
            <ProcessingButton type="primary" disabled={true}>
              <img src={loadingIcon} alt={savingMsg} />
              <span>{savingMsg}</span>
            </ProcessingButton>
          }
          { !this.state.saving &&
            <ProcessingButton
              type="plain"
              className={fieldset.cancel}
              onClick={this.onCancelBtnClick}
              onMouseOver={() => this.doNotValidate = true}
              onMouseLeave={() => this.doNotValidate = false}
              displayId="au.entity.cancel"
              tracking={{
                element: trackableElements.BUTTON,
                action: trackableActions.CANCEL,
                page: trackingPage
              }}
            />
          }
        </div>
      </form>
    );
  }
}

let injectedEntityEdit = injectIntl(_EntityEdit);

export { injectedEntityEdit as EntityEdit};
