const scriptjs = require(`scriptjs`);

const STATUS_PAGE_URL = 'https://cdn.statuspage.io/se-v2.js';

function _initialize(config) {
  return new Promise(resolve => {
    scriptjs([STATUS_PAGE_URL], () => {
      window.SP = new window.StatusPage.page(config);
      resolve();
    });
  });
}

export async function initialize(config) {
  if (!window.SP) {
    await _initialize(config);
  }
}

export default {
  initialize
};
