import React from "react";

import { BUTTON_TYPE_SECONDARY } from '@au/core/lib/components/elements/AuButton';

import { generateEntityPath } from "../../../utils/entity";
import { wrapActionWithTracking } from '../../../utils/analyticsHelpers';
import Banner, { BANNER_TYPE_LOADING } from "../../Banner";
import StartDialog from "../StartDialog";
import FeedCommonView from './FeedCommonView';

import styles from '../../../css/components/entity_view.module.scss';

export default class ForkView extends FeedCommonView {

  queryParams = this.props.entityDef.queryParams;

  state = {
    ...super.state,
    showStartDialog: false,
  }

  renderDialogs() {
    const dialogs = super.renderDialogs();
    const { entityDef } = this.props;
    dialogs.push(
      <StartDialog
        key={"start-fork-dialog"}
        show={this.state.showStartDialog}
        onCancel={() => this.setState({showStartDialog: false})}
        onConfirm={this.confirmStart}
        titleDisplayId={"au.fork.start"}
        confirmDisplayId={"au.fork.start"}
        headerDisplayId={"au.fork.start"}
        messageDisplayId={"au.fork.message"}
        entityType={entityDef.type}
      />
    );
    return dialogs;
  }

  confirmStart = this.confirmStart.bind(this);
  confirmStart(selection) {
    const { endpoint, entity } = this.props;
    const id = entity.get('id');

    endpoint.start(id, selection)
      .then(
        this.updateFork,
        this.genericErrorHandler
      ).then(() => {
        this.setState({ showStartDialog: false });
      });
  }

  updateFork = this.updateFork.bind(this);
  updateFork(resp, fieldPath = []) {
    const { endpoint, serviceAlias, actions, popoutContained, popoutProps } = this.props;
    const path = [...generateEntityPath(endpoint, serviceAlias), this.entityId, ...fieldPath];

    if (popoutContained && popoutProps.onStateChange) {
      popoutProps.onStateChange(resp.data);
    }

    return actions.patchEntitySuccess({ path, data: { state: resp.data.state } });
  }

  renderContentAboveTable() {
    const { entity } = this.props;
    const forkState = entity.get('state');

    let content = super.renderAdditionalContent();

    if (forkState === 'REPLAY') {
      content.push(
        <Banner 
          key="fork_replaying_banner" 
          type={BANNER_TYPE_LOADING} 
          displayId="au.fork.waitForReplay"
          />
      );
    }

    content.push(this.renderTopologyFragment());

    return content;
  }

  pauseForkBtn(id) {
    return this.props.endpoint.pause(id).then(this.updateFork, this.genericErrorHandler);
  }

  startForkBtn(id) {
    this.setState({ showStartDialog: true, id });
    return this.renderDialogs;
  }

  getActions() {
    const { entity } = this.props;
    const id = entity.get('id');
    const forkState = entity.get('state');

    const actions = super.getActions();

    const pauseFork = wrapActionWithTracking(
      {
        key: 'pause_fork',
        type: BUTTON_TYPE_SECONDARY,
        className: styles.button,
        displayId: 'au.fork.pause',
        onClick: this.pauseForkBtn.bind(this, id)
      },
      'Fork',
      'View'
    );
    const startFork = wrapActionWithTracking(
      {
        key: 'start_fork',
        type: BUTTON_TYPE_SECONDARY,
        className: styles.button,
        displayId: 'au.fork.start',
        onClick: this.startForkBtn.bind(this, id)
      },
      'Fork',
      'View'
    );

    if (forkState === 'RUNNING' || forkState === 'REPLAY') {
      actions.splice(1, 0, pauseFork);
    }
    else if (forkState === 'PAUSED') {
      actions.splice(1, 0, startFork);
    }

    if (forkState === 'REPLAY') {
      const index = actions.findIndex(element => element.key === 'entity_edit_btn');
      // hide edit button
      if (index > -1) {
        actions.splice(index, 1);
      }
    }    

    return actions;
  }

}
