import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import { NOOP } from '../constants';

import styles from '../css/components/zoom.module.scss';

export default class Zoom extends React.PureComponent {
  static propTypes = {
    className: T.string
  }

  static defaultProps = {
    onPlusClick: NOOP,
    onMinusClick: NOOP
  }

  render() {
    const { className, caption, onPlusClick, onMinusClick } = this.props;
    return (
      <div className={cn(styles.container, className)} data-caption={caption}>
        <div className={styles.plus} onClick={onPlusClick} />
        <div className={styles.minus} onClick={onMinusClick} />
      </div>
    );
  }
}
