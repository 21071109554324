import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import InfoDialog from '@au/core/lib/components/objects/InfoDialog';
import copy from '@au/core/lib/utils/copyTextToClipboard';

import { NOOP } from '../constants';
import { formatMessage } from '../utils/reactIntl';

import styles from '../css/components/client_secret_dialog.module.scss';

export default class ClientSecretDialog extends React.PureComponent {

  state = { }

  static propTypes = {
    isNew: T.bool,
    clientId: T.string,
    clientSecret: T.string,
    onClose: T.func
  }

  static defaultProps = {
    onClose: NOOP
  }

  handleCopyClick = (text) => {
    copy(text);
  }

  renderCopyBtn(labelId, value) {
    let el;

    if (value === undefined) {
      // do nothing
    } else if (this.state[labelId] === value) {
      el =  <AutoIntl className={styles.copy} displayId="au.client.copied" />;
    } else {
      const onClick = () => {
        copy(value);
        this.setState({ [labelId]: value });
        setTimeout(() => this.setState({ [labelId]: undefined }), 3000);
      };

      el = (
        <a className={styles.copy} onClick={onClick}>
          { formatMessage({ id: 'au.client.copy' }) }
        </a>
      );
    }

    return el;
  }

  renderRow(labelId, value) {
    return (
      <tr key={labelId} className={styles.row}>
        <td className={styles.fieldname}>
          { formatMessage({ id: `au.client.${labelId}` })}
        </td>
        <td className={styles.fieldvalue}>
          { value }
        </td>
        <td className={styles.fieldcopy}>
          { this.renderCopyBtn(labelId, value) }
        </td>
      </tr>
    );
  }

  render() {
    const { isNew, clientId, clientSecret, onClose } = this.props;

    return (
      <InfoDialog
        className={cn(styles.container, { [styles.new]: isNew})}
        titleId={`au.client.${isNew ? 'clientCreated' : 'secretGenerated'}`}
        onClose={onClose}
        secret={true}
      >
        <AutoIntl
          className={styles.message}
          displayId={`au.client.${isNew ? 'clientIdAndSecretEnclosed' : 'keepSecretSafe'}`}
          tag="div"
        />
        <table className={styles.rows}>
          <tbody>
            { this.renderRow('clientId', clientId) }
            { this.renderRow('clientSecret', clientSecret) }
          </tbody>
        </table>
      </InfoDialog>
    );
  }

}
