import TMC from '@autonomic/browser-sdk';

export function findClientByName(name) {
  return new TMC.services.Accounts().clients.list({ query: `name = '${name}'` });
}

export function findUserByUsername(username) {
  return new TMC.services.Accounts().users.list({ query: `username = '${username}'` });
}

export function findUserByName(name) {
  return new TMC.services.Accounts().users.list({ query: `name = '${name}'` });
}

export function findClientsByIds(ids) {
  return new TMC.services.Accounts().clients.list({ query: `clientId in ('${ids.join("','")}')` });
}
