import React from "react";
import cn from 'classnames';
import * as T from 'prop-types';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import formatters from '../utils/formatters';

import styles from '../css/components/entity_view.module.scss';
import popoutStyles from '../css/components/sms_history_view.module.scss';

export default class SmsHistoryView extends React.Component {

    static propTypes = {
      popoutProps: T.shape({
        data: T.object
      })
    }

    render() {
        const tableData = this.props.popoutProps.data;

        return (
          <div className={popoutStyles.container}>
            <AutoIntl className={popoutStyles.title} displayId={'au.entity.attr.smsIdValue'} values={{id: tableData.smsId}}/>
            <table className={popoutStyles.table}>
              <tbody>
                <tr key={'field_deliveryStatus'} className={styles.row} >
                  <td className={cn(styles.fieldname, styles.column)}>
                    <AutoIntl displayId={'au.entity.attr.deliveryStatus'} />
                  </td>
                  <td className={cn(styles.fieldvalue, styles.column, popoutStyles.value)}>
                    <AutoIntl displayString={tableData.deliveryStatus}/>
                  </td>
                </tr>
                <tr key={'field_carrier'} className={styles.row} >
                  <td className={cn(styles.fieldname, styles.column)}>
                    <AutoIntl displayId={'au.entity.attr.carrier'} />
                  </td>
                  <td className={cn(styles.fieldvalue, styles.column, popoutStyles.value)}>
                    <AutoIntl displayString={tableData.carrier}/>
                  </td>
                </tr>
                <tr key={'field_wakeupState'} className={styles.row}>
                  <td className={cn(styles.fieldname, styles.column)}>
                    <AutoIntl displayId={'au.entity.attr.deviceWakeupState'} />
                  </td>
                  <td className={cn(styles.fieldvalue, styles.column, popoutStyles.value)}>
                    <AutoIntl displayString={tableData.wakeupState}/>
                  </td>
                </tr>
                <tr key={'field_createTime'} className={styles.row}>
                  <td className={cn(styles.fieldname, styles.column)}>
                    <AutoIntl displayId={'au.entity.attr.createTime'} />
                  </td>
                  <td className={cn(styles.fieldvalue, styles.column, popoutStyles.value)}>
                    <AutoIntl displayId={'au.entity.attr.timeValue'} values={{time: formatters.date({value: tableData.createTime})}}/>
                  </td>
                </tr>
                <tr key={'field_updateTime'} className={styles.row}>
                  <td className={cn(styles.fieldname, styles.column)}>
                    <AutoIntl displayId={'au.entity.attr.updateTime'} />
                  </td>
                  <td className={cn(styles.fieldvalue, styles.column, popoutStyles.value)}>
                    <AutoIntl displayId={'au.entity.attr.timeValue'} values={{time: formatters.date({value: tableData.updateTime})}}/>
                  </td>
                </tr>
                <tr key={'field_carrierDeliveryTime'} className={styles.row}>
                  <td className={cn(styles.fieldname, styles.column)}>
                    <AutoIntl displayId={'au.entity.attr.carrierDelivery'} />
                  </td>
                  <td className={cn(styles.fieldvalue, styles.column, popoutStyles.value, {[popoutStyles.no_data]: !tableData.deliveredToCarrier})}>
                    <AutoIntl displayId={tableData.deliveredToCarrier ? 'au.entity.attr.timeValue' : 'au.noDataAvailable'} values={{time: formatters.date({value: tableData.deliveredToCarrier})}}/>
                  </td>
                </tr>
                <tr key={'field_deviceDeliveryTime'} className={styles.row} >
                  <td className={cn(styles.fieldname, styles.column)}>
                    <AutoIntl displayId={'au.entity.attr.deviceDelivery'} />
                  </td>
                  <td className={cn(styles.fieldvalue, styles.column, popoutStyles.value, {[popoutStyles.no_data]: !tableData.deliveredToDevice})}>
                    <AutoIntl displayId={tableData.deliveredToDevice ? 'au.entity.attr.timeValue' : 'au.noDataAvailable'} values={{time: formatters.date({value: tableData.deliveredToDevice})}}/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )
    }
}