import React from "react";
import { FormattedMessage } from "react-intl";
import * as T from "prop-types";

import AutoIntl from "@au/core/lib/components/elements/AutoIntl";

import { NOOP } from '../../constants';
import { Filter } from "../../AuPropTypes";
import { regexp, required } from "../../utils/validationRules";
import Value from "./Value";

import styles from "../../css/components/filters/value.module.scss";
export default class TemplatizedValue extends React.Component {
  static propTypes = {
    selection: T.arrayOf(Filter).isRequired,
    onChange: T.func.isRequired,
    onInit: T.func.isRequired,
    onApplyTemplate: T.string,
    validCharacters: T.string,
    displayId: T.string,
    placeholderId: T.string,
    labelId: T.string,
    multiSelect: T.bool,
    conditionId: T.string,
    hideLabel: T.bool,
    hideCondition: T.bool
  };

  static defaultProps = {
    selection: [],
    validCharacters: "a-z, A-Z, 0-9, _ ;:.,()?&$%@+=*#[]-",
    conditionId: 'au.filters.conditionOr',
    hideLabel: true,
    hideCondition: false
  };

  state = this.getInitialState();

  getInitialState() {
    return {
      selection: []
    };
  }

  componentDidMount() {
    this.props.onInit(this.props.selection);
  }

  resetComponent() {
    this.setState(this.getInitialState());
  }

  processApply(value) {
    if (this.props.onApplyTemplate) {
      value = this.props.onApplyTemplate.replace("{}", value);
    }

    return value;
  }

  getValueValidations() {
    const charactersToEscape = "\\]/.?*+^$";
    const charactersToRemove = ", ";
    const escapedValidCharacters = this.props.validCharacters
      .replace(new RegExp(`([${charactersToEscape}])`, "g"), "\\$1")
      .replace(new RegExp(`[${charactersToRemove}]`, "g"), "");
    const noNonValueCharacters = regexp("^[" + escapedValidCharacters + "]+$", "au.validation.invalidCharacter");
    return [required, noNonValueCharacters];
  }

  onValueChange = (selectedItems) => {
    let lastItemIndex = selectedItems.length - 1;
    selectedItems[lastItemIndex] = { ...selectedItems[lastItemIndex], urlValue: this.processApply(selectedItems[lastItemIndex].urlValue) };
    this.props.onChange(selectedItems);
  }

  render() {
    const {selection, multiSelect, displayId, placeholderId, labelId, conditionId, hideLabel, hideCondition, validCharacters} = this.props;

    return (
      <div>
        <div className={styles.labelled_value}>
          {hideCondition == false &&
          <AutoIntl className={styles.value_text} displayId={conditionId}/>
          }
          {hideLabel == false &&
          <label className={styles.value_label}>
            <FormattedMessage id={labelId} />
          </label>
          }
        </div>
        <div className={styles.container}>
          <Value
            displayId={displayId}
            placeholderId={placeholderId}
            hideCondition={true}
            selection={selection}
            multiSelect={multiSelect}
            onInit={NOOP}
            onChange={this.onValueChange}
            valueValidations={this.getValueValidations()}
            validCharacters={validCharacters}
          />
        </div>
      </div>
    );
  }
}
