import React from 'react';

import { history as browserHistory } from '../../../history';
import { wrapActionWithTracking } from '../../../utils/analyticsHelpers';
import { RESPONSIVE_TABLE_PAGES_TO_PRELOAD, RESPONSIVE_TABLE_PAGES_TO_CONTINUE } from '../../../constants';
import DefaultList from '../List';
import EmbeddedGroupLookup from './EmbeddedGroupLookup';

import styles from "../../../css/components/entity_list.module.scss";
export default class GroupList extends DefaultList {
  static defaultProps = {
    ...super.defaultProps,
    pageSize: 500
  }

  fetchInitialPages = this.fetchInitialPages.bind(this);
  fetchInitialPages() {
    const { parentEntity } = this.props;

    // In the case we have a parent, don't load a full list
    // it can bog down pages that don't need full group list such as vehicles/:id/groups/list
    return parentEntity ? super.fetchInitialPages(RESPONSIVE_TABLE_PAGES_TO_PRELOAD) : super.fetchInitialPages(RESPONSIVE_TABLE_PAGES_TO_CONTINUE);
  }

  onManageMembersBtnClick(id, action) {
    //FIXME - MOVE TO linkHelper
    browserHistory.push({
      pathname: this.baseUrl + `/${id}/members/${action}`,
      state: { prevUrl: this.props.match.url }
    });
  }

  renderExtras() {
    return (
      <EmbeddedGroupLookup
        endpoint={this.props.endpoint}
        entityDef={this.props.entityDef}
        match={this.props.match}
        className={styles.lookup_margin}
      />
    );
  }

  getRowActions({id, canWrite, permissions, ...otherProps}) {
    const actions = super.getRowActions({id, canWrite, permissions, ...otherProps});
    const addMembersAction = {
      displayId: 'au.group.addMembers',
      onClick: this.onManageMembersBtnClick.bind(this, id, 'add')
    };
    const removeMembersAction = {
      displayId: 'au.group.removeMembers',
      onClick: this.onManageMembersBtnClick.bind(this, id, 'remove')
    };

    actions.splice(2, 0,
      wrapActionWithTracking(removeMembersAction, 'Group', 'List'),
    );
    actions.splice(2, 0,
      wrapActionWithTracking(addMembersAction, 'Group', 'List'),
    );

    return actions;
  }
}
