import React from 'react';
import * as T from 'prop-types';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import ConfirmationDialog from '@au/core/lib/components/objects/ConfirmationDialog';

import { formatMessage } from '../../utils/reactIntl';

export default class EntityDeleteDialog extends React.PureComponent {

  static propTypes = {
    entityType: T.string.isRequired,
    onConfirm: T.func.isRequired,
    onCancel: T.func.isRequired,
    show: T.bool,
    titleDisplayId: T.string,
    confirmDisplayId: T.string,
    confirmBodyDisplayId: T.string,
    showNoUndo: T.bool
  }

  static defaultProps = {
    show: false,
    showNoUndo: true,
    titleDisplayId: 'au.entity.action.delete',
    confirmDisplayId: 'au.entity.delete.confirm',
    confirmBodyDisplayId: 'au.entity.delete.body'
  }

  render() {
    const { 
      show,
      entityType,
      onConfirm,
      onCancel,
      titleDisplayId,
      confirmDisplayId,
      confirmBodyDisplayId,
      showNoUndo 
    } = this.props;

    if (!show) {
      return false;
    }

    return (
      <ConfirmationDialog
        type="alert"
        titleString={formatMessage({ id: titleDisplayId }, {
          entityName: formatMessage({ id: `au.entity.name.${entityType}` })
        })}
        confirmDisplayId={confirmDisplayId}
        onConfirm={onConfirm}
        onCancel={onCancel}
      >
        {showNoUndo &&
          <AutoIntl displayId="au.entity.noUndo" tag="div" />
        }
        <AutoIntl
          displayString={formatMessage({ id: confirmBodyDisplayId }, {
            entityName: formatMessage({ id: `au.entity.name.${entityType}` })
          })}
          tag="div"
        />
      </ConfirmationDialog>
    );
  }

}
