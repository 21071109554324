/**
 * Parsers
 *
 */

import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';

const isEmpty = value => {
  if (typeof value !== 'undefined' && value !== null) {
    return typeof value.trim === 'function' ? Boolean(value.trim()) : true;
  }
  return false;
};

const parsers = {
  aui({ value, arn }) {
    if (value && value.indexOf('aui:') === 0) {
      return value;
    }

    if (!arn) {
      return undefined;
    }

    return `${arn}/${value}`;
  },

  pick({ value, paths }) {
    return pick(value, paths);
  },

  filterEmpty({ value }) {
    if (Array.isArray(value)) {
      return value.filter(isEmpty);
    } else if (typeof value === 'object') {
      return pickBy(value, isEmpty);
    }

    return value;
  }
};

export default parsers;
