import React from 'react';
import cn from 'classnames';
import * as T from 'prop-types';

import { STATUS_UNKNOWN } from '../constants';

import styles from '../css/components/widget_tile.module.scss';

export default class WidgetTile extends React.PureComponent {

  static propTypes = {
    icon: T.string
  }

  static defaultProps = {
    icon: STATUS_UNKNOWN
  }

  render() {
    const { icon } = this.props;

    return (
      <div className={cn(styles.container, styles[icon?.toLowerCase()])}>
        {this.props.children}
      </div>
    );
  }
}