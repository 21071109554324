import { Map as imMap, OrderedMap, Set as imSet, fromJS} from 'immutable';

import {
  immutableSelectorCreator, genKeyedSelector, createDeepEqualSelector
} from '@au/core/lib/selectors/general';

import { statusPageServiceStatus } from '../constants';

const nonObservableKeys = imSet(['fetchTopics', 'streamTopics', 'activities']);
export function observableSelector(provider) {
  // Excludes network functions
  // routing?
  return immutableSelectorCreator(
    state => state.filter((v, k) => !nonObservableKeys.has(k)),
    subState => provider(subState)
  );
}

export const getNotifications = (filteredStatusPageServices) => {
  return({
    servicesDown: filteredStatusPageServices?.filter(s => s.get('status') === statusPageServiceStatus.MAJOR_OUTAGE).size ?? 0,
    servicesDegraded: (filteredStatusPageServices?.filter(s => [statusPageServiceStatus.PARTIAL_OUTAGE, statusPageServiceStatus.DEGRADED_PERFORMANCE].includes(s.get('status')) ).size) ?? 0
  });
};

const getStatusPageSummary = (state) => {
  return state.getIn(['statuspage', 'summary'], imMap());
};

export const getAllStatusPageSummary = immutableSelectorCreator(
  [getStatusPageSummary],
  summary => summary
);

export const getEntities = (state, path) => state.getIn(path, OrderedMap());

/*
  Filtering by entity key may look confusing, but there are cases when the key
  won't be assigned to any of the fields.

  When you're on a child entity page and you do refresh, child entity data
  will be fetched and stored under the parent entity in the store.
  So, now we have a record for the parent entity with the data containing a
  single field `entities`:

    services:
      groups-service:
        groups:
          '123456789': {
            entities: {
              permissions: {
                .... list of fetched permissions here ....
              }
            }
          }
        }
      }
    }

  NOTE: don't change `==` to `===` or it will break Permissions.
 */
const filterValidEntities = immutableSelectorCreator(
  entities => entities,
  entities => entities.filter((e, id) =>
    // TODO: clean this up
    e.some(v => {
      if (v == id) {
        return true;
      }
      // Check nested values for id (like in the case for deployments)
      if (v && v.some && v.some(val => val == id)) {
        return true;
      }

      return false;
    })
  )
);

export const getValidEntities = (state, path) => {
  return filterValidEntities(getEntities(state, path));
};

export const getValidEntity = (state, path, entityId) => {
  return getValidEntities(state, path).get(entityId, imMap());
};

export const getEntityResources = (state, entityDef) => {
  const resources = new Map();

  if (entityDef) {
    const sources = Object.values(entityDef.attributes)
      .filter(attr => attr?.source || attr?.sources || attr?.join)
      .reduce((acc, attr) => {
        if (attr?.source) {
          acc.push(attr.source);
        }
        if (attr?.join) {
          acc.push(attr.join);
        }
        if (attr?.sources) {
          attr.sources.forEach(source => acc.push(source));
        }
        return acc;
      }, []);

    // source and join attributes treated the same way here
    sources.forEach(source => {
      const rKey = `${source.service}-${source.entity}`;
      // Check that resource was not already set for a different attribute
      if (!resources.get(rKey)) {
        const resource = getValidEntities(
          state,
          ['services', source.service, 'entities', source.entity]
        );
        if (resource.size) {
          resources.set(rKey, resource);
        }
      }
    });
  }

  return resources;
};

const joinEntity = (entity, resources, joinAttrs) => {
  for (let { join } of joinAttrs) {
    const targetEntities = resources.get(`${join.service}-${join.entity}`);

    if (!targetEntities) continue;

    let targetJoinOn;
    let entityJoinOn;
    let targetPrefix;
    let entityPrefix;

    if (typeof join.on === 'string') {
      targetJoinOn = entityJoinOn = join.on;
    } else if (typeof join.on === 'object') {
      [targetJoinOn, entityJoinOn] = Object.entries(join.on)[0];
    }

    // A prefix can be used to prepend a target attribute with more context
    if (typeof join.prefix === 'string') {
      targetPrefix = entityPrefix = join.prefix;
    } else if (typeof join.prefix === 'object') {
      targetPrefix = join.prefix['targetPrefix'];
      entityPrefix = join.prefix['entityPrefix'];
    }

    if (targetJoinOn && entityJoinOn) {
      let entityTarget = entityPrefix
        ? `${entityPrefix}${entity.get(entityJoinOn)}`
        : entity.get(entityJoinOn);

      const isTargetEntity = (target) => {
        const foundTarget = targetPrefix
          ? `${targetPrefix}${target.get(targetJoinOn)}`
          : target.get(targetJoinOn);

        return foundTarget === entityTarget;
      };
      if (!join.many) {
        const joinEntity = targetEntities.find(isTargetEntity);
        for (let [original, alias] of Object.entries(join.include)) {
          entity = entity.setIn([alias], joinEntity ? joinEntity.get(original) : entityTarget ?? '');
        }
      } else {
        const joinEntities = targetEntities.filter(isTargetEntity);
        const attrEntities = [];
        joinEntities.forEach((joinEntity) => {
          const modifiedEntity = {};
          for (let [original, alias] of Object.entries(join.include)) {
            modifiedEntity[alias] = joinEntity.get(original) ?? '';
          }
          attrEntities.push(fromJS(modifiedEntity));
        });
        entity = entity.setIn([join.attr], fromJS(attrEntities));
      }
    } else {
      // TODO refactor `joinAttrs` to include attr name
      /* eslint-disable no-console */
      console.warn(
        'Cannot join entities. Check the `join` configuration: ',
        JSON.stringify(join)
      );
      /* eslint-enable no-console */
    }
  }

  return entity;
};

export const getFulfilledEntity = createDeepEqualSelector(
  [ (entity) => entity,
    (entity, entityDef) => entityDef,
    (entity, entityDef, resources) => resources
  ],
  (entity, entityDef, resources) => {
    for (let [attr, props] of Object.entries(entityDef.attributes)) {
      const { join, source, lookup } = props || {};
      if (source && lookup) {
        let resource = resources.get(`${source.service}-${source.entity}`);

        if (resource) {
          entity = entity.setIn([attr], (resource.find(r =>
            r.get(lookup.match) === entity.get(lookup.by)) || imMap()
          ).get(lookup.field));
        }
      }

      if (join) {
        entity = joinEntity(entity, resources, [{ join, attr }]);
      }
    }

    return entity;
  }
);

export function getAllJoinedEntities(entities, resources, joinAttrs) {
  if (joinAttrs.length) {
    return entities.map(entity => joinEntity(entity, resources, joinAttrs));
  }
  return entities;
}

function genGeoShapeSelector() {
  return immutableSelectorCreator(
    [ geoFence => geoFence.get('geoShape') ],
    geoShape => {
      let ext;

      if (geoShape) {
        if (geoShape.has('polygon')) {
          const def = geoShape.get('polygon').toJS();
          def.points.forEach(p => p.lng = p.lon);
          const gPoly = new google.maps.Polygon({ paths: def.points });
          const gBounds = new google.maps.LatLngBounds();
          def.points.forEach(point => gBounds.extend(point));
          ext = { gPoly, gBounds };
        }
        else if (geoShape.has('rectangle')) {
          const def = geoShape.get('rectangle').toJS();
          const sw  = { lat: def.southWest.lat, lng: def.southWest.lon };
          const ne  = { lat: def.northEast.lat, lng: def.northEast.lon };
          const gRect = new google.maps.Rectangle({
            bounds: new google.maps.LatLngBounds(sw, ne)
          });
          ext = { gRect, gBounds: gRect.getBounds() };
        }
        else if (geoShape.has('circle')) {
          const def = geoShape.get('circle').toJS();
          const gCir = new google.maps.Circle({
            center: { lat: def.center.lat, lng: def.center.lon },
            radius: def.radiusMeters
          });
          ext = { gCir, gBounds: gCir.getBounds() };
        }

        return geoShape.merge(imMap(ext));
      }

      return geoShape;
    }
  );
}

export const sharedGeoShapeSelectors = genKeyedSelector(
  genGeoShapeSelector,
  geoFence => geoFence.get('id')
);
