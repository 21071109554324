import TMC from '@autonomic/browser-sdk';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { generateEntityPath } from '../../../utils/entity';
import DefaultView from '../View';

export default class CommandView extends DefaultView {

  oemCommandsEndpoint = new TMC.services.OemCommand({ apiVersion: '3-alpha' }).commands;

  /**
   * NOTE: We're no longer checking current entity.size here to get around the command/commandSummaries combo
   * the entity at this point could be partially populated by commandSummaries,
   * but we should fetch again to make sure we have all fields
   */
  loadData = this.loadData.bind(this);
  loadData() {
    const { endpoint, serviceAlias } = this.props;
    return this.loadCommandData(generateEntityPath(endpoint, serviceAlias)).catch(this.genericErrorHandler);
  }

  /*
   * Since this component is extended for the VehicleCommandView, which has a
   * different path, we need to make this method callable by both with the ability
   * to accommodate the differing paths.
   */
  loadCommandData = this.loadCommandData.bind(this);
  loadCommandData(basePath) {
    const { entityDef, endpoint, actions } = this.props;

    // First check if command is existing from OEM endpoint
    return this.oemCommandsEndpoint.get(this.entityId, this.queryParams).then(successResp => {
      // Since we're not using an enhanced endpoints (as we want to store as a command and not oemCommand)
      // let's map data so it looks more like a command and store
      const data = {
        ...successResp.data,
        id: successResp.data.commandId,
        correlationId: successResp.data.oemCorrelationId,
        currentStatus: successResp.data.status
      };

      actions.getEntitySuccess({
        path: [...basePath, data.id],
        data,
        pkField: entityDef.pkField
      });

      this.setState({ oemCommand: true });

      return successResp;
    }, () => {
      this.setState({ oemCommand: false });
      // Next try command-service endpoint, and fail normally
      return endpoint.get(this.entityId, this.queryParams).then(this.onAfterFetch, () => {
        return endpoint.search({ "ids": [this.entityId] }).then(successResp => {
          // in case data was already deleted and both requests return 404
          // fall back to Commands DB data
          const data = {
            ...successResp.items[0],
          };
          actions.getEntitySuccess({
            path: [...basePath, data.id],
            data,
            pkField: entityDef.pkField
          });

          this.setState({ oemCommand: true, fetching: false });

          if (successResp.items.length === 0) {
            createResponseAlertMessage({status: 404, data: { error: 'Not Found', message: 'Not Found' }})
          }
          return successResp;
        }, this.genericErrorHandler);
      }, this.genericErrorHandler);
    });
  }

  getNavLinks() {
    //Hides the state transitions for commands from the oem command service as they will not be available through the command api
    if(this.state.oemCommand || this.state.oemCommand === undefined){
      return super.getNavLinks().filter(navLink => navLink.labelId !== 'au.entity.title.stateTransitions');
    }

    return super.getNavLinks();
  }

}
