import React from 'react';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import { BUTTON_TYPE_SECONDARY } from '@au/core/lib/components/elements/AuButton';
import ConfirmationDialog from '@au/core/lib/components/objects/ConfirmationDialog';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { history as browserHistory } from '../../../history';
import DefaultView from "../View";

import styles from '../../../css/components/user_group_list.module.scss';

export default class UserGroupView extends DefaultView {

  onDeleteBtnClick() {
    const { entity } = this.props;
    this.setState({ groupDisplayName: entity.get('displayName'), groupId: entity.get('id') });
    this.showConfirmDeleteUserGroupDialog();
  }

  handleOnDeleteConfirm = this.handleOnDeleteConfirm.bind(this);
  handleOnDeleteConfirm() {
    const { endpoint, serviceAlias } = this.props;
    const { groupId, groupDisplayName } = this.state;

    endpoint.delete(groupId).then((response) => {
      this.deletedGroupName = response._data.displayName;
      browserHistory.push({
        pathname: `/services/${serviceAlias}/userGroups/list`,
        state: {
          showSuccessDeleteUserGroupDialog: true,
          groupName: groupDisplayName
        }
      });
    }, createResponseAlertMessage);

    this.hideConfirmDeleteUserGroupDialog();
  }

  showConfirmDeleteUserGroupDialog = this.showConfirmDeleteUserGroupDialog.bind(this);
  showConfirmDeleteUserGroupDialog() {
    this.setState({ showConfirmDeleteUserGroupDialog: true });
  }

  hideConfirmDeleteUserGroupDialog = this.hideConfirmDeleteUserGroupDialog.bind(this)
  hideConfirmDeleteUserGroupDialog() {
    this.setState({ showConfirmDeleteUserGroupDialog: false });
  }

  renderConfirmDeleteUserGroupDialog() {
    const { showConfirmDeleteUserGroupDialog, groupDisplayName } = this.state;

    if (!showConfirmDeleteUserGroupDialog) {
      return false;
    }

    return (
      <ConfirmationDialog
        key="ConfirmDeleteUserGroup"
        titleId="au.userGroup.confirmDeleteTitle"
        confirmDisplayId="au.entity.delete.confirm"
        type="warning"
        buttonType={BUTTON_TYPE_SECONDARY}
        className={styles.container}
        headerClassName={styles.confirmation_header}
        onConfirm={this.handleOnDeleteConfirm}
        onCancel={this.hideConfirmDeleteUserGroupDialog}
      >
        <AutoIntl
          className={styles.info}
          displayId="au.userGroup.dialogs.displayName" values={{
            b: chunks => <strong className={styles.display_name}>{chunks}</strong>,
            displayName: groupDisplayName
          }}
          tag="div"
        />
        <AutoIntl
          className={styles.description}
          displayId="au.userGroup.confirmDelete"
          values={{ b: chunks => { chunks; }, br: <br /> }}
          tag="div"
        />
      </ConfirmationDialog>
    );
  }

  renderDialogs() {
    const dialogs = super.renderDialogs();
    dialogs.push(this.renderConfirmDeleteUserGroupDialog());
    return dialogs;
  }
}
