import React from "react";
import { injectIntl } from "react-intl";
import * as T from "prop-types";
import throttle from 'lodash/throttle';

import AuInput, { INPUT_TYPE_TEXT } from "@au/core/lib/components/elements/AuInput";
import AuButton from "@au/core/lib/components/elements/AuButton";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import { BUTTON_TYPE_TERTIARY } from "@au/core/lib/components/elements/AuButton";

import { ruleRunner, run } from "../../utils/validationRuleRunner";
import { regexp, required, shouldNotBeInList } from "../../utils/validationRules";
import { Filter } from "../../AuPropTypes";

import styles from "../../css/components/filters/key_value.module.scss";

const noNonKeyCharacters = regexp("^[\\w-]+$", "au.validation.invalidCharacter");
const noNonValueCharacters = regexp("^[\\w0-9 ;:.,/()?&$%@+=*+#[\\]-]+$", "au.validation.invalidCharacter");
const throttleWaitTime = 25;

export class KeyValue extends React.Component {
  static propTypes = {
    selection: T.arrayOf(Filter).isRequired,
    onChange: T.func.isRequired,
    onInit: T.func.isRequired
  };

  static defaultProps = {
    selection: []
  };

  keyPlaceholderId = 'au.filters.keyValue.key';
  valuePlaceholderId = 'au.filters.keyValue.value';

  state = this.getInitialState();

  constructor(props) {
    super(props);

    this.handleKeyChangeThrottled = throttle(this.onKeyChange, throttleWaitTime);
    this.handleValueChangeThrottled = throttle(this.onValueChange, throttleWaitTime);
  }

  getInitialState() {
    return {
      key: "",
      value: "",
      keyErrorId: "",
      valueErrorId: ""
    };
  }

  componentDidMount() {
    this.props.onInit(this.props.selection);
  }

  resetComponent() {
    this.setState(this.getInitialState());
  }

  handleClick = this.handleClick.bind(this);

  handleClick() {
    const {key, value} = this.state;

    const errors = this.checkFormForErrors();
    if (errors) {
      this.setState({keyErrorId: errors.key, valueErrorId: errors.value});
    } else {
      const selectedItems = [...this.props.selection, {bubbleText: key + ': "' + value + '"', urlValue: key + ":" + value}];
      this.props.onChange(selectedItems);
      this.resetComponent();
    }
  }

  getKeyValidations() {
    const previousKeys = this.props.selection.map(previousItem => previousItem.urlValue.split(":")[0]);
    return [required, noNonKeyCharacters, shouldNotBeInList(previousKeys)];
  }

  getValueValidations() {
    return [required, noNonValueCharacters];
  }

  checkFormForErrors() {
    const errors = run(this.state, [
      ruleRunner("key", "au.entity.name", ...this.getKeyValidations()),
      ruleRunner("value", "au.entity.value", ...this.getValueValidations())
    ]);

    if (Object.keys(errors).length !== 0) {
      return {key: errors?.key?.errDisplayId ?? "", value: errors?.value?.errDisplayId ?? ""};
    } else {
      return undefined;
    }
  }

  onKeyChange = (event) => this.setState({key: event.target.value, keyErrorId: ""});
  onKeyClear = () => this.setState({key: "", keyErrorId: ""});
  onValueChange = (event) => this.setState({value: event.target.value, valueErrorId: ""});
  onValueClear = () => this.setState({value: "", valueErrorId: ""});

  keyInput() {
    const {key, keyErrorId} = this.state;

    return <AuInput
      className={styles.key_input}
      placeholderId={this.keyPlaceholderId}
      type={INPUT_TYPE_TEXT}
      value={key}
      onChange={this.handleKeyChangeThrottled}
      onClear={this.onKeyClear}
      showError={this.state.keyErrorId !== ""}
      error={{
        errDisplayId: keyErrorId,
        fieldDisplayId: "au.entity.name",
        values: {validCharacters: "a-z, A-Z, 0-9, _, -"}
      }}
      createMode={true}
    />;
  }

  render() {
    const {value, valueErrorId} = this.state;

    return (
      <div className={styles.key_value}>
        <AutoIntl className={styles.key_value_text} displayId="au.filters.conditionAnd"/>
        {this.keyInput()}
        <AuInput
          className={styles.value_input}
          placeholderId={this.valuePlaceholderId}
          type={INPUT_TYPE_TEXT}
          value={value}
          onChange={this.handleValueChangeThrottled}
          onClear={this.onValueClear}
          showError={Boolean(valueErrorId)}
          error={{
            errDisplayId: valueErrorId,
            fieldDisplayId: "au.entity.value",
            values: {validCharacters: "a-z, A-Z, 0-9, _ ;:.,/()?&$%@+=*+#-[]"}
          }}
          createMode={true}
        />
        <AuButton
          type={BUTTON_TYPE_TERTIARY}
          onClick={this.handleClick}
          displayId={"au.filters.apply"}
        />
      </div>
    );
  }
}

export default injectIntl(KeyValue);
