import cloneDeep from 'lodash/cloneDeep';

import { getLoggers } from '../logger';
import FeedTree from './tree';
import iMap from '../dataStructures/indexedMap';
import { flipDirection, ensureFields } from './common';

import { FLOW_ARN, FORK_ARN, PROCESSOR_ARN, TAP_ARN, PROCESSOR_CUSTOM_PREFIX, DIRECT_ROUTE_ARN  } from '../../constants';

const { info, exception } = getLoggers('FeedGraph');

const toMap = items => items.reduce((m,i) => m.set(i.aui, i), new Map());

export default class FeedGraph {
  static flowReqFields = ['flowAui', 'inputAuis', 'inputTaps', 'outputAuis', 'outputTaps'];
  static tapReqFields = ['tapAui', 'inputFlow', 'outputFlow'];
  static processorReqFields = ['aui', 'inputFlow', 'outputFlow'];
  static forkReqFields = ['aui', 'input', 'outputs'];
  static directRouteReqFields = ['aui', 'input', 'outputs'];

  constructor(flows, taps, processors, forks, routes, skipInferredNodes=false) {
    ensureFields(flows, this.constructor.flowReqFields);
    ensureFields(taps, this.constructor.tapReqFields);
    ensureFields(processors, this.constructor.processorReqFields);
    ensureFields(forks, this.constructor.forkReqFields);
    ensureFields(routes, this.constructor.directRouteReqFields);

    // always index by displayAui if possible
    this._flows = toMap(flows.map(f => ({ ...cloneDeep(f), aui: f.flowAui, idAui: f.aui, inferred: false, type: 'flow' })));
    this._taps = toMap(taps.map(t => ({ ...cloneDeep(t), aui: t.tapAui, idAui: t.aui, inferred: false, type: 'tap' })));
    this._processors = toMap(processors.map(p => ({ ...cloneDeep(p), inferred: false, type: 'processor' })));
    this._forks = toMap(forks.map(f => ({ ...cloneDeep(f), inferred: false, type: 'fork' })));
    this._routes = toMap(routes.map(r => ({ ...cloneDeep(r), inferred: false, type: 'route' })));


    // lookup needed to easily find nodes by displayName-based-AUI and
    // ID-based-AUI
    this._auiLookup = new Map(
      [...this.flows, ...this.taps, ...this.processors, ...this.forks, ...this.routes]
    );
    for (let node of this._auiLookup.values()) {
      if (node.idAui) {
        this._auiLookup.set(node.idAui, node);
      }
    }

    this._skipInferredNodes = skipInferredNodes;


    // normalize nodes to `input` and `output` AUI string sets
    for (let flow of this.flows.values()) {
      // HACK fix bad processors Auis (processor-crud-UUID)
      flow.input = new Set([...flow.inputTaps, ...flow.inputAuis.map(aui =>
        aui.replace(PROCESSOR_CUSTOM_PREFIX, `${PROCESSOR_ARN}/`)
      )]);
      flow.output = new Set([...flow.outputTaps, ...flow.outputAuis.map(aui =>
        aui.replace(PROCESSOR_CUSTOM_PREFIX, `${PROCESSOR_ARN}/`)
      )]);
    }
    for (let tap of this.taps.values()) {
      tap.input = new Set([tap.inputFlow]);
      tap.output = new Set([tap.outputFlow]);
    }
    for (let processor of this.processors.values()) {
      processor.input = new Set([processor.inputFlow]);
      processor.output = new Set([processor.outputFlow]);
    }
    for (let fork of this.forks.values()) {
      fork.input = new Set([fork.input.aui]); // NOTE: overwrites original prop
      fork.output = new Set([...fork.outputs.map(f => f.aui)]);
    }
    for (let route of this.routes.values()) {
      route.input = new Set([route.input?.aui]);
      route.output = new Set([...route.outputs.map(r => r.aui)]);
    }

    // filter out empty input/output made by legend
    for (let node of [...this.flows.values(), ...this.taps.values(), ...this.processors.values(), ...this.forks.values(), ...this.routes.values()]) {
      node.input = new Set([...node.input].filter(i => i));
      node.output = new Set([...node.output].filter(i => i));
    }

    // set common `aui` as a lookup ID and convert to a map
    // the `aui` will be displayName-based if possible and ID-based otherwise
    //  (inferred or types that don't use displayName-based AUIs like processors
    //  and forks)
    // This is useful so users can share links to node types they do not have
    // access to but might see as inferred.

    // infer nodes that the user cannot directly see
    for (let [nodeAui, node] of [...this.flows, ...this.taps, ...this.processors, ...this.forks, ...this.routes]) {
      for (let aui of node.input) {
        this._inferNode(aui, 'output', nodeAui);
      }
      for (let aui of node.output) {
        this._inferNode(aui, 'input', nodeAui);
      }
    }

    this._nodes = new Map([...this.flows, ...this.taps, ...this.processors, ...this.forks, ...this.routes]);

    // fill in missing connections
    for (let node of this.nodes.values()) {
      const input = new iMap();
      for (let aui of node.input) {
        const fromNode = this._auiLookup.get(aui);
        if (!fromNode) exception(`${aui} does not exist`);
        input.set(fromNode.aui, fromNode);
      }
      node.input = input;

      const output = new iMap();
      for (let aui of node.output) {
        const toNode = this._auiLookup.get(aui);
        if (!toNode) exception(`${aui} does not exist`);
        output.set(toNode.aui, toNode);
      }
      node.output = output;
    }

    info('constructed');
  }

  _inferNode(aui, kinDir, kinAui) {
    let node = this._auiLookup.get(aui);

    if (node) {
      node[kinDir].add(kinAui);
    } else {
      if (aui.startsWith(`${FLOW_ARN}/`)) {
        info('inferring flow', aui);
        node = { inferred: true, aui, type: 'flow', input: new Set(), output: new Set() };
        this.flows.set(aui, node);
      } else if (aui.startsWith(`${TAP_ARN}/`)) {
        info('inferring tap', aui);
        node = { inferred: true, aui, type: 'tap', input: new Set(), output: new Set() };
        this.taps.set(aui, node);
      } else if (aui.startsWith(`${PROCESSOR_ARN}/`)) {
        // NOTE: processors are not inferred since Java backend does not acknowlege Go backend for processors
        // leaving this here just in case this ever changes
        info('inferring processor', aui);
        node = { inferred: true, aui, type: 'processor', input: new Set(), output: new Set() };
        this.processors.set(aui, node);
      } else if (aui.startsWith(`${FORK_ARN}/`)) {
        info('inferring fork', aui);
        node = { inferred: true, aui, type: 'fork', input: new Set(), output: new Set() };
        this.forks.set(aui, node);
      } else if (aui.startsWith(`${DIRECT_ROUTE_ARN}/`)) {
        info('inferring direct route flow', aui);
        node = { inferred: true, aui, type: 'route', input: new Set(), output: new Set() };
        this.routes.set(aui, node);
      } else {
        exception(`cannot infer node of unknown type from ${aui}`);
      }
    }

    node[kinDir].add(kinAui);
    this._auiLookup.set(aui, node);
  }

  // no order
  get flows() {
    return this._flows;
  }

  // no order
  get taps() {
    return this._taps;
  }

  // no order
  get processors() {
    return this._processors;
  }

  get forks() {
    return this._forks;
  }

  get routes() {
    return this._routes;
  }

  // no order
  get nodes() {
    return this._nodes;
  }

  get size() {
    return this._nodes.size;
  }

  get skipInferredNodes() {
    return this._skipInferredNodes;
  }

  getTrees(direction='output') {
    const trees = new Map();
    for (let rootNode of this.getRootNodes(direction).values()) {
      const tree = this.getTree(rootNode, direction);
      trees.set(tree.id, tree);
    }
    return trees;
  }

  getRootNodes(direction='output') {
    const nodes = [
      ...this._getRoots(direction, 'flows').values(),
      ...this._getRoots(direction, 'taps').values(),
      ...this._getRoots(direction, 'processors').values(),
      ...this._getRoots(direction, 'forks').values(),
      ...this._getRoots(direction, 'routes').values()
    ];
    nodes.sort((a, b) => a[direction].size - b[direction].size);
    return new iMap(nodes.map(n => [n.aui, n]));
  }

  // returns an indexed map of roots (is the start for the direction) ordered
  // by the count of direct children
  _getRoots(direction, type) {
    const oppDirection = flipDirection(direction);
    const roots = [];
    for (let node of this[type].values()) {
      if (node[oppDirection].size) continue;
      roots.push(node);
    }
    roots.sort((a, b) => a[direction].size - b[direction].size);
    return new iMap(roots.map(n => [n.aui, n]));
  }

  getTree(rootNode, direction='output') {
    if (typeof rootNode === 'string') {
      if (!this.nodes.has(rootNode)) exception(`${rootNode} does not exist`);
      rootNode = this.nodes.get(rootNode);
    }
    return new FeedTree(this.flows, this.taps, this.processors, this.forks, rootNode, direction, undefined, this.skipInferredNodes);
  }

  _getPathsToLeafs(start, direction, curPath=[], paths=[]) {
    if (!start[direction].size) {
      paths.push([...curPath, start]);
    }

    for (let adjNode of start[direction].values()) {
      this._getPathsToLeafs(adjNode, direction, [...curPath, start], paths);
    }

    return paths;
  }

  // DFS order
  getPathsToLeafs(start, direction='output') {
    if (typeof start === 'string') {
      if (!this.nodes.has(start)) exception(`${start} does not exist`);
      start = this.nodes.get(start);
    }
    return this._getPathsToLeafs(start, direction);
  }
}
