import React from "react";
import cloneDeep from 'lodash.clonedeep';
import * as T from "prop-types";
import cn from 'classnames';

import TMC from '@autonomic/browser-sdk';
import AuDropDown from '@au/core/lib/components/elements/AuDropDown';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import { createResponseAlertMessage } from "@au/core/lib/components/objects/AlertMessage";

import { formatMessage } from '../utils/reactIntl';
import { enhanceSdkEndpoint } from '../utils/api';

import fieldset from '../css/fieldset.module.scss';
import styles from '../css/components/flow_editor.module.scss';

export default class FlowEditor extends React.Component {

  static propTypes = {
    idx: T.number,
    entity: T.object,
    defaultPropValue: T.string,
    defaultValue: T.number,
    className: T.string,
    createMode: T.bool,
    inputFlow: T.string,
    showErrors: T.bool,
    onChange: T.func
  }

  static defaultProp = {
    defaultValue: 24
  }

  constructor(props) {
    super(props);

    this.state = {
      flows: [],
      options: [],
      outputs: [{ aui: '', displayName: '', idx: 0, foundSelection: null, shardCount: 24, showError: false, userEntry: false }]
    }

    if (this.props.maxOutputs !== 1 && this.props.outputs && Array.isArray(this.props.outputs)) {
      this.state.outputs = this.props.outputs.map((output, index) => ({
        aui: output.aui,
        displayName: output.displayName,
        outputId: output.outputId,
        idx: index,
        foundSelection: true,
        userEntry: output.userEntry
      }));

      this.commitChanges();
    }

    if (this.props.maxOutputs == 1) {
      this.state.outputs[0].aui = this.props.entity?.outputFlowId
      this.state.outputs[0].displayName = this.props.entity?.outputFlow
      this.state.outputs[0].id = this.props.entity?.outputFlowId

      this.commitChanges();
    }

    this.endpoint = enhanceSdkEndpoint(
      new TMC.services.Feed(),
      'flows',
      props.actions
    );
  }

  componentDidMount() {
    this.endpoint.list().then(resp => resp.getItems(Infinity).then(items => {
      const flowOptions = items.map((flow) => ({
        val: flow.id,
        displayString: flow.displayName
      }));
      this.setState({ flows: flowOptions });
    }, createResponseAlertMessage));

    const options = [
      { val: 12, displayString: formatMessage({ id: `au.shardCount.12` }), className: fieldset.option_padding_first },
      { val: 24, displayString: formatMessage({ id: `au.shardCount.24` }), className: fieldset.option_hint },
      { val: 48, displayString: formatMessage({ id: `au.shardCount.48` }), className: fieldset.option_padding },
      { val: 96, displayString: formatMessage({ id: `au.shardCount.96` }), className: fieldset.option_padding }
    ]

    this.setState({ options });
  }

  componentDidUpdate() {
    const { entity, defaultPropValue, idx, createMode } = this.props
    const { outputs } = this.state;

      if (JSON.parse(sessionStorage.getItem('created')) == false && JSON.parse(sessionStorage.getItem('updatedFlows')) && JSON.parse(sessionStorage.getItem('updatedFlows')).length > this.state.flows.length) {
        this.setState({ flows: JSON.parse(sessionStorage.getItem('updatedFlows')) })
        if (outputs[idx].aui && JSON.parse(sessionStorage.getItem('updatedFlows')).find((opt) => opt.displayString == outputs[idx].aui)) {
          this.setState(prevState => {
            const outputs = [...prevState.outputs];
            outputs[idx].foundSelection = true
          }, this.commitChanges);
        }
      }

      if (outputs[idx].aui && JSON.parse(sessionStorage.getItem('updatedFlows')) && JSON.parse(sessionStorage.getItem('updatedFlows')).find((opt) => opt.displayString == outputs[idx].aui) && outputs[idx].foundSelection == false) {
        this.setState(prevState => {
          const outputs = [...prevState.outputs];
          outputs[idx].foundSelection = true
        }, this.commitChanges);
      }

      if (createMode === true && entity && entity[defaultPropValue] && (entity[defaultPropValue] !== outputs[0].displayName && outputs[0].userEntry === false)) {
        const foundSelection = this.state.flows.find(opt => entity[defaultPropValue] === opt.displayString);
        this.setState(prevState => {
          const outputs = [...prevState.outputs];
          outputs[0].displayName = entity[defaultPropValue];
          outputs[0].aui = foundSelection?.val || entity[defaultPropValue];
          outputs[0].showError = entity[defaultPropValue].length === 0;
          outputs[0].foundSelection = Boolean(foundSelection);

          return outputs;
        }, this.commitChanges);
      } else if (outputs[idx].userEntry && outputs[0] && outputs[0]?.displayName === '' && entity[defaultPropValue] === '') {
        this.setState(prevState => {
          const outputs = [...prevState.outputs];
          outputs[0].userEntry = false;
        });
      }
  }

  handleDropdownChange(idx, selection) {
    this.setState(prevState => {
      const foundSelection = this.state.flows.find(opt => selection === opt.val);
      const outputs = cloneDeep(prevState.outputs);
      outputs[idx].aui = selection;
      outputs[idx].displayName = foundSelection?.displayString || selection;
      outputs[idx].foundSelection = Boolean(foundSelection);
      outputs[idx].showError = outputs[idx].displayName.length === 0;
      outputs[idx].userEntry = true;

      return { outputs };
    }, this.commitChanges);
  }

  onChange(idx, outputStr) {
    this.setState(prevState => {
      const foundSelection = this.state.flows.find(opt => outputStr === opt.displayString);
      const outputs = cloneDeep(prevState.outputs);
      outputs[idx].aui = foundSelection?.val || outputStr;
      outputs[idx].displayName = foundSelection?.displayString || outputStr;
      outputs[idx].foundSelection = Boolean(foundSelection);
      if (outputStr !== '') {
        outputs[idx].showError = false;
      }
      return { outputs };
    }, this.commitChanges);
  }

  onClear(idx) {
    this.setState(prevState => {
      const outputs = cloneDeep(prevState.outputs);
      outputs[idx].displayName = '';
      outputs[idx].aui = '';
      outputs[idx].userEntry = true;
      return { outputs };
    }, this.commitchanges);
  }

  handleShardCountChange(idx, selection) {
    this.setState(prevState => {
      const outputs = cloneDeep(prevState.outputs);
      outputs[idx].shardCount = selection;
      return { outputs };
    }, this.commitChanges);
  }

  commitChanges() {
    const { outputs } = this.state;
    this.props.onChange(outputs);
  }

  render() {
    const { createMode, showErrors, inputFlow, idx, className } = this.props;
    const { flows, outputs } = this.state;

    return (
      <div className={styles.container}>
        <div className={cn(styles.flow_dropdown, className)}>
          <AuDropDown
            selection={outputs[idx].displayName}
            options={flows.filter(flow => inputFlow !== flow.val)}
            allowTyping={true}
            createMode={createMode}
            selectOption={this.handleDropdownChange.bind(this,idx)}
            onChange={this.onChange.bind(this, idx)}
            showError={outputs[idx].showError}
            onClear={this.onClear.bind(this, idx)}
          />
          {(outputs[idx].showError || showErrors && !outputs[idx].showError && !outputs[idx].aui) &&
            <AutoIntl
              className={styles.error}
              displayId='au.validation.isRequired'
              values={{ field: <AutoIntl displayId='au.entity.attr.outputFlow' /> }}
            />}
          {outputs[idx].aui && outputs[idx].foundSelection === false &&
            <AutoIntl
              className={fieldset.notice}
              displayId='au.entity.attr.flows.hint'
            />}
        </div>
        {outputs[idx].aui && outputs[idx].foundSelection === false &&
          <AutoIntl displayId={'au.entity.attr.shardCount'} className={styles.flow_label} />}
        {outputs[idx].aui && outputs[idx].foundSelection === false &&
          <AuDropDown
            createMode={createMode}
            className={fieldset.number_dropdown}
            defaultValue={this.props.defaultValue}
            options={this.state.options}
            optionsClassName={fieldset.number_options}
            selection={outputs[idx].shardCount}
            selectOption={this.handleShardCountChange.bind(this, idx)}
            hint={<AutoIntl key={this.state.options.length} displayId={'au.shardCount.hint'} className={fieldset.options_hint} />}
          />}
      </div>
    )
  }
}