import React from 'react';
import * as T from 'prop-types';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import { trackException } from '@au/core/lib/utils/ga';

import styles from '../css/components/binding_event.module.scss';

const VALID_STATES = [
  'bind',
  'unbind',
];

function BindingEvent({ event }) {
  let bindState = event.toLowerCase();
  if (!VALID_STATES.includes(bindState)) {
    bindState = 'unknown_event'; // casing for CSS/Locales
    // Track unknown events
    trackException(`Unknown binding event found - ${event}`);
  }

  return (
    <AutoIntl
      displayId={`au.entity.${bindState}`}
      className={styles[bindState]}
      tag="div"
    />
  );
}

BindingEvent.propTypes = {
  event: T.oneOf(VALID_STATES.map(s => s.toUpperCase()))
};

export default BindingEvent;
