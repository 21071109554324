import React from 'react';

import styles from '../css/components/screen_width_detector.module.scss';

export const screenWidths = ['desktop', 'tabletLandscape', 'tabletPortrait', 'mobile'];

export default class ScreenWidthDetector extends React.Component {

  visible(refName) {
    if (!this.refs[refName]) { return; }

    return window.getComputedStyle(this.refs[refName]).display !== 'none';
  }

  updateScreenWidth = this.updateScreenWidth.bind(this);
  updateScreenWidth() {
    for (var i in screenWidths) {
      if (this.visible(screenWidths[i]) && this.props.screenWidth !== screenWidths[i]) {
        this.props.onChange(screenWidths[i]);
      }
      if (this.visible(screenWidths[i])) {
        return;
      }
    }
  }

  UNSAFE_componentWillUpdate() {
    this.updateScreenWidth();
  }

  componentDidMount() {
    this.updateScreenWidth();
  }

  UNSAFE_componentWillMount() {
    this.updateScreenWidth();
    window.addEventListener('resize', this.updateScreenWidth);
  }

  componentWillUnmount() {
    this.updateScreenWidth();
    window.removeEventListener('resize', this.updateScreenWidth);
  }

  render() {
    return (
      <div>
        <div ref="mobile" className={styles.mobile}></div>
        <div ref="tabletPortrait" className={styles.tablet}></div>
        <div ref="tabletLandscape" className={styles.tablet_ls}></div>
        <div ref="desktop" className={styles.desktop}></div>
      </div>
    );
  }
}
