import { connect } from 'react-redux';

import SmsHistoryView from '../components/SmsHistoryView';
import { defaultMapDispatchToProps } from '../utils/ConnectDefaults';

export default connect(
  (state) => {
    return state.get('popout').get('componentProps').toJS();
  },
  defaultMapDispatchToProps
)(SmsHistoryView);