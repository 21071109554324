import { fromJS } from 'immutable';

export default {
  OPEN_POPOUT: (state, action) => {
    const initialWidth = state.getIn(['popout', 'props', 'initialWidth']);
    const { popoutProps } = action;

    if (initialWidth && popoutProps.maintainWidth) {
      popoutProps.props.initialWidth = initialWidth;
    }

    return state.set('popout', fromJS(popoutProps));
  },
  CLOSE_POPOUT: (state) => {
    const initialWidth = state.getIn(['popout', 'props', 'initialWidth']);
    return state.set('popout', fromJS({ props: { initialWidth } }));
  },
  PERSIST_POPOUT_WIDTH: (state, action) => {
    return state.setIn(['popout', 'props', 'initialWidth'], action.width);
  },
  RESET_POPOUT_WIDTH: (state) => {
    return state.deleteIn(['popout', 'props', 'initialWidth']);
  },
};
