import React from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import cloneDeep from 'lodash.clonedeep';
import * as T from "prop-types";

import AuDropDown from '@au/core/lib/components/elements/AuDropDown';
import AuButton from '@au/core/lib/components/elements/AuButton';
import AuInput from '@au/core/lib/components/elements/AuInput';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import shared from '../shared';
import { TAP_FILTERS_MODES } from '../constants';
import { formatMessage } from '../utils/reactIntl';
import auFormatters from '../utils/formatters';
import TapFilters from '../containers/TapFilters';
import FlowEditor from '../containers/FlowEditor';
import fieldset from '../css/fieldset.module.scss';
import styles from '../css/components/output_editor.module.scss';

const BUSINESS_UNIT = 'BusinessUnit';
const REGION = 'Region';

export default class OutputEditor extends React.Component {

  static propTypes = {
    mode: T.string,
    filters: T.array,
    filterTypeOptions: T.array,
    formatters: T.object,
    onChange: T.func,
    businessUnit: T.string,
    region: T.string,
    inputFlow: T.string,
    actions: T.object,
    showErrors: T.bool,
    createMode: T.bool,
    entity: T.object,
    maxOutputs: T.number,
    showOverride: T.bool,
    validationModes: T.array,
    defaultValue: T.number,
    defaultPropValue: T.string
  }

  static defaultProps = {
    showErrors: false,
    maxOutputs: 40,
    showOverride: true,
    validationModes: ['permit']
  }

  constructor(props) {
    super(props);

    this.state = {
      outputs: [{
        aui: '',
        displayName: '',
        filters: [],
        idx: 0,
        foundSelection: null,
        hasDuplicate: false,
        businessUnit: "",
        region: "",
        showBuR: false,
        shardCount: 24,
        userEntry: false
      }],
      lastKnownIdx: 0,
      businessUnitItems: [],
      regionItems: [],
    };

    if (this.props.entity && Array.isArray(this.props.entity.outputs)) {
      this.state.outputs = this.props.entity.outputs.map((output, index) => ({
        aui: output.aui,
        displayName: output.displayName,
        businessUnit: output.businessUnit,
        region: output.region,
        filters: output.filters ?? [],
        outputId: output.outputId,
        idx: index,
        foundSelection: true,
        hasDuplicate: false,
        showBuR: Boolean(output.businessUnit || output.region),
        overriddenBusinessUnit: Boolean(output.businessUnit),
        overriddenRegion: Boolean(output.region)
      }));

      this.commitChanges();
    }

    if (this.props.entity && Array.isArray(this.props.entity.filters)) {
      this.state.outputs[0].filters = this.props.entity.filters
    }

    const { requiredTags = [] } = shared.account;

    const businessUnit = requiredTags.find((item) => item.key === BUSINESS_UNIT);
    const region = requiredTags.find((item) => item.key === REGION);
    if (businessUnit) {
      this.state.businessUnitItems = businessUnit.values.map(value => ({ displayString: value, val: value }));
    }
    if (region) {
      this.state.regionItems = region.values.map(value => ({ displayString: value, val: value }));
    }
  }

  addOutputRow = this.addOutputRow.bind(this);
  addOutputRow() {
    this.setState(prevState => {
      const outputs = cloneDeep(prevState.outputs);
      outputs.push({ aui: '', displayName: '', filters: [], idx: prevState.lastKnownIdx + 1 });
      return { outputs, lastKnownIdx: prevState.lastKnownIdx + 1 };
    }, this.commitChanges);
  }

  toggleOverrideFields(idx) {
    this.setState(prevState => {
      const outputs = [...prevState.outputs];
      outputs[idx].showBuR = !outputs[idx].showBuR;

      return { outputs };
    });
  }

  handleFieldChange(idx, selection) {
    this.setState(prevState => {
      const outputs = cloneDeep(prevState.outputs);
      outputs[idx].filters = selection;
      return { outputs };
    }, this.commitChanges);
  }

  onChange(idx, outputStr) {
    this.setState(prevState => {
      const outputs = cloneDeep(prevState.outputs);
      outputs[idx].aui = outputStr[idx].aui;
      outputs[idx].displayName = outputStr[idx].displayName;
      outputs[idx].foundSelection = outputStr[idx].foundSelection;
      if (outputStr !== '') {
        outputs[idx].showError = false;
      }
      return { outputs };
    }, this.commitChanges);
  }

  onOverrideFieldChange(idx, field, selection) {
    this.setState(prevState => {
      const outputs = cloneDeep(prevState.outputs);
      outputs[idx][field] = selection;
      return { outputs };
    }, this.commitChanges);
  }

  handleOverrideFieldDropdown(idx, field, selection) {
    this.setState(prevState => {
      const outputs = cloneDeep(prevState.outputs);
      outputs[idx][field] = selection;
      return { outputs };
    }, this.commitChanges);
  }

  removeOutputRow(idx) {
    this.setState(prevState => {
      const outputs = cloneDeep(prevState.outputs);
      outputs.splice(idx, 1);
      if (outputs.length === 0) {
        outputs.push({ aui: '', displayName: '', filters: [], idx: 0 });
      }
      return { outputs };
    }, this.commitChanges);
  }

  commitChanges() {
    const { outputs } = this.state;

    const dupes = this.state.outputs.reduce((dupes, output) => {
      return { ...dupes, [output.aui]: (dupes[output.aui] || 0) + 1 };
    }, {});
    for (let o of this.state.outputs) {
      o.hasDuplicate = o.aui !== "" && dupes[o.aui] > 1;
    }
    this.props.onChange(outputs);
  }

  renderOutputRow = this.renderOutputRow.bind(this)
  renderOutputRow(output, idx) {
    // output.idx is only for key
    // idx is for events

    const { businessUnitItems, regionItems } = this.state;
    const { inputFlow, showErrors, createMode, maxOutputs, entityType } = this.props;
    const canDelete = this.state.outputs.length !== 1;

    return (
      <div className={styles.row_container} key={`key_${output.idx}_${idx}`}>
        <div className={styles.row}>
          <div className={styles.editor_container}>
            <div className={styles.flow_container}>
              <AutoIntl displayId={'au.entity.attr.flow'} className={styles.flow_label} />
              <div className={styles.flow_inputs}>
                <FlowEditor
                  onChange={this.onChange.bind(this, idx)}
                  inputFlow={inputFlow}
                  showError={output?.showError || (showErrors && !output.displayName)}
                  createMode={createMode}
                  shardCountSelection={output.shardCount}
                  idx={idx}
                  outputs={this.state.outputs}
                  entity={this.props.entity}
                  defaultValue={this.props.defaultValue}
                  defaultPropValue={this.props.defaultPropValue}
                  className={styles.flow_dropdown}
                  maxOutputs={maxOutputs}
                />
              </div>
            </div>
            <div className={styles.filter_container}>
              <AutoIntl displayId={'au.entity.attr.filters'} className={styles.label} />
              <TapFilters
                mode={TAP_FILTERS_MODES.CREATE}
                filters={output.filters}
                formatters={auFormatters}
                onChange={this.handleFieldChange.bind(this, idx)}
                filterTypeOptions={this.props.filterTypeOptions}
                rowLimit={this.props.filterLimit}
                validationModes={this.props.validationModes}
                className={styles.tap_filters}
                showVinLimit={true}
                hideSequenceColumn={this.props.hideSequenceColumn}
                entityType={entityType}
              />
            </div>
            {this.props.showOverride && <AuInput
              type={'checkbox'}
              caption={formatMessage({ id: 'au.forks.overrideBusinessUnitorRegion' })}
              className={styles.checkbox}
              checked={output.showBuR}
              onChange={this.toggleOverrideFields.bind(this, idx)}
            />}
            {this.state.outputs[idx].hasDuplicate && <div className={cn(fieldset.notice, styles.hint)}>
              <FormattedMessage id='au.entity.attr.override.hint'
                values={{ b: chunks => <strong>{chunks}</strong> }}
              />
            </div>}
            {output.showBuR &&
              <div className={styles.override_fields}>
                <div className={styles.businessUnitRegionNotice}><AutoIntl displayId={'au.forks.businessUnitorRegionForm'} /></div>
                <table>
                  <tbody>
                    <tr>
                      <td><AutoIntl displayId={'au.entity.attr.businessUnit'} className={styles.label} /></td>
                      <td><AuDropDown
                        selectOption={this.handleOverrideFieldDropdown.bind(this, idx, 'businessUnit')}
                        options={businessUnitItems}
                        className={styles.dropdown}
                        onChange={this.onOverrideFieldChange.bind(this, idx, 'businessUnit')}
                        allowTyping={true}
                        selection={output.businessUnit}
                        showError={output.businessUnit === "" && output.overriddenBusinessUnit}
                        error={{ fieldDisplayId: 'au.entity.attr.businessUnit', errDisplayId: 'au.validation.isRequired' }}
                        disabled={businessUnitItems.length === 0}
                      /></td>
                    </tr>
                    <tr>
                      <td><AutoIntl displayId={'au.entity.attr.region'} className={styles.label} /></td>
                      <td><AuDropDown
                        selectOption={this.handleOverrideFieldDropdown.bind(this, idx, 'region')}
                        onChange={this.onOverrideFieldChange.bind(this, idx, 'region')}
                        options={regionItems}
                        className={styles.dropdown}
                        selection={output.region}
                        allowTyping={true}
                        showError={output.region === "" && output.overriddenRegion}
                        error={{ fieldDisplayId: 'au.entity.attr.region', errDisplayId: 'au.validation.isRequired' }}
                        disabled={regionItems.length === 0}
                      /></td>
                    </tr>
                  </tbody>
                </table>
              </div>}
          </div>
          {maxOutputs > 1 && <div className={styles.delete_column}>
            <AuButton
              type={"plain"}
              size={"small"}
              title={formatMessage({ id: 'au.taps.filter.deleteFilter' })}
              className={cn(styles.delete, { [styles.disabled]: !canDelete })}
              onClick={this.removeOutputRow.bind(this, idx)}
            />
          </div>}
        </div>
      </div>
    );
  }

  renderOutputs() {
    if (this.state.outputs.length === 0 && this.props.mode === TAP_FILTERS_MODES.EDIT) {
      this.setState({outputs: [{
        aui: '',
        displayName: '',
        filters: [],
        idx: 0,
        foundSelection: null,
        hasDuplicate: false,
        businessUnit: "",
        region: "",
        showBuR: false,
        shardCount: 24,
        userEntry: false
      }]});
    }
    return this.state.outputs.map(this.renderOutputRow);
  }

  render() {

    return (
      <div className={styles.container}>
        {this.renderOutputs()}
        <div className={styles.hint_container}>
          {this.state.outputs.length < this.props.maxOutputs &&
            <AuButton className={styles.add}
              onClick={this.addOutputRow}
              displayId='au.entity.attr.addOutput'
              size={'small'} type={'plain'}
            />}
        </div>
      </div>
    );
  }
}