import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';

import AuAnalytics from '@au/core/lib/utils/AuAnalytics';

import apiFactory from '../utils/api';

export default class EntityLookup extends React.Component {

  static propTypes = {
    endpoint: T.object,
    entityId: T.string,
    lookupField: T.string,
    serviceAlias: T.string,
    entityAlias: T.string,
    entity: IPT.map
  }

  componentDidMount() {
    const { entity, serviceAlias, entityAlias, actions, endpoint, entityId } = this.props;

    let lookupEndpoint;

    if (entity) return;

    //TODO - Replace with Network Manager
    if (endpoint) {
      lookupEndpoint = endpoint;
    } else if (serviceAlias && entityAlias && actions) {
      lookupEndpoint = apiFactory(serviceAlias, entityAlias, actions);
    }
    if (lookupEndpoint) {
      lookupEndpoint.get(entityId).then(resp => this.setState({ entity: resp.data }), error => {
        // log error message
        AuAnalytics.trackException({
          description: error.toString(),
          fatal: false
        });
      });
    }
  }

  render() {
    const { entity, lookupField } = this.props;

    return this.state?.entity?.[lookupField] || entity?.get(lookupField, this.state?.entity?.[lookupField]) || null;
  }
}