import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { NOOP } from '../constants';

import styles from '../css/components/filters_count_button.module.scss';

export default class FiltersCounterButton extends React.Component {

  static propTypes = {
    className: T.string,
    count: T.number,
    onClick: T.func
  }

  static defaultProps = {
    count: 0,
    visible: true,
    onClick: NOOP
  }

  render() {
    const { className, count, onClick } = this.props;

    return (
      <div className={cn(styles.container, className)}>
        <AutoIntl
          data-count={count}
          className={styles.button}
          displayId="au.entity.attr.filters"
          onClick={onClick}
          tag="button"
        />
      </div>
    );
  }

}
