import React from 'react';

import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { history as browserHistory } from '../../../history';
import { TOPOLOGY_PATH } from '../../../constants';
import { generateEntityPath } from '../../../utils/entity';
import { wrapActionWithTracking } from '../../../utils/analyticsHelpers';
import { handleTapFiltersReplication } from '../../utils/taps';
import DefaultList from '../List';
import StartDialog from "../StartDialog";

export default class TapList extends DefaultList {

  onBeforeReplicate = this.onBeforeReplicate.bind(this);
  onBeforeReplicate(entity, isSamePartition) {
    return handleTapFiltersReplication(entity, isSamePartition);
  }

  renderDialogs() {
    const dialogs = super.renderDialogs();

    dialogs.push(
      <StartDialog
        key={"start-tap-dialog"}
        show={this.state.showStartDialog}
        onCancel={() => this.setState({showStartDialog: false})}
        onConfirm={this.confirmStart}
        titleDisplayId={"au.tap.start"}
        confirmDisplayId={"au.tap.start"}
        headerDisplayId={"au.tap.start"}
        messageDisplayId={"au.tap.message"}
      />
    );

    return dialogs;
  }

  confirmStart = this.confirmStart.bind(this);
  confirmStart(pointInTime) {
    const { endpoint } = this.props;
    const { id } = this.state;

    endpoint.start(id, pointInTime)
      .then(
        this.updateTap,
        createResponseAlertMessage
      ).then(() => this.setState({ showStartDialog: false, id: undefined }));
  }

  onPauseBtnClick(id) {
    const { endpoint } = this.props;

    return endpoint.pause(id)
      .then(this.updateTap, createResponseAlertMessage)
      .then(this.setState(prevState =>
        ({ showPauseDialog: !prevState.showPauseDialog })
      ));
  }

  onStartBtnClick(id) {
    this.setState({ showStartDialog: true, id });
    return this.renderDialogs;
  }

  onViewTopology(id) {
    //FIXME - MOVE TO linkHelper
    browserHistory.push(`${TOPOLOGY_PATH}/${id}`);
  }

  updateTap = this.updateTap.bind(this);
  updateTap(resp, fieldPath=[]) {
    const { endpoint, serviceAlias, actions } = this.props;
    const path = [...generateEntityPath(endpoint, serviceAlias), resp.data.id, ...fieldPath];
    return actions.patchEntitySuccess({ path, data: resp.data });
  }

  getRowActions({id, canWrite, permissions, ...otherProps}) {
    const actions = super.getRowActions({id, canWrite, permissions, ...otherProps});
    const { entities } = this.props;
    const tapState = entities.getIn([id, 'state']);

    if (['RUNNING', 'PAUSED', 'REPLAY'].includes(tapState)) {
      const isRunning = tapState === 'RUNNING' || tapState === 'REPLAY';
      const tapAction = {
         displayId: `au.taps.${isRunning ? 'pause' : 'start'}`,
         onClick: isRunning  ? this.onPauseBtnClick.bind(this, id) : this.onStartBtnClick.bind(this, id)
      };

      actions.splice(1, 0, wrapActionWithTracking(tapAction, 'Tap', 'List'));
    }

    return actions;
  }
}
