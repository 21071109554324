import TMC from '@autonomic/browser-sdk';
import {getParentEntityAwarePath} from '../../../utils/entity';

import DefaultList from '../List';

export default class SMSHistoryList extends DefaultList {

    static defaultProps = {
        ...super.defaultProps,
        pageSize: 500
    }

    endpoint = new TMC.services.Command({apiVersion: '1-alpha'});

    list() {
        return this.fetchData();
    }

    fetchData = this.fetchData.bind(this)
    async fetchData() {
        const entityId = this.props.parentEntity.entityId;

        return this.endpoint.devices.getWakeupEndpoint(entityId).search({pageSize: this.props.pageSize})
        .then(resp => resp, err => err ); //devices without SMS History will return a 404, which should be rendered without error message
    }

    onListSuccess = this.onListSuccess.bind(this);
    onListSuccess(resp) {
        const { parentEntity, actions, entityAlias } = this.props;

        const path = [
        ...getParentEntityAwarePath(parentEntity, entityAlias),
        'smsHistory'
        ];

        actions.listEntitiesSuccess({
        path,
        data: resp.data.items,
        pkField: 'id',
        replace: false
        });
    }
}