import React from 'react';
import { Map as imMap } from 'immutable';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { generateIdSerializer } from '../../../utils/serializers';
import { getParentEntityAwarePath } from '../../../utils/entity';
import apiFactory, { addResponseHook } from '../../../utils/api';
import DefaultList from '../List';

// Based on possible parentEntity types
const ADDITIONAL_INFO = Object.freeze({
  'vehicle': {
    additionalInfoType: 'devices',
    additionalInfoKey: 'deviceId',
    additionalInfoRelationsKey: 'bindings'
  },
  'device': {
    additionalInfoType: 'vehicles',
    additionalInfoKey: 'vehicleId',
    additionalInfoRelationsKey: 'binding'
  }
});
export default class BindingEventList extends DefaultList {
  list() {
    /**
     * We need to skip out on using enhancedEndpoints. Binding events do not have an id field
     * (which is the pkField for the vehicle endpoint)
     * This ends up thowing errors around a missing pkField before we have time to generate ids via api.js
     * Instead here we manually call and then generate ids for each event
     */

    return super.list()
      .then(resp => addResponseHook(resp, this.handleBindingsResponse)).catch(createResponseAlertMessage);
  }

  getLeftAddons() {
    const leftAddons = super.getLeftAddons();
    if (this.props.parentEntity.entityDef.type === 'vehicle') {
      leftAddons.push(<AutoIntl displayId={`au.vehicle.bindings.currentlyBoundDevices`} to={this.parentUrl + '/view'} tag="link"/>);
    }
    return leftAddons;
  }

  onListSuccess = this.onListSuccess.bind(this);
  onListSuccess() {
    // Override the default method to do nothing and pass through the response.
    // listEntitiesSuccess is handled after data is joined in handleBindingsResponse.
  }

  getAdditionalInfoRequests(bindingEventsData) {
    const { parentEntity, actions } = this.props;
    const parentEntityDef = parentEntity.entityDef;

    // To support deviceType/vin fields we're missing device type for vehicles and vins for devices
    // setup and array of endpoints to fetch required data
    const { additionalInfoType, additionalInfoKey} = ADDITIONAL_INFO[parentEntityDef.type];
    const addtionalInfoEndpoint = apiFactory(parentEntity.serviceAlias, additionalInfoType, actions);

    return bindingEventsData.items.map(item =>
      addtionalInfoEndpoint.get(item[additionalInfoKey]) // if device has been bound/unbound multiple times, same call will be repeated each time
    );
  }

  mapAdditionalData(bindingEventsData, additionalEntities) {
    const { parentEntity, entityDef } = this.props;
    const {
      additionalInfoKey,
      additionalInfoRelationsKey
    } = ADDITIONAL_INFO[parentEntity.entityDef.type];
    const parentBindings = (parentEntity.entity.getIn(['relations', additionalInfoRelationsKey]) || imMap()).toJS();

    return bindingEventsData.items.map(entry => {
      // Map matching fields in results for ID generation
      const genIdFields = entityDef.genId.map(f => entry[f]);
      const currentlyBound = Array.isArray(parentBindings)
        ? parentBindings.find(b => b.id === entry[additionalInfoKey])
        : parentBindings.id === entry[additionalInfoKey];
      const additionalEntity = additionalEntities.find(d => d.id === entry[additionalInfoKey]);

      return {
        ...entry,
        _id: generateIdSerializer(genIdFields), // Map fields listed in genId to a unique ID for events as they're not returned
        currentlyBound: Boolean(currentlyBound),
        deviceType: additionalEntity?.type,
        vin: additionalEntity?.properties?.vin,
        serial: additionalEntity?.properties?.serial,
        imei: additionalEntity?.properties?.imei
      };
    });
  }

  handleBindingsResponse = this.handleBindingsResponse.bind(this);
  handleBindingsResponse(bindingEventsResult) {
    const { parentEntity, entityDef, match, actions } = this.props;
    const { entityAlias } = match.params;
    const bindingEventsData = bindingEventsResult.data;
    // Grab GET requests for additional info needed
    const additionalInfoProms = this.getAdditionalInfoRequests(bindingEventsData);

    return Promise.all(additionalInfoProms)
      .then(additionalEntitiesResp => additionalEntitiesResp.map(resp => resp.data))
      .then(additionalEntities => {
        const data = this.mapAdditionalData(bindingEventsData, additionalEntities);

        // Store new data
        actions.listEntitiesSuccess({
          path: getParentEntityAwarePath(parentEntity, entityAlias),
          data,
          pkField: entityDef.pkField
        });

        // Return bindingEvents API result for List.onAfterFetch etc
        return bindingEventsResult;
      });
  }
}
