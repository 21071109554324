import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import PrivacyPolicy from '@au/core/lib/components/elements/PrivacyPolicy';

import styles from '../css/components/footer.module.scss';

export default class Footer extends React.PureComponent {

  static propTypes = {
    className: T.string
  }

  render() {
    const { className } = this.props;

    return (
      <div className={cn(styles.container, className)}>
        <PrivacyPolicy />
      </div>
    );
  }

}
