// Taken from https://github.com/sindresorhus/copy-text-to-clipboard
// sindresorhus does not believe in transpiling for browsers and CRA does not
// allow configuring webpack so the code must be transpiled from here.

export default input => {
    const el = document.createElement('textarea');
  
    el.value = input;
  
    // Prevent keyboard from showing on mobile
    el.setAttribute('readonly', '');
  
    el.style.contain = 'strict';
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    el.style.fontSize = '12pt'; // Prevent zooming on iOS
  
    const selection = document.getSelection();
    let originalRange = false;
    if (selection.rangeCount > 0) {
    originalRange = selection.getRangeAt(0);
    }
  
    document.body.appendChild(el);
    el.select();
  
    // Explicit selection workaround for iOS
    el.selectionStart = 0;
    el.selectionEnd = input.length;
  
    let success = false;
    try {
    success = document.execCommand('copy');
    } catch (err) {} // eslint-disable-line
  
    document.body.removeChild(el);
  
    if (originalRange) {
    selection.removeAllRanges();
    selection.addRange(originalRange);
    }
  
    return success;
  };
  