import DefaultList from '../List';

import { getParentEntityAwarePath } from '../../../utils/entity';

export default class DeploymentDeviceStateList extends DefaultList {

  onListSuccess = this.onListSuccess.bind(this);
  onListSuccess(resp) {
    const { parentEntity, entityDef, actions, match } = this.props;
    const { entityAlias } = match.params;

    actions.listEntitiesSuccess({
      path: getParentEntityAwarePath(parentEntity, entityAlias),
      data: resp.data.deviceStates,
      pkField: entityDef.pkField,
      replace: resp.isFirstPage
    });
  }

  list() {
    const { endpoint, parentEntity, pageSize } = this.props;
    return endpoint.getDeviceStates(parentEntity.entityId, { pageSize });
  }

}
