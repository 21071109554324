import {required, validUuid, validVin} from "../../../utils/validationRules";
import Lookup from "../Lookup";

export default class EmbeddedVehicleLookup extends Lookup {

  attrDisplayId = 'au.vehicles.vehicleIdOrVin';

  attrPlaceholderId = 'au.vehicles.lookupEmbeddedVehiclePlaceholder';

  embedded = true;

  lookup() {
    const {endpoint} = this.props;
    const {isVin, isUuid, inputStr} = this.state;

    if (isVin) {
      return endpoint.getByVin({
        vin: inputStr,
        includeRelations: 'bindings',
        expandRelations: 'bindings'
      });
    } else if (isUuid) {
      return endpoint.get(inputStr, {
        includeRelations: 'bindings',
        expandRelations: 'bindings'
      });
    }
  }

  validate() {
    const {inputStr} = this.state;
    let validationError;

    // required
    validationError = required(inputStr);
    if (validationError) {
      validationError.fieldDisplayId = this.attrDisplayId;
    }

    let isVin = false;
    let isUuid = false;

    if (!validationError) {
      // is not valid vin
      const vinError = validVin(inputStr);
      isVin = !vinError;
      // is not uuid
      const idError = validUuid(inputStr);
      isUuid = !idError;

      if (vinError && idError) {
        validationError = {...vinError, fieldDisplayId: this.attrDisplayId};
      }
    }

    this.setState(prevState => ({
      showError: prevState.submitted && Boolean(validationError),
      error: validationError,
      isUuid,
      isVin
    }));

    return validationError;
  }

}

