import React from "react";
import * as T from "prop-types";
import { NOOP } from "@au/core/lib/constants";
import TMC from "@autonomic/browser-sdk";
import AuDropDown from "@au/core/lib/components/elements/AuDropDown";
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import styles from '../css/components/tap_filter_details_editor.module.scss';

export class TagFilterKey extends React.PureComponent {
  // May contain other properties defined in AuDropDown.propTypes
  static propTypes = {
    tagType: T.string.isRequired,
    value: T.string,
    onChange: T.func
  };

  static defaultProps = {
    onChange: NOOP
  };

  state = {keyOptions: [], dropdownCounter: 0};

  keyEndpoint = new TMC.services.Feed({apiVersion: 2}).tags;

  componentDidMount() {
    this.keyEndpoint[`get${this.props.tagType}Tags`]?.().then(resp => {
      this.setState({
        keyOptions: resp.data.items.map((item) => ({
          displayString: item,
          val: item
        })),
        dropdownCounter: 1
      });
    }, createResponseAlertMessage);
  }

  render() {
    const { createMode, tagType, value, onChange, ...otherProps } = this.props;
    const { keyOptions, dropdownCounter } = this.state;
    const hasOptions = keyOptions.length === 0;

    return (
      <AuDropDown
        {...otherProps}
        toggleClassName={`toggle_${tagType}`}
        key={`attributeTag_${tagType}${dropdownCounter}`}
        disabled={hasOptions}
        selection={value}
        selectionClassName={styles.selection}
        options={keyOptions}
        selectOption={onChange}
        unsaved={!createMode}
      />
    );
  }
}
