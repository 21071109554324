import React from 'react';
import cn from 'classnames';
import { List } from 'immutable';

import TMC from '@autonomic/browser-sdk';
import AuButton, { BUTTON_TYPE_PRIMARY, BUTTON_TYPE_SECONDARY, BUTTON_TYPE_PLAIN } from '@au/core/lib/components/elements/AuButton';
import AuInput from '@au/core/lib/components/elements/AuInput';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuDropDown from '@au/core/lib/components/elements/AuDropDown';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { deviceType } from '../../../constants';
import { enhanceSdkEndpoint } from '../../../utils/api';
import { formatMessage } from '../../../utils/reactIntl';
import { required, validUuid } from '../../../utils/validationRules';
import { wrapActionWithTracking } from '../../../utils/analyticsHelpers';
import SimpleTable, { Row, Cell, HeaderCell } from '../../SimpleTable';
import DefaultView from '../View';

import styles from '../../../css/components/vehicle_manage_bindings.module.scss';
import loadingIcon from '../../../images/spinner_white.gif';

export default class VehicleManageBindings extends DefaultView {

  deviceTypeOptions = Object.values(deviceType).map(val => ({
    displayId: `au.devices.types.${val}`,
    val
  }))

  queryParams = { includeRelations: 'bindings', expandRelations: 'bindings' }

  devicesEndpoint = enhanceSdkEndpoint(
    new TMC.services.Inventory(),
    'devices',
    this.props.actions
  )

  getActions() {
    const { newBinding } = this.state;

    return [
      wrapActionWithTracking(
        {
          key: 'add_binding',
          type: BUTTON_TYPE_PRIMARY,
          className: styles.button,
          displayId: 'au.vehicle.bindings.add',
          onClick: this.onAddBindingBtnClick,
          disabled: Boolean(newBinding)
        },
        'Vehicle',
        'manageBindings'
      )
    ];
  }

  getNavLinks() {
    // mock Tabs
    return [];
  }

  getCrumbs() {
    const crumbs = super.getCrumbs();

    crumbs[crumbs.length - 1].destination = this.baseUrl + `/${this.entityId}/view`;
    crumbs.push({
      key: `crumb_${this.entityId}_manageBindings`,
      displayId: 'au.vehicle.manageBindings'
    });

    return crumbs;
  }

  onAddBindingBtnClick = this.onAddBindingBtnClick.bind(this);
  onAddBindingBtnClick() {
    this.setState({ newBinding: { deviceIdOrEsn: '', deviceType: deviceType.ECG } });
  }

  onDeleteBindingBtnClick(id) {
    this.setState({ deleteBinding: { id, confirm: true } });
  }

  handleDeviceTypeChange = this.handleDeviceTypeChange.bind(this);
  handleDeviceTypeChange(deviceType) {
    this.setState(prevState => ({
      newBinding: {
        ...prevState.newBinding,
        deviceType
      }
    }));
  }

  handleInputChange = this.handleInputChange.bind(this);
  handleInputChange(ev) {
    const { value } = ev.target;

    this.setState(prevState => ({
      newBinding: {
        ...prevState.newBinding,
        deviceIdOrEsn: value
      }
    }), this.validate);
  }

  validate() {
    const { newBinding } = this.state;
    let validationError = required(newBinding.deviceIdOrEsn);
    let idError = {};
    // required
    if (!validationError) {
      // is not uuid
      idError = validUuid(newBinding.deviceIdOrEsn);
      // geotab devices don't have `serial` property, so it must be UUID
      if (newBinding.deviceType === deviceType.GEOTAB && idError) {
        validationError = idError;
      }
    }

    this.setState(prevState => ({
      newBinding: {
        ...prevState.newBinding,
        showError: prevState.newBinding.submitted && Boolean(validationError),
        error: {
          ...validationError,
          fieldDisplayId: 'au.vehicle.bindings.deviceIdOrEsn'
        },
        isUuid: !idError
      }
    }));

    return validationError;
  }

  addBinding = this.addBinding.bind(this);
  async addBinding() {
    this.setState(prevState => ({
      newBinding: {
        ...prevState.newBinding,
        submitted: true
      }
    }));

    const error = this.validate();

    if (!error) {
      const { newBinding } = this.state;
      const { deviceIdOrEsn, isUuid } = newBinding;

      this.setState(prevState => ({
        newBinding: {
          ...prevState.newBinding,
          saving: true
        }
      }));

      let deviceId = isUuid ? deviceIdOrEsn : undefined;

      if (!deviceId) {
        await this.devicesEndpoint.getByUniqueProperty({
          type: newBinding.deviceType,
          uniquePropertyValue: deviceIdOrEsn
        }).then(
          resp => deviceId = resp.data.id,
          this.onError
        );
      }

      if (deviceId) {
        this.devicesEndpoint.bind(deviceId, {
          vehicleId: this.entityId
        }).then(() => {
          this.props.endpoint.get(this.entityId, this.queryParams).then(
            () => this.setState({ newBinding: null })
          );
        }, this.onError);
      }
    }
  }

  onError = this.onError.bind(this);
  onError(resp) {
    if (resp.data.error === 'not found') {
      this.setState(prevState => ({
        newBinding: {
          ...prevState.newBinding,
          showError: true,
          error: {
            errDisplayId: 'au.devices.noDeviceFoundWithId',
            fieldDisplayId: 'au.vehicle.bindings.deviceIdOrEsn',
            values: { deviceId: this.state.newBinding.deviceIdOrEsn }
          }
        }
      }));
    } else {
      createResponseAlertMessage(resp);
    }
    this.setState(prevState => ({
      newBinding: {
        ...prevState.newBinding,
        saving: false
      }
    }));
  }

  deleteBinding(bindingId) {
    const { endpoint } = this.props;

    if (bindingId) {
      this.devicesEndpoint.unbind(bindingId).then(() => {
        this.setState({ deleteBinding: null });
        endpoint.get(this.entityId, this.queryParams);
      }, createResponseAlertMessage);
    }
  }

  renderHead() {
    return (
      <Row className={styles.header_row}>
        <HeaderCell className={styles.type}>
          { formatMessage({ id: 'au.vehicle.bindings.deviceType' }) }
        </HeaderCell>
        <HeaderCell className={styles.serial}>
          { formatMessage({ id: 'au.vehicle.bindings.device' }) }
          <span className={styles.hint}>
            ({ formatMessage({ id: 'au.vehicle.bindings.device.hint' })})
          </span>
        </HeaderCell>
        <HeaderCell className={styles.action}>
          { formatMessage({ id: 'au.vehicle.bindings.action' }) }
        </HeaderCell>
      </Row>
    );
  }

  renderBody() {
    const { deleteBinding, newBinding } = this.state;
    const rows = [];

    this.props.entity.getIn(['relations', 'bindings'], List()).forEach(binding => {
      const bindingId = binding.get('id');
      const unbind    = deleteBinding && deleteBinding.id === bindingId;
      const deleting  = unbind && deleteBinding.deleting;
      const confirm   = deleteBinding && deleteBinding.confirm;

      rows.push(
        <Row key={`binding_${bindingId}`} className={styles.row}>
          <Cell className={styles.type}>{binding.get('type')}</Cell>
          <Cell className={styles.serial}>{binding.getIn(['properties', 'serial'], bindingId)}</Cell>
          <Cell className={styles.action}>
            <div className={cn(styles.actions, { [styles.fixed]: unbind })}>
              { !unbind &&
                <a
                  className={styles.delete}
                  title={formatMessage({ id: 'au.vehicle.bindings.delete' })}
                  onClick={this.onDeleteBindingBtnClick.bind(this, bindingId)}
                />
              }
              { unbind && deleting &&
                <div className={styles.deleting}>
                  <AuButton
                    type="alert"
                    size="medium"
                    disabled={true}
                    className={styles.deleting_btn}
                  >
                    <img src={loadingIcon} alt={formatMessage({ id: 'au.vehicle.bindings.deleting' })} />
                  </AuButton>
                </div>
              }
              { unbind && confirm &&
                <div className={styles.confirmation}>
                  <AutoIntl className={styles.msg} displayId="au.vehicle.bindings.delete.confirmation" />
                  <AuButton
                    className={styles.proceed}
                    type="alert"
                    size="medium"
                    displayId="au.vehicle.bindings.delete.proceed"
                    onClick={() => this.deleteBinding(bindingId)}
                  />
                  <AuButton
                    type="plain"
                    size="medium"
                    className={styles.cancel}
                    displayId="au.vehicle.bindings.delete.cancel"
                    onClick={() => this.setState({ deleteBinding: null })}
                  />
                </div>
              }
            </div>
          </Cell>
        </Row>
      );
    });

    if (newBinding) {
      const { saving } = newBinding;
      rows.push(
        <Row key="binding_new" className={cn(styles.row, styles.new)}>
          <Cell className={styles.type}>
            <AuDropDown
              name="type"
              className={styles.small}
              createMode={true}
              options={this.deviceTypeOptions}
              selection={this.state.newBinding.deviceType}
              selectOption={this.handleDeviceTypeChange}
            />
          </Cell>
          <Cell className={styles.serial}>
            <AuInput
              type="text"
              value={newBinding.deviceIdOrEsn}
              createMode={true}
              autoFocus={true}
              showError={newBinding.showError}
              error={newBinding.error}
              placeholderId="au.vehicle.bindings.add.placeholder"
              onChange={this.handleInputChange}
            />
          </Cell>
          <Cell className={styles.action}>
            <div className={styles.buttons}>
              { !saving &&
                <AuButton
                  type={BUTTON_TYPE_SECONDARY}
                  size="medium"
                  className={styles.save_btn}
                  displayId="au.vehicle.bindings.add.save"
                  onClick={this.addBinding}
                />
              }
              { saving &&
                <AuButton
                  type={BUTTON_TYPE_SECONDARY}
                  size="medium"
                  disabled={true}
                  className={styles.save_btn}
                >
                  <img src={loadingIcon} alt={formatMessage({ id: 'au.vehicle.bindings.add.saving' })} />
                </AuButton>
              }
              <AuButton
                type={BUTTON_TYPE_PLAIN}
                size="medium"
                disabled={saving}
                className={styles.cancel_btn}
                displayId="au.vehicle.bindings.add.cancel"
                onClick={() => this.setState({ newBinding: null })}
              />
            </div>
          </Cell>
        </Row>
      );
    }

    return rows;
  }

  renderContent() {
    const { entity } = this.props;
    const { newBinding } = this.state;
    const hasBindings = Boolean(entity.getIn(['relations', 'bindings'], List()).size);

    return (
      <div className={styles.content} ref={ref => this.containerRef = ref}>
        { this.renderActions() }
        <div className={styles.section}>
          <SimpleTable className={styles.bindings}>
            { this.renderHead() }
            { (hasBindings || newBinding) && this.renderBody() }
          </SimpleTable>
          { !(hasBindings || newBinding) &&
            <AutoIntl className={styles.no_bindings} displayId="au.vehicle.noBindingsYet" />
          }
        </div>
      </div>
    );
  }

}
