import React from 'react';
import * as T from 'prop-types';

import AuDropDown from '@au/core/lib/components/elements/AuDropDown';
import AuButton from '@au/core/lib/components/elements/AuButton';
import AuInput from '@au/core/lib/components/elements/AuInput';
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import { NOOP } from '../constants';

import styles from '../css/components/key_value_editor.module.scss';

export default class KeyValueEditor extends React.Component {

  static propTypes = {
    namePlaceholderId: T.string,
    valuePlaceholderId: T.string,
    onChange: T.func,
    entries: T.array,
    createMode: T.bool,
    keyPlaceholderId: T.string,
    options: T.array,
    removeBtnDisplayId: T.string,
    keys: T.array,
    max: T.number
  }

  static defaultProps = {
    entries: [],
    onChange: NOOP,
    options: []
  }

  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      errors: {},
      entries: null,
      inputs: [],
      lastKnownId: 0,
      mounted: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    let keys = props.entries.map(item => {return item.key }).sort();

    if (!state.mounted) {
      if (!state.entries && (props.entries.length !== 0 || props.createMode)) {
        let inputs = []
        keys.map(key => {
          let val;
          props.entries.map((item, idx) => {
            if (item.key === key) {
              val = { ...item, id: idx, unsaved: false };
            }
          });
          inputs.push(val);
        })
  
        return {
          entries: props.entries,
          inputs,
          lastKnownId: props.entries.length,
          mounted: true
        };
      }
      else if (state.entries?.length === 0 || state.entries === null || props.createMode) {
        const emptyInputs = [{ key: '', val: '' }];
        return {
          entries: props.entries,
          inputs: emptyInputs,
          lastKnownId: props.entries.length,
          mounted: true
        }
      }
    }
    return null;
  }

  componentDidMount() {
    if (this.state.entries === null || this.props.createMode) {
      this.state.inputs.push({ key: '', val: '' });
    }
  }

  removeKeyValue(idx) {
    this.setState(prevState => {
      const inputs = [...prevState.inputs];
      inputs.splice(idx, 1);
      if (inputs.length === 0) {
        inputs.push({ key: '', val: '' });
      }
      return { inputs };
    }, this.commitChanges);
  }

  addKeyValue = this.addKeyValue.bind(this);
  addKeyValue() {
    this.setState(prevState => {
      const inputs = [...prevState.inputs];
      inputs.push({ key: '', val: '', id: prevState.lastKnownId, unsaved: true });
      return { inputs, lastKnownId: prevState.lastKnownId + 1 };
    }, this.commitChanges);
  }

  handleInputChange(idx, e) {
    this.onChange(idx, e.target.value, 'val');
  }

  handleDropdownChange(idx, selection) {
    this.setState(prevState => {
      const inputs = [...prevState.inputs];
      if (inputs[idx]) {
        inputs[idx].key = selection;
        inputs[idx].unsaved = true;
      } else {
        inputs[idx] = { key: selection, unsaved: true };
      }
      return { inputs };
    }, this.commitChanges);
  }

  onChange(idx, inputStr, input) {
    this.setState(prevState => {
      const inputs = [...prevState.inputs];
      inputs[idx][input] = inputStr;
      inputs[idx][inputStr] = input;
      return { inputs };
    }, this.commitChanges);
  }

  commitChanges() {
    const { entries, inputs } = this.state;

    const updatedPropertiesObject = inputs.reduce((acc, property) => {
      acc[property.key] = property.val;
      return acc;
    }, {});
    if (entries) {
      for (let { key: name } of entries) {
        if (!(name in updatedPropertiesObject)) {
          updatedPropertiesObject[name] = '';
        }
      }
    }
    this.props.onChange(updatedPropertiesObject);
  }

  checkError(input) {
    if (input.val !== '' && input.key === '') {
      return true;
    }
    return false;
  }

  renderInput = this.renderInput.bind(this);
  renderInput(input, idx) {
    const { keyPlaceholderId, valuePlaceholderId, removeBtnDisplayId, options, max } = this.props;
    const keys = this.state.inputs.map(input => input.key);
    const error = this.checkError(input);

    const filteredOptions = options.filter((option) => {
      return keys.indexOf(option.val  ) === -1;
    });

    return (
      <div className={styles.inputs} key={`key_${input.id}_${idx}`}>
        <div className={styles.property_row}>
          <AuDropDown
            className={error ? styles.dropdown_error : styles.dropdown}
            allowTyping={true}
            options={filteredOptions}
            placeholderId={keyPlaceholderId}
            selection={input.key || ''}
            selectOption={this.handleDropdownChange.bind(this, idx)}
            onChange={this.onChange.bind(this, idx, 'key')}
            maxOptionsHeight={max}
            error={error}
            showError={error}
            unsaved={input.unsaved}
          />
          <AuInput
            className={styles.input}
            placeholderId={valuePlaceholderId}
            value={input.val || ''}
            readOnly={false}
            onChange={this.handleInputChange.bind(this, idx)}
            onClear={this.onChange.bind(this, idx, '', 'val')}
            unsaved={input.unsaved}
          />
          <AuButton className={styles.remove} displayId={removeBtnDisplayId} onClick={this.removeKeyValue.bind(this, idx)} size={'small'} type={'plain'} />
        </div>
        <div>
          {error &&
            <AutoIntl
              displayId={'au.vehicle.emptyProperty'}
              className={styles.error}
              tag="div"
            />
          }
        </div>
      </div>
    );
  }

  renderInputs() {
    return this.state.inputs.map(this.renderInput);
  }

  render() {
    const { options, addMoreDisplayId } = this.props;

    return (
      <div className={styles.container}>
        {this.renderInputs()}
        {this.state.inputs.length < options.length && <AuButton className={styles.add} onClick={this.addKeyValue} displayId={addMoreDisplayId} size={'small'} type={'plain'} />}
      </div>
    );
  }
}
