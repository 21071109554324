import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import StatusBadge from '../components/StatusBadge';
import styles from '../css/components/tap_state.module.scss';

export default class TapState extends React.Component {
  static propTypes = {
    displayValid:      T.bool,
    showValidDetails:  T.bool,
    showPausedDetails: T.bool,
    validDetails:      T.string,
    pausedDetails:     T.string,
    statusProps:       T.object,
    isView:            T.bool
  }

  static defaultProps = {
    statusProps: {}
  }

  render() {
    const { pausedDetails, validDetails, displayValid, showValidDetails, showPausedDetails, validProps, statusProps, isView } = this.props;

    return (
      <div className={styles.container}>
        {displayValid && <div className={cn(styles.tap_container, {[styles.view]: isView})}>
          <StatusBadge className={styles.status} {...validProps} />
          {showValidDetails && <AutoIntl displayId={validDetails} tag="div" className={styles.details} />}
        </div>}
        <div className={cn(styles.tap_container, {[styles.view]: isView && displayValid})}>
          <StatusBadge className={styles.status} {...statusProps} />
          {showPausedDetails && <AutoIntl displayId={pausedDetails} tag="div" className={styles.details} />}
        </div>
      </div>
    );
  }
}
