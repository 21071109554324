import React from "react";
import * as T from "prop-types";
import moment from 'moment-timezone';
import cn from "classnames";

import AuDropDown from "@au/core/lib/components/elements/AuDropDown";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";

import DateInput from "../datetime/DateInput";
import TimeInput from "../datetime/TimeInput";
import { DATE_FORMAT } from "../utils/filterDates";
import Calendar from '../Calendar';

import styles from "../../css/components/filters/date_time_fields.module.scss";

const MIN_DATE = moment("01/01/1970", DATE_FORMAT);

const DEFAULT_LEGEND = Object.freeze({
  today: {
    displayId: 'au.calendar.today'
  }
});

export default class DateTimeFields extends React.Component {

  static propTypes = {
    className: T.string,
    title: T.string,
    description: T.string,
    datetime: T.object,
    updateDatetime: T.func,
    onBlur: T.func,
    hasError: T.bool,
    hideYear: T.bool,
    sidebar: T.bool
  };

  state = {dropdownKey: 0, expanded: false, startDate: undefined, endDate: undefined, value: null, selected: false}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.datetime.timePeriod !== this.props.datetime.timePeriod) {
      this.setState({dropdownKey: prevState.dropdownKey + 1});
    }
  }

  onClick = this.onClick.bind(this);
  onClick() {
    this.setState(prevState => ({expanded: !prevState.expanded}));
  }

  onKeyUp = this.onKeyUp.bind(this);
  onKeyUp() {
    this.setState({selected: true, expanded: false});
  }

  onSelect(date) {
    const { timezone } = this.props;
    const isUtcTimezone = timezone === 'UTC';

    const timezoneDate = isUtcTimezone ? moment.utc(date) : date;
    const formattedDate = moment(timezoneDate).format(DATE_FORMAT);
    this.setState({value: formattedDate, selected: true});
  }

  render() {
    const { className, title, description, datetime, updateDatetime, onBlur, hasError, hideYear, timezone, sidebar, from, to, maxBound, modifiers, containerRef, start } = this.props;
    const { expanded } = this.state;
    const endBeforeStartError = datetime.dateError ? true : false;
    const error = datetime.error !== '' && datetime.error !== undefined;

    let dayClassName = styles.sidebar_date;
    if (datetime.date != '') {
      dayClassName = cn(styles.sidebar_date, styles.selected_sidebar)
    }
    if (endBeforeStartError) {dayClassName = cn(styles.sidebar_date, styles.selected_sidebar, styles.invalid)}

    return (
      <div className={className}>
        <div className={styles.main_title}>
          {title &&
            <AutoIntl
              displayId={title}
              className={styles.title}
              tag="div"
            />}
          <AutoIntl
            displayId={timezone === "UTC" ? "au.filters.timePeriod.UTC" : 'au.filters.timePeriod.local'}
            className={styles.hint}
            tag="div"
          />
        </div>
        {description &&
          <AutoIntl
            displayId={description}
            className={styles.description}
            tag="div"
          />
        }
        {sidebar &&
          <div className={styles.sidebar_fields} onBlur={() => this.setState({expanded: false})}>
            <div className={dayClassName}>
              <DateInput
                hideYear={hideYear}
                date={datetime.date}
                setDate={(value) => updateDatetime({date: value})}
                showClear={false}
                createMode={true}
                onBlur={onBlur}
                hasError={hasError || endBeforeStartError}
                timezone={timezone}
                onClick={this.onClick}
                onKeyUp={this.onKeyUp}
              />
            </div>
            <div className={expanded ? styles.calendar_container : styles.hidden}>
              <Calendar
                from={from}
                to={to}
                fromMonth={MIN_DATE.toDate()}
                toMonth={maxBound}
                numberOfMonths={1}
                legend={DEFAULT_LEGEND}
                modifiers={modifiers}
                className={styles.calendar}
                canChangeMonth={true}
                portalRef={containerRef}
                sidebar={sidebar}
                onSelect={this.onClick}
      
                readOnly={false}
                onChange={(date) => {
                  this.onClick;
                  this.onSelect(date);
                  this.props.onChange(date, start)
                }}
              />
            </div>
            {endBeforeStartError &&
              <AutoIntl
                displayId={datetime.dateError}
                className={styles.error}
                tag="div"
              />
            } 
            <div className={styles.time_input}>
              <TimeInput
                time={datetime.time}
                setTime={(value) => updateDatetime({ time: value })}
                showClear={false}
                createMode={true}
                onBlur={onBlur}
                hasError={hasError && !endBeforeStartError}
                timezone={timezone}
              />
              <AuDropDown
                key={this.state.dropdownKey}
                className={styles.time_period}
                selection={datetime.timePeriod}
                selectOption={(value) => updateDatetime({timePeriod: value})}
                options={[{val: "am", displayString: "am"}, {val: "pm", displayString: "pm"}]}
                createMode={true}
                showError={hasError && !endBeforeStartError}
              />
            </div>
            {error &&
              <AutoIntl
                displayId={datetime.error}
                className={styles.error}
                tag="div"
              />
            } 
          </div>
        }
        {!sidebar && 
          <div className={styles.date_time_fields}>
            <DateInput
              hideYear={hideYear}
              date={datetime.date}
              setDate={(value) => updateDatetime({date: value})}
              showClear={false}
              createMode={true}
              onBlur={onBlur}
              hasError={hasError}
              timezone={timezone}
            />
            <TimeInput
              time={datetime.time}
              setTime={(value) => updateDatetime({ time: value })}
              showClear={false}
              createMode={true}
              onBlur={onBlur}
              hasError={hasError}
              timezone={timezone}
            />
            <AuDropDown
              key={this.state.dropdownKey}
              className={styles.time_period}
              selection={datetime.timePeriod}
              selectOption={(value) => updateDatetime({timePeriod: value})}
              options={[{val: "am", displayString: "am"}, {val: "pm", displayString: "pm"}]}
              createMode={true}
              showError={hasError}
            />
            {error &&
              <AutoIntl
                displayId={datetime.error}
                className={styles.error}
                tag="div"
              />
            }
          </div>
        }
      </div>
    );
  }
}