import {connect} from 'react-redux';

import PropProvider from '../PropProvider';
import EntityLookup from '../components/EntityLookup';
import {defaultMapDispatchToProps} from '../utils/ConnectDefaults';


export default connect(
  (state, ownProps) => PropProvider(state).EntityLookup(ownProps),
  defaultMapDispatchToProps
)(EntityLookup);