import { connect } from 'react-redux';

import OutputEditor from '../components/OutputEditor';
import { defaultMapDispatchToProps } from '../utils/ConnectDefaults';


export default connect(
  null,
  defaultMapDispatchToProps
)(OutputEditor);
