import React from 'react';
import cn from 'classnames';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';

import AuComponent from '@au/core/lib/components/elements/AuComponent';
import CommonGlobalNavigation from '@au/core/lib/components/objects/GlobalNavigation';
import { appendQueryParams } from '@au/core/lib/utils/url';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import shared from '../shared';
import serviceDefs from '../services/serviceDefs';
import {
  SERVICES_PATH,
  TOPOLOGY_PATH,
  customCredsQueryParams,
  COMPONENT_GALLERY_PATH,
  COMPONENT_GALLERY_PAGES,
  SERVICE_NAMES
} from '../constants';
import apiFactory from '../utils/api';
import { formatMessage } from '../utils/reactIntl';
import { setPartition } from '../utils/linkHelper';
import MobilePageHeader from './MobilePageHeader';
import Footer from './Footer';
import { shouldHideContent } from '../utils/entity';

import styles from '../css/components/global_navigation.module.scss';

export const custOnClick = Object.fromEntries(Object.entries(customCredsQueryParams).map(([tab, pq]) =>
  [ tab, () => window.location.href = appendQueryParams(window.location.href, [[pq]]) ]
));

function filterDisplayForUser(serviceOrEntity) {
  // default is true
  // do not filter if using custom credentials and only filter if displayForUser equals `false`
  // "user" means public user obtained via regular code flow, not a client or service account
  // it is possible to use a token for a user, but we will assume tokens are for clients or service accounts
  return shared.usingCustomCreds || serviceOrEntity.displayForUser !== false;
}

export default class GlobalNavigation extends AuComponent {
  static propTypes = {
    pageTitle: T.string,
    screenWidth: T.string,
    notifications: T.object,
    accountId: T.string,
    accountName: T.string,
    partition: T.string,
    accounts: IPT.map,
    showStatusPageLink: T.bool,
    actions: T.shape({
      setAccountId: T.func.isRequired
    }).isRequired
  };

  static defaultProps = {
    notifications: {}
  };

  componentDidMount() {
    const { actions } = this.props;
    const endpoint = apiFactory(SERVICE_NAMES.ACCOUNTS, 'accounts', actions);
    endpoint.list().catch(createResponseAlertMessage);
  }

  getPartitions() {
    return shared.config.partitions ? Object.entries(shared.config.partitions).map(([value, partition]) =>
      ({ displayString: partition.label, value })
    ) : [];
  }

  get partition() {
    const { partition } = this.props;
    return shared.config.partitions ? shared.config.partitions[partition].label : '';
  }

  renderHeader() {
    const { screenWidth, schema, pageTitle } = this.props;

    if (['desktop', 'tabletLandscape'].includes(screenWidth) || !pageTitle) {
      return false;
    }

    return <MobilePageHeader schema={schema} pageTitle={pageTitle} />;
  }

  generateManageNavLinks() {
    const { accountId } = this.props;

    const manageEntities = [];
    Object.entries(serviceDefs)
          .filter(([a]) => serviceDefs[a].display !== false)
          .filter(([a]) => filterDisplayForUser(serviceDefs[a]))
          .filter(([a]) => !shouldHideContent(serviceDefs[a]))
          .forEach(([serviceAlias, serviceDef]) => {
            if (serviceDef) {
              for (let [entityAlias, entityDef] of Object.entries(serviceDef.entities)) {
                if (entityDef.display === false || serviceDef.accountAware && !accountId) {
                  continue;
                }
                if (!filterDisplayForUser(entityDef) || shouldHideContent(entityDef)) {
                  continue;
                }
                let displayId = `au.entity.title.${entityDef.type}`;
                manageEntities.push({
                  destination: `${SERVICES_PATH}/${serviceAlias}/${entityAlias}/${entityDef.landingPage || 'list'}`,
                  labelId: displayId,
                  sortingName: formatMessage({ id: displayId })
                });
              }
            }
          });

    // sort entities alphabetically by labelId
    manageEntities.sort((a, b) =>
      b.sortingName > a.sortingName
      ? -1
      : (a.sortingName > b.sortingName ? 1 : 0)
    );

    return manageEntities;
  }

  generateNavLinks() {
    const { accountId } = this.props;
    const isDevEnv = process.env.NODE_ENV === 'development';
    const manageNavLinks = this.generateManageNavLinks();

    const navLinks = [
      { labelId: 'au.section.title.topology',
        destination: TOPOLOGY_PATH,
        isVisible: Boolean(manageNavLinks.length && accountId)
      },
      { labelId: 'au.section.title.manage',
        isVisible: Boolean(manageNavLinks.length && accountId),
        navLinks: manageNavLinks },
      { labelId: 'au.section.title.component_gallery',
        isVisible: isDevEnv,
        navLinks: Object.values(COMPONENT_GALLERY_PAGES).map(pageAlias => ({
          destination: `${COMPONENT_GALLERY_PATH}/${pageAlias}`,
          labelId: `au.section.title.component_gallery.${pageAlias}`,
        })),
      },
    ];

    const { pathname } = window.location;
    // iterate over primary links to find those containing secondary links
    for (let i = 0, pLinksLen = navLinks.length; i < pLinksLen; i++) {
      const primaryLink = navLinks[i];
      let matchFound = primaryLink.destination && pathname === primaryLink.destination;

      if (!matchFound && 'navLinks' in primaryLink) {
        // iterate over secondary links and find mathing one
        for (let j = 0, sLinksLen = primaryLink.navLinks.length; j < sLinksLen; j++) {
          const secondaryLink = primaryLink.navLinks[j];

          if (secondaryLink.destination === pathname) {
            // all the common links
            primaryLink.selected = true;
            matchFound = true;
            break;
          } else if (pathname.startsWith(SERVICES_PATH)) {
            // entity framework links handling
            const { serviceAlias } = this.props.match.params;
            const entityAlias = pathname.split('/')[pathname.split('/').indexOf(serviceAlias) + 1];
            if (secondaryLink.destination
                && secondaryLink.destination.startsWith(`${SERVICES_PATH}/${serviceAlias}/${entityAlias}`)) {
              primaryLink.selected = true;
              secondaryLink.selected = true;
              matchFound = true;
              break;
            }
          }
        }
      }

      if (matchFound) {
        break;
      }
    }

    return navLinks;
  }

  render() {
    const { accounts, accountId, children, notifications, accountName, actions } = this.props;
    const showMenu = Boolean(this.partition || accountId);
    return (
      <CommonGlobalNavigation
        navLinks={this.generateNavLinks()}
        breadCrumbs={this.renderHeader()}
        logoLink={<Link to="/" />}
        className={cn(styles.nav, {
          [styles.alert]: notifications.servicesDown,
          [styles.warning]: !notifications.servicesDown && notifications.servicesDegraded
        })}
        navLinkClassName={styles.navlink}
        activeNavLinkClassName={styles.selected}
        accountId={accountId}
        accountName={accountName}
        accounts={accounts}
        onUserTokenClick={custOnClick['token']}
        onUserClientClick={custOnClick['client']}
        partition={this.partition}
        partitions={this.getPartitions()}
        confidentialClient={shared.usingCustomCreds}
        setAccountId={actions.setAccountId}
        setPartition={setPartition}
        showMenu={showMenu}
      >
        { children }
        <Footer/>
      </CommonGlobalNavigation>
    );
  }
}
