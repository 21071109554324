import React from 'react';
import * as T from 'prop-types';
import upperFirst from 'lodash/upperFirst';
import lowerFirst from 'lodash/lowerFirst';
import cn from 'classnames';

import { ENTER_KEY } from '@au/core/lib/constants';
import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import AuInput from '@au/core/lib/components/elements/AuInput';
import ProcessingButton from '@au/core/lib/components/elements/ProcessingButton';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { history as browserHistory } from '../../history';
import { formatMessage } from '../../utils/reactIntl';
import { required, validUuid } from "../../utils/validationRules";

import styles from '../../css/components/entity_lookup.module.scss';

const HTTP_NOT_FOUND = 404;

export default class Lookup extends React.Component {

  static propTypes = {
    endpoint: T.object.isRequired,
    entityDef: T.object.isRequired,
    match: T.shape({
      url: T.string.isRequired
    }).isRequired
  }

  state = { inputStr: '', showError: false, submitted: false };

  entityType = undefined;

  attrName = undefined;

  attrDisplayId = undefined;

  attrPlaceholder = undefined;

  attrPlaceholderId = undefined;

  embedded = false;

  constructor(props) {
    super(props);
    this.init();
  }

  init() {
    this.baseUrl = this.props.match.url.split('/').slice(0, -1).join('/');

    if (this.props.entityDef) {
      if (!this.entityType) {
        this.entityType = this.props.entityDef.type;
      }
      if (!this.attrName) {
        this.attrName = this.props.entityDef.pkField;
      }
      if (!this.attrDisplayId) {
        this.attrDisplayId = `au.entity.attr.${this.entityType}${upperFirst(this.attrName)}`;
      }
    }
  }

  lookup() {
    const { endpoint } = this.props;
    const { inputStr } = this.state;

    return endpoint.get(inputStr);
  }

  validate() {
    const {inputStr} = this.state;
    const fieldDisplayId = this.attrDisplayId;

    // required
    let validationError = required(inputStr);
    let idError = {};

    if (!validationError) {
      // is not uuid
      idError = validUuid(inputStr);
      if (idError) {
        validationError = {...idError, fieldDisplayId};
      }
    }

    this.setState(prevState => ({
      showError: prevState.submitted && Boolean(validationError),
      error: {...validationError, fieldDisplayId},
      isUuid: !idError
    }));

    return validationError;
  }

  handleInputChange = this.handleInputChange.bind(this);
  handleInputChange(e) {
    const {value} = e.target;
    this.onChange(value);
  }

  handleButtonClick = this.handleButtonClick.bind(this);
  handleButtonClick() {
    return new Promise(resolve => {
      this.setState({submitted: true}, () => {
        const error = this.validate();

        if (!error) {
          this.lookup().then(this.onSuccess, this.onError).then(resolve);
        } else {
          resolve();
        }
      });
    });
  }

  onChange = this.onChange.bind(this);
  onChange(value) {
    this.setState({ inputStr: value.trim() }, this.validate);
  }

  onSuccess = this.onSuccess.bind(this);
  onSuccess(resp) {
    const { entityDef, match } = this.props;
    const { url } = match;
    const entityId = resp.data[entityDef.pkField];

    if (entityId) {
      //FIXME - MOVE TO linkHelper
      browserHistory.push(
        url.split('/').slice(0, -1).join('/') + `/${entityId}/view`
      );
    } else {
      // We can't redirect to the details page without knowing the id.
      // Show the default Not Found message as a fallback and report.
      this.onError({ status: HTTP_NOT_FOUND });
      AuAnalytics.exception({
        description: "A lookup was successful, but the response doesn't " +
                     `contain the "pkField" (${entityDef.pkField}).`,
        fatal: false
      });
    }
  }

  onError = this.onError.bind(this);
  onError(resp) {
    if (resp.status === HTTP_NOT_FOUND) {
      this.setState({
        showError: true,
        error: {
          errDisplayId: 'au.entity.noEntityFound',
          fieldDisplayId: this.attrDisplayId,
          values: {
            entityName: formatMessage({
              id: `au.entity.name.${this.entityType}`
            }).toLowerCase(),
            attrName: lowerFirst(formatMessage({ id: this.attrDisplayId })),
            attrValue: this.state.inputStr
          }
        }
      });
    } else {
      createResponseAlertMessage(resp);
    }
  }

  onKeyDown = this.onKeyDown.bind(this);
  onKeyDown(e) {
    if (e.key === ENTER_KEY) {
      this.handleButtonClick();
    }
  }

  renderControls() {
    const { inputStr, error, showError } = this.state;

    let placeholder = this.attrPlaceholder;
    if (!this.attrPlaceholderId && !placeholder) {
      placeholder = formatMessage(
        { id: 'au.entity.type.placeholder' },
        { attrName: lowerFirst(formatMessage({ id: this.attrDisplayId })) }
      );
    }

    return (
      <>
        <div className={styles.control} key="input_control">
          <AuInput
            autoFocus={true}
            name="input"
            value={inputStr}
            error={error}
            showError={showError}
            placeholder={placeholder}
            placeholderId={this.attrPlaceholderId}
            className={styles.input}
            onChange={this.handleInputChange}
            onKeyDown={this.onKeyDown}
            showClear={false}
            errorClassName={cn({[styles.error_text]: showError})}
          />
        </div>
        <ProcessingButton
          size={'large'}
          type="secondary"
          disabled={showError && Boolean(error)}
          className={cn(styles.button, {[styles.button_embedded]: this.embedded, [styles.button_error]: showError})}
          displayId="au.entity.viewDetails"
          onClick={this.handleButtonClick}
        />
      </>
    );
  }

  render() {
    const { className } = this.props;

    return (
      <div className={cn(styles.form, className, {[styles.form_embedded]: this.embedded})}>
        <div className={styles.controls}>
          {this.renderControls()}
        </div>
      </div>
    );
  }
}
