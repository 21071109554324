import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import moment from 'moment-timezone';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import TMC from '@autonomic/browser-sdk';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuComponent from '@au/core/lib/components/elements/AuComponent';
import AuButton from '@au/core/lib/components/elements/AuButton';
import { getTmcLink } from '@au/core/lib/utils/tmcLinks';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import shared from '../shared';
import {
  DATETIME_FORMAT_FULL,
  HOME_PATH,
  INCOMPLETE,
  SERVICE_NAMES,
  SERVICES_PATH,
  tmcLinkTypes,
  TOPOLOGY_PATH,
  STATUS_PATH
} from '../constants';
import { history as browserHistory } from '../history';
import { formatMessage } from '../utils/reactIntl';
import { enhanceSdkEndpoint } from '../utils/api';
import { isValidTap } from './utils/taps';
import VinLookup from './VinLookup';
import FeedTopologyLegend from './FeedTopologyLegend';
import Banner from './Banner';
import StateIcon from './StateIcon';
import { setPageTitle } from "./utils/pageTitle";

import styles from '../css/components/landing_page.module.scss';

function Indicator(props){
  const { indicator } = props;
  if (!indicator) {
    return false;
  }
  return <div className={styles.indicator} >{indicator}</div>;
}

const maxAnnouncements = 3;

const LOCAL_TIMEZONE = moment.tz.guess();

export class QuickAction extends AuComponent {
  static propTypes = {
    entityAlias: T.string
  }

  onActionBtnClick(link, action, key) {
    AuAnalytics.trackEvent({
      category: 'HomePage',
      action: action,
      label: key
    });
    //FIXME - MOVE TO linkHelper
    browserHistory.push({
      pathname: link,
      state: { prevUrl: HOME_PATH }
    });
  }

  render() {
    const entityAlias = this.props.entityAlias;
    const quickActions = { 'taps': SERVICE_NAMES.FEED, flows: SERVICE_NAMES.FEED, 'clients': SERVICE_NAMES.ACCOUNTS, 'groups': SERVICE_NAMES.INVENTORY };

    const title = 'au.quickActions.' + entityAlias;
    const text = title + '.text';
    //FIXME - MOVE TO linkHelper
    const manageLink = `${SERVICES_PATH}/${quickActions[entityAlias]}/${entityAlias}/${'list'}`;
    const createLink = `${SERVICES_PATH}/${quickActions[entityAlias]}/${entityAlias}/${'create'}`;

    return (
      <div className={styles.action_block}>
        <div>
          <div className={styles[`${entityAlias}_icon`]}/>
          <AutoIntl displayId={title} className={styles.entity_title} tag='h2'/>
        </div>
        <AutoIntl displayId={text} className={styles.entity_text} tag='p'/>
        <div>
          <AuButton size='small' type="secondary" className={styles.action_btn} displayId='au.entity.create' onClick={() => this.onActionBtnClick(createLink, 'create', entityAlias)}/>
          <AuButton size='small' className={styles.manage_btn} displayId='au.entity.manage' onClick={() => this.onActionBtnClick(manageLink, 'manage', entityAlias)}/>
        </div>
      </div>
    );
  }
}

export default class LandingPage extends AuComponent {

  sessionTimezone = localStorage.getItem('timezone');

  static propTypes = {
    notifications: T.object,
    incidents: IPT.list,
    taps: IPT.map,
    announcements: IPT.list,
    accountId: T.string,
    partition: T.string
  }

  tapsEndpoint = enhanceSdkEndpoint(
    new TMC.services.Feed({ apiVersion: 2 }),
    'taps',
    this.props.actions
  )

  componentDidMount() {
    const { actions } = this.props;
    setPageTitle(actions.setPageTitle);
    createResponseAlertMessage('clearEvents');

    this.setState({ fetching: true });
    this.tapsEndpoint.listPages({ pagesToLoad: Infinity }).catch(createResponseAlertMessage);
  }

  renderAnnouncements() {
    const { announcements } = this.props;

    let currentAnnouncements = [];

    for (let announcement of Object.values(announcements.toJS())) {
      const { name, id, scheduled_for, scheduled_until } = announcement;
      if (currentAnnouncements.length < maxAnnouncements) {
        currentAnnouncements.push(
          <div className={styles.announcement} key={id}>
            <AutoIntl displayString={name} tag="h2" />
            <div className={styles.duration}>
              <AutoIntl displayId='au.duration' tag="div" />
              <div className={styles.start_time}>
                {moment.tz(scheduled_for, this.sessionTimezone ? this.sessionTimezone : LOCAL_TIMEZONE).format(DATETIME_FORMAT_FULL)}
              </div>
              <div className={styles.end_time}>
                {moment.tz(scheduled_until, this.sessionTimezone ? this.sessionTimezone : LOCAL_TIMEZONE).format(DATETIME_FORMAT_FULL)}
              </div>
            </div>
            <AutoIntl displayId={'au.home.details'} target="_blank" rel="noopener noreferrer" tag="a" href={STATUS_PATH} className={styles.details} />
          </div>);
      }
    }
    const more = announcements.count() - maxAnnouncements;

    if (more > 0) {
      currentAnnouncements.push(
        <div className={styles.announcement} key={"extra"}>
          <AutoIntl
            displayId="au.home.announcements.more"
            values={{ number: more }}
            tag="a"
            target="_blank"
            rel="noopener noreferrer"
            href={STATUS_PATH} /></div>);
    }
    return currentAnnouncements;
  }

  handleOnClick(linkType) {
    AuAnalytics.trackEvent({
      category: 'HomePage',
      action: 'TmcLinks',
      label: linkType
    });
  }


  renderTmcLinks() {
    const links = [];

    tmcLinkTypes.forEach(linkType => {
      const link = getTmcLink(linkType, shared.config.baseDomain);
      if (link) {
        links.push(
          <li className={styles.list} key={linkType}>
            <a href={link.destination} target="_blank" rel="noopener noreferrer" onClick={() => this.handleOnClick(linkType)}>
              <AutoIntl displayId={link.displayId} className={styles.list} />
            </a>
            <AutoIntl displayId={link.displayId + ".description"} className={styles.link_description} />
          </li>
        );
      }
    });

    return links;
  }

  renderTmcLinksTile() {
    return (
      <div className={styles.tile}>
        <AutoIntl displayId="au.tmcLinks" tag="h3" className={styles.title} />
        <div className={styles.link_container}>
          <ul className={styles.list_container}>
            {this.renderTmcLinks()}
          </ul>
        </div>
      </div>
    );
  }

  renderCurrentStatusTile() {
    const { notifications, incidents, showStatusPageLink } = this.props;

    const alert = formatMessage({ id: 'au.status.alert' }, { servicesDegraded: notifications.servicesDegraded, servicesDown: notifications.servicesDown });
    const warning = formatMessage({ id: 'au.status.warning' }, { servicesDegraded: notifications.servicesDegraded });
    const success = formatMessage({ id: 'au.status.success' });

    return (
      <div className={cn(styles.current_status, { [styles.background_alert]: notifications.servicesDown, [styles.background_warning]: !notifications.servicesDown && notifications.servicesDegraded })}>
        <Indicator indicator={<div className={cn(styles.icon, { [styles.alert]: notifications.servicesDown, [styles.warning]: !notifications.servicesDown && notifications.servicesDegraded })} />} />
        <div className={styles.current_status_tile}>
          <AutoIntl displayId='au.status.title.currentStatus' className={styles.current_status_title} tag="h3" />

          <div className={styles.description_container}>
            <h2 className={cn(styles.description, { [styles.description_alert]: notifications.servicesDown, [styles.description_warning]: !notifications.servicesDown && notifications.servicesDegraded })}>
              {notifications.servicesDown ? alert : !notifications.servicesDown && notifications.servicesDegraded ? warning : success}
            </h2>
            {Boolean(incidents?.size) && <h2 className={styles.incidents}>{formatMessage({ id: 'au.incidents' }, { count: incidents.size })}</h2>}
          </div>
          {showStatusPageLink && <AutoIntl displayId="au.home.details" target="_blank" rel="noopener noreferrer" className={cn(STATUS_PATH ? styles.view : styles.hide)} href={STATUS_PATH} tag="a" />}
        </div>
      </div>
    );
  }

  renderTapStatus(statusData) {
    const statusList = [];
    statusData.forEach(status => {
      statusList.push(
        <div key={status.name} className={styles.status_list}>
          <StateIcon icon={status.name.toLowerCase()} className={styles[`icon_${status.name}`]} />
          <AutoIntl displayId={`au.home.${status.name}`} className={styles.label} />
          <AutoIntl displayId="au.home.number" values={{ number: status.value }} className={styles.counter} />
        </div>
      );
    });

    return statusList;
  }

  renderTapsTile() {
    const { taps } = this.props;
    const obj = { RUNNING: 0, PAUSED: 0, STOPPED: 0, INCOMPLETE: 0};
    const statusData = [];
    const pieChartData = [];

    taps.valueSeq().forEach(v => {
      obj[v.getIn(['state'])]++;
      if(!isValidTap(v.getIn(['filters']).toJS())) {
        obj.INCOMPLETE++;
      }
    });

    for (const [key, value] of Object.entries(obj)) {
      statusData.push({ name: key, value });
      if (key !== INCOMPLETE) {
        pieChartData.push({ name: key, value });
      }
    }

    return (
      <div className={styles.taps_tile} onClick={AuAnalytics.trackEvent({ category: 'HomePage', action: 'TapsWidget', label: status.name })}>
        <AutoIntl displayId="au.home.taps" tag="h3" className={styles.title} />
        <div className={styles.tap_list_container}>
          {this.renderTapStatus(statusData)}
        </div>
      </div>
    );
  }

  renderQuickActions() {
    const actionBlocks = [];
    const entityAliases = ['taps', 'flows', 'clients', 'groups'];

    for (const alias in entityAliases) {
      actionBlocks.push(
        <QuickAction
          key={entityAliases[alias]}
          entityAlias = {entityAliases[alias]}
          >
        </QuickAction>
      );
    }

    return actionBlocks;
  }

  renderTopologyLegend() {
    //FIXME - MOVE TO linkHelper
    return (
      <div className={styles.topology_tile}>
        <AutoIntl className={styles.tile_title} displayId={'au.topologyLegend.title'} tag="div"/>
        <AutoIntl className={styles.topology_link} onClick={() => browserHistory.push(`${TOPOLOGY_PATH}`)} displayId={'au.topologyLegend.text'} tag="a"/>
        <FeedTopologyLegend landingPage={true} className={styles.topology_legend} />
      </div>
    );
  }

  render() {
    const { announcements } = this.props;

    return <div className={styles.container}>
      <div className={styles.splash}>
        <div className={styles.banner} />
        <div className={styles.tmc_container}>
          <div className={styles.tmc} />
        </div>
        <AutoIntl displayId="au.home.title" tag="h1" />
      </div>
      { announcements?.size > 0 &&
        <Banner className={styles.announcements}>
          {this.renderAnnouncements()}
        </Banner>}
      <div className={styles.content}>
        <VinLookup />
        <div className={styles.tiles}>
          <div className={styles.tile_row}>
            {this.renderCurrentStatusTile()}
            <Link to={'/services/feed-service/taps/list'}>
              {this.renderTapsTile()}
            </Link>
            {this.renderTmcLinksTile()}
          </div>
          <div className={styles.tile_divider}/>
          <AutoIntl className={styles.actions_title} displayId='au.quickActions.title'/>
          <div className={styles.quick_actions_row}>
            {this.renderQuickActions()}
          </div>
          {this.renderTopologyLegend()}
        </div>
      </div>
    </div>;
  }
}
