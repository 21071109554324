import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import cn from 'classnames';

import AuButton, {
  BUTTON_TYPE_TERTIARY,
  BUTTON_SIZE_MEDIUM
} from '@au/core/lib/components/elements/AuButton';
import BinaryFontButton from '@au/core/lib/components/elements/BinaryFontButton';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { statementEffect, STATEMENT_HISTORY_VIEW_PATH } from '../../constants';
import { history as browserHistory } from '../../history';
import StateIcon, { ICON_ENABLED, ICON_DISABLED } from '../StateIcon';
import { formatMessage } from '../../utils/reactIntl';
import { statementIdSerializer } from '../../utils/serializers';

import styles from '../../css/components/entity_policies.module.scss';

export class StatementTable extends React.Component {
  static propTypes = {
    statement: IPT.map.isRequired,
    renderExtraRows: T.func,
    formatAui: T.func,
    showResourseAui: T.bool,
    showSubjectAui: T.bool,
  }
  static defaultProps = {
    renderExtraRows: undefined,
    formatAui: (aui) => aui,
    showResourseAui: false,
    showSubjectAui: false,
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { statement, renderExtraRows, formatAui, showResourseAui, showSubjectAui } = this.props;
    const sAui = statement.get('subjectAui');
    const rAui = statement.get('resourceAui');

    const entitlements = statement.get('entitlements').map(entitlement =>
      <div key={`entitlement_${entitlement}`}>{ entitlement }</div>
    );
    
    const condition = statement.get('condition');
    const effect = statement.get('effect');
    const icon = effect === statementEffect.DENY
                   ? ICON_DISABLED
                   : ICON_ENABLED;

    return (
      <table className={styles.table}>
        <tbody>
          {showSubjectAui && (
            <tr>
              <td className={styles.fieldname}>
                { formatMessage({ id: 'au.entity.attr.subjectAui' })}
              </td>
              <td className={cn(styles.fieldvalue, styles.statements)}>
                { formatAui(sAui) }
              </td>
            </tr>
          )}
          {showResourseAui && (
            <tr>
              <td className={styles.fieldname}>
                { formatMessage({ id: 'au.entity.attr.resourceAui' })}
              </td>
              <td className={cn(styles.fieldvalue, styles.statements)}>
                { formatAui(rAui) }
              </td>
            </tr>
          )}
          <tr>
            <td className={styles.fieldname}>
              { formatMessage({ id: 'au.entity.attr.entitlements' })}
            </td>
            <td className={cn(styles.fieldvalue, styles.statements)}>
              { entitlements }
            </td>
          </tr>
          { Boolean(condition) && (
            <tr>
              <td className={styles.fieldname}>
                { formatMessage({ id: 'au.entity.attr.condition' })}
              </td>
              <td className={cn(styles.fieldvalue, styles.condition)}>
                { condition }
              </td>
            </tr>
          )}
          <tr>
            <td className={cn(styles.fieldname, styles.effect)}>
              { formatMessage({ id: 'au.entity.attr.effect' })}
            </td>
            <td className={cn(styles.fieldvalue, styles.effect)}>
              { effect && <StateIcon icon={icon} displayId={`au.policies.effects.${effect}`} /> }
            </td>
          </tr>
          <tr>
            <td className={styles.fieldname}>
              { formatMessage({ id: 'au.entity.attr.description' })}
            </td>
            <td className={cn(styles.fieldvalue, styles.statements)}>
              { statement.has('_permissionId') &&
                <AutoIntl className={styles.v1} displayId="au.policies.v1Permission" />
              }
              { statement.get('description') }
            </td>
          </tr>
          {Boolean(renderExtraRows) && renderExtraRows(statement) }
        </tbody>
      </table>
    );
  }
}

export class Statement extends React.Component {

  static propTypes = {
    formatAui: T.func.isRequired,
    statement: IPT.map.isRequired,
    statementAttrName: T.string.isRequired,
    isDirectPolicy: T.bool.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = { expanded: false };
  }

  baseUrl = window.location.pathname.split('/').slice(0, -1).join('/');

  handleClick = this.handleClick.bind(this);
  handleClick() {
    this.setState(prevState => ({ expanded: !prevState.expanded }));
  }

  onViewHistoryBtnClick = this.onViewHistoryBtnClick.bind(this);
  onViewHistoryBtnClick() {
    const { statement } = this.props;
    const entityId = statementIdSerializer(statement.toJS());
    browserHistory.push(`${STATEMENT_HISTORY_VIEW_PATH}/${entityId}/history`);
  }

  render() {
    const { statement, statementAttrName, isDirectPolicy, formatAui } = this.props;
    const { expanded } = this.state;
    const sAui = statement.get(statementAttrName);

    return (
      <div className={styles.statement_group}>
        <div className={styles.statement_node}>
          <BinaryFontButton
            onClick={this.handleClick}
            onInitial={!expanded}
            initial="collapse" next="minus-square" />
          <div className={styles.statement_toggle}>
            <AutoIntl displayId={`au.entity.attr.${statementAttrName}`} className={styles.label} />
            <span>
              { isDirectPolicy && sAui }
              { !isDirectPolicy && formatAui(sAui) }
            </span>
          </div>
        </div>
        {expanded && (
          <div className={styles.statement_wrapper}>
            <StatementTable statement={statement} />
            {!statement.has('_permissionId') && (
              <AuButton
                type={BUTTON_TYPE_TERTIARY}
                size={BUTTON_SIZE_MEDIUM}
                displayId="au.entity.viewHistory"
                className={styles.view_history_btn}
                onClick={this.onViewHistoryBtnClick}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
