import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import { Map } from 'immutable';

import { formatMessage } from '../utils/reactIntl';
import { Row, Cell } from '../components/SimpleTable';
import { parseCallable } from '../utils/parse';
import SimpleKeyValue, { orderTypes } from './SimpleKeyValue';

import styles from '../css/components/simple_key_value.module.scss';

export default class FormattedKeyValue extends SimpleKeyValue {

  static propTypes = {
    data: IPT.map,
    rowDefs: T.array.isRequired,
    formatters: T.object,
    localePrefix: T.string,
    order: T.oneOf([orderTypes.Ascending, orderTypes.Descending])
  }

  static defaultProps = {
    formatters: {}
  }

  createRows = this.createRows.bind(this);
  createRows(displayData) {
    const rowData = Map(displayData);
    const { localePrefix, rowDefs } = this.props;

    const rows = rowDefs.map(rowDef => {
      let value = rowData.get(rowDef.attr);
      if (rowDef.formatters) {
        const formatters = parseCallable(rowDef.formatters);
        if (formatters.length) {
          for (let { func, args } of formatters) {
            args = { ...args };

            if (typeof value !== 'undefined' && value !== null && typeof value[func] === 'function') {
                value = value[func]();
            } else if (typeof this.props.formatters[func] === 'function') {
              if (func === 'date' && !args.timezone) {
                args.timezone = this.props.timezone;
              }
              value = this.props.formatters[func]({ ...args, rowData, property: rowDef.attr, value });
            }
          }
        }
      }

      return (
        <Row key={rowDef.attr} className={styles.row}>
          <Cell className={styles.key}>{localePrefix ? formatMessage({id: `${localePrefix}.${rowDef.attr}`}) : rowDef.attr}</Cell>
          <Cell className={styles.value}>{value}</Cell>
        </Row>
      );
    });
    return rows;
  }
}
