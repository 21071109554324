import React from 'react';
import * as T from 'prop-types';
import uniqueId from 'lodash/uniqueId';

import { ENTER_KEY } from '@au/core/lib/constants';
import AuButton from '@au/core/lib/components/elements/AuButton';
import AuDropDown from '@au/core/lib/components/elements/AuDropDown';
import { Chip } from '@au/core/lib/components/elements/AuChips';

import { formatMessage } from '../utils/reactIntl';

import styles from '../css/components/tag_editor.module.scss';

const TAG_NAME = 'tagName';
const TAG_VALUE = 'tagValue';

const TAG_NAME_RE_RULE = /^[a-zA-Z0-9_]{1,256}$/;
const TAG_VALUE_RE_RULE = /^[a-zA-Z0-9 ;:.,()?!\-_&$%@[\]+*=#]{0,256}$/;

export default class TagEditor extends React.PureComponent {

  static propTypes = {
    tags: T.object,
    namePlaceholder: T.string,
    valuePlaceholder: T.string,
    onChange: T.func,
    validationRules: T.array
  }

  static defaultProps = {
    tags: {}
  }

  tagNameRef = React.createRef();
  tagValueRef = React.createRef();

  // uniqueId is passed to child chips to distinguish them between multiple
  // instances
  _uniqueId = uniqueId('tag-chips-')

  constructor(props) {
    super(props);

    // convert object into an array of objects containing
    // { key: value } pairs, then sort the resulting array by keys
    const tags = Object.entries(this.props.tags).map(([k, v]) => ({[k]: v})).sort((a, b) => {
      let [keyA] = Object.keys(a);
      let [keyB] = Object.keys(b);
      return keyA > keyB ? 1 : keyB > keyA ? -1 : 0;
    });

    this.state = {
      showErrors: false,
      errors: {},
      tagName: '',
      tagValue: '',
      tags
    };
  }

  handleInputChange = this.handleInputChange.bind(this);
  handleInputChange(name, value) {
    this.setState({ [name]: value }, this.validate);
  }

  handleAddClick = this.handleAddClick.bind(this);
  handleAddClick() {
    const { tagName, tagValue } = this.state;
    const noInput = !tagName.trim() && !tagValue.trim();
    const isValid = this.validate();

    if (noInput) {
      this.setState({ showErrors: false });
    } else if (isValid) {
      this.setState(prevState => ({
        tags: [...prevState.tags, { [tagName]: tagValue }],
        tagName: '',
        tagValue: '',
        showErrors: false
      }), this.commitChanges);

      if (this.tagsEl) {
        setTimeout(() => this.tagsEl.scrollTop = this.tagsEl.offsetHeight);
      }

      this.tagNameRef.current.reset();
      this.tagValueRef.current.reset();

      setTimeout(this.tagNameRef.current.focus);
    } else {
      this.setState({ showErrors: true }, () => {
        const { errors } = this.state;
        if ('tagName' in errors) {
          this.tagNameRef.current.focus();
        } else if ('tagValue' in errors) {
          this.tagValueRef.current.focus();
        }
      });
    }
  }

  handleRemoveClick = this.handleRemoveClick.bind(this);
  handleRemoveClick(index) {
    this.setState(prevState => {
      let tags = [...prevState.tags];
      tags.splice(index, 1);
      return { tags };
    }, this.commitChanges);
  }

  handleRemoveAllClick = this.handleRemoveAllClick.bind(this);
  handleRemoveAllClick() {
    this.setState({ tags: [] }, this.commitChanges);
  }

  onKeyPressed = this.onKeyPressed.bind(this);
  onKeyPressed(e) {
    if (e.key === ENTER_KEY) {
      this.handleAddClick();
    }
  }

  commitChanges() {
    let tags = this.state.tags.reduce((acc, tag) => {
      const [ name, value ] = Object.entries(tag)[0];
      acc[name] = value;
      return acc;
    }, {});

    this.props.onChange(tags);
  }

  validate() {
    const { validationRules } = this.props;
    const { tags } = this.state;
    const tagName = this.state.tagName.trim();
    const tagValue = this.state.tagValue.trim();
    const errors = {};

    const nameRule = validationRules?.name ? new RegExp(validationRules.name) : TAG_NAME_RE_RULE;
    const valueRule = validationRules?.value ? new RegExp(validationRules.value) : TAG_VALUE_RE_RULE;

    if (tagName || tagValue) {
      if (!tagName) {
        // required
        errors.tagName = { fieldDisplayId: 'au.tagEditor.tagName', errDisplayId: 'au.validation.isRequired' };
      }

      if (!tagValue) {
        // required
        errors.tagValue = { fieldDisplayId: 'au.tagEditor.tagValue', errDisplayId: 'au.validation.isRequired' };
      }

      let hasDuplicates = false;
      if (tagName) {
        hasDuplicates = this.state.tags.some(tag => {
          return tagName === Object.keys(tag)[0];
        });
        if (hasDuplicates) {
          // duplicate
          errors.tagName = { fieldDisplayId: 'au.tagEditor.tagName', errDisplayId: 'au.validation.duplicateTag' };
        }
      }

      if (tagName && !errors.tagName && !(nameRule).test(tagName)) {
        // unsupported characters
        errors.tagName = { fieldDisplayId: 'au.tagEditor.tagName', errDisplayId: 'au.validation.invalidTagName' };
      }

      if (tagValue && !errors.tagValue && !(valueRule).test(tagValue)) {
        // unsupported characters
        errors.tagValue = {
          fieldDisplayId: 'au.tagEditor.tagValue',
          errDisplayId: 'au.validation.pattern',
          values: { re: valueRule.toString() }
        };
      }

      if (validationRules) {
        if (validationRules.max && tags.length >= validationRules.max) {
          errors.tagName = { fieldDisplayId: 'au.tagEditor.tags', errDisplayId: 'au.validation.maxNumber', values: { num: validationRules.max }}
        }
      }
    }

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  }

  renderTags() {
    const { tags } = this.state;
    const elements = [];

    tags.forEach((tag, index) => {
      let [name, value] = Object.entries(tag)[0];
      if (name !== '' && value !== '') {
      elements.push(
        <Chip
          key={`tag_${index}`}
          index={index}
          isNew={!(name in this.props.tags && this.props.tags[name] === value)}
          className={styles.tag}
          onRemove={this.handleRemoveClick}
          uniqueParentId={this._uniqueId}
        >
          {name} : {`"${value}"`}
        </Chip>
      )}
    });

    return elements;
  }

  render() {
    const { namePlaceholder, valuePlaceholder } = this.props;
    const { tags, tagName, tagValue, errors, showErrors} = this.state;
    const emptyTagValue = tags.length === 0 || tags.length === 1 && Object.values(tags[0])[0] === '';

    return (
      <div className={styles.container}>
        <div className={styles.form} onKeyDown={this.onKeyPressed}>
          <AuDropDown
            ref={this.tagNameRef}
            className={styles.dropdown}
            allowTyping={true}
            options={[]}
            selection={tagName}
            selectionClassName={styles.selection}
            toggleClassName={styles.hideToggle}
            placeholder={namePlaceholder || formatMessage({ id: 'au.tagEditor.placeholder.tagName' })}
            selectOption={val => this.handleInputChange(TAG_NAME, val)}
            onChange={val => this.handleInputChange(TAG_NAME, val)}
            error={errors.tagName}
            showError={showErrors && Boolean(errors.tagName)}
            createMode={true}
          />
          <AuDropDown
            ref={this.tagValueRef}
            className={styles.dropdown}
            allowTyping={true}
            options={[]}
            selection={tagValue}
            selectionClassName={styles.selection}
            toggleClassName={styles.hideToggle}
            placeholder={valuePlaceholder || formatMessage({ id: 'au.tagEditor.placeholder.tagValue' })}
            selectOption={val => this.handleInputChange(TAG_VALUE, val)}
            onChange={val => this.handleInputChange(TAG_VALUE, val)}
            error={errors.tagValue}
            showError={showErrors && Boolean(errors.tagValue)}
            createMode={true}
          />
          <AuButton
            type="secondary"
            className={styles.add_btn}
            displayString="add"
            onClick={this.handleAddClick}
          />
        </div>
        <div className={styles.tags} ref={el=>this.tagsEl=el}>
          { this.renderTags() }
          { !emptyTagValue &&
            <AuButton
              type="plain"
              size="medium"
              displayId="au.tagEditor.clearAll"
              className={styles.remove_all_btn}
              onClick={this.handleRemoveAllClick}
            />
          }
        </div>
      </div>
    );
  }

}
