import React from 'react';

import { policyType, knownPolicyProps } from '../../../constants';
import { cleanupPolicy } from '../../../utils/policies';
import Tabs from '../../Tabs';
import DefaultCreate from '../Edit';

export default class PolicyCreate extends DefaultCreate {

  // remove extra fields before sending the data to backend
  entityProcessors = [
    policy => cleanupPolicy(policy, [...knownPolicyProps, 'subjectAui', 'resourceAui' ])
  ]

  changePolicyType(type) {
    this.setState(prevState => ({
      entity: prevState.entity.set('type', type)
    }));
  }

  getSelectedType() {
    return this.state.entity.get('type', policyType.SUBJECT);
  }

  getNavLinks() {
    return Object.keys(policyType).map(typeKey => ({
      labelId: `au.policies.types.${typeKey}`,
      selected: policyType[typeKey] === this.getSelectedType(),
      onClick: this.changePolicyType.bind(this, policyType[typeKey])
    }));
  }

  renderTabs() {
    return <Tabs navLinks={this.getNavLinks()} />;
  }

  getFormFields() {
    const fields = super.getFormFields();

    if (this.props.parentEntity) {
      return fields;
    }

    const excludeField = this.getSelectedType() === policyType.SUBJECT
                         ? 'resourceAui'
                         : 'subjectAui';

    return fields.filter(({ props }) => props.field !== excludeField);
  }

  getEntityProcessors = this.getEntityProcessors.bind(this);
  getEntityProcessors() {
    const processors = super.getEntityProcessors();
    return [...processors, ...this.entityProcessors];
  }

  renderForm() {
    if (this.props.parentEntity) {
      return super.renderForm();
    }

    return (
      <div>
        { this.renderTabs() }
        { super.renderForm() }
      </div>
    );
  }

}
