import React from 'react';
import { fromJS } from 'immutable';

import TMC from '@autonomic/browser-sdk';

import { enhanceSdkEndpoint } from '../../../utils/api';
import { history as browserHistory } from '../../../history';
import { formatMessage } from '../../../utils/reactIntl';
import { validUuid } from '../../../utils/validationRules';
import DefaultCreate from '../Edit';

const bindingEntityDef = Object.freeze({
  type: 'device',
  attributes: {
    vinOrUuid: {
      type: 'string',
      labelId: 'au.vehicles.vehicleIdOrVin',
      placeholderId: 'au.vehicles.lookupPlaceholder',
      rules: 'required',
      autoFocus: true,
      submitOnEnter: true
    },
    deviceId: {
      type: 'string',
      labelId: 'au.device.deviceId',
      rules: 'required',
      map: 'deviceId',
      readonly: true
    }
  }
});

class DeviceBind extends DefaultCreate {

  vehiclesEndpoint = enhanceSdkEndpoint(
    new TMC.services.Inventory(),
    'vehicles',
    this.props.actions
  )

  getTitle() {
    return formatMessage({ id: 'au.device.bindDevice' });
  }

  handleOnSave = this.handleOnSave.bind(this);
  async handleOnSave(binding) {
    const { endpoint, location } = this.props;

    let vehicleId = validUuid(binding.vinOrUuid) === null ? binding.vinOrUuid : undefined;
    if (!vehicleId) {
      await this.vehiclesEndpoint.getByVin({ vin: binding.vinOrUuid }).then(
        resp => vehicleId = resp.data.id,
        this.genericErrorHandler
      );
    }

    if (vehicleId) {
      // bind the device
      return endpoint.bind(binding.deviceId, { vehicleId }).then(
        // update vehicle record with new binding info
        () => endpoint.get(binding.deviceId, { includeRelations: 'binding', expandRelations: 'binding' }).finally(
          // finally, navigate back to the previous page (view)
          () => browserHistory.push(location.state.prevUrl)
          // () => setTimeout(() => browserHistory.push(location.state.prevUrl))
        ),
        this.genericErrorHandler
      );
    }

    return Promise.resolve();
  }

}

export default (props) => {
  const override = {
    createMode: true,
    entity: fromJS({
      deviceId: props.match.params.entityId
    }),
    entityDef: bindingEntityDef,
  };
  return <DeviceBind {...props} {...override} />;
};
