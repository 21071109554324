module.exports = Object.freeze({
  assets: {
    tableId: 'assets',
    tablePkField: 'id',
    sort: { columnId: 'vin', direction: 'down' },
    searchbox: true,
    searchboxPlaceholderId: 'au.vehicles.searchboxPlaceholder',
    columnDefs: [
      { property: 'id',              labelId: 'au.noop',                   searchable: false, weight: 0, width: 0,   display: false },
      { property: 'vin',             labelId: "au.vehicle.vin",            searchable: true,  weight: 9, width: 175, display: true, flexGrow: 1, formatters: [{ func: 'link', args: { type: 'vehicle', idProperty: 'vehicle_id' } }] },
      { property: 'name',            labelId: "au.vehicle.name",           searchable: true,  weight: 9, width: 175, display: true, flexGrow: 1, formatters: [{ func: 'link', args: { type: 'vehicle', idProperty: 'vehicle_id' } }] },
      { property: 'fleet_name',      labelId: "au.vehicle.fleet",          searchable: true,  weight: 8, width: 150, display: true, flexGrow: 1 },
      { property: 'timestamp',       labelId: "au.vehicle.lastUpdate",     searchable: false, weight: 2, width: 85,  display: true, flexGrow: 1, formatters: [{ func: 'relativeDate' }] },
    ]
  },
  assetIndicators: {
    tableId: 'assetIndicators',
    tablePkField: 'id',
    searchbox: false,
    columnDefs: [
      { property: 'indicator', labelId: 'au.asset.indicatorsTable.header.indicators',  searchable: false, weight: 9, width: 320, display: true, flexGrow: 1 },
      { property: 'status',    labelId: 'au.asset.indicatorsTable.header.status',      searchable: false, weight: 9, width: 60,  display: true },
      { property: 'icon',      labelId: 'au.noop',                                     searchable: false, weight: 9, width: 40,  display: true, sortable: false, formatters: [{ func: 'className' }] }
    ]
  },
  geofencePage: {
    tableId: 'geofencePage',
    tablePkField: 'vehicle_id',
    sort: { columnId: 'vin', direction: 'up' },
    searchbox: true,
    searchboxPlaceholderId: 'au.geofencepage.searchboxPlaceholder',
    columnDefs: [
      { property: 'vehicle_id', labelId: 'au.noop',                        width: 0,   searchable: false, display: false },
      { property: 'vin',        labelId: 'au.geofencepage.table.vinLabel', width: 190, searchable: true,  display: true, flexGrow: 1, formatters: [{ func: 'link', args: { type: 'vehicle', idProperty: 'vehicle_id' } }] } ,
      { property: 'timestamp',  labelId: 'au.vehicle.lastUpdate',          width: 180, searchable: false, display: true, flexGrow: 1, formatters: [{ func: 'date' }] },
    ]
  },
  geofences: {
    tableId: 'geofences',
    tablePkField: 'id',
    sort: { columnId: 'name', direction: 'up' },
    searchbox: true,
    searchboxPlaceholderId: 'au.geofences.searchboxPlaceholder',
    filter: null,
    filterDisplay: true,
    filterPlaceholderId: 'au.geofences.filterPlaceholder',
    responsive: true,
    columnDefs: [
      { property: 'id',                      labelId: 'au.noop',                 searchable: false, width: 0,   display: false },
      { property: 'name',                    labelId: 'au.geofence.name',        searchable: true,  width: 250, display: true, flexGrow: 1,  formatters: [{ func: 'link', args: { type: 'geofences', idProperty: 'id' } }] },
      { property: 'numberOfVehiclesPresent', labelId: 'au.geofence.numVehicles', searchable: false, width: 165, display: true, flexGrow: 1 },
      { property: 'actions',                 labelId: 'au.noop',                 searchable: false, width: 140, display: true, sortable: false, formatters: [{ func: 'actionCell', args: { multiActionButton: true } }] },
    ]
  },
  tapFiltersView: {
    tablePkField: 'sequence',
    columnDefs: [
      { property: 'filterType',   labelId: 'au.entity.taps.filterType',    width: 150, formatters: [{ func: 'localize', args: { prefix: 'au.taps.filters'}}] },
      { property: 'details',      labelId: 'au.entity.taps.details',       width: 'auto', formatters: [{ func: 'localizeKeys', args: { prefix: 'au.taps.filter.details' } }, { func: 'fancyKeyValue' }] },
      { property: 'type',         labelId: 'au.entity.taps.permitDeny',    width: 160, formatters: [{ func: 'localize', args: { prefix: 'au.taps.filter.types'} }] },
    ]
  },
  tapFiltersEdit: {
    tablePkField: 'sequence',
    columnDefs: [
      { property: 'sequence',     labelId: 'au.entity.taps.sequence',      width: 42 },
      { property: 'filterType',   labelId: 'au.entity.taps.filterType',    width: 220,    formatters: [{ func: 'tapFilterType' }] },
      { property: 'details',      labelId: 'au.entity.taps.details',       width: '100%', formatters: [{ func: 'tapFilterDetails' }] },
      { property: 'type',         labelId: 'au.entity.taps.permitDeny',    width: 138,    formatters: [{ func: 'tapFilterPermission' }] },
      { property: 'order',        labelId: 'au.taps.order',                width: 70,     formatters: [{ func: 'tapFilterOrderBtn' }] },
      { property: 'actions',      labelId: 'au.taps.actions',              width: 118,    formatters: [{ func: 'tapFilterActions' }] }
    ]
  },
  tapFiltersCreate: {
    tablePkField: 'sequence',
    columnDefs: [
      { property: 'sequence',   labelId: 'au.taps.seq',                  width: 42 },
      { property: 'filterType', labelId: 'au.taps.filterType',           width: 220,    formatters: [{ func: 'tapFilterType' }] },
      { property: 'details',    labelId: 'au.taps.details',              width: 'auto', formatters: [{ func: 'tapFilterDetails' }] },
      { property: 'type',       labelId: 'au.entity.taps.permitDeny',    width: 138,    formatters: [{ func: 'tapFilterPermission' }] },
      { property: 'order',      labelId: 'au.taps.order',                width: 70,     formatters: [{ func: 'tapFilterOrderBtn' }] },
      { property: 'actions',    labelId: 'au.taps.delete',               width: 70,     formatters: [{ func: 'tapFilterDeleteBtn' }] },
    ]
  },
  forkFiltersCreate: {
    tablePkField: 'sequence',
    columnDefs: [
      { property: 'sequence',   labelId: 'au.taps.seq',                  display: false },
      { property: 'filterType', labelId: 'au.taps.filterType',           width: 220,    formatters: [{ func: 'tapFilterType' }] },
      { property: 'details',    labelId: 'au.taps.details',              width: 'auto', formatters: [{ func: 'tapFilterDetails' }] },
      { property: 'type',       labelId: 'au.entity.taps.permitDeny',    width: 138,    formatters: [{ func: 'tapFilterPermission' }] },
      { property: 'order',      labelId: 'au.taps.order',                width: 70,     formatters: [{ func: 'tapFilterOrderBtn' }] },
      { property: 'actions',    labelId: 'au.taps.delete',               width: 70,     formatters: [{ func: 'tapFilterDeleteBtn' }] },
    ]
  },
  userGroupUserMemberCreate: {
    tablePkField: 'id',
    columnDefs: [
      { property: 'id',       labelId: 'au.accounts.user.id',           width: '380px' },
      { property: 'username', labelId: 'au.accounts.user.username',     width: 'auto' },
      { property: 'email',    labelId: 'au.accounts.user.email',        width: 'auto' },
      { property: 'actions',  labelId: 'au.userGroup.members.delete',   width: '280px' }
    ]
   },
   userGroupClientMemberCreate: {
    tablePkField: 'id',
    columnDefs: [
      { property: 'id',       labelId: 'au.accounts.user.id',           width: '380px' },
      { property: 'name',     labelId: 'au.entity.name',                width: 'auto' },
      { property: 'email',    labelId: 'au.accounts.user.email',        width: 'auto' },
      { property: 'actions',  labelId: 'au.userGroup.members.delete',   width: '280px' }
    ]
   },
   flowTableView: {
    tablePkField: 'name',
    columnDefs: [
      { property: 'type',   labelId: 'au.entity.flowTable.type',    width: 200},
      { property: 'status', labelId: 'au.entity.flowTable.status',  width: 200},
      { property: 'name',   labelId: 'au.entity.flowTable.name',    width: 'auto'},
    ]
  }
});
