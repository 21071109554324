import { getLoggers } from '../logger';

const { exception } = getLoggers('FeedGraph');

export function updateRefs(struct, direction) {
  // update in/out references to new stuct's
  for (let node of struct.nodes.values()) {
    const ourNodes = new Map();
    for (let childNode of node[direction].values()) {
      if (struct.nodes.has(childNode.aui)) {
        ourNodes.set(childNode.aui, struct.nodes.get(childNode.aui));
      }
    }
    node[direction] = ourNodes;
  }
}


export function ensureFields(nodes, reqFields) {
  for (let node of nodes) {
    if (node['inputFlow'] && node['outputFlow'] === undefined) {
      node['outputFlow'] = "";
    }
    if (node['inputFlow'] === undefined && node['outputFlow'] === undefined) {
      node['inputFlow'] = "";
      node['outputFlow'] = "";
    }
    for (let field of reqFields) {
      if (node[field] === undefined) {
        exception(`node is missing field ${field}`);
      }
    }
  }
}


export function flipDirection(direction) {
  return direction === 'input' ? 'output' : 'input';
}

// apply `fn` to node's lineage in a specific direction to a certain depth
export function forEach(start, fn, direction, depth=Infinity) {
  if (depth === 0) return;
  fn(start);
  for (let relative of start[direction].values()) {
    forEach(relative, fn, direction, depth - 1);
  }
}

function *genForever(iterable) {
  while (true) { // eslint-disable-line no-constant-condition
    for (let x of iterable) {
      yield x;
    }
  }
}

function swap(items, idx1, idx2) {
  [items[idx1], items[idx2]] = [items[idx2], items[idx1]];
}

/**
 * Recursively searches in the specified direction for the end node returning a
 * boolean indicating if the node was found or not. Search is depth first.
 * @param  {Object}  start     start node being searched for
 * @param  {Object}  end       end node being search for
 * @param  {String}  direction 'input' or 'output', expected iterable property
 *                             that returns adjacent nodes in that direction
 * @return {Boolean}           true if end is found, false otherwise
 */
export function isKin(start, end, direction) {
  for (let node of start[direction].values()) {
    if (node === end) return true;
    if (isKin(node, end, direction)) {
      return true;
    }
  }
  return false;
}

// based on common backtracking examples
export function *genPermutations(iterable, startIdx=0) {
  const items = [...iterable];
  const endIdx = items.length - 1;
  if (startIdx === endIdx) {
    yield items;
  }
  else {
    for (let i=startIdx; i<=endIdx; ++i) {
      swap(items, startIdx, i);
      yield* genPermutations(items, startIdx + 1, endIdx);
      swap(items, i, startIdx);
    }
  }
}

function isExclusive(assignments) {
  const uniqueAssignments = new Set(assignments);
  if (uniqueAssignments.size < assignments.length) {
    return false;
  }
  return true;
}

export function getNumPicks(iterable) {
  return [...iterable]
    .map(items => new Set(items))
    .map(items => items.size)
    .reduce((n,is) => n*is, 1);
}

/**
 * Generates "picks" of iterable in each "slot". The order will looks
 * similar to a counter with the lowest indexed node iterating on every tick,
 * i.e. [9, 3, 4] -> [0, 4, 4] (using base10).
 * @return {Generator} Array[picked item in each slot]
 */
export function *genPicks(iterable, exclusive=false) { // NOTE: exclusive is not cheap
  // Convert each slot's iterable to a set
  const slots = [...iterable]
    .map(items => new Set(items));

  if (!slots.length) {
    yield [];
    return;
  }

  const itemsSizes = slots
    .map(items => items.size);

  const emptySlots = itemsSizes.filter(s => s === 0);
  if (emptySlots.length) {
    throw 'cannot generate picks with empty slots';
  }

  const itemsMods = [itemsSizes[0]];
  for (let i=0; i<itemsSizes.length-2; ++i) {
    itemsMods.push(itemsMods[i] * itemsSizes[i+1]);
  }
  itemsMods.unshift(1); // first element changes on each tick
  const numPicks = itemsMods[itemsMods.length-1] * itemsSizes[itemsSizes.length-1];

  const generators = slots
    .map(items => genForever(items));

  // Initialize and yield first assignments
  const assignments = [];
  for (let i=0; i<generators.length; i++) {
    assignments.push(generators[i].next().value);
  }
  if (!exclusive || isExclusive(assignments)) {
    yield [...assignments];
  }

  for (let pick=1; pick<numPicks; ++pick) {
    for (let i=0; i<assignments.length; ++i) {
      // On each permutation check if that index needs to be iterated by using
      // its mod value
      if (pick % itemsMods[i] === 0) {
        assignments[i] = generators[i].next().value;
      }
    }

    if (!exclusive || isExclusive(assignments)) {
      yield [...assignments];
    }
  }
}
