import React from 'react';

import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import AuButton, { BUTTON_TYPE_PRIMARY } from '@au/core/lib/components/elements/AuButton';

import { history as browserHistory } from '../../../history';
import { required, validVin, validUuid } from '../../../utils/validationRules';
import { trackableActions, trackableElements } from '../../../utils/analyticsHelpers';
import { setPageTitle } from "../../utils/pageTitle";
import EntityLookup from '../Lookup';

import styles from '../../../css/components/entity_lookup.module.scss';

export default class VehicleLookup extends EntityLookup {

  attrDisplayId = 'au.vehicles.vehicleIdOrVin'

  onCreateVehicleBtnClick = this.onCreateVehicleBtnClick.bind(this);
  onCreateVehicleBtnClick() {
    AuAnalytics.trackEvent({
      category: trackableElements.BUTTON,
      action: trackableActions.CREATE,
      label: 'VehicleLookup'
    });
    //FIXME - MOVE TO linkHelper
    browserHistory.push(this.baseUrl + '/create');
  }

  componentDidMount() {
    setPageTitle(this.props.actions.setPageTitle, [{displayId: "au.entity.title.vehicle"}]);
  }

  getAddons() {
    const addons = super.getAddons();
    addons.push(
      <div key="addons_buttons" className={styles.buttons}>
        <AuButton
          type={BUTTON_TYPE_PRIMARY}
          displayId="au.vehicles.createVehicle"
          onClick={this.onCreateVehicleBtnClick}
        />
      </div>
    );

    return addons;
  }

  validate() {
    const { inputStr } = this.state;
    let validationError;

    // required
    validationError = required(inputStr);
    if (validationError) {
      validationError.fieldDisplayId = this.attrDisplayId;
    }

    let isVin = false;
    let isUuid = false;

    if (!validationError) {
      // is not valid vin
      const vinError = validVin(inputStr);
      isVin = !vinError;
      // is not uuid
      const idError = validUuid(inputStr);
      isUuid = !idError;

      if (vinError && idError) {
        validationError =  { ...vinError, fieldDisplayId: this.attrDisplayId };
      }
    }

    this.setState(prevState => ({
      showError: prevState.submitted && Boolean(validationError),
      error: validationError,
      isUuid,
      isVin
    }));

    return validationError;
  }

  lookup() {
    const { endpoint } = this.props;
    const { isVin, isUuid, inputStr } = this.state;

    if (isVin) {
      return endpoint.getByVin({
        vin: inputStr,
        includeRelations: 'bindings',
        expandRelations: 'bindings'
      });
    } else if (isUuid) {
      return endpoint.get(inputStr, {
        includeRelations: 'bindings',
        expandRelations: 'bindings'
      });
    }
  }

}
