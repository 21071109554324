import { required, validUuid } from '../../../utils/validationRules';
import { setPageTitle } from "../../utils/pageTitle";
import EntityLookup from '../Lookup';

export default class DeviceLookup extends EntityLookup {

  attrDisplayId = 'au.devices.deviceIdOrEsn'

  componentDidMount() {
    setPageTitle(this.props.actions.setPageTitle, [{displayId: "au.entity.title.device"}]);
  }

  validate() {
    const { inputStr } = this.state;
    // required
    let validationError = required(inputStr);
    let idError = {};

    if (!validationError) {
      // is not uuid
      idError = validUuid(inputStr);
    }

    this.setState(prevState => ({
      showError: prevState.submitted && Boolean(validationError),
      error: {
        ...validationError,
        fieldDisplayId: this.attrDisplayId
      },
      isUuid: !idError
    }));

    return validationError;
  }

  lookup() {
    const { endpoint } = this.props;
    const { isUuid, inputStr } = this.state;

    if (isUuid) {
      return endpoint.get(inputStr, { includeRelations: 'binding', expandRelations: 'binding' });
    } else {
      return endpoint.getByUniqueProperty({
        uniquePropertyValue: inputStr,
        includeRelations: 'binding',
        expandRelations: 'binding'
      });
    }
  }

}
