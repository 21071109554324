import React from 'react';
import moment from 'moment-timezone';
import cn from 'classnames';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { get } from '../../../utils/api';
import TimezoneSelector from '../../TimezoneSelector';
import DefaultView from '../View';

import styles from '../../../css/components/entity_view.module.scss';

export default class RulesView extends DefaultView {

  async loadData() {
    if (this.entityId) {
      return await get(`/1/processing-crud/rule/${this.entityId}`)
      .then((resp) => {
        this.setState({data: resp?.data, ready: true, found: true});
        return resp
      }, (err) => {
        if (err.data.code === 404) {
          return err;
        }
        else {
          this.setState({ errorStatusCode: err.data.code, errorStatusMessage: err.data.message });
          throw err;
        }
      });
    }
  }

  onTimeZoneChange = this.onTimeZoneChange.bind(this);
  onTimeZoneChange(timezone) {
    this.setState({ timezone });
    localStorage.setItem('timezone', timezone);
  }

  renderContentAboveTable() {
    return (
      <div className={styles.table_topper}>
        <TimezoneSelector onChange={this.onTimeZoneChange} />
      </div>
    )
  }

  parseCondition(cond) {
    if (cond) {
      cond = cond.replace('{', "{\n");
      cond = cond.replace('}', "\n}");
      cond = cond.replace('(', "{\n");
      cond = cond.replace(')', "\n}");
      cond = cond.replace('and ', "\nand\n");
      cond = cond.replace('and not', "\nand not\n");
    }
    return cond;
  }

  renderSection() {
    const { data } = this.state;
    const newCond = this.parseCondition(data?.condition);
    const sessionTimezone = localStorage.getItem('timezone');

    return (
      <table className={styles.rows} ref={ref => this.tableRef = ref}>
        {data &&
          <tbody>
            <tr key={`field_ruleId`} className={styles.row} ref={ref => this.rowRef = ref} >
              <td className={styles.fieldname}>
                <AutoIntl displayId={'au.entity.attr.ruleId'} />
              </td>
              <td className={styles.fieldvalue}>
                {data.ruleId}
              </td>
            </tr>
            <tr key={`field_name`} className={styles.row} ref={ref => this.rowRef = ref} >
              <td className={styles.fieldname}>
                <AutoIntl displayId={'au.entity.attr.ruleName'} />
              </td>
              <td className={styles.fieldvalue}>
                {data.name}
              </td>
            </tr>
            <tr key={`field_validFrom`} className={styles.row} ref={ref => this.rowRef = ref} >
              <td className={styles.fieldname}>
                <AutoIntl displayId={'au.entity.attr.validTime'} />
              </td>
              <td className={styles.fieldvalue}>
                {moment.tz(data.validFrom, sessionTimezone).format('MMM DD YYYY hh:mm:ss.SSS a z')}
              </td>
            </tr>
            <tr key={`field_precedenceFlag`} className={styles.row} ref={ref => this.rowRef = ref} >
              <td className={styles.fieldname}>
                <AutoIntl displayId={'au.entity.attr.precedenceFlag'} />
              </td>
              <td className={styles.fieldvalue}>
                {data.ruleVersion}
              </td>
            </tr>
            <tr key={`field_condition`} className={styles.row} ref={ref => this.rowRef = ref} >
              <td className={styles.fieldname}>
                <AutoIntl displayId={'au.entity.attr.condition'} />
              </td>
              <td className={cn(styles.fieldvalue, styles.pre_line)}>
                {newCond}
              </td>
            </tr>
          </tbody>
        }
      </table>
    );
  }
}