import canvg from 'canvg';
import { formatMessage } from '../utils/reactIntl';

const svgNS = "http://www.w3.org/2000/svg";
const copyrightStyles = (
  'fill: #324661; font-size: 16px; font-family: Arimo; opacity: .5;'
);
const RE = /,?\s?>?\s?:[a-z0-9-:,]+(\(.+\))?/g;

function getElementCss(element) {
  const styleSheets = [];

  const siblings = element.getElementsByTagName('*');
  const elements = [element, ...siblings];

  for (let sheet of [...document.styleSheets]) {
    for (let rule of [...sheet.cssRules]) {
      if (!rule.selectorText) continue;

      /*
        Safari raises a SyntaxError when an invalid query selector provided.
        Pseudo-classes (e.g. :nth-child, ::before, etc.) are not allowed.
        Here we're using a basic regex to remove pseudo-classes from selectors,
        but probably there are cases that the regex won't cover.
      */
      let selector = rule.selectorText.replace(RE, '').trim();

      if (!selector) continue;

      let matches;

      try {
        // check the element + all siblings for matching selector
        matches = elements.some(el => el.matches(selector));
      } catch (e) {
        if (!(e instanceof SyntaxError)) {
          throw e;
        }
      }

      if (matches) {
        styleSheets.push(rule.cssText);
      }
    }
  }

  return styleSheets.join('\n');
}

function injectStyles(svgElement) {
  let defs  = document.createElementNS(svgNS, 'defs');
  let style = document.createElementNS(svgNS, 'style');
  /* add inline styles to svg defs section */
  style.innerHTML = getElementCss(svgElement);
  defs.appendChild(style);
  svgElement.prepend(defs);
}

function injectCopyright(svgElement, ctx) {
  let copyright = formatMessage(
    { id: 'au.legal.allRightsReserved' },
    { year: new Date().getFullYear() }
  );
  let copyTextTop = document.createElementNS(svgNS, 'text');
  copyTextTop.setAttribute('x', ctx.canvas.width - 360);
  copyTextTop.setAttribute('y', -20);
  copyTextTop.setAttribute('style', copyrightStyles);
  copyTextTop.appendChild(document.createTextNode(copyright));

  let copyTextBottom = copyTextTop.cloneNode(true);
  copyTextBottom.setAttribute('y', ctx.canvas.height - 60);

  svgElement.appendChild(copyTextTop);
  svgElement.appendChild(copyTextBottom);
}

export default async function svg2png(svgElement, callback) {
  let { width, height } = svgElement.getBBox();
  let clonedSvgElement = svgElement.cloneNode(true);

  let canvas = document.createElement('canvas');
  canvas.width = width + 100;  // add extra space
  canvas.height = height + 130; // add extra space

  let ctx = canvas.getContext('2d');
  // fill canvas background
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  injectStyles(clonedSvgElement);
  injectCopyright(clonedSvgElement, ctx);

  let data = (new XMLSerializer()).serializeToString(clonedSvgElement);
  let v = await canvg.fromString(ctx, data, { ignoreClear: true, offsetY: 50 });
  // render SVG on the canvas
  await v.render();

  callback(canvas.toDataURL('image/jpeg'));
}
