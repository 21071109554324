import Immutable, { fromJS } from 'immutable';

import { auPersist } from '@au/core/lib/utils/stateDelegators';

import AuSpec from '../utils/AuSpec';
import AuSpecs from '../AuSpecs';

/*
 * This file contains init reducer which describes initial state of the store.
 */

export default {
  "REDUX_INIT": (state) => {
    let initialState = (state || Immutable.Map()).mergeDeep(fromJS({
      location: {},
      popout: {},
      services: { }, // for storing service-scoped entities
      settings: { },
    }));

    // Deep merge with localStorage
    const persistentState = auPersist.read();
    // Assert that our state is what it should be
    const spec = AuSpecs(AuSpec.builder({strict: true})).persistentState;
    if (spec.valid(persistentState.toJS())) {
      initialState = initialState.mergeDeep(persistentState);
    } else {
      // The state no longer matches!
      console.warn('Local storage state is not up to spec! Resetting local storage state'); // eslint-disable-line no-console
      auPersist.clear();
    }

    return initialState;
  }
};
