import React from "react";
import * as T from "prop-types";
import {ConfirmationDialog} from "@au/core/lib/components/objects";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import AuDropDown from "@au/core/lib/components/elements/AuDropDown";
import {injectIntl} from "react-intl";
import moment from "moment";
import DateTimeFields from "../filters/DateTimeFields";

import styles from '../../css/components/start_fork_dialog.module.scss';

const yearFormat = "yyyy";
const dateFormat = "MM/DD";
const timeFormat = "hh:mm";
const timePeriodFormat = "a";
const zoneFormat = "Z";

const currentDateTime = moment().tz(moment.tz.guess());
const currentYear = currentDateTime.format(yearFormat);
const currentDate = currentDateTime.format(dateFormat);
const currentTime = currentDateTime.format(timeFormat);
const currentTimePeriod = currentDateTime.format(timePeriodFormat);
const currentTimeZone = currentDateTime.format(zoneFormat);

const LATEST = "LATEST";
const POINT_OF_PAUSE = "POINT_OF_PAUSE";
const EARLIEST = "EARLIEST";
const CUSTOM = "CUSTOM";

class StartDialog extends React.PureComponent {
  static propTypes = {
    show: T.bool,
    onConfirm: T.func.isRequired,
    onCancel: T.func.isRequired,
  }

  state = {
    currentSelection: LATEST,
    datetime: {year: currentYear, date: currentDate, time: currentTime, timePeriod: currentTimePeriod, timezone: currentTimeZone, error: ""},
  }

  constructor(props) {
    super(props);

    const { intl } = props;

    this.options = [
      { val: LATEST, displayId: "au.latest" },
      { val: POINT_OF_PAUSE, displayId: "au.pointOfPause" },
      { val: EARLIEST, displayId: "au.earliest" },
      { val: CUSTOM, displayId: "au.custom", className: styles.list_break }
    ];

    this.options.push({
      val: moment().subtract({ hours: 1 }).toISOString(),
      displayString: intl.formatMessage({ id: "au.hr" }, { hours: 1 })
    });
    this.options.push({
      val: moment().subtract({ hours: 3 }).toISOString(),
      displayString: intl.formatMessage({ id: "au.hr" }, { hours: 3 })
    });
    this.options.push({
      val: moment().subtract({ hours: 6 }).toISOString(),
      displayString: intl.formatMessage({ id: "au.hr" }, { hours: 6 })
    });
    this.options.push({
      val: moment().subtract({ hours: 12 }).toISOString(),
      displayString: intl.formatMessage({ id: "au.hr" }, { hours: 12 })
    });
  }

  handleStartTimeSelect = this.handleStartTimeSelect.bind(this);
  handleStartTimeSelect(selection) {
    this.setState({currentSelection: selection});
  }

  onConfirm = this.onConfirm.bind(this);
  onConfirm() {
    const { entityType } = this.props;

    if([LATEST, POINT_OF_PAUSE, EARLIEST].includes(this.state.currentSelection)){
      if (entityType === 'fork') {
        return this.props.onConfirm({ resumeAt: this.state.currentSelection});
      } else {
        return this.props.onConfirm({ pointInTime: this.state.currentSelection});
      }
    }
    if(this.state.currentSelection === CUSTOM) {
      const {year, date, time, timePeriod, timezone} = this.state.datetime;
      const timeToStart = moment(`${date}/${year} ${time} ${timePeriod} ${timezone}`, `${dateFormat}/${yearFormat} ${timeFormat} ${timePeriodFormat} ${zoneFormat}`);
      return this.props.onConfirm({timestamp: timeToStart.toISOString()});
    }

    this.props.onConfirm({timestamp: this.state.currentSelection});
  }

  render() {
    const {show, onCancel, confirmDisplayId, headerDisplayId, messageDisplayId} = this.props;

    if (!show) {
      return false;
    }
    return (
      <ConfirmationDialog
        confirmDisplayId={confirmDisplayId}
        className={styles.container}
        onConfirm={this.onConfirm}
        onCancel={onCancel}
        titleId={headerDisplayId}
        headerClassName={styles.header}
      >
        <AutoIntl displayId={messageDisplayId} tag={'div'} className={styles.message}/>
        <AuDropDown
          options={this.options}
          selectOption={this.handleStartTimeSelect}
          selection={this.state.currentSelection}
          disableSort
        />
        {this.state.currentSelection === CUSTOM &&
        <>
          <DateTimeFields
            className={styles.date_time}
            datetime={this.state.datetime}
            updateDatetime={(newDatetime) => this.setState(prevState => ({datetime: {...prevState.datetime, ...newDatetime}}))}
            hideYear
            includeTimezone
          />
          <AutoIntl displayId='au.dataResumeLimit' tag='div' className={styles.data_resume_limit}/>
        </>
        }
      </ConfirmationDialog>
    );
  }
}

export default injectIntl(StartDialog);
