import React from 'react';
import * as T from 'prop-types';

import { SelectableListProps } from '../../AuPropTypes';
import { NOOP, validityStatus, INVALID } from '../../constants';
import FilterSelectableList from "./FilterSelectableList";

export default class ValidityStatus extends React.Component {

  static propTypes = {
    defaultValue: SelectableListProps.defaultValue,
    containerRef: SelectableListProps.containerRef,
    selection: SelectableListProps.selection,
    sort: SelectableListProps.sort,
    multiSelect: T.bool,
    onChange: T.func,
    onInit: T.func
  }

  static defaultProps = {
    onChange: NOOP,
    onInit: NOOP,
    selection: []
  }

  validityStatusItems = Object.values(validityStatus).map(key => {
    const value = key === INVALID ? 'false': 'true';
    return { displayId: `au.entity.state.${key}`, value };
  });

  render() {
    return (
      <FilterSelectableList
        containerRef={this.props.containerRef}
        items={this.validityStatusItems}
        sort={this.props.sort}
        defaultValue={this.props.defaultValue}
        selection={this.props.selection}
        multiSelect={this.props.multiSelect}
        onChange={this.props.onChange}
        onInit={this.props.onInit}
      />
    );
  }

}
