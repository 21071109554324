import TMC from "@autonomic/browser-sdk";
import Edit from "../Edit";
import { history as browserHistory } from '../../../history';
export default class CommandManagementEdit extends Edit {

  definitionsEndpoint = new TMC.services.Command({ apiVersion: "1-beta" }).definitions;

  disableSaveBtn = this.disableSaveBtn.bind(this);
  disableSaveBtn() {
    return this.state.hideContent
  }

  handleOnSave = this.handleOnSave.bind(this);
  handleOnSave(modifiedEntity) {

    return this.definitionsEndpoint.editMetadata(modifiedEntity.id, {
      description: modifiedEntity?.description,
      userTags: modifiedEntity?.userTags
    }).then(({ data }) => {
      browserHistory.push(`${this.baseUrl}/${encodeURIComponent(`${data.id}`)}/view`);
    }).catch((err) => {
      const e = { data: { error: '', message: this.getErrorMessage(err._data) } };
      this.genericErrorHandler(e);
    })
  }

}