import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ENTER_KEY } from "@au/core/lib/constants";
import AuChips from '@au/core/lib/components/elements/AuChips';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { NOOP } from '../constants';
import { formatMessage } from '../utils/reactIntl';
import { validVin, noListDuplicates } from '../utils/validationRules';
import { run } from '../utils/validationRuleRunner';

import styles from '../css/components/vin_editor.module.scss';

const validationRules = [noListDuplicates('')];

// currenly supported separators are: comma, semicolon and white space
const SEPARATOR_RE = /[,;\s]/;

const SPACE_KEY = " ";
const SEMICOLON_KEY = ";";
const COMMA_KEY = ",";

export default class VinEditor extends React.Component {

  static propTypes = {
    vins: T.array,
    placeholder: T.string,
    className: T.string,
    onChange: T.func,
    showVinLimit: T.bool
  }

  static defaultProps = {
    vins: [],
    onChange: NOOP
  }

  state = {
    errors: [],
    vins: this.props.vins
  }

  storedVins = {}

  componentDidMount() {
    const { vins } = this.props;
    this.storedVins = vins.reduce((acc, vin) => (acc[vin]='', acc), {});
  }

  handleChipsChange = this.handleChipsChange.bind(this);
  handleChipsChange([...vins]) {
    const userInput = vins.pop();
    const nextVins = [...vins, ...(userInput || '').trim().split(SEPARATOR_RE).filter(text => text.trim())];
    const validationErrors = run(nextVins, validationRules);
    const errors = (validationErrors || {}).errors || [];

    const field = formatMessage({ id: 'au.entity.attr.vin' });
    for (let i=0, len=nextVins.length; i < len; i++) {
      let vErr = run(nextVins[i], [validVin]);
      if (vErr.errDisplayId) {
        errors[i] = { ...vErr, values: { field } };
      }
    }

    this.setState({
      vins: nextVins,
      totalErrors: errors.filter(e => e).length,
      errors
    }, this.commitChanges);
  }

  commitChanges() {
    const { vins, errors } = this.state;
    this.props.onChange(vins, errors);
  }

  render() {
    const { className, showVinLimit } = this.props;
    const { errors, totalErrors, vins } = this.state;
    const count = vins.length;

    return (
      <div className={cn(styles.container, className)}>
        <AuChips
          chips={vins}
          errors={errors}
          storedChips={this.storedVins}
          placeholderId="au.vinEditor.placeholder.typeVins"
          handleOnPaste={true}
          createChipKeys={[ENTER_KEY,SPACE_KEY,SEMICOLON_KEY,COMMA_KEY]}
          onChange={this.handleChipsChange}
        />
        { !showVinLimit && Boolean(totalErrors) &&
          <AutoIntl className={styles.error} displayId="au.vinEditor.invalidVins" values={{ count: totalErrors }} />
        }
        <AutoIntl className={styles.note} displayId="au.vinEditor.vinsSeparationNote" />
        { showVinLimit && Boolean(totalErrors) &&
          <AutoIntl className={styles.error} displayId="au.vinEditor.invalidVins" values={{ count: totalErrors }} />
        }
        { showVinLimit && count !== 0 &&
          <div className={count > 100 ? styles.error_container : styles.hint_container}>
            <FormattedMessage className={count ? styles.error : styles.hint}
              id="au.vinEditor.maxVins"
              values={{
                count,
                cta: chunks => <strong>{chunks}</strong>
              }}
            />
          </div>
        }
      </div>
    );
  }
}
