/**
 * Set of helper functions for formatting various elements of tracking
 */

import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import {formatMessage} from './reactIntl';

// Add anything trackable that we can't infer from data
export const trackableElements = Object.freeze({
  ACTION_BUTTON: 'ActionButton',
  BUTTON: 'Button',
  ENTITY_LINK: 'EntityLink',
  FILTER: 'Filter',
  TMC_METRICS_LINK: 'TmcMetricsLink',
  TOGGLE: 'Toggle'
});

export const trackableActions = Object.freeze({
  CANCEL: 'Cancel',
  CREATE: 'Create',
  DELETE: 'Delete',
  UPDATE: 'Update',
});

/**
 * Proxy analytics request off to AuAnalytics
 * 
 * @param {string} element - Element acted upon
 * @param {string} action - Element option/EntityType/Action (ex. Create, Cancel, Tap, Flow)
 * @param {string} page - Page element was on (ex. TapList, FlowDetail)
 */
export function trackEvent({ element, action, page }) {
  AuAnalytics.trackEvent({ 
    category: element, 
    action: AuAnalytics.formatTrackingText(action), 
    label: AuAnalytics.formatTrackingText(page) 
  });
}

function pascalCaseString(string) {
  return string
    .split(' ')
    .map(AuAnalytics.formatTrackingText)
    .join('');
}

function getTrackingAction(action) {
  if (action.displayString) {
    return pascalCaseString(action.displayString);
  }

  if (action.displayId) {
    return pascalCaseString(formatMessage({ id: action.displayId }));
  }
}

export function wrapActionWithTracking(action, entityAlias, viewAction) {
  const trackingAction = getTrackingAction(action);

  return {
    ...action,
    tracking: {
      element: trackableElements.ACTION_BUTTON,
      action: trackingAction,
      page: `${AuAnalytics.formatTrackingText(entityAlias)}${AuAnalytics.formatTrackingText(viewAction)}`
    }
  };
}
