import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as ActionCreators from '../ActionCreators';
import GlobalNavigation from '../components/GlobalNavigation';
import PropProvider from '../PropProvider';

export default connect(
  (state, ownProps) => PropProvider(state).GlobalNavigation(ownProps),
  (dispatch) => ({actions: bindActionCreators(ActionCreators, dispatch)})
)(GlobalNavigation);
