import React from 'react';
import cn from 'classnames';
import * as T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import AuButton from '@au/core/lib/components/elements/AuButton';
import AuInput from '@au/core/lib/components/elements/AuInput';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { memberType } from '../../../constants';
import { history as browserHistory } from '../../../history';
import { generateEntityPath } from '../../../utils/entity';
import { formatMessage } from '../../../utils/reactIntl';
import { validVin } from '../../../utils/validationRules';
import loadingIcon from '../../../images/spinner_white.gif';
import DefaultView from '../View';
import GroupMembersEditor from '../../GroupMembersEditor';

import styles from '../../../css/components/group_manage_members.module.scss';
import fieldset from '../../../css/fieldset.module.scss';

function parseResponseArr(responseArr) {
  return responseArr.reduce((acc, resp) => {
    if (resp.vin) {
      acc.vehicles.push(resp.vin);
    }
    else if (resp.groupMemberId) {
      if (resp.groupMemberId.vehicleId) {
        acc.vehicles.push(resp.groupMemberId.vehicleId);
      }
      else if (resp.groupMemberId.deviceId) {
        acc.devices.push(resp.groupMemberId.deviceId);
      }
    }

    return acc;
  }, { devices: [], vehicles: [] });
}

// Expects `respArray` to be an array of nested response objects from original response
// e.g.- [{status: 200, id: '123'}, {status: 400, id: '456}]
function handleBulkResponse({ respArray, onItemSuccess, onError, onAllSuccess }) {
  let errItems = [];

  respArray.forEach(resp => {
    if (resp.status >= 400) {
      errItems.push(resp);
    } else {
      onItemSuccess && onItemSuccess(resp);
    }
  });

  if (errItems.length && onError) {
    return onError(errItems);
  } else if (onAllSuccess) {
    return onAllSuccess(respArray);
  }
}

export default class GroupManageMembers extends DefaultView {

  static propTypes = {
    entityDef: T.object.isRequired,
    match: T.shape({
      params: T.shape({
        action: T.string.isRequired
      }).isRequired
    }).isRequired
  }

  constructor(props) {
    super(props);

    const { entityDef, match } = this.props;
    const { action } = match.params;

    this.state = {
      ...super.state,
      groupMembers: Object.values(memberType).reduce((acc, type) => {
        acc[type] = [];
        return acc;
      }, {}),
      errors: {}
    };

    this.entityId  = props.parentEntity.entityId;
    this.baseUrl   = match.url.split('/').slice(0, -1).join('/');
    this.parentUrl = this.baseUrl + `/${ entityDef.landingPage || 'list'}`;
    this.action    = action;
  }

  getCrumbs() {
    const crumbs = super.getCrumbs();

    crumbs.splice(2, 1, {
      key: `crumb_${this.entityId}_${this.action}Members`,
      displayId: `au.group.${this.action}Members`
    });

    return crumbs;
  }

  getNavLinks() {
    return [];
  }

  loadData() {
    // we don't need any data to render the page
    this.setState({ found: true });
    return Promise.resolve();
  }

  onMembersListChange(type, members, errors) {
    this.setState(prevState => ({
      groupMembers: {
        ...prevState.groupMembers,
        [type]: members
      },
      errors: {
        ...prevState.errors,
        [type]: errors.filter(e => e).length > 0
      }
    }));
  }

  getUpdates() {
    const { endpoint } = this.props;
    const { groupMembers } = this.state;
    let updates = [];
    let memberIds = [];
    let memberVins = [];

    for (let [type, members] of Object.entries(groupMembers)) {
      if (type === memberType.VEHICLE) continue;
      memberIds = [...memberIds, ...members.map(id => ({ [`${type}Id`]: id }))];
    }

    for (let vinOrId of groupMembers.vehicle) {
      // distinguish UUIDs from VINs
      if (validVin(vinOrId) === null) {
        memberVins.push(vinOrId);
      } else {
        memberIds.push({ vehicleId: vinOrId });
      }
    }

    if (memberIds.length) {
      if (this.action === 'add') {
        updates.push(endpoint.batchAdd(memberIds));
      } else if (this.action === 'remove') {
        updates.push(endpoint.batchRemove(memberIds));
      }
    }
    if (memberVins.length) {
      if (this.action === 'add') {
        updates.push(endpoint.addMembersByVin(memberVins));
      } else if (this.action === 'remove') {
        updates.push(endpoint.removeMembersByVin(memberVins));
      }
    }

    return updates;
  }

  handleOnSave = this.handleOnSave.bind(this);
  handleOnSave() {
    return new Promise(async resolve => { // eslint-disable-line no-async-promise-executor
      this.setState({ saving: true });
      try {
        // getUpdates() returns a list of promises to be resolved,
        // since VINs/IDs are added/removed via different requests.
        const saveProms = this.getUpdates();
        if (saveProms.length) {
          // This is often a `200` with the response containing status codes and results per asset acted upon
          // Results are per asset type AND type of action (add/remove vehicles or devices)
          // e.g. [[{addVehicleResp, data: [...]}], [{removeVehicleResp, data: [...]}], [{addDeviceResp, data: [...]}], [{removeDeviceResp, data: [...]}]];
          const saveResp = await Promise.all(saveProms);
          handleBulkResponse({
            respArray: saveResp.flatMap(resp => resp.data),
            onError: (errorItems=[]) => {
              const { vehicles, devices } = parseResponseArr(errorItems);
              this.setState({ saving: false });
              this.genericErrorHandler({
                data: {
                  error: formatMessage({ id: `au.group.members.saveError.${this.action}` }),
                  message: formatMessage(
                    { id: 'au.group.members.saveErrorReasons'},
                    { messages: [...vehicles, ...devices].join(', ') }
                  )
                }
              });
            },
            onAllSuccess: (responseArr) => {
              if (this.action === 'remove') {
                this.clearEntities();
              }
              browserHistory.push(this.parentUrl);

              const { vehicles, devices } = parseResponseArr(responseArr);
              const vehiclesCount = vehicles.length;
              const devicesCount = devices.length;

              let vehiclesMsg = vehiclesCount && formatMessage({ id: 'au.group.members.vehiclesCount' }, { count: vehiclesCount });
              let devicesMsg = devicesCount && formatMessage({ id: 'au.group.members.devicesCount' }, { count: devicesCount });

              let members;
              if (vehiclesCount && devicesCount) {
                // When we have both messages - combine into a single message
                members = formatMessage(
                  { id: 'au.group.members.vehiclesAndDevicesCount' },
                  { vehiclesMsg, devicesMsg }
                );
              }
              else {
                // Show either message, whichever was set
                members = vehiclesMsg || devicesMsg;
              }

              createResponseAlertMessage({data: {
                message: formatMessage(
                  { id: `au.group.members.saveSuccess.${this.action}` },
                  {
                    count: vehiclesCount + devicesCount,
                    members
                  }
                )
              }});
            }
          });
        }
      } catch (err) {
        this.setState({ saving: false });
        this.genericErrorHandler(err);
      }

      return resolve();
    });
  }

  clearEntities() {
    const { endpoint, serviceAlias, actions } = this.props;
    actions.listEntitiesSuccess({
      path: generateEntityPath(endpoint, serviceAlias),
      data: [],
      pkField: 'id',
      replace: true
    });
  }

  onCancelBtnClick = this.onCancelBtnClick.bind(this);
  onCancelBtnClick() {
    browserHistory.push(this.parentUrl);
  }

  hasErrors() {
    const { errors } = this.state;
    return Object.values(errors).some(err => err);
  }

  canSave() {
    const { saving, groupMembers } = this.state;
    return Boolean(!saving && !this.hasErrors() && Object.values(groupMembers).some(members => members.length));
  }

  renderEditors() {
    const editors = [];

    for (let type of Object.values(memberType)) {
      editors.push(
        <div className={fieldset.row} key={`${type}_editor`}>
          <label className={fieldset.label}>
            <FormattedMessage id={`au.group.members.${type}s`} />
          </label>
          <div className={fieldset.control}>
            <GroupMembersEditor
              type={type}
              onChange={this.onMembersListChange.bind(this, type)}
            />
          </div>
        </div>
      );
    }

    return editors;
  }

  renderContent() {
    const { saving } = this.state;
    const canSave = this.canSave();
    const savingMsg = formatMessage({ id: `au.entity.save.wait` });

    return (
      <div className={styles.container}>
        <div className={fieldset.table}>
          <div className={cn(fieldset.panel, styles.panel)}>
            <div className={fieldset.container}>
              <div className={fieldset.row}>
                <label className={fieldset.label}>
                  <FormattedMessage id="au.entity.attr.id" />
                </label>
                <div className={fieldset.control}>
                  <AuInput
                    value={this.entityId}
                    disabled={true}
                  />
                </div>
              </div>
              { this.renderEditors() }
            </div>
          </div>
          <div className={fieldset.buttons}>
            {saving ? (
              <AuButton type="primary" disabled={true}>
                <img src={loadingIcon} alt={savingMsg} />
                <span>{savingMsg}</span>
              </AuButton>
            ) : (
              <AuButton
                type={this.action === 'remove' ? 'alert' : 'primary'}
                disabled={!canSave}
                className={styles.save}
                onClick={this.handleOnSave}
                displayId={`au.group.${this.action}Members`}
              />
            )}
            <AuButton
              type="plain"
              className={fieldset.cancel}
              onClick={this.onCancelBtnClick}
              displayId="au.entity.cancel"
            />
          </div>
        </div>
      </div>
    );
  }
}
