import React from 'react';

import { history as browserHistory } from '../../../history';
import ClientSecretDialog from '../../ClientSecretDialog';
import DefaultCreate from './ClientEdit';

export default class ClientCreate extends DefaultCreate {

  /*
    This is a custom onSave handler to add an intermediate step - display
    client secret, that is available one time only during Client creation step.
  */
  handleOnSave = this.handleOnSave.bind(this);
  handleOnSave(client) {
    const { endpoint } = this.props;

    return endpoint.create(client).then(response => {
      this.setState({
        showSecretDialog: true,
        clientId: response.data.clientId,
        clientSecret: response.data.secret
      });
    })
  }

  handleOnClientSecretClose = this.handleOnClientSecretClose.bind(this);
  handleOnClientSecretClose() {
    const { clientId } = this.state;
    //FIXME - MOVE TO linkHelper
    const path = clientId ? this.baseUrl + `/${clientId}/view` : this.parentUrl;

    this.setState({ showSecretDialog: false });

    browserHistory.push(path);
  }

  renderNewSecretDialog() {
    const { showSecretDialog, clientId, clientSecret } = this.state;

    if (!showSecretDialog) {
      return false;
    }

    return (
      <ClientSecretDialog
        key="client_new_secret_dialog"
        isNew={true}
        clientId={clientId}
        clientSecret={clientSecret}
        onClose={this.handleOnClientSecretClose}
      />
    );
  }

  renderDialogs() {
    const dialogs = super.renderDialogs();
    dialogs.push(this.renderNewSecretDialog());
    return dialogs;
  }

}
