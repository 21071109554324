import React from 'react';
import lowerFirst from 'lodash/lowerFirst';
import AuInput from '@au/core/lib/components/elements/AuInput';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import ProcessingButton from '@au/core/lib/components/elements/ProcessingButton';
import Breadcrumbs from './entity/Breadcrumbs';
import { formatMessage } from '../utils/reactIntl';
import Lookup from "./entity/Lookup";

import styles from '../css/components/entity_lookup.module.scss';

export default class LandingPageLookup extends Lookup {

  renderControls() {
    const {inputStr, error, showError} = this.state;

    let placeholder;
    if (!this.attrPlaceholderId) {
      placeholder = formatMessage(
        {id: 'au.entity.type.placeholder'},
        {attrName: lowerFirst(formatMessage({id: this.attrDisplayId}))}
      );
    }

    return (
      <>
        <div className={styles.control} key="input_control">
          <AutoIntl
            className={styles.label}
            displayId={this.attrDisplayId}
            tag="div"
          />
          <AuInput
            autoFocus={true}
            name="input"
            value={inputStr}
            error={error}
            showError={showError}
            placeholder={placeholder}
            placeholderId={this.attrPlaceholderId}
            className={styles.input}
            onChange={this.handleInputChange}
            onKeyDown={this.onKeyDown}
          />
        </div>
        <div className={styles.control} key="btn_control">
          <ProcessingButton
            type="secondary"
            disabled={showError && Boolean(error)}
            className={styles.button}
            displayId="au.entity.viewDetails"
            onClick={this.handleButtonClick}
          />
        </div>
      </>
    );
  }

  getAddons() {
    return [];
  }

  renderAdditionalContent() {
    return false;
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <Breadcrumbs crumbs={this.props.breadcrumbs}/>
          <div className={styles.addons}>
            {this.getAddons()}
          </div>
        </div>
        <div className="o-wrapper">
          {super.render()}
          <div className={styles.extras}>
            {this.renderAdditionalContent()}
          </div>
        </div>
      </div>
    );
  }

}
