import React from 'react';
import cn from 'classnames';

import StateIcon from './StateIcon';

import styles from '../css/components/tap_validation_icon.module.scss';

export default function({ className, ...props }) {
  return <StateIcon {...props} className={cn(styles[props.icon], className )} />;
}

