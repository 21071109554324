import isEqual from "lodash/isEqual";
import { Map as imMap } from 'immutable';

import TMC from '@autonomic/browser-sdk';

import apiFactory from '../../../utils/api';
import { enhanceSdkEndpoint } from '../../../utils/api';
import { redirectOnSaveSuccess } from "../../../utils/linkHelper";
import { UUID_RE } from '../../../constants';
import {
  hasMembershipFilter,
  hasSignalFilter,
  isValidVin
} from '../../utils/taps';
import Edit from "../Edit";

export default class TapEdit extends Edit {

  getEntitiesToCreate() {
    const { entityDef, actions, resources, } = this.props;
    const { modifiedEntity } = this.state;

    const editedTap = {
      id: modifiedEntity.id,
      displayName: modifiedEntity.displayName,
      inputFlowId: modifiedEntity.inputFlowIdEdit,
      businessUnit: modifiedEntity.businessUnit,
      region: modifiedEntity.region,
      outputFlowId: Object.values(modifiedEntity.outputCreate)[0].aui,
      outputFlowDisplayName: Object.values(modifiedEntity.outputCreate)[0].displayName,
      filters: Object.values(modifiedEntity.outputCreate)[0].filters
    }

    this.endpoint = enhanceSdkEndpoint(
      new TMC.services.Feed(),
      'flows',
      actions
    );

    const extraProps = {
      inputFlow: undefined,
      outputFlowId: undefined,
      outputFlowDisplayName: undefined
    };

    let entitiesToCreate = super.getEntitiesToCreate();
    if (modifiedEntity.outputCreate) {
      // add flow(s)
      for (let [attr, { inlineCreate, source }] of Object.entries(entityDef.attributes)) {
        if (inlineCreate && source) {
          const { service: serviceAlias, entity: entityAlias, textProperty } = source;
          const resource = resources.get(`${serviceAlias}-${entityAlias}`, imMap());
          if (resource.size && Object.values(modifiedEntity.outputCreate)[0].displayName && !Object.values(modifiedEntity.outputCreate)[0].foundSelection && Object.values(modifiedEntity.outputCreate)[0].foundSelection !== null) {
            const groupKey = `${serviceAlias}-${entityAlias}`;
            const group = entitiesToCreate.get(groupKey) ?? { ...inlineCreate, entities: [] };
            const endpoint = apiFactory(serviceAlias, entityAlias, actions);

            group.entities.push({
              /* key is needed to differentiate between entities */
              key: `${groupKey}-${attr}`,
              idProp: endpoint.idProp,
              data: {
                [textProperty]: Object.values(modifiedEntity.outputCreate)[0].displayName,
                shardCount: Object.values(modifiedEntity.outputCreate)[0].shardCount
              },
              saveFn: data => {
                return endpoint.create(data).then(resp => {
                  extraProps['outputFlowId'] = resp.data[endpoint.idProp];
                  extraProps['outputFlowDisplayName'] = resp.data.displayName
                  return resp;
                });
              },
              halt: true,
              displayFields: ['displayName', 'id']
            });

            entitiesToCreate.set(groupKey, group);
          }
        }
      }
    }

    //All of our tap edits
    entitiesToCreate.set(`feed-service-updateTap`,
      {
        entities: [{
          key: `feed-service-updateTap`,
          idProp: entityDef.pkField,
          data: { ...editedTap },
          saveFn: (data) => {
            const modifiedData = { ...data };
            return this.updateEntity(modifiedData);
          },
          halt: true,
          displayFields: ['displayName', 'id']
        }],
        serviceAlias: 'feed-service',
        entityType: 'tap',
        entityAlias: 'tap',
        verbRoot: "edit",
      });

    return entitiesToCreate;
  }

  updateEntity = this.updateEntity.bind(this);
  updateEntity(entity) {
    const { endpoint } = this.props;

    const editedFields = {};

    editedFields.filters = entity.filters
    entity.filters.forEach(filter => {
      if (filter.attributeTagFilter) {
        editedFields.denyAttributeTags = true;
      }
    })

    editedFields.displayName = entity.displayName;
    editedFields.inputFlowId = entity.inputFlowId;
    editedFields.region = entity.region;
    editedFields.businessUnit = entity.businessUnit;
    editedFields.outputFlowDisplayName = entity.outputFlowDisplayName;

    return endpoint.patch(entity.id, editedFields).catch(this.genericErrorHandler);
  }

  areFiltersEqual(a = [], b = []) {
    if (a.length !== b.length) {
      return false;
    }

    return a.reduce((areFiltersEqual, filter, idx) => {
      if (!areFiltersEqual) return false;

      if (filter?.groupFilter) {
        return filter.groupFilter?.groupId === b[idx]?.groupFilter?.groupId;
      }
      return isEqual(filter, b[idx]);
    }, true);
  }

  onStatusDialogClose = this.onStatusDialogClose.bind(this)
  onStatusDialogClose(created, statuses) {
    if (created) {
      const { serviceAlias, match } = this.props;
      const status = statuses.get(`${serviceAlias}-updateTap`);

      redirectOnSaveSuccess(status.resp, this.props.endpoint, match.url);
    }
    else {
      this.getEntitiesToCreate();
      sessionStorage.setItem('created', created)
      this.endpoint.list().then(resp => resp.getItems(Infinity).then(items => {
        const flowOptions = items.map((flow) => ({
          val: flow.id,
          displayString: flow.displayName
        }));
        sessionStorage.setItem('updatedFlows', JSON.stringify(flowOptions));
      }, this.genericErrorHandler));
      this.setState({ showStatusDialog: false })
    }
  }


  disableSaveBtn = this.disableSaveBtn.bind(this);
  disableSaveBtn(entity) {
    const attributes = this.props.entityDef.attributes;
    const origEntity = this.state.entity.toJS();

    let requiredFields = [];
    let requiredEntityFieldsObject = {};
    let emptyValue;
    let hasOutputFlow;
    let hasSignal;
    let hasMembership;
    let numFiltersWithDetails = 0;
    let numFilters = 0;
    let validGroupFilter;
    let validVinFilter;
    let noChanges = true;

    if (entity.outputCreate && !this.areFiltersEqual(entity.outputCreate[0].filters, origEntity.filters)) {
      noChanges = false;
    }
    if (!isEqual(origEntity.displayName, entity.displayName)) {
      noChanges = false;
    }
    if (!isEqual(origEntity.inputFlowIdEdit, entity.inputFlowIdEdit)) {
      noChanges = false;
    }
    if (!isEqual(origEntity.region, entity.region)) {
      noChanges = false;
    }
    if (!isEqual(origEntity.businessUnit, entity.businessUnit)) {
      noChanges = false;
    }
    if (!isEqual(origEntity.outputFlow, entity.outputCreate?.[0].displayName)) {
      noChanges = false;
    }

    if (!isEqual(origEntity.state, entity.state)) {
      noChanges = false;
    }

    Object.entries(attributes).forEach(attribute => {
      if (attribute[1].rules && attribute[1].rules.includes('required') && attribute[1].display?.edit !== false) {
        requiredFields.push(attribute[0])
        requiredEntityFieldsObject[attribute[0]] = entity[attribute[0]];
      }
    });

    Object.values(requiredEntityFieldsObject).forEach(field => {
      if (typeof field === 'object') {
        emptyValue = field.length === 0
      }
    });

    if (requiredEntityFieldsObject.outputCreate !== undefined) {
      if ((Object.values(requiredEntityFieldsObject.outputCreate)[0].filters)) {
        hasSignal = hasSignalFilter(Object.values(requiredEntityFieldsObject.outputCreate)[0].filters);
        hasMembership = hasMembershipFilter(Object.values(requiredEntityFieldsObject.outputCreate)[0].filters);
        numFilters = Object.values(requiredEntityFieldsObject?.outputCreate)[0].filters.length;
        Object.values(requiredEntityFieldsObject.outputCreate)[0].filters.forEach(filter => {
          if (filter.groupFilter) {
            validGroupFilter = UUID_RE.test(filter.groupFilter.groupId);
          }
          if (filter.vinFilter) {
            validVinFilter = filter.vinFilter.vins?.every(isValidVin)
          }
          if (filter.deviceFilter || filter.groupFilter || filter.iamFilter || filter.attributeFilter) {
            if (!Object.values(Object.values(filter)[2]).includes('')) {
              numFiltersWithDetails ++;
            }
          } else if (filter.vinFilter) {
            const checkIfDuplicateExists = (arr=[]) => {
              return new Set(arr).size !== arr.length;
            }
            validVinFilter = !checkIfDuplicateExists(filter.vinFilter.vins) && filter.vinFilter.vins?.every(isValidVin)
            if (filter.vinFilter.vins?.length > 0) {
              numFiltersWithDetails++;
            }
          } else if (filter.membershipTagFilter || filter.attributeTagFilter) {
            if (!Object.values(Object.values(filter)[2]).includes(undefined)) {
              numFiltersWithDetails ++;
            }
          } else if (filter.signalPassThroughFilter || filter.memberPassThroughFilter) {
            numFiltersWithDetails ++;
          }
        })
      }
      hasOutputFlow = Object.values(requiredEntityFieldsObject.outputCreate)[0].aui !== '';
    }

    return Object.values(requiredEntityFieldsObject).includes(undefined) ||
      Object.values(requiredEntityFieldsObject).includes('') ||
      !hasSignal ||
      !hasMembership ||
      !hasOutputFlow ||
      emptyValue === undefined ||
      numFilters !== numFiltersWithDetails ||
      validGroupFilter === false ||
      validVinFilter === false ||
      noChanges
  }

  handleOnSave = this.handleOnSave.bind(this);
  handleOnSave(modifiedEntity) {

    this.setState({
      showStatusDialog: true,
      modifiedEntity
    });

    return Promise.resolve();
  }
}