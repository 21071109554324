import { history as browserHistory } from '../../../history';
import CommandManagementCreate from "./CommandManagementCreate";
export default class CommandManagementReplicate extends CommandManagementCreate {
    // Entity framework for Replicate in this case requires a create custom component to support create endpoint call.
    getActionTextId() {
      return 'au.entity.commandManagement.duplicate';
    }

    getTitle() {
      const { entity } = this.props;
      let title = this.title;
      return title += ' : ' + entity.getIn(['type']);
    }

    handleOnCancel = this.handleOnCancel.bind(this);
    handleOnCancel(/*ev*/) {
      sessionStorage.removeItem('entityToReplicate');
      const { prevUrl } = this.props.location?.state ?? {};      
      browserHistory.push(prevUrl ?? this.parentUrl);
    }
  
}

