import services from "./";
import common from "./common";

export const yamlReplacementKey = "yamlRef";

function replaceYamlReduce(input, path) {
  return Object.keys(input).reduce((acc, key) => {
    return {...acc, [key]: replaceYaml(input[key], path + key + ".")};
  }, {});
}

export function replaceYaml(input, path = ""){
  if(input == null || typeof input !== 'object'){
    return input;
  }

  if(Array.isArray(input)){
    return input.map((item, index) => replaceYaml(item, path + "[" + index + "]."));
  }

  if(yamlReplacementKey in input){
    const referenceKey = input[yamlReplacementKey];
    if (!(referenceKey in common)) {
      //replace "someArray.[123] with someArray[123] to match JSON paths
      const newPath = path.replace(/\.\[(\d+)]/g, "[$1]");
      throw new Error(`"${referenceKey}" from "${newPath + yamlReplacementKey}" could not be found in common config`);
    }
    const newInput = { ...replaceYaml(common[referenceKey]), ...input};
    delete newInput[yamlReplacementKey];
    return replaceYamlReduce(newInput, path);
  }

  return replaceYamlReduce(input, path);
}

export default replaceYaml(services);
