import React from 'react';
import { connect } from 'react-redux';
import { upperFirst } from 'lodash';
import { Switch, Route } from 'react-router-dom';

import GenericError from '@au/core/lib/components/elements/GenericError';

import { history } from '../history';
import serviceDefs from '../services/serviceDefs';
import PropProvider from '../PropProvider';
import apiFactory from '../utils/api';
import * as customComponents from '../components/entity/custom';
import { EntityList, EntityView, EntityEdit } from '../components/entity';
import { defaultMapDispatchToProps } from '../utils/ConnectDefaults';
import { shouldHideContent } from '../utils/entity';
import styles from '../css/components/entity.module.scss';

function canUpdate(endpoint, entityDef) {
  return endpoint.actions.includes('patch') ||
    endpoint.actions.includes('replace') ||
    entityDef.customUpdateMethods;
}

// Ideally, this should live under components/, but it seems quite hard to
// decouple it from the container, because of the cross dependency.
const Entity = props => {
  const { entityAlias, action } = props.match.params;
  const parentEntityAlias = entityAlias;
  const entityId = props.match.params.entityId && decodeURIComponent(props.match.params.entityId);
  const { entity, parentEntity } = props;
  const serviceAlias = props.serviceAlias || props.match.params.serviceAlias;
  const serviceDef = serviceAlias in serviceDefs ? serviceDefs[serviceAlias] : {};

  let Component;
  let entityDef;
  let endpoint;
  let breadcrumbs;
  let canAccess = false;
  let canCreate = false;
  let canDelete = false;
  let canEdit   = false;
  let show404   = true;
  let defaultPage = entityDef?.subviews && Object.values(entityDef.subviews)[0]?.action || 'view';

  if (entityAlias) {
    if (parentEntity) {
      breadcrumbs = [...parentEntity.breadcrumbs];
      entityDef = parentEntity.entityDef.subviews[entityAlias];
        // Some child entities don't belong to the parent at all so set up the correct endpoint
      if (entityDef.parentEndpoint) {
        const { parentEndpoint } = entityDef;
        // This case handles child endpoints of different parentEntities
        if (entityDef.childEndpoint) {
          const parentService = apiFactory(
            parentEndpoint.serviceAlias,
            parentEndpoint.entityAlias,
            props.actions,
            serviceDefs[parentEndpoint.serviceAlias].version
          );
          const entityName = 'name' in entityDef ? entityDef.name : entityAlias;
          const parentEntityId = parentEntity.entityId;
          endpoint = parentService[`get${upperFirst(entityName)}Endpoint`](parentEntityId);
        } else {
          // Otherwise just use the specified parent API
          endpoint = apiFactory(
            parentEndpoint.serviceAlias,
            parentEndpoint.entityAlias,
            props.actions,
            serviceDefs[parentEndpoint.serviceAlias].version
          );

        }
      } else {
        const entityName = 'name' in entityDef ? entityDef.name : entityAlias;
        const parentEntityId = parentEntity.entityId;
        endpoint = parentEntity.endpoint[`get${upperFirst(entityName)}Endpoint`](parentEntityId);
      }
    }
    else {
      breadcrumbs = [];
      entityDef = serviceDef.entities[entityAlias];
      // Entities with different API versions are set on entityDef.apiVersion
      endpoint  = apiFactory(serviceAlias, entityAlias, props.actions, (entityDef.apiVersion || serviceDef.version));
    }

    // use endpoint's id property as a primary key by default
    entityDef.pkField = entityDef.pkField || (Array.isArray(endpoint.idProp) ? endpoint.idProp[0] : endpoint.idProp);

    // current url
    let url = props.match.url.split('/');
    // drop all the entries after entityAlias
    url = url.slice(0, url.indexOf(entityAlias) + 1).join('/');

    // Popouts won't have a parent crumb
    if (!props.popoutContained) {
      breadcrumbs.push({
        key: `crumb_${entityDef.type}`,
        displayId: `au.entity.title.${entityDef.type}`,
        destination: `${url}/${entityDef.landingPage || 'list'}`,
        tag: entityDef.showCustomTag || ((entityDef.showVersionTag || serviceDef.showVersionTag) &&
             (`${entityDef.apiVersion || serviceDef.version}`).split('-')[1])
      });
    }

    if (entityId) {
      breadcrumbs.push({
        key: `crumb_${entityDef.type}_${entityId}_view`,
        displayString: entityDef.crumbProp ? entity.getIn(entityDef.crumbProp.split('.')) || entityId : entityId,
        destination: `${url}/${entityId}/${defaultPage}`
      });
    }

    let CustomComponent;
    if (action) {
      /*
       * The standard Entity operations can over-ridden through either naming
       * conventions or the specification of a custom component in the service yamls
       * that is used as the root for the override component name.
       *
       * The "standard" entity over ride convention is to concatenate the entity type
       * with the action. For example: GroupEdit
       *
       * The custom component override replaces the entity type with a specified
       * componentRootName which is then combined with the action.
       * For example: BoundSubEntityList
       */
      const actionString = action.split('-').map(w => upperFirst(w)).join('');

      //This is not necessary, but lays out better the logic and conventions.
      const customOverrideComponentName = entityDef.componentRootName && entityDef.componentRootName + actionString;
      const entityOverrideComponentName = upperFirst(entityDef.type) + actionString;

      const componentName = customOverrideComponentName || entityOverrideComponentName;
      CustomComponent   = entityDef.allowOverride && customComponents[componentName];
    }

    switch (action) {
      case 'list':
        Component = CustomComponent || EntityList;
        canAccess = endpoint.actions.includes('list') || endpoint.actions.includes('inspect');
        break;
      case 'view':
        Component = CustomComponent || EntityView;
        // ideally all the endpoints should support .get() action. if not, we'll
        // try to (re)load data using .list()
        canAccess = true;
        break;
      case 'create':
      case 'replicate':
        Component = CustomComponent || EntityEdit;
        canAccess = entityDef.readonly !== true && endpoint.actions.includes('create');
        break;
      case 'edit':
        Component = CustomComponent || EntityEdit;
        canAccess = entityDef.readonly !== true && canUpdate(endpoint, entityDef);
        break;
      default:
        // custom actions/components
        if (action && CustomComponent) {
          Component = CustomComponent;
          canAccess = true;
        }
        break;
    }

    if (entityDef.customActions && canAccess === false) {
      canAccess = entityDef.customActions.includes(action);
    }

    canCreate = endpoint.actions.includes('create') && !entityDef?.readonly;
    canDelete = endpoint.actions.includes('delete') && !entityDef?.readonly;
    canEdit   = canUpdate(endpoint, entityDef) && !entityDef?.readonly;

    if (entityDef.customActions && canCreate === false) {
      canCreate = entityDef.customActions.includes('create');
    }
  } else if (action || entityId) {
    show404 = false;
  }

  const routeRenderer = ownProps => {
    const customProps = { ...ownProps, serviceAlias };
    const { entityAlias } = ownProps.match.params;

    if (endpoint) {
      customProps.parentEntity = {
        parentEntity,
        serviceAlias,
        serviceDef,
        entityAlias: parentEntityAlias,
        entityDef,
        entityId,
        breadcrumbs,
        endpoint
      };
    }

    if (entityAlias) {
      if (entityDef) {
        // child entity definition
        customProps.entityDef = entityDef.subviews[entityAlias];
      } else {
        // top level entity definition
        customProps.entityDef = serviceDef.entities[entityAlias];
      }
    }

    return <EntityWrapper {...customProps} />;
  };

  let knownEntities = [];
  /*
    we noticed that we were not restricting react router paths by the known entities.
    Let's say you have a bookmark to a page that is hidden now (display: False)
    or you're trying to access a page not displayed in the Manage dropdown.
    React router will parse the url and we'll try to render the page, but it will crash.
  */
  if (entityDef?.subviews) {
    knownEntities = Object.keys(entityDef.subviews).filter((entityAlias) => !shouldHideContent(entityDef.subviews[entityAlias]));
  } else if (serviceDef.entities) {
    knownEntities = Object.keys(serviceDef.entities).filter((entityAlias) => !shouldHideContent(serviceDef.entities[entityAlias]));
  }
  let knownAliases = knownEntities.length ? '(' + knownEntities.join('|') + ')' : '';

  return (
    <div className={styles.container}>
      { Component && canAccess &&
        <Component
          {...props}
          key={`${serviceAlias}_${entityDef.type}_${action}`}
          entityDef={entityDef}
          endpoint={endpoint}
          breadcrumbs={breadcrumbs}
          permissions={{ canCreate, canDelete, canEdit }}
        />
      }
      { Component && !canAccess &&
        <GenericError code={404} history={history} messageDisplayId={'au.errors.404.message'} />
      }
      {/*
        Popouts are rendered outside the router currently.
        Returning them here causes an error as these will not be wrapped in a router
      */}
      {!props.popoutContained &&
        <Switch>
          <Route exact path={props.match.url + `/:entityAlias${knownAliases}/:action`} render={routeRenderer} />
          <Route exact path={props.match.url + `/:entityAlias${knownAliases}/:entityId/:action`} render={routeRenderer} />
          <Route path={props.match.url + `/:entityAlias${knownAliases}/:entityId`} render={routeRenderer} />
          { !Component && show404 && <Route render={() => <GenericError code={404} history={history} messageDisplayId={'au.errors.404.message'} />} />}
        </Switch>
      }
    </div>
  );
};

const EntityWrapper = connect(
  (state, ownProps) => PropProvider(state).Entity(ownProps),
  defaultMapDispatchToProps
)(Entity);

export default Entity;
