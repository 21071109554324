/**
 * Translates passed filters into a correspondinq query params.
 * @param  {Object} filters    Applied filters
 * @return {Object}            Query params
 */
export function filtersToQueryParams(filters = {}) {
  return Object.values(filters).flat().reduce((queryParams, filter) => {
    const {queryKey, queryValue, shouldUseToQuery} = filter;
    const filterParam = queryParams[queryKey];

    if(!shouldUseToQuery){
      return queryParams;
    }

    if (Array.isArray(filterParam)) {
      return {...queryParams, [queryKey]: [...filterParam, queryValue]};
    } else if (filterParam) {
      return {...queryParams, [queryKey]: [filterParam, queryValue]};
    } else {
      return {...queryParams, [queryKey]: queryValue};
    }
  }, {});
}

export function convertToFilters(selectedItems, filterName) {
  if (Array.isArray(selectedItems)) {
    return selectedItems.map(item => {
      return {
        urlKey: item.urlKey ?? filterName,
        urlValue: item.urlValue,
        queryKey: item.queryKey ?? item.urlKey ?? filterName,
        queryValue: item.queryValue ?? item.urlValue,
        bubbleDisplayId: item.bubbleDisplayId,
        bubbleText: item.bubbleText ?? item.urlValue,
        shouldUseToQuery: item.shouldUseToQuery ?? true,
        shouldUseInUrl: filterName == 'username' || filterName.includes('Aui') ? false : item.shouldUseInUrl ?? true,
        shouldDisplayBubble: item.shouldDisplayBubble ?? true
      };
    });
  } else {
    return [{
      urlKey: selectedItems.urlKey ?? filterName,
      urlValue: selectedItems.urlValue,
      queryKey: selectedItems.queryKey ?? selectedItems.urlKey ?? filterName,
      queryValue: selectedItems.queryValue ?? selectedItems.urlValue,
      bubbleDisplayId: selectedItems.bubbleDisplayId,
      bubbleText: selectedItems.bubbleText ?? selectedItems.urlValue,
      shouldUseToQuery: selectedItems.shouldUseToQuery ?? true,
      shouldUseInUrl: filterName == 'username' || filterName.includes('Aui') ? false : selectedItems.shouldUseInUrl ?? true,
      shouldDisplayBubble: selectedItems.shouldDisplayBubble ?? true
    }];
  }
}

export const objectIsSubset = (o1, o2) => {
  return Object.keys(o1).every(property => o1[property] === o2[property]);
};

export const arraysEqual = (a1, a2) => {
  return (
    a1.length === a2.length &&
    a1.reduce((acc, current, index) => acc && objectIsSubset(current, a2[index]), true)
  );
};
