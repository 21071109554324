import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import cloneDeep from 'lodash.clonedeep';

import { ENTER_KEY } from '@au/core/lib/constants';
import AuInput from '@au/core/lib/components/elements/AuInput';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuButton, { BUTTON_TYPE_PLAIN, BUTTON_TYPE_SECONDARY } from '@au/core/lib/components/elements/AuButton';

import { NOOP } from '../constants';
import { validUsernameOrUuid } from '../utils/validationRules';
import { run } from '../utils/validationRuleRunner';
import { formatMessage } from '../utils/reactIntl';
import { findUserByUsername, findClientByName } from '../utils/accounts';
import apiFactory from '../utils/api';
import tableDefs from '../tableDefs';
import { parseUuid } from '../utils/parse';
import SimpleTable, { Row, Cell, HeaderCell } from './SimpleTable';

import styles from '../css/components/user_group_members_editor.module.scss';

const validationRules = [validUsernameOrUuid];
const CONFIDENTIAL_CLIENT = 'CONFIDENTIAL_WITH_SERVICE_ACCOUNT';

export default class UserGroupMembersEditor extends React.Component {

  static propTypes = {
    createMode: T.bool,
    field: T.oneOf(['users', 'clients']),
    values: T.array,
    onChange: T.func
  }

  static defaultProps = {
    values: []
  }

  endpoint = apiFactory('accounts-service', this.props.field, this.props.actions);

  state = {
    values: this.props.values,
    inputValue: '',
    processing: false,
    showError: false,
    error: {}
  }

  get columnDefs() {
    return cloneDeep(
      this.props.field === 'users'
        ? tableDefs.userGroupUserMemberCreate.columnDefs
        : tableDefs.userGroupClientMemberCreate.columnDefs
    );
  }

  handleInputChange = this.handleInputChange.bind(this);
  handleInputChange(e) {
    const { value } = e.target;
    this.setState({ inputValue: value });
  }

  handleClearInput = this.handleClearInput.bind(this);
  handleClearInput() {
    return this.setState({
      inputValue: '',
      showError: false,
      processing: false
    });
  }

  async validateInputValue(inputValue) {
    const { field } = this.props;
    const { values } = this.state;
    
    // standard validation checks
    const validationError = run(inputValue, validationRules);
    if (validationError.errDisplayId) {
      return validationError;
    }

    let isDuplicate;
    let getMember;

    const isUuid = Boolean(parseUuid(inputValue));
    if (isUuid) {
      // uuid
      isDuplicate = member => member.id === inputValue;
      getMember = id => this.endpoint.get(id).then(res => res.data);
    }
    else {
      const fieldName = field === 'clients' ? 'name' : 'username';
      isDuplicate = member => member[fieldName] === inputValue;
      
      if (field === 'clients') {
        getMember = name => findClientByName(name).then(res => res.items[0], NOOP);
      }
      else {
        getMember = username => findUserByUsername(username).then(res => res.items[0], NOOP);
      }
    }

    if (values.some(isDuplicate)) {
      // duplicate entry
      return {
        errDisplayId: 'au.userGroup.members.exists',
        values: { value: field === 'clients' ? 'client' : 'user' }
      };
    }

    const member = await getMember(inputValue);
    if (!member) {
      // not found
      return {
        errDisplayId: 'au.userGroup.members.notFound',
        values: { value: field === 'clients' ? 'client' : 'user' }
      };
    } else if (field === 'clients' && member.type !== CONFIDENTIAL_CLIENT) {
      // not valid client
      return {
        errDisplayId: 'au.userGroup.members.notValid'
      };
    }

    this.setState({ newMember: member });

    // all clear
    return null;
  }

  handleAddClick = this.handleAddClick.bind(this);
  async handleAddClick() {
    const inputValue = this.state.inputValue.trim();

    if (inputValue === '') {
      return;
    }

    this.setState({ processing: true });

    const validationError = await this.validateInputValue(inputValue);
    
    if (validationError) {
      this.setState({ showError: true, error: validationError, processing: false });
      return;
    }

    this.addNewMember();
  }

  addNewMember() {
    this.setState(prevState => ({
      values: [...prevState.values, prevState.newMember],
      inputValue: '',
      processing: false,
      error: {}
    }), () => this.props.onChange(this.state.values));
  }

  onKeyPressed = this.onKeyPressed.bind(this);
  onKeyPressed(e) {
    if (e.key === ENTER_KEY) {
      this.handleAddClick();
    }
  }

  handleDeleteRow = this.handleDeleteRow.bind(this);
  handleDeleteRow(rowIndex) {
    if (this.props.createMode) {
      this.setState(prevState => {
        const values = [...prevState.values];
        values.splice(rowIndex, 1);
        return { values };
      });
    } else {
      this.setState(prevState => {
        const values = [...prevState.values];
        values[rowIndex].toBeDeleted = true;
        return { values };
      });
    }
  }

  handleRecoverRow(rowIndex) {
    this.setState(prevState => {
      const values = [...prevState.values];
      values[rowIndex].toBeDeleted = false;
      return { values };
    });
  }

  renderUserInputField() {
    const { field } = this.props;
    const { inputValue, processing, error, showError } = this.state;
    
    return (
      <div className={styles.container}>
        <div className={styles.form} onKeyDown={this.onKeyPressed}>
          <AuInput
            name={this.props.field}
            className={styles.user_input}
            onChange={this.handleInputChange}
            onClear={this.handleClearInput}
            value={inputValue}
            placeholderId={field === 'clients' ? "au.userGroup.idOrName" : "au.userGroup.idOrUsername"}
            error={{ ...error, fieldDisplayId: 'au.userGroup.members.' + field }}
            showError={showError}
          />
          <AuButton
            type={BUTTON_TYPE_SECONDARY}
            className={styles.add_btn}
            displayId="au.userGroup.members.add"
            onClick={this.handleAddClick}
            disabled={processing}
          />
        </div>
      </div>
    );
  }

  renderHeader() {
    const { field } = this.props;
    const cells = this.columnDefs.map(col =>
      <HeaderCell key={`${field}_${col.property}_header`} width={col.width} className={cn(styles.header_cell, styles[col.property])}>
        { formatMessage({ id: col.labelId }) }
      </HeaderCell>
    );

    return <Row className={styles.header_row}>{ cells }</Row>;
  }

  renderBody() {
    const { field } = this.props;
    const { values, newMember } = this.state;

    return values.map((item, index) => (
      <tr key={`${field}_${item.id}_row`} className={cn(styles.row, { [styles.new]: newMember?.id === item.id, [styles.remove]: item.toBeDeleted })}>
        <Cell className={styles.cell}>{ item.id }</Cell>
        <Cell className={styles.cell}>{ item.username || item.displayName }</Cell>
        <Cell className={styles.cell}>{ item.email }</Cell>
        <Cell className={styles.actions}>
          <div className={styles.remove_message}>
            <AutoIntl 
              className={styles.remove_label} 
              displayId="au.userGroup.members.markedForRemoval"
            />
            <AuButton
              type={BUTTON_TYPE_PLAIN}
              displayId="au.userGroup.members.undo"
              className={styles.restore_btn}
              onClick={() => this.handleRecoverRow(index)}
            />
          </div>
          <button
            type="button"
            title={formatMessage({ id: 'au.userGroup.members.deleteMember' })}
            className={styles.delete_btn}
            onClick={() => this.handleDeleteRow(index)}
          />
        </Cell>
      </tr>
    ));
  }

  render() {
    return (
      <div className={styles.container}>
        {this.renderUserInputField()}
        <SimpleTable className={styles.table}>
          {this.renderHeader()}
          {this.renderBody()}
        </SimpleTable>
      </div>
    );
  }
}
