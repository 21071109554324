import {guaranteeUndefinedOrArray} from '../../utils/dataUtils';
import {generateEntityPath} from '../../../utils/entity';
import CommandList from "./CommandList";

export default class VehicleCommandList extends CommandList {

  list() {
    const { entityDef, pageSize, parentEntity } = this.props;
    const { queryParams } = entityDef;

    let data = { pageSize, ...queryParams, ...this.getFilterQueryParams(), vehicleIds: [parentEntity.entityId] };

    data["currentStatuses"] = guaranteeUndefinedOrArray(data["currentStatuses"]);
    data["types"] = guaranteeUndefinedOrArray(data["types"]);
    data["regions"] = guaranteeUndefinedOrArray(data["regions"]);

    return this.commandEndpoint.search(data);
  }

  onListSuccess = this.onListSuccess.bind(this);
  onListSuccess(resp) {
    const { entityDef, parentEntity, actions } = this.props;
    const path = [
      ...generateEntityPath(parentEntity.endpoint, parentEntity.serviceAlias),
      parentEntity.entityId,
      'commands'
    ];

    actions.listEntitiesSuccess({
      path,
      data: resp.items,
      pkField: entityDef.pkField,
      replace: resp.isFirstPage
    });
  }
}
