import Immutable from 'immutable';

import entityReducer from './entity';
import generalReducer from './general';
import initReducer from './init';
import mapReducer from './map';
import popoutReducer from './popout';
import summaryReducer from './summary';
import settingReducer from './setting';
import accountReducer from './account';

import AuSpec from '../utils/AuSpec';
import AuSpecs from '../AuSpecs';

const handlers = Object.assign({},
  entityReducer,
  generalReducer,
  initReducer,
  mapReducer,
  popoutReducer,
  summaryReducer,
  settingReducer,
  accountReducer
);

export default function(state=Immutable.Map(), action) {
  var handler = handlers[action.type];
  var nextState;
  if (handler) {
    nextState = handler(state, action);
  } else {
    nextState = state;
  }
  if (nextState == state) {
    return state;
  }
  AuSpec.assert(() => ({
    spec: AuSpecs(AuSpec.builder({strict: true})).storeState,
    data: nextState.toJS()
  }));
  return nextState;
}
