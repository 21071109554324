import React from 'react';
import * as T from 'prop-types';
import { formatMessage } from '../utils/reactIntl';

import MultiDropDown from "@au/core/lib/components/elements/MultiDropDown";
import { NOOP, tapFilterType, signalFilterTypes, membershipFilterTypes } from '../constants';

import styles from '../css/components/tap_filter_type_selector.module.scss';

const filterTypeOptions = Object.values(tapFilterType).filter(type => type !== tapFilterType.TAG).map(type => ({
  displayId: `au.taps.filters.${type}`,
  displayString: type,
  val: type
}));

export default class TapFilterTypeSelector extends React.Component {

  static propTypes = {
    selection: T.string,
    onChange: T.func,
    bufferTop: T.number,
    bufferBottom: T.number,
    filterTypeOptions: T.array
  }

  static defaultProps = {
    onChange: NOOP,
    bufferTop: 142,
    bufferBottom: 54,
  }

  selectOption(value) {
    const { onChange } = this.props;
    onChange(value);
  }

  render() {
    const { selection, createMode } = this.props;

    const filterOptions = this.props.filterTypeOptions || filterTypeOptions;
    const signalOptions = filterOptions.filter(option => signalFilterTypes.includes(option.val));
    const memberOptions = filterOptions.filter(option => membershipFilterTypes.includes(option.val));

    const tapFilterOptions = [{displayId: 'au.taps.filters.memberFilters', entityName: 'memberOptions', options: memberOptions}, {displayId: 'au.taps.filters.signalFilters', entityName: 'signalOptions', options: signalOptions}];
   
    return (
      <>
        <MultiDropDown
          className={styles.input}
          selection={selection ?? formatMessage({ id: `au.taps.filters.${selection}` })}
          options={tapFilterOptions}
          selectOption={(value) => this.selectOption(value)}
          onChange={(value) => this.selectOption(value)}
          allowTyping={false}
          placeholderId={'au.entity.attr.choosePlaceholder'}
          showClear={false}
          optionsClassName={styles.options}
          createMode={!createMode}
        />
      </>
    )
  }

}
