import * as force from 'd3-force';

import { getLoggers } from '../logger';
import { getNumPicks, genPicks, isKin } from './common';
import Tree from './tree';
import MultiTree from './multiTree';

// Tweaking points:
//  - collide.strength (defaults to 0.7)
//  - link.strength - some thought can go into how to use this
//  - alpha decay - https://www.npmjs.com/package/d3-force#simulation_alphaDecay
//  - could detect when things are relatively stable and exit iterations early...
class ForceLayout {
  constructor(nodes, links) {
    this.info = getLoggers('ForceLayout').info;
    this.done = new Promise(res => this.resolveDone = res); // TODO: maybe something other than `.done`?
    nodes = nodes.map(n => ({ ...n })); // simulation will modifiy the node

    this.info('starting simulation...');
    this.simulation = force.forceSimulation(nodes)
      .force('links', force.forceLink().links(links).id(d => d.id))
      .force('charge', force.forceManyBody().strength(-300))
      .force('x', force.forceX(100))
      .force('collide', force.forceCollide().radius(n => n.radius))
      .on('end', () => this._onEnd());
    this.nodes = nodes;
    this.links = links;
  }

  _onEnd() {
    this.info('...done simulating');
    const nodes = [...this.nodes]
      .sort((a, b) => a.y - b.y);
    this.resolveDone(nodes);
  }

  // for debugging -- helps visualize the arrangement
  async _render(container) {
    await this.done;

    const circles = container.selectAll('circle')
      .data(this.nodes);

    circles.enter()
      .append('circle')
      .attr('data-id', d => d.id)
      .attr('r', d => d.radius)
      .attr('fill', 'orange')
      .attr('cx', d => d.x)
      .attr('cy', d => d.y)
      .exit();

    const links = container.selectAll('line')
      .data(this.links);

    links.enter()
      .append('line')
      .attr('x1', d => d.source.x)
      .attr('y1', d => d.source.y)
      .attr('x2', d => d.target.x)
      .attr('y2', d => d.target.y)
      .attr('stroke-width', 2)
      .style('stroke', '#ccc');
  }
}


export default class LayoutOptimizer {
  constructor(mTree, maxIterations) {
    this.info = getLoggers('LayoutOptimizer').info;
    this.warn = getLoggers('LayoutOptimizer').warn;
    this.mTree = mTree;
    this.maxIters = maxIterations;
    this.iter = 0;
    this.possibleIters = 0; // constantly updated total possbile number of iterations that could happen
  }


  _buildExcludedTrees(trees, assignedCrossNodes) {
    const excludeFromTrees = new Map(); // tree -> Set(excluded node auis)
    for (let [cNode, assignedTree] of assignedCrossNodes) {
      // Exclude this node from all trees it's a member of but not assigned
      const excludedTrees = new Set();
      for (let tree of trees.values()) {
        if (tree === assignedTree) continue;
        if (tree.nodes.has(cNode.aui)) {
          excludedTrees.add(tree);
        }
      }

      // Only exclude node from trees that it is a member of to avoid needless
      // tree rebuilding
      for (let tree of excludedTrees) {
        if (excludeFromTrees.has(tree)) {
          excludeFromTrees.get(tree).add(cNode.aui);
        }
        else {
          excludeFromTrees.set(tree, new Set([cNode.aui]));
        }
      }
    }

    // TODO: could memoize rebuilt trees

    const rebuiltTrees = new Map(trees);
    for (let [tree, excludedAuis] of excludeFromTrees) {
      const rebuiltTree = new Tree(
        tree.flows,
        tree.taps,
        tree.processors,
        tree.forks,
        tree.root,
        tree.direction,
        excludedAuis
      );
      rebuiltTrees.set(rebuiltTree.id, rebuiltTree);
    }
    return rebuiltTrees;
  }


  /**
   * Assigns cross nodes from this.mTree to a tree so that their parents are at
   * an equal or lower global depth.
   * @return {Generator} Map of assigned trees
   */
  *_genCrossTreeAssignments() {
    // Pre-assign if a parent bundle has the global max depth and is the max
    // amoung other bundles. Edges can only point foward.
    const preAssigned = new Map();
    const unassigned = new Map([...this.mTree.crossNodes.values()].map(n => [n, new Set(n.crossTrees)]));
    for (let cNode of this.mTree.crossNodes.values()) {
      // Determine if the cross node has an uncontested bundle at it's global
      // max depth
      const maxBundleDepths = new Map();
      for (let parentTrees of cNode.crossTrees) {
        const bundleMax = Math.max(...[...parentTrees]
            .map(t => t.nodes.get(cNode.aui).maxDepth)
          );
        maxBundleDepths.set(parentTrees, bundleMax);
      }

      // One bundle must be at the global max depth
      const globalMaxDepth = Math.max(...maxBundleDepths.values());
      const bundlesAtMaxDepth = [...maxBundleDepths]
        .filter(bd => bd[1] === globalMaxDepth);
      if (bundlesAtMaxDepth.length === 1) {
        preAssigned.set(cNode, bundlesAtMaxDepth[0][0]);
        unassigned.delete(cNode);
        continue;
      }

      // Remove trees that cannot place the cross node at it's global max depth
      for (let [parentBundle, bundleMax] of maxBundleDepths) {
        if (bundleMax < globalMaxDepth) {
          unassigned.get(cNode).delete(parentBundle);
        }
      }
    }

    // Looping ALL unassigned permutations
    const unassignedNodes = [...unassigned.keys()];
    const treesSlots = [...unassigned.values()];
    this.possibleIters += getNumPicks(treesSlots);

    for (let pick of genPicks(treesSlots)) {
      const assignedCrossNodes = new Map(
        unassignedNodes.map((n, i) => [n, pick[i]])
      );

      // Merged pre-assigned
      for (let [cNode, trees] of preAssigned) {
        assignedCrossNodes.set(cNode, trees);
      }

      // Sort nodes waiting to be assigned so upstream is assigned to a single
      // tree first by depth and downstream can be assigned based on them
      const unassignedByDepth = [...assignedCrossNodes]
        .sort(([cNodeA], [cNodeB]) => cNodeA.crossDepth - cNodeB.crossDepth);

      // Resolve abiguous assignments by checking if fully assigned upstream
      // nodes can solidify assignment
      for (let [cNode, trees] of unassignedByDepth) {
        if (trees.size === 1) continue;
        // Get the first upstream cross node at this tree intersection with a
        // defined tree
        const upstreamCrossNode = [...this.mTree.treesCrossNodes.get(trees)]
          .filter(n => n !== cNode)
          .filter(n => n.crossDepth < cNode.crossDepth)
          .filter(n => assignedCrossNodes.get(n).size === 1)
          .filter(n => isKin(cNode, n, 'input')) // TODO: create data structure to speed this up
          .sort((a, b) => a.crossDepth - b.crossDepth)
          .pop();

        // Update the abiguous assignment
        assignedCrossNodes.set(cNode, assignedCrossNodes.get(upstreamCrossNode));
      }

      // All trees are assigned, removed set shell
      for (let [cNode, trees] of assignedCrossNodes) {
        assignedCrossNodes.set(cNode, [...trees][0]);
      }

      // The yielded has all cross nodes assigned to a specific tree
      yield this._buildExcludedTrees(this.mTree.crossTrees, assignedCrossNodes);
    }
  }


  _addPull(pulls, nodeAui, pull) {
    if (pulls.has(nodeAui)) {
      const currentPull = pulls.get(nodeAui);
      if (currentPull.power === pull.power && currentPull.direction !== pull.direction) {
        this.warn(`[iteration ${this.iter}] ${nodeAui} is being pulled in opposite directions`); // TODO: this could be taken into consideration when scoring
      }
    }
    pulls.set(nodeAui, pull);
  }

  async _arrangeTrees(trees) {
    const assignedMTree = new MultiTree(trees);

    const treeEdges = new Map([...trees.values()].map(t => [t, new Set()]));

    if (this.mTree.crossNodes.size > assignedMTree.nodes.size) {
      for (let cNodeAui of this.mTree.crossNodes) {
        if (!assignedMTree.nodes.get(cNodeAui[0])) {
          this.mTree.crossNodes.delete(cNodeAui[0]);
        }
      }
    }

    for (let [cNodeAui, mcNode] of this.mTree.crossNodes) {
      const cNodeTree = assignedMTree.nodes.get(cNodeAui).onlyTree;
      for (let parentAui of mcNode.input.keys()) {
        const parentTree = assignedMTree.nodes.get(parentAui).onlyTree;
        if (parentTree.id === cNodeTree.id) continue;
        treeEdges.get(parentTree).add(cNodeTree);
      }
    }

    // Convert to force layout nodes
    const nodes = [...trees.values()]
      .map(t => ({ id: t.id, radius: t.expandedHeight }));
    const links = [];
    for (let [treeA, treeBSet] of treeEdges) {
      for (let treeB of treeBSet) {
        links.push({ source: treeA.id, target: treeB.id });
      }
    }

    const forceLayout = new ForceLayout(nodes, links);
    const arrangedNodes = await forceLayout.done;

    // Sort multi-tree with new layout
    assignedMTree.sort(arrangedNodes.map(n => n.id));

    // Build pulls
    const treePulls = new Map([...trees.values()].map(t => [t.id, new Map()]));
    for (let [cNodeAui, mcNode] of this.mTree.crossNodes) {
      const cNodeTree = assignedMTree.nodes.get(cNodeAui).onlyTree;
      for (let parentAui of mcNode.input.keys()) {
        const parent = assignedMTree.nodes.get(parentAui);
        const parentTree = parent.onlyTree;
        if (parentTree === cNodeTree) continue;

        // Add mutual pulls
        const [cDir, pDir] = cNodeTree.order < parentTree.order ? ['down', 'up'] : ['up', 'down'];
        this._addPull(treePulls.get(cNodeTree.id), cNodeAui, { direction: cDir, power: 0 });
        this._addPull(treePulls.get(parentTree.id), parentAui,  { direction: pDir, power: 0 });
      }
    }

    // TODO: could change power for the pulls to minimize the total cross distance

    // Sort with new pulls
    for (let tree of trees.values()) {
      tree.sort(treePulls.get(tree.id));
    }

    return { assignedMTree, treeEdges };
  }

  // thought: need to be more efficient in keeping cross edges...
  _getEstimatedCrossEdgeDistance(assignedMTree) {
    let dist = 0;
    for (let [cNodeAui, mcNode] of this.mTree.crossNodes) {
      const cNode = assignedMTree.nodes.get(cNodeAui);
      for (let parentAui of mcNode.input.keys()) {
        const parent = assignedMTree.nodes.get(parentAui);
        if (cNode.onlyTree === parent.onlyTree) continue;
        dist += assignedMTree.getInterNodeDistance(cNodeAui, parentAui);
      }
    }
    return dist;
  }

  _compareIterations(iterA, iterB) {
    if (iterA && !iterB) return -1;
    if (iterB && !iterA) return 1;

    for (let iter of [iterA, iterB]) {
      if (!iter.crossEdgeDist) {
        iter.crossEdgeDist = this._getEstimatedCrossEdgeDistance(iter.assignedMTree);
      }
    }

    // TODO: use inter-tree edge distance since pull and arrangment can effect
    // that

    return iterA.crossEdgeDist < iterB.crossEdgeDist ? -1 : 1;
    // could compare average cross edge length
  }

  _finalizeIteration(start, layout) {
    const duration = (new Date() - start) / 1000;
    this.info(`[iteration ${this.iter}] final layout determined: iteration ${layout.iter} out of potentially ${this.possibleIters} iterations in ${duration} seconds`);
    return layout;
  }

  async run() {
    const start = new Date();
    ++this.iter;
    let bestLayout;
    for (let trees of this._genCrossTreeAssignments()) {
      const arrangement = await this._arrangeTrees(trees);
      const iterLayout = { iter: this.iter, mTree: this.mTree, ...arrangement };

      if (this._compareIterations(iterLayout, bestLayout) < 0) {
        bestLayout = iterLayout;
        this.info(`[iteration ${this.iter}] layout is the best`);
      }

      if (this.iter + 1 > this.maxIters) {
        this.info(`[iteration ${this.iter}] hit iteration limit ${this.maxIters}`);
        return this._finalizeIteration(start, bestLayout);
      }

      ++this.iter;
    }
    this.info(`[iteration ${this.iter}] exhaused all layouts`);
    return this._finalizeIteration(start, bestLayout);
  }
}
