import React from 'react';
import * as T from 'prop-types';
import AuInput from '@au/core/lib/components/elements/AuInput';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import styles from '../../../css/components/command_version_editor.module.scss';
export default class CommandVersionEditor extends React.Component {
  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    disabled: T.bool,
    min: T.number,
    max: T.number,
    errors: T.object
  }

  constructor(props) {
    super(props);

    this.state = {
      0: '1',   // major   (version)
      1: '0',   // minor   (version)
      2: '0'    // bug fix (version)
    };
  }

  componentDidMount() {
    const { field, labelId, disabled, registerValidation, validationRules } = this.props;
    if (registerValidation && validationRules && !disabled) {
      registerValidation(field, labelId, validationRules);
    }

    const { version } = this.props.entity;
    if (version) {
      version.split('.', 3).forEach((value, idx) => {
        this.setState({ [idx]: value });
      });
    }

    this.commitChanges();
  }

  handleInputChange(idx, value) {
    if (typeof value === 'string') {
      this.setState({ [idx]: value }, this.commitChanges);
    } else {
      this.setState({ [idx]: value.target.value }, this.commitChanges);
    }
  }

  commitChanges() {
    const customVersion = this.state[0] + '.' + this.state[1] + '.' + this.state[2];
    this.props.onChange(customVersion);
  }

  render() {
    const { min, max, errors, createMode } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.inputs}>
          <AuInput
            min={1}
            max={max}
            onChange={this.handleInputChange.bind(this, 0)}
            value={this.state[0]}
            className={styles.input}
            type='number'
            disabled={!createMode}
          />
          <AuInput
            min={min}
            max={max}
            onChange={this.handleInputChange.bind(this, 1)}
            value={this.state[1]}
            className={styles.input}
            type='number'
            disabled={!createMode}
          />
          <AuInput
            min={min}
            max={max}
            onChange={this.handleInputChange.bind(this, 2)}
            value={this.state[2]}
            className={styles.input}
            type='number'
            disabled={!createMode}
          />
        </div>
        {errors && <AutoIntl className={styles.error} displayId={errors.errDisplayId} />}
      </div>
    );
  }
}