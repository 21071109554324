import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import LoadingIndicator from '@au/core/lib/components/elements/LoadingIndicator';

import { formatMessage } from '../utils/reactIntl';
import formatters from '../utils/formatters';
import { get } from '../utils/api';
import { ftcpTelemetryStageFields } from '../constants';

import SimpleTable, { Row, Cell, HeaderCell } from './SimpleTable';
import EmptyValueHint from './EmptyValueHint';
import FtcpMessageState from './FtcpMessageState';

import styles from '../css/components/entity_view.module.scss';
import customStyles from '../css/components/last_ftcp_message.module.scss';

export const FTCP_TELEMETRY_DISPLAYABLE_STAGES = [
  'FTCP_INGEST', // Received
  'FTCP_DECRYPTOR_RAW_DECRYPTED', // Decrypted
  'FTCP_CONVERTER' // Converted To TMC
];

export default class LastFtcpMessage extends React.PureComponent {

  static propTypes = {
    vin: T.string.isRequired,
  }

  state = {
    ftcpMessageData: null,
    fetching: false,
    error: false
  }

  componentDidMount() {
    this.fetchLastFtcpMessage();
  }

  fetchLastFtcpMessage = this.fetchLastFtcpMessage.bind(this);
  fetchLastFtcpMessage() {
    const { vin } = this.props;

    if (vin) {
      this.setState({ fetching: true });
      // `type` can be "all", "connectivity", or "telemetry"
      return get(`/v1/audit/${vin}?type=all`).then(result => {
        this.setState({
          ftcpMessageData: result.data,
          fetching: false,
          error: false
        });
      }).catch(error => {
        // No data state
        if (error.status === 404) {
          this.setState({
            ftcpMessageData: null,
            fetching: false,
            error: false,
          });
        } else {
          this.setState({ error: true, fetching: false });
          AuAnalytics.trackException({
            description: error.toString(),
            fatal: false
          });
        }
      });
    }
  }

  hasDisplayableStages() {
    const { ftcpMessageData } = this.state;
    return ftcpMessageData && Object.keys(ftcpMessageData.stages).some(s =>
      FTCP_TELEMETRY_DISPLAYABLE_STAGES.includes(s)
    );
  }

  getMessageTime(date) {
    // Check if date is in correct UTC format and adjust
    return date.endsWith('Z') ? date : `${date}Z`;
  }

  renderError() {
    return (
      <tr className={cn(styles.row, customStyles.error_row)}>
        <td className={customStyles.error_message}>
          <AutoIntl
            displayId="au.vehicle.lastFtcpMessage.error"
            values={{
              a: txtValue => (
                <a onClick={this.fetchLastFtcpMessage}>
                  {txtValue}
                </a>
              )
            }}
          />
        </td>
      </tr>
    );
  }

  renderLoading() {
    return (
      <tr className={cn(styles.row, customStyles.loading_row)}>
        <td className={customStyles.loading}>
          <LoadingIndicator
            className={customStyles.spinner}
            displayId="au.vehicle.lastFtcpMessage.loading"
          />
        </td>
      </tr>
    );
  }

  renderLastFtcpMessage() {
    const { ftcpMessageData } = this.state;

    // Null data or missing stages we want to display
    if (!ftcpMessageData || !this.hasDisplayableStages()) {
      return (
        <tr className={styles.row}>
          <td className={styles.fieldname}>
            <AutoIntl displayId="au.vehicle.lastFtcpMessage" />
          </td>
          <td className={styles.fieldvalue}>
            <EmptyValueHint displayId="au.noDataAvailable" />
          </td>
        </tr>
      );
    }

    const rows = FTCP_TELEMETRY_DISPLAYABLE_STAGES.map(stage => {
      const messageData = ftcpMessageData.stages[stage];
      if (messageData) {
        return (
          <Row key={stage} className={styles.row}>
            <Cell>
              {/* Stage */}
              <AutoIntl displayId={`au.vehicle.lastFtcpMessage.${stage}`} />
            </Cell>
            <Cell>
              {/* Time */}
              {formatters.date({
                value: new Date(this.getMessageTime(messageData.lastMessageTime))
              })}
            </Cell>
            <Cell>
              {/* Result (success/failure) */}
              <FtcpMessageState result={messageData.resultDetails.result} />
            </Cell>
            <Cell>
              {/* List of details */}
              {Object.entries(messageData.resultDetails).filter(([key]) =>
                ftcpTelemetryStageFields[stage].includes(key)
              ).sort(([aKey], [bKey]) =>
                formatMessage({ id: `au.vehicle.lastFtcpMessage.details.${aKey}`})
                  .localeCompare(formatMessage({ id: `au.vehicle.lastFtcpMessage.details.${bKey}`}))
              ).map(([key, val]) => {
                return (
                  <AutoIntl
                    key={key}
                    className={customStyles.detail_row}
                    displayId={`au.vehicle.lastFtcpMessage.details.${key}`}
                    values={{ val }}
                    tag="div"
                  />
                );
              })}
            </Cell>
          </Row>
        );
      }
    });

    return (
      <tr className={styles.row}>
        <td className={styles.fieldname}>
          <AutoIntl displayId="au.vehicle.lastFtcpMessage" />
        </td>
        <td className={cn(styles.fieldvalue, customStyles.no_col_padding)}>
          <SimpleTable>
            <Row>
              <HeaderCell
                className={customStyles.stage_header}
                displayId="au.vehicle.lastFtcpMessage.stage"
              />
              <HeaderCell
                className={customStyles.time_header}
                displayId="au.vehicle.lastFtcpMessage.time"
              />
              <HeaderCell
                className={customStyles.result_header}
                displayId="au.vehicle.lastFtcpMessage.result"
              />
              <HeaderCell displayId="au.vehicle.lastFtcpMessage.details" />
            </Row>
            { rows }
          </SimpleTable>
        </td>
      </tr>
    );
  }

  render() {
    const { error, fetching } = this.state;

    return (
      <div className={styles.section}>
        <table className={styles.rows}>
          <tbody>
            { fetching ? this.renderLoading() : null }
            { !fetching && error ? this.renderError() : null }
            { !error && !fetching ? this.renderLastFtcpMessage() : null }
          </tbody>
        </table>
      </div>
    );
  }
}
