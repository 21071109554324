import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import debounce from 'lodash/debounce';

import AuDropDown from '@au/core/lib/components/elements/AuDropDown';

import { NOOP } from '../constants';
import { trackEvent } from '../utils/analyticsHelpers';

import styles from '../css/components/feed_topology_search.module.scss';

export default class FeedTopologySearch extends React.Component {

  static propTypes = {
    className: T.string,
    onSearch: T.func.isRequired,
    onChange: T.func,
    options: T.arrayOf(T.shape({
      val: T.string.isRequired,
      displayString: T.string.isRequired
    })),
    caption: T.string,
    searchBoxOnly: T.bool
  };

  static defaultProps = {
    onChange: NOOP,
    options: [],
    searchBoxOnly: false
  };

  state = {
    showSearch: false,
    searchText: '',
    clearCount: 0 // Used to clear AuDropdown
  };

  dropdownRef = React.createRef();

  toggleSearch = this.toggleSearch.bind(this);
  toggleSearch() {
    this.setState(prevState => ({
      showSearch: !prevState.showSearch
    }), () => {
      if (this.state.showSearch) {
        this.dropdownRef.current.focus();
      }
    });
  }

  handleSelectionChange = this.handleSelectionChange.bind(this);
  handleSelectionChange(option) {
    const { options } = this.props;
    this.setState({ searchText: option });

    // Make sure we have a valid selection
    const foundEntity = options.find(opt => option === opt.val);
    if (foundEntity) {
      this.props.onSearch(option);
    }

    // Track usage
    trackEvent({
      element: 'FeedTopologySearch',
      action: 'SelectedSearchOption',
      page: 'FeedTopology'
    });
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(searchText) {
    this.setState({ searchText });

    if (searchText.length === 0) {
      this.clearText();
    }

    this.applyFieldChange(searchText);
  }

  applyFieldChange = debounce(this.applyFieldChange, 200).bind(this);
  applyFieldChange(searchText) {
    this.props.onChange(searchText);
  }

  clearText = this.clearText.bind(this);
  clearText() {
    this.setState(prevState => ({
      searchText: '',
      clearCount: ++prevState.clearCount
    }), () => {
      this.props.onChange(this.state.searchText);
      this.dropdownRef.current.focus();
    });
  }

  render() {
    const { className, options, searchBoxOnly, caption } = this.props;
    const { showSearch, searchText, clearCount } = this.state;

    return (
      <React.Fragment>
        { (showSearch || searchBoxOnly ) &&
          <div className={cn({[styles.search_container]: searchBoxOnly === false})}>
            <div className={styles.search_box}>
              <AuDropDown
                ref={this.dropdownRef}
                key={`search_${clearCount}`}
                toggleClassName={styles.hide_toggle}
                selectionClassName={styles.selection}
                placeholderId={'au.topologySearch.placeholder'}
                options={options}
                selection={searchText}
                selectOption={this.handleSelectionChange}
                onChange={this.handleFieldChange}
                allowTyping={true}
              />
              <div
                className={cn(styles.search_container_icon, {
                  [styles.search_container_search_icon]: searchText.length === 0,
                  [styles.search_container_close_icon]: searchText.length > 0
                })}
                onClick={this.clearText}
              />
            </div>
          </div>
        }
        {!searchBoxOnly &&
          <div className={cn(styles.container, className)} onClick={this.toggleSearch}>
            <div
              data-caption={caption}
              className={cn({
                [styles.search_icon]: !showSearch,
                [styles.close_icon]: showSearch
              })}
            />
          </div>
        }
      </React.Fragment>
    );
  }
}
