import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PropProvider from '../PropProvider';
import * as ActionCreators from '../ActionCreators';
import MockEdit from '../components/MockEdit';

export default connect(
  (state, ownProps) => PropProvider(state).MockCreate(ownProps),
  (dispatch) => ({actions: bindActionCreators(ActionCreators, dispatch)})
)(MockEdit);
