import React from 'react';
import ReactDOM from 'react-dom';
import * as T from 'prop-types';
import moment from 'moment';
import cn from 'classnames';

import AuDayPicker from '@au/core/lib/components/elements/AuDayPicker';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { NOOP } from '../constants';

import styles from '../css/components/calendar.module.scss';

const DATEPICKER_NUMBER_OF_MONTHS = 3;

export default class Calendar extends React.Component {

  static propTypes = {
    className: T.string,
    excludeToday: T.bool,
    onChange: T.func,
    legend: T.object,
    modifiers: T.object,
    from: T.instanceOf(Date),
    to: T.instanceOf(Date),
    portalRef: T.object,
    readOnly: T.bool,
    numberOfMonths: T.number,
    reverseMonths: T.bool,
    sidebar: T.bool
  }

  static defaultProps = {
    excludeToday: false,
    readOnly: true,
    onChange: NOOP,
    numberOfMonths: DATEPICKER_NUMBER_OF_MONTHS,
    reverseMonths: false
  }

  state = {
    multiSelect: {first: undefined, second: undefined}
  }

  getModifiers = this.getModifiers.bind(this);
  getModifiers() {
    const { from: start, to: end, modifiers } = this.props;
    return Object.assign({ start, end }, modifiers);
  }

  // We chose to make a minor adjustment here in order to allow for exclusive end dates to
  // be represented well on the calendar. This only affects times of 12:00 am, times of 12:01 am are shown as inclusive.
  getSelectedDays = this.getSelectedDays.bind(this);
  getSelectedDays() {
    const { from, to } = this.props;
    const { enterFrom, enterTo } = this.state;

    return [
      enterFrom || from,
      {
        from: enterFrom || from,
        to: to ? moment(to).subtract({seconds: 1}).toDate() : enterTo
      }
    ];
  }

  getDisabledDays = this.getDisabledDays.bind(this);
  getDisabledDays() {
    const { from, excludeToday, readOnly } = this.props;
    const disabledDays = {
      after: moment().subtract(excludeToday ? 1 : 0, 'days').toDate()
    };

    if (readOnly) {
      disabledDays.before = from;
    }

    return disabledDays;
  }

  handleDayClick = this.handleDayClick.bind(this);
  handleDayClick({ from: date }) {
    // because of the changes in react-day-picker v8 (with mode=range) and 
    // the way we're handling range selection, sometimes we need to treat `from`
    // (depending on our own logic) as `to`

    const { enterFrom: from } = this.state;

    if (this.props.sidebar) {
      this.setState({enterFrom: date, enterTo: undefined});
      this.props.onSelect();
      this.props.onChange(date);
    }
    else {
      if (from) {
        // commit changes
        this.props.onChange({ date: date });
        // reset custom selection
        this.setState({ enterFrom: undefined, enterTo: undefined });
      }
      else {
        this.setState({ enterFrom: date, enterTo: undefined });
      }
    }
  }

  handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
  handleDayMouseEnter(date) {
    if (this.state.enterFrom) {
      this.setState({ enterTo: date });
    }
  }

  handleDayMouseLeave = this.handleDayMouseLeave.bind(this);
  handleDayMouseLeave() {
    if (this.state.enterTo) {
      this.setState({ enterTo: undefined });
    }
  }

  renderLegend() {
    const { legend } = this.props;
    const legendEntries = [];

    if (!legend) return;

    for (let [name, props] of Object.entries(legend)) {
      legendEntries.push(
        <AutoIntl
          key={'legend_entry_' + name}
          displayId={props.displayId}
          displayString={props.displayString}
          className={cn(styles.entry, `DayPicker-Day--${name}`)}
          tag="li"
        />
      );
    }

    return <ul className={styles.legend}>{ legendEntries }</ul>;
  }

  renderContent() {
    const { to, fromMonth, toMonth, initialMonth, numberOfMonths } = this.props;
    const firstMonth = moment().subtract(numberOfMonths - 1, 'months').startOf('month').toDate();
    const lastMonth  = moment().endOf('month').toDate();

    return (
      <div className={cn(styles.container, this.props.className)} onMouseDown={(e) => e.preventDefault()}>
        <AuDayPicker
          mode="range"
          className={styles.daypicker}
          fromMonth={fromMonth || firstMonth}
          toMonth={toMonth || lastMonth}
          initialMonth={initialMonth || to}
          month={to}
          readOnly={this.props.readOnly}
          modifiers={this.getModifiers()}
          selectedDays={this.getSelectedDays()}
          disabledDays={this.getDisabledDays()}
          numberOfMonths={numberOfMonths}
          reverseMonths={this.props.reverseMonths}
          canChangeMonth={this.props.canChangeMonth}
          onDayMouseEnter={this.handleDayMouseEnter}
          onDayMouseLeave={this.handleDayMouseLeave}
          onDayClick={this.handleDayClick}
        />
        { this.renderLegend() }
      </div>
    );
  }

  render() {
    if (this.props.portalRef) {
      return ReactDOM.createPortal(this.renderContent(), this.props.portalRef);
    }

    return this.renderContent();
  }
}
