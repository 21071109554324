import React from "react";
import { injectIntl } from "react-intl";
import * as T from "prop-types";
import AuDropDown from "@au/core/lib/components/elements/AuDropDown";

import { devicePropertyFilterValues, vehiclePropertyFilterValues } from "../../constants";
import { shouldBeInList } from "../../utils/validationRules";
import { KeyValue } from "./KeyValue";

import styles from "../../css/components/filters/key_value.module.scss";

const propertyFilterValues = {
  "device": devicePropertyFilterValues,
  "vehicle": vehiclePropertyFilterValues,
};

class Property extends KeyValue {

  static propTypes = {
    selection: T.arrayOf(T.shape({
      bubbleText: T.string,
      urlValue: T.string,
    })).isRequired,
    onChange: T.func.isRequired,
    onInit: T.func.isRequired,
    entityType: T.oneOf(Object.keys(propertyFilterValues)).isRequired
  };

  keyPlaceholderId = 'au.filters.property.key';
  valuePlaceholderId = 'au.filters.property.value';

  state = {...this.getInitialState(), counter: 0};

  propertiesItems = propertyFilterValues[this.props.entityType];

  resetComponent() {
    super.resetComponent();
    this.setState(prevState => ({counter: prevState.counter + 1}));
  }

  getKeyValidations() {
    const newKeyValidations = super.getKeyValidations();
    const propertyNames = this.propertiesItems.map(item => item.displayString);
    newKeyValidations.splice(1, 0, shouldBeInList(propertyNames));
    return newKeyValidations;
  }

  keyInput() {
    const {key, counter, keyErrorId} = this.state;
    const {selection} = this.props;

    // In order to clear the dropdown, we change the key every time the user hits apply
    return <AuDropDown
      key={`tag_name_${counter}`}
      className={styles.key_input}
      toggleClassName={styles.key_value_tg}
      placeholderId={this.keyPlaceholderId}
      selection={key}
      selectOption={(value) => this.setState({key: value, keyErrorId: ""})}
      options={this.propertiesItems.filter(item => !selection.some(selectedItem => {
        return selectedItem.urlValue.split(":")[0] === item.val;
      }))}
      allowTyping
      showError={Boolean(keyErrorId)}
      error={{
        errDisplayId: keyErrorId,
        fieldDisplayId: "au.entity.name",
        values: {validCharacters: "a-z, A-Z, 0-9, _"}
      }}
      createMode={true}
      onChange={value => {
        if (value === "") {
          this.setState({key: value, keyErrorId: ""});
        }
      }}
    />;
  }
}

export default injectIntl(Property);