import React from 'react';
import * as T from 'prop-types';
import debounce from 'lodash/debounce';
import { FormattedMessage } from 'react-intl';
import TMC from "@autonomic/browser-sdk";

import { formatMessage } from '../../../utils/reactIntl';
import { fsmType } from '../../../constants';
import { SelectInput } from '../Inputs';
import CommandVersionEditor from './CommandVersionEditor';

import fieldset from '../../../css/fieldset.module.scss';
import styles from '../../../css/command_inputs.module.scss';

export class ManagedCommandType extends React.Component {
  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    labelId: T.string,
    placeholderId: T.string,
    defaultOption: T.string,
    options: T.arrayOf(
      T.shape({
        displayId: T.string,
        displayString: T.string
      })
    ),
    allowTyping: T.bool,
    validationErrors: T.object
  }

  state = { options: [] };

  metaDataEndpoint = new TMC.services.Command({ apiVersion: '1-beta' }).definitions;

  componentDidMount() {
    this.metaDataEndpoint.uniqueTypes()
      .then(resp => {
        this.setState({
          customCommandTypeItems: [
            ...resp.data.results.map((name) => ({
              displayString: name,
              val: name
            }))
          ]
        });
      })
      .catch(this.genericErrorHandler);
  }

  handleOptionSelect = debounce(this.handleOptionSelect, 150).bind(this);
  handleOptionSelect(option) {
    const { selection } = this.props;

    if (option?.trim() && !selection.some(entry => entry.urlValue === option)) {
      let displayId = `au.commands.types.${ option }`;
      let text = formatMessage({ id: displayId });
      this.props.onChange([...selection, {
        urlValue: option,
        bubbleText: text === displayId ? option : text
      }]);
      this.typeRef.current.reset();
      setTimeout(this.typeRef.current.focus);
    }
  }

  render() {
    const options = this.state?.customCommandTypeItems;
    if (!options)
      return;

    const { field, placeholderId, labelId, validationErrors, createMode } = this.props;

    return (<SelectInput
      {...this.props}
      allowMismatch={true}
      key={field + options?.length}
      options={options}
      toggleClassName={styles.command_type_toggle}
      allowTyping={true}
      createMode={createMode}
      disabled={!createMode}
      labelId={labelId}
      placeholderId={placeholderId}
      validationErrors={validationErrors}
      showErrors={true}
    />);
  }
}

export class CommandVersionInput extends React.Component {

  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
    min: T.number,
    max: T.number,
    labelId: T.string,
    registerValidation: T.func,
    validationErrors: T.object,
    createMode: T.bool
  }

  static defaultProps = {
    min: 0,
    max: 99
  }

  componentDidMount(){
    const { field, labelId, disabled, registerValidation, validationRules } = this.props;
    if (registerValidation && validationRules && !disabled) {
      registerValidation(field, labelId, validationRules);
    }
  }

  handleFieldChange = this.handleFieldChange.bind(this);
  handleFieldChange(version) {
    const { fieldChangeCallback, field } = this.props;
    if (fieldChangeCallback) {
      fieldChangeCallback(field, version);
    }
  }

  render() {
    const { createMode,entity, min, max, validationErrors, field, labelId } = this.props;
    const validationError = validationErrors && (field in validationErrors) ? validationErrors[field] : null;

    return (
      <div className={fieldset.row}>
        <label className={fieldset.label}>
          <FormattedMessage id={labelId} />
        </label>
        <div className={styles.command_version_control}>
          <CommandVersionEditor
            onChange={this.handleFieldChange}
            entity={entity}
            min={min}
            max={max}
            errors={validationError}
            createMode={createMode}
          />
        </div>
      </div>
    );
  }
}

export class FsmInput extends React.Component {

  static propTypes = {
    entity: T.object,
    field: T.string,
    fieldChangeCallback: T.func,
  }

  state = { options: [] }

  componentDidMount() {
    const fsmTypeOptions = Object.values(fsmType).map(type => {
      return {
        val: type, displayString: type,
      };
    });

    this.setState({ options: fsmTypeOptions});
  }

  render() {
    const { options } = this.state;
    const { field, createMode, placeholderId, labelId } = this.props;
    const hasOptions = this.state.options?.length !== 0;

    return (<SelectInput
      {...this.props}
      allowMismatch={!hasOptions}
      key={field + options?.length}
      options={options}
      allowTyping={hasOptions}
      createMode={!hasOptions ? false : createMode}
      disabled={!hasOptions}
      placeholderId={placeholderId}
      labelId={labelId}
    />);
  }
}