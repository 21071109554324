import moment, {ISO_8601} from "moment";

export const DATE_FORMAT = "MM/DD/YYYY";
export const DATE_LENGTH = DATE_FORMAT.length;
export const TIME_FORMAT = "hh:mm";
export const TIME_MS_FORMAT = "hh:mm:ss";
export const TIME_LENGTH = TIME_FORMAT.length;
export const TIME_MS_LENGTH = TIME_MS_FORMAT.length;
export const DEFAULT_TIME_PERIOD = "am";
export const ISO_DATE_FORMAT = "YYYY-MM-DD";

export function stateDateToMoment({date, time, timePeriod}) {
  let sessionTimezone = localStorage.getItem('timezone');
  let utcTimezone = sessionTimezone === 'UTC';

  if (time?.length === TIME_MS_LENGTH && timePeriod) {
    if (utcTimezone) {
      return moment.utc(date + time + timePeriod, DATE_FORMAT + TIME_MS_FORMAT + "a", true);
    } else {
      return moment(date + time + timePeriod, DATE_FORMAT + TIME_MS_FORMAT + "a", true);
    }
  }
  else if (time?.length === TIME_LENGTH && timePeriod) {
    if (utcTimezone) {
      return moment.utc(date + time + timePeriod, DATE_FORMAT + TIME_FORMAT + "a", true);
    } else {
      return moment(date + time + timePeriod, DATE_FORMAT + TIME_FORMAT + "a", true);
    }
  }
  return utcTimezone ? moment.utc(date, DATE_FORMAT, true) : moment(date, DATE_FORMAT, true);
}

export function isoDateToState(isoDate) {
  let sessionTimezone = localStorage.getItem('timezone');
  let utcTimezone = sessionTimezone === 'UTC';

  let date = utcTimezone ? moment.utc(isoDate, ISO_8601) : moment(isoDate, ISO_8601);

  return {date: date.format(DATE_FORMAT), time: date.format(TIME_MS_FORMAT), timePeriod: date.format("a"), error: date.isValid() ? "" : date.format()};
}

export function isValidISODate(isoDate) {
  return moment(isoDate, ISO_8601).isValid();
}

export function stateDateToDisplayString({date, time, timePeriod}) {
  return `${date} ${time} ${timePeriod}`;
}

export function checkStateForErrors(datetime, minDate, maxDate) {
  const momentDate = stateDateToMoment(datetime);

  if (datetime.date.length === DATE_LENGTH && !momentDate.isValid()) {
    return "au.validation.invalidDate";
  }
  if (minDate && minDate.isAfter(momentDate)) {
    return "au.validation.laterDate";
  }
  if (maxDate && maxDate.isBefore(momentDate)) {
    return "au.validation.earlierDate";
  }
  if (datetime.time?.length === TIME_LENGTH && !moment(datetime.time, TIME_FORMAT, true).isValid()) {
    return "au.validation.invalidTime";
  }
  return "";
}

export function generateCalendarBoundsFromStateDate(startDate, endDate, minDate, maxBound = moment().toDate()) {
  let from = undefined;
  let to = undefined;

  const startMoment = stateDateToMoment(startDate);
  const endMoment = stateDateToMoment(endDate);
  const isStartValid = startDate.error === "" && startMoment.isValid();
  const isEndValid = endDate.error === "" && endMoment.isValid();

  if (isStartValid) {
    from = startMoment.toDate();
  } else if (isEndValid) {
    from = minDate;
  }

  if (isEndValid) {
    to = endMoment.toDate();
  } else if (isStartValid) {
    to = maxBound;
  }
  return {from, to, maxBound: to > maxBound ? to : maxBound};
}

