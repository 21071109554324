import lowerFirst from 'lodash/lowerFirst';

import {required, validUuid} from "../../../utils/validationRules";
import { formatMessage } from '../../../utils/reactIntl';
import Lookup from "../Lookup";

export default class EmbeddedGroupLookup extends Lookup {

  attrDisplayId = 'au.entity.attr.groupId'

  attrPlaceholder = formatMessage({
    id: 'au.entity.lookup.placeholder'
  }, {
    fieldName: lowerFirst(formatMessage({ id: 'au.entity.attr.groupId' }))
  })

  embedded = true;

  validate() {
    const { inputStr } = this.state;
    // required
    let validationError = required(inputStr);
    let idError = {};

    if (!validationError) {
      // is not uuid
      idError = validUuid(inputStr);

      if (idError) {
        validationError = {...idError, fieldDisplayId: this.attrDisplayId};
      }
    }

    this.setState(prevState => ({
      showError: prevState.submitted && Boolean(validationError),
      error: {
        ...validationError,
        fieldDisplayId: this.attrDisplayId
      },
      isUuid: !idError
    }));

    return validationError;
  }

  lookup() {
    const { endpoint } = this.props;
    const { isUuid, inputStr } = this.state;

    if (isUuid) {
      return endpoint.get(inputStr);
    }
  }

}
