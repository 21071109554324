import React from 'react';
import * as T from 'prop-types';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import styles from '../css/components/connected_state_badge.module.scss';

export default class ConnectivityStateBadge extends React.Component {
  static propTypes = {
    connectionStatus: T.string
  }

  render() {
    const { connectionStatus } = this.props;
    
    return (
      <AutoIntl
        displayId={`au.entity.${connectionStatus === 'CONNECTED' ? 'connected' : connectionStatus === 'DISCONNECTED' ? 'disconnected' : 'unknown_event'}`}
        className={connectionStatus === 'CONNECTED' ? styles.connected : connectionStatus === 'DISCONNECTED' ? styles.disconnected : styles.unknown_event}
        tag="div" />
    );
  }
}