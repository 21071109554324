import React from 'react';
import * as T from 'prop-types';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuTags from '@au/core/lib/components/elements/AuTags';

import styles from '../css/components/truncated_tags.module.scss';
import truncatedListStyle from '../css/components/truncated_list.module.scss';

export default class TruncatedTagsWithPopup extends React.Component {

  static propTypes = {
    tags: T.object,
    maxTagsCount: T.number,
  }

  static defaultProps = {
    maxTagsCount: 3,
  }

  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  renderMore(more, moreTags) {
    if (more > 0) {
      return (
        <div className={truncatedListStyle.plus} key={"extra"} 
          onMouseEnter={() => this.setState({hover: true})}
          onMouseLeave={() => this.setState({hover: false})}
        >
          <AutoIntl displayId="au.entity.more" values={{ number: more }} />
          {this.state.hover &&
            <div className={truncatedListStyle.popup_container}>
              <div className={truncatedListStyle.popup}>
                <div className={truncatedListStyle.popup_list}>
                  <AuTags tags={moreTags} className={styles.tags_container}/>
                </div>
              </div>
            </div>
            }
        </div>
      )
    }
    return '';
  }

  render() {
    const { tags, maxTagsCount } = this.props;

    if(tags) {
      const objectKeys = Object.keys(tags);
      const objectKeysLength = objectKeys.length;
      if (objectKeysLength) {
        const more = objectKeysLength - maxTagsCount;
        const displayMore = more > 0;
        let displayTags = {};
        let moreTags = {};
        if(displayMore) {
          displayTags = Object.fromEntries(objectKeys.slice(0, maxTagsCount).map(key => [key, tags[key]]));
          moreTags = Object.fromEntries(objectKeys.slice(maxTagsCount).map(key => [key, tags[key]]));
        } else {
          displayTags = {...tags};
        }

        return (
          <>
            <AuTags tags={displayTags} className={styles.tags_container}/>
            {(displayMore) && this.renderMore(more, moreTags)}
          </>
        );
      }
    }
    return '';
  }
}
