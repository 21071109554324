import { connect } from 'react-redux';

import PropProvider from '../PropProvider';
import TapFilters from '../components/TapFilters';
import { defaultMapDispatchToProps } from '../utils/ConnectDefaults';
import { SERVICE_NAMES } from "../constants";

const joinResources = Object.freeze([
  { service: SERVICE_NAMES.ACCOUNTS, entity: 'clients' },
  { service: SERVICE_NAMES.IAM, entity: 'roles' }
]);

export default connect(
  (state, ownProps) => PropProvider(state).TapFilters({...ownProps, joinResources }),
  defaultMapDispatchToProps
)(TapFilters);