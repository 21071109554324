import { bindActionCreators } from 'redux';

import { smartEqual } from '@au/core/lib/utils/equality';

import * as ActionCreators from '../ActionCreators';

export const defaultMergeProps = (stateProps, dispatchProps, ownProps) => ({...ownProps, ...stateProps, ...dispatchProps});

export const defaultConnectEqualityChecks = {
  areStatesEqual: smartEqual,
  areOwnPropsEqual: smartEqual,
  areStatePropsEqual: smartEqual,
  areMergedPropsEqual: smartEqual,
};

export const defaultMapDispatchToProps = (dispatch) => ({actions: bindActionCreators(ActionCreators, dispatch)});
