const flags = {
  showClientsTab: false,
  showUsersTab: false,
  pauseFetch: false,
  profiler: false,
  addMissingOdometer: true,
  andPrintMissingOdometer: false,
  pauseSpec: true,
  getFlagValue: (flag) => {
    if (window && window._devflags_ && (flag in window._devflags_)){
      return window._devflags_[flag];
    }
    return flags[flag];
  }
};

if (process.env.NODE_ENV === 'development') {
  // So we can modify dev flags from the console
  window.auDebug = window.auDebug || {};
  window.auDebug.flags = flags;
}

export default flags;
