import TMC from '@autonomic/browser-sdk';
import DefaultList from '../List';
import { guaranteeUndefinedOrArray } from '../../utils/dataUtils';
import { generateEntityPath } from '../../../utils/entity';
import { preclusion, showAll } from "../../filters/FailureMode";

const DEPLOYMENT = 'deployment';
export default class CommandList extends DefaultList {

  static defaultProps = {
    ...super.defaultProps,
    pageSize: 300
  }

  commandEndpoint = new TMC.services.Command({ apiVersion: '1-beta' }).commands;

  list() {
    const { entityDef, pageSize } = this.props;
    const { queryParams } = entityDef;

    let data = { pageSize, ...queryParams, ...this.getFilterQueryParams() };

    data["currentStatuses"] = guaranteeUndefinedOrArray(data["currentStatuses"]);
    data["types"] = guaranteeUndefinedOrArray(data["types"]);
    data["regions"] = guaranteeUndefinedOrArray(data["regions"]);
    data["deviceTypes"] = guaranteeUndefinedOrArray(data["deviceTypes"]);
    data["vehicleModels"] = guaranteeUndefinedOrArray(data["vehicleModels"]);
    data["vehicleModelYears"] = guaranteeUndefinedOrArray(data["vehicleModelYears"]);

    return this.commandEndpoint.search(data);
  }

  getFilterQueryParams = this.getFilterQueryParams.bind(this);
  getFilterQueryParams() {
    const {
      failureMode,
      beforeDeliveryFailure,
      afterDeliveryFailure,
      beforeDeliveryExpiration,
      afterDeliveryExpiration,
      preclusion,
      ...copy
    } = this.state.filterQueryParams;

    if (failureMode || beforeDeliveryFailure || afterDeliveryFailure || beforeDeliveryExpiration || afterDeliveryExpiration) {
      copy.failureMode = {
        showAll: failureMode === showAll ? true : undefined,
        beforeDeliveryFailureTypes: guaranteeUndefinedOrArray(beforeDeliveryFailure),
        afterDeliveryFailureTypes: guaranteeUndefinedOrArray(afterDeliveryFailure),
        beforeDeliveryExpirationTypes: guaranteeUndefinedOrArray(beforeDeliveryExpiration),
        afterDeliveryExpirationTypes: guaranteeUndefinedOrArray(afterDeliveryExpiration)
      };
    }
    if(preclusion){
      copy.preclusion = this.preclusionMapping(guaranteeUndefinedOrArray(preclusion));
    }

    return copy;
  }

  preclusionMapping(preclusionFilters){
    return {
      showAll:  this.trueOrUndefined(preclusionFilters, showAll),
      firmwareUpgrade: this.trueOrUndefined(preclusionFilters, preclusion.firmwareUpdate.value),
      deepSleep: this.trueOrUndefined(preclusionFilters, preclusion.deepSleep.value),
      carrierRegistration: this.trueOrUndefined(preclusionFilters, preclusion.carrierRegistration.value),
      temperatureThreshold: this.trueOrUndefined(preclusionFilters, preclusion.temperatureThreshold.value)
    };
  }

  onListSuccess(resp) {
    const { actions, entityDef, endpoint, serviceAlias } = this.props;

    actions.listEntitiesSuccess({
      path: generateEntityPath(endpoint, serviceAlias),
      data: resp.items,
      pkField: entityDef.pkField,
      replace: resp.isFirstPage
    });

    return resp;
  }

  trueOrUndefined(array, value){
    if(Array.isArray(array) && array.includes(value)){
      return true;
    }
    return undefined;
  }

  // filtering out commands until they are ready to be exposed
  getFilteredEntities() {
    const filteredEntities =  this.props.entities.filter((entity) => entity.getIn(['type']) !== DEPLOYMENT);

    return filteredEntities;
  }

}
