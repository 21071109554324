import React from 'react';
import * as T from 'prop-types';

import StateIcon from './StateIcon';

import styles from '../css/components/device_connectivity_state_badge.module.scss';

// keep updated with device connectivity service
export const states = [
  'DISCONNECTED',
  'CONNECTED',
  'DEEP_SLEEP',
  'UNKNOWN'
];

export default class DeviceConnectivityStateBadge extends React.PureComponent {

  static propTypes = {
    state: T.oneOf(states).isRequired
  }

  render() {
    const { state } = this.props;

    const className = styles[`${state.toLowerCase()}_container`];
    const displayId = `au.deviceConnectivity.state.${state}`;

    return <DevConStateBadge icon={state} className={className} displayId={displayId} />;
  }

}

class DevConStateBadge extends StateIcon {

  static icons = states;

  static propTypes = {
    ...StateIcon.propTypes,
    icon: T.oneOf(this.icons),
  }

  getIconStyles() {
    return styles[this.props.icon.toLowerCase()];
  }

}
