import { connect } from 'react-redux';

import PropProvider from '../PropProvider';
import Entity from './Entity';

import { defaultMapDispatchToProps } from '../utils/ConnectDefaults';

export default connect(
  (state, ownProps) => PropProvider(state).PopoutEntityView(ownProps),
  defaultMapDispatchToProps
)(Entity);
