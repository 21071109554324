import React from 'react';
import { Map as imMap } from 'immutable';

import { FEED_TAPS } from '../../../constants';
import { FeedEventLink } from '../../FeedEventLink';
import FeedCommonView from './FeedCommonView';

export default class FlowView extends FeedCommonView {

  renderContentAboveTable() {
    let content = super.renderAdditionalContent();
    content.push(this.renderTopologyFragment());
    return content;
  }

  renderFeedEventLink = this.renderFeedEventLink.bind(this);
  renderFeedEventLink() {
    const { entity: entityProp, entityDef, resources } = this.props;
    const entity = entityProp.toJS();

    const allTaps = resources.get(FEED_TAPS) || imMap();
    const inputTapIds = allTaps
      .filter(tap => entity.inputTaps.includes(tap.get('aui')))
      .keySeq().toArray();

    return (
      <FeedEventLink
        accountId={entity.accountId}
        entityName={entityDef.type}
        tapIds={inputTapIds}
        flows={[entity.id]}
      />
    );
  }

}
