import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import { List } from 'immutable';
import cn from 'classnames';

import { formatMessage } from '../utils/reactIntl';
import { parseCallable } from '../utils/parse';
import SimpleTable, { Row, Cell, HeaderCell } from '../components/SimpleTable';

import styles from '../css/components/formatted_table.module.scss';

const COLUMN_SIZES = ['short', 'medium', 'long'];

export default class FormattedTable extends React.Component {

  static propTypes = {
    data: T.oneOfType([IPT.map, IPT.list]).isRequired,
    columnDefs: T.array.isRequired,
    formatters: T.object,
    popoutContained: T.bool,
    timezone: T.string
  }

  static defaultProps = {
    formatters: {}
  }

  renderColumns(idx, rowData) {
    const { columnDefs, popoutContained } = this.props;

    const columns = columnDefs.map(columnDef => {
      let value = rowData.get(columnDef.attr, '');

      if (columnDef.formatters) {
        const formatters = parseCallable(columnDef.formatters);
        // TODO move to utils
        if (formatters.length) {
          for (let { func, args } of formatters) {
            args = { ...args, popoutContained };

            if (typeof value !== 'undefined' && value !== null && typeof value[func] === 'function') {
                value = value[func]();
            } else if (typeof this.props.formatters[func] === 'function') {
              if (func === 'date' && !args.timezone) {
                args.timezone = this.props.timezone;
              }
              value = this.props.formatters[func]({ ...args, rowData, property: columnDef.attr, value, popoutContained });
            }
          }
        }
      }

      return (
        <Cell key={`cell_${idx}_${columnDef.attr}`} className={styles.cell}>
          { value }
        </Cell>
      );
    });

    return columns;
  }

  renderHeader() {
    const { columnDefs } = this.props;
    const headerColumns = columnDefs.map(({ attr, labelId, size }) =>
      <HeaderCell
        key={`header_cell_${attr}`}
        className={cn(styles.cell, { [styles[size]]: size && COLUMN_SIZES.includes(size) })}
      >
        { formatMessage(({ id: labelId || `au.entity.attr.${attr}` })) }
      </HeaderCell>
    );

    return (
      <Row className={styles.header}>{ headerColumns }</Row>
    );
  }

  renderRows() {
    const { data } = this.props;
    const rowItems = data instanceof List ? data : List([data]);

    const rows = rowItems.map((rowData, idx) =>
      <Row key={`row_${idx}`} className={styles.row}>
        { this.renderColumns(idx, rowData) }
      </Row>
    );

    return rows;
  }

  render() {
    return (
      <div className={styles.container}>
        <SimpleTable  className={styles.table}>
          { this.renderHeader() }
          { this.renderRows() }
        </SimpleTable>
      </div>
    );
  }

}
