import React from 'react';

import AuComponent from '@au/core/lib/components/elements/AuComponent';

import { required, minLength, maxLength, mustMatch } from '../utils/validationRules';
import { EntityEdit } from './entity/Forms';
import { SimpleLayout } from './entity/Layouts';
import { TextInput, EmailInput } from './entity/Inputs';

export default class MockCreate extends AuComponent {
  /* Proposed template for end state of Create entitiy */

  render() {
    return (
      <EntityEdit
        entityType="mock"
        entityExists={false}
        saveSuccessDestination="/geofences"
        cancelDestination="/geofences"
        actions={this.props.actions}
      >
        <SimpleLayout>
          <TextInput field="firstName" labelId="au.users.firstName" validationRules={[required, minLength(6), maxLength(8)]}/>
          <TextInput field="lastName" labelId="au.users.lastName" validationRules={[required, mustMatch('firstName', 'au.validation.passwordMismatch')]}/>
          <EmailInput field="email" labelId="au.users.email"/>
        </SimpleLayout>
      </EntityEdit>
    );
  }

}
