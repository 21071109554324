import React from 'react';
import * as T from 'prop-types';

import TMC from '@autonomic/browser-sdk';
import AuInput from '@au/core/lib/components/elements/AuInput';
import AuDropDown from '@au/core/lib/components/elements/AuDropDown';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { NOOP } from '../constants';
import { formatMessage } from '../utils/reactIntl';

import styles from '../css/components/attribute_tag_filter_value.module.scss';

const sampleRate = Object.freeze([
  'up_to_1_second',
  'up_to_5_seconds'
]);

const messageDirection = Object.freeze([
  'C2D',
  'D2C'
]);

export default class AttributeTagFilterValue extends React.PureComponent {

  // May contain other properties defined in AuDropDown.propTypes
  static propTypes = {
    tagKey: T.string,
    value: T.string,
    onChange: T.func
  }

  static defaultProps = {
    onChange: NOOP
  }

  state = { commandTypeOptions: [] }

  metaDataEndpoint = new TMC.services.Command({ apiVersion: '1-beta' }).metadata;

  sampleRateOptions = sampleRate.map(val => ({
    displayString: val, val
  }));

  messageDirectionOptions = messageDirection.map(val => ({
    displayString: val, val
  }));

  get options() {
    const { tagKey } = this.props;
    const { commandTypeOptions } = this.state;

    switch (tagKey) {
      case 'commandType':
        return commandTypeOptions;
      case 'sample_rate':
        return this.sampleRateOptions;
      case 'messageDirection':
        return this.messageDirectionOptions;
      default:
        return [];
    }
  }

  componentDidMount() {
    this.metaDataEndpoint.list().then(resp => {
      const commands = resp.items.map(({ name }) => ({
        displayId: `au.commands.types.${name}`,
        val: name
      }));
      /*
       * There is a case where it makes sense to have an empty attributeTagValue as there
       * appear to be cases where the attributeTagValue is not set upstream and in this
       * case there is a desire for the customer to pass the data along downstream.
       */
      commands.push({
        displayId: 'au.taps.filters.attributeTagFilter.emptyCommand',
        val: '()'
      });
      this.setState({
        commandTypeOptions: commands
      });
    }, createResponseAlertMessage);
  }

  componentDidUpdate(prevProps) {
    if (this.props.tagKey !== prevProps.tagKey) {
      if (this.options.length === 1) {
        // set the default value
        this.props.onChange(this.options[0].val);
      } else {
        // reset value
        this.props.onChange(this.options.length === 0 ? '' : undefined);
      }
    }
  }

  handleOnInputChange = this.handleOnInputChange.bind(this);
  handleOnInputChange(e) {
    const { value } = e.target;
    this.props.onChange(value);
  }

  handleOnClear = this.handleOnClear.bind(this);
  handleOnClear() {
    this.props.onChange('');
  }

  render() {
    const { tagKey, value, onChange, hintClassName, ...otherProps } = this.props;
    const optionsSize = this.options.length;

    if (optionsSize < 2) {
      const { displayId, displayString } = this.options[0] ?? {};
      const displayValue = displayId ? formatMessage({ id: displayId }) : displayString ?? value;
      const readonly = optionsSize === 1;

      return (
        <div className={styles.container}>
          <AuInput
            key={`attributeTag_${tagKey}`}
            value={displayValue}
            className={otherProps.className}
            createMode={true}
            disabled={readonly}
            onChange={readonly ? NOOP : this.handleOnInputChange}
            onClear={this.handleOnClear}
          />
          <div className={styles.hint_container}>
            <AutoIntl displayId={''} className={styles.icon} tag="div" />
            <AutoIntl displayId={'au.attributeTagFilterValue.hint'} className={hintClassName} />
          </div>
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <AuDropDown
          {...otherProps}
          key={`attributeTag_${tagKey}`}
          createMode={true}
          selection={value}
          options={this.options}
          selectOption={onChange}
        />
        <div className={styles.hint_container}>
          <AutoIntl displayId={''} className={styles.icon} tag="div" />
          <AutoIntl displayId={'au.attributeTagFilterValue.hint'} className={hintClassName} />
        </div>
      </div>
    );
  }

}
