import React from 'react';
import cn from 'classnames';
import * as T from 'prop-types';

import {BUTTON_TYPE_SECONDARY} from '@au/core/lib/components/elements/AuButton';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import ConfirmationDialog from '@au/core/lib/components/objects/ConfirmationDialog';
import LoadingIndicator from '@au/core/lib/components/elements/LoadingIndicator';

import {history as browserHistory} from '../../../history';
import {wrapActionWithTracking} from '../../../utils/analyticsHelpers';
import formatters from '../../../utils/formatters';
import {get} from '../../../utils/api';
import DeviceConnectivityStateBadge from '../../DeviceConnectivityStateBadge';
import EmptyValueHint from '../../EmptyValueHint';
import DefaultView from '../View';

import styles from '../../../css/components/entity_view.module.scss';
import customStyles from '../../../css/components/device_view.module.scss';

class RowOverlay extends React.PureComponent {
  static propTypes = {
    height: T.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]).isRequired, // in number of rows
    children: T.element.isRequired,
    className: T.string
  }

  render() {
    const { height, children, className } = this.props;

    return (
      <tr rowSpan={height} className={cn(styles['row' + height], className)} >
        <td colSpan="2" className={customStyles.timestampError}>
          {children}
        </td>
      </tr>
    );
  }
}

function NoData() {
  return <EmptyValueHint displayId="au.deviceConnectivity.noDataAvailable" />;
}

export default class DeviceView extends DefaultView {

  queryParams = { includeRelations: 'binding', expandRelations: 'binding' }

  componentDidMount() {
    super.componentDidMount();
    this.mayBeFetchCurrentConnectivity();
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    this.mayBeFetchCurrentConnectivity();
  }

  mayBeFetchCurrentConnectivity() {
    const {entity} = this.props;

    let provisionedDevices = entity.getIn(['relations', 'binding']);
    const provisioned = provisionedDevices?.size > 0;

    if (!provisioned) {
      const date = new Date(0);
      const formattedDate = date.toISOString();
      const defaultCurrentConnectivityStateResponse = {
        defaultResponse: true,
        state: 'UNKNOWN',
        hostname: '',
        timestamp: formattedDate.toString(),
      };

      this.setState({ latestDevCon: defaultCurrentConnectivityStateResponse });
    }

    if (provisioned && (this.state.latestDevCon == null || this.state.latestDevCon.defaultResponse)) {
      this.fetchDevCon();
    }
  }

  fetchDevCon = this.fetchDevCon.bind(this)
  async fetchDevCon() {
    const vin = this.props.entity.toJS().relations.binding.properties.vin;
    const currentConnectivityStateResponse = await get(`/v1/device-connectivity/${vin}/current-state`)
        .catch(r => r);
    this.setState({ latestDevCon: currentConnectivityStateResponse });
  }

  getDevConRow(key, vals) {
    return this.renderRow(`au.entity.attr.${key}`, vals[key]);
  }

  getDevConRows(vals={}) {
    return [
      this.getDevConRow('connectionStatus', vals),
      this.getDevConRow('endpointName', vals),
      this.getDevConRow('time', vals),
    ];
  }

  renderDevCon() {
    const { latestDevCon } = this.state;

    if (!latestDevCon) {
      // loading
      return [
        <RowOverlay key="dcsLoading" height={3} className={customStyles.dcs_loading}>
          <LoadingIndicator displayId="au.device.deviceConnectivityLoading" />
        </RowOverlay>
      ];
    } else if (latestDevCon.ok) {
      return this.getDevConRows({
        connectionStatus: <DeviceConnectivityStateBadge state={latestDevCon.data.state} />,
        endpointName:         latestDevCon.data.hostname,
        time:             formatters.dateWithRelative({ value: new Date(latestDevCon.data.timestamp) })
      });
    } else if (latestDevCon.status === 404) {
      // no data
      return this.getDevConRows({
        connectionStatus: <NoData />,
        endpointName:         <NoData />,
        time:             <NoData />
      });
    } else if (latestDevCon.defaultResponse) {
      return this.getDevConRows({
        connectionStatus: <DeviceConnectivityStateBadge state={latestDevCon.state} />,
        endpointName:         latestDevCon.hostname,
        time:             formatters.dateWithRelative({ value: new Date(latestDevCon.timestamp) })
      });
    }

    // error; captures 401/403 as well
    return [
      <RowOverlay key="dcsError" height={3} className={customStyles.dcs_error}>
        <React.Fragment>
          <AutoIntl
            displayId="au.device.deviceConnectivityError"
            className={customStyles.dcs_error_msg}
            values={{
              a: (...chunks) => <a onClick={this.fetchDevCon}>{chunks}</a>
            }}
          />
        </React.Fragment>
      </RowOverlay>
    ];
  }

  renderTableRows() {
    return [
      ...this.renderDevCon(),
      <tr className={styles.spacer} key="devConSpacer" />,
      ...super.renderTableRows()
    ];
  }

  loadData = this.loadData.bind(this);
  loadData() {
    const { entity, endpoint } = this.props;

    // Ensure we get relations when navigating between cached data
    // Saw this issue when navigating between device and vehicle event bindings where
    // we would render but relations were missing
    if (entity.size && !entity.getIn(['relations', 'bindings'])) {
      return endpoint.get(this.entityId, this.queryParams).then(this.onAfterFetch, this.genericErrorHandler);
    }

    return super.loadData();
  }

  onBindBtnClick = this.onBindBtnClick.bind(this);
  onBindBtnClick() {
    //FIXME - MOVE TO linkHelper
    browserHistory.push({
      pathname: this.baseUrl + `/${this.entityId}/bind`,
      state: {
        prevUrl: this.props.match.url
      }
    });
  }

  onUnbindBtnClick = this.onUnbindBtnClick.bind(this);
  onUnbindBtnClick() {
    if (this.props.entity) {
      this.setState({ showUnbindDialog: true });
    }
  }

  toggleUnbindDialog = this.toggleUnbindDialog.bind(this);
  toggleUnbindDialog() {
    this.setState(prevState => ({ showUnbindDialog: !prevState.showUnbindDialog }));
  }

  unbindDevice = this.unbindDevice.bind(this);
  unbindDevice() {
    const { endpoint } = this.props;

    endpoint.unbind(this.entityId).then(
      this.onDeviceUnbindSuccess,
      this.genericErrorHandler
    );
  }

  onDeviceUnbindSuccess = this.onDeviceUnbindSuccess.bind(this);
  onDeviceUnbindSuccess() {
    const { endpoint } = this.props;
    // fetch device details to update bindigs
    endpoint.get(this.entityId, this.queryParams);

    this.setState({ showUnbindDialog: false, unbindSucceded: null });
  }

  renderUnbindDialog() {
    const { showUnbindDialog, unbindSucceded } = this.state;

    if (!showUnbindDialog) {
      return false;
    }

    return (
      <ConfirmationDialog
        key="device_unbind_dialog"
        type="alert"
        titleId="au.device.unbind.title"
        confirmDisplayId="au.device.unbind.confirm"
        className={customStyles.unbind_dialog}
        headerClassName={customStyles.header}
        succeded={unbindSucceded}
        onConfirm={this.unbindDevice}
        onCancel={this.toggleUnbindDialog}
      >
        <AutoIntl displayId="au.device.unbind.message1" tag="div"/>
        <AutoIntl displayId="au.device.unbind.message2" tag="div"/>
      </ConfirmationDialog>
    );
  }

  renderDialogs() {
    const dialogs = super.renderDialogs();
    dialogs.push(this.renderUnbindDialog());
    return dialogs;
  }

  getActions() {
    const { entity } = this.props;
    const actions = super.getActions();
    const binded = entity.hasIn(['relations', 'binding', 'id']);

    actions.splice(1, 0, wrapActionWithTracking({
      key: `${binded ? 'un': ''}bind_device`,
      type: BUTTON_TYPE_SECONDARY,
      className: styles.button,
      displayId: `au.device.${binded ? 'un' : ''}bindDevice`,
      onClick: binded ? this.onUnbindBtnClick : this.onBindBtnClick
    }, 'Device', 'View'));

    return actions;
  }

}
