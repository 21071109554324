import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import moment from 'moment-timezone';

import AuInput from '@au/core/lib/components/elements/AuInput';
import AuDayPickerInput from '@au/core/lib/components/elements/AuDayPickerInput';
import {
  NOOP, DATETIME_FORMAT_DATE, DATETIME_FORMAT_HOURS, DATETIME_FORMAT_MINUTES
} from '../constants';

import styles from '../css/components/au_date_time_input.module.scss';

const HOURS_KEY      = 'hours';
const MINUTES_KEY    = 'minutes';
const LOCAL_TIMEZONE = moment.tz.guess();

export default class AuDateTimeInput extends React.Component {

  static propTypes = {
    value: T.instanceOf(Date),
    format: T.string,
    timezone: T.string,
    className: T.string,
    disabledDays: T.object,
    dateCaption: T.string,
    timeCaption: T.string,
    createMode: T.bool,
    onChange: T.func,
    onBlur: T.func
  };

  static defaultProps = {
    timezone: LOCAL_TIMEZONE,
    disabledDays: {},
    createMode: false,
    onChange: NOOP,
    onBlur: NOOP
  };

  constructor(props) {
    super(props);

    const { value, timezone } = props;
    const momentDate = moment.tz(value, timezone).seconds(0);

    this.state = {
      [HOURS_KEY]: momentDate.format(DATETIME_FORMAT_HOURS),
      [MINUTES_KEY]: momentDate.format(DATETIME_FORMAT_MINUTES),
      value: momentDate.toDate(),
      invalidFields: {},
      timezone
    };

    props.onChange(this.state.value);
  }

  static getDerivedStateFromProps(props, state) {
    const { timezone } = props;

    if (timezone !== state.timezone) {
      const momentDate = moment.tz(state.value, timezone);
      return {
        value: momentDate.toDate(),
        [HOURS_KEY]: momentDate.format(DATETIME_FORMAT_HOURS),
        [MINUTES_KEY]: momentDate.format(DATETIME_FORMAT_MINUTES),
        timezone
      };
    }

    return null;
  }

  handleDateChange = this.handleDateChange.bind(this);
  handleDateChange(date) {
    const { timezone } = this.props;

    this.setState(prevState => {
      const old = moment.tz(prevState.value, timezone);
      return {
        ...prevState,
        value: moment.tz(date, timezone).hours(old.hours()).minutes(old.minutes()).toDate()
      };
    }, this.onDateTimeChange);
  }

  handleTimeChange = this.handleTimeChange.bind(this);
  handleTimeChange(ev) {
    const { target } = ev;
    const momentDate = this.getMomentDate({ [target.name]: target.value });

    this.setState(prevState => ({
      [target.name]: target.value,
      invalidFields: {
        ...prevState.invalidFields,
        [target.name]: !momentDate.isValid()
      }
    }));
  }

  handleDatepickerChange = this.handleDatepickerChange.bind(this);
  handleDatepickerChange(ev) {
    const { target } = ev;
    this.handleDateChange(target.value);
  }

  handleTimepickerChange = this.handleTimepickerChange.bind(this);
  handleTimepickerChange(ev) {
    const { target } = ev;
    const [hours, minutes] = target.value.split(':');
    const momentDate = this.getMomentDate({ hours, minutes });
    const invalid = !momentDate.isValid();

    this.setState(prevState => ({
      value: momentDate.toDate(),
      [HOURS_KEY]: hours,
      [MINUTES_KEY]: minutes,
      invalidFields: {
        ...prevState.invalidFields,
        [HOURS_KEY]: invalid,
        [MINUTES_KEY]: invalid
      }
    }), this.onDateTimeChange);

  }

  handleInputFocus = this.handleInputFocus.bind(this);
  handleInputFocus(ev) {
    const { target } = ev;
    this.setState({ [target.name]: '' });
  }

  handleInputBlur = this.handleInputBlur.bind(this)
  handleInputBlur(ev) {
    const { timezone } = this.props;
    const { value, invalidFields } = this.state;
    const { target } = ev;
    const format = target.name === HOURS_KEY ? DATETIME_FORMAT_HOURS : DATETIME_FORMAT_MINUTES;

    setTimeout(() => {
      const momentDate = this.getMomentDate(target);

      let props;

      if (momentDate.isValid()) {
        props = {
          value: momentDate.toDate(),
          [target.name]: momentDate.format(format)
        };
      } else {
        props = {
          [target.name]: moment.tz(value, timezone).format(format)
        };
      }

      this.setState(prevState => ({
        invalidFields: {
          ...prevState.invalidFields,
          [target.name]: false
        },
        ...props
      }), this.onDateTimeChange);
    }, invalidFields[target.name] ? 500 : 0);
  }

  onDateTimeChange = this.onDateTimeChange.bind(this)
  onDateTimeChange() {
    this.props.onChange(this.state.value);
  }

  handleBtnClick = this.handleBtnClick.bind(this);
  handleBtnClick() {
    if (this.from) {
      this.from.input.focus();
    }
  }

  getMomentDate(input) {
    const { timezone } = this.props;
    const { value } = this.state;
    /*
      `input` may contain `hours`, `minutes` or both.
      Here, we're getting current `hours` and `minutes` from the state and
      override them with one or both values from `input`.
     */
    const { [HOURS_KEY]: hours, [MINUTES_KEY]: minutes } = Object.assign({}, this.state, input);
    const date = moment.tz(value, timezone).format(DATETIME_FORMAT_DATE);

    return moment.tz(
      `${date} ${hours}:${minutes}`,
      `${DATETIME_FORMAT_DATE} H:m`,
      true,
      timezone
    );
  }

  render() {
    const { timezone, disabledDays, dateCaption, timeCaption, createMode, className } = this.props;
    const { name, showError, error } = this.props;
    const { value, invalidFields } = this.state;
    const tz = moment.tz.zone(timezone);

    return (
      <div className={cn(styles.container, className)}>
        <div className={styles.dateWrapper}>
          {dateCaption && <div className={styles.label}>{dateCaption}</div>}
          <AuDayPickerInput
            ref={el => this.from = el}
            locale={moment.locale()}
            timezone={timezone}
            value={value}
            format={DATETIME_FORMAT_DATE}
            className={cn(styles.input, styles.daypicker)}
            overlayClassName={styles.overlay}
            createMode={createMode}
            inputProps={{
              className: styles.input,
              name,
              error,
              showError
            }}
            dayPickerProps={{
              canChangeMonth: true,
              disabledDays: disabledDays,
              numberOfMonths: 1,
              weekdays: moment.weekdaysShort(),
              months: moment.months()
            }}
            onDayChange={this.handleDateChange}
            onBlur={this.props.onBlur}
          />
          <div className={styles.btn} onClick={this.handleBtnClick}></div>
          <AuInput
            type="date"
            name="datepicker"
            className={styles.datepicker}
            value={moment.tz(value, timezone).format('YYYY-MM-DD')}
            createMode={createMode}
            onChange={this.handleDatepickerChange}
            onBlur={this.props.onBlur}
            showError={showError}
            showClear={false}
          />
        </div>
        <div className={styles.timeWrapper}>
          { timeCaption && <div className={styles.label}>{timeCaption}</div>}
          <div className={styles.inline}>
            <AuInput
              type="text"
              name={HOURS_KEY}
              maxLength="2"
              className={cn(styles.hours, styles.short)}
              value={this.state[HOURS_KEY]}
              onChange={this.handleTimeChange}
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur}
              showError={invalidFields[HOURS_KEY] || showError}
              showClear={false}
              createMode={createMode}
            />
            <span className={styles.colon}>{" : "}</span>
            <AuInput
              type="text"
              name={MINUTES_KEY}
              maxLength="2"
              className={cn(styles.minutes, styles.short)}
              value={this.state[MINUTES_KEY]}
              onChange={this.handleTimeChange}
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur}
              showError={invalidFields[MINUTES_KEY] || showError}
              showClear={false}
              createMode={createMode}
            />
            <AuInput
              type="time"
              name="timepicker"
              className={styles.timepicker}
              value={`${this.state[HOURS_KEY]}:${this.state[MINUTES_KEY]}`}
              createMode={createMode}
              onChange={this.handleTimepickerChange}
              onBlur={this.props.onBlur}
              showError={showError}
              error={error}
              showClear={false}
            />
            { tz && <span className={styles.tz}>{moment.tz(value, timezone).zoneAbbr()}</span>}
          </div>
        </div>
      </div>
    );
  }

}
