import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import AuDropDown from '@au/core/lib/components/elements/AuDropDown';

import { NOOP, tapFilterType } from '../constants';

import styles from '../css/components/tap_filter_type_selector.module.scss';

const filterTypeOptions = Object.values(tapFilterType).filter(type => type !== tapFilterType.TAG).map(type => ({
  displayId: `au.taps.filters.${type}`,
  val: type
}));
export default class ForkFilterTypeSelector extends React.Component {

  static propTypes = {
    selection: T.string,
    disabled: T.bool,
    createMode: T.bool,
    className: T.string,
    onChange: T.func,
    bufferTop: T.number,
    bufferBottom: T.number,
    filterTypeOptions: T.array
  }

  static defaultProps = {
    onChange: NOOP,
    bufferTop: 142,
    bufferBottom: 54,
  }

  render() {
    const { selection, disabled, createMode, className, onChange, bufferTop, bufferBottom } = this.props;

    return (
      <AuDropDown
        className={cn(styles.container, className)}
        options={this.props.filterTypeOptions || filterTypeOptions}
        selection={selection}
        selectOption={onChange}
        createMode={createMode}
        disabled={disabled}
        bufferTop={bufferTop}
        bufferBottom={bufferBottom}
      />
    );
  }

}