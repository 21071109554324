import React, { useState } from 'react';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import MiniDropDown from '@au/core/lib/components/elements/MiniDropDown';

import { formatMessage } from '../utils/reactIntl';

import styles from '../css/components/column_selector.module.scss';

const DraggableFields = (props) => {

    // uncomment below code for reordering functionality
    //   let fieldItemDrag = useRef();
    //   let fieldItemDragOver = useRef();

    // function dragStart(e, index) {
    //   fieldItemDrag.current = e.target.id;
    // }
    
    // function dragEnter(e, index) {
    //   if (e.target.tagName === 'DIV') {
    //     fieldItemDragOver.current = e.target.id;
    //   }
    // }
    
    // function dragEnd(e, index) {
    //   let reorderedOptions;
    //   if (Object.values(options).length !== Object.values(stateOptions).length) {
    //     updateOptions(options);
    //     reorderedOptions = options;
    //   }
    //   else {
    //     reorderedOptions = stateOptions;
    //   }

    //   let newColumns = props.columns;
    //   const dragOption = reorderedOptions.findIndex((opt) => opt.props.id === fieldItemDrag.current);
    //   const columnDragIndex = newColumns.findIndex((col) => col.label === fieldItemDrag.current);
    //   const columnItem = newColumns[columnDragIndex];
    //   const draggedItem = reorderedOptions[dragOption];
    //   const dragOverOption = reorderedOptions.findIndex((opt) => opt.props.id === fieldItemDragOver.current);
    //   const columnDragOverIndex = newColumns.findIndex((col) => col.label === fieldItemDragOver.current);
    //   reorderedOptions.splice(dragOption, 1);
    //   reorderedOptions.splice(dragOverOption, 0, draggedItem);

    //   newColumns.splice(columnDragIndex, 1);
    //   newColumns.splice(columnDragOverIndex, 0, columnItem);

    //   fieldItemDragOver.current = null;
    //   fieldItemDrag.current = null;

    //   updateOptions(reorderedOptions);
    //   props.handleReorder(newColumns)
    // }

    const [expanded, toggleExpanded] = useState(false);
    let options = [];
    let availableFields = [];
    let index = 0;
    let maxHeight;
    if (props.columns && props.columns.length > 0) {
        props.columns.map((col) =>{
            const label = props.attributes[col.property]?.label ? formatMessage({ id: props.attributes[col.property]?.label }) : col.label;
            if (props.attributes[col.property]?.toggle === 'option') {
              if (col.display) {
                  options.push(
                    <div id={col.label} className={index === 0 ? styles.top_option : styles.option}>
                      <i className={styles.reorder}/>
                      <AutoIntl displayString={label}/>
                      <i className={styles.delete} onClick={() => {props.onClick(col.property, 'delete')}}/>
                    </div>
                  );
                  index++;
              }
              else {
                availableFields.push({label: label, property: col.property});
              }
            }
            if (props.attributes[col.property]?.toggle === 'default' && col.property !== 'id' && col.property !== 'timestamp') {
              if (col.display) {
                  options.push(
                    <div className={index === 0 ? styles.top_option : styles.option} id={col.label}>
                      <i className={styles.reorder}/>
                      <AutoIntl displayString={label}/>
                      <i className={styles.delete} onClick={() => {props.onClick(col.property, 'delete')}}/>
                    </div>
                  );
                  index++;
              }
              else {
                availableFields.push({label: label, property: col.property});
              }
            }
          });
          const arrowDown = !expanded || availableFields.length === 0;
          maxHeight = 35 * availableFields.length + 66;
          props.setMaxHeight(maxHeight > 380 ? maxHeight : 380);
          const sortedFields = availableFields.map(field => field.label).sort();
          options.unshift(
            <div className={styles.head_section}>
              <div className={styles.available_fields} onClick={() => {toggleExpanded(!expanded); updateOptions(stateOptions)}}><div className={styles.count}>{availableFields.length}</div><AutoIntl displayId={'au.entity.columnSelector.availableFields'}/><i className={availableFields.length > 0 ? (arrowDown ? styles.caret_down : styles.caret_up) : styles.caret_disabled} /></div>
              <div className={expanded ? styles.visible : styles.hidden}>
                {sortedFields.map((field) => {
                  return (
                    <div className={styles.expanded_option} key={field}>
                      <i className={styles.reorder}/>
                      <AutoIntl displayString={field}/>
                      <i className={styles.add} onClick={() => {props.onClick(availableFields.find(val => val.label === field).property, 'add')}}/>
                    </div>
                  )
                })}
              </div>
            </div>
          )
    }   
    const [stateOptions, updateOptions] = useState(options);
    if (Object.values(options).length !== Object.values(stateOptions).length) {
        updateOptions(options);
    }

    return stateOptions;
} 

export default class ColumnSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            columns: this.props.columns,
            count: 0
        }
    }

  parseColumns(attributes) {
    let options = [];
    for (const [key, value] of Object.entries(attributes)) {
      if (value.display?.list !== false && key !== 'id') {
        options.push(key);
      }
    }
  }

  toggleFields = this.toggleFields.bind(this);
  toggleFields() {
    this.setState((prevState) => ({expanded: !prevState.expanded}));
  }

  handleColumnChange = this.handleColumnChange.bind(this);
  handleColumnChange(field, action) {
    const { handleChange } = this.props;

    if (handleChange) {
      this.setState((prevState) => ({count: prevState.count + 1}));
      handleChange(field, action);
    }
  }

  handleColumnReorder = this.handleColumnReorder.bind(this);
  handleColumnReorder(columns) {
    const { handleReorder } = this.props;

    if (handleReorder) {
        this.setState((prevState) => ({count: prevState.count + 1}));
        handleReorder(columns);
    }
  }

  setMaxHeight = this.setMaxHeight.bind(this);
  setMaxHeight(val) {
    if (this.state.maxHeight !== val) {
        this.setState({maxHeight: val});
    }
  }

  render() {
    const { attributes, columns, type } = this.props;
    const { count, maxHeight } = this.state;
    const options = <DraggableFields id={count} type={type} attributes={attributes} columns={columns} onClick={this.handleColumnChange} handleReorder={this.handleColumnReorder} setMaxHeight={this.setMaxHeight}/>;

    return (
      <div className={styles.container}>
        <MiniDropDown
          type="fields"
          align="left"
          displayId={'au.entity.fields'}
          options={options}
          optionsType="columnSelector"
          optionsClassName={styles.column_options}
          maxHeight={maxHeight}
        />
      </div>
    );
  }
}