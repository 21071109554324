import React, {useMemo} from "react";
import AuInput from "@au/core/lib/components/elements/AuInput";
import * as T from 'prop-types';

const validDateInputRegex = /^\d{0,2}$|^\d{2}\/\d{0,2}$|^\d{2}\/\d{2}\/\d{0,4}$/;
const validPartialDateInputRegex = /^\d{0,1}$|^\d{1,2}\/\d{0,2}$|^\d{0,2}\/\d{0,2}\/\d{0,4}$/;
const validMonthDayInputRegex = /^\d{0,2}$|^\d{2}\/\d{0,2}$/;
const validPartialMonthDayInputRegex = /^\d{0,1}$|^\d{1,2}\/\d{0,2}$/;

// Dates: 01/34/6789
const FIRST_FORWARD_SLASH = 2;
const SECOND_FORWARD_SLASH = 5;

function DateInput(props) {
  const {date, setDate, hasError, hideYear, onClick, onKeyUp, ...rest} = props;

  const placeholderId = useMemo(() => hideYear ? "au.dayInput.placeholderMMDD" : "au.dayInput.placeholder", [hideYear]);

  function onChange(event) {
    const isDelete = (event.nativeEvent.inputType === "deleteContentBackward");
    if (event.target.value.match(hideYear ? validMonthDayInputRegex : validDateInputRegex)) {
      if (!isDelete && (event.target.value.length === FIRST_FORWARD_SLASH || (!hideYear && event.target.value.length === SECOND_FORWARD_SLASH))) {
        setDate(event.target.value + "/");
      } else if (isDelete && (event.target.value.length === FIRST_FORWARD_SLASH || event.target.value.length === SECOND_FORWARD_SLASH)) {
        setDate(event.target.value.slice(0, -1));
      } else {
        setDate(event.target.value);
      }
    }
    else if (event.target.value.match(hideYear ? validPartialMonthDayInputRegex : validPartialDateInputRegex)) {
      setDate(event.target.value);
    }
    else if (isDelete) {
      setDate(event.target.value);
    }
  }

  return (
    <div onClick={onClick}>
      <AuInput
        {...rest}
        value={date}
        placeholderId={placeholderId}
        onChange={onChange}
        showError={hasError}
        type={"calendar"}
        onKeyUp={onKeyUp}
      />
    </div>
  );
}

DateInput.propTypes = {
  date: T.string,
  setDate: T.func,
  hasError: T.bool,
  hideYear: T.bool
};

export default DateInput;

