import registerServiceWorker from './registerServiceWorker';

//React
import React from 'react';
import { createRoot } from 'react-dom/client';

//React Router
import { history } from './history';

//Redux
import { createStore, applyMiddleware, bindActionCreators, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';

//Reducer
import reducer from './reducers/index';

import AuAnalytics, { buildUserInfo } from '@au/core/lib/utils/AuAnalytics';
import authenticate from '@au/core/lib/utils/auth';
import { fetchJson } from '@au/core/lib/network/fetch';
import { clearStoredDomain, getUserDomain, writeDomainCookie } from '@au/core/lib/utils/domain';
import { AuDomainBootstrap, CustomAuth } from '@au/core/lib/components/elements';
import { removeQueryParams } from '@au/core/lib/utils/url';

import TMC from '@autonomic/browser-sdk';

//NOTE main.scss should be imported before Root and other components
import './css/main.scss';

import Root from './Root';
import IntlProvider from './containers/IntlProvider';
import * as actionCreators from './ActionCreators';
import DevFlags from './DevFlags';
import { setAccess } from './utils/accessFunctions';
import { getPartition, removePartition } from './utils/linkHelper';
import shared from './shared';
import { customCredsQueryParams } from './constants';
import StatusPage from './utils/statusPage';

const middlewares = [thunkMiddleware];


//Moment Locales
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/ru';
import 'moment/locale/de';

const moment = require('moment');

let appliedMiddlewares = applyMiddleware(...middlewares);
if (process.env.NODE_ENV === 'development') {
  const loggerMiddleware = createLogger({
    logger: {
      log: function () {
        if (window.reduxLogEnabled) {
          /* eslint-disable no-console */
          console.log.apply(this, arguments);
          /* eslint-enable no-console */
        }
      }
    }
  });
  middlewares.push(loggerMiddleware);
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  appliedMiddlewares = composeEnhancers(applyMiddleware(...middlewares));
}

const store = createStore(reducer, appliedMiddlewares);

const actions = bindActionCreators(actionCreators, store.dispatch);
store.dispatch({ type: 'REDUX_INIT' });

// trigger settingsMiddleware on init
store.dispatch({ type: 'SET_SETTING' });

// Set locale and unit system based on locale
const locale = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
actions.setLocale(locale);
if (locale.toUpperCase() === 'EN-US') {
  actions.setSystemOfUnits('uscs');
} else {
  actions.setSystemOfUnits('si');
}

// Set Momentjs locale
// reset a default locale (last imported locale is used by default)
moment.locale(); // fallback
// try to switch to a browser locale
moment.locale(locale);

if (module.hot) {
	//module.hot.accept();
  module.hot.accept('./reducers/index', () => {
    const nextRootReducer = require('./reducers/index').default;
    store.replaceReducer(nextRootReducer);
  });
}

window.copyDebugReportToClipboard = function() {
  //In Google Chrome you can then run:
  //copyDebugReportToClipboard()
  //which works with
  //pbpaste > /some/file/path.json
  //on macOS
  /* eslint-disable no-undef */
  if (typeof(copy) === "function") {
  /* eslint-enable no-undef */
    /* eslint-disable no-undef */
    copy(JSON.stringify(store.getState().toJS()));
    /* eslint-enable no-undef */
    return "copied app state to clipboard";
  } else {
    return JSON.stringify(store.getState().toJS());
  }
};

window.auDebug = window.auDebug || {};
window.auDebug.store = store;

var rootProps = {
  store,
  history
};

const root = createRoot(document.getElementById("root"));

// Do the initial data fetch
function initialFetch(appConfig) {
  const partitionConfig = getUserPartitionConfig(appConfig);
  if (partitionConfig.statusPageId) {
    actions.fetchStatusPageSummary();
  }
}

function fetchAccountSettings() {
  const accountsService = new TMC.services.Accounts();
  accountsService.accounts.settings().then(resp => {
    shared.account = resp.data;
  }).catch(error => {
    AuAnalytics.trackException({
      description: error.toString(),
      fatal: false
    });
  });
}

function intervalFetch(appConfig) {
  const partitionConfig = getUserPartitionConfig(appConfig);

  // Using named functions for the intervals for better tracebacks and profiling
  setInterval(function fetchServicesInterval() {
    if (partitionConfig.statusPageId) {
      actions.fetchStatusPageSummary();
    }
  }, 30000);
}

function setApplicationSettings(appConfig, partitionConfig){
  //Base domain, used for creation of the API URL
  window.API_BASE_URL = 'https://api.' + partitionConfig.baseDomain;
  shared.config.baseDomain = partitionConfig.baseDomain;
  shared.config.statusPageUrl = partitionConfig.statusPageUrl;

  //Used for the AuSpec to determine whether it runs or not
  window._deployment_mode_ = appConfig.deploymentMode ? appConfig.deploymentMode : "production";

  //Save the Partitions into shared for display later
  shared.config.partitions = appConfig.partitions;
  shared.config.environment = appConfig.environment;
  shared.config.partition = partitionConfig.id;
  /*
   * Allow environment over-ride of the dev flags
   */
  window._devflags_ = {};
  if (appConfig.devflags && typeof appConfig.devflags === 'object'){
    Object.keys(DevFlags).forEach((flag) => {
      if (flag in appConfig.devflags){
        window._devflags_[flag] = appConfig.devflags[flag];
      }
    });
  }
}


async function initializeApp(appConfig, partitionConfig, authClient) {
  setAccess(authClient.accessTokenPayload); // TODO: remove
  setApplicationSettings(appConfig, partitionConfig);

  shared.authClient = authClient;

  //Now that we know the user has auth'd, persist the partition
  actions.setPartition(partitionConfig.id);
  removePartition();

  // we need to get settings before mounting any component
  await fetchAccountSettings();

  initialFetch(appConfig);

  root.render(<Root {...rootProps} />);
  intervalFetch(appConfig);
}

if (module.hot) {
  module.hot.accept('./Root', () => {
    // If you use Webpack 2 in ES modules mode, you can
    // use <App /> here rather than require() a <NextApp />.
    const NextApp = require('./Root').default;
    root.render(<NextApp {...rootProps} />);
  });
}
registerServiceWorker();


function getUserPartitionConfig(appConfig) {
  let partitionConfig;

  // const urlParams = new URLSearchParams(window.location.search);

  // const partitionKey = store.getState().getIn(['settings', 'partition']);
  // const partitionKey = urlParams.get(environmentQueryParams.partition);
  let partitionKey = getPartition() || store.getState().getIn(['settings', 'partition']);

  if (appConfig.partitions) {
    if (partitionKey && appConfig.partitions[partitionKey]){
      partitionConfig = appConfig.partitions[partitionKey];
    }
    else {
      partitionKey = Object.keys(appConfig.partitions).find(key => appConfig.partitions[key].default);

      if (!partitionKey) {
        partitionKey = Object.keys(appConfig.partitions)[0];
      }

      partitionConfig = appConfig.partitions[partitionKey];
    }
    //augment the partition def with its ID. We'll need that later when we
    //persist it after the auth has been successful.
    partitionConfig.id = partitionKey;
  }
  else {
    //this is a problem. there should be at least one partition
    /* eslint-disable no-console */
    console.error("Missing partition definitions", appConfig);
    /* eslint-enable no-console */
  }
  return partitionConfig;
}

function buildAuthConfig(appConfig, partitionConfig) {
  const clientId = (process.env.NODE_ENV === 'production') ? appConfig.clientId : process.env.REACT_APP_AUTH_CLIENT;
  return {
    clientId,
    baseDomain: partitionConfig.baseDomain,
    idpHint: partitionConfig.idpHint
  };
}

async function initialize(appConfig) {
  const partitionConfig = getUserPartitionConfig(appConfig);
  const accountId = store.getState().getIn(['settings', 'partitions', partitionConfig.id, 'accountId']);
  const authConfig = await buildAuthConfig(appConfig, partitionConfig);

  function auth(customCreds) {
    shared.usingCustomCreds = false;
    let config = authConfig;
    if (customCreds) {
      config = {...config, ...customCreds};
      shared.usingCustomCreds = true;
    }

    return authenticate({ TMC, config, shared, accountId });
  }

  // if custom creds flag is active will display a dialog and not continue until
  // the custom credentials are accepted or the user cancels the dialog
  const client = await askForCustomCredentials(auth);

  const analyticsConfig = {
    ga: {
      trackingId: appConfig.gaTrackingId,
      options: {debug: false}
    }
  };
  if (appConfig.dd) {
    analyticsConfig.dd = {
      config: {
        token: appConfig.dd.token,
        appId: appConfig.dd.appId
      },
      userInfo: buildUserInfo(client)
    };
  }
  if (partitionConfig.statusPageId) {
    await StatusPage.initialize({ page : partitionConfig.statusPageId });
  }
  AuAnalytics.initialize(analyticsConfig);
  initializeApp(appConfig, partitionConfig, client);
}

/**
 * askForCustomCredentials handles displaying a custom credentials page similar
 * to domain bootstrap. Will call the `callback` function with user entered
 * params. `callback()` should return a promise which if resolved will clean up
 * the query parameter and resolve the promise returned. The returned promise
 * will not resolve unless the callback accepts the credentials or this is
 * cancelled. It's expected that what is rendered to the DOM is replaced on
 * success.
 * @param  {Function} callback Takes credentials in form { clientId,
 *                             clientSecret } and returns a promise to reject,
 *                             with a failed response or string, or accepts
 *                             which will resolve the returned promise.
 * @return {Promise}           Resolved if params are accepted by the callback,
 *                             rejected otherwise.
 */
async function askForCustomCredentials(callback) {
  const urlParams = new URLSearchParams(window.location.search);
  // if any key is present assume user wants to use custom credentials
  const useCustCreds = Object.values(customCredsQueryParams).some(qp => urlParams.has(qp));

  function cleanQP() {
    const cleanUrl = removeQueryParams(window.location.href, Object.values(customCredsQueryParams));
    history.replace({}, document.title, cleanUrl);
  }

  function onCancel() {
    cleanQP();
    callback();
  }

  if (useCustCreds) {
    let initTab;
    for (let [tab, qp] of Object.entries(customCredsQueryParams)) {
      if (urlParams.has(qp)) {
        initTab = tab;
      }
    }

    return new Promise(res => {
      const onSubmit = custCreds => {
        const cbResult = callback(custCreds);
        cbResult
          .then(cleanQP)
          .then(() => res(cbResult))
          .then(() =>
            AuAnalytics.trackEvent({
              page: 'CustomCredentials',
              element: 'Button',
              event: custCreds.clientSecret ? 'ClientLoginSuccess' : 'TokenLoginSuccess'
            })
          )
          .catch(() =>
            AuAnalytics.trackEvent({
              page: 'CustomCredentials',
              element: 'Button',
              event: custCreds.clientSecret ? 'ClientLoginFailure' : 'TokenLoginFailure'
            })
          );
        return cbResult;
      };

      root.render(<IntlProvider {...rootProps}><CustomAuth onSubmit={onSubmit} onCancel={onCancel} initTab={initTab }/></IntlProvider>);

    });
  }

  return callback();
}

async function askForDomain() {
  //GET USER DOMAIN FROM LOCAL STORAGE
  const domain = getUserDomain();

  //FETCH APP CONFIG BASED ON THE DOMAIN
  //IF EITHER MISSING, DISPLAY DOMAIN BOOTSTRAP COMPONENT
  if (!domain) {
    return new Promise(res => {
      const setDomain = domain => {
        writeDomainCookie(domain);
        res(domain);
      };

      root.render(<IntlProvider {...rootProps}><AuDomainBootstrap callback={setDomain} /></IntlProvider>);
    });
  }

  return domain;
}

async function main() {
  const domain = await askForDomain();

  fetchJson(`/config/${domain}/application.json`)
    .then(
      initialize,
      () => { clearStoredDomain(); main(); } //failed to fetch the domain.
    );
}

main();
