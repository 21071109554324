import React from 'react';
import cn from 'classnames';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { get } from '../utils/api';
import SimpleTable, { Row, Cell } from './SimpleTable';
import StateIcon, { ICON_ENABLED, ICON_DISABLED } from './StateIcon';

import tableStyles from '../css/components/simple_key_value.module.scss';
import styles from '../css/components/entity_view.module.scss';

export default class AppliedPolicyTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            statements: undefined
        }
    }

    async fetchData() {
        let { aui, actor } = this.props;
        if (aui) {
          localStorage.setItem("aui", aui);
        }
        else {
          aui = localStorage.getItem("aui");
        }
    
        if (actor) {
          const subjectAui = encodeURIComponent(`aui:iam:user/${actor}`);
    
          const resp = await get(`/v2/iam/policies:inspect?resourceAui=${aui}&subjectAui=${subjectAui}&traverse=all`)
          .then(resp => resp).catch((err) => {
            err['message'] = 'There is an error loading applied policy data'
            createResponseAlertMessage(err)
            this.setState({ errorStatusCode: 403, errorStatusMessage: 'There is an error loading applied policy data. Please check that you have the correct permissions or request permissions.' });
            throw err;
          });
          if (resp?.data) {
            this.setState({statements: resp.data.statements})
          }
        }
        else {
          this.setState({statements: undefined});
        }
      }

    async componentDidMount() {
        await this.fetchData();
    }
    
    render() {
        const { statements } = this.state;
        const noStatements = !statements || statements == [];
        const effect = (statements || statements?.length === 0) ? statements[0].effect : undefined;
        const allowed = effect === 'ALLOW';

        return (
          <SimpleTable className={cn(tableStyles.table, tableStyles.width_fill)}>
            <Row>
              <Cell className={cn(tableStyles.key, tableStyles.resource_header)}><AutoIntl displayId={'au.entity.attr.name'}/></Cell>
              <Cell className={cn(tableStyles.value, tableStyles.resource_header)}><AutoIntl displayId={'au.entity.attr.value'}/></Cell>
            </Row>
            <Row key={'aui'} className={tableStyles.row}>
              <Cell className={cn(tableStyles.key, tableStyles.resource_odd)}><AutoIntl displayId={'au.entity.attr.subjectAui'}/></Cell>
              <Cell className={cn(tableStyles.value, tableStyles.resource_odd)}>{noStatements ? <AutoIntl displayId={'au.deviceConnectivity.noDataAvailable'} className={styles.hint}/> : statements[0]?.subjectAui}</Cell>
            </Row>
            <Row key={'type'} className={tableStyles.row}>
              <Cell className={cn(tableStyles.key, tableStyles.resource_even)}><AutoIntl displayId={'au.entity.attr.resourceAui'}/></Cell>
              <Cell className={cn(tableStyles.value, tableStyles.resource_even)}>{noStatements ? <AutoIntl displayId={'au.deviceConnectivity.noDataAvailable'} className={styles.hint}/> : statements[0]?.resourceAui}</Cell>
            </Row>
            <Row key={'displayName'} className={tableStyles.row}>
              <Cell className={cn(tableStyles.key, tableStyles.resource_odd)}><AutoIntl displayId={'au.entity.attr.targetEntitlement'}/></Cell>
              <Cell className={cn(tableStyles.value, tableStyles.resource_odd)}>
                {noStatements && <AutoIntl displayId={'au.deviceConnectivity.noDataAvailable'} className={styles.hint}/>}
                {!noStatements && statements[0].entitlements.map((ent) => {
                  return <div key={ent}>{ent}</div>
                })}
              </Cell>
            </Row>
            <Row key={'type'} className={tableStyles.row}>
              <Cell className={cn(tableStyles.key, tableStyles.resource_even)}><AutoIntl displayId={'au.entity.attr.effect'}/></Cell>
              <Cell className={cn(tableStyles.value, tableStyles.resource_even)}>{noStatements ? <AutoIntl displayId={'au.deviceConnectivity.noDataAvailable'} className={styles.hint}/> : <StateIcon icon={allowed ? ICON_ENABLED : ICON_DISABLED} displayId={`au.policies.effects.${effect}`}/>}</Cell>
            </Row>
          </SimpleTable>
        )
    }
}