export const config   = {}; // expected to be populated by index.js
export const account  = {}; // expected to be populated by index.js
export const settings = {}; // expected to be populated by index.js

export default {
  config,
  account,
  settings,
  topology: {
    layout: null,
    transform: null
  }
};
