import React from 'react';
import TMC from '@autonomic/browser-sdk';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import { enhanceSdkEndpoint } from '../utils/api';
import EntityLookup from './EntityLookup';
import EntityLink from '../containers/EntityLink';
import { SERVICE_NAMES } from '../constants';

export default class ForkGroupFilterDetails extends React.Component {

  constructor(props) {
    super(props);
    this.groupEndpoint = enhanceSdkEndpoint(
      new TMC.services.Inventory(),
      'groups',
      this.props.actions
    );
  }

  render() {
    const { groupId } = this.props;
    return <div>
      <div>
        <AutoIntl displayId="au.taps.filters.groupName" />
        <EntityLookup
          endpoint={this.groupEndpoint}
          entityId={groupId}
          lookupField={'displayName'}
          serviceAlias={SERVICE_NAMES.INVENTORY}
          entityAlias={'groups'}
        />
      </div>
      <div>
        <AutoIntl displayId="au.taps.filters.groupId" />
        <EntityLink
          idProperty={groupId}
          entityId={groupId}
          entityType={'group'}
          action={'view'}
          url={"/services/inventory-service/groups"}
        >
          {groupId}
        </EntityLink></div>
    </div>
  }
}