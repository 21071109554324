import React from 'react';
import cn from 'classnames';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import { BUTTON_TYPE_TERTIARY } from '@au/core/lib/components/elements/AuButton';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import DefaultView from '../View';
import { get } from '../../../utils/api';
import {
  orderByDisplay,
  skipDisplay,
  shouldHideContent,
  hideOptionalEmptyData
} from '../../../utils/entity';
import SimpleTable, { Row, Cell } from '../../../components/SimpleTable';
import StateIcon, { ICON_ENABLED, ICON_DISABLED } from '../../StateIcon';

import tableStyles from '../../../css/components/simple_key_value.module.scss';
import styles from '../../../css/components/entity_view.module.scss';

export default class LogView extends DefaultView {

  async onAfterFetch(data) {
    super.onAfterFetch(data);

    const { entity } = this.props;
    let aui = entity.get('aui');
    if (aui) {
      localStorage.setItem("aui", aui);
    }
    else {
      aui = localStorage.getItem("aui");
    }

    const actor = entity.get('actor');

    if (actor) {
      const subjectAui = encodeURIComponent(`aui:iam:user/${entity.get('actor')}`);

      const resp = await get(`/v2/iam/policies:inspect?resourceAui=${aui}&subjectAui=${subjectAui}&traverse=all`)
      .then(resp => resp).catch((err) => {
        err['message'] = 'There is an error loading applied policy data'
        createResponseAlertMessage(err)
        this.setState({ errorStatusCode: 403, errorStatusMessage: 'There is an error loading applied policy data. Please check that you have the correct permissions or request permissions.' });
        throw err;
      });
      if (resp?.data) {
        this.setState({statements: resp.data.statements})
      }
    }
    else {
      this.setState({statements: undefined});
    }
  }

  renderResourceRow() {
    const { entity } = this.props;
    const type = entity.get('resourceType');
    const resourceAui = entity.get('aui');
    const entityId = resourceAui.substring(resourceAui.indexOf('/') + 1);

    return (
      <tr key={`field_resource`} className={styles.filters_row} ref={ref => this.rowRef = ref}>
        <td className={cn(styles.fieldname, styles.column)}>
          <AutoIntl displayId={'au.entity.attr.resource'}/>
        </td>
        <td>
          <SimpleTable className={tableStyles.table}>
            <Row>
              <Cell className={cn(tableStyles.key, tableStyles.resource_header)}><AutoIntl displayId={'au.entity.attr.name'}/></Cell>
              <Cell className={cn(tableStyles.value, tableStyles.resource_header)}><AutoIntl displayId={'au.entity.attr.value'}/></Cell>
            </Row>
            <Row key={'aui'} className={tableStyles.row}>
              <Cell className={cn(tableStyles.key, tableStyles.resource_odd)}><AutoIntl displayId={'au.entity.attr.aui'}/></Cell>
              <Cell className={cn(tableStyles.value, tableStyles.resource_odd)}>{resourceAui}</Cell>
            </Row>
            <Row key={'type'} className={tableStyles.row}>
              <Cell className={cn(tableStyles.key, tableStyles.resource_even)}><AutoIntl displayId={'au.entity.attr.type'}/></Cell>
              <Cell className={cn(tableStyles.value, tableStyles.resource_even)}>{type}</Cell>
            </Row>
            <Row key={'displayName'} className={tableStyles.row}>
              <Cell className={cn(tableStyles.key, tableStyles.resource_odd)}><AutoIntl displayId={'au.entity.attr.name'}/></Cell>
              <Cell className={cn(tableStyles.value, tableStyles.resource_odd)}>
                <a href={`/services/feed-service/${type}s/${entityId}/view`}>{entity.get('displayName')}</a>
              </Cell>
            </Row>
          </SimpleTable>
        </td>
      </tr>
    )
  }

  onViewJsonBtnClick = this.onViewJsonBtnClick.bind(this);
  onViewJsonBtnClick() {
    const { actions, entity } = this.props;
    const data = entity.toJS();
    const entityId = data.id;

    actions.openEntityJsonViewer(
      entityId,
      data,
      {
        entityType: 'log',
        initialWidth: 680,
        minWidth: 680,
        buttonType: BUTTON_TYPE_TERTIARY,
        copyBtnClassName: styles.log_copy_btn,
        copyBtnDisplayId: 'au.multiSaveDialog.copyToClipboard'
      }
    );
  }

  renderAppliedPolicyTable() {
    const { statements } = this.state;
    const noStatements = !statements || statements == [];
    const effect = (statements || statements?.length === 0) ? statements[0].effect : undefined;
    const allowed = effect === 'ALLOW';

    return (
      <tr key={`field_resource`} className={styles.filters_row} ref={ref => this.rowRef = ref}>
        <td className={cn(styles.fieldname, styles.column)}>
          <AutoIntl displayId={'au.audit.appliedPolicy'}/>
        </td>
        <td>
          <SimpleTable className={tableStyles.table}>
            <Row>
              <Cell className={cn(tableStyles.key, tableStyles.resource_header)}><AutoIntl displayId={'au.entity.attr.name'}/></Cell>
              <Cell className={cn(tableStyles.value, tableStyles.resource_header)}><AutoIntl displayId={'au.entity.attr.value'}/></Cell>
            </Row>
            <Row key={'aui'} className={tableStyles.row}>
              <Cell className={cn(tableStyles.key, tableStyles.resource_odd)}><AutoIntl displayId={'au.entity.attr.subjectAui'}/></Cell>
              <Cell className={cn(tableStyles.value, tableStyles.resource_odd)}>{noStatements ? <AutoIntl displayId={'au.deviceConnectivity.noDataAvailable'} className={styles.hint}/> : statements[0]?.subjectAui}</Cell>
            </Row>
            <Row key={'type'} className={tableStyles.row}>
              <Cell className={cn(tableStyles.key, tableStyles.resource_even)}><AutoIntl displayId={'au.entity.attr.resourceAui'}/></Cell>
              <Cell className={cn(tableStyles.value, tableStyles.resource_even)}>{noStatements ? <AutoIntl displayId={'au.deviceConnectivity.noDataAvailable'} className={styles.hint}/> : statements[0]?.resourceAui}</Cell>
            </Row>
            <Row key={'displayName'} className={tableStyles.row}>
              <Cell className={cn(tableStyles.key, tableStyles.resource_odd)}><AutoIntl displayId={'au.entity.attr.targetEntitlement'}/></Cell>
              <Cell className={cn(tableStyles.value, tableStyles.resource_odd)}>
                {noStatements && <AutoIntl displayId={'au.deviceConnectivity.noDataAvailable'} className={styles.hint}/>}
                {!noStatements && statements[0].entitlements.map((ent) => {
                  return <div key={ent}>{ent}</div>
                })}
              </Cell>
            </Row>
            <Row key={'type'} className={tableStyles.row}>
              <Cell className={cn(tableStyles.key, tableStyles.resource_even)}><AutoIntl displayId={'au.entity.attr.effect'}/></Cell>
              <Cell className={cn(tableStyles.value, tableStyles.resource_even)}>{noStatements ? <AutoIntl displayId={'au.deviceConnectivity.noDataAvailable'} className={styles.hint}/> : <StateIcon icon={allowed ? ICON_ENABLED : ICON_DISABLED} displayId={`au.policies.effects.${effect}`}/>}</Cell>
            </Row>
          </SimpleTable>
        </td>
      </tr>
    )
  }

  renderJsonRow() {
    return (
      <tr key={`field_resource`} className={styles.filters_row} ref={ref => this.rowRef = ref}>
        <td className={cn(styles.fieldname, styles.column)}>
          <AutoIntl displayId={'au.entity.eventHistory.updateDetails'}/>
        </td>
        <td className={cn(styles.fieldvalue, styles.json_row)}>
          <div onClick={this.onViewJsonBtnClick}><AutoIntl displayId={'au.entity.commandManagement.viewjson'} className={styles.json_button}/></div>
        </td>
      </tr>
    )
  }

  renderTableRows(customAttributes) {
    const { entityDef, entity } = this.props;
    const rows = [];

    if (entity.size) {
      const orderedAttrs = orderByDisplay(customAttributes || entityDef.attributes, 'view');
      for (let [property, attr] of orderedAttrs) {
        if (hideOptionalEmptyData(attr, property, entity)) continue;
        if (skipDisplay(attr, 'view')) continue;
        if (shouldHideContent(attr)) continue;
        if (this.isHiddenField(property) && this.shouldHideField(property)) {
          continue;
        }
        if (attr?.multiRow) {
          const values = this.renderFormattedValue(property, attr);
          values && values.forEach((value, index) => {
            rows.push(this.renderRow(attr?.labelId || `au.entity.attr.${property}`, value, index + 1));
          });
        } 
        else if (property === 'jsonView') {
          rows.push(this.renderJsonRow());
        }
        else if (property === 'displayName') {
          rows.push(this.renderResourceRow());
        }
        else if (!['filters', 'inputView', 'outputView'].includes(property)) {
          rows.push(
            this.renderRow(attr?.labelId || `au.entity.attr.${property}`, this.renderFormattedValue(property, attr))
          );
        } 
        else {
          rows.push(
            <tr key={`field_${property}`} className={styles.filters_row} ref={ref => this.rowRef = ref} >
              <td className={styles.label}>
                <AutoIntl displayId={attr?.labelId || `au.entity.attr.${property}`} />
              </td>
              <td className={styles.fieldvalue}>
                {this.renderFormattedValue(property, attr)}
              </td>
            </tr>
          );
        }
      }
      rows.push(this.renderAppliedPolicyTable())
    }

    return rows;
  }
}