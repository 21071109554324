import createDebug from 'debug';

const prefix = 'au:console';

createDebug.formatters.l = iterable => {
  return [...iterable]
    .map(x => x.toString())
    .join(', ');
};

function getLogger(name, logLevel, enabled) {
  const logger = createDebug(prefix + ':' + name);
  logger.log = console[logLevel].bind(console); // eslint-disable-line no-console
  if (enabled !== undefined) {
    logger.enabled = enabled;
  }
  return logger;
}

// FIXME: exception logger does not work correctly with formatted strings :(

export function getLoggers(name) {
  const errorLogger = getLogger(name, 'error', true); // always show errors
  const exceptionLogger = exception => {
    errorLogger(exception.message || exception.toString());
    throw exception;
  };
  return {
    exception: exceptionLogger,
    error: errorLogger, // always show errors
    warn: getLogger(name, 'warn', true), // always show warnings
    info: getLogger(name, 'info'),
    debug: getLogger(name, 'debug')
  };
}
