import React from 'react';
import * as T from 'prop-types';

import AuInput from '@au/core/lib/components/elements/AuInput';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { required, validUuid } from "../utils/validationRules";
import { NOOP } from '../constants';

import styles from '../css/components/group_filter_value.module.scss';

const MODE_ID   = 'groupId';
const MODE_NAME = 'groupName';
export default class GroupFilterValue extends React.Component {

  static propTypes = {
    value: T.string,
    onChange: T.func
  }

  static defaultProps = {
    onChange: NOOP
  }

  constructor(props) {
    super(props);

    const { groupId, groupName } = props;

    this.state = {
      mode: groupId || !groupName ? MODE_ID : MODE_NAME,
      showError: false,
      groupId,
      groupName
    };
  }

  validate(value) {
    // required
    let validationError = required(value);
    let errors = {};

    if (!validationError) {
      // is not uuid
      errors = validUuid(value);

      if (errors) {
        validationError = {...errors, fieldDisplayId: 'au.entity.attr.groupId' };
      }
    }

    this.setState({
      showError: Boolean(validationError),
      error: {
        ...validationError,
        fieldDisplayId: 'au.entity.attr.groupId'
      },
      isUuid: !errors
    });

    return validationError;
  }

  handleOnChange = this.handleOnChange.bind(this);
  handleOnChange(e) {
    const value = e.target.value;
    this.onChange(value);
  }

  onChange(value) {
    const { mode } = this.state;
    this.setState({ [mode]: value });
    this.validate(value)
    this.props.onChange(value, mode);
  }

  render() {
    const { mode, showError, error } = this.state;
    return (
      <div className={styles.container}>
        <AutoIntl
          className={styles.label}
          displayId={`au.taps.filters.${mode}`}
        />
        <div className={styles.wrapper}>
          <AuInput
            key={`input_${mode}`}
            name={mode}
            createMode={true}
            value={this.state[mode]}
            className={styles.input}
            onChange={this.handleOnChange}
            onClear={() => this.onChange('')}
            showError={showError}
            error={error}
          />
        </div>
      </div>
    );
  }

}
