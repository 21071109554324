/**
 * Helper function that returns a "deferred" object
 * @return {Object} Deferred object existing a promise interface
 */
export default function defer() {
  let isPending = true;
  let isRejected = false;
  let isFulfilled = false;

  const deferred = {
    promise: null,
    resolve: null,
    reject: null,
    isPending: () => isPending,
    isRejected: () => isRejected,
    isFulfilled: () => isFulfilled
  };

  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  }).then(() => {
    isFulfilled = true;
    isPending = false;
  },
  () => {
    isRejected = true;
    isPending = false;
  });

  return deferred;
}
