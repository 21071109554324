import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import AuTooltip from '@au/core/lib/components/elements/AuTooltip';

import styles from '../css/components/hint_tooltop.module.scss';

function HintTooltip(props) {
  const { id, tooltipTitle, tooltipContent, children } = props;

  return (
    <span className={styles.container}>
      {children}
      <span className={cn(styles.icon, 'icon-question-mark')} data-tip data-for={`hint_${id}`} />
      <AuTooltip
        id={`hint_${id}`} 
        type="info" 
        className={styles.tooltip}
        title={tooltipTitle}
        titleClassName={styles.title}
      >
        {tooltipContent}
      </AuTooltip>
    </span>
  );
}

HintTooltip.propTypes = {
  id: T.string.isRequired,
  tootltipTitle: T.oneOfType([T.string, T.element]),
  tooltipContent: T.oneOfType([T.string, T.element])
};

export default HintTooltip;