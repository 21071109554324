import React from "react";
import { injectIntl } from "react-intl";
import * as T from "prop-types";
import throttle from 'lodash/throttle';

import { ENTER_KEY } from '@au/core/lib/constants';
import AuInput, { INPUT_TYPE_TEXT } from "@au/core/lib/components/elements/AuInput";
import AuButton from "@au/core/lib/components/elements/AuButton";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import { BUTTON_TYPE_TERTIARY } from "@au/core/lib/components/elements/AuButton";

import { ruleRunner, run } from "../../utils/validationRuleRunner";
import { regexp, required } from "../../utils/validationRules";
import { Filter } from "../../AuPropTypes";

import styles from "../../css/components/filters/value.module.scss";

const noNonValueCharacters = regexp("^[\\w0-9 ;:.,()?&$%@+=*+#[\\]-]+$", "au.validation.invalidCharacter");
const throttleWaitTime = 25;

export class Value extends React.Component {
  static propTypes = {
    selection: T.arrayOf(Filter).isRequired,
    onChange: T.func.isRequired,
    onInit: T.func.isRequired,
    placeholderId: T.string,
    valueValidations: T.array,
    validCharacters: T.string,
    hideCondition: T.bool
  };

  static defaultProps = {
    selection: [],
    hideCondition: false,
    validCharacters: "a-z, A-Z, 0-9, _;:.,()?&$%@+=*#[]-"
  };

  state = this.getInitialState();

  constructor(props) {
    super(props);

    this.handleValueChangeThrottled = throttle(this.onValueChange, throttleWaitTime);
  }

  getInitialState() {
    return {
      value: "",
      valueErrorId: ""
    };
  }

  componentDidMount() {
    this.props.onInit(this.props.selection);
  }

  resetComponent() {
    this.setState(this.getInitialState());
  }

  handleClick = this.handleClick.bind(this);
  handleClick() {
    const {value} = this.state;

    const errors = this.checkFormForErrors();
    if (errors) {
      this.setState({valueErrorId: errors.value});
    } else {
      const selectedItems = [...this.props.selection, {urlValue: value}];
      this.props.onChange(selectedItems);
      this.resetComponent();
    }
  }

  getValueValidations() {
    if (this.props.valueValidations) {
      return this.props.valueValidations;
    }

    return [required, noNonValueCharacters];
  }

  checkFormForErrors() {
    const errors = run(this.state, [
      ruleRunner("value", "au.entity.value", ...this.getValueValidations())
    ]);

    return errors?.value ? {value: errors.value.errDisplayId} : undefined;
  }

  onKeyDown = this.onKeyDown.bind(this);
  onKeyDown(e) {
    if (e.key === ENTER_KEY) {
      this.handleClick();
    }
  }

  onValueChange = (event) => this.setState({value: event.target.value, valueErrorId: ""});
  handleValueClear = () => this.setState({value: "", valueErrorId: ""});

  render() {
    const {hideCondition, placeholderId} = this.props;
    const {value, valueErrorId} = this.state;

    return (
      <div className={styles.container}>
        {hideCondition == false &&
        <AutoIntl className={styles.value_text} displayId="au.filters.conditionOr"/>
        }
        <AuInput
          className={styles.value_input}
          placeholderId={placeholderId}
          type={INPUT_TYPE_TEXT}
          value={value}
          onChange={this.handleValueChangeThrottled}
          onClear={this.handleValueClear}
          showError={Boolean(valueErrorId)}
          error={{
            errDisplayId: valueErrorId,
            fieldDisplayId: placeholderId,
            values: {validCharacters: this.props.validCharacters}
          }}
          createMode={true}
          onKeyDown={this.onKeyDown}
        />
        <AuButton
          type={BUTTON_TYPE_TERTIARY}
          onClick={this.handleClick}
          displayId={"au.filters.apply"}
        />
      </div>
    );
  }
}

export default injectIntl(Value);