import React from 'react';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import ConfirmationDialog from '@au/core/lib/components/objects/ConfirmationDialog';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { formatMessage } from '../../../utils/reactIntl';
import { findClientsByIds } from '../../../utils/accounts';
import { generateEntityPath, getParentEntityAwarePath } from '../../../utils/entity';
import { history as browserHistory } from '../../../history';
import SuccessDialog from '../SuccessDialog';
import DefaultList from '../List';

import styles from '../../../css/components/user_group_members_list.module.scss';

export default class UserGroupMemberList extends DefaultList {

  onListSuccess = this.onListSuccess.bind(this);
  onListSuccess(resp) {
    const { entityDef } = this.props;
    if (entityDef.name === 'clients') {
      const { endpoint, serviceAlias, actions } = this.props;
      let path = generateEntityPath(endpoint, serviceAlias);
      let ids = resp.items.map(user => user.username.split("service-account-").pop());
      if (ids.length > 0) {
        findClientsByIds(ids).then(
          res => {
            actions.listEntitiesSuccess({
              path,
              data: res.items,
              pkField: entityDef.pkField,
              replace: resp.isFirstPage
            });
          }
        )
      }
    } else {
      super.onListSuccess(resp);
    }
  }

  onViewBtnClick = this.onViewBtnClick.bind(this);
  onViewBtnClick(id) {
    const { serviceAlias, entityDef } = this.props;
    browserHistory.push({
      pathname: `/services/${serviceAlias}/${entityDef.name}/${decodeURIComponent(id)}/view`
    });
  }

  onDeleteBtnClick(id) {
    const { entityDef, entities } = this.props;
    const propertyGet = entityDef.name === 'clients' ? 'displayName' : 'username';
    this.setState({ 
      showDelete: true, toDelete: id, toDeleteName: entities.getIn([id, propertyGet])
    });
  }

  getRowActions({ id, canWrite, canReplicate, permissions }) {
    let actions = super.getRowActions({ id, canWrite, canReplicate, permissions });
    const { parentEntity } = this.props;

    if (parentEntity) { // For subviews
      actions = actions.filter((x => x.displayId === 'au.entity.viewDetails'));
      if (canWrite && permissions.canDelete) {
        const removeAction = {
          key: 'remove_btn',
          displayId: 'au.entity.remove',
          onClick: this.onDeleteBtnClick.bind(this, id)
        };
        actions.push(removeAction);
      }
    }
    return actions;
  }

  getAddons() {
    const addons = super.getAddons();
    const { parentEntity } = this.props;
    return parentEntity ? addons.filter((x => x.key !== 'entity_buttons')) : addons;
  }

  renderConfirmDeleteDialog() {
    const { showDelete, toDeleteName } = this.state;
    const { entityDef, parentEntity } = this.props;

    if (!showDelete) {
      return false;
    }

    return (
      <ConfirmationDialog
        type="alert"
        key="ConfirmDeleteMemberSubview"
        titleString={formatMessage(
          { id: 'au.userGroup.members.delete.title' },
          { entityName: formatMessage({ id: `au.entity.name.${entityDef.type}` }) }
        )}
        confirmDisplayId='au.entity.proceed'
        onConfirm={this.handleOnDeleteConfirm}
        onCancel={this.handleOnDeleteCancel}
        className={styles.container}
      >
        <AutoIntl
          className={styles.info}
          displayId="au.userGroup.dialogs.displayName" values={{
            b: chunks => <strong className={styles.display_name}>{chunks}</strong>,
            displayName: toDeleteName
          }}
          tag="div"
        />
        <AutoIntl 
          className={styles.description}
          displayId="au.userGroup.members.delete.description" 
          values={{ 
            entityName: formatMessage({ id: `au.entity.name.${entityDef.type}` }).toLowerCase(), 
            displayName: <strong>{parentEntity.entity.get('displayName')}</strong>
          }} 
          tag="div"
        />
      </ConfirmationDialog>
    );
  }

  renderDeleteSuccessDialog = this.renderDeleteSuccessDialog.bind(this);
  renderDeleteSuccessDialog() {
    const { showDeleteSuccess, toDeleteName } = this.state;
    const { entityDef, parentEntity } = this.props;

    if (!showDeleteSuccess) {
      return false;
    }

    return (
      <SuccessDialog
        key="successdeleteMemberOfUserGroupSubView"
        nameDisplayId={`au.userGroup.dialogs.${entityDef.type === 'client' ? 'displayName' : 'username'}`}
        nameValues={{
          b: chunks => <strong className={styles.display_name}>{chunks}</strong>,
          [entityDef.type === 'client' ? 'displayName' : 'username']: <strong>{toDeleteName}</strong>
        }}
        messageDisplayId="au.userGroup.members.deleteSuccess"
        messageValues={{
          entityName: formatMessage({ id: `au.entity.name.${entityDef.type}` }).toLowerCase(),
          displayName: <strong>{parentEntity.entity.get('displayName')}</strong>
        }}
        onClose={this.hideDeleteSuccess}
      />
    );
  }

  renderDialogs() {
    const dialogs = super.renderDialogs();
    dialogs.push(this.renderConfirmDeleteDialog());
    dialogs.push(this.renderDeleteSuccessDialog());
    return dialogs;
  }

  handleOnDeleteConfirm = this.handleOnDeleteConfirm.bind(this);
  handleOnDeleteConfirm() {
    const { endpoint, parentEntity, actions, match, entityDef, entities } = this.props;
    const { toDelete } = this.state;
    const { entityAlias } = match.params;
    if (toDelete) {
      if (entityDef.name === 'clients') {
        const clientIdToDelete = entities.getIn([toDelete, 'clientId']);
        if (clientIdToDelete) {
          endpoint.delete("service-account-" + clientIdToDelete).then(() => {
            this.setState({ showDelete: false, showDeleteSuccess: true });
            if (parentEntity) {
              const path = getParentEntityAwarePath(parentEntity, entityAlias);
              actions.deleteEntitySuccess({ path: [...path, toDelete] });
            }
          }, createResponseAlertMessage);
        } else {
          this.setState({ showDelete: false });
        }
      } else {
        endpoint.delete(toDelete).then(() => {
          this.setState({ showDelete: false, showDeleteSuccess: true });
        }, createResponseAlertMessage);
      }
    }
  }

  handleOnDeleteCancel = this.handleOnDeleteCancel.bind(this);
  handleOnDeleteCancel() {
    this.setState({ showDelete: false, toDelete: null, toDeleteName: null });
  }

  hideDeleteSuccess = this.hideDeleteSuccess.bind(this);
  hideDeleteSuccess() {
    this.setState({ showDeleteSuccess: false });
  }
}
