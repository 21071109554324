import React from 'react';
import * as T from 'prop-types';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import styles from '../css/components/empty_value_hint.module.scss';

export default class EmptyValueHint extends React.PureComponent {

  static propTypes = {
    displayId: T.string
  }

  render() {
    const { displayId } = this.props;

    return (
      <AutoIntl
        className={styles.hint}
        displayId={displayId}
        tag="div" />
    );
  }

}
