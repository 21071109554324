import { getLoggers } from '../logger';
import cSet from '../dataStructures/comparisonSet';
import cMap from '../dataStructures/comparisonMap';
import { updateRefs, flipDirection } from './common';

import { areSetsEqual } from '../comparators';
import { flatten } from '../converters';

const { exception } = getLoggers('MultiTree');


function only(mapOrSet) {
  if (mapOrSet.size === 1) {
    for (let x of mapOrSet) return x;
  }
}

export default class MultiTree {
  constructor(trees, enforceUniquelyAssigned=false) {
    // expects a map of [[tree.id, tree], ...]
    this._nodes = new Map();
    this._trees = trees;
    this._sorted = false;

    for (let [treeAui, tree] of trees) {
      if (!this._direction) {
        this._direction = tree.direction;
      }
      else if (this._direction !== tree.direction) {
        exception(`${treeAui} tree is not in the same ${this.direction} direction as the others`);
      }
    }

    for (let [treeAui, tree] of trees) {
      for (let node of tree.nodes.values()) {
        if (!this._nodes.has(node.aui)) {
          const ourNode = Object.create(node);
          ourNode.trees = new Set([tree]);
          ourNode.depth = new Map([[treeAui, node.depth]]);
          ourNode.maxDepth = new Map([[treeAui, node.maxDepth]]);
          this._nodes.set(node.aui, ourNode);
        }
        else {
          const ourNode = this._nodes.get(node.aui);
          ourNode.trees.add(tree);
          ourNode.depth.set(treeAui, node.depth);
          ourNode.maxDepth.set(treeAui, node.maxDepth);
          ourNode.input = new Map([...ourNode.input, ...node.input]);
          ourNode.output = new Map([...ourNode.output, ...node.output]);
        }
      }
    }

    // Set "only" props
    for (let node of this.nodes.values()) {
      const onlyTree = only(node.trees);
      if (onlyTree) {
        node.onlyTree = onlyTree;
        node.onlyMaxDepth = only(node.maxDepth)[1];
        node.onlyDepth = only(node.depth)[1];
      }
    }

    if (enforceUniquelyAssigned && !this.isUniquelyAssigned) {
      exception('not all nodes are uniquely assigned to a tree');
    }

    updateRefs(this, 'input');
    updateRefs(this, 'output');
  }

  sort(orderedIds=[]) {
    const sortedTrees = new Map();
    let order = 0;
    for (let treeId of orderedIds) {
      const tree = this.trees.get(treeId);
      tree.order = order++;
      sortedTrees.set(treeId, this.trees.get(treeId));
    }
    for (let [treeId, tree] of this.trees) {
      if (!sortedTrees.has(treeId)) {
        tree.order = order++;
        sortedTrees.set(treeId, tree);
      }
    }
    this._sorted = true;
    this._trees = sortedTrees;
    return this._trees;
  }

  // Assumes everything is sorted and nodes are at maxDepth
  // Assumes fourth quadrant Cartesian coordinates (like SVG)
  getInterNodeDistance(nodeAAui, nodeBAui) {
    if (!this.sorted) {
      exception('cannot calculate distance between trees if not sorted');
    }
    const [nodeA, nodeB] = [nodeAAui, nodeBAui].map(a => this.nodes.get(a));
    for (let node of [nodeA, nodeB]) {
      if (!node.onlyTree) {
        exception(`cannot calculate distance for node ${node.aui} that exists in multiple trees`);
      }
    }
    const [treeA, treeB] = [nodeA, nodeB].map(n => n.onlyTree);
    for (let tree of [treeA, treeB]) {
      if (!tree.sorted) {
        exception(`cannot calculate distance for unsorted tree ${tree.id}`);
      }
    }

    const nodeYDist = (treeB.expandedPosition+nodeB.expandedPosition) - (treeA.expandedPosition+nodeA.expandedPosition);
    const nodeXDist =  nodeB.onlyMaxDepth - nodeA.onlyMaxDepth;

    return Math.abs(nodeYDist) + Math.abs(nodeXDist);// taxicab/Manhattan distance
  }


  // no order
  get nodes() {
    return this._nodes;
  }

  get direction() {
    return this._direction;
  }

  get isUniquelyAssigned() {
    if (this._isUniquelyAssigned) return this._isUniquelyAssigned;

    this._isUniquelyAssigned = true;
    for (let node of this.nodes.values()) {
      if (!node.onlyTree) {
        this._isUniquelyAssigned = false;
        break;
      }
    }

    return this._isUniquelyAssigned;
  }

  // No order
  get crossNodes() {
    if (this._crossNodes) return this._crossNodes;
    const oppDirection = flipDirection(this.direction);

    // Gather all cross nodes
    this._crossNodes = new Map();
    for (let [nodeAui, node] of this.nodes) {
      // A node that is member to multiple trees but parents are not also
      // members of the same trees may be a cross node
      const crossTrees = new cSet([], areSetsEqual);
      for (let parentNode of node[oppDirection].values()) {
        crossTrees.add(parentNode.trees); // set of tree sets
      }

      // All parents are in the same set of trees (or none for root)
      if (crossTrees.size <= 1) continue;

      // Multiple parents must be in different tree sets
      node.crossTrees = crossTrees;
      this._crossNodes.set(nodeAui, node);
    }

    // These keep track of the cross nodes processed and unprocessed
    this._treesCrossNodes = new cMap([], areSetsEqual); // Set(trees,...) -> Set(cNode,...)
    const remainingCrossNodes = new Set(); // track nodes not added to this._treesCrossNodes

    // Seed depth 0 cross nodes -- cross nodes that's parents are in only one
    // tree
    for (let cNode of this._crossNodes.values()) {
      if ([...cNode.crossTrees].every(ts => ts.size === 1)) {
        cNode.crossDepth = 0; // 0 means this is node can decide downstream cross node assignment

        const intersectingTrees = new Set(flatten(cNode.crossTrees));
        if (this._treesCrossNodes.has(intersectingTrees)) {
          this._treesCrossNodes.get(intersectingTrees).add(cNode);
        }
        else {
          this._treesCrossNodes.set(intersectingTrees, new Set([cNode]));
        }
      }
      else {
        remainingCrossNodes.add(cNode);
      }
    }

    // Add remaining cross nodes to this._treesCrossNodes by checking...
    let crossDepth = 1;
    while (remainingCrossNodes.size) {
      // Keep track of newly added tree sets for this particular iteration.
      // This ensures that a newly added tree set is not used to add another
      // cross node in the same iteration, thus the same cross depth,
      // erroneously.
      const newTreesCrossNodes = new cMap(this._treesCrossNodes, areSetsEqual);

      for (let cNode of remainingCrossNodes) {
        // If all this cross node's cross trees are either in the
        // this._treesCrossNodes or the cross tree set is of size one (singular
        // tree) then add it
        if ([...cNode.crossTrees].every(ts => ts.size === 1 || this._treesCrossNodes.has(ts))) {
          cNode.crossDepth = crossDepth;

          const intersectingTrees = new Set(flatten(cNode.crossTrees));
          if (newTreesCrossNodes.has(intersectingTrees)) {
            newTreesCrossNodes.get(intersectingTrees).add(cNode);
          }
          else {
            newTreesCrossNodes.set(intersectingTrees, new Set([cNode]));
          }

          remainingCrossNodes.delete(cNode);
        }
      }

      this._treesCrossNodes = newTreesCrossNodes;

      ++crossDepth;
    }

    return this._crossNodes;
  }

  // Is a map of a set of trees to the cross node that intersects these trees
  get treesCrossNodes() {
    this.crossNodes; // prime
    return this._treesCrossNodes;
  }

  get crossTrees() {
    if (this._crossTrees) return this._crossTrees;

    this._crossTrees = new Map();
    for (let cNode of this.crossNodes.values()) {
      for (let cTreeSet of cNode.crossTrees) {
        for (let cTree of cTreeSet) {
          this._crossTrees.set(cTree.id, cTree);
        }
      }
    }

    return this._crossTrees;
  }

  get trees() {
    return this._trees;
  }

  get sorted() {
    return this._sorted;
  }
}
