import {ALL_DEVICES_WILDCARD, tapFilterType} from "../constants";
import {parseAui} from "./parse";
import {immutableSelectorCreator} from "@au/core/lib/selectors/general";

export const feedFiltersSelector = immutableSelectorCreator(
  [ params => params.taps, params => params.forks, params => params.groups, params => params.vehicle ],
  getFeedFilters
);

export function getFeedFilters(taps, forks, groups, vehicle) {
  const tapFilters = getTapFilters(taps);
  const forkFilters = getForkFilters(forks);
  const filteredTaps = filterTaps(tapFilters, groups, vehicle);
  const filteredForks = filterForks(forkFilters, groups, vehicle);

  return filteredTaps.concat(filteredForks);
}

export function getTapFilters(taps) {
  return taps.flatMap(tap => {
    const outputFlow = tap.outputFlow ? {aui: tap.outputFlow, displayName: parseAui(tap.outputFlow)?.resource} : undefined;
    return tap.filters.map(createRow(tap, "Tap", outputFlow));
  });
}

export function getForkFilters(forks) {
  return forks.flatMap(fork => {
    return (fork?.outputs.flatMap(output => {
      return output.filters.map(createRow(fork, "Fork", output));
    }));
  });
}

export function createRow(entity, entityName, outputFlow) {
  return (filter) => {
    const filterType = Object.values(tapFilterType).map((item) => item).find(type => Object.keys(filter).includes(type));
    return ({
      ...entity,
      entity: entityName,
      outputFlow: outputFlow,
      filter: filterType,
      type: filter.type,
      filterDetails: filter[filterType]
    });
  };
}

export function filterTaps(taps, groups, vehicle){
  if(!vehicle){
    return taps.filter(tap => doesGroupMatch(tap, groups));
  }

  const vin = vehicle.getIn(['properties', 'vin']);
  const vehicleDeviceTypes = new Set(vehicle.getIn(['relations', 'bindings']).toJS().flatMap(d => d.type || []));
  const vehicleMembershipTags = vehicle.getIn(['relations', 'bindings']).toJS().flatMap(d => d.tags || []);

  return taps.filter(tap => {
    return doesVinMatch(tap, vin) ||
      doesGroupMatch(tap, groups) ||
      doesDeviceMatch(tap, vehicleDeviceTypes) ||
      doesMembershipTagMatch(tap, vehicleMembershipTags);
  });
}

export function filterForks(forks, groups, vehicle){
  if(!vehicle){
    return forks.filter(fork => doesGroupMatch(fork, groups));
  }

  const vin = vehicle.getIn(['properties', 'vin']);
  return forks.filter(fork => {
    return doesVinMatch(fork, vin) || doesGroupMatch(fork, groups);
  });
}

export function doesVinMatch(entity, vin){
  return Boolean(entity?.filterDetails?.vins?.includes(vin));
}

export function doesGroupMatch(entity, groups){
  return Boolean(groups.get(entity?.filterDetails?.groupId));
}

export function doesDeviceMatch(entity, vehicleDeviceTypes) {
  const filterDeviceType = entity?.filterDetails?.deviceType;
  return Boolean(vehicleDeviceTypes.size &&
    (vehicleDeviceTypes.has(filterDeviceType) || filterDeviceType === ALL_DEVICES_WILDCARD));
}

export function doesMembershipTagMatch(entity, vehicleMembershipTags) {
  const tagKey = entity?.filterDetails?.tagKey;
  const tagValue = entity?.filterDetails?.tagValue;
  return Boolean(tagValue && tagKey && vehicleMembershipTags.find(tag => tagKey in tag)?.[tagKey] === tagValue);
}