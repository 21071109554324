import get from "lodash/get";

import { generateIdSerializer } from "../../../utils/serializers";
import { getParentEntityAwarePath } from "../../../utils/entity";
import DefaultList from '../List';

export default class StateTransitionsList extends DefaultList {

  static defaultProps = {
    ...super.defaultProps,
    pageSize: 300
  };

  list() {
    const { entityDef, pageSize, parentEntity, endpoint } = this.props;
    const { queryParams } = entityDef;

    let data = { pageSize, ...queryParams };
    return endpoint.getHistory(parentEntity.entityId, data);
  }

  onListSuccess(resp) {
    const { parentEntity, match, actions, entityDef } = this.props;
    const { entityAlias } = match.params;

    if (parentEntity && entityDef.parentEndpoint && resp.data?.items) {
      const itemsWithIds = this.mapAdditionalData(resp.data.items);

      actions.listEntitiesSuccess({
        path: getParentEntityAwarePath(parentEntity, entityAlias),
        data: itemsWithIds,
        pkField: entityDef.pkField,
        replace: resp.isFirstPage
      });
    }
  }

  mapAdditionalData(items) {
    const { entityDef } = this.props;

    return items.map(item => {
      const genIdFields = entityDef.genId.map(fieldKey => get(item, fieldKey.split(".")));
      return {
        ...item,
        id: generateIdSerializer(genIdFields)
      };
    });
  }
}
