/**
 * flatten works the same as lodash's
 * (https://lodash.com/docs/4.17.15#flattenDepth), except it will work on any
 * iterable and it also takes an optional array to append to. It will not
 * continuously flatten single character strings since they are iterable but
 * also will return themselves.
 * @param  {Iterable.<*>} iterable any nested objects that implement ES iterator
 *                                 interface
 * @param  {Number} [depth=1]      number of times iterable is flattened
 * @param  {Array}  [appendTo=[]]  array to push flattened values into
 * @return {Array}                 array flatten at depth
 */
export function flatten(iterable, depth=1, appendTo=[]) {
  if (depth < 0) {
    appendTo.push(iterable);
    return appendTo;
  }

  for (let x of iterable) {
    if (typeof x[Symbol.iterator] === 'function') {
      if (typeof x === 'string' && x.length === 1)  {
        appendTo.push(x);
      }
      else {
        flatten(x, depth - 1, appendTo);
      }
    }
    else {
      appendTo.push(x);
    }
  }

  return appendTo;
}
