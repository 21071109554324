import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import AuToggle, { TOGGLE_SIZE_SMALL } from '@au/core/lib/components/elements/AuToggle';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuButton, { BUTTON_TYPE_PLAIN } from '@au/core/lib/components/elements/AuButton';

import SimpleTable, { Row, Cell, HeaderCell, ExpandableRow } from './SimpleTable';
import { formatMessage } from '../utils/reactIntl';
import PanelContent from "./PanelContent";

import styles from '../css/components/collapsible_details.module.scss';


const detailsPropTypes = {
  headerData: T.object,
  sections: T.arrayOf(T.shape({
    rawData: T.object,
    headerData: T.object,
    ...PanelContent.propTypes
  }))
};

export default class CollapsibleDetails extends React.Component {

  static propTypes = {
    headers: T.arrayOf(T.arrayOf(T.shape({
      className: T.string
    }))),
    details: T.arrayOf(T.shape({
      ...detailsPropTypes,
      details: T.arrayOf(T.shape({
        ...detailsPropTypes,
        details: T.array
      }))
    })),
    actions: T.element,
    disabled: T.bool,
    showControls: T.bool
  };

  static defaultProps = {
    headers: [],
    details: [],
    disabled: false,
    showControls: false
  }

  state = {
    filterInvalid: false
  }

  onExpandAllClick = this.onExpandAllClick.bind(this);
  onExpandAllClick() {
    this.toggleCollapse('collapsed');
  }

  onCollapseAllClick = this.onCollapseAllClick.bind(this);
  onCollapseAllClick() {
    this.toggleCollapse('expanded');
  }
  
  toggleCollapse(state) {
    // FIXME not great, but works
    document.querySelectorAll(`.${styles.row} [data-togglebtn="${state}"]`).forEach(el => el.click());
  }

  toggleInvalidFilter = this.toggleInvalidFilter.bind(this);
  toggleInvalidFilter() {
    this.setState(prevState => ({ filterInvalid: !prevState.filterInvalid }));
  }

  render() {
    const { headers, details, actions, disabled, showControls } = this.props;
    const { filterInvalid } = this.state;
    const [currentLevelHeaders, ...nextLevelHeaders] = headers; 
    const invalidCount = details?.filter(({ rawData }) => rawData.state === 'INVALID').length;
    
    return (
      <div className={styles.container}>
        { /* TODO move this out */ }
        { showControls && 
          <div className={styles.actions}>
            <AuButton
              type={BUTTON_TYPE_PLAIN}
              className={styles.action}
              displayId="au.entity.taps.validation.collapseAll"
              onClick={this.onCollapseAllClick}
            />
            <div className={styles.pipe} />
            <AuButton
              type={BUTTON_TYPE_PLAIN}
              className={styles.action}
              displayId="au.entity.taps.validation.expandAll"
              onClick={this.onExpandAllClick}
            />
            <AuToggle
              name="filter_invalid"
              value={filterInvalid}
              size={TOGGLE_SIZE_SMALL}
              className={styles.filter_invalid}
              switchClassName={styles.filter_invalid_switch}
              labelClassName={styles.filter_invalid_label}
              onChange={this.toggleInvalidFilter}
            />
            <AutoIntl
              displayId="au.entity.taps.validation.showInvalidOnly"
              className={styles.filter_invalid_caption}
            />
          </div>
        }
        <SimpleTable disabled={disabled} className={styles.table}>
          <Row className={styles.header_row}>
            <HeaderCell width={20} className={styles.cell} />
            {currentLevelHeaders.map(col => (
              <HeaderCell
                key={`header_${col.property}`}
                className={cn(styles.cell, col.options.className)}
                width={col.options.width}
              >{formatMessage({ id: col.labelId })}</HeaderCell>
            ))}
          </Row>
          {details.map(({ headerData, rawData, sections, details }, i) => headerData ? (
            <ExpandableRow
              index={i}
              key={`row_${i}`}
              id={`${headerData?.[currentLevelHeaders[0]?.property]}`}
              className={cn(styles.row, { [styles.hidden]: filterInvalid && rawData.state !== 'INVALID' })}
              cellClassName={styles.cell}
              expansionClassName={styles.expansion_row}
              expansionRenderer={() => (
                <PanelContent sections={sections} className={styles.content}>
                  {actions}
                  {details && <CollapsibleDetails
                    headers={nextLevelHeaders || [currentLevelHeaders]}
                    details={details}
                  />}
                </PanelContent>
              )}
            >
              {currentLevelHeaders.map((col) => (
                <Cell
                  key={`row_${i}_prop_${col.property}`}
                  width={col.options.width}
                  className={cn(styles.cell, col.options.className)}
                >{headerData[col.property]}</Cell>
              ))}
            </ExpandableRow>
          ) : null)}
          {filterInvalid && !invalidCount &&
            <Row className={styles.row}>
              <Cell className={styles.no_rows} colSpan={headers[0].length + 1}>
                <AutoIntl displayId="au.table.dataEmptyMessage" />
              </Cell>
            </Row>
          }
        </SimpleTable>
      </div>
    );
  }

}