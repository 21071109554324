import React from 'react';
import cn from 'classnames';

import StateIcon from './StateIcon';
import styles from '../css/components/status_badge.module.scss';

export default class StatusBadge extends React.PureComponent {

  static propTypes = StateIcon.propTypes;

  render() {
    const { icon, displayId } = this.props;

    return (
      <StateIcon
        {...this.props}
        className={cn(styles.badge, styles[icon] ?? styles.unknown)}
        displayId={displayId ?? `au.entity.state.${icon && icon.toUpperCase() || 'unknown'}`}
      />
    );
  }
}