import EntityLink from './EntityLink';

export default class UsernameEntityLink extends EntityLink {

  resolveEntityId() {
    const { lazyLoad } = this.props;
    return this.endpoint.list({ query: `username = '${this.props.entityId}'` })
      .then(resp => resp.items?.length ? resp.data.items[0][lazyLoad.targetProp] : Promise.reject(resp));
  }
}
