import { required, validUuid } from "../../../utils/validationRules";
import Lookup from "../Lookup";

export default class EmbeddedDeviceLookup extends Lookup {

  attrDisplayId = 'au.devices.deviceIdOrEsn';

  attrPlaceholderId = 'au.devices.lookupEmbeddedDevicePlaceholder';

  embedded = true;

  validate() {
    const { inputStr } = this.state;
    // required
    let validationError = required(inputStr);
    let idError = {};

    if (!validationError) {
      // is not uuid
      idError = validUuid(inputStr);
    }

    this.setState(prevState => ({
      showError: prevState.submitted && Boolean(validationError),
      error: {
        ...validationError,
        fieldDisplayId: this.attrDisplayId
      },
      isUuid: !idError
    }));

    return validationError;
  }

  lookup() {
    const { endpoint } = this.props;
    const { isUuid, inputStr } = this.state;

    if (isUuid) {
      return endpoint.get(inputStr, { includeRelations: 'binding', expandRelations: 'binding' });
    } else {
      return endpoint.getByUniqueProperty({
        uniquePropertyValue: inputStr,
        includeRelations: 'binding',
        expandRelations: 'binding'
      })
    }
  }
}