import React from 'react';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import ConfirmationDialog from '@au/core/lib/components/objects/ConfirmationDialog';

import DefaultView from '../View';
import ClientSecretDialog from '../../ClientSecretDialog';
import { clientType } from '../../../constants';

export default class ClientView extends DefaultView {

  hiddenFields = {
    'subjectAui': () => {
      const {entity} = this.props;
      return entity && entity.get('type')?.toUpperCase() !== clientType.CONFIDENTIAL_WITH_SERVICE_ACCOUNT;
    }
  };

  toggleResetSecretDialog = () => {
    this.setState(prevState => ({showResetDialog: !prevState.showResetDialog}));
  }

  toggleNewSecretDialog = () => {
    this.setState(prevState => ({showSecretDialog: !prevState.showSecretDialog}));
  }

  renderResetSecretDialog = () => {
    const {showResetDialog} = this.state;

    if (!showResetDialog) {
      return false;
    }

    return (
      <ConfirmationDialog
        key="client_reset_secret_dialog"
        type="alert"
        titleId="au.client.resetSecret.title"
        confirmDisplayId="au.client.resetSecret.confirm"
        onConfirm={this.resetClientSecret}
        onCancel={this.toggleResetSecretDialog}
      >
        <AutoIntl displayId="au.client.resetSecret.message1" tag="div"/>
        <AutoIntl displayId="au.client.resetSecret.message2" tag="div"/>
      </ConfirmationDialog>
    );
  }

  renderNewSecretDialog = () => {
    const {entity} = this.props;
    const {clientSecret, showSecretDialog} = this.state;

    if (!showSecretDialog) {
      return false;
    }

    return (
      <ClientSecretDialog
        key="client_new_secret_dialog"
        clientId={entity.get('clientId')}
        clientSecret={clientSecret}
        onClose={this.toggleNewSecretDialog}
      />
    );
  }

  resetClientSecret = () => {
    if (this.entityId) {
      const {endpoint} = this.props;

      this.toggleResetSecretDialog();

      endpoint.resetClientSecret(this.entityId)
        .then((response) => {
          this.setState({
            showSecretDialog: true,
            clientSecret: response.data.secret
          });
        }, this.genericErrorHandler);
    }
  }

  onResetSecretBtnClick = () => {
    const {entity} = this.props;

    if (entity) {
      this.setState({showResetDialog: true});
    }
  }

  renderDialogs() {
    const dialogs = super.renderDialogs();
    dialogs.push(this.renderResetSecretDialog());
    dialogs.push(this.renderNewSecretDialog());
    return dialogs;
  }

  // adds ResetClientSecret button (left to primary button if any)
  getActions() {
    const actions = super.getActions();
    const {entity} = this.props;
    const entityClientType = entity.get('type')?.toUpperCase();

    // Public clients do not have secrets
    if (entityClientType === clientType.PUBLIC) {
      return actions;
    }

    return actions;
  }
}
