import isEqual from 'lodash/isEqual';

import TMC from '@autonomic/browser-sdk';

import { policyType, policyGroup, knownPolicyProps, knownStatementProps } from '../../../constants';
import { history as browserHistory } from '../../../history';
import { getParentEntityAwarePath, getEntityUrlByArn } from '../../../utils/entity';
import { getPolicies, cleanupPolicy } from '../../../utils/policies';
import { parseAui } from '../../../utils/parse';
import { findUserByUsername, findClientByName } from '../../../utils/accounts';
import DefaultEdit from '../Edit';

const statementKeys = [...knownStatementProps, 'subjectAui', 'resourceAui'];

export default class PolicyEdit extends DefaultEdit {

  loadData() {
    const { entityDef, parentEntity, actions } = this.props;
    const { pkField } = entityDef;
    const includePermissions = !this.createMode;

    let props;
    if (entityDef.pkField === 'resourceAui') {
      props = {
        type: policyType.RESOURCE,
        resourceAui: this.getResourceAui()
      };
    } else {
      props = {
        type: policyType.SUBJECT,
        subjectAui: parentEntity.entity.get('subjectAui')
      };
    }

    return getPolicies({...props, pkField, group: policyGroup.DIRECT, includePermissions}).then(policies => {
      actions.listEntitiesSuccess({
        path: getParentEntityAwarePath(parentEntity, 'policies'),
        data: Object.values(policies),
        pkField
      });
    }, this.onAfterFetch).catch(this.genericErrorHandler);
  }

  getResourceAui() {
    const { parentEntity } = this.props;

    if (!parentEntity) {
      return '';
    }

    return (
      parentEntity.entityDef.arn ? parentEntity.entityDef.arn + '/' : ''
    ) + parentEntity.entityId;
  }

  onSaveSuccess = this.onSaveSuccess.bind(this);
  async onSaveSuccess(results) {
    const { match, location } = this.props;
    const { entityType, action } = match.params;
    let destinationUrl = location?.state?.prevUrl ?? this.parentUrl;

    if (action === 'replicate' && results[0]) {
      // when replicating - only one promise will be in response
      const createdPolicy = results[0];
      const attrName  = createdPolicy.type === policyType.SUBJECT ? 'subjectAui' : 'resourceAui';
      const policyAui = createdPolicy[attrName];
      const { arn, uuid, resource } = parseAui(policyAui);

      /*
        if we're able to retrieve a uuid from the created policy,
        let's redirect to that policy's details page for convenience
       */

      if (uuid) {
        destinationUrl = (
          getEntityUrlByArn(arn, entityType) +
          `/${uuid}/policies/${encodeURIComponent(policyAui)}/view`
        );
      }
      else if (arn === 'aui:iam:user' && resource) {
        // let's find out user/client uuid
        const clientName = resource.split('service-account-')[1];
        const client = clientName && (await findClientByName(clientName)).items[0];
        if (client) {
          destinationUrl = (
            getEntityUrlByArn(arn, 'client') +
            `/${client.id}/policies/${encodeURIComponent(policyAui)}/view`
          );
        }
        else {
          const user = (await findUserByUsername(resource)).items[0];
          if (user) {
            destinationUrl = (
              getEntityUrlByArn(arn, 'user') +
              `/${user.id}/policies/${encodeURIComponent(policyAui)}/view`
            );
          }
        }
      }
    }

    browserHistory.replace(destinationUrl);
  }

  handleOnSave = this.handleOnSave.bind(this);
  handleOnSave(entity) {
    return new Promise((resolve, reject) => {
      const saveProms = this.getUpdates(entity);
      if (saveProms.length) {
        return Promise.all(saveProms).then(resolve).catch(reject);
      } else {
        resolve();
      }
    })
    .then(this.onSaveSuccess);
  }

  getUpdates = this.getUpdates.bind(this);
  getUpdates(entity) {
    const { endpoint, parentEntity, actions, match } = this.props;
    // assuming `resource` type policy
    let policyAttrName = 'resourceAui';
    let statementAttrName = 'subjectAui';

    if (entity.type === policyType.SUBJECT) {
      // switch to `subject` type policy
      [statementAttrName, policyAttrName] = [policyAttrName, statementAttrName];
    }

    const updates = [];

    const permissionsToDelete = Object.values(entity.statements).filter(s => s._permissionId && s.deleted);
    const statementsToRevoke  = Object.values(entity.statements).filter(s => !s._permissionId && s.deleted);
    const otherStatements     = Object.values(entity.statements).filter(s => !s._permissionId && !s.deleted);

    if (permissionsToDelete.length) {
      updates.push(new TMC.services.Iam({ apiVersion: '1-alpha' }).permissions.bulkDelete({
        permissions: permissionsToDelete.map(p => ({ subjectAui: p.subjectAui, objectAui: p.resourceAui }))
      }));
    }
    if (statementsToRevoke.length) {
      updates.push(endpoint.revoke({
        [policyAttrName]: entity[policyAttrName],
        statements: statementsToRevoke.map(s =>
          ({ [statementAttrName]: s[statementAttrName], effect: s.effect })
        )
      }).then(() => {
        const aui = decodeURIComponent(match.params.entityId);
        // drop the whole policy for a specific aui, so it can be reloaded
        actions.deleteEntitySuccess({
          path: [...getParentEntityAwarePath(parentEntity, 'policies'), aui]
        });
      }));
    }
    if (otherStatements.length) {
      const { entity: originalEntity } = this.state;
      const isModified = statement => {
        const originalStatement = originalEntity.getIn(['statements', statement._id]).toJS();
        return statementKeys.some(key => {
          let equal = isEqual(originalStatement[key], statement[key]);
          // IAM service omits empty description
          return !(equal || typeof originalStatement[key] === 'undefined' && statement[key] === '');
        });
      };
      const statementsToGrant = otherStatements.filter(s => s.isNew || isModified(s) || this.createMode);

      if (statementsToGrant.length) {
        const policy = {
          type: entity.type,
          [policyAttrName]: entity[policyAttrName],
          statements: statementsToGrant
        };
        const cleanedPolicy = cleanupPolicy(
          policy,
          [...knownPolicyProps, policyAttrName],
          [...knownStatementProps, statementAttrName]
        );

        updates.push(endpoint.grant(cleanedPolicy).then(() => policy));
      }
    }

    return updates;
  }

}
