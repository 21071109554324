
import DefaultCreate from '../Edit';

export default class VehicleCreate extends DefaultCreate {
  getEntityProcessors() {
    const processors = super.getEntityProcessors();
    processors.push((entity) => {
      if (!entity.properties) {
        entity.properties = {};
      }
      entity.properties.vin = entity.vin;

      delete entity.vin;
      return entity;
    });
    return processors;
  }
}
