import React from 'react';
import cn from 'classnames';

import TMC from '@autonomic/browser-sdk';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import AuButton, { BUTTON_TYPE_PLAIN, BUTTON_TYPE_SECONDARY, BUTTON_TYPE_TERTIARY } from '@au/core/lib/components/elements/AuButton';
import { TOGGLE_SIZE_MEDIUM } from '@au/core/lib/components/elements/AuToggle';

import { TAP_VALIDATION_MODES, TAP_FILTERS_MODES, tapFilterType } from '../../../constants';
import { isValidTopology } from '../../../utils/topologyValidation';
import { formatDetails, formatHeaders } from '../../utils/mappers';
import { history as browserHistory } from '../../../history';
import TapValidationMessage from '../../TapValidationMessage';
import ValidationMessage from '../../ValidationMessage';
import CollapsibleDetails from '../../CollapsibleDetails';
import { formatFilter } from '../../utils/taps';
import DefaultView from "../View";

import styles from '../../../css/components/entity_view.module.scss';
import customStyles from '../../../css/components/tap_validation.module.scss';

const columns = Object.freeze({
  sequence: { className: customStyles.sequence },
  filterType: { className: customStyles.filter_type }, 
  validity: { className: customStyles.validity }
});

const subColumns = Object.freeze({
  name: { className: customStyles.name }, 
  validationStatus: { className: customStyles.validation_status }
});

export default class TapValidation extends DefaultView {

  filterFormatters = {
    'groupFilter': {
      details: [{ func: 'groupFilterDetails' }]
    }
  }

  componentPrefix = "au.entity.taps.validation"

  topologyEndpoint = new TMC.services.Topology({ apiVersion: '1' }).topology;

  constructor(props) {
    super(props);

    const validationResults = props.entity.get('validationResults')?.toJS();
    
    this.state = {
      ...super.state,
      valid: validationResults ? isValidTopology(validationResults) : null,
      validating: validationResults ? false : null,
      results: validationResults
    };
  }

  componentDidMount() {
    super.componentDidMount();

    const { location } = this.props;
    if (location?.state?.runValidation) {
      this.validateTopology();
    }
  }

  renderContentAbove() {
    return false;
  }

  onManageFiltersClick = this.onManageFiltersClick.bind(this);
  onManageFiltersClick() {
    browserHistory.push(`${this.baseUrl}/${encodeURIComponent(this.entityId)}/manage-filters`);
  }

  renderSection() {
    const { filterType, entity } = this.props;
    const { results } = this.state;
    const filterTypes = filterType || Object.values(tapFilterType);
    const filters = entity.get('filters')?.toJS() ?? [];
    const resultsPlaceholder = filters.map(filter => ({
      filterType: Object.entries(tapFilterType).find(([_, value]) => value in filter)[0], // eslint-disable-line no-unused-vars
      filter: { sequence: filter.sequence },
      state: null
    }));
    const hasData = Boolean(results);

    return (
      <>
        <AutoIntl
          displayId={`${this.componentPrefix}.details.filterConfiguration`}
          className={customStyles.title}
          tag="h2"
        />
        <TapValidationMessage  {...{
          filters: this.props.entity.get('filters').toJS().map((filter, id) => {
            const filterType = filterTypes.find(filterType => filterType in filter); 
            return formatFilter({
              id, filterType, filter, filterFormatters: this.filterFormatters[filterType]
            });
          }) ?? [],
          mode: TAP_FILTERS_MODES.VIEW,
          denyAttributeTags: this.props.entity.get('denyAttributeTags'),
          validationModes: [
            TAP_VALIDATION_MODES.MEMBERSHIP,
            TAP_VALIDATION_MODES.ATTRIBUTE,
            TAP_VALIDATION_MODES.WILDCARD,
            TAP_VALIDATION_MODES.ATTRIBUTE_TAGS
          ]
        }} />
        <div className={styles.top_gap_50}>
          <AutoIntl
            className={cn(customStyles.title, styles.no_margin)}
            displayId={`${this.componentPrefix}.details.topologyValidation`}
            tag="h2"
          />
          {this.renderActions(this.state.validating)}
        </div>
        <ValidationMessage {...{
          prefixMsg: `${this.componentPrefix}.topology.message`,
          infoMsg: "info.withLink",
          validMsg: "success",
          invalidMsg: "invalid",
          inProgressMsg: "validating",
          valid: this.state.valid,
          inProgress: this.state.validating,
          values: {
            b: (txt) => <strong>{txt}</strong>,
            a: (txt) => <a onClick={this.onValidateBtnClick}>{txt}</a>
          }
        }} />
        <CollapsibleDetails
          headers={[formatHeaders(
            columns, 
            `${this.componentPrefix}.topology.filterDetails.headers`
          ), formatHeaders(
            subColumns,
            `${this.componentPrefix}.topology.filterDetails.headers`
          )]}
          details={formatDetails(this.state.results || resultsPlaceholder, [columns, subColumns])}
          actions={(
            <div className={customStyles.filter_actions}>
              <AuButton
                type={BUTTON_TYPE_TERTIARY}
                size={TOGGLE_SIZE_MEDIUM}
                className={customStyles.manage_filters}
                displayId="au.taps.manageFilters"
                onClick={this.onManageFiltersClick}
              />
            </div>
          )}
          disabled={!hasData}
          showControls={hasData}
        />
      </>
    );
  }

  onViewJsonBtnClick = this.onViewJsonBtnClick.bind(this);
  onViewJsonBtnClick() {
    const { actions } = this.props;

    AuAnalytics.trackEvent({
      category: 'Button',
      action: 'ViewJson',
      label: 'TapValidation'
    });

    actions.openEntityJsonViewer(
      this.entityId,
      this.state.results,
      {
        tabsClassName: styles.json_viewer_tabs,
        panelClassName: styles.json_viewer_panel
      }
    );
  }

  onValidateBtnClick = this.onValidateBtnClick.bind(this);
  onValidateBtnClick() {
    AuAnalytics.trackEvent({
      category: 'Button',
      action: 'Validate',
      label: 'TapValidation'
    });

    this.validateTopology();
  }

  validateTopology = this.validateTopology.bind(this);
  validateTopology() {
    const { entity } = this.props;

    this.setState({ validating: true });

    this.topologyEndpoint.validate({
      id: entity.get("id"),
      name: entity.get("displayName"),
      entityType: "TAP"
    }).then(({ data }) => this.setState({
      valid: isValidTopology(data.results),
      validating: false,
      results: data.results
    })).catch(this.genericErrorHandler);
  }

  getActions() {
    const actions = [];

    actions.push({
      key: 'tap_validate_btn',
      type: BUTTON_TYPE_SECONDARY,
      displayId: `${this.componentPrefix}.validate`,
      onClick: this.onValidateBtnClick
    });

    return actions;
  }

  renderExtraButtons() {
    return (
      <AuButton
        type={BUTTON_TYPE_PLAIN}
        displayId={`${this.componentPrefix}.jsonViewer`}
        disabled={!this.state.results}
        className={styles.view_json}
        onClick={this.onViewJsonBtnClick}
      />
    );
  }
}
