import React from "react";
import { injectIntl } from "react-intl";
import cn from "classnames";
import * as T from "prop-types";

import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import SelectableList from "@au/core/lib/components/elements/SelectableList";

import { NOOP } from "../../constants";
import { Filter } from "../../AuPropTypes";

import styles from "../../css/components/filters/failure_mode.module.scss";

function toFilters(strings) {
  return strings.map(toFilter);
}

function toFilter(string) {
  return ({displayId: `au.failureMode.types.${string}`, value: string});
}

export const failureModes = {
  beforeDeliveryFailure: toFilters([ "UNKNOWN_FAILURE_TYPE", "INVALID_COMMAND_PAYLOAD_FAILURE", "INTERNAL_TMC_ERROR", "PRECONDITION_FAILURE", "OEM_CLOUD_TRANSLATION_FAILURE", "OEM_CLOUD_DELIVERY_FAILURE", "DEVICE_DELIVERY_FAILURE"]),
  afterDeliveryFailure: toFilters([ "UNKNOWN_FAILURE_TYPE", "DEVICE_VALIDATION_FAILURE", "DEVICE_EXECUTION_FAILURE", "INVALID_DEVICE_RESPONSE_FAILURE"]),
  beforeDeliveryExpiration: toFilters([ "UNKNOWN_EXPIRATION_TYPE", "DEVICE_NEVER_CONNECTED", "PRIOR_COMMANDS_IN_PROGRESS", "OEM_CLOUD_DELIVERY_TIMEOUT", "EXPIRED_DURING_DELIVERY"]),
  afterDeliveryExpiration: toFilters([ "UNKNOWN_EXPIRATION_TYPE", "DEVICE_NEVER_RESPONDED"]),
};

export const preclusion = {
  firmwareUpdate: toFilter("COMMANDS_PRECLUDED_BY_FIRMWARE_UPDATE"),
  deepSleep: toFilter("COMMANDS_PRECLUDED_BY_DEEP_SLEEP"),
  carrierRegistration: toFilter("COMMANDS_PRECLUDED_BY_CARRIER_REGISTRATION"),
  temperatureThreshold: toFilter("COMMANDS_PRECLUDED_BY_TEMPERATURE_THRESHOLD"),
};
const preclusionFilters = Object.values(preclusion);

const preclusionUrlKey = "preclusion";
const failureModeUrlKey = "failureMode";
export const showAll = "showAll";


class FailureMode extends React.Component {

  static propTypes = {
    selection: T.arrayOf(Filter),
    multiSelect: T.bool,
    onChange: T.func,
    onInit: T.func
  };

  static defaultProps = {
    selection: [],
    multiSelect: true,
    onChange: NOOP,
    onInit: NOOP
  };

  constructor(props) {
    super(props);
    const failureModeFilters = this.getInitialFilters({
      props,
      showAllUrlKey: failureModeUrlKey,
      showAllDisplayId: "au.failureMode.allFailureModes",
      itemKeys: Object.keys(failureModes),
      generateItemBubbleText: (item) => this.generateFailureModeBubbleText(`au.failureMode.${item.urlKey}`, `au.failureMode.types.${item.urlValue}`)
    });
    const preclusionFilters = this.getInitialFilters({
      props,
      showAllUrlKeys: preclusionUrlKey,
      showAllDisplayId: "au.failureMode.allPreclusions",
      itemKeys: [preclusionUrlKey],
      generateItemBubbleText: (item) => this.generatePreclusionBubbleText(`au.failureMode.types.${item.urlValue}`)
    });
    props.onInit([...failureModeFilters, ...preclusionFilters]);
  }

  getInitialFilters = this.getInitialFilters.bind(this);
  getInitialFilters({props, showAllUrlKey, showAllDisplayId, itemKeys, generateItemBubbleText}) {
    const {selection, intl} = props;

    if (selection.some(item => item.urlKey === showAllUrlKey && item.urlValue === showAll)) {
      return [{
        urlKey: showAllUrlKey,
        urlValue: showAll,
        bubbleText: intl.formatMessage({id: showAllDisplayId})
      }];
    } else {
      const matchingFilters = selection.filter(item => itemKeys.includes(item.urlKey));
      return matchingFilters.map((item) => {
        return {
          urlKey: item.urlKey,
          urlValue: item.urlValue,
          bubbleText: generateItemBubbleText(item)
        };
      });
    }
  }

  toggleShowAll = this.toggleShowAll.bind(this);
  toggleShowAll(showAllUrlKey, showAllDisplayId, isPreclusion) {
    const {selection, onChange, intl} = this.props;

    if (selection.some(item => item.urlKey === showAllUrlKey && item.urlValue === showAll)) {
      onChange(selection.filter(item => item.urlKey !== showAllUrlKey));
    } else {
      onChange([
        {
          urlKey: showAllUrlKey,
          urlValue: showAll,
          bubbleText: intl.formatMessage({id: showAllDisplayId})
        },
        ...selection.filter(item => isPreclusion ? item.urlKey !== preclusionUrlKey : item.urlKey === preclusionUrlKey)
      ]);
    }
  }

  onChange(items, failureModeName) {
    const {selection, onChange} = this.props;

    const otherFilters = selection.filter(item => item.urlKey !== `${failureModeName}` && item.urlKey !== failureModeUrlKey);
    const currentFilter = items.map((item) => {
      return ({
        urlKey: failureModeName,
        urlValue: item.value,
        bubbleText: this.generateFailureModeBubbleText(`au.failureMode.${failureModeName}`, item.displayId)
      });
    });

    onChange([...currentFilter, ...otherFilters]);
  }

  onChangePreclusion(items) {
    const {selection, onChange} = this.props;

    const otherFilters = selection.filter(item => item.urlKey !== preclusionUrlKey);
    const currentFilter = items.map((item) => {
      return ({
        urlKey: preclusionUrlKey,
        urlValue: item.value,
        bubbleText: this.generatePreclusionBubbleText(item.displayId)
      });
    });

    onChange([...otherFilters, ...currentFilter]);
  }

  generateFailureModeBubbleText = this.generateFailureModeBubbleText.bind(this);
  generateFailureModeBubbleText(keyDisplayId, valueDisplayId) {
    const {intl} = this.props;
    const failureModeKey = intl.formatMessage({id: keyDisplayId});
    const failureModeValue = intl.formatMessage({id: valueDisplayId});
    return `${failureModeKey}: "${failureModeValue}"`;
  }

  generatePreclusionBubbleText = this.generatePreclusionBubbleText.bind(this);
  generatePreclusionBubbleText(valueDisplayId) {
    const {intl} = this.props;
    const itemName = intl.formatMessage({id: valueDisplayId});
    return `${itemName}: true`;
  }

  render() {
    const { selection } = this.props;
    const showAllFailureModes = selection.some(item => item.urlKey === failureModeUrlKey);
    const showAllPreclusions = selection.some(item => item.urlKey === preclusionUrlKey && item.urlValue === showAll);

    return (
      <div className={styles.container}>
        <AutoIntl
          className={cn(styles.showAll, {[styles.checked]: showAllFailureModes})}
          displayId="au.failureMode.allFailureModes"
          tag="button"
          onClick={() => this.toggleShowAll(failureModeUrlKey, "au.failureMode.allFailureModes")}
        />
        { Object.keys(failureModes).map(failureModeName => (
          <React.Fragment key={failureModeName}>
            <AutoIntl className={styles.subheader} displayId={`au.failureMode.${failureModeName}Type`} tag="p"/>
            <SelectableList
              {...this.props}
              items={failureModes[failureModeName]}
              selection={selection.filter(item => item.urlKey === `${failureModeName}`).map(item => item.urlValue)}
              onChange={(items) => this.onChange(items, failureModeName)}
              onInit={NOOP}
            />
          </React.Fragment>
        ))}
        <React.Fragment>
          <AutoIntl className={styles.subheader} displayId={`au.failureMode.preclusionType`} tag="p"/>
          <AutoIntl
            className={cn(styles.showAll, {[styles.checked]: showAllPreclusions})}
            displayId="au.failureMode.allPreclusions"
            tag="button"
            onClick={() => this.toggleShowAll(preclusionUrlKey, "au.failureMode.allPreclusions", true)}
          />
          <SelectableList
            {...this.props}
            items={preclusionFilters}
            selection={selection.filter(item => item.urlKey === preclusionUrlKey).map(item => item.urlValue)}
            onChange={(items) => this.onChangePreclusion(items)}
            onInit={NOOP}
          />
        </React.Fragment>
      </div>
    );
  }
}

export default injectIntl(FailureMode);
