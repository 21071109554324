import React from 'react';
import * as T from 'prop-types';
import { NOOP } from '../../constants';
import shared from '../../shared';
import { SelectableListProps } from '../../AuPropTypes';
import FilterSelectableList from "./FilterSelectableList";
export default class BusinessUnit extends React.Component {

  static propTypes = {
    defaultValue: SelectableListProps.defaultValue,
    containerRef: SelectableListProps.containerRef,
    selection: SelectableListProps.selection,
    sort: SelectableListProps.sort,
    multiSelect: T.bool,
    onChange: T.func,
    onInit: T.func
  }

  static defaultProps = {
    onChange: NOOP,
    onInit: NOOP,
    selection: []
  }

  state = { businessUnitItems: [] }

  componentDidMount() {
    const { requiredTags=[] } = shared.account;
    const businessUnit = requiredTags.find(item =>
      item.key === 'BusinessUnit'
    );

    if (businessUnit) {
      const businessUnitItems = businessUnit.values.map(value => {
        return { displayString: value, value };
      });
      this.setState({ businessUnitItems });
    }
  }

  render() {
    const { businessUnitItems } = this.state;

    if (!businessUnitItems.length) {
      this.props.onInit([]);
      return false;
    }

    return (
      <FilterSelectableList
        containerRef={this.props.containerRef}
        items={businessUnitItems}
        sort={this.props.sort}
        defaultValue={this.props.defaultValue}
        selection={this.props.selection}
        onChange={this.props.onChange}
        onInit={this.props.onInit}
      />
    );
  }
}
