import apiFactory from "./api";

export function getNewFlowEntities(outputCreate, actions)
{
  const entitiesToCreate = new Map();

  const flowEndpoint = apiFactory('feed-service', 'flows', actions);
  if (outputCreate.length > 0) {
    outputCreate.map((output) => {
      let flowData = output.shardCount ? { displayName: output.displayName, shardCount: output.shardCount } : { displayName: output.displayName }

      if (!output.foundSelection && output.foundSelection !== null) {
        entitiesToCreate.set(
          `feed-service-${output.displayName}`,
          {
            entities: [{
              key: `flowName-${output.displayName}`,
              idProp: flowEndpoint.idProp,
              data: flowData,
              saveFn: (data) => flowEndpoint.create(data),
              halt: true,
              displayFields: ['displayName', 'id']
            }],
            serviceAlias: 'feed-service',
            entityType: 'flow',
            entityAlias: 'flows'
          }
        );
      }
    });
  }

  return { entitiesToCreate };
}