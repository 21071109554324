import React from 'react';
import upperFirst from 'lodash/upperFirst';

import { BUTTON_TYPE_SECONDARY } from '@au/core/lib/components/elements/AuButton';

import { TOPOLOGY_PATH } from '../../../constants';
import { history as browserHistory } from '../../../history';
import { wrapActionWithTracking } from '../../../utils/analyticsHelpers';
import FeedTopologyFragment from '../../FeedTopologyFragment';
import DefaultView from '../View';

import styles from '../../../css/components/entity_view.module.scss';

export default class FeedCommonView extends DefaultView {

  state = {
    ...super.state,
    viewHidden: false
  }

  queryParams = this.props.entityDef.queryParams;

  onViewTopology = this.onViewTopology.bind(this);
  onViewTopology() {
    const { entity } = this.props;
    // TODO: leonid - when this.entityId is undefined?
    const entityId = !this.entityId ? entity.get('aui') : this.entityId;

    //FIXME - MOVE TO linkHelper
    browserHistory.push(`${TOPOLOGY_PATH}/${entityId}`);
  }

  getActions() {
    const { entityDef, popoutContained } = this.props;
    let actions = super.getActions();
    
    // No need if already on the topology page!
    if (!popoutContained) {
      const viewInTopology = wrapActionWithTracking(
        {
          key: 'view_topology_btn',
          type: BUTTON_TYPE_SECONDARY,
          className: styles.button,
          displayId: 'au.entity.viewTopology',
          onClick: this.onViewTopology
        },
        upperFirst(entityDef.type),
        'View'
      );

      actions.unshift(viewInTopology);
    }

    return actions;
  }

  hideDetailsOnFullRender = this.hideDetailsOnFullRender.bind(this);
  hideDetailsOnFullRender(shouldHide) {
    this.setState({ viewHidden: shouldHide });
  }

  renderTopologyFragment() {
    // No need to render if we're already on topology
    if (this.props.popoutContained) return false;

    return (
      <FeedTopologyFragment
        key={'topology-fragment'}
        hideDetailsOnFullRender={this.hideDetailsOnFullRender}
        {...this.props}
      />
    );
  }

  renderBreadcrumbs() {
    if (this.props.popoutContained) {
      return <></>;
    }
    return super.renderBreadcrumbs();
  }

  renderContentAboveTable() {
    if (typeof this.renderFeedEventLink === 'function' && this.props.popoutContained) {
      return (
        <div className={styles.link}>
          { this.renderFeedEventLink() }
          { super.renderContentAboveTable() }
        </div>
      );
    } else if (typeof this.renderFeedEventLink === 'function') {
      return (
        <div className={styles.link}>
          { this.renderFeedEventLink() }
        </div>
      );
    } else if(this.props.popoutContained) {
      return super.renderContentAbove();
    }

    return [];
  }

  renderTableRows() {
    const { viewHidden } = this.state;

    if (viewHidden) {
      return false;
    }

    return super.renderTableRows();
  }
}