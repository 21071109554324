/**
 * This file contains general purpose reducers.
 * At some point we might want split it into: Nav, System, etc. reducers.
 */

export default {
  SAVE_USER_DATA: (state, action) => {
    return state.setIn(['userData', ...action.path], action.data);
  },
  SET_LOCALE: (state, action) => {
    return state.set('locale', action.locale);
  },
  SET_SYSTEM_OF_UNITS: (state, action) => {
    // Currently conversion is done as data comes in so this should be set
    // once on initial application load.
    return state.set('systemOfUnits', action.systemOfUnits);
  },
  SET_PAGE_TITLE: (state, action) => {
    return state.set('pageTitle', action.title);
  },
  SET_PAGE_SCHEMA: (state, action) => {
    return state.set('pageSchema', action.schema);
  },
  SCREEN_WIDTH_CHANGED: (state, action) => {
    return state.set('screenWidth', action.width);
  },
  "NOOP": (state => state),
};
