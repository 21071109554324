import DefaultList from "../List";

export default class RawMessageList extends DefaultList {

  list() {
    const { endpoint, pageSize, parentEntity } = this.props;
    const {startTime, endTime} = this.state.filterQueryParams;

    const messagesEndpoint = endpoint.getMessagesEndpoint(parentEntity.entity.getIn(["properties", "vin"]));

    return messagesEndpoint.search({pageSize, startTime, endTime});
  }

}