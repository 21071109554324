import Access from '@au/core/lib/utils/access';
import shared from '../shared';

let _access;

export function setAccess(token) {
  _access = new Access(token);
}

export function hasAccess() {
  return _access.has(...arguments);
}

export function getAccess() {
  return _access;
}

export function isAuMember() {
  return ['autonomic', 'account-1'].includes(shared.authClient.docAccess);
}
