import React from 'react';
import cn from 'classnames';
import { BUTTON_TYPE_SECONDARY } from '@au/core/lib/components/elements/AuButton';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import LoadingIndicator from '@au/core/lib/components/elements/LoadingIndicator';

import { history as browserHistory } from '../../../history';
import { wrapActionWithTracking } from '../../../utils/analyticsHelpers';
import formatters from '../../../utils/formatters';
import { isAuMember } from '../../../utils/accessFunctions';
import { get } from '../../../utils/api';
import LastFtcpMessage from '../../LastFtcpMessage';
import DefaultView from '../View';

import styles from '../../../css/components/entity_view.module.scss';
import customStyles from '../../../css/components/vehicle_view.module.scss';

export default class VehicleView extends DefaultView {

  queryParams = { includeRelations: 'bindings', expandRelations: 'bindings' }

  constructor(props) {
    super(props);

    // must be set in the contructor so DefaultView state is not overwritten
    this.state = {
      ...this.state,
      fetchingLastTimestamp: false,
      timestampError: false,
      lastTimestamp: false
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchLastTimestamp();
  }

  fetchLastTimestamp = this.fetchLastTimestamp.bind(this);
  fetchLastTimestamp() {
    this.setState({fetchingLastTimestamp: true});

    // try to use more accurate alpha endpoint first
    get(`/v1alpha/troubleshoot-consumer/vehicles/${this.entityId}`)
      .catch(() => get(`/v1beta/troubleshoot-consumer/vehicles/${this.entityId}`))
      .then(result => {
        const timestamp = result.data.lastTimestamp;
        if (timestamp) {
          this.setState({
            fetchingLastTimestamp: false,
            timestampError: false,
            lastTimestamp: timestamp
          });
        }
      }).catch(result => {
        if (result.status === 404) {
          this.setState({
            fetchingLastTimestamp: false,
            timestampError: false,
            lastTimestamp: false
          });
        }
        else {
          this.setState({
            fetchingLastTimestamp: false,
            timestampError: true,
            lastTimestamp: false
          });
        }
      });
  }

  loadData = this.loadData.bind(this);
  loadData() {
    const { entity, endpoint } = this.props;

    // Ensure we get relations when navigating between cached data
    // Saw this issue when navigating between device and vehicle event bindings where
    // we would render but relations were missing
    if (entity.size && !entity.getIn(['relations', 'bindings'])) {
      return endpoint.get(this.entityId, this.queryParams).then(this.onAfterFetch, this.genericErrorHandler);
    }

    return super.loadData().catch(this.genericErrorHandler);
  }

  onManageBindingsBtnClick = this.onManageBindingsBtnClick.bind(this);
  onManageBindingsBtnClick() {
    //FIXME - MOVE TO linkHelper
    browserHistory.push(this.baseUrl + `/${this.entityId}/manage-bindings`);
  }

  getActions() {
    const actions = super.getActions();

    const extraActions = [{
      key: 'manage_bindings',
      type: BUTTON_TYPE_SECONDARY,
      className: styles.button,
      displayId: 'au.vehicle.manageBindings',
      onClick: this.onManageBindingsBtnClick
    }];

    extraActions.forEach(action => {
      actions.splice(1, 0, wrapActionWithTracking(action, 'Vehicle', 'View'));
    });

    return actions;
  }

  lastTimestampRowContent() {
    const { fetchingLastTimestamp, timestampError, lastTimestamp } = this.state;

    if (fetchingLastTimestamp) {
      return (
        <tr className={cn(styles.row, customStyles.loading_row)} ref={ref => this.rowRef = ref} key="_lastTimestamp" >
          <td className={customStyles.loading}>
            <LoadingIndicator className={customStyles.spinner} displayId="au.vehicle.lastTimestampLoading" />
          </td>
        </tr>
      );
    }
    else if (timestampError) {
      return (
        <tr className={cn(styles.row, customStyles.error_row)} ref={ref => this.rowRef = ref} key="_lastTimestamp" >
          <td className={customStyles.timestampError}>
            <AutoIntl
              displayId="au.vehicle.lastTimestampError"
              values={{
                a: (...chunks) => <a onClick={this.fetchLastTimestamp}>{chunks}</a>
              }}
            />
          </td>
        </tr>
      );
    }
    else if (!lastTimestamp) {
      return (
        <tr className={styles.row} ref={ref => this.rowRef = ref} key="_lastTimestamp" >
          <td className={styles.fieldname}>
            <AutoIntl displayId="au.vehicle.lastTimestamp" />
          </td>
          <td className={styles.fieldvalue}>
            <AutoIntl
              displayId="au.vehicle.lastTimestampNoData"
              className={customStyles.timestampNoData}
            />
          </td>
        </tr>
      );
    }
    else {
      const ltObj = {
        value: new Date(isNaN(lastTimestamp) ? lastTimestamp : lastTimestamp * 1000),
        timezone: this.props.timezone
      };

      return (
        <tr className={styles.row} ref={ref => this.rowRef = ref} key="_lastTimestamp" >
          <td className={styles.fieldname}>
            <AutoIntl displayId="au.vehicle.lastTimestamp" />
          </td>
          <td className={styles.fieldvalue}>
            {formatters.dateWithRelative(ltObj)}
          </td>
        </tr>
      );
    }

  }

  renderTableRows() {
    const tableRows = super.renderTableRows();
    const extraRows = [];

    extraRows.push(<tr className={styles.spacer} key="_spacer" />);
    extraRows.push(this.lastTimestampRowContent());

    return [...tableRows, ...extraRows];
  }

  renderAdditionalContent() {
    let content = super.renderAdditionalContent();

    // Only display for Au account members
    if (isAuMember()) {
      const { entity } = this.props;
      const vin = entity.getIn(['properties', 'vin']);

      content.push(
        <LastFtcpMessage key="lastFtcpMessage" vin={vin} />
      );
    }

    return content;
  }

}
