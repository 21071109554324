import { fromJS } from 'immutable';
import { isEmpty } from "lodash";

import TMC from '@autonomic/browser-sdk';

import {
  tapFilterType,
  attributeFilterTypes,
  permittedFilterTypes,
  membershipFilterTypes,
  signalFilterTypes,
  FILTER_PERMIT,
  UUID_RE,
  VIN_RE
} from '../../constants';

export function hasPermittedFilter(filters) {
  return filters.some(filter =>
    permittedFilterTypes.includes(filter.filterType) && filter.type == FILTER_PERMIT ||
      Object.keys(filter).some(k => permittedFilterTypes.includes(k)) && filter.type == FILTER_PERMIT
  );
}

export function hasAttributeFilter(filters) {
  return filters.some(filter =>
    attributeFilterTypes.includes(filter.filterType) && filter.type == FILTER_PERMIT ||
      Object.keys(filter).some(k => attributeFilterTypes.includes(k)) && filter.type == FILTER_PERMIT
  );
}

export function hasAttributeTagFilter(filters) {
  return filters.some(filter =>
    filter.filterType === tapFilterType.ATTRIBUTE_TAG ||
      Object.keys(filter).includes(tapFilterType.ATTRIBUTE_TAG)
  );
}

export function hasMembershipFilter(filters) {
  return filters.some(filter =>
    membershipFilterTypes.includes(filter.filterType) && filter.type == FILTER_PERMIT && filter.filterType !== tapFilterType.GROUP && filter.filterType !== tapFilterType.VIN ||
    membershipFilterTypes.includes(filter.filterType) && filter.type == FILTER_PERMIT && filter.filterType === tapFilterType.GROUP && UUID_RE.test(filter.details.groupId) ||
    membershipFilterTypes.includes(filter.filterType) && filter.type == FILTER_PERMIT && filter.filterType == tapFilterType.VIN && filter.details.vins?.length !== 0 && filter.details.vins?.every(isValidVin) ||
    Object.keys(filter).some(k => membershipFilterTypes.includes(k)) && filter.type == FILTER_PERMIT && !filter[tapFilterType.GROUP] && !filter[tapFilterType.VIN] ||
    Object.keys(filter).some(k => membershipFilterTypes.includes(k)) && filter.type == FILTER_PERMIT && filter[tapFilterType.GROUP] && UUID_RE.test(filter[tapFilterType.GROUP].groupId) ||
    Object.keys(filter).some(k => membershipFilterTypes.includes(k)) && filter.type == FILTER_PERMIT && filter[tapFilterType.VIN] && filter[tapFilterType.VIN].vins?.length !== 0 && filter[tapFilterType.VIN].vins?.every(isValidVin)
  )
}

export const isValidVin = (vin) => VIN_RE.test(vin)

export function hasSignalFilter(filters) {
  return filters.some(filter =>
    signalFilterTypes.includes(filter.filterType) && filter.type == FILTER_PERMIT ||
      Object.keys(filter).some(k => signalFilterTypes.includes(k)) && filter.type == FILTER_PERMIT
  );
}

export function hasAttributeWildcardUri(filters) {
  // checking the name should be sufficient, assuming there is only one filter of this type
  return filters.some(filter => filter.details.name === 'uri' && filter.details.value.includes('*'));
}

export function hasValidAttributeWildcardUri(filters) {
  // checking the name should be sufficient, assuming there is only one filter of this type
  const uriFilters = filters.filter(filter => filter.details.name === 'uri');

  return !uriFilters.some(filter =>
    filter.details.value.split('*').length !== filter.details.value.split('.*').length
  );
}

export function isValidTap(filters, denyAttributeTags) {
  if (!hasSignalFilter(filters)) {
    // it's already invalid, skip other checks
    return false;
  }

  if (hasAttributeTagFilter(filters) && !denyAttributeTags) {
    // it's already invalid, skip other checks
    return false;
  }

  if (!hasMembershipFilter(filters)) {
    return false;
  }

  return true;
}

export function isValidOutput(filters) {
  if (!hasMembershipFilter(filters)) {
    return undefined;
  }
  const hasValidPermitFilter = filters?.some(filter => filter.type === FILTER_PERMIT && !isEmpty(filter.details));
  return hasValidPermitFilter || undefined;
}

export function formatFilter({
  id, isNew, filter, filterType, filterFormatters
}) {
  return Object.assign({}, filter, {
    isNew,
    origSequence: filter.sequence,
    details: filter[filterType],
    filterFormatters,
    filterType,
    id
  });
}

export async function handleTapFiltersReplication(entity, isSamePartition) { 
  // drop VIN filters, as they shouldn't be replicated
  let filters = entity.get('filters').filter(filter => !filter.has('vinFilter'));

  if (!isSamePartition) {  
    const groupsEndpoint = new TMC.services.Inventory({ apiVersion: '1-beta' }).groups;

    filters = fromJS(await Promise.all(filters.map(async filter => {
      // GroupId cannot be replicated between partitions
      if (filter.has('groupFilter')) {
        const groupId = filter.getIn(['groupFilter', 'groupId']);
        let groupName = '';

        if (groupId) {
          groupName = (await groupsEndpoint.get(groupId))?.data?.displayName ?? '';
        }

        return filter
                .setIn(['groupFilter', 'groupName'], groupName)
                .removeIn(['groupFilter', 'groupId']);
      }

      return filter;
    })));
  }

  return entity.set('filters', filters);
}
