import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import { FormattedMessage } from 'react-intl';

import TMC from '@autonomic/browser-sdk';
import AuChips from '@au/core/lib/components/elements/AuChips';
import AuDropDown from '@au/core/lib/components/elements/AuDropDown';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { NOOP } from '../constants';
import { formatMessage } from '../utils/reactIntl';

import { noListDuplicates } from '../utils/validationRules';
import { run } from '../utils/validationRuleRunner';

import styles from '../css/components/entitlement_editor.module.scss';
import Banner from "./Banner";

const validationRules = [ noListDuplicates('') ];
const regExpToValidateSubjectAui = RegExp('^aui:iam:user/.+$');
const regExpToValidateResourceAui = RegExp('^aui:.+:.+$');

// currently supported separators are: comma, semicolon and white space
const SEPARATOR_RE = /[,;\s]/;

export default class EntitlementEditor extends React.Component {

  static propTypes = {
    subjectAui: T.string,
    resourceAui: T.string,
    entitlements: T.array,
    className: T.string,
    createMode: T.bool,
    disabled: T.bool,
    onChange: T.func,
    showErrors: T.bool,
    showStatementExistsWarning: T.bool,
    error: T.object
  }

  static defaultProps = {
    entitlements: [],
    createMode: false,
    showStatementExistsWarning: false,
    onChange: NOOP
  }

  state = {
    errors: [],
    counter: 0,
    suggestions: [],
    entitlements: this.props.entitlements
  }

  storedEntitlements = {}

  entitlementOptions = []

  componentDidMount() {
    const { resourceAui, entitlements, createMode } = this.props;
    this.iamServiceV2 = new TMC.services.Iam({ apiVersion: 2 });
    if (!createMode) {
      this.storedEntitlements = entitlements.reduce((acc, entitlement) => {
        acc[entitlement] = null;
        return acc;
      }, {});
    }
    this.loadEntitlementOptions(resourceAui);
    this.validate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resourceAui !== this.props.resourceAui) {
      this.loadEntitlementOptions(this.props.resourceAui);
    }
    if (this.props.resourceAui && this.props.subjectAui && (
        prevProps.resourceAui !== this.props.resourceAui || prevProps.subjectAui !== this.props.subjectAui
    )) {
      this.loadExistingEntitlements(this.props.subjectAui, this.props.resourceAui);
    }
  }

  loadEntitlementOptions = debounce(this.loadEntitlementOptions, 500).bind(this);
  loadEntitlementOptions(resourceAui, action) {
    if (this.props.disabled) return;

    if (!resourceAui) {
      this.entitlementOptions = [];
      this.setState({ suggestions: [] }, this.validate);
    } else {
      this.iamServiceV2.roles.suggest({ resourceAui, action }).then(resp => {
        this.entitlementOptions = [
          ...(resp.data.actions || []).map(({ name }) => ({
            displayString: name,
            val: name
          })),
          ...(resp.data.roles || []).map(role => ({
            displayString: role.aui,
            val: role.aui
          }))
        ];
        this.setState({ suggestions: this.entitlementOptions.map(o => o.val) }, this.validate);
      }, createResponseAlertMessage);
    }
  }

  loadExistingEntitlements = debounce(this.loadExistingEntitlements, 500).bind(this);
  loadExistingEntitlements(subjectAui, resourceAui) {
    if (this.props.disabled) return;

    if (!regExpToValidateSubjectAui.test(subjectAui) || !regExpToValidateResourceAui.test(resourceAui)) {
      this.clearStatementExistsWarning();
    } else {
      this.iamServiceV2.policies.inspect({subjectAui, resourceAui}).then(resp => {
        let statementExists = resp.data.statements && resp.data.statements.length != 0;
        if (statementExists) {
          this.setState(prevState => ({
            entitlements: resp.data.statements[0].entitlements,
            showStatementExistsWarning: true,
            counter: prevState.counter + 1
          }), () => this.handleChipsChange(this.state.entitlements));
        } else {
          this.setState(prevState => ({
            showStatementExistsWarning: false,
            counter: prevState.counter + 1
          }), () => this.handleChipsChange(this.state.entitlements));
        }
        return resp;
      }, createResponseAlertMessage).catch(() => {
        this.clearStatementExistsWarning();
      });
    }
  }

  clearStatementExistsWarning() {
    this.setState(() => ({
      showStatementExistsWarning: false
    }), () => this.handleChipsChange(this.state.entitlements));
  }

  isValidEntitlement = this.isValidEntitlement.bind(this);
  isValidEntitlement(entitlement) {
    const { suggestions } = this.state;
    // make a wildcard entitlement into a Regexp
    const re = new RegExp(entitlement.replace('*', '.+'));

    if (!suggestions.length || /.+:.+/.test(entitlement) && suggestions.some(s => re.test(s))) {
      return null;
    }

    return { errDisplayId: 'au.validation.notValid' };
  }

  validate = this.validate.bind(this);
  validate() {
    this.setState(prevState => {
      const { entitlements } = prevState;
      const validationErrors = run(entitlements, validationRules);
      const errors = (validationErrors || {}).errors || [];
      const field = formatMessage({ id: 'au.entitlementEditor.entitlement' });

      for (let i=0, len=entitlements.length; i < len; i++) {
        let vErr = run(entitlements[i], [this.isValidEntitlement]);
        if (vErr.errDisplayId) {
          errors[i] = { ...vErr, values: { field } };
        }
      }

      return {
        totalErrors: errors.filter(e => e).length,
        errors
      };
    }, this.commitChanges);
  }

  handleChipsChange = this.handleChipsChange.bind(this);
  handleChipsChange([...entitlements]) {
    const userInput = entitlements.pop();
    const nextEntitlements = [...entitlements, ...(userInput || '').trim().split(SEPARATOR_RE).filter(text => text.trim())];
    const validationErrors = run(nextEntitlements, validationRules);
    const errors = (validationErrors || {}).errors || [];

    const field = formatMessage({ id: 'au.entitlementEditor.entitlement' });
    for (let i=0, len=nextEntitlements.length; i < len; i++) {
      let vErr = run(nextEntitlements[i], [this.isValidEntitlement]);
      if (vErr.errDisplayId) {
        errors[i] = { ...vErr, values: { field } };
      }
    }

    this.setState(({ entitlements: nextEntitlements, showError: true }), this.validate);
  }

  handleEntitlementSelect = this.handleEntitlementSelect.bind(this);
  handleEntitlementSelect(entitlement) {
    this.setState(prevState => ({
      entitlements: [...prevState.entitlements, entitlement],
      counter: prevState.counter + 1
    }), () => this.handleChipsChange(this.state.entitlements));
  }

  commitChanges() {
    const { entitlements, errors } = this.state;
    this.props.onChange(entitlements, errors);
  }

  render() {
    const { className, createMode, disabled, error } = this.props;
    const { counter, entitlements, suggestions, errors, totalErrors } = this.state;
    const fieldError = Boolean((this.props.showError || this.state.showError) && (error || {}).errDisplayId);
    const showStatementExistsWarning = this.state.showStatementExistsWarning;

    return (
      <div className={cn(styles.container, className)}>
        <AuDropDown
          ref={this.setTagNameRef}
          key={`entitlement_${counter}`}
          createMode={Boolean(createMode && this.entitlementOptions.length)}
          className={styles.dropdown}
          disabled={disabled || !this.entitlementOptions.length}
          options={this.entitlementOptions}
          selectionClassName={styles.selection}
          placeholderId="au.entitlementEditor.placeholder.entitlement"
          selectOption={this.handleEntitlementSelect}
          allowTyping={true}
        />
        <AuChips
          chips={entitlements}
          errors={errors}
          storedChips={this.storedEntitlements}
          suggestions={suggestions}
          placeholderId="au.entitlementEditor.placeholder.entitlements"
          className={cn({ [styles.invalid]: fieldError })}
          handleOnPaste={true}
          highlightFirstSuggestion={true}
          disabled={disabled}
          createChipKeys={['Enter', ' ', ';', ',']}
          onChange={this.handleChipsChange}
        />
        { Boolean(totalErrors) && !fieldError &&
          <AutoIntl
            className={styles.error}
            displayId="au.entitlementEditor.invalidEntitlements"
            values={{ count: totalErrors }}
          />
        }
        { fieldError &&
          <AutoIntl
            className={styles.error}
            displayId={error.errDisplayId}
            values={{ ...error.values, field: formatMessage({ id: error.fieldDisplayId })}}
          />
        }
        <AutoIntl className={styles.note} displayId="au.entitlementEditor.separationNote" />
        { showStatementExistsWarning &&
          <Banner type='warning' className={styles.warning}>
            <FormattedMessage
              id='au.entitlementEditor.editingExistingStatement'
            />
          </Banner>
        }
      </div>
    );
  }
}
