import React from 'react';
import cn from 'classnames';
import * as T from 'prop-types';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import { trackException } from '@au/core/lib/utils/ga';

import styles from '../css/components/state_icon.module.scss';

export const ICON_DISABLED    = 'disabled';
export const ICON_ENABLED     = 'enabled';
export const ICON_RUNNING     = 'running';
export const ICON_PAUSED      = 'paused';
export const ICON_STOPPED     = 'stopped';
export const ICON_REPLAY      = 'replay';
export const ICON_VALID       = 'valid';
export const ICON_INVALID     = 'invalid';
export const ICON_INCOMPLETE = 'incomplete';
export const ICON_PERMIT      = 'permit';
export const ICON_DENY        = 'deny';
export const ICON_FAILURE     = 'failure';
export const ICON_WARNING     = 'warning';
export const ICON_SUCCESS     = 'success';
export const ICON_CANCELLED   = 'cancelled';
export const ICON_IN_PROGRESS = 'in_progress';
export const ICON_EXPIRED     = 'expired';
export const ICON_IN_FLIGHT   = 'in_flight';
export const ICON_DEVICE_RESPONSE_RECEIVED  = 'device_response_received';
export const ICON_RECEIVED_USER_INPUT_ALLOW = 'received_user_input_allow';
export const ICON_RECEIVED_USER_INPUT_DENY  = 'received_user_input_deny';
export const ICON_PERMIT_DEFAULT_DENY = 'permit_default_deny';
export const ICON_STARTING    = 'starting';
export const ICON_UNKNOWN     = 'unknown';
export const ICON_PARTIAL_SUCCESS  = 'partial_success';
export const ICON_PARTIAL_FAILURE  = 'partial_failure';
export const ICON_COMMAND_DEFINITION_DRAFT   = 'command_definition_draft';
export const ICON_COMMAND_DEFINITION_ENABLED = 'command_definition_enabled';
export const ICON_COMMAND_DEFINITION_DISABLED = 'command_definition_disabled';
export const ICON_COMMAND_DEFINITION_VALID   = 'command_definition_valid';
export const ICON_COMMAND_DEFINITION_INVALID = 'command_definition_invalid';
export const ICON_COMPLETE = 'complete';

export default class StateIcon extends React.PureComponent {

  static icons = [
    ICON_DISABLED,
    ICON_ENABLED,
    ICON_RUNNING,
    ICON_PAUSED,
    ICON_STOPPED,
    ICON_REPLAY,
    ICON_VALID,
    ICON_INVALID,
    ICON_INCOMPLETE,
    ICON_PERMIT,
    ICON_DENY,
    ICON_FAILURE,
    ICON_WARNING,
    ICON_SUCCESS,
    ICON_CANCELLED,
    ICON_IN_PROGRESS,
    ICON_EXPIRED,
    ICON_IN_FLIGHT,
    ICON_DEVICE_RESPONSE_RECEIVED,
    ICON_RECEIVED_USER_INPUT_ALLOW,
    ICON_RECEIVED_USER_INPUT_DENY,
    ICON_PERMIT_DEFAULT_DENY,
    ICON_STARTING,
    ICON_PARTIAL_SUCCESS,
    ICON_PARTIAL_FAILURE,
    ICON_COMMAND_DEFINITION_DRAFT,
    ICON_COMMAND_DEFINITION_ENABLED,
    ICON_COMMAND_DEFINITION_DISABLED,
    ICON_COMMAND_DEFINITION_VALID,
    ICON_COMMAND_DEFINITION_INVALID,
    ICON_COMPLETE
  ]

  static propTypes = {
    icon: T.oneOf(StateIcon.icons),
    displayId: T.string,
    displayString: T.string,
    className: T.string,
    containerClass: T.string
  }

  static defaultProps = {
    containerClass: 'container'
  }

  componentDidMount() {
    if (this.props.icon) {
      this.trackUnknownIcon();
    }
  }

  componentDidUpdate(prevProps) {
    const { icon } = this.props;

    if (icon && icon !== prevProps.icon) {
      this.trackUnknownIcon();
    }
  }

  trackUnknownIcon() {
    const { icon, displayId, displayString } = this.props;

    if (!this.constructor.icons.includes(icon)) {
      console.warn(`StateIcon: received an unknown icon "${icon}"`); // eslint-disable-line no-console

      trackException(
        `StateIcon: received an unknown icon "${icon}" ` +
        `along with display${displayId ? 'Id' : 'String'}: ` +
        `"${displayId || displayString}"`,
      );
    }
  }

  // Extracted for overrides
  getIconStyles() {
    const { icon } = this.props;
    return styles[icon];
  }

  render() {
    const { icon, className, containerClass } = this.props;
    const isKnownIcon = icon && this.constructor.icons.includes(icon);

    let displayId;
    let displayString;
    let iconClassName;

    if (isKnownIcon) {
      displayId = this.props.displayId;
      displayString = this.props.displayString;
      iconClassName = this.getIconStyles();
    } else {
      displayId = `au.entity.state.${ICON_UNKNOWN}`;
      iconClassName = styles[ICON_UNKNOWN];
    }

    return (
      <div className={cn(styles[containerClass], className, styles[`container_${icon}`])}>
        <AutoIntl
          displayId={displayId}
          displayString={displayString}
          className={iconClassName}
        />
      </div>
    );
  }
}
