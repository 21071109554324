import React, { useEffect } from 'react';
import Slider from 'rc-slider';
import { fromJS } from 'immutable';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import cn from 'classnames';

import AuButton from '@au/core/lib/components/elements/AuButton';
import AuDropDown from '@au/core/lib/components/elements/AuDropDown';
import AuToggle from '@au/core/lib/components/elements/AuToggle';
import TabSelector from '@au/core/lib/components/elements/TabSelector';
import Pill from '@au/core/lib/components/elements/Pill';
import AuInput from '@au/core/lib/components/elements/AuInput';
import TreeView from '@au/core/lib/components/elements/TreeView';
import CollapsiblePanel from '@au/core/lib/components/elements/AuInput';
import AuProgressBar from '@au/core/lib/components/elements/AuProgressBar';
import LoadingIndicator from '@au/core/lib/components/elements/LoadingIndicator';
import BinaryFontButton from '@au/core/lib/components/elements/BinaryFontButton';
import AuDistributionChart from '@au/core/lib/components/elements/AuDistributionChart';
import AuSidebarCollapser from '@au/core/lib/components/elements/AuSidebarCollapser';

import { formatMessage } from '../utils/reactIntl';
import { tapFilterType,
         deploymentStateIcon,
         commandState,
         COMPONENT_GALLERY_PAGES,
         NOOP } from '../constants';

import AuStatGrid from './AuStatGrid.js';
import Banner from './Banner';
import SelectionSummary from './SelectionSummary';
import FancyKeyValue from './FancyKeyValue';
import {
  TextInput, TextDisplay, EmailInput, BooleanInput, SelectInput, ChipsInput
} from './entity/Inputs';
import { SimpleLayout } from './entity/Layouts';
import { required, minLength, noListDuplicates } from '../utils/validationRules';
import TagEditor from './TagEditor';
import SimpleTable, { Row, Cell, HeaderCell, DraggableRow, ExpandableRow } from './SimpleTable';
import TapFilterTypeSelector from './TapFilterTypeSelector';
import TapFilterDetailsEditor from './TapFilterDetailsEditor';
import StateIcon from './StateIcon';
import VehicleTapFilterDetail from './VehicleTapFilterDetail';
import EmptyValueHint from './EmptyValueHint';
import SidebarFilters from './SidebarFilters';
import StatusBadge from './StatusBadge';
import FilterSegment from './FilterSegment';
import Calendar from './Calendar';
import WidgetTile from './WidgetTile';
import WidgetIcon from './WidgetIcon';
import WidgetText from './WidgetText';
import { BusinessUnit, RegionCode, CommandType, CommandState, DateRange } from './filters';
import styles from '../css/components/component_gallery.module.scss';
import * as T from "prop-types";

const mockSelectionSummaryOptions = {
  'au.filters.fleetFilters': {
    validOptions: {
      'Filter 1': {},
      'Filter 2': {},
      'Filter 3': { display: 'F3' },
      '1w': { displayId: 'au.time.oneWeek' },
    }
  },
  'au.filters.makeFilters': {
    invalidOptions: {
      'f150': { display: 'Ford F-150' },
      'escape': { display: 'Ford Escape' },
      '1w': { displayId: 'au.time.oneWeek' }
    }
  },
};

const mockDropdownOptions = {
  '1w': { displayId: 'au.time.oneWeek' },
  '2w': { displayId: 'au.time.twoWeeks' },
  '1m': { displayId: 'au.time.oneMonth' },
  '2m': { displayId: 'au.time.twoMonths'}
};

const mockTreeViewItems = [
  {
    id: 'a1',
    display: 'Root Item 1',
    expanded: false,
    children: [
      {
        id: 'a11',
        display: 'Sub Item 1-1'
      },
      {
        id: 'a12',
        display: 'Sub Item 1-2'
      },
      {
        id: 'a13',
        display: 'Sub Item 1-3'
      }
    ]
  },
  {
    id: 'a2',
    display: 'Root Item 2',
    expanded: false,
    children: [
      {
        id: 'a21',
        display: 'Sub Item 2-1',
        children: [
          {
            id: 'a211',
            display: 'Sub Item 2-1-1'
          }
        ]
      },
      {
        id: 'a22',
        display: 'Sub Item 2-2'
      },
      {
        id: 'a23',
        display: 'Sub Item 2-3'
      }
    ]
  },
  {
    id: 'a3',
    display: 'Root Item 3',
    expanded: true,
    children: [
      {
        id: 'a31',
        display: 'Sub Item 3-1',
        children: [
          {
            id: 'a311',
            display: 'Sub Item 3-1-1'
          }
        ]
      },
      {
        id: 'a32',
        display: 'Sub Item 3-2'
      },
      {
        id: 'a33',
        display: 'Sub Item 3-3'
      },
      {
        id: 'a34',
        display: 'Sub Item 3-4',
        expanded: true,
        children: [
          {
            id: 'a341',
            display: 'Sub Item 3-4-1',
          },
          {
            id: 'a342',
            display: 'Sub Item 3-4-2',
          },
          {
            id: 'a343',
            display: 'Sub Item 3-4-3',
            expanded: true,
            children: [
              {
                id: 'a3431',
                display: 'Sub Item 3-4-3-1',
              }
            ]
          },
          {
            id: 'a344',
            display: 'Sub Item 3-4-4',
          },
        ]
      }
    ]
  },
];

export default class ComponentGallery extends React.Component {

  static propTypes = {
    match: T.shape({
      params: T.shape({
        pageAlias: T.string.isRequired
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    const dataSets = [];
    // create 3 data sets, 10k items each
    for (let i = 0; i < 3; i++) {
      const data = [];
      // some properties we'll be searching for
      let prop1 = `prop_${i * 2 + 1}`;
      let prop2 = `prop_${i * 2 + 2}`;

      for (let j = 0; j < 10000; j++) {
        let dataObj = {
          'id': `id${j}`,
          [prop1]: Math.random().toString(36).substring(2, 16),
          [prop2]: Math.random().toString(36).substring(2, 16)
        };
        data.push(dataObj);
      }

      dataSets.push({
        'items': data,
        'filterProps': [{
          'name': prop1,
          'displayId': prop1,
          'displayMax': 3,
        }, {
          'name': prop2,
          'displayId': prop2,
          'displayMax': 5,
        }]
      });
    }

    this.state = { range1: 50, range2: 50, range3: 75, dataSets: dataSets };
  }

  renderEntityInput() {
    const entity = {
      name: "Scott",
      email: "scott@autonomic.ai",
      disableUser: true,
      tags: ["sample tag 1", "sample tag 2"],
      vin: "12345ABCDEF",
      fleet_id: "456def"
    };
    const fleets = [
      { val:'CharSF', displayString: "Chariot SF" },
      { val: 'CharLA', displayString: "Chariot LA" },
      { val: 'test', displayString: "Logistics Test" }
    ];
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Entity Inputs</h2>
        <div className={styles.wrapper}>
          <TextDisplay
            entity={entity}
            field="vin"
            labelId="au.vehicle.vin" />
          <TextInput
            entity={entity}
            field="name"
            labelId="au.userProfile.fullName"
            validationRules={[required, minLength(6)]} />
          <EmailInput
            entity={entity}
            field="email"
            labelId="au.userProfile.emailAddress"
            validationRules={[required]} />
          <BooleanInput
            entity={entity}
            value={true}
            field="disable"
            labelId="au.entity.attr.effect"
            captionId="au.disable"
            validationRules={[required]} />
          <SelectInput
            entity={entity}
            field="fleet_id"
            labelId="au.vehicle.fleet"
            defaultOption="123abc"
            options={fleets}
            validationRules={[required]} />
          <ChipsInput
            entity={entity}
            field="tags"
            suggestions={["sample tag 3"]}
            labelId="au.userProfile.tags"
            placeholderId="au.userProfile.addTag"
            validationRules={[noListDuplicates()]} />
        </div>
      </div>
    );
  }

  renderEntityLayout(){
    const entity = {
      name: "Scott",
      email: "scott@autonomic.ai",
      disableUser: true
    };
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Entity Layouts</h2>
        <div className={styles.wrapper}>
          <SimpleLayout entity={entity}>
            <TextInput
              field="name"
              labelId="au.userProfile.fullName"
              validationRules={[required, minLength(6)]} />
            <EmailInput
              field="email"
              labelId="au.userProfile.emailAddress"
              validationRules={[required]} />
          </SimpleLayout>
        </div>
      </div>
    );
  }

  renderAuButton() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>AuButton</h2>
        <div className={styles.flexbox}>
          <div className={styles.column}>
            <p>Inactive Buttons</p>
            <AuButton type="inactive" displayString="Regular" />
            <AuButton type="inactive" displayString="Small" size="small" />
          </div>
          <div className={styles.column}>
            <p>Primary Buttons</p>
            <AuButton type="primary" displayString="Regular" />
            <AuButton type="primary" displayString="Small" size="small" />
          </div>
          <div className={styles.column}>
            <p>Secondary Buttons</p>
            <AuButton type="secondary" displayString="Regular" />
            <AuButton type="secondary" displayString="Small" size="small" />
          </div>
          <div className={styles.column}>
            <p>Plain Buttons</p>
            <AuButton type="plain" displayString="Regular" />
            <AuButton type="plain" displayString="Small" size="small" />
          </div>
        </div>
      </div>
    );
  }

  renderAuInput() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>AuInput</h2>
        <div className={styles.flexbox}>
          <div className={styles.column}>
            <p>Checkboxes</p>
            <AuInput name="field1" type="checkbox" caption="Not checked" />
            <AuInput name="field2" type="checkbox" caption="Checked" checked={true} />
          </div>
          <div className={styles.column}>
            <p>Radio Buttons</p>
            <AuInput name="field3" type="radio" caption="Not Selected" />
            <AuInput name="field4" type="radio" caption="Selected" checked={true} />
          </div>
          <div className={styles.column}>
            <p>Input Fields</p>
            <AuInput name="field5" type="text" value="" placeholder="Empty" />
            <AuInput name="field6" type="text" value="With Text" />
            <AuInput name="field7" type="text" value="Invalid" showError={true} error={{fieldDisplayId: 'au.vehicle.imei', errDisplayId: 'au.validation.isRequired'}} />
          </div>
          <div className={styles.column}>
            <p>Password Fields</p>
            <form>
              <AuInput name="field8" type="password" value="qwerty123" />
              <AuInput name="field9" type="password" value="" showError={true} error={{fieldDisplayId: 'au.vehicle.imei', errDisplayId: 'au.validation.minLength', values: {length: 64}}}  />
            </form>
          </div>
        </div>
      </div>
    );
  }

  renderAuToggle() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>AuToggle</h2>
        <div className={styles.flexbox}>
          <div className={styles.column}>
            <p>Simple View</p>
            <AuToggle name="toggle_1_1"  />
            <AuToggle name="toggle_1_2" checked={true} />
          </div>
          <div className={styles.column}>
            <p>w/ Custom Captions</p>
            <AuToggle name="toggle_2_1" captionLeft="Left" captionRight="Right" />
            <AuToggle name="toggle_2_2" captionLeft="Left" captionRight="Right" checked={true}  />
          </div>
          <div className={styles.column}>
            <p>w/ Labels</p>
            <AuToggle name="toggle_3_1" showLabel={true}  />
            <AuToggle name="toggle_3_2" showLabel={true} checked={true}  />
          </div>
        </div>
      </div>
    );
  }

  renderAuStatGrid() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>AuStatGrid</h2>
        <div className={styles.wrapper}>
          <AuStatGrid>
            <p style={{backgroundColor: 'lightgreen'}}>Stat</p>
            <p style={{backgroundColor: 'lightgreen'}}>Stat</p>
            <p style={{backgroundColor: 'lightgreen'}}>Stat</p>
            <p style={{backgroundColor: 'lightgreen'}}>Stat</p>
            <p style={{backgroundColor: 'lightgreen'}}>Stat</p>
            <p style={{backgroundColor: 'lightgreen'}}>Stat</p>
            <p style={{backgroundColor: 'lightgreen'}}>Stat</p>
          </AuStatGrid>
        </div>
      </div>
    );
  }

  renderSelectionSumary() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Selection Summary</h2>
        <div className={styles.wrapper}>
          <SelectionSummary allOptions={mockSelectionSummaryOptions} />
        </div>
      </div>
    );
  }

  renderDropDowns() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>DropDowns</h2>
        <div className={styles.wrapper} style={{ display: 'flex', padding: '16px 32px' }}>
          <div style={{ width: '300px', marginRight: 24 }}>
            <label>AuDropDown</label>
            <AuDropDown selection={'1w'} selectOption={()=>{}} options={mockDropdownOptions} />
          </div>
        </div>
      </div>
    );
  }

  renderPills() {
    const pills = [];
    for (let i=0; i<40; ++i) {
      pills.push(<div key={i} style={{ width: 100 }}><Pill display={`filter #${i}`} invalid={i > 37} /></div>);
    }
    pills.push(<Pill key='au.filters.makeFilters' displayId='au.filters.makeFilters' />);

    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Pills</h2>
        <div className={styles.wrapper} style={{maxHeight: 185, overflow: 'auto', display: 'flex', flexWrap: 'wrap'}}>
          {pills}
        </div>
      </div>
    );
  }

  renderSlider() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Slider</h2>
        <div className={styles.wrapper}>
          <div className={styles.column}>
            <p>Min=1, Max=200, Default=50, Step=1</p>
            <div style={{width: 150, paddingTop: 10, paddingLeft: 15}}>
              <Slider min={1} max={200} value={this.state.range1} onChange={function(value) { this.setState({range1: value}); }.bind(this)} />
              {this.state.range1}
            </div>
            <br />

            <p>Min=50, Max=200, Default=50, Step=25</p>
            <div style={{width: 200, paddingTop: 10, paddingLeft: 15}}>
              <Slider min={50} max={200} value={this.state.range2} step={25} onChange={function(value) { this.setState({range2: value}); }.bind(this)} />
              {this.state.range2}
            </div>
          </div>

          <div className={styles.column}>
            <p>Min=1, Max=200, Default=50, Step=1, disabled</p>
            <div style={{width: 250, paddingTop: 10, paddingLeft: 15}}>
              <Slider min={1} max={200} value={this.state.range1} disabled={true} />
              {this.state.range1}
            </div>
            <br />

            <p>Min=0, Max=200, Default=75, Step=5</p>
            <div style={{width: 300, paddingTop: 10, paddingLeft: 15}}>
              <Slider min={0} max={200} step={5} value={this.state.range3}
                trackStyle={
                  { backgroundColor: '#6882A1', height: 4 }
                }
                handleStyle={{
                  borderColor: '#4FAAD7',
                  height: 15,
                  width: 12,
                  marginLeft: -6,
                  marginTop: -5,
                  backgroundColor: '#4FAAD7',
                  border: 0,
                  borderRadius: 0
                }}
                onChange={function(value) { this.setState({range3: value}); }.bind(this)} />
              {this.state.range3}
            </div>
            <br />
          </div>
        </div>
      </div>
    );
  }

  renderCollapsiblePanel() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>CollapsiblePanel</h2>
        <div style={{display: 'flex'}} className={styles.wrapper}>
          <div style={{width: 400, padding: 20}}>
            Permanent Panel (enabled=false):
            <div style={{border: '1px solid #fff'}}>
              <CollapsiblePanel displayId="au.userProfile.currentView" enabled={false}>
                <p>You shall not collapse this panel!</p>
              </CollapsiblePanel>
            </div>
            Collapsible Panel 1:
            <div style={{border: '1px solid #fff'}}>
              <CollapsiblePanel displayId="au.userProfile.title.loggedUser">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus porta, sem sit amet semper imperdiet, massa lacus molestie justo, non dapibus enim nunc ac urna.</p>
              </CollapsiblePanel>
            </div>
            Collapsible Panel 2:
            <div style={{border: '1px solid #fff'}}>
              <CollapsiblePanel displayId="au.userProfile.title.loginHistory">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras pulvinar laoreet diam a auctor. Nullam vel suscipit diam, sed suscipit odio. Fusce et condimentum leo, tempus condimentum urna. Donec faucibus facilisis euismod. Quisque lorem lectus.</p>
              </CollapsiblePanel>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTapFilterTypeSelector() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>TapFilterTypeSelector</h2>
        <div style={{display: 'flex'}} className={styles.wrapper}>
          <div style={{width: 400, padding: 20}}>
            <TapFilterTypeSelector />
          </div>
        </div>
      </div>
    );
  }

  renderTapFilterDetailsEditor() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>TapFilterDetailsEditor</h2>
        <div style={{display: 'flex'}} className={styles.wrapper}>
          <div style={{width: 400, padding: 20}}>
            <TapFilterDetailsEditor filterType="iamFilter" onChange={NOOP}/>
            <TapFilterDetailsEditor filterType="attributeFilter" onChange={NOOP}/>
          </div>
        </div>
      </div>
    );
  }

  renderTreeView() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>TreeView</h2>
        <div style={{display: 'flex'}} className={styles.wrapper}>
          <div style={{width: 400, padding: 20}}>
            <TreeView data={fromJS(mockTreeViewItems)} />
          </div>
        </div>
      </div>
    );
  }

  renderAuProgressBar() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>AuProgressBar</h2>
        <div style={{display: 'flex'}} className={styles.wrapper}>
          <div style={{width: 400, padding: 20}}>
            <AuProgressBar min={0} max={100} value={97} isAnimated={true} />
          </div>
          <div style={{width: 600, padding: 20}}>
            <AuProgressBar min={0} max={100} value={37} isAnimated={false} />
          </div>
          <div style={{width: 900, padding: 20}}>
            <AuProgressBar min={0} max={100} value={49} isAnimated={true} />
          </div>
        </div>
      </div>
    );
  }

  renderLoadingIndicator() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>ProgressIndicator</h2>
        <div style={{display: 'flex'}} className={styles.wrapper}>
          <div style={{width: 400, height: 20, padding: 20}}>
            <LoadingIndicator />
          </div>
          <div style={{width: 600, padding: 20}}>
            <LoadingIndicator />
          </div>
          <div style={{width: 900, height: 20, padding: 20}}>
            <LoadingIndicator displayId="au.table.filterEmptyMessage" />
          </div>
        </div>
      </div>
    );
  }

  renderBinaryFontButton() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Binary Font Button</h2>
        <div style={{display: 'flex'}} className={styles.wrapper}>
          <div style={{width: 400, height: 20, padding: 20}}>
            <BinaryFontButton initial="plus-square" next="minus-square" />
          </div>
          <div style={{width: 400, padding: 20}}>
            <BinaryFontButton onInitial={false} initial="plus-square" next="minus-square" />
          </div>
          <div style={{width: 400, height: 20, padding: 20, fontSize: 20}}>
            <BinaryFontButton initial="plus-square" next="minus-square" />
          </div>
        </div>
      </div>
    );
  }

  renderAuDistributionChart() {
    const labelValues = [
      { label: "Harsh_Deceleration_v1", value: 743 },
      { label: "Harsh_Acceleration",    value: 537 },
      { label: "Overspeed_v3",          value: 973 },
      { label: "Over_RPM",              value: 832 },
      { label: "Harsh_Acceleration_v1", value: 45 },
      { label: "Overspeed_harsh_brake", value: 411 },
      { label: "Harsh_Acceleration_v2", value: 238 },
    ];
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>AuDistributionChart</h2>
        <div style={{display: 'flex'}} className={styles.wrapper}>
          <AuDistributionChart labelValues={labelValues} />
        </div>
      </div>
    );
  }

  renderTabSelector() {
    const tabs = [
      { displayString: 'Tab 1' },
      { displayString: 'Tab 2' },
      { displayString: 'Tab 3' }
    ];

    const intlTabs = [
      {
        displayId: 'au.filters.addFilters'
      },
      {
        displayId: 'au.filters.currentFilters'
      }
    ];

    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Tab Selector</h2>
        <div className={styles.wrapper}>
          <br />
          <TabSelector tabs={tabs} /> <br />
          <TabSelector tabs={tabs} /> <br />
          <TabSelector tabs={intlTabs} />
          <br />
        </div>
      </div>
    );
  }

  renderAuSidebarCollapser() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Sidebar Collapser</h2>
        <div className={styles.wrapper}>
          <div style={{ height: 150, maxWidth: 350, overflow: 'auto', background: '#FFF', padding: 20 }}>
            <AuSidebarCollapser>
              Minim proident minim Lorem laboris laboris sunt et. Aliquip aliquip eu officia voluptate et. Mollit pariatur aute tempor culpa ea sunt fugiat.
              Do tempor voluptate laboris sint pariatur deserunt non nulla nisi in dolore velit. Do quis quis enim duis amet incididunt incididunt et eu ea aute ad. Do elit aute id voluptate magna esse.
              Aute aute dolore consectetur sunt veniam deserunt dolor deserunt consequat labore sint laborum non. Cupidatat qui eiusmod consequat eu ad nostrud laboris anim commodo aliquip ex qui qui cupidatat. Irure in veniam Lorem ex voluptate occaecat id. In cupidatat id ullamco non mollit ad minim ut. Mollit commodo sit nisi enim nostrud cillum ullamco aliquip magna culpa et. Voluptate officia enim ullamco cupidatat aliquip.
            </AuSidebarCollapser>
          </div>
        </div>
      </div>
    );
  }

  renderTagEditor() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Tag Editor</h2>
        <div className={styles.wrapper}>
          <TagEditor />
        </div>
      </div>
    );
  }

  renderFancyKeyValue() {

    const mockFancyKeyValueValues = fromJS({
      'au.entity.name': 'uri',
      'au.entity.value': '.*',
      'au.entity.vins': ['aui', 'aui', 'aui','aui', 'aui', 'aui', 'aui', 'aui', 'aui', 'aui', 'aui', 'aui', 'aui', 'aui', 'aui']}
    );

    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Fancy Key Value</h2>
        <div className={styles.wrapper}>
          <FancyKeyValue style={{ height: 200 }} value={mockFancyKeyValueValues}/>
        </div>
      </div>
    );
  }

  renderStateIcon() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>State Icon</h2>
        <div className={styles.wrapper}>
          <StateIcon enabled={true} />
          <StateIcon enabled={false} />
        </div>
      </div>
    );
  }

  renderBanner() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Banner Components</h2>
        <div className={styles.wrapper}>
          <Banner type='info-circle' displayId='au.vehicle.tapFilters.iamMessage' />
        </div>
        <br />
        <div className={styles.wrapper}>
          <Banner type='warning' displayId='au.vehicle.tapFilters.iamMessage' />
        </div>
        <br />
        <div className={styles.wrapper}>
          <Banner type='alert' displayId='au.vehicle.tapFilters.iamMessage' />
        </div>
        <br />
        <div className={styles.wrapper}>
          <Banner type='success' displayId='au.vehicle.tapFilters.iamMessage' />
        </div>
        <br />
        <div className={styles.wrapper}>
          <Banner type='loading' displayId='au.vehicle.tapFilters.iamMessage' />
        </div>
        <br />
        <div className={styles.wrapper}>
          <Banner displayString='Default type and a displayString message' />
        </div>

        <h2 className={styles.title} style={{ marginTop: 24 }}>Banner Component with children</h2>
        <div className={styles.wrapper}>
          <Banner type='warning'>
            <FormattedMessage
              id="au.permissions.obsoleteWarning"
              values={{
                v2Policies: (
                  <NavLink to='/some/bogus/location/only/for/testing'>
                    { formatMessage({id: 'au.permissions.v2Policies' }) }
                  </NavLink>
                )
              }}
            />
          </Banner>
        </div>
      </div >
    );
  }

  renderVehicleTapFilterDetail() {

    const deviceType = {
      tapId: 'foo1',
      displayName: 'My Special Tap',
      type: 'PERMIT',
      state: 'RUNNING',
      filterType: tapFilterType.DEVICE,
      value: 'epid2'
    };

    const groupType = {
      tapId: 'foo2',
      displayName: 'Foo Bar',
      type: 'PERMIT',
      state: 'RUNNING',
      filterType: tapFilterType.GROUP,
      value: {
        name: 'faux group', // displayName from the joined group
        id: 'this-is-a-faux-uuid'
      }
    };

    const vinType = {
      tapId: 'foo3',
      displayName: 'Foo Bar 3',
      type: 'DENY',
      state: 'RUNNING',
      filterType: tapFilterType.VIN,
      value: 'this-is-a-faux-vin'
    };

    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Vehicle Tap Filter Detail Formatter</h2>
        <div className={styles.wrapper}>
          <VehicleTapFilterDetail tapFilter={fromJS(deviceType)}/>
          <VehicleTapFilterDetail tapFilter={fromJS(groupType)}/>
          <VehicleTapFilterDetail tapFilter={fromJS(vinType)}/>
        </div>
      </div>
    );
  }

  renderEmptyValueHint() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Empty Value Hint</h2>
        <div className={styles.wrapper}>
          <EmptyValueHint displayId="au.noDataAvailable" />
        </div>
      </div>
    );
  }

  renderSidebarFilters() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Sidebar Filters</h2>
        <div className={styles.wrapper}>
          <SidebarFilters counter={2} filtersDef={['foo', 'bar', 'foobar']} actions={{saveUserData: ()=>{}}}>
            <div style={{ background: '#FFF', padding: '10px 20px', flex: 1 }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.Phasellus
              porta, sem sit amet semper imperdiet, massa lacus molestie justo,
              non dapibus enim nunc ac urna.
            </div>
          </SidebarFilters>
        </div>
      </div>
    );
  }

  renderFilterSegment() {
    const filters = [
      {displayId: "au.entity.attr.businessUnit", iconClassName: "icon-business_unit", value: '0'},
      {displayId: "au.entity.attr.region", iconClassName: "icon-region", value: '1'},
      {displayId: "au.entity.attr.client", iconClassName: "icon-client", value: '2'},
      {displayId: "au.entity.attr.type", iconClassName: "icon-type", value: '3'},
      {displayId: "au.entity.attr.status", iconClassName: "icon-status", value: '4'},
      {displayId: "au.entity.attr.status", iconClassName: "", value: '5'},
    ];

    const filtersArray = filters.map((filter, i) => {
      let selection = [filter.value];
      return <FilterSegment
        name={'foo'}
        key={`this_is_fine_key_${i}`}
        displayId={filter.displayId}
        iconClassName={filter.iconClassName}
        selection={selection}
        filterName={`My Filter - ${i}`}
        Filter={function(props) {
          let displayString = `Filter - ${i + 1}`;
          // aka componentDidMount
          useEffect(() => {
            props.onInit({ displayString });
          }, []);
          return (
            <a onClick={() => props.onChange({ displayString, value: `${i}` })}>Click Me</a>
          );
        }}
      />;
    });

    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Filter Segment</h2>
        <div className={styles.wrapper} style={{ height: 400 }}>
          {filtersArray}
        </div>
      </div>
    );
  }

  renderBadge() {
    const deploymentBadges = Object.entries(deploymentStateIcon).map(([state, icon]) =>
      <div className={styles.status_badge_container} key={state}>
        <StatusBadge icon={icon} displayId={`au.entity.state.${state}`} />
      </div>
    );
    const commandBadges = Object.entries(commandState).map(([state, icon]) =>
      <div className={styles.status_badge_container} key={state}>
        <StatusBadge icon={icon} displayId={`au.entity.state.${state}`} />
      </div>
    );

    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Status Badge</h2>
        <div className={styles.wrapper}>
          {deploymentBadges}
          {commandBadges}
        </div>
      </div>
    );
  }

  renderCalendar() {
    const modifiers = {
      incident: [
        moment().date(3).toDate(),
        moment().date(8).toDate(),
        moment().date(18).toDate(),
      ],
      announcement: [
        moment().date(11).toDate(),
        moment().date(19).toDate(),
      ]
    };

    const legend = {
      incident: { displayId: 'au.entity.name.incident' },
      announcement: { displayId: 'au.entity.name.announcement' },
      today: { displayId: 'au.calendar.today' }
    };

    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Calendar</h2>
        <div className={styles.wrapper}>
          <div style={{ maxWidth: 500, background: 'white' }}>
            <Calendar
              numberOfMonths={1}
              legend={legend}
              modifiers={modifiers}
              from={moment().date(18).toDate()}
              to={moment().date(20).toDate()}
            />
          </div>
        </div>
      </div>
    );
  }

  renderRegionList() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>RegionCode List</h2>
        <div style={{display: 'flex'}} className={styles.wrapper}>
          <RegionCode />
        </div>
      </div>
    );
  }

  renderBusinessUnitList() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>BusinessUnit List</h2>
        <div style={{display: 'flex'}} className={styles.wrapper}>
          <BusinessUnit />
        </div>
      </div>
    );
  }

  renderCommandTypeList() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>CommandType List</h2>
        <div style={{display: 'flex'}} className={styles.wrapper}>
          <CommandType />
        </div>
      </div>
    );
  }

  renderCommandStateList() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>CommandState List</h2>
        <div style={{display: 'flex'}} className={styles.wrapper}>
          <CommandState />
        </div>
      </div>
    );
  }

  renderDateRangeList() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>DateRange List</h2>
        <div className={styles.wrapper}>
          <div style={{ maxWidth: 500, background: 'white' }}>
            <DateRange
              defaultValue={'30m'}
              ranges={"5m,15m,30m|1h,3h,6h,12h,24h|2d,7d,30d,90d|month_to_date,prev_month"}
              customProperties={{from: 'startTime', to: 'endTime'}}/>
          </div>
        </div>
      </div>
    );
  }

  renderWidgetTile() {
    const commandWidgets = Object.entries(commandState).map(([state, icon]) =>
      <div key={state}>
        <WidgetTile icon={state}>
          <WidgetIcon icon={icon} />
          <WidgetText title={'au.widgetTile.title.connectivityStatus'} value={`au.entity.state.${state}`} />
        </WidgetTile>
      </div>
    );
    const connectivityWidgets = Object.entries(WidgetIcon.icons).map(([state, icon]) =>
      <div key={state}>
        <WidgetTile icon={icon}>
          <WidgetIcon icon={icon} />
          <WidgetText titleDisplayString={"Title"}>
            Value
          </WidgetText>
        </WidgetTile>
      </div>
    );
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>Widget Tile</h2>
        <div className={styles.wrapper}>
          <div>
            {commandWidgets}
            {connectivityWidgets}
            <WidgetTile icon={'foo'}>
              <WidgetIcon icon={'foo'} />
              <WidgetText titleDisplayString={'Title'}>
                {123}
              </WidgetText>
            </WidgetTile>
          </div>
        </div>
      </div>
    );
  }

  _renderSimpleTable(draggable=false, collapsible=false) {
    const columnDefs = [
      { property: 'title', labelId: 'au.incidents.incidentTitle', width: '25%' },
      { property: 'services', labelId: 'au.incidents.affectedServices', width: 200 },
      { property: 'lastModified', labelId: 'au.incidents.lastModified', width: 'auto' },
    ];
    const data = [
      [1, 'foo', 'bar'],
      [2, 'hello', 'world'],
      [3, <div key="hello"><div>lvl1</div><div>lvl2</div></div>]
    ];

    const RowComponent = draggable ? DraggableRow : collapsible ? ExpandableRow : Row;

    return (
      <SimpleTable className={styles.table}>
        <Row>
          {columnDefs.map(col => (
            <HeaderCell
              key={`header_${col.property}`}
              width={col.width}
            >{formatMessage({ id: col.labelId })}</HeaderCell>
          ))}
        </Row>
        {
          data.map((record, i) => {
            let cells = [];
            columnDefs.forEach((col, i) => {
              cells.push(
                <Cell
                  key={`row_${i}_prop_${col.property}`}
                  width={col.width}
                >{record[i]}</Cell>
              );
            });
            return (
              <RowComponent 
                key={`row_${i}`}
                id={`${record[0]}`}
                index={i}
                className={cn({ [styles.draggable]: draggable })}
              >{cells}</RowComponent>
            );
          })
        }
      </SimpleTable>
    );
  }

  renderSimpleTable() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>SimpleTable</h2>
        <div style={{ display: 'flex' }} className={styles.wrapper}>
          { this._renderSimpleTable() }
        </div>
      </div>
    );
  }

  renderDraggableTable() {
    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>SimpleTable (draggable)</h2>
        <div style={{ display: 'flex' }} className={styles.wrapper}>
          {this._renderSimpleTable(true)}
        </div>
      </div>
    );
  }

  renderCollapsibleTable() {
    const columnDefs = [
      { property: 'title', labelId: 'au.incidents.incidentTitle', width: '25%' },
      { property: 'services', labelId: 'au.incidents.affectedServices', width: 200 },
      { property: 'lastModified', labelId: 'au.incidents.lastModified', width: 'auto' },
    ];
    const data = [
      [1, 'foo', 'bar'],
      [2, 'hello', 'world'],
      [3, <div key="hello"><div>lvl1</div><div>lvl2</div></div>]
    ];

    return (
      <div className={styles.exhibit}>
        <h2 className={styles.title}>SimpleTable (collapsible)</h2>
        <div style={{ display: 'flex' }} className={styles.wrapper}>
          {/* {this._renderSimpleTable(false, true)} */}
          <SimpleTable className={styles.table}>
            <Row>
              <HeaderCell width="0" />
              {columnDefs.map(col => (
                <HeaderCell
                  key={`header_${col.property}`}
                  width={col.width}
                >{formatMessage({ id: col.labelId })}</HeaderCell>
              ))}
            </Row>
            {
              data.map((record, i) => {
                let cells = [];
                columnDefs.forEach((col, i) => {
                  cells.push(
                    <Cell
                      key={`row_${i}_prop_${col.property}`}
                      width={col.width}
                    >{record[i]}</Cell>
                  );
                });
                return (
                  <ExpandableRow index={i} key={`row_${i}`} id={`${record[0]}`} expansionRenderer={() => <div>test test test test test test test test test test</div>}>
                    {cells}
                  </ExpandableRow>
                );
              })
            }
          </SimpleTable>
        </div>
      </div>
    );
  }

  render() {
    switch (this.props.match.params.pageAlias) {
      case COMPONENT_GALLERY_PAGES.ENTITY_FRAMEWORK:
        return (
          <div className={styles.container}>
            {this.renderEntityInput()}
            {this.renderEntityLayout()}
            {this.renderTapFilterTypeSelector()}
            {this.renderTapFilterDetailsEditor()}
            {this.renderTagEditor()}
            {this.renderVehicleTapFilterDetail()}
          </div>
        );
      case COMPONENT_GALLERY_PAGES.INPUTS:
        return (
          <div className={styles.container}>
            {this.renderAuButton()}
            {this.renderAuInput()}
            {this.renderAuToggle()}
            {this.renderSelectionSumary()}
            {this.renderDropDowns()}
            {this.renderSlider()}
            {this.renderTabSelector()}
            {this.renderCalendar()}
          </div>
        );
      case COMPONENT_GALLERY_PAGES.FORMATTERS:
        return (
          <div className={styles.container}>
            {this.renderTreeView()}
            {this.renderVehicleTapFilterDetail()}
            {this.renderEmptyValueHint()}
            {this.renderBadge()}
          </div>
        );
      case COMPONENT_GALLERY_PAGES.FILTERS:
        return (
          <div className={styles.container}>
            {this.renderSidebarFilters()}
            {this.renderFilterSegment()}
            {this.renderCalendar()}
            {this.renderRegionList()}
            {this.renderBusinessUnitList()}
            {this.renderCommandStateList()}
            {this.renderCommandTypeList()}
            {this.renderDateRangeList()}
          </div>
        );
      case COMPONENT_GALLERY_PAGES.NAVIGATION:
        return (
          <div className={styles.container}>
            {this.renderTabSelector()}
            {this.renderWidgetTile()}
          </div>
        );
      case COMPONENT_GALLERY_PAGES.BASE_COMPONENTS:
        return (
          <div className={styles.container}>
            {this.renderAuStatGrid()}
            {this.renderPills()}
            {this.renderCollapsiblePanel()}
            {this.renderAuSidebarCollapser()}
            {this.renderAuProgressBar()}
            {this.renderLoadingIndicator()}
            {this.renderBinaryFontButton()}
            {this.renderFancyKeyValue()}
            {this.renderBanner()}
            {this.renderBadge()}
            {this.renderWidgetTile()}
          </div>
        );
      case COMPONENT_GALLERY_PAGES.OVERVIEW_COMPONENTS:
        return (
          <div className={styles.container}>
            {this.renderLoadingIndicator()}
            {this.renderAuDistributionChart()}
            {this.renderWidgetTile()}
          </div>
        );
      case COMPONENT_GALLERY_PAGES.TABLE_COMPONENTS:
        return (
          <div className={styles.container}>
            {this.renderSimpleTable()}
            {this.renderDraggableTable()}
            {this.renderCollapsibleTable()}
          </div>
        );
      default: return null;
    }
  }
}
