import React from 'react';
import * as T from 'prop-types';
import { NOOP, regionCodeType } from '../../constants';
import { Filter, SelectableListProps } from '../../AuPropTypes';
import FilterSelectableList from "./FilterSelectableList";

export default class RegionCode extends React.Component {

  static propTypes = {
    defaultValue: SelectableListProps.defaultValue,
    containerRef: SelectableListProps.containerRef,
    selection: T.arrayOf(Filter),
    sort: SelectableListProps.sort,
    multiSelect: T.bool,
    onChange: T.func,
    onInit: T.func
  };

  static defaultProps = {
    onChange: NOOP,
    onInit: NOOP,
    selection: []
  };

  regionItems = Object.keys(regionCodeType).map(value => ({
    displayId: `au.commands.regions.${value}`, value
  }));

  render() {
    return (
      <FilterSelectableList
        containerRef={this.props.containerRef}
        items={this.regionItems}
        sort={this.props.sort}
        defaultValue={this.props.defaultValue}
        selection={this.props.selection}
        multiSelect={this.props.multiSelect}
        onChange={this.props.onChange}
        onInit={this.props.onInit}
      />
    );
  }
}
