export default function AuSpecs(builder) {
  const s = builder;

  const geofenceSpec = s.map({
    id: s.string(),
    fleet_id: s.string(),
    name: s.string(),
    timezone: s.string(),
    bounding_box: s.map({
      north: s.number(),
      south: s.number(),
      east: s.number(),
      west: s.number()
    }),
    // bounding_circle: s.map(),
    bounding_polygon: s.collection(s.map({
      lat: s.number(),
      lon: s.number(),
      alt: s.number()
    })),
    tags: s.map(),
    last_modified_timestamp: s.string()
  });

  const assetSpec = s.map({
    id: s.string(),
    vehicle_id: s.string(),
    tenant_id: s.string(),
    fleet_id: s.string(),
    fuel_level: s.number(),
    idle_status: s.bool(),
    ignition_status: s.string(),
    imei: s.string(),
    indicators: s.map(),
    location: s.map({
      lon: s.number(),
      lat: s.number(),
    }),
    location_uncertainty: s.number(),
    make: s.string(),
    matched_geofences: s.collection(),
    model: s.string(),
    name: s.string(),
    odometer: s.number(),
    oil_level_status: s.string(),
    oil_life_remaining: s.number(),
    pid_plugged_in: s.string(),
    vin: s.string(),
    year: s.string(),
    request_id: s.string(),
    timestamp: s.string(),
    geofenceHistory: s.mapOf(s.map({
      data: s.collection(s.map({
        direction: s.string(),
        duration: s.number(),
        timestamp: s.number()
      }))
    })),
    // are these still in use?
    speed: s.number(),
    avg_speed: s.number(),
    fuel_consumed: s.number(),
    activity_status: s.string(),
    tire_pressure: s.string(),
    max_speed: s.number(),
    overspeed: s.number(),
    oil_temp: s.string(),
    oil_level: s.string(),
    coolant_temp: s.string(),
    service_required: s.string(),
    needs_service: s.bool(),
    distance_travelled: s.number(),
    heading: s.number(),
  });

  const deviceSpec = s.map({
    id: s.string(),
    imei: s.string(),
    make: s.string(),
    model: s.string(),
    name: s.string(),
    tags: s.map(),
    tenant_id: s.string(),
    type: s.string(),
    vehicle_id: s.map({
      id: s.string(),
      href: s.string(),
    }),
    created: s.string(),
    modified: s.string()
  });

  const fleetSpec = s.map({
    id: s.string(),
    name: s.string(),
    default: s.bool(),
    tags: s.map(),
    tenant_id: s.string(),
    type: s.string(),
    created: s.string(),
    modified: s.string()
  });

  const userSpec = s.map({
    email: s.string(),
    enabled: s.bool(),
    first_name: s.string(),
    last_name: s.string(),
    last_login: s.string(),
    realm_id: s.string(),
    realm_name: s.string(),
    roles: s.map(),
    tags: s.map(),
    tenant_id: s.collection(s.string()),
    tenant_name: s.collection(s.string()),
    user_id: s.string(),
    created: s.string()
  });

  return {
    persistentState: s.map({
      'settings': s.map({
        'partition': s.string(),
        'partitions': s.mapOf(s.map({
          'accountId': s.string()
        }))
      })
    }, 'persistentState'),
    theVehiclesResults: s.map({
      results: s.required(s.map({
        'last:vehicle_id': s.required(s.mapOf(s.map({
          speed: s.number(),
          avg_speed: s.number(),
          fuel_level: s.number(),
          odometer: s.number(),
          ignition_status: s.string(),
        })))
      }))
    }, 'theVehiclesResults'),
    storeState: s.map({
      nav: s.map({
        open: s.bool(),
      }),
      entities: s.map({
        assets: s.mapOf(assetSpec),
        devices: s.mapOf(deviceSpec),
        fleets: s.mapOf(fleetSpec),
        users: s.mapOf(userSpec),
      }),
      analytics: s.map({
        timeBack: s.string(),
        fuelEconomy: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
        distanceTraveled: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
        fuelConsumed: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
      }),
      dashboardAnalytics: s.map({
        timeBack: s.string(),
        fuelEconomy: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
        distanceTraveled: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
        fuelConsumed: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
      }),
      systemOfUnits: s.string(),
      locale: s.string(),
      map: s.map({
        center: s.map({
          lat: s.number(),
          lng: s.number(),
        }),
        zoom: s.number(),
        bounds: s.map({
          north: s.number(),
          south: s.number(),
          east: s.number(),
          west: s.number(),
        }),
        initialBounds: s.map({
          north: s.number(),
          south: s.number(),
          east: s.number(),
          west: s.number(),
        }),
        mmapZoom: s.number(),
        hoveredAsset: s.string(),
        hoveredInTime: s.number(),
        fullScreen: s.bool(),
      }),
      assetAnalytics: s.mapOf(s.map({
        fuelEconomy: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
        distanceTraveled: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
        fuelConsumed: s.collection(s.map({
          periodStart: s.string(),
          val: s.number(),
        })),
      })),
      geofences: s.mapOf(geofenceSpec),
      fetchTopics: s.mapOf(s.map({
        isFetching: s.bool(),
        startedFetchAt: s.number(),
        finishedFetchAt: s.number(),
        status: s.string(),
        version: s.number()
      })),
      streamTopics: s.mapOf(s.map({
        isStreaming: s.bool(),
        startedStreamingAt: s.number(),
        finishedStreamingAt: s.number(),
        status: s.string()
      })),
      screenWidth: s.string()
    }, 'StoreState')
  };
}
