import {formatMessage} from "../../utils/reactIntl";

export function setPageTitle(setMobilePageTitle, breadcrumbs) {
  let title = formatMessage({id: 'au.title'});
  let mobileTitle = formatMessage({id: 'au.title'});
  if (Array.isArray(breadcrumbs) && breadcrumbs.length > 0) {
    const breadcrumbString = breadcrumbs.map(({displayId, displayString}) => {
      let message = displayString;
      if (displayId) {
        message = formatMessage({
          id: displayId,
          defaultMessage: displayString
        });
      }
      return message;
    }).join(" - ");
    title += " - " + breadcrumbString;
    mobileTitle = breadcrumbString;
  }

  if (document.title !== title) {
    document.title = title;
  }
  setMobilePageTitle?.(mobileTitle);
}

