import React from "react";
import * as T from 'prop-types';
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import { getTmcLink } from "@au/core/lib/utils/tmcLinks";
import { TMC_METRICS_LINK } from "@au/core/lib/constants";

import { generateUrl } from "../utils/linkHelper";
import { trackableElements, trackEvent } from "../utils/analyticsHelpers";
import { FEED_EVENT_RATES_PATH } from '../constants';
import shared from '../shared';

import styles from "../css/components/feed_event_link.module.scss";

FeedEventLink.propTypes = {
  accountId: T.string.isRequired,
  clientNames: T.arrayOf(T.string),
  flows: T.arrayOf(T.string),
  flowNames: T.arrayOf(T.string),
  tapIds: T.arrayOf(T.string)
};

function mapToQueryParams(paramsArray, paramName) {
  if (paramsArray === undefined || paramsArray.length === 0) {
    return [[ paramName, "All" ]];
  }

  return paramsArray.map(param => [paramName, param]);
}

export function deriveEnvParam(environment, partition) {
  switch (environment) {
    case "dev": {
      return ["dev"]
    }
    case "staging": {
      switch (partition) {
        case("cns") : {
          return ["aws-cn-staging"]
        }
      }

      return ["staging"]
    }
    default : {
      switch (partition) {
        case("cnp0") : 
        case("cnp0a1") : {
          return ["cnp0"]
        }
        case("cnp1") : 
        case("cnp1a1") : {
          return ["cnp1"]
        }
        case("sb1") : {
          return ["sb1"]
        }
      }

      return ["prod"]
    }
  } 
}

export function FeedEventLink(props) {
  const {accountId, clientNames, flowNames, flows, tapIds, entityName} = props;
  const {destination: tmcMetricsUrl} = getTmcLink(TMC_METRICS_LINK, shared.config.baseDomain, shared.config.partition);
  const queryParams = [
    ["orgId", "1"],
    ["refresh", "15m"],
    ["var-account", accountId],
    ...mapToQueryParams(clientNames, "var-clientName"),
    ...mapToQueryParams(flowNames, "var-FlowName"),
    ...mapToQueryParams(flows, "var-Flow"),
    ...mapToQueryParams(tapIds, "var-TapId"),
    ["var-env", ...deriveEnvParam(shared.config.environment, shared.config.partition)]
  ];

  const redirectToTmcMetric = generateUrl(tmcMetricsUrl, FEED_EVENT_RATES_PATH, queryParams);

  function trackAnalytics() {
    trackEvent(
      {
        element: trackableElements.TMC_METRICS_LINK,
        action: entityName,
        page: entityName + "Detail"
      }
    );
  }

  return (
    <div>
      <span className={styles.metric}/>
      <span className={styles.beta}/>
      <AutoIntl
        displayId="au.entity.feedEventLink"
        className={styles.feed_event_link}
        tag="a"
        target="_blank"
        rel="noopener noreferrer"
        href={redirectToTmcMetric}
        onClick={trackAnalytics}
      />
    </div>
  );
}
