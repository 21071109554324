import React, { Component } from 'react';
import { connect } from 'react-redux';

import Popout from '@au/core/lib/components/objects/Popout';
import ScreenWidthDetector from '../components/ScreenWidthDetector';
import PropProvider from '../PropProvider';
import { defaultMapDispatchToProps, defaultMergeProps, defaultConnectEqualityChecks } from '../utils/ConnectDefaults';
import { observableSelector } from '../utils/selectors';
import ReactIntl from '../utils/reactIntl';

import JsonViewer from '../components/JsonViewer';
import ConnectedPopoutEntityView from './PopoutEntityView';
import ConnectedSmsHistoryView from './ConnectedSmsHistoryView';

import { ScreenWidthContext} from '../contexts/screenWidthContext';

// Popout compatible components
const popoutComponents = {
  ConnectedPopoutEntityView,
  ConnectedSmsHistoryView,
  JsonViewer
};

class App extends Component {

  renderPopout() {
    const { popout, actions } = this.props;
    const PopoutChildComponent = popout && popoutComponents[popout.componentName] ||
      (popout && popout.props.viewInPopout === true) && ConnectedPopoutEntityView;

    if (popout && PopoutChildComponent) {
      return (
        <Popout {...popout.props} onCloseClick={popout.props.onCloseClick || actions.closePopout} onResize={actions.persistPopoutWidth}>
          <PopoutChildComponent {...popout.componentProps} popoutContained={true}/>
        </Popout>
      );
    }

    return false;
  }

  render() {
    const { actions, children, screenWidth } = this.props;

    return (
      <ScreenWidthContext.Provider value={screenWidth}>
        <ReactIntl
          /*
            This component is used only to add the ability to import
            "utils/reactIntl" which exposes formatMessage function
            from react-intl package.
          */
        />
        <ScreenWidthDetector screenWidth={screenWidth} onChange={actions.screenWidthChanged} />
        {this.renderPopout()}
        {children}
      </ScreenWidthContext.Provider>
    );
  }
}

const appSelector = observableSelector(state => PropProvider(state).App());

export default connect(
  appSelector,
  defaultMapDispatchToProps, defaultMergeProps, defaultConnectEqualityChecks
)(App);
