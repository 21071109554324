import DefaultList from '../List';
import { fromJS } from "immutable";
import TMC from "@autonomic/browser-sdk";
import { entitiesToMap } from "@au/core/lib/utils/immutableHelpers";
import { feedFiltersSelector } from "../../../utils/feedFilters";
import { enhanceSdkEndpoint } from "../../../utils/api";

export default class VehicleFeedList extends DefaultList {

  constructor(props) {
    super(props);

    this.state.feedFetched = false;
    this.state.groupsFetched = false;
  }

  componentDidMount() {
    super.componentDidMount();

    const { parentEntity } = this.props;
    const vehicle = parentEntity.entity;

    if (!this.state.groupsFetched) {
      this.groupsEndpoint = enhanceSdkEndpoint(
        new TMC.services.Inventory(),
        'groups',
        this.props.actions
      );

      if (vehicle) {
        this.groupsEndpoint.list({ vin: vehicle.getIn(['properties', 'vin']) }).then(
          resp => this.setState({ groupsFetched: true, groups: entitiesToMap(resp.items, 'id') })
        ).catch(this.onError).then(() => this.setState({ groupsFetched: true }));
      }
    }

    if(!this.state.feedFetched) {
      this.getVehicleTaps()
        .then((taps) => this.getVehicleForks().then(forks => this.setState({feedFetched: true, taps, forks})))
        .catch(this.onError).then(() => this.setState({feedFetched: true}));
    }
  }

  async getVehicleTaps() {
    return (await new TMC.services.Feed({apiVersion: 2}).taps.list()).items;
  }

  async getVehicleForks() {
    return (await new TMC.services.Feed({apiVersion: 2}).forks.list({detailed: true})).items;
  }

  getFilteredEntities() {
    const { parentEntity: {entity: vehicle} } = this.props;
    const { groups = fromJS({}), taps = [], forks = [] } = this.state;

    if (!vehicle) {
      return fromJS([]);
    }

    return fromJS(feedFiltersSelector({taps, forks, groups, vehicle}));
  }
}
