import { Map as imMap, OrderedMap, fromJS } from 'immutable';
import hash from 'object-hash';

import TMC from '@autonomic/browser-sdk';
import { keyIn, orderedMapReviver } from '@au/core/lib/utils/immutableHelpers';
import { createDeepEqualSelector } from '@au/core/lib/selectors/general';
import { trackException } from '@au/core/lib/utils/ga';

import {
  policyGroup, policyType, knownPolicyProps, knownStatementProps
} from '../constants';

/**
 * Retrieves IAM v1 perimssions and makes them look as IAM v2 statements
 * @param  {string} objectAui [description]
 * @param  {string} group     [description]
 * @param  {string} pageToken [description]
 * @return {object}           [description]
 */
export function loadPermissions(objectAui, group, pageToken) {
  if (group === policyGroup.DIRECT && objectAui) {
    return new TMC.services.Iam({ apiVersion: '1-alpha' }).permissions.inspect({ objectAui, pageToken }).then(resp => {
      const { data } = resp;
      const statements = [];

      if (data.items) {
        for (let permission of data.items) {
          // mimic as IAM v2 statements
          statements.push({
            subjectAui: permission.subjectAui,
            resourceAui: permission.objectAui,
            entitlements: [ permission.roleAui ],
            description: '', // mock
            creatorAui: permission.creatorAui,
            createTime: permission.createTime,
            _permissionId: permission.id,
            _id: hash(permission.id)
          });
        }
      }

      return { statements, nextPageToken: data.nextPageToken };
    }).catch(error => {
      // sometimes it ends up with 403
      trackException(error.toString());
      return { statements: [] };
    });
  }
  return Promise.resolve({ statements: [] });
}

/**
 * Retrieves IAM v2 statements
 * @param  {string} subjectAui  [description]
 * @param  {string} resourceAui [description]
 * @param  {string} group       [description]
 * @param  {string} pageToken   [description]
 * @return {object}             [description]
 */
export function loadStatements({ subjectAui, resourceAui }, group, pageToken) {
  return new TMC.services.Iam({ apiVersion: 2 }).policies.inspect({ subjectAui, resourceAui, traverse: group, pageToken }).then(resp => {
    const data = resp.data.statements || [];
    const statements = [];

    for (let statement of data) {
      statements.push({ ...statement, _id: hash([statement.subjectAui, statement.resourceAui, statement.effect]) });
    }

    return { statements, nextPageToken: resp.data.nextPageToken };
  }).catch(error => {
    trackException(error.toString());
    return { statements: [] };
  });
}

/**
 * Builds policies from statements
 * @param  {string} pkField   Field to be used for key generation
 * @param  {array} statements An array of statements
 * @param  {string} group     Policy group (`d` - direct, `o` - overlay, `m` - membership)
 * @param  {string} type      Policy type (`subject` or `resource`)
 * @return {object}           An object containing policies - statements grouped by aui (pkField)
 */
export function buildPolicies(pkField, statements, group, type) {
  return statements.reduce((acc, statement) => {
    const aui = statement[pkField];

    if (!acc.has(aui)) {
      acc = acc.set(aui, fromJS({ type, group, statements: {}, [pkField]: aui }), orderedMapReviver);
    }

    return acc.setIn([aui, 'statements', statement._id], statement);
  }, OrderedMap()).toJS();
}

/**
 * Loads statements and builds policies from them
 * @return {object} An object containing policies
 */
export function getPolicies({ subjectAui, resourceAui, pkField, group, type, includePermissions=true}) {
  const inspect = [
    loadStatements({ subjectAui, resourceAui }, group)
  ];

  if (includePermissions) {
    inspect.unshift(
      loadPermissions(resourceAui, group),
    );
  }

  return Promise.all(inspect).then(results => {
    const statements = [].concat(...results.map(res => res.statements));
    return buildPolicies(pkField, statements, group, type);
  });
}

export const cleanupPolicy = createDeepEqualSelector(
  [
    state => state,
    (state, policyProps=knownPolicyProps) => policyProps,
    (state, policyProps, statementProps=knownStatementProps) => statementProps
  ],
  (state, policyProps, statementProps) => {
    const isImmutable = state instanceof imMap;
    // convert to immutable (if it's not) for easier manipulations
    const policy = isImmutable ? state : fromJS(state, orderedMapReviver);
    const filterProp = policy.get('type') === policyType.SUBJECT
                       ? 'resourceAui'
                       : 'subjectAui';

    const result = policy.filter(keyIn(...policyProps))
      .update('statements', statement =>
        // keep only needed statement props
        statement.map(props =>
          props.filter(keyIn(...statementProps, filterProp))
        ).toIndexedSeq()
      );

    // make sure that the type doesn't change
    return isImmutable ? result : result.toJS();
  }
);
