import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import Spinner from "@au/core/lib/components/elements/Spinner";

import styles from '../css/components/banner.module.scss';

export const BANNER_TYPE_INFO_SQUARE    = 'info-square';
export const BANNER_TYPE_INFO_CIRCLE    = 'info-circle';
export const BANNER_TYPE_ALERT   = 'alert';
export const BANNER_TYPE_SUCCESS = 'success';
export const BANNER_TYPE_WARNING = 'warning';
export const BANNER_TYPE_LOADING = 'loading';

export default class Banner extends React.PureComponent {

  static types = [
    BANNER_TYPE_INFO_SQUARE,
    BANNER_TYPE_INFO_CIRCLE,
    BANNER_TYPE_ALERT,
    BANNER_TYPE_SUCCESS,
    BANNER_TYPE_WARNING,
    BANNER_TYPE_LOADING,
  ]

  static propTypes = {
    type: T.oneOf(Banner.types),
    displayId: T.string,
    displayString: T.string,
    className: T.string,
    values: T.object
  }

  static defaultProps = {
    type: BANNER_TYPE_INFO_CIRCLE,
    values: {}
  }

  render() {
    const { type, displayId, displayString, values, children, className } = this.props;

    return (
      <div className={cn(styles.banner, styles[`banner-${type}`], className)}>
        {(displayId || displayString) &&
          <>
            {type === BANNER_TYPE_LOADING && <Spinner className={styles.spinner} />}
            <AutoIntl
              className={styles.message}
              displayId={displayId}
              displayString={displayString}
              tag='div'
              values={{...values, b: text => <b>{text}</b>}}
            />
          </>}
        {children}
      </div>
    );
  }
}
