import React from 'react';
import cn from 'classnames';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import {
  TopologySvg,
  NODE_X_DIM,
  NODE_Y_DIM,
  CIRCLE_NODE_X_DIM,
  CIRCLE_NODE_Y_DIM
} from './utils/topology';

import styles from '../css/components/feed_topology_tooltip_legend.module.scss';

// TODO: handle clicking somewhere else like is done for dev-portal search
export default class FeedTopologyTooltipLegend extends React.PureComponent {
  state = { showTooltip: false };

  componentDidMount() {
    document.addEventListener('click', this.onDocClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocClick);
  }

  onDocClick = this.onDocClick.bind(this);
  onDocClick(e) {
    if (this.state.showTooltip && !e.target.closest('.' + styles.container)) {
      this.setState({ showTooltip: false });
    }
  }

  renderSymbolSvg(href, displayId) {
    // Now that we have multiple sized nodes, use the bigger of them to determine viewbox
    const viewBoxWidth = CIRCLE_NODE_X_DIM > NODE_X_DIM ? CIRCLE_NODE_X_DIM : NODE_X_DIM;
    const viewBoxHeight = CIRCLE_NODE_Y_DIM > NODE_Y_DIM ? CIRCLE_NODE_Y_DIM : NODE_Y_DIM;

    return (
      <div className={styles.entry}>
        <TopologySvg className={styles.symbol} viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}>
          <use href={href} />
        </TopologySvg>
        <AutoIntl displayId={displayId} className={styles.label} />
      </div>
    );
  }

  tooltipRef = this.tooltipRef.bind(this);
  tooltipRef(ref) {
    this.tooltip = ref;
  }

  renderTooltip() {
    return (
      <div className={styles.tooltip} ref={this.tooltipRef}>
        <AutoIntl className={styles.title} displayId="au.topology.usageTitle" tag="div" />
        { this.renderSymbolSvg('#flow', 'au.topologyLegend.nodes.flow') }
        { this.renderSymbolSvg('#flow-inferred', 'au.topologyLegend.nodes.inferredFlow') }
        { this.renderSymbolSvg('#tap', 'au.topologyLegend.nodes.tap') }
        { this.renderSymbolSvg('#tap-paused', 'au.topologyLegend.nodes.pausedTap') }
        { this.renderSymbolSvg('#tap-stopped', 'au.topologyLegend.nodes.stoppedTap') }
        { this.renderSymbolSvg('#tap-inferred', 'au.topologyLegend.nodes.inferredTap') }
        { this.renderSymbolSvg('#processor', 'au.topologyLegend.nodes.processor') }
        { this.renderSymbolSvg('#processor-paused', 'au.topologyLegend.nodes.pausedProcessor') }
        { this.renderSymbolSvg('#processor-stopped', 'au.topologyLegend.nodes.stoppedProcessor') }
        { this.renderSymbolSvg('#processor-inferred', 'au.topologyLegend.nodes.inferredProcessor') }
        { this.renderSymbolSvg('#fork', 'au.topologyLegend.nodes.fork') }
        { this.renderSymbolSvg('#fork-paused', 'au.topologyLegend.nodes.pausedFork') }
        { this.renderSymbolSvg('#fork-stopped', 'au.topologyLegend.nodes.stoppedFork') }
        { this.renderSymbolSvg('#fork-inferred', 'au.topologyLegend.nodes.inferredFork') }
      </div>
    );
  }

  onClick = this.onClick.bind(this);
  onClick() {
    this.setState({ showTooltip: !this.state.showTooltip });
  }

  render() {
    const { className, caption } = this.props;
    const { showTooltip } = this.state;

    return (
      <div className={cn(styles.container, styles.icon, className)} onClick={this.onClick} data-caption={caption}>
        { showTooltip && this.renderTooltip() }
      </div>
    );
  }
}
