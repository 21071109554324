import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Map as imMap } from 'immutable';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import CollapsiblePanel from '@au/core/lib/components/elements/CollapsiblePanel';
import LoadingIndicator from '@au/core/lib/components/elements/LoadingIndicator';
import MultiActionButton from '@au/core/lib/components/elements/MultiActionButton';
import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import AuButton, {
  BUTTON_TYPE_PLAIN,
  BUTTON_SIZE_MEDIUM,
} from '@au/core/lib/components/elements/AuButton';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { policyType, policyGroup, SERVICES_PATH, SERVICE_NAMES } from '../../../constants';
import { history as browserHistory } from '../../../history';
import { formatMessage } from '../../../utils/reactIntl';
import { wrapActionWithTracking } from '../../../utils/analyticsHelpers';
import { cleanupPolicy } from '../../../utils/policies';
import { setPageTitle } from "../../utils/pageTitle";
import SidebarSubviews from "../../SidebarSubview";
import DefaultList from '../List';
import Policies from '../Policies';

import styles from '../../../css/components/policy_list.module.scss';

// swap group names with keys
const groupNames = Object.keys(policyGroup).reduce((acc, key) => {
  acc[policyGroup[key]] = key;
  return acc;
}, {});

export default class PolicyList extends DefaultList {

  initialize() {
    const { parentEntity, actions, breadcrumbs } = this.props;

    if (parentEntity) {
      setPageTitle(actions.setPageTitle, breadcrumbs);
      // errors are handled in DefaultList class
      this.fetchParentEntities().then(() => this._initParentEntity.promise).catch((err) => {createResponseAlertMessage(err); this.setState({error: true})});
    }
    this.setState({ready: true});
    //NOTE: initializing state here, because defining state in this class overrides parent class states
    this.setState({ expanded: false, toggled: false });
  }

  setRef(name, el) {
    if (el) {
      this[name] = el;
    }
  }

  // mock Addons
  getAddons() {
    return [];
  }

  scrollToSection(name) {
    if (this[name]) {
      this[name].scrollIntoView(true);
    }
  }

  handleOnCreatePolicy = this.handleOnCreatePolicy.bind(this);
  handleOnCreatePolicy() {
    //FIXME - MOVE TO linkHelper
    browserHistory.push({
      pathname: this.baseUrl + '/create',
      state: { prevUrl: this.props.match.url }
    });
  }

  onViewBtnClick = this.onViewBtnClick.bind(this);
  onViewBtnClick() {
    const aui = this.getResourceAui();
    //FIXME - MOVE TO linkHelper
    browserHistory.push({
      pathname: this.baseUrl + `/${encodeURIComponent(aui)}/view`,
      state: { prevUrl: this.props.match.url }
    });
  }

  getResourceAui() {
    const { parentEntity } = this.props;

    if (!parentEntity) {
      return '';
    }

    return (
      parentEntity.entityDef.arn ? parentEntity.entityDef.arn + '/' : ''
    ) + parentEntity.entityId;
  }

  getSubjectAui() {
    const { parentEntity } = this.props;

    if (!parentEntity) {
      return '';
    }

    return parentEntity.entity.get('subjectAui') ?? parentEntity.entity.get('aui');
  }

  onExpandAllClick = this.onExpandAllClick.bind(this);
  onExpandAllClick() {
    this.setState({ expanded: true, toggled: true });
  }

  onCollapseAllClick = this.onCollapseAllClick.bind(this);
  onCollapseAllClick() {
    this.setState({ expanded: false, toggled: true });
  }

  renderLinks(groups) {
    const links = [];
    for (let i = 0, len = groups.length; i < len; i++) {
      const groupName = groupNames[groups[i]];
      if (i > 0) {
        links.push(<span key={`separator_${i}`} className={styles.separator} />);
      }
      links.push(
        <AutoIntl
          key={`group_link_${groups[i]}`}
          displayId={`au.policies.groups.${groupName}`}
          onClick={this.scrollToSection.bind(this, groupName)}
          tag="a"
        />
      );
    }

    return (
      <div className={styles.links_wrapper}>
        <div className={styles.links}>
          { links }
        </div>
        <div className={styles.actions}>
          <AuButton
            type={BUTTON_TYPE_PLAIN}
            className={styles.action}
            displayId="au.entity.taps.validation.collapseAll"
            onClick={this.onCollapseAllClick}
          />
          <span className={styles.separator} />
          <AuButton
            type={BUTTON_TYPE_PLAIN}
            className={styles.action}
            displayId="au.entity.taps.validation.expandAll"
            onClick={this.onExpandAllClick}
          />
        </div>
      </div>
    );
  }

  renderPolicies = this.renderPolicies.bind(this);
  renderPolicies(groupKey, data, defaultExpand) {
    const { entityDef, parentEntity, actions, permissions } = this.props;
    const { expanded, toggled } = this.state;
    const { pkField } = entityDef;
    let policyProps;
    if (pkField === 'resourceAui') {
      policyProps = {
        type: policyType.RESOURCE,
        resourceAui: this.getResourceAui()
      };
    } else {
      policyProps = {
        type: policyType.SUBJECT,
        subjectAui: this.getSubjectAui()
      };
    }

    return (
      <Policies {...policyProps}
        data={data}
        group={groupKey}
        parentEntity={parentEntity}
        onCreatePolicy={this.handleOnCreatePolicy}
        actions={actions}
        permissions={permissions}
        expanded={toggled? expanded : defaultExpand}
        defaultExpand={defaultExpand}
      />
    );
  }

  getAui(data) {
    const pkField = data.get('type') === policyType.SUBJECT
                    ? 'subjectAui'
                    : 'resourceAui';
    return data.get(pkField);
  }

  onViewJsonBtnClick = this.onViewJsonBtnClick.bind(this);
  onViewJsonBtnClick(data) {
    AuAnalytics.trackEvent({
      category: 'Button',
      action: 'ViewJson',
      label: 'Policies'
    });

    const { actions } = this.props;
    const entityId = this.getAui(data);

    if (entityId) {
      actions.openEntityJsonViewer(
        entityId,
        cleanupPolicy(data),
        {
          tabsClassName: styles.json_viewer_tabs,
          panelClassName: styles.json_viewer_panel
        }
      );
    }
  }

  onActionBtnClick(action, entityId) {
    if (entityId && action) {
      if (action === 'replicate') {
         this.onReplicateBtnClick(entityId);
      } else {
        //FIXME - MOVE TO linkHelper
        browserHistory.push(`${this.baseUrl}/${encodeURIComponent(entityId)}/${action}`);
      }
    }
  }

  getActions = this.getActions.bind(this);
  getActions(data) {
    const { permissions, entityDef } = this.props;
    const actions = [];
    const entityId = this.getAui(data);

    if(permissions.canEdit === true) {
      actions.push({
        displayId: 'au.entity.edit',
        onClick: this.onActionBtnClick.bind(this, 'edit', entityId)
      });
    }
    if (entityDef.allowReplicate) {
      actions.push({
        displayId: 'au.entity.replicate',
        onClick: this.onActionBtnClick.bind(this, 'replicate', entityId)
      });
    }

    return actions.map(mappedAction =>
      wrapActionWithTracking(mappedAction, 'Policy', 'List')
    );
  }

  renderContent() {
    const { entityDef, parentEntity, entities, screenWidth, disableActions } = this.props;
    const { ready, expanded, toggled, error } = this.state;
    const { includeGroups=[] } = entityDef;
    const isMobile = screenWidth !== "desktop";

    if (!ready) {
      return (
        <div className={styles.container}>
          <LoadingIndicator className={styles.loader} />
        </div>
      );
    }

    // is Public Client?
    if (entityDef.pkField === 'subjectAui' && !parentEntity.entity?.has('subjectAui')) {
      return (
        <div className={styles.info}>
          <FormattedMessage
            id="au.policies.cannotBeCreated"
            values={{
              manageUsers: (
                <NavLink to={SERVICES_PATH + `/${SERVICE_NAMES.ACCOUNTS}/users/list`}>
                  { formatMessage({id: 'au.policies.manageUsers' }) }
                </NavLink>
              )
            }}
          />
        </div>
      );
    }

    const groups = includeGroups.filter(g => Object.values(policyGroup).includes(g));
    // policies by a group (direct/overlay/membership)
    const showGroups = groups.reduce((acc, g) => {
      return acc.set(g, entities.filter(entity => entity.get('group') === g));
    }, new imMap());

    const policies = [];
    for (let [groupKey, data] of showGroups.entries()) {
      const groupName = groupNames[groupKey];
      if (showGroups.size === 1) {
        // don't render groups
        policies.push(this.renderPolicies(groupKey, data, true));
      } else {
        // render policies by group
        let defaultExpand =  false;
        let actionButtonsElement;
        if (groupKey === policyGroup.DIRECT) {
          defaultExpand = true;
          let policyData;
          if (data.size) {
            data.forEach((policy) => {
              policyData = policy;
            });
            actionButtonsElement = (
              <div className={styles.buttons}>
                <AuButton 
                  type={BUTTON_TYPE_PLAIN}
                  size={BUTTON_SIZE_MEDIUM}
                  disabled={disableActions}
                  displayId='au.policies.jsonViewer'
                  onClick= {() => this.onViewJsonBtnClick(policyData)}
                />
                <MultiActionButton 
                  className={styles.actions}
                  actions={this.getActions(policyData)}
                />
              </div>
            );
          }
        }

        policies.push(
          <CollapsiblePanel
            key={`group_${groupName}`}
            ref={el => this.setRef(groupName, el)}
            displayId={`au.policies.groups.${groupName}`}
            className={styles.panel}
            collapsedClassName={styles.collapsed}
            actionButtonsElement={actionButtonsElement}
            expanded={toggled? expanded : defaultExpand}
          >
            { this.renderPolicies(groupKey, data, defaultExpand)}
          </CollapsiblePanel>
        );
      }
    }

    return (
      <div className={styles.container}>
        {Boolean(parentEntity) && <SidebarSubviews
          navLinks={this.getNavLinks()}
          portalRef={this._leftSidebarRef}
          open={this.state.subviewSidebarOpen}
          setOpen={this.setSubviewSidebar}
          isMobile={isMobile}
        />}
        <div className={styles.content_wrapper}>
          {showGroups.size > 1 && !error && this.renderLinks(groups)}
          {!error &&
            <div className={styles.content}>
              {policies}
            </div>
          }
        </div>
      </div>
    );
  }

}
