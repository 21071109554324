import React from "react";
import * as T from "prop-types";

import SelectableList from "@au/core/lib/components/elements/SelectableList";

import { SelectableListProps } from "../../AuPropTypes";

export default class FilterSelectableList extends React.Component {

  static propTypes = {
    defaultValue: SelectableListProps.defaultValue,
    containerRef: SelectableListProps.containerRef,
    selection: SelectableListProps.selection,
    sort: SelectableListProps.sort,
    multiSelect: T.bool,
    items: SelectableListProps.items,
    onChange: T.func,
    onInit: T.func
  }

  onChange = this.onChange.bind(this);
  onChange(items) {
    const {onChange} = this.props;

    if (Array.isArray(items)) {
      onChange(items.map((item) => ({
        urlValue: item.value,
        bubbleDisplayId: item.displayId,
        bubbleText: item.displayString
      })));
    } else {
      onChange([{urlValue: items.value, bubbleDisplayId: items.displayId, bubbleText: items.displayString}]);
    }
  }

  onInit = this.onInit.bind(this);
  onInit(initialItems) {
    const {onInit} = this.props;

    if (Array.isArray(initialItems)) {
      onInit(initialItems.map((item) => ({
        urlValue: item.value,
        bubbleDisplayId: item.displayId,
        bubbleText: item.displayString
      })));
    } else {
      onInit([{
        urlValue: initialItems.value,
        bubbleDisplayId: initialItems.displayId,
        bubbleText: initialItems.displayString
      }]);
    }
  }

  render() {
    const {...rest} = this.props;

    return (
      <SelectableList
        {...rest}
        selection={this.props.selection.map(item => item.urlValue)}
        onChange={this.onChange}
        onInit={this.onInit}
      />
    );
  }
}
