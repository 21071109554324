import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import InfoDialog from '@au/core/lib/components/objects/InfoDialog';

import { NOOP } from '../../constants';

import styles from '../../css/components/entity_success_dialog.module.scss';

export default class SuccessDialog extends React.Component {
  
  static propTypes = {
    nameDisplayId: T.string,
    nameValues: T.object,
    messageDisplayId: T.string,
    messageValues: T.object,
    className: T.string,
    onClose: T.func
  }

  static defaultProps = {
    onClose: NOOP
  }

  render() {
    const { className, onClose, nameDisplayId, messageDisplayId, nameValues, messageValues } = this.props;

    return (
      <InfoDialog
        titleId={"au.entity.success"}
        className={cn(styles.container, className)}
        showClose={false}
        closeClassName={styles.button}
        onClose={onClose}
      >
        <AutoIntl 
          className={styles.info} 
          displayId={nameDisplayId} 
          values={nameValues}
          tag="div"
        />
        <AutoIntl 
          className={styles.description} 
          displayId={messageDisplayId} 
          values={messageValues} 
          tag="div"
        />
      </InfoDialog>
    );
  }
}