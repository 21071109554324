import WrappedMap from './wrappedMap';

// A Set that contains members that can be compared via custom comparison
//  function. O(n) instead of O(1) for most methods.
const missing = Symbol('ComparisonSetMissingKey'); // solves `undefined` as a key
export default class ComparisonMap extends WrappedMap {
  constructor(iterable, comparator) {
    super([]);
    this._cmp = comparator;
    for (let [key, val] of iterable) {
      this.set(key, val);
    }
  }

  __getOurKey(key) {
    // First check if we really have the key
    if (this._map.has(key)) return key;

    // TODO: could trade memory for speed by keeping a map lookup so O(n) only
    //  occurs on first lookup (WeakMap would be ideal)
    for (let ourKey of this.keys()) {
      if (this._cmp(key, ourKey)) {
        return ourKey;
      }
    }
    return missing;
  }

  delete(key) {
    return super.delete(this.__getOUrKey(key));
  }

  get(key) {
    return super.get(this.__getOurKey(key));
  }

  has(key) {
    return super.has(this.__getOurKey(key));
  }

  set(key, value) {
    const ourKey = this.__getOurKey(key);
    if (ourKey === missing) {
      super.set(key, value);
    }
    else {
      super.set(ourKey, value);
    }
  }
}
