import React from 'react';
import * as T from 'prop-types';
import { NOOP } from '../../constants';
import debounce from 'lodash/debounce';

import TMC from "@autonomic/browser-sdk";
import AuDropDown from "@au/core/lib/components/elements/AuDropDown";
import LoadingIndicator from "@au/core/lib/components/elements/LoadingIndicator";
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { SelectableListProps } from '../../AuPropTypes';
import { formatMessage } from '../../utils/reactIntl';

import styles from "../../css/components/filter_segment.module.scss";

export default class CustomCommandType extends React.Component {

  static propTypes = {
    defaultValue: SelectableListProps.defaultValue,
    containerRef: SelectableListProps.containerRef,
    selection: SelectableListProps.selection,
    sort: SelectableListProps.sort,
    multiSelect: T.bool,
    allowTyping: T.bool,
    onChange: T.func,
    onInit: T.func
  };

  static defaultProps = {
    allowTyping: true,
    onChange: NOOP,
    onInit: NOOP,
    selection: []
  };

  state = {customCommandTypeItems: [], hasCustomCommandTypesLoaded: false};
  metaDataEndpoint = new TMC.services.Command({ apiVersion: '1-beta' }).definitions;
  typeRef = React.createRef();

  componentDidMount() {
    this.metaDataEndpoint.uniqueTypes()
      .then(resp => {
        this.setState({
          customCommandTypeItems: [
            ...resp.data.results.map((name) => ({
              displayString: name,
              val: name
            }))
          ]
        });
      }, createResponseAlertMessage)
      .finally(() => {
        this.setState({hasCustomCommandTypesLoaded: true});
        this.props.onInit(this.props.selection.map(sel => {
          let displayId = `au.commands.types.${ sel.urlValue }`;
          let text = formatMessage({ id: displayId });
          if (displayId !== text) {
            sel.bubbleText = text;
          }
          return sel;
        }));
      });
  }

  getTypeOptions = this.getTypeOptions.bind(this);
  getTypeOptions() {
    const { selection } = this.props;
    const { customCommandTypeItems } = this.state;
    const urlValues = selection.map(sel => sel.urlValue);

    return customCommandTypeItems.filter(item => !urlValues.includes(item.val));
  }

  handleOptionSelect = debounce(this.handleOptionSelect, 150).bind(this);
  handleOptionSelect(option) {
    const { selection } = this.props;

    if (option?.trim() && !selection.some(entry => entry.urlValue === option)) {
      this.props.onChange([...selection, {
        urlValue: option,
        bubbleText: option
      }]);
      this.typeRef.current.reset();
      setTimeout(this.typeRef.current.focus);
    }
  }

  render() {
    const typeOptions = this.getTypeOptions();

    return (
      <>
        <LoadingIndicator
          className={ styles.loader }
          displayId="au.commands.typesWait"
          display={!this.state.hasCustomCommandTypesLoaded}
        />
        {typeOptions.length > 0 &&
          <div className={styles.dd_wrapper}>
            <AuDropDown
              ref={this.typeRef}
              className={styles.dropdown}
              options={typeOptions}
              selection={''}
              selectOption={this.handleOptionSelect}
              placeholderId="au.commands.placeholder.type"
              allowTyping={this.props.allowTyping}
              createMode={true}
            />
          </div>
        }
      </>
    );
  }
}
