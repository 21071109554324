import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import Navigation from '@au/core/lib/components/elements/Navigation';

import styles from '../css/components/tabs.module.scss';

export default class Tabs extends React.Component {

  static propTypes = {
    navLinks: T.array,
    className: T.string
  }

  static defaultProps = {
    navLinks: []
  }

  render() {
    const { className } = this.props;
    const navLinks = [];

    if (!this.props.navLinks.length) {
      return false;
    }

    for (let link of this.props.navLinks) {
      navLinks.push({
        ...link,
        className: cn(link.className, { [styles.selected]: link.selected }) 
      });
    }

    return <Navigation className={cn(styles.container, className)} navLinks={navLinks} />;
  }

}
