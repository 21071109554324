import { connect } from 'react-redux';

import EntityLink from '../components/EntityLink';
import { defaultMapDispatchToProps } from '../utils/ConnectDefaults';


export default connect(
  null,
  defaultMapDispatchToProps
)(EntityLink);
