import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Spinner from '@au/core/lib/components/elements/Spinner';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import { formatMessage } from '../utils/reactIntl';

import styles from '../css/components/search_status.module.scss';

export default class ListStatus extends React.PureComponent {
  static propTypes = {
    allDataFetched: T.bool,
    entityType: T.string,
    count: T.number,
    fetching: T.bool,
    cancel: T.bool,
    showLoadAllText: T.bool,
    className: T.string
  }

  static defaultProps = {
    allDataFetched: false,
    fetching: false,
    cancel: false,
    showLoadAllText: false,
    count: 0
  }

  render() {
    const { cancel, showLoadAllText, allDataFetched, count, entityType, fetching, onClick, className } = this.props;
    const classes = cn(className, styles.fetch_status, {
      [styles.success]: allDataFetched,
      [styles.inactive]: !allDataFetched && !fetching && !cancel && showLoadAllText,
      [styles.partial]: !allDataFetched && !fetching && cancel && showLoadAllText, 
      [styles.hide]: fetching || !showLoadAllText
    });

    const loadMoreButton = (
      <AutoIntl 
        displayId="au.searchList.loadMore"
        className={styles.button} 
        onClick={onClick} 
      />
    );

    const values = {
      value: count,
      count: <div className={styles.count}><FormattedNumber value={count} /></div>,
      entityName: ( // single vs plural form
        formatMessage({ id: `au.entity.${count === 1 ? 'name' : 'title'}.${entityType}` })
      ),
      loadMoreButton
    };

    let messageId;

    switch (true) {
      case allDataFetched:
        messageId = 'au.searchList.success.message';
        break;
      case (!allDataFetched && !fetching && showLoadAllText):
        messageId = `au.searchList.${cancel ? 'cancelled' : 'partial'}.message`;
        break;
      default:
        break;
    }

    return (
      <div className={classes}>
        { !showLoadAllText && fetching && !allDataFetched && <Spinner className={styles.spinner} /> }
        { messageId && <FormattedMessage id={messageId} values={values} /> }
      </div>
    );
  }
}