import React, { useState } from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { NOOP } from '../constants';

import styles from '../css/components/simple_table.module.scss';

// walks through the childrens and looks for DragHandle object to assign props
const recursivePassHandleProps = (children, dragHandleProps) => {
  return React.Children.map(children, child => {
    let childProps = {};

    if (React.isValidElement(child) && child.props) {
      if (child.type.prototype === DragHandle.prototype) {
        childProps = { ...dragHandleProps };
      } else if (child.props.children) {
        childProps.children = recursivePassHandleProps(child.props.children, dragHandleProps);
      }
      return React.cloneElement(child, childProps);
    }
    return child;
  });
};

export const Row = React.forwardRef((props, ref) => {
  const { className, isDragging, isDragDisabled, children, ...otherProps } = props;
  const classNames = cn(
    styles.row,
    {
      [styles.dragging]: isDragging,
      [styles.disabled]: isDragDisabled
    },
    className
  );

  return (
    <tr ref={ref} className={classNames} {...otherProps}>
      { children }
    </tr>
  );
});

export const DraggableRow = ({ id, index, isDragDisabled, className, children }) => {
  return (
    <Draggable draggableId={id} isDragDisabled={isDragDisabled} index={index}>
      {(provided, snapshot) => {
        const content = recursivePassHandleProps(children, provided.dragHandleProps);
        return (
          <Row
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            isDragDisabled={isDragDisabled}
            className={className}
            {...provided.draggableProps}
          >{content}</Row>
        );
      }}
    </Draggable>
  );
};

export const ExpandableRow = ({ index, disabled, children, ...otherProps }) => {
  const { className, cellClassName, expansionClassName, expansionRenderer } = otherProps;
  const [ expanded, setExpanded ] = useState(false);

  return [
    <Row key={`row_${index}_main`} className={cn(styles.expandable, className)}>
      <Cell width={15} className={cn(styles.expand_cell, cellClassName)}>
        <a 
          data-togglebtn={expanded ? 'expanded' : 'collapsed'}
          className={cn({ [styles.expanded]: expanded, [styles.collapsed]: !expanded})} 
          onClick={() => !disabled && setExpanded(!expanded)} />
      </Cell>
      {children}
    </Row>,
    <Row key={`row_${index}_expansion`} className={expansionClassName}>
      { expanded && [
        <Cell className={cellClassName} colSpan={children.length + 1} key={`row_${index}_cell_1`}>
          {expansionRenderer()}
        </Cell>
      ]}
    </Row>
  ];
};

export const Cell = ({ width, className, isDragging, children, ...otherProps }) => {
  const style = { width, minWidth: width };
  return (
    <td className={cn(styles.cell, { [styles.dragging]: isDragging }, className)} {...otherProps} style={ style }>
      {children}
    </td>
  );
};

export const HeaderCell = ({ className, displayId, displayString, children, ...otherProps }) => {
  return (
    <Cell className={cn(styles.header_cell, className)} {...otherProps}>
      { children ? children : <AutoIntl displayId={displayId} displayString={displayString} /> }
    </Cell>
  );
};

export const DragHandle = ({ children, ...otherProps }) => {
  return <div {...otherProps}>{children}</div>;
};

export const Table = ({ className, children }) => {
  return (
    <Droppable droppableId="droppable">
      {(provided) => {
        return (
          <table className={className} ref={provided.innerRef} {...provided.droppableProps}>
            <tbody>
              {children}
              {provided.placeholder}
            </tbody>  
          </table>
        );
      }}
    </Droppable>
  );
};

export default class SimpleTable extends React.Component {

  static propTypes = {
    disabled: T.bool,
    className: T.string,
    onDragStart: T.func,
    onDragEnd: T.func,
    onDragUpdate: T.func
  }

  static defaultProps = {
    disabled: false,
    onDragStart: NOOP,
    onDragEnd: NOOP,
    onDragUpdate: NOOP
  }

  render() {
    const { className, disabled, onDragStart, onDragEnd, onDragUpdate, children } = this.props;

    return (
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
        <Table className={cn(styles.table, {[styles.disabled]: disabled}, className)}>
          {children}
        </Table>
      </DragDropContext>
    );
  }

}
