import { connect } from 'react-redux';

import DeviceEntityLink from '../components/DeviceEntityLink';
import { defaultMapDispatchToProps } from '../utils/ConnectDefaults';


export default connect(
  null,
  defaultMapDispatchToProps
)(DeviceEntityLink);
