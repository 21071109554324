import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';

import { wShape } from '@au/core/lib/AuPropTypes';

export const AssetProps = {
  id: T.string,
  vehicle_id: T.string,
  tenant_id: T.string,
  fleet_id: T.string,
  fuel_level: T.number,
  make: T.string,
  model: T.string,
  pid_plugged_in: T.string,
  odometer: T.number,
  location: T.shape({
    lat: T.number,
    lon: T.number
  })
};

export const DeviceProps = {
  id: T.string,
  imei: T.string,
  make: T.string,
  model: T.string,
  name: T.string,
  tags: T.object,
  tenant_id: T.string,
  type: T.string,
  vehicle_id: T.shape({
    id: T.string,
    href: T.string,
  }),
  created: T.string,
  modified: T.string
};

export const FleetProps = {
  id: T.string,
  name: T.string,
  default: T.bool,
  tags: T.object,
  tenant_id: T.string,
  type: T.string,
  created: T.string,
  modified: T.string
};

export const GeoFenceDefProps = {
  id: T.string,
  name: T.string,
  fleet_id: T.string,
  timezone: T.string,
  bounding_box: T.shape({
    north: T.number,
    south: T.number,
    east: T.number,
    west: T.number
  }),
  // bounding_circle: T.shape(),
  bounding_polygon: T.arrayOf(T.shape({
    lat: T.number,
    lon: T.number,
    alt: T.number
  })),
  tags: T.object,
  last_modified_timestamp: T.string
};

export const TableDefProps = {
  tablePkField: T.string,
  sort: wShape({
    columnId: T.string,
    direction: T.string
  }),
  searchbox: T.bool,
  searchboxPlaceholderId: T.string,
  columnDefs: T.arrayOf(wShape({
    property: T.string.isRequired,
    labelId: T.string.isRequired,
    searchable: T.bool,
    sortable: T.bool,
    weight: T.number,
    width: T.number.isRequired,
    display: T.bool,
    flexGrow: T.number,
    flexShrink: T.number,
    formatters: T.oneOfType([
      T.string,
      T.object,
      T.array
    ]),
    processors: T.oneOfType([
      T.string,
      T.object,
      T.array
    ])
  }))
};

export const ResponsiveTablePropTypes = {
  tableDef: T.shape(TableDefProps),
  tableData: IPT.listOf(IPT.map),
  fetching: T.bool
};

export const UserProps = {
  email: T.string,
  enabled: T.bool,
  first_name: T.string,
  last_name: T.string,
  last_login: T.string,
  realm_id: T.string,
  realm_name: T.string,
  roles: T.object,
  tags: T.object,
  tenant_id: T.arrayOf(T.string),
  tenant_name: T.arrayOf(T.string),
  user_id: T.string,
  created: T.string
};

export const Filter = T.shape({
  queryKey: T.string,
  queryValue: T.oneOfType([T.string, T.bool]),
  urlKey: T.string,
  urlValue: T.oneOfType([T.string, T.bool]),
  bubbleText: T.string,
  shouldUseToQuery: T.bool,
  shouldUseInUrl: T.bool,
  shouldDisplayBubble: T.bool,
  removedSelection: T.string
});

export const SelectableListProps = {
  containerRef: T.object,
  sort: T.oneOf(['asc', 'desc']),
  items: T.arrayOf(T.shape({
    displayId: T.string,
    displayString: T.string,
    value: T.oneOfType([T.string, T.number, T.bool]).isRequired,
    groupIdx: T.number
  })),
  selection: T.arrayOf(Filter),
  defaultValue: T.oneOfType([
    T.string,
    T.number,
    T.bool,
    T.arrayOf(T.oneOfType([T.string, T.number, T.bool]))
  ]),
  multiSelect: T.bool,
  limitedHeight: T.bool,
  canDeselect: T.bool,
  onChange: T.func,
  onInit: T.func
};
