import shared from '../../../shared';
import DefaultView from '../View';

export default class UserView extends DefaultView {

  getActions() {
    const actions = super.getActions();
    const { entityDef, permissions } = this.props;
    if (!entityDef.readonly && permissions.canDelete && shared.authClient._userInfo.preferred_username == this.props.entity.get('username')) {
      return actions.filter(action => action.key !== 'entity_delete_btn');

    }
    return actions;
  }
}