import FeedCommonView from './FeedCommonView';

export default class ProcessorView extends FeedCommonView {

  renderContentAboveTable() {
    let content = super.renderAdditionalContent();
    content.push(this.renderTopologyFragment());
    return content;
  }
}
