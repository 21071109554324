import React from "react";
import * as T from "prop-types";
import throttle from "lodash/throttle";
import { injectIntl } from "react-intl";
import moment, { ISO_8601 } from "moment";
import cn from "classnames";

import ProcessingButton from '@au/core/lib/components/elements/ProcessingButton';
import { BUTTON_TYPE_TERTIARY } from "@au/core/lib/components/elements/AuButton";

import { Filter } from "../../AuPropTypes";
import { formatMessage } from '../../utils/reactIntl';
import { arraysEqual } from "../utils/filters";
import {
  checkStateForErrors,
  DATE_FORMAT,
  DATE_LENGTH,
  TIME_MS_FORMAT,
  DEFAULT_TIME_PERIOD, generateCalendarBoundsFromStateDate,
  isoDateToState, isValidISODate,
  stateDateToDisplayString,
  stateDateToMoment,
  TIME_LENGTH,
  TIME_MS_LENGTH
} from "../utils/filterDates";
import Calendar from "../Calendar";
import DateTimeFields from "./DateTimeFields";

import styles from '../../css/components/filter_timeperiod.module.scss';

const DEFAULT_LEGEND = Object.freeze({
  today: {
    displayId: 'au.calendar.today'
  }
});

const MIN_DATE = moment("01/01/1970", DATE_FORMAT);

const initialUtcOffset = moment().tz(moment.tz.guess()).format('Z').substring(1) + ':00';

class TimePeriod extends React.Component {
  static propTypes = {
    className: T.string,
    customProperties: T.shape({
      start: T.string.isRequired,
      end: T.string.isRequired
    }).isRequired,
    maxDate: T.string,
    selection: T.arrayOf(Filter),
    onChange: T.func.isRequired,
    onInit: T.func.isRequired,
    hideCalendar: T.bool,
    timezone: T.string,
    sidebar: T.bool,
    onClick: T.func,
    dateRange: T.bool
  };

  constructor(props) {
    super(props);

    this.throttledOnChange = throttle((s) => props.onChange(s), 1000);
    const customDate = moment(this.props.maxDate, DATE_FORMAT, true);
    this.MAX_DATE = customDate.isValid() ? customDate.add({day: 1}).startOf("day") : moment().add({day: 1}).startOf("day");

    this.state = {
      startDate: {date: "", time: '', timePeriod: DEFAULT_TIME_PERIOD, error: ""},
      endDate: {date: "", time: '', timePeriod: DEFAULT_TIME_PERIOD, error: ""},
      start: undefined,
      end: undefined,
      filters: this.props.selection,
      presetState: '',
      presetEnd: '',
      presetValue: formatMessage({id: 'au.filters.dateRange.select'}),
      removedEnd: false,
      removedStart: false
    }
  }

  componentDidMount() {
    const {selection, onInit, customProperties} = this.props;

    if (Array.isArray(selection)) {
      const matchedItems = [];

      let startDate = {};
      let endDate = {};
      const start = selection.find(item => item?.urlKey === customProperties.start);
      const end = selection.find(item => item?.urlKey === customProperties.end);

      if (start && isValidISODate(start.urlValue)) {
        startDate = isoDateToState(start.urlValue);
        matchedItems.push({
          ...start,
          bubbleText: this.props.intl.formatMessage({id: "au.filters.timePeriod.startSymbol"}) + " " +
            stateDateToDisplayString(startDate)});
      }
      if (end && isValidISODate(end.urlValue)) {
        endDate = isoDateToState(end.urlValue);
        matchedItems.push(
          {...end,
            bubbleText: this.props.intl.formatMessage({id: "au.filters.timePeriod.endSymbol"}) + " " +
              stateDateToDisplayString(endDate)});
      }

      this.updateDateRange(startDate, endDate, true);
      onInit(matchedItems);
    } else {
      onInit([]);
    }
  }

  componentDidUpdate(prevProps) {
    const {selection, customProperties, timezone, presetValue, removedSelection} = this.props;
    const startDate = selection.find(i => i.urlKey === customProperties.start)?.urlValue || stateDateToMoment(this.state.startDate).toISOString();
    const endDate = selection.find(i => i.urlKey === customProperties.end)?.urlValue || stateDateToMoment(this.state.endDate).toISOString();
    const utcTimezone = timezone === 'UTC';
    const startMoment = utcTimezone ? moment.utc(startDate, ISO_8601) : moment(startDate, ISO_8601);
    const endMoment = utcTimezone ? moment.utc(endDate) : moment(endDate);
    const DEFAULT_TIME = utcTimezone ? initialUtcOffset : '12:00:00';

    if (this.state.presetValue === 'Select Range' && this.props.presetValue !== 'Select Range' && this.props.presetValue) {
      this.setState({startDate: {date: "", time: '', timePeriod: DEFAULT_TIME_PERIOD, error: ""}, endDate: {date: "", time: '', timePeriod: DEFAULT_TIME_PERIOD, error: ""}, presetValue: presetValue, filters: []});
    }

    const needToRemove = !this.state.removedEnd || !this.state.removedStart;
    if (removedSelection && removedSelection !== '' && needToRemove) {
      const startRemove = removedSelection.includes('start') || removedSelection.includes('Start');
      const endRemove = removedSelection.includes('end') || removedSelection.includes('End');
      if (startRemove && this.state.startDate.date != '' && !this.state.removedStart) {
        this.setState({startDate: {date: '', time: '', timePeriod: DEFAULT_TIME_PERIOD, error: ''}, removedStart: true});
      }
      if (endRemove && this.state.endDate.date != '' && !this.state.removedEnd) {
        this.setState({endDate: {date: '', time: '', timePeriod: DEFAULT_TIME_PERIOD, error: ''}, removedEnd: true});
      }
    }
    else if (prevProps.selection !== this.props.selection && presetValue === 'Select Range') {
      if (startDate && endDate) {
        const hasStartChanged = !startMoment.isSame(stateDateToMoment(this.state.startDate), 'day');
        const hasEndChanged = !endMoment.isSame(stateDateToMoment(this.state.endDate), 'day');
        if (hasStartChanged) {
          const startTime = this.state.startDate.time || DEFAULT_TIME;
          this.setState({
            startDate: {date: startMoment.format(DATE_FORMAT), time: startTime, timePeriod: DEFAULT_TIME_PERIOD, error: ""}
          });
        }
        if (hasEndChanged) {
          const endTime = this.state.endDate.time || DEFAULT_TIME;
          this.setState({
            endDate: {date: endMoment.format(DATE_FORMAT), time: endTime, timePeriod: DEFAULT_TIME_PERIOD, error: ""}
          });
        }
      }
    }
    if (prevProps.timezone !== this.props.timezone) {
      if (startDate && endDate) {
        this.updateDateRange({ date: startMoment.format(DATE_FORMAT), time: startMoment.format(TIME_MS_FORMAT), timePeriod: DEFAULT_TIME_PERIOD }, { date: endMoment.format(DATE_FORMAT), time: endMoment.format(TIME_MS_FORMAT), timePeriod: DEFAULT_TIME_PERIOD })
      }
    }
  }

  updateFilters = this.updateFilters.bind(this);
  updateFilters(filters) {
    const {selection} = this.props;

    if (!arraysEqual(filters, selection)) {
      this.throttledOnChange(filters);
    }
  }

  isValidDatetime(datetime, isStart) {
    if (!moment(datetime.date).isValid()) {
      if (isStart) {
        this.setState(prevState => ({startDate: {...prevState.startDate, ...{dateError: 'Invalid entry. Please enter a date in the format MM/DD/YYYY.'}}}));
      }
      else {
        this.setState(prevState => ({endDate: {...prevState.endDate, ...{dateError: 'Invalid entry. Please enter a date in the format MM/DD/YYYY.'}}}));
      }
      return false;
    }
    if (parseInt(datetime.time?.slice(0, 2)) > 12 || parseInt(datetime.time?.slice(3, 5)) >= 60 || parseInt(datetime.time?.slice(6)) >= 60) {
      if (isStart) {
        this.setState(prevState => ({startDate: {...prevState.startDate, ...{error: 'Invalid entry. Please enter a time in the format HH:MM:SS.'}}}));
      }
      else {
        this.setState(prevState => ({endDate: {...prevState.endDate, ...{error: 'Invalid entry. Please enter a time in the format HH:MM:SS.'}}}));
      }
      return false;
    }

    const time_length = datetime.time?.length === TIME_LENGTH || datetime.time?.length === TIME_MS_LENGTH;
    if (datetime.date && datetime.date.length === DATE_LENGTH && datetime.time && time_length) {
      return true;
    }
    if (!datetime.date || datetime.date.length != DATE_LENGTH) {
      if (isStart) {
        this.setState(prevState => ({startDate: {...prevState.startDate, ...{dateError: 'Invalid entry. Please enter a date in the format MM/DD/YYYY.'}}}));
      }
      else {
        this.setState(prevState => ({endDate: {...prevState.endDate, ...{dateError: 'Invalid entry. Please enter a date in the format MM/DD/YYYY.'}}}));
      }
      return false;
    }
    if (!datetime.time || !time_length) {
      if (isStart) {
        this.setState(prevState => ({startDate: {...prevState.startDate, ...{error: 'Invalid entry. Please enter a time in the format HH:MM:SS.'}}}));
      }
      else {
        this.setState(prevState => ({endDate: {...prevState.endDate, ...{error: 'Invalid entry. Please enter a time in the format HH:MM:SS.'}}}));
      }
      return false;
    }
  }

  createFilterFromDatetime(datetime, label, propertyKey) {
    let { timezone } = this.props;
    if (!timezone) {
      timezone = moment.tz.guess();
    }
    const displayDate = datetime.date + " " + datetime.time + " " + datetime.timePeriod + " " + moment.tz(timezone)?.zoneName();
    const bubbleText = this.props.intl.formatMessage({id: label}) + " " + displayDate;
    let urlValue;
    if (datetime.time?.length === TIME_MS_LENGTH) {
      urlValue = stateDateToMoment(datetime).toISOString(); 
    }
    else {
      urlValue = stateDateToMoment(datetime).toISOString();
    }

    return {urlKey: propertyKey, urlValue, bubbleText};
  }

  updateDateRange(start, end, disableOnChange = false) {
    this.setState(prevState => ({startDate: {...this.state.startDate, ...start}, endDate: {...prevState.endDate, ...end}}));
    const isStartValid = this.state.startDate.date === "" || this.isValidDatetime(this.state.startDate, true);
    const isEndValid = this.state.endDate.date === "" || this.isValidDatetime(this.state.endDate, false);

    if (!disableOnChange && isStartValid && isEndValid) {
      let { filters }  = this.state;
      if (this.state.start !== "") {
        filters[0] = this.createFilterFromDatetime(start, "au.filters.timePeriod.startSymbol", this.props.customProperties.start);
      }
      if (this.state.end !== "") {
        filters[1] = this.createFilterFromDatetime(end, "au.filters.timePeriod.endSymbol", this.props.customProperties.end);
      }

      this.setState({filters: filters});
    }
  }

  updateStartDate(newState) {
    this.setState(prevState => ({startDate: {...prevState.startDate, ...newState, error: '', dateError: ''}}));
  }

  updateEndDate(newState) {
    this.setState(prevState => ({endDate: {...prevState.endDate, ...newState, error: '', dateError: ''}}));
  }

  validateDateForErrors() {
    const startError = checkStateForErrors(this.state.startDate, MIN_DATE, moment());

    const startDate = stateDateToMoment(this.state.startDate);
    const endError = checkStateForErrors(this.state.endDate, startDate.isValid() ? startDate : MIN_DATE, this.MAX_DATE);

    this.setState(prevState => ({
      startDate: {
        ...prevState.startDate,
        error: startError
      },
      endDate: {
        ...prevState.endDate,
        error: endError
      }
    }));

    return {hasStartError: startError !== "", hasEndError: endError !== ""};
  }

  setError = this.setError.bind(this);
  setError(state, error) {
    this.setState(prevState => ({
      [state]: {
        ...prevState[state],
        error: error
      }
    }));
  }

  checkIncompleteError = this.checkIncompleteError.bind(this);
  checkIncompleteError(state, stateName) {
    if (state.date !== "" && state.date.length !== DATE_LENGTH) {
      this.setError(stateName, "au.validation.incompleteDate");
    }
    else if (state.time !== "" && state.time.length !== TIME_LENGTH) {
      this.setError(stateName, "au.validation.incompleteTime");
    }
  }

  onChange = this.onChange.bind(this);
  onChange(date, start) {
    let DEFAULT_TIME = this.props.timezone === 'UTC' ? initialUtcOffset : '12:00:00';

    if (start) {
      this.setState(prevState => ({start: {...prevState.start, ...date}, startDate: {...prevState.startDate, ...moment(date).format(DATE_FORMAT)}}));
      this.updateStartDate({date: moment(date).format(DATE_FORMAT), time: DEFAULT_TIME, timePeriod: DEFAULT_TIME_PERIOD});
    }
    else {
      this.setState(prevState => ({end: {...prevState.end, ...date}, endDate: {...prevState.endDate, ...moment(date).format(DATE_FORMAT)}}));
      this.updateEndDate({date: moment(date).format(DATE_FORMAT), time: DEFAULT_TIME, timePeriod: DEFAULT_TIME_PERIOD});
    }
  }

  parseDates(datetime) {
    let date = datetime.date;
    let time = datetime.time;
    if (date?.length < 10) {
      if (date[1] === '/') {
        date = '0' + date;
      }
      if (date[4] === '/') {
        date = date?.slice(0, 3) + '0' + date.slice(3);
      }
    }
    if (time?.length < 10) {
      if (time[1] === ':') {
        time = '0' + time;
      }
      if (time[4] === ':') {
        time = time?.slice(0, 3) + '0' + time.slice(3);
      }
    }
    datetime.date = date;
    datetime.time = time;
    return datetime;
  }

  onApply = this.onApply.bind(this);
  onApply() {
    const newStart = this.parseDates(this.state.startDate);
    const newEnd = this.parseDates(this.state.endDate);
    let isStartValid = true;
    let isEndValid = true;

    if (newStart.date === "" && newEnd.date === "") {
      if (newStart.date === "") {
        this.setState(prevState => ({startDate: {...prevState, ...{dateError: 'Invalid entry. Please enter a start date.'}}}));
      }
      if (newEnd.date === "") {
        this.setState(prevState => ({endDate: {...prevState, ...{dateError: 'Invalid entry. Please enter an end date.'}}}));
      }
    }
    else {
      let { filters } = this.state;

      if (this.state.startDate.date !== '') {
        isStartValid = this.isValidDatetime(this.state.startDate, true);
        if (isStartValid) {
          filters[0] = this.createFilterFromDatetime(newStart, "au.filters.timePeriod.startSymbol", this.props.customProperties.start);
        }
      }
      if (this.state.endDate.date !== '') {
        isEndValid = this.isValidDatetime(this.state.endDate, false);
        if (isEndValid) {
          if (filters.length >= 1) {
            filters[1] = this.createFilterFromDatetime(newEnd, "au.filters.timePeriod.endSymbol", this.props.customProperties.end);
          }
          else if (filters[0]?.urlKey === 'endTime') {
            filters[0] = this.createFilterFromDatetime(newEnd, "au.filters.timePeriod.endSymbol", this.props.customProperties.end);
          }
          else {
            filters.push( this.createFilterFromDatetime(newEnd, "au.filters.timePeriod.endSymbol", this.props.customProperties.end));
          }
        }
      }

      if (isStartValid && isEndValid) {
        if (this.state.startDate.date !== '' && this.state.endDate.date !== '') {
          const end = moment(filters[1]?.urlValue);
          const start = moment(filters[0]?.urlValue);
          if (end.isAfter(start)) {
            this.setState(prevState => ({endDate: {...prevState.endDate, ...{dateError: ''}}}));
            this.updateFilters(filters);
          } else if (start.isAfter(end, 'day')) {
            if (filters[0].urlValue?.slice(0, 10) === filters[1].urlValue?.slice(0, 10) && filters[0].bubbleText.includes('12:00:00 am')) {
              this.updateFilters(filters);
            }
            this.setState(prevState => ({endDate: {...prevState.endDate, ...{dateError: 'Invalid entry. It should be later than the start time.'}}}));
          }
          else {
            if (filters[0].bubbleText.includes('12:00:00 am')) {
              this.updateFilters(filters);
            }
            else {
              this.setState(prevState => ({endDate: {...prevState.endDate, ...{dateError: 'Invalid entry. It should be later than the start time.'}}}));
            }
          }
        }
        else {
          this.updateFilters(filters);
        }
      }

      if (this.props.presetFunc) {
        this.props.presetFunc();
      }
      this.setState({filters: filters, presetValue: 'Select Range', removedStart: false, removedEnd: false});
      
    }
  }

  render() {
    const { timezone, sidebar, intl, customProperties, dateRange } = this.props;
    const { startDate, endDate } = this.state;
    const { from, to, maxBound } = generateCalendarBoundsFromStateDate(startDate, endDate, MIN_DATE.toDate());
    let DEFAULT_TIME = timezone === 'UTC' ? initialUtcOffset : '12:00:00';

    return <div className={dateRange ? cn(styles.container, this.props.className, styles.date_range) : cn(styles.container, this.props.className)}>
      <DateTimeFields
        className={sidebar ? undefined : styles.date_time}
        title="au.filters.timePeriod.start"
        description="au.filters.timePeriod.startDescription"
        datetime={startDate}
        updateDatetime={(datetime) => this.updateStartDate(datetime)}
        hasError={startDate.error !== "" && startDate.error !== undefined}
        timezone={timezone}
        sidebar={sidebar}
        intl={intl}
        onChange={this.onChange}
        customProperties={customProperties}
        start={true}
      />
      <DateTimeFields
        className={sidebar ? styles.end_section : styles.date_time}
        title="au.filters.timePeriod.end"
        description="au.filters.timePeriod.endDescription"
        datetime={endDate}
        updateDatetime={(datetime) => this.updateEndDate(datetime)}
        hasError={endDate.error !== "" && endDate.error !== undefined}
        timezone={timezone}
        sidebar={sidebar}
        intl={intl}
        onChange={this.onChange}
        start={false}
      />
      { sidebar && 
        <div className={styles.btn_container}>
          <ProcessingButton
            type={BUTTON_TYPE_TERTIARY}
            onClick={this.onApply}
            displayId={"au.filters.apply"}
          />
        </div>
      }
      {!this.props.hideCalendar && !sidebar &&
        <Calendar
          from={from}
          to={to}
          fromMonth={MIN_DATE.toDate()}
          toMonth={maxBound}
          readOnly={false}
          numberOfMonths={1}
          legend={DEFAULT_LEGEND}
          modifiers={{}}
          canChangeMonth={true}
          onChange={({from, to}) => {
            const isFromFirst = from < to;
            const startDate = moment(isFromFirst ? from : to).format(DATE_FORMAT);
            const endDate = moment(isFromFirst ? to : from).add({days: 1}).format(DATE_FORMAT);
            this.updateDateRange({date: startDate, time: DEFAULT_TIME, timePeriod: DEFAULT_TIME_PERIOD}, {date: endDate, time: DEFAULT_TIME, timePeriod: DEFAULT_TIME_PERIOD});
          }}
        />
      }
    </div>;
  }
}

export default injectIntl(TimePeriod);
