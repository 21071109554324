import React, { PureComponent } from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import { withRouter } from "react-router";

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { entityEvent } from '../constants';

import styles from '../css/components/mobile_page_header.module.scss';

export const PAGE_SCHEMA_LOGO   = 'logo';
export const PAGE_SCHEMA_CREATE = 'create';
export const PAGE_SCHEMA_CANCEL = 'cancel';
export const PAGE_SCHEMA_BACK   = 'back';

export class MobilePageHeader extends PureComponent {

  static schemas = [
    PAGE_SCHEMA_LOGO,
    PAGE_SCHEMA_CREATE,
    PAGE_SCHEMA_CANCEL,
    PAGE_SCHEMA_BACK
  ]

  static propTypes = {
    pageTitle: T.string,
    schema: T.oneOf(MobilePageHeader.schemas),
    location: T.object.isRequired,
    history: T.object.isRequired
  }

  render() {
    const { pageTitle, schema, location, history } = this.props;

    // FIXME - MOVE TO linkHelper
    // FIXME location.hash doesn't work when IndexRedirect is used
    const backBtnClick = location.hash
                         ? () => history.push('/')
                         : history.goBack;

    const showAddBtn    = schema === PAGE_SCHEMA_CREATE;
    const showCancelBtn = schema === PAGE_SCHEMA_CANCEL;
    const showBackBtn   = schema === PAGE_SCHEMA_BACK;
    const showLogo      = !(showAddBtn || showBackBtn || showCancelBtn);

    return (
      <div className={cn(styles.container, { [styles.adjusted]: showLogo })}>
        { showCancelBtn &&
          <div className={styles.back}
            onClick={() => window.dispatchEvent(new CustomEvent(entityEvent.CANCEL_BTN_CLICK))}
          >
            <AutoIntl displayId="au.page.cancel" className={styles.caption} />
          </div>
        }
        { showBackBtn &&
          <div className={styles.back} onClick={backBtnClick}>
            <AutoIntl displayId="au.page.back" className={styles.caption} />
          </div>
        }
        { showAddBtn &&
          <div className={styles.create}
            onClick={() => window.dispatchEvent(new CustomEvent(entityEvent.CREATE_BTN_CLICK))}
          >
            <AutoIntl displayId="au.page.create" className={styles.caption} />
          </div>
        }

        <AutoIntl className={cn(styles.title, {[styles.wide]: showLogo || showAddBtn})} displayString={pageTitle} />
      </div>
    );
  }
}


export default withRouter(MobilePageHeader);