import React from 'react';
import * as T from 'prop-types';
import { Map as imMap } from 'immutable';
import IPT from 'react-immutable-proptypes';
import isEqual from 'lodash/isEqual';
import cn from 'classnames';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import LoadingIndicator from '@au/core/lib/components/elements/LoadingIndicator';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import * as validationRules from '../../utils/validationRules';
import auFormatters from '../../utils/formatters';
import auProcessors from '../../utils/processors';
import auFilters from '../../utils/filters';
import { keyIn } from '../../utils/immutableHelpers';
import { parseCallable } from '../../utils/parse';
import { formatMessage } from '../../utils/reactIntl';
import { loadJoinedResources } from '../../utils/api';
import { skipDisplay, orderByDisplay, shouldHideContent } from '../../utils/entity';
import EntityMultiSaveDialog from '../../containers/EntityMultiSaveDialog';
import { setPageTitle } from "../utils/pageTitle";
import { PAGE_SCHEMA_CANCEL, PAGE_SCHEMA_LOGO } from '../MobilePageHeader';
import { TextInput } from './Inputs';
import * as inputs from './Inputs';
import { EntityEdit } from './Forms';
import { SimpleLayout } from './Layouts';

import styles from '../../css/components/entity_edit.module.scss';

export default class Edit extends React.Component {

  static propTypes = {
    entity: IPT.map,
    endpoint: T.object.isRequired,
    entityDef: T.object.isRequired,
    createMode: T.bool,
    location: T.shape({
      key: T.string,
      pathname: T.string,
      search: T.string,
      hash: T.string,
      state: T.object
    }).isRequired,
    match: T.shape({
      params: T.shape({
        action: T.string.isRequired,
        entityId: T.string
      }).isRequired
    }).isRequired,
    resources: T.instanceOf(Map),
  }

  static defaultProps = {
    createMode: false
  }

  queryParams = {};

  constructor(props) {
    super(props);

    const { entity, match, entityDef, createMode } = props;
    const { action, entityId } = match.params;

    this.createMode = createMode || ['create', 'replicate'].includes(action);

    this.state = {
      entity: Edit.processEntity(entity, entityDef, action),
      fetching: true,
      stickyButtons: false,
      hideForm: false
    };

    // by default, drop /:entityId/:action
    let dropSegments = 2;

    if (!entityId || action === 'create') {
      // drop /:action
      dropSegments = 1;
    }
    else if (action === 'replicate') {
      // drop /-/:entityAlias/:action
      dropSegments = 3;
    }

    this.baseUrl = match.url.split('/').slice(0, -dropSegments).join('/');
    this.parentUrl = this.baseUrl + `/${ entityDef.landingPage ?? 'list'}`;

    this.title = formatMessage(
      { id: this.getActionTextId() },
      { entityName: formatMessage({ id: `au.entity.name.${entityDef.type}` }) }
    );
  }

  getActionTextId() {
    const { action } = this.props.match.params ?? '';
    return `au.entity.action.${action}`;
  }

  static getDerivedStateFromProps(props, state) {
    const { entity, entityDef, resources, match } = props;

    if (entity && (entity.size > state.entity.size || !isEqual(resources, state.resources))) {
      return {
        entity: Edit.processEntity(entity, entityDef, match.params.action),
        resources
      };
    }

    return null;
  }

  /**
   * Filters entity attributes and executes processors defined in entityDef if any
   * @param  {imMap} entity     Original entity
   * @param  {object} entityDef Object containig entity definition (attributes)
   * @param  {string} action    Current action
   * @return {imMap}            Modified entity
   */
  static processEntity(entity, entityDef, action) {
    let modifiedEntity = new imMap(entity);
    let keepFields = [];

    for (let [property, attr] of Object.entries(entityDef.attributes)) {
      // Add current attribute to the list of fields we'd like to keep
      if (attr?.replicate === true) {
        keepFields.push(property);
      }

      if (attr?.processors) {
        const processors = parseCallable(attr.processors);
        let value = modifiedEntity.get(property);

        for (let { func, args } of processors) {
          if (typeof value !== 'undefined' && typeof value[func] === 'function') {
            value = value[func]();
          } else if (typeof auProcessors[func] === 'function') {
            value = auProcessors[func]({ ...args, rowData: entity, property, value });
          }
        }

        modifiedEntity = modifiedEntity.set(property, value);
      }
    }

    // Filter out all the fields not on the list
    if (action === 'replicate') {
      modifiedEntity = modifiedEntity.filter(keyIn(...keepFields));
    }

    return modifiedEntity;
  }

  componentDidMount() {
    const { match, actions, breadcrumbs } = this.props;
    window.addEventListener('resize', this.checkFormHeight);
    createResponseAlertMessage('clearEvents');

    setPageTitle(actions.setPageTitle, breadcrumbs);
    actions.setPageSchema(PAGE_SCHEMA_CANCEL);

    this.loadResourcesData()
      .then(() => {
        if (match.params.action !== 'replicate') {
          return this.fetchParentEntity();
        }
      })
      .then(() => {
        if (!this.createMode) {
          return this.loadData().catch(this.genericErrorHandler);
        }
      })
      .then(this.onAfterFetch, this.onAfterFetch);
  }

  componentWillUnmount() {
    if (this.formObserver) {
      this.formObserver.disconnect();
    }
    window.removeEventListener('resize', this.checkFormHeight);
  }

  componentDidUpdate() {
    const { actions, screenWidth } = this.props;
    this.checkFormHeight();

    if (screenWidth === 'tabletPortrait') {
      actions.setPageSchema(PAGE_SCHEMA_LOGO);
    } else  {
      actions.setPageSchema(PAGE_SCHEMA_CANCEL);
    }
  }

  loadResourcesData() {
    const { entityDef, resources, actions } = this.props;

    const sources = (
      Object.values(entityDef.attributes)
        .filter(attr => !skipDisplay(attr, this.createMode ? 'create' : 'edit') && (attr?.source || attr?.sources))
        .filter(attr => !shouldHideContent(attr))
        .flatMap(attr => attr.source || attr.sources)
    );

    /*
      sources - contains service/entity definitions from where data needs to be loaded
      resources - contains actual data that was already loaded (based on `sources`)
     */
    return loadJoinedResources(sources, resources, actions);
  }

  fetchParentEntity = this.fetchParentEntity.bind(this);
  fetchParentEntity() {
    const { parentEntity } = this.props;
    // If we don't have our parent entity, fetch first for breadcrumb data and similar
    if (parentEntity && !parentEntity.entity && parentEntity.endpoint.actions.includes('get')) {
      return parentEntity.endpoint.get(parentEntity.entityId, parentEntity.entityDef.queryParams);
    }
    return Promise.resolve();
  }

  setFormRef = this.setFormRef.bind(this);
  setFormRef(ref) {
    if (ref) {
      this.formRef = ref;
      this.formObserver = new MutationObserver(this.checkFormHeight);
      this.formObserver.observe(this.formRef, {
        attributes: true,
        childList: true,
        subtree: true
      });
    }
  }

  checkFormHeight = this.checkFormHeight.bind(this);
  checkFormHeight() {
    if (this.formRef) {
      const container = this.formRef.closest('main');
      if (container) {
        const panelEl   = this.formRef.firstElementChild;
        const buttonsEl = this.formRef.lastElementChild;
        const diff = container.clientHeight - panelEl.offsetTop - panelEl.scrollHeight;

        if (!this.state.stickyButtons && diff < buttonsEl.clientHeight / 2) {
          this.formRef.style.maxHeight = `${container.clientHeight - buttonsEl.clientHeight}px`;
          this.formRef.style.overflow = 'scroll';
          this.setState({ stickyButtons: true });
        } else if (this.state.stickyButtons) {
          if (diff >= buttonsEl.clientHeight / 2) {
            this.formRef.style.maxHeight = 'none';
            this.formRef.style.overflow = 'none';
            panelEl.style.marginBottom = 0;
            this.setState({ stickyButtons: false });
          } else {
            this.formRef.style.height = `${container.clientHeight - panelEl.offsetTop - buttonsEl.clientHeight / 2}px`;
            panelEl.style.marginBottom = '40px';
          }
        }
        else {
          this.formRef.style.maxHeight = 'none';
          this.formRef.style.overflow = 'none';
          this.formRef.style.height = 'unset';
        }
      }
    }
  }

  loadData() {
    const { endpoint, match } = this.props;
    const { entityId } = match.params;

    if (!entityId) {
      return Promise.reject();
    }

    // fallback for endpoint that doesn't support .get() action
    if (endpoint.actions.includes('get')) {
      return endpoint.get(entityId, this.queryParams);
    } else {
      // NOTE we should consider dropping this, since it will work only if the
      // requested object is returned within the first page
      return endpoint.list();
    }
  }

  onAfterFetch = this.onAfterFetch.bind(this);
  onAfterFetch() {
    this.setState({ fetching: false });
  }

  parseFieldRules(inputRules) {
    const rules = [];

    if (inputRules) {
      parseCallable(inputRules).forEach(({ func, args }) => {
        if (func in validationRules) {
          let fn = validationRules[func];
          rules.push(Array.isArray(args) && args.length || args && Object.keys(args).length ? fn(...args) : fn);
        } else {
          /* eslint-disable no-console */
          console.warn('Validation rule not found: ', func, args);
          /* eslint-enable no-console */
        }
      });
    }

    return rules;
  }

  mapControl(field, attr, readonly) {
    if (skipDisplay(attr, this.createMode ? 'create' : 'edit')) {
      return;
    }
    if (shouldHideContent(attr)) {
      return;
    }

    const { resources } = this.props;
    const rules = this.parseFieldRules(attr?.rules);
    const commonProps = {
      field,
      key: field,
      labelId: attr?.labelId ?? `au.entity.attr.${field}`,
      createMode: this.createMode,
      placeholder: attr?.placeholder,
      placeholderId: attr?.placeholderId,
      validationRules: rules
    };

    if (attr?.source?.service && attr?.source?.entity) {
      const { entity } = this.state;
      const { filter, idProperty } = attr.source;
      let resource = resources.get(`${attr.source.service}-${attr.source.entity}`, imMap());

      if (filter?.type && auFilters[filter.type]) {
        resource = resource.filter(obj => auFilters[filter.type](obj, filter));
      }

      // in the case when we have an `id` field that we can't map to the resources - disable the field.
      // usually it happens when the user don't have access to the particular resource.
      if (!this.createMode && idProperty && !resource.some(obj => obj.get(idProperty) === entity.get(field))) {
        commonProps.defaultOption = entity.get(attr.lookup?.by ?? field);
        commonProps.placeholder = entity.get(attr.lookup?.by ?? field);
        commonProps.disabled = true;
      }
      else {
        commonProps.source = attr.source;
        commonProps.resource = resource;
      }
    }

    let autoFocus;
    if (attr?.autoFocus) {
      if (this._autoFofocused && this._autoFofocused !== field) {
        /* eslint-disable no-console */
        console.warn('Multiple "autofocus" fields detected. Ignore field: "' + field + '"');
        /* eslint-enable no-console */
      } else {
        this._autoFofocused = field;
        autoFocus = true;
      }
    }

    let control;
    let customInputDef;

    if (typeof attr?.display === 'object') {
      let display = this.createMode ? attr.display.create : attr.display.edit;
      if (typeof display === 'object') {
        customInputDef = display;
      }
    }

    if (customInputDef) {
      if (Object.prototype.hasOwnProperty.call(inputs, customInputDef.component)) {
        const Component = inputs[customInputDef.component];
        control = <Component {...commonProps} {...customInputDef.props} />;
      } else {
        throw(`Cannot map control to unknown component "${customInputDef.component}"`);
      }
    } else {
      control = <TextInput {...commonProps} disabled={readonly} autoFocus={autoFocus} />;
    }

    return control;
  }

  // Used by this.mapControl when an attribute uses a formatter that returns a react component
  // this will find and return the formatter value, for example <BoundState/>
  getFormattedValue(property, attr) {
    const { entity, resources, popoutContained } = this.props;
    const { entityAlias, action } = this.props.match.params;
    const formatters = parseCallable(attr.formatters);
    const processors = parseCallable(attr.processors);
    let value = entity.get(property, '');

    for (let { func, args } of processors) {
      if (typeof value !== 'undefined' && typeof value[func] === 'function') {
        value = value[func]();
      } else if (typeof auProcessors[func] === 'function') {
        value = auProcessors[func]({ ...args, rowData: entity, property, value });
      }
    }

    for (let { func, args: origArgs } of formatters) {
      if (typeof value !== 'undefined' && value !== null && typeof value[func] === 'function') {
        // formatter is a native function
        value = value[func]();
      } else if (typeof auFormatters[func] === 'function') {
        // copy args, add tracking
        const args = {
          ...origArgs,
          action,
          tracking: {
            page: `${entityAlias}Edit`
          },
          popoutContained // formatters can handle rendering differently when in Popout
        };

        if (func === 'date' && !args.timezone) {
          args.timezone = this.props.timezone;
        }
        value = auFormatters[func]({ ...args, rowData: entity, property, value, resources, popoutContained });
      }
    }

    return value;
  }

  getFormFields() {
    const { entityDef } = this.props;
    const { entity } = this.state;

    const fields = [];
    for (let [property, attr] of orderByDisplay(entityDef.attributes, 'edit')) {
      const isPk = property === entityDef.pkField;
      const readonly = Boolean(
        (!this.createMode && (attr?.readonly ?? isPk)) ||
        (this.createMode && attr?.map && entity.has(property))
      );
      const control = this.mapControl(property, attr, readonly);

      if (control) {
        fields.push(control);
      }
    }
    return fields;
  }

  // there are no processors by default (for overriding purposes)
  getEntityProcessors() {
    return [];
  }

  genericErrorHandler = this.genericErrorHandler.bind(this);
  genericErrorHandler(error) {
    // Calling method here so we can override this function
    if (error && error._data?.code === 403) {
      this.setState({ hideContent: true });
    }
    createResponseAlertMessage(error);
  }

  renderForm() {
    const { entityDef, endpoint, match, actions, screenWidth, location } = this.props;
    const { prevUrl } = location.state ?? {};
    const fields = this.getFormFields();

    return (
      <EntityEdit
        formRef={this.setFormRef}
        className={cn(styles.form, { [styles.sticky]: this.state.stickyButtons })}
        layout={screenWidth === 'desktop' ? 'table' : 'list'}
        entity={this.state.entity}
        entityDef={entityDef}
        entityType={entityDef.type}
        entityExists={!this.createMode}
        entityProcessors={this.getEntityProcessors}
        endpoint={endpoint}
        saveHandler={this.handleOnSave}
        saveSuccessDestination={prevUrl ?? this.parentUrl}
        cancelHandler={this.handleOnCancel}
        cancelDestination={prevUrl ?? this.parentUrl}
        action={match.params.action}
        actions={actions}
        errorHandler={this.genericErrorHandler}
        disableSaveBtn={this.disableSaveBtn}
      >
        <SimpleLayout>
          { fields }
        </SimpleLayout>
      </EntityEdit>
    );
  }

  getEntitiesToCreate() {
    return new Map();
  }

  onStatusDialogClose = this.onStatusDialogClose.bind(this);
  onStatusDialogClose() {
    this.setState({ showStatusDialog: false });
  }

  getDisplayIdOverrides() {
    return false;
  }

  renderDialogs = this.renderDialogs.bind(this);
  renderDialogs() {
    const dialogs = [];

    if (this.state.showStatusDialog) {
      dialogs.push(
        <EntityMultiSaveDialog
          key="entity_multi_save_dialog"
          titleString={formatMessage(
            { id: 'au.entity.status.title' },
            { title: this.title }
          )}
          url={this.baseUrl}
          entitiesToCreate={this.getEntitiesToCreate()} //Running every render probably needs to be refactored
          onClose={this.onStatusDialogClose}
          displayIdOverrides={this.getDisplayIdOverrides()}
        />
      );
    }

    return dialogs;
  }

  renderSummary = this.renderSummary.bind(this);
  renderSummary() {
    return (
      <></>
    );
  }

  getTitle() {
    const { entity, entityDef, match } = this.props;
    let title = this.title;

    if (match.params.action === 'replicate') {
      return title += ' : ' + entity.get(entityDef.crumbProp || entityDef.pkField);
    }
    return title;
  }

  render() {
    const { fetching } = this.state;
    return (
      <div className={cn("o-wrapper", styles.container)}>
        <AutoIntl
          className={styles.title}
          displayString={this.getTitle()}
          tag="h2"
        />
        <LoadingIndicator display={fetching} />
        { !fetching && this.renderForm() }
        { this.renderSummary() }
        { this.renderDialogs() }
      </div>
    );
  }

}
