import { connect } from 'react-redux';

import PropProvider from '../PropProvider';
import FeedTopology from '../components/FeedTopology';
import { defaultMapDispatchToProps } from '../utils/ConnectDefaults';

export default connect(
  state => PropProvider(state).FeedTopology(),
  defaultMapDispatchToProps
)(FeedTopology);
