// NOTE: cannot extend builtins until we upgrade babel
//  https://github.com/babel/babel/issues/4480
//  https://github.com/babel/babel/pull/7020/files
export default class WrappedMap {
  constructor(iterable) {
    this._map = new Map(iterable);
  }

  get size() {
    return this._map.size;
  }

  clear() {
    return this._map.clear();
  }

  delete(key) {
    return this._map.delete(key);
  }

  entries() {
    return this._map.entries();
  }

  forEach(...args) {
    return this._map.forEach(...args);
  }

  get(key) {
    return this._map.get(key);
  }

  has(key) {
    return this._map.has(key);
  }

  keys() {
    return this._map.keys();
  }

  set(key, value) {
    return this._map.set(key, value);
  }

  values() {
    return this._map.values();
  }

  *[Symbol.iterator]() {
    yield *this._map[Symbol.iterator]();
  }
}
