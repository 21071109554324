import { connect } from 'react-redux';

import PropProvider from '../PropProvider';
import InputOutputTable from '../components/InputOutputTable';
import { defaultMapDispatchToProps } from '../utils/ConnectDefaults';
import { SERVICE_NAMES } from "../constants";

const joinResources = Object.freeze([
  { service: SERVICE_NAMES.ACCOUNTS, entity: 'clients' },
  { service: SERVICE_NAMES.IAM, entity: 'roles' }
]);

export default connect(
  (state, ownProps) => PropProvider(state).InputOutputTable({...ownProps, joinResources}),
  defaultMapDispatchToProps
)(InputOutputTable);