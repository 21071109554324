import React from 'react';
import cn from 'classnames';

import SearchBox from '@au/core/lib/components/elements/SearchBox';

import { formatMessage } from '../utils/reactIntl';
import styles from '../css/components/searchable_tooltip.module.scss';

export default class SearchableTooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      result: []
    };
  }

  filterChildren = this.filterChildren.bind(this);
  filterChildren(searchText) {
    let queryMatch = [];
    let overflow = [];
    const { children } = this.props;
    this.setState({ result: overflow.push(children.join(', ')) });
    if (!searchText) {
      this.setState({ result: [] });
    }
    else {
      let searchQuery = searchText.toUpperCase();
      children.forEach((el) => {
        let query = el.includes(searchQuery);
        if (query === true) {
          queryMatch.push(el);
        }
      });
      if (!queryMatch.length) {
        this.setState({ result: formatMessage({ id: 'au.noResultsFound' }) });
      } else {
        let renderQueryMatch = queryMatch.join(', ');
        this.setState({ result: renderQueryMatch });
      }
    }
  }

  renderText() {
    const { children } = this.props;
    const { result } = this.state;
    let values = [];

    if (result.length > 0) {
      return result;
    } else {
      values.push(children.join(', '));
      return values;
    }
  }

  render() {
    const { position } = this.props;

    return <div className={cn(styles.container, styles[position])} style={this.props.style}>
      <div className={styles.tooltip}></div>
      <SearchBox
        className={styles.searchbox}
        onChange={this.filterChildren}
        placeholderId='au.search'
        onClick={this.clearSearch}
      />
      <div className={styles.text}>
        {this.renderText()}
      </div>
    </div>;
  }
}