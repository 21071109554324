import { get } from '../../../utils/api';
import {getParentEntityAwarePath} from '../../../utils/entity';
import { generateIdSerializer } from '../../../utils/serializers';

import DefaultList from '../List';

export default class ConnectivityHistoryList extends DefaultList {

    list() {
        return this.fetchHistory();
    }

    fetchHistory = this.fetchHistory.bind(this)
    async fetchHistory() {
        const entityId = this.props.parentEntity.entityId;

        return get(`/v1alpha/troubleshoot/connectivity-history/${entityId}`)
        .then(resp => resp, (err) => {
            if (err.data.code === 404) {
                //404s will be handled as a "No data response"
                return err;
            }
            else {
                this.setState({ errorStatusCode: err.data.code, errorStatusMessage: err.data.message });
                throw err;
            }
        });
    }

    onListSuccess = this.onListSuccess.bind(this);
    onListSuccess(resp) {
        const { parentEntity, actions, entityAlias } = this.props;

        const history = resp.data?.device_connectivity_history?.map((obj) => {
                return ({'_id': generateIdSerializer([obj.connectivity_state_time, obj.connectivity_source]),
                        'time' : obj.connectivity_state_time, 'status': obj.connectivity_state,
                        'connectivitySource':  obj.connectivity_source,
                        'disconnectReason': obj.disconnect_reason});
        }).flatMap(obj => obj);

        const path = [
        ...getParentEntityAwarePath(parentEntity, entityAlias),
        'connectivityHistory'
        ];

        actions.listEntitiesSuccess({
        path,
        data: history,
        pkField: '_id',
        replace: true
        });
    }
}