import React from 'react';
import cn from 'classnames';

import TMC from '@autonomic/browser-sdk';
import { BUTTON_TYPE_SECONDARY } from '@au/core/lib/components/elements/AuButton';

import { history as browserHistory } from '../../../history';
import { TOPOLOGY_PATH } from '../../../constants';
import { isValidTopology } from '../../../utils/topologyValidation';
import { generateEntityPath, shouldHideContent } from '../../../utils/entity';
import { wrapActionWithTracking } from '../../../utils/analyticsHelpers';
import { handleTapFiltersReplication } from '../../utils/taps';
import { FeedEventLink } from '../../FeedEventLink';
import ValidationMessage from '../../ValidationMessage';
import StartDialog from "../StartDialog";
import FeedCommonView from './FeedCommonView';

import styles from '../../../css/components/entity_view.module.scss';

export default class TapView extends FeedCommonView {

  topologyEndpoint = new TMC.services.Topology({ apiVersion: '1' }).topology;

  constructor(props) {
    super(props);

    const validationResults = props.entity.get('validationResults');

    this.state = {
      ...super.state,
      validationResults: validationResults,
      validationInProgress: null,
      isValidTopology: validationResults ? isValidTopology(validationResults) : null,
      showPauseDialog: false,
      viewHidden: false
    };
  }

  componentDidMount() {
    super.componentDidMount();

    const { location } = this.props;
    const { prevUrl } = location.state ?? {};

    if (prevUrl) {
      let action = (prevUrl.split('/')).pop();
      if (action === 'create') {
        setTimeout(() => this.validateTopology(), 8000);
      }
    }
  }

  onBeforeReplicate = this.onBeforeReplicate.bind(this);
  onBeforeReplicate(entity, isSamePartition) {
    return handleTapFiltersReplication(entity, isSamePartition);
  }

  renderDialogs() {
    const dialogs = super.renderDialogs();

    dialogs.push(
      <StartDialog
        key={"start-tap-dialog"}
        show={this.state.showStartDialog}
        onCancel={() => this.setState({ showStartDialog: false })}
        onConfirm={this.confirmStart}
        titleDisplayId={"au.tap.start"}
        confirmDisplayId={"au.tap.start"}
        headerDisplayId={"au.tap.start"}
        messageDisplayId={"au.tap.message"}
      />
    );

    return dialogs;
  }

  confirmStart = this.confirmStart.bind(this);
  confirmStart(pointInTime) {
    const { endpoint } = this.props;
    const { id } = this.state;

    endpoint.start(id, pointInTime)
      .then(
        this.updateTap,
        this.genericErrorHandler
      ).then(() => this.setState({ showStartDialog: false, id: undefined }));
  }


  startTapBtn(id) {
    this.setState({ showStartDialog: true, id });
    return this.renderDialogs;
  }

  pauseTapBtn(id) {
    const { endpoint } = this.props;

    return endpoint.pause(id)
      .then(this.updateTap, this.genericErrorHandler)
      .then(this.setState(prevState =>
        ({ showPauseDialog: !prevState.showPauseDialog })
      ));
  }

  updateTap = this.updateTap.bind(this);
  updateTap(resp, fieldPath=[], replace=false) {
    const { endpoint, serviceAlias, actions, popoutContained, popoutProps } = this.props;
    const path = [...generateEntityPath(endpoint, serviceAlias), this.entityId, ...fieldPath];

    if (popoutContained && popoutProps.onStateChange) {
      popoutProps.onStateChange(resp.data);
    }

    return actions.patchEntitySuccess({ path, data: resp.data, replace });
  }

  onViewTopology = this.onViewTopology.bind(this);
  onViewTopology() {
    const { entity, entityDef } = this.props;
    // Use tapAui or flowAui for linkage to topology if entityId is missing
    const entityId = !this.entityId
      ? entity.get(`${entityDef.type}Aui`)
      : this.entityId;

    //FIXME - MOVE TO linkHelper
    browserHistory.push(`${TOPOLOGY_PATH}/${entityId}`);
  }

  validateTopology = this.validateTopology.bind(this);
  validateTopology() {
    const { entityDef } = this.props;
    const { validationInProgress } = this.state;

    if (shouldHideContent(entityDef.subviews.validation)) {
      return;
    }

    if (!validationInProgress) {
      this.setState({
        validationInProgress: true
      });

      this.topologyEndpoint.validate({
        id: this.entityId,
        name: this.props.entity.get('displayName'),
        entityType: 'TAP'
      }).then(({ data }) => {
        this.updateTap({ data: data.results }, ['validationResults'], true);

        this.setState({
          isValidTopology: isValidTopology(data.results),
          validationResults: data,
          validationInProgress: false
        });
      }, error => {
        this.setState({ validationInProgress: false });
        this.genericErrorHandler(error);
      });
    }
  }

  renderFeedEventLink = this.renderFeedEventLink.bind(this);
  renderFeedEventLink() {
    const {entity: entityProp, entityDef} = this.props;
    const entity = entityProp.toJS();

    return <FeedEventLink
      accountId={entity.accountId}
      entityName={entityDef.type}
      tapIds={[entity.id]}
      flows={[entity.outputFlowId]}
    />;
  }

  // TODO: How can we make this more compositional
  renderContentAboveTable() {
    let content = super.renderAdditionalContent();
    content.push(this.renderValidationMessage());
    content.push(this.renderTopologyFragment());
    return content;
  }

  renderValidationMessage() {
    const { entityDef } = this.props;
    const { validationInProgress, isValidTopology } = this.state;

    if (shouldHideContent(entityDef.subviews.validation)) {
      return false;
    }

    return (
      <ValidationMessage
        className={cn(styles.no_margin_top, styles.bottom_gap_24)}
        prefixMsg="au.entity.taps.validation.topology.message"
        infoMsg="info.withLink"
        validMsg="success"
        invalidMsg="invalid.withLink"
        inProgressMsg="validating"
        valid={isValidTopology}
        inProgress={validationInProgress}
        values={{
          b: txt => <strong>{txt}</strong>,
          a: (txt) => <a onClick={() => {
            if (!this.state.validationResults) {
              this.validateTopology();
            }
            else if (!isValidTopology) {
              browserHistory.push(`${this.baseUrl}/${this.entityId}/validation`);
            }
          }}>{ txt }</a>
        }}
      />
    );
  }

  getActions() {
    const actions = super.getActions();
    const { entity, entityDef, popoutContained } = this.props;
    const id = entity.get('id');
    const tapState = entity.get('state');

    const pauseTap = wrapActionWithTracking(
      {
        key: 'pause_tap',
        type: BUTTON_TYPE_SECONDARY,
        className: styles.button,
        displayId: 'au.taps.pause',
        onClick: this.pauseTapBtn.bind(this, id)
      },
      'Tap',
      'View'
    );

    const startTap = wrapActionWithTracking(
      {
        key: 'start_tap',
        type: BUTTON_TYPE_SECONDARY,
        className: styles.button,
        displayId: 'au.taps.start',
        onClick: this.startTapBtn.bind(this, id)
      },
      'Tap',
      'View'
    );

    const validationCheck = wrapActionWithTracking(
      {
        key: 'validation_check_btn',
        type: BUTTON_TYPE_SECONDARY,
        className: styles.button,
        displayId: 'au.entity.validationCheck',
        disabled: this.state.validationInProgress,
        onClick: this.validateTopology
      },
      'Tap',
      'View'
    );

    if (!popoutContained) {
      // Validation is currently unavailable on the topology page
      if (!shouldHideContent(entityDef.subviews?.validation)) {
        actions.push(validationCheck);
      }
    }

    if (['RUNNING', 'PAUSED', 'REPLAY'].includes(tapState)) {
      const isRunning = tapState === 'RUNNING' || tapState === 'REPLAY';

      actions.splice(1, 0, isRunning ? pauseTap : startTap);
    }

    return actions;
  }

}
