import React from 'react';
import { injectIntl } from "react-intl";

import TMC from '@autonomic/browser-sdk';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { enhanceSdkEndpoint } from '../../../utils/api';
import { FLOW_ARN } from '../../../constants';
import { history as browserHistory } from '../../../history';
import { redirectOnSaveSuccess } from '../../../utils/linkHelper';
import { getNewFlowEntities } from "../../../utils/flowCreate";
import { UUID_RE } from '../../../constants';
import {
  hasPermittedFilter,
  isValidVin
} from '../../utils/taps';
import Tabs from '../../Tabs';
import DefaultCreate from '../Edit';

const MODE_MANUAL = '#manual';
const MODE_ADVANCE = '#advance';
const MODES = [MODE_MANUAL, MODE_ADVANCE];

const TABS_ENABLED = false;

class ForkCreate extends DefaultCreate {

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener('popstate', this.enforceMode);
    this.enforceMode();
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.enforceMode);
  }

  enforceMode() {
    // copying state from redirect
    const mode = window.location.hash;
    if (!mode || !MODES.includes(mode)) {
      browserHistory.replace({
        hash: MODE_MANUAL,
        state: this.props.location.state
      });
    }
  }

  renderAdvanceView() {
    // TODO implement
    return (
      <div></div>
    );
  }

  renderManualView() {
    return super.renderForm();
  }

  getEntitiesToCreate() {
    const { serviceAlias, entityDef, match, actions } = this.props;
    const { modifiedEntity } = this.state;
    const { entityAlias } = match.params;

    this.flowEndpoint = enhanceSdkEndpoint(
      new TMC.services.Feed(),
      'flows',
      actions
    );

    const newFork = {
      displayName: modifiedEntity.displayNameView,
      input: { aui: `${FLOW_ARN}/${modifiedEntity.inputFlowId}` },
      businessUnit: modifiedEntity.businessUnit,
      region: modifiedEntity.region,
      outputs: Object.values(modifiedEntity.outputCreate).map(output => ({
        aui: `${FLOW_ARN}/${output.aui}`,
        filters: output.filters,
        businessUnit: output.businessUnit,
        region: output.region
      })),
      state: modifiedEntity.state
    };

    let entitiesToCreate = super.getEntitiesToCreate();
    let createdGroups = new Map();

    if (modifiedEntity.outputCreate) {
      const { entitiesToCreate: newFlowsGroups, newGroupsCreated } = getNewFlowEntities(Object.values(modifiedEntity.outputCreate), actions);
      entitiesToCreate = new Map([...entitiesToCreate, ...newFlowsGroups]);
      createdGroups = newGroupsCreated;
    }

    entitiesToCreate.set(
      `${serviceAlias}-${entityAlias}`,
      {
        entityType: entityDef.type,
        entities: [{
          key: `${serviceAlias}-${entityAlias}-new`,
          idProp: entityDef.pkField,
          data: { ...newFork },
          saveFn: (data) => {
            const modifiedData = { ...data };

            //Apply the ids from the newly created groups
            modifiedData.outputs = modifiedData.outputs.map(output => {
              const filters = output.filters.map(filter => {
                if (filter?.groupFilter?.groupName) {
                  filter.groupFilter = { ...filter.groupFilter, groupId: createdGroups.get(filter.groupFilter.groupName)?.id };
                }
                return filter;
              });
              return { ...output, filters };
            });

            return this._saveEntity(modifiedData, resp => resp, error => Promise.reject(error));
          },
          halt: true,
          displayFields: ['displayName', 'id']
        }],
        serviceAlias,
        entityAlias
      }
    );

    return entitiesToCreate;
  }

  onStatusDialogClose = this.onStatusDialogClose.bind(this)
  onStatusDialogClose(created, statuses) {
    if (created) {
      const { serviceAlias, match } = this.props;
      const { entityAlias } = match.params;
      const status = statuses.get(`${serviceAlias}-${entityAlias}-new`);

      redirectOnSaveSuccess(status.resp, this.props.endpoint, match.url);
    }
    else {
      this.getEntitiesToCreate();
      sessionStorage.setItem('created', created)
      this.flowEndpoint.list().then(resp => resp.getItems(Infinity).then(items => {
        const flowOptions = items.map((flow) => ({
          val: flow.id,
          displayString: flow.displayName
        }));
        sessionStorage.setItem('updatedFlows', JSON.stringify(flowOptions));
      }, createResponseAlertMessage()).catch(createResponseAlertMessage));
      this.setState({ showStatusDialog: false })
    }
  }

  disableSaveBtn = this.disableSaveBtn.bind(this);
  disableSaveBtn(entity) {
    const attributes = this.props.entityDef.attributes;
    let requiredFields = [];
    let requiredEntityFieldsObject = {};
    let emptyValue;
    let hasOutputFlow;
    let hasPermitted;
    let numFiltersWithDetails = 0;
    let numFilters = 0;
    let validGroupFilter;
    let validVinFilter;
    const nameRule = RegExp("^[a-z0-9][a-z0-9\\-\\/]*[a-z0-9]$").test(entity.displayNameView);

    Object.entries(attributes).forEach(attribute => {
      if (attribute[1].rules && attribute[1].rules.includes('required') && attribute[1].display?.create !== false) {
        requiredFields.push(attribute[0])
        requiredEntityFieldsObject[attribute[0]] = entity[attribute[0]];
      }
    });

    Object.values(requiredEntityFieldsObject).forEach(field => {
      if (typeof field === 'object') {
        emptyValue = field.length === 0
      }
    });

    if (requiredEntityFieldsObject.outputCreate !== undefined && Object.values(requiredEntityFieldsObject.outputCreate)[0]?.filters !== undefined) {
      hasPermitted = hasPermittedFilter(Object.values(requiredEntityFieldsObject.outputCreate)[0].filters);
      hasOutputFlow = Object.values(requiredEntityFieldsObject.outputCreate)[0].aui !== '';
      numFilters = Object.values(requiredEntityFieldsObject?.outputCreate)[0].filters.length;
      Object.values(requiredEntityFieldsObject.outputCreate)[0].filters.forEach(filter => {
        if (filter.groupFilter) {
          validGroupFilter = UUID_RE.test(filter.groupFilter.groupId);
          if (filter.groupFilter.groupId !== '' && filter.groupFilter.groupId !== '') {
            numFiltersWithDetails++;
          }
        }
        if (filter.vinFilter) {
          const checkIfDuplicateExists = (arr=[]) => {
            return new Set(arr).size !== arr.length;
          }
          validVinFilter = !checkIfDuplicateExists(filter.vinFilter.vins) && filter.vinFilter.vins?.every(isValidVin)
          if (filter.vinFilter.vins?.length > 0) {
            numFiltersWithDetails++;
          }
        }
        if (filter.memberPassThroughFilter) {
          numFiltersWithDetails ++;
        }
      })
    }

    return Object.values(requiredEntityFieldsObject).includes(undefined) ||
      Object.values(requiredEntityFieldsObject).includes('') ||
      !hasPermitted ||
      !hasOutputFlow ||
      emptyValue === undefined ||
      numFilters !== numFiltersWithDetails ||
      validGroupFilter === false ||
      validVinFilter === false ||
      !nameRule
  }

  handleOnSave = this.handleOnSave.bind(this);
  handleOnSave(modifiedEntity, doSaveFn) {
    this._saveEntity = doSaveFn;
    this.setState({
      showStatusDialog: true,
      modifiedEntity
    });

    return Promise.resolve();
  }

  renderForm() {
    const mode = window.location.hash;
    const navLinks = [
      { label: 'Manual', destination: MODE_MANUAL },
      { label: 'Advance', destination: MODE_ADVANCE }
    ].map(link => {
      link.selected = mode === link.destination;
      return link;
    });

    return (
      <div>
        { TABS_ENABLED && <Tabs navLinks={navLinks} /> }
        { mode === MODE_MANUAL ? this.renderManualView() : this.renderAdvanceView() }
      </div>
    );
  }

}

export default injectIntl(ForkCreate);