import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import copy from '@au/core/lib/utils/copyTextToClipboard';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuButton,
  { BUTTON_TYPE_TERTIARY,
    BUTTON_SIZE_MEDIUM,
    BUTTON_TYPE_PRIMARY,
    BUTTON_TYPE_PLAIN } from '@au/core/lib/components/elements/AuButton';
import AuScrollbar from '@au/core/lib/components/elements/AuScrollbar';
import LoadingIndicator from '@au/core/lib/components/elements/LoadingIndicator';
import Modal from '@au/core/lib/components/objects/Modal';

import { history as browserHistory } from '../../history';
import { NOOP, SERVICES_PATH } from '../../constants';
import { formatMessage } from '../../utils/reactIntl';

import styles from '../../css/components/entity_multi_save_dialog.module.scss';

const STATE_PROCESSING = 'processing';
const STATE_FAILURE    = 'failure';
const STATE_SUCCESS    = 'success';
const STATE_ABORTED    = 'aborted';

export default class MultiSaveDialog extends React.Component {

  static propTypes = {
    titleId: T.string,
    titleString: T.string,
    entitiesToCreate: T.instanceOf(Map).isRequired,
    onClose: T.func,
    displayIdOverrides: T.oneOfType([T.instanceOf(Map), T.bool])
  }

  static defaultProps = {
    onClose: NOOP
  }

  state = {
    statuses: new Map(),
    counters: {},
    created: false,
    aborted: false
  };

  componentDidMount() {
    const { entitiesToCreate } = this.props;
    const statuses = new Map();
    const counters = {};

    // set initial status to STATUS_PROCESSING and counters
    for (let [groupKey, group] of entitiesToCreate) {
      for (let entity of group.entities) {
        statuses.set(entity.key, { state: STATE_PROCESSING });
      }
      counters[groupKey] = {
        total: group.entities.length,
        created: 0
      };
    }

    this.setState({ statuses, counters }, this.processEntities);
  }

  processEntities = this.processEntities.bind(this);
  async processEntities() {
    const { entitiesToCreate } = this.props;

    let aborted = false;
    let error;

    for (let [groupKey, group] of entitiesToCreate) {
      for (let entity of group.entities) {
        if (aborted) {
          this.setState(prevState => ({
            statuses: new Map(prevState.statuses).set(entity.key, { state: STATE_ABORTED })
          }));
          continue;
        }

        await entity
          .saveFn(entity.data)
          .then(
            resp => ({ state: STATE_SUCCESS, created: 1, resp }),
            error => ({ state: STATE_FAILURE, created: 0, error })
          )
          .then(data => {
            this.setState(prevState => ({
              statuses: new Map(prevState.statuses).set(entity.key, data),
              counters: {
                ...prevState.counters,
                [groupKey]: {
                  ...prevState.counters[groupKey],
                  created: prevState.counters[groupKey].created + data.created
                }
              }
            }));
            if (data.error && entity.halt) {
              aborted = true;
              error = data.error;
              this.setState({ aborted });
            }
          });
      }
    }

    if (aborted) {
      return Promise.reject(error);
    }

    this.setState({ created: true });
  }

  handleOnCopyClick = this.handleOnCopyClick.bind(this);
  handleOnCopyClick() {
    let text = '';

    text += document.querySelector(`.${styles.header}`).textContent + "\n\n";

    for (let record of document.querySelectorAll(`.${styles.record}`)) {
      text += record.innerText + "\n\n";
    }

    copy(text);
  }

  handleOnCloseClick = this.handleOnCloseClick.bind(this);
  handleOnCloseClick() {
    if (this.state.created) {
      sessionStorage.removeItem('entityToReplicate');
    } else {
      browserHistory.push(this.props.url + '/list');
    }

    this.props.onClose(this.state.created, this.state.statuses);
  }

  handleOnRetryClick = this.handleOnRetryClick.bind(this);
  handleOnRetryClick() {
    if (this.state.created) {
      sessionStorage.removeItem('entityToReplicate');
    }

    this.props.onClose(this.state.created, this.state.statuses);
  }

  renderRow = this.renderRow.bind(this);
  renderRow(entity, serviceAlias, entityAlias) {
    const { statuses } = this.state;
    const { key, idProp, displayFields } = entity;
    const status = statuses.get(entity.key);
    const { displayIdOverrides } = this.props;

    return (
      <div key={`row_${key}`} className={styles.wrapper}>
        <div className={styles.row}>
          { status.state === STATE_FAILURE && status.error?.data?.message &&
            <div className={styles.alert}>
              { status.error.data.error }
              <span> : </span>
              { status.error.data.message.charAt(0).toUpperCase() + status.error.data.message.slice(1) }
            </div>
          }
          <div className={styles.attributes}>
            { displayFields.map((field, i) => Boolean(entity.data[field] || status?.resp?.data[field]) &&
              <div key={`${key}-${field}`} className={styles.attribute}>
                <AutoIntl
                  className={styles.attr_name}
                  displayId={`${this.getFieldDisplayId(field, displayIdOverrides)}`}
                  tag="div"
                />
                <div className={styles.attr_value}>
                  { !status?.resp && entity.data[field] }
                  {/* render first entry as a Link */}
                  { status?.resp && i === 0 &&
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`${SERVICES_PATH}/${serviceAlias}/${entityAlias}/${status.resp.data[idProp]}/view`}
                    >{ entity.data[field] }</Link>
                  }
                  { status?.resp && i > 0 && status.resp.data[field] }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  getFieldDisplayId(field, overrides) {
    if (!overrides || !overrides.get(field)) {
      return `au.entity.attr.${field}`;
    }
    return overrides.get(field);
  }

  renderStatusRecord = this.renderStatusRecord.bind(this);
  renderStatusRecord([groupKey, record]) {
    const { statuses, counters } = this.state;
    const { entityType, entities, verbRoot = "create" } = record;
    const count = entities.length;

    if (!statuses.size) {
      return false;
    }

    // calculate number of entities in each state
    const stateCounts = entities.reduce((acc, entity) => {
      const state = statuses.get(entity.key).state;
      if (!(state in acc)) {
        acc[state] = 0;
      }
      acc[state]++;
      return acc;
    }, {});

    // the overall state is determined by the individual entity state in the following order (NOTE order is important!):
    const state = [STATE_FAILURE, STATE_ABORTED, STATE_PROCESSING, STATE_SUCCESS].find(state => stateCounts[state]);
    // number of entities to be created (for a specific group)
    const diff  = (
      state === STATE_PROCESSING && counters[groupKey].total > 1
        ? counters[groupKey].total - counters[groupKey].created
        : 0
    );

    return (
      <div key={`status_${groupKey}`} className={styles.record}>
        <div className={styles.record_title}>
          <LoadingIndicator
            className={styles.processing}
            display={state === STATE_PROCESSING}
            displayId={""/* empty */}
          />
          <span className={cn(styles.entity, { [styles[state]]: state !== STATE_PROCESSING })}>
            <AutoIntl className={styles.entity_name} displayId={`au.entity.name.${entityType}`}/>
            {
              formatMessage(
                { id: `au.multiSaveDialog.message.${verbRoot}.${state}` },
                [STATE_FAILURE, STATE_ABORTED].includes(state)
                  ? {
                    entityName: formatMessage({
                      id: `au.entity.name.${entityType}`
                    }) }
                  : {
                    entityName: formatMessage({
                      /* `name` provides a single form, `title` - plural */
                      id: `au.entity.${count === 1 ? 'name' : 'title'}.${entityType}`
                    }),
                    hint: text => (<span className={styles.hint}>{ `(${ text })`}</span>),
                    more: diff,
                    count
                 }
               )
             }
          </span>
        </div>
        <div className={styles.details}>
          { entities.map(entity => this.renderRow(entity, record.serviceAlias, record.entityAlias)) }
        </div>
      </div>
    );
  }

  render() {
    const { titleId, titleString, entitiesToCreate } = this.props;

    return (
      <Modal>
        <div className={styles.container}>
          <div className={styles.header}>
            { titleId ? formatMessage({ id: titleId }) : titleString }
            <AuButton
              type={BUTTON_TYPE_TERTIARY}
              size={BUTTON_SIZE_MEDIUM}
              className={styles.copy}
              disabled={!this.state.created && !this.state.aborted}
              displayId="au.multiSaveDialog.copyToClipboard"
              onClick={this.handleOnCopyClick}
            />
          </div>
          <div className={styles.content}>
            <AuScrollbar className={styles.body}>
              { [...entitiesToCreate].map(this.renderStatusRecord) }
            </AuScrollbar>
          </div>
          <div className={styles.buttons}>
            {!this.state.created && <AuButton displayId="au.entity.retry" onClick={this.handleOnRetryClick} type={BUTTON_TYPE_PRIMARY} />}
            {this.state.created && <AuButton displayId="au.entity.viewDetails" onClick={this.handleOnRetryClick} type={BUTTON_TYPE_PRIMARY} />}
            {!this.state.created && <AuButton displayId="au.entity.close" onClick={this.handleOnCloseClick} type={BUTTON_TYPE_PLAIN} />}
          </div>
        </div>
      </Modal>
    );
  }

}
