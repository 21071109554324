import { BUTTON_TYPE_TERTIARY } from '@au/core/lib/components/elements/AuButton';
import { history as browserHistory } from '../../../history';

import DefaultView from "../View";

export default class CommandDeviceWakeUp extends DefaultView {

  onViewSmsDeliveryHistory = this.onViewSmsDeliveryHistory.bind(this);
  onViewSmsDeliveryHistory() {
    const deviceId = this.props.entity.getIn(['target', 'deviceId']);
    browserHistory.push({
      pathname: `/services/inventory-service/devices/${deviceId}/smsHistory/list`,
      state: { prevUrl: this.props.match.url }
    });
  }

  getActions() {
    const actions = super.getActions();
    actions.push({
      key: 'view_sms_delivery_history_btn',
      type: BUTTON_TYPE_TERTIARY,
      displayId: "au.commands.viewSmsDeliveryHistory",
      onClick: this.onViewSmsDeliveryHistory
    })
    return actions;
  }

  renderTableRows() {
    return super.renderTableRows(this.props.entityDef.subviews.deviceWakeUp.attributes);
  }
}
