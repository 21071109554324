import React from 'react';
import { select } from 'd3-selection';
import * as T from 'prop-types';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import Spinner from '@au/core/lib/components/elements/Spinner';

import { FeedGraph, MultiTree } from '../utils/topology';
import { formatMessage } from '../utils/reactIntl';
import {
  TopologySvg,
  positionTreesNodes,
  renderTrees
} from './utils/topology';

import styles from '../css/components/feed_topology_legend.module.scss';

class LegendFeedGraph extends FeedGraph {
  static flowReqFields = [];
  static tapReqFields = [];
  static processorReqFields = [];
  static directRouteReqFields = [];
}

const flows = [
  {
    flowAui: 'aui:flow:feed/rootFlow',
    displayId: 'au.topologyLegend.nodes.rootFlow',
    inputTaps: [],
    outputTaps: ['aui:tap:feed/tap', 'aui:tap:feed/pausedTap', 'aui:tap:feed/stoppedTap'],
    outputTapIds: ['au.topologyLegend.nodes.inferredTap'],
    inputAuis: [],
    outputAuis: []
  },
  {
    flowAui: 'aui:flow:feed/connFlow',
    displayId: 'au.topologyLegend.nodes.connectedFlow',
    inputTaps: ['aui:tap:feed/tap'],
    outputTaps: [],
    inputAuis: [],
    outputAuis: [],
    outputAuiIds: ['au.topologyLegend.nodes.inferredFork'],
    outputProcessorIds: ['au.topologyLegend.nodes.inferredProcessor']
  },
  {
    flowAui: 'aui:flow:feed/forkFlow1',
    displayId: 'au.topologyLegend.nodes.connectedFlow',
    inputTaps: [],
    outputTaps: [],
    inputAuis: ['fork'],
    outputAuis: []
  },
  {
    flowAui: 'aui:flow:feed/forkFlow2',
    displayId: 'au.topologyLegend.nodes.connectedFlow',
    inputTaps: [],
    outputTaps: [],
    inputAuis: ['fork'],
    outputAuis: []
  },
];

const taps = [
  {
    tapAui: 'aui:tap:feed/tap',
    displayId: 'au.topologyLegend.nodes.tap',
    inputFlow: 'aui:flow:feed/rootFlow',
    outputFlow: 'aui:flow:feed/connFlow',
    state: 'RUNNING'
  },
  {
    tapAui: 'aui:tap:feed/pausedTap',
    displayId: 'au.topologyLegend.nodes.pausedTap',
    inputFlow: 'aui:flow:feed/rootFlow',
    outputFlowId: 'au.topologyLegend.nodes.inferredFlow',
    state: 'PAUSED'
  },
  {
    tapAui: 'aui:tap:feed/stoppedTap',
    displayId: 'au.topologyLegend.nodes.stoppedTap',
    inputFlow: 'aui:flow:feed/rootFlow',
    outputFlow: '',
    state: 'STOPPED'
  }
];

const processors = [
  {
    aui: 'processor',
    displayId: 'au.topologyLegend.nodes.processor',
    inputFlow: 'aui:flow:feed/connFlow',
    outputFlow: '',
    state: 'RUNNING'
  },
  {
    aui: 'pausedProcessor',
    displayId: 'au.topologyLegend.nodes.pausedProcessor',
    inputFlow: 'aui:flow:feed/connFlow',
    outputFlow: '',
    state: 'PAUSED'
  },
  {
    aui: 'stoppedProcessor',
    displayId: 'au.topologyLegend.nodes.stoppedProcessor',
    inputFlow: 'aui:flow:feed/connFlow',
    outputFlow: '',
    state: 'STOPPED'
  }
];

const forks = [
  {
    aui: 'fork',
    displayId: 'au.topologyLegend.nodes.fork',
    input: { aui: 'aui:flow:feed/connFlow' },
    outputs: [
      { aui: 'aui:flow:feed/forkFlow1' },
      { aui: 'aui:flow:feed/forkFlow2' },
    ],
    state: 'RUNNING'
  },
  {
    aui: 'pausedFork',
    displayId: 'au.topologyLegend.nodes.pausedFork',
    input: { aui: 'aui:flow:feed/connFlow' },
    outputs: [],
    state: 'PAUSED'
  },
  {
    aui: 'stoppedFork',
    displayId: 'au.topologyLegend.nodes.stoppedFork',
    input: { aui: 'aui:flow:feed/connFlow' },
    outputs: [],
    state: 'STOPPED'
  }
];

const routes = [
  {
    aui: 'directRoute',
    displayId: 'au.topologyLegend.nodes.directRoute',
    input: { aui: 'aui:flow:feed/rootFlow' },
    outputs: [
      { aui: 'aui:flow:feed/forkFlow1' },
      { aui: 'aui:flow:feed/forkFlow2' }
    ]
  }
];


export default class FeedTopologyLegend extends React.PureComponent {
  static propTypes = {
    landingPage: T.bool,
    smallLoader: T.bool,
    caption: T.string,
    collapsed: T.bool
  }
  static defaultProps = {
    landingPage: false,
    smallLoader: false,
    collapsed: false
  }

  localize(nodes) {
    for (let node of nodes) {
      node.displayName = formatMessage({ id: node.displayId });

      // hacks to get implied node names to be localized
      if (node.outputFlowId) {
        node.outputFlow = 'aui:flow:feed/' + formatMessage({ id: node.outputFlowId });
      }
      if (node.outputTapIds) {
        for (let id of node.outputTapIds) {
          node.outputTaps.push('aui:tap:feed/' + formatMessage({ id }));
        }
      }
      if (node.outputAuiIds) {
        for (let id of node.outputAuiIds) {
          node.outputAuis.push('aui:feed:fork/' + formatMessage({ id }));
        }
      }
      if (node.outputProcessorIds) {
        for (let id of node.outputProcessorIds) {
          node.outputAuis.push('aui:feed:processor/' + formatMessage({ id }));
        }
      }
    }
    return nodes;
  }

  componentDidMount() {
    if(!this.props.smallLoader) this.renderD3();
  }

  renderD3() {
    const graph = new LegendFeedGraph(this.localize(flows), this.localize(taps), this.localize(processors), this.localize(forks), this.localize(routes));
    const trees = graph.getTrees();
    const mTree = new MultiTree(trees);

    const svg = select('.' + styles.sample);

    // Pull nodes so they match the design
    [...trees.values()][0].sort(new Map([
      ['aui:tap:feed/tap', { direction: 'up', power: 3 }],
      ['aui:tap:feed/pausedTap', { direction: 'up', power: 2 }],
      ['aui:tap:feed/stoppedTap', { direction: 'up', power: 1 }],
      ['processor', { direction: 'up', power: 6 }],
      ['pausedProcessor', { direction: 'up', power: 5 }],
      ['stoppedProcessor', { direction: 'up', power: 4 }],
      ['fork', { direction: 'up', power: 3 }],
      ['pausedFork', { direction: 'up', power: 2 }],
      ['stoppedFork', { direction: 'up', power: 1 }],
      ['directRoute', { direction: 'up', power: 10 }],
    ]));

    positionTreesNodes(trees, 0);
    renderTrees(mTree, trees, svg);
  }

  render() {
    const { landingPage, smallLoader, collapsed } = this.props;

    return (
      <div className={landingPage ? styles.landing_page : styles.container}>
        <div className={styles.hat}>
          {!landingPage && !collapsed &&
            <div className={styles.loading}>
              <Spinner className={styles.spinner} />
              <AutoIntl className={styles.rendering} displayId="au.topology.renderingPleaseWait" tag="div" />
            </div>
          }
          {(!landingPage && !smallLoader) && <AutoIntl className={styles.title} displayId="au.topology.usageTitle" tag="div" /> }
        </div>
        {!smallLoader && 
          <div className={styles.topology_container}>
            <TopologySvg className={styles.sample} />
          </div>
        }
      </div>
    );
  }
}
