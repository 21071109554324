import DefaultList from "../List";

export default class FtcpMetadataList extends DefaultList {
  list() {
    const {endpoint, parentEntity, pageSize, entityDef: {queryParams}} = this.props;

    return endpoint
      .getVehicleMetadataEndpoint(parentEntity.entity.getIn(["properties", "vin"]))
      .list({pageSize, queryParams});
  }
}