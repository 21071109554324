import React from "react";
import { fromJS } from 'immutable';
import Highlighter from 'react-highlight-words';
import transit from 'transit-immutable-js';
import cn from 'classnames';

import TMC from '@autonomic/browser-sdk';
import { AutoIntl } from "@au/core/lib/components/elements";
import CollapsiblePanel from '@au/core/lib/components/elements/CollapsiblePanel';

import { guaranteeUndefinedOrArray } from '../../utils/dataUtils';
import { generateEntityPath } from '../../../utils/entity';
import { showAll } from "../../filters/FailureMode";
import { history as browserHistory } from '../../../history';
import { COMMAND_MANAGEMENT_DEFINITION, SERVICES_PATH, SERVICE_NAMES } from '../../../constants';
import DefaultList from '../List';

import styles from '../../../css/components/command_management_list.module.scss';
import { createResponseAlertMessage } from "@au/core/lib/components/objects/AlertMessage";

export default class CommandManagementList extends DefaultList {

  static defaultProps = {
    ...super.defaultProps,
    pageSize: 300
  }

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      commandsByType: {}
    };

    this.header = {};
    this.content = {};
  }

  contentRef = React.createRef();

  initialize() {
    super.initialize();
    this.setState({ expanded: false, toggled: false });
  }
  
  getLeftAddons() {
    const leftAddons = super.getLeftAddons();

    leftAddons.push(<div className={styles.go_to_commands_link}>
      <AutoIntl tag="link" displayId="au.entity.goToCommandList" to={SERVICES_PATH + `/${SERVICE_NAMES.COMMAND}/commands/list`} />
    </div>)

    return leftAddons;
  }

  definitionsEndpoint = new TMC.services.Command({ apiVersion: '1-beta' }).definitions;
  SwaggerDocsEndpoint = new TMC.services.Command({ apiVersion: '1-beta' }).apiDocs;

  componentDidMount() {
    super.componentDidMount();

    this.SwaggerDocsEndpoint.getSwaggerDocs().then((resp) => {
      const apiDefinition = resp.data.CommandDefinitionRequest?.properties || resp.data.components.schemas.CommandDefinitionRequest?.properties;
      sessionStorage.setItem('caasGlobalMetaData', JSON.stringify({
          deliveryPriority: Array(apiDefinition.deliveryPriority.maximum - apiDefinition.deliveryPriority.minimum + 1).fill("").map((_v, i) => String(apiDefinition.deliveryPriority.minimum + i)),
          deviceTypes: apiDefinition.deviceTypePriorityList.enum,
          maxExpirationSeconds: apiDefinition.maxExpirationSeconds.maximum,
          minExpirationSeconds: apiDefinition.maxExpirationSeconds.minimum,
          fsmNames: apiDefinition.fsmName.enum,
          status: apiDefinition?.commandDefinitionStatus?.enum || resp.data.CommandDefinitionRequest?.commandDefinitionStatus?.enum
      }));
    }).catch(createResponseAlertMessage);
  }
  
  list() {
    const { entityDef, pageSize } = this.props;
    const { queryParams } = entityDef;

    let data = { pageSize, ...queryParams, ...this.getFilterQueryParams() };

    data["statuses"] = guaranteeUndefinedOrArray(data["statuses"]);
    data["types"] = guaranteeUndefinedOrArray(data["types"]);
    data["versions"] = guaranteeUndefinedOrArray(data["versions"]);
    const userTags = guaranteeUndefinedOrArray(data["userTags"]);
    data["userTags"] = userTags && Object.fromEntries(userTags.map((tag)=> tag.split(':')));
    data["fsmNames"] = guaranteeUndefinedOrArray(data["fsmNames"]);
    const deviceTypes = guaranteeUndefinedOrArray(data["deviceTypes"]);
    data["deviceTypes"] = deviceTypes && deviceTypes.map((deviceType) => decodeURIComponent(deviceType));
    return this.definitionsEndpoint.search(data);
  }

  getFilterQueryParams = this.getFilterQueryParams.bind(this);
  getFilterQueryParams() {
    const {
      failureMode,
      beforeDeliveryFailure,
      afterDeliveryFailure,
      beforeDeliveryExpiration,
      afterDeliveryExpiration,
      preclusion,
      ...copy
    } = this.state.filterQueryParams;

    if (failureMode || beforeDeliveryFailure || afterDeliveryFailure || beforeDeliveryExpiration || afterDeliveryExpiration) {
      copy.failureMode = {
        showAll: failureMode === showAll ? true : undefined,
        beforeDeliveryFailureTypes: guaranteeUndefinedOrArray(beforeDeliveryFailure),
        afterDeliveryFailureTypes: guaranteeUndefinedOrArray(afterDeliveryFailure),
        beforeDeliveryExpirationTypes: guaranteeUndefinedOrArray(beforeDeliveryExpiration),
        afterDeliveryExpirationTypes: guaranteeUndefinedOrArray(afterDeliveryExpiration)
      };
    }
    if (preclusion) {
      copy.preclusion = this.preclusionMapping(guaranteeUndefinedOrArray(preclusion));
    }

    return copy;
  }

  getBanner() {
    return;
  }

  getTextIdForCreate() {
    return 'au.entity.action.register';
  }

  onEditBtnClick(id) {
    browserHistory.push(`${this.baseUrl}/${encodeURIComponent(`${id}`)}/edit`);
  }

  onUpdateBtnClick(id) {
    browserHistory.push(`${this.baseUrl}/${encodeURIComponent(`${id}`)}/define`);
  }

  onReplicateBtnClick(entity) {
    // save entity to session storage for UI to pickup
    sessionStorage.setItem('entityToReplicate', transit.toJSON(entity));

    browserHistory.push({
      pathname: `${this.baseUrl}/replicate`,
      state: { prevUrl: this.baseUrl + `/list` }
    });
  }

  getRowActions({ id, canWrite/*, permissions*/ }) {// TODO: need permissions provide valid info for "canDelete" 
    let actions = [];

    const entity = this.props.entities?.get(id);
    actions.push(
      { displayId: 'au.entity.viewDetails', onClick: super.onViewBtnClick.bind(this, id) },
      { displayId: 'au.entity.duplicate', onClick: this.onReplicateBtnClick.bind(this, entity) }
    );

    const status = entity?.get(COMMAND_MANAGEMENT_DEFINITION.COMMAND_DEFINITION_STATUS).toLowerCase();
    switch (status) {
      case COMMAND_MANAGEMENT_DEFINITION.ENABLED:
      case COMMAND_MANAGEMENT_DEFINITION.DISABLED:
        if (canWrite) {
          actions.splice(1, 0, { displayId: 'au.entity.edit', onClick: this.onEditBtnClick.bind(this, id) });
        }
        break;
      case COMMAND_MANAGEMENT_DEFINITION.DRAFT:
        if (canWrite) {
          actions.splice(1, 0, { displayId: 'au.entity.edit', onClick: this.onUpdateBtnClick.bind(this, id) });
        }
        actions.push({ displayId: 'au.entity.delete', onClick: super.onDeleteBtnClick.bind(this, id) });
        break;
      default:
        break;
    }
    return actions;
  }

  onListSuccess(resp) {
    const { actions, entityDef, endpoint, serviceAlias } = this.props;

    actions.listEntitiesSuccess({
      path: generateEntityPath(endpoint, serviceAlias),
      data: resp.items,
      pkField: entityDef.pkField,
      replace: resp.isFirstPage
    });

    return resp;
  }

  setRef(name, el) {
    if (el) {
      this[name] = el;
    }

    if (name) {
      this.header[name] = this[name].firstElementChild;
      this.content[name] = this[name].lastElementChild;
    }
  }

  handleMouseEnter(type) {
    this.content[type].setAttribute("id", "hovered_content");
    const hoveredContent = document.getElementById('hovered_content');
    const tableHeader = hoveredContent.firstElementChild.firstElementChild.firstElementChild;
    const tableBody = hoveredContent.firstElementChild.firstElementChild.lastElementChild;
    const contentHeader = hoveredContent.parentElement.firstElementChild;
    const rowBelow = hoveredContent.parentElement.nextSibling;
    const secondRowBelow = hoveredContent.parentElement.nextSibling?.nextSibling;
    const thirdRowBelow = hoveredContent.parentElement.nextSibling?.nextSibling?.nextSibling;
    const header = tableHeader.getBoundingClientRect();
    const body = tableBody.getBoundingClientRect();
    const overlap = body.top < header.bottom;

    if (hoveredContent) {
      if (rowBelow) {
        rowBelow.style.zIndex = 0;
      } if (secondRowBelow) {
        secondRowBelow.style.zIndex = 0;
      } if (thirdRowBelow) {
        thirdRowBelow.style.zIndex = 0;
      }
      if (!overlap) {
        tableHeader.style.zIndex = 0;
        contentHeader.style.zIndex = 0;
      }
  }
}

  handleMouseLeave() {
    const hoveredContent = document.getElementById('hovered_content');
    const tableHeader = hoveredContent.firstElementChild.firstElementChild.firstElementChild;
    const contentHeader = hoveredContent.parentElement.firstElementChild;
    const rowBelow = hoveredContent.parentElement.nextSibling;
    const secondRowBelow = hoveredContent.parentElement.nextSibling?.nextSibling;
    const thirdRowBelow = hoveredContent.parentElement.nextSibling?.nextSibling?.nextSibling;

    if (rowBelow) {
      rowBelow.style.zIndex = 'unset';
      if (secondRowBelow) {
        secondRowBelow.style.zIndex = 'unset';
      } if (thirdRowBelow) {
        thirdRowBelow.style.zIndex = 'unset';
      }
    }
    tableHeader.style.zIndex = 2;
    contentHeader.style.zIndex = 3;
    hoveredContent.removeAttribute('id')
  }

  getFilteredEntities() {
    const entities = super.getFilteredEntities();

    const commandsByType = {};  
    for (const data of entities.values()) {
      const type = data.get(COMMAND_MANAGEMENT_DEFINITION.TYPE);
      let command = commandsByType[type];
      if (!command) {
        command = {};
        command[COMMAND_MANAGEMENT_DEFINITION.VERSION_ENTITIES] = {};
        command[COMMAND_MANAGEMENT_DEFINITION.NUM_DRAFTS] = 0;
        commandsByType[type] = command;
      }
      const veEntities = command[COMMAND_MANAGEMENT_DEFINITION.VERSION_ENTITIES];
      veEntities[data.get(COMMAND_MANAGEMENT_DEFINITION.ID)] = data;
      if (data.get(COMMAND_MANAGEMENT_DEFINITION.COMMAND_DEFINITION_STATUS).toLowerCase() === COMMAND_MANAGEMENT_DEFINITION.DRAFT) {
        command[COMMAND_MANAGEMENT_DEFINITION.NUM_DRAFTS] = command[COMMAND_MANAGEMENT_DEFINITION.NUM_DRAFTS] + 1;
      }
    }

    this.setState({ commandsByType: commandsByType });

    return fromJS(commandsByType);
  }

  /** Override the base as Search is handled here not in the base */
  getSearchText() {
    return '';
  }

  renderTableContent(entities) {
    if (!entities) 
      return;

    const { expanded, toggled, searchText } = this.state;

    const commands = [];
    for (let [type, data] of Object.entries(this.state.commandsByType)) {
      const versionsJS = fromJS(data[COMMAND_MANAGEMENT_DEFINITION.VERSION_ENTITIES]);
      if (versionsJS?.size > 0) {
        const restElements = (
          <div className={styles.version_container}>
            <AutoIntl
              displayId='au.entity.commandManagement.definition.versions' values={{versions: versionsJS.size}}
              tag="div"
              className={styles.version_label}
            />
            <AutoIntl
              displayId='au.entity.commandManagement.definition.drafts' values={{drafts: data[COMMAND_MANAGEMENT_DEFINITION.NUM_DRAFTS]}}
              tag="div"
              className={styles.draft_label}
            />
          </div>
        );
  
        let value = type;
        if (!searchText || type.toString().toLowerCase().includes(searchText.toLowerCase())) {
          if (searchText) {
            value = <Highlighter
              highlightClassName={styles.highlight}
              searchWords={searchText.split(/\s+/)}
              autoEscape={true}
              textToHighlight={value}
            />;
          }

          commands.push(
            <CollapsiblePanel
              key={`command_management_${type}`}
              id={type}
              ref={el => this.setRef(type, el)}
              displayString={value}
              className={styles.panel}
              collapsedClassName={styles.collapsed}
              actionButtonsElement={restElements}
              expanded={toggled? expanded : false}
            >
              { super.renderTableContent(versionsJS) }
            </CollapsiblePanel>
          );
        }
      }
    }

    let noData = commands.length === 0;

    return (
      <div className={styles.container}>
        <div className={styles.content_wrapper}>
          <div className={cn(styles.content, {[styles.no_data]: noData})} ref={this.contentRef}>
            {!noData && commands}
            {noData && <AutoIntl className={styles.no_data_message} displayId='au.table.dataFilterEmptyMessage' tag="div"/>}
          </div>
        </div>
      </div>
    );
  }
}