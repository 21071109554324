import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuComponent from '@au/core/lib/components/elements/AuComponent';

import { NOOP } from '../constants';
import { formatMessage } from '../utils/reactIntl';

import { Filter } from "../AuPropTypes";

import styles from '../css/components/filter_segment.module.scss';

export default class FilterSegment extends AuComponent {

  static propTypes = {
    Filter: T.func.isRequired,
    name: T.string.isRequired,
    displayId: T.string,
    selection: T.arrayOf(Filter),
    customProperties: T.object,
    contentRef: T.object,
    className: T.string,
    visibleClassName: T.string,
    expandedClassName: T.string,
    iconClassName: T.string,
    headerClassName: T.string,
    filterClassName: T.string,
    multiSelect: T.bool,
    hideClearBtn: T.bool,
    onFilterInit: T.func,
    onFilterChange: T.func,
    showRemoveBtn: T.bool
  }

  static defaultProps = {
    multiSelect: false,
    hideClearBtn: false,
    showRemoveBtn: true,
    selection: [],
    contentRef: {},
    onFilterInit: NOOP,
    onFilterChange: NOOP
  }

  state = {
    initialized: false,
    isOpen: false,
    removedSelection: ''
  };

  containerRef = React.createRef();

  toggleOpen = this.toggleOpen.bind(this);
  toggleOpen() {
    this.setState(
      prevState => {
        this.trackFilterEvent({
          action: prevState.isOpen ? 'CollapseFilter' : 'ExpandFilter',
        });
        return {isOpen: !prevState.isOpen};
      }
    );
  }

  trackFilterEvent({category = 'SidebarFilters', action, label}) {
    const {displayId, displayString} = this.props;
    const filter = displayId ? formatMessage({id: displayId}) : displayString || 'UnknownFilter';
    AuAnalytics.trackEvent({category, action: action + ': ' + filter, label});
  }

  trackFilterChange(selectedItems, action = 'ChangeFilter') {
    const label = Array.isArray(selectedItems)
      ? selectedItems.map(i => i?.urlValue).join(', ')
      : selectedItems?.urlValue;
    this.trackFilterEvent({action, label});
  }

  clearSelection = this.clearSelection.bind(this);
  clearSelection() {
    if (this.props.name === "dateRange") {
      this.props.handleClearForm('');
    }
    this.trackFilterEvent({action: 'ClearFilter', label: '*empty*'});
    this.commitChanges([]);
  }

  removeSelection = this.removeSelection.bind(this);
  removeSelection(item) {
    const {selection} = this.props;
    this.trackFilterEvent({action: 'RemoveFilter', label: item.urlValue});

    this.setState({removedSelection: item?.urlKey})

    const newSelection = selection.filter(prevItem => prevItem !== item);
    this.commitChanges(newSelection);
  }

  initializeFilter(selectedItems) {
    const {name: filterName, onFilterInit} = this.props;
    if (selectedItems.length) {
      this.trackFilterChange(selectedItems, 'InitFilter');
    }
    onFilterInit(filterName, selectedItems);
  }

  handleOnInit = this.handleOnInit.bind(this);
  handleOnInit(selectedItems) {
    this.initializeFilter(selectedItems);
    this.setState({initialized: true});
  }

  handleOnChange = this.handleOnChange.bind(this);
  handleOnChange(selectedItems) {
    this.setState((prevState, props) => {
      this.trackFilterChange(selectedItems);
      if (props.selection.length < selectedItems.length) {
        setTimeout(() => { // so the scrollTop is set to the scrollHeight after the new filter is added
          if (this.containerRef.current) {
            this.containerRef.current.scrollTop = this.containerRef.current.scrollHeight;
          }
        });
      }
      return {
        isOpen: this.props.multiSelect ? prevState.isOpen : false,
        initialized: true,
        removedSelection: ''
      };
    }, () => this.commitChanges(selectedItems));
  }

  commitChanges(selectedItems) {
    const {name: filterName, onFilterChange} = this.props;
    onFilterChange(filterName, selectedItems);
  }

  renderSelection() {
    const {selection, showRemoveBtn} = this.props;

    const selectedFilters = selection.map(item => {
        if (item.shouldDisplayBubble) {
          return (
            <div className={styles.selection} key={item.urlKey + ":" + item.urlValue}>
              <div className={styles.pill}>
                <AutoIntl displayId={item.bubbleDisplayId} displayString={item.bubbleText} className={styles.title}/>
                {showRemoveBtn && <a className={styles.close} onClick={() => {
                  this.removeSelection(item);
                }}/>}
              </div>
            </div>
          );
        }
        return <React.Fragment key={item.urlKey + ":" + item.urlValue}/>;
      }
    );

    return (
      <div className={styles.selection_container} ref={this.containerRef}>
        {selectedFilters}
      </div>
    );
  }

  render() {
    const {
      selection,
      className,
      visibleClassName,
      expandedClassName,
      iconClassName,
      headerClassName,
      filterClassName,
      Filter,
      contentRef,
      displayId,
      displayString,
      hideClearBtn,
      timezone,
      ...otherProps
    } = this.props;
    const {initialized, isOpen, removedSelection} = this.state;

    const classes = cn(styles.container, className, {
      [styles.show]: initialized,
      [styles.open]: isOpen,
      [expandedClassName]: isOpen,
      [visibleClassName]: initialized,
    });

    let emptySelection = selection.length > 0 ? false : true;
    if (selection.length === 1 && selection[0].queryKey === 'endTime' && selection[0].urlKey === 'unused') {
      emptySelection = true;
    }
    if (selection.length === 1 && selection[0].urlValue === "custom" && selection[0].shouldDisplayBubble == false) {
      emptySelection = true;
    }
    const clearBtn = selection.some((sel) => sel.shouldDisplayBubble === true);

    return (
      <div className={classes}>
        <div className={cn({[styles.padding]: !emptySelection && clearBtn}, {[styles.open_header]: isOpen}, headerClassName)}>
          <div className={styles.toggle} onClick={this.toggleOpen}>
            <div className={cn(styles.icon, iconClassName || styles.unknown)}/>
            <AutoIntl displayId={displayId} displayString={displayString}/>
            {!emptySelection && hideClearBtn === false && clearBtn &&
            <AutoIntl
              displayId={'au.filterSegment.clear'}
              tag='a'
              onClick={this.clearSelection}
              className={styles.button}
            />}
          </div>
          {this.renderSelection()}
        </div>
        <div className={cn(styles.filter, filterClassName)}>
          {Filter &&
          <Filter
            {...otherProps}
            selection={selection}
            onChange={this.handleOnChange}
            onInit={this.handleOnInit}
            containerRef={contentRef.current}
            timezone={timezone}
            removedSelection={removedSelection}
          />
          }
        </div>
      </div>
    );
  }
}
