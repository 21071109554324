import accountsService from "./accounts-service.yaml";
import commandService from "./command-service.yaml";
import commandAlphaService from "./command-alpha-service.yaml";
import deploymentService from "./deployment-service.yaml";
import feedService from "./feed-service.yaml";
import ftcpService from "./ftcp-service.yaml";
import geofenceService from "./geofence-service.yaml";
import iam2Service from "./iam2-service.yaml";
import iamService from "./iam-service.yaml";
import inventoryService from "./inventory-service.yaml";
import tmcAudits from "./tmc-audits.yaml";
import commandManagementService from "./command-management-service.yaml";
import rawMessageService from "./raw-message-service.yaml";
import processingCrudService from "./processing-crud-service.yaml";

// Test coverage in entitySpec.js

export default {
  ...accountsService,
  ...commandService,
  ...commandAlphaService,
  ...deploymentService,
  ...feedService,
  ...ftcpService,
  ...geofenceService,
  ...iam2Service,
  ...iamService,
  ...tmcAudits,
  ...inventoryService,
  ...commandManagementService,
  ...rawMessageService,
  ...processingCrudService
};
