import React from 'react';
import * as T from 'prop-types';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import EntityLink from './EntityLink';
import FancyKeyValue from './FancyKeyValue';

import { SERVICE_NAMES, tapFilterType } from '../constants';

import styles from '../css/components/vehicle_tap_filter_detail.module.scss';

export default class VehicleTapFilterDetail extends React.Component {
  static propTypes = {
    tapFilter: T.shape({
      filterType: T.string,
      value: T.string
    })
  }

  renderGroupName(name) {
    return (
      <div className= {styles.group_name} >
        <AutoIntl displayId="au.entity.attr.name" tag="span" />: {name}
      </div>
    );
  }

  render() {
    const { tapFilter } = this.props;
    const filterType = tapFilter.get('filterType');
    const filterValue = tapFilter.get('value');

    if (filterType === tapFilterType.DEVICE) {
      const kv = { 'au.entity.attr.type': filterValue };
      return (
        <FancyKeyValue value={kv}/>
      );
    }
    else if (filterType === tapFilterType.GROUP) {
      const { popoutContained } = this.props;
      const name = filterValue.get('name');
      const id = filterValue.get('id');
      return (
        <div className={styles.group_detail}>
          { name ? this.renderGroupName(name) : ''}
          <AutoIntl className={styles.group_id} displayId="au.taps.filter.details.groupId" tag="span" />:{' '}
          <EntityLink
            className={styles.group_link}
            entityId={id}
            url={`/services/${SERVICE_NAMES.INVENTORY}/groups`}
            action='view'
            tracking={{ page: 'ManageFilters', action: 'group' }}
            popoutContained={popoutContained}
          >
            { id }
          </EntityLink>
        </div>
      );
    }
    else if (filterType === tapFilterType.MEMBERSHIP_TAG) {
      return filterValue ?? false;
    }

    return false;
  }
}
