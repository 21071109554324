// NOTE not sure why but import from `js-base64` doesn't work.
// maybe webpack has some rules for how .js and .mjs files are resolved
import {Base64} from "js-base64";

/**
 * Serialize statement subjectAui, resourceAui and effect fields into
 * a base 64 string
 * @param  {string} statement  Statement object
 * @return {string}             Base64 string
 */
export function statementIdSerializer(statement) {
  const { subjectAui, resourceAui, effect } = statement;
  return generateIdSerializer([subjectAui, resourceAui, effect]);
}

/**
 * Deserialize statementId into subjectAui and resourceAui
 * @param  {string} statementId Statement Id (base64 string)
 * @return {object}             Object containing deserialized subjectAui,
 *                              resourceAui and effect
 */
export function statementIdDeserializer(statementId) {
  const [subjectAui, resourceAui, effect] = generateIdDeserializer(statementId);
  return { subjectAui, resourceAui, effect };
}

/**
 * Serialize a list of fields (joined by "|") into a base 64 string
 * @param  {array} fields Array of fields to serialize
 * @return {string}       Base64 string
 */
export function generateIdSerializer(fields) {
  const toEncode = fields.join('|');
  return Base64.encode(toEncode);
}

/**
 * Deserialize statementId into subjectAui and resourceAui
 * @param  {string} generatedId Id generated by generateIdSerializer (base64 string)
 * @return {object}             Array containing deserialized fields
 */
export function generateIdDeserializer(generatedId) {
  return Base64.decode(generatedId).split('|');
}
