import React from 'react';
import * as T from 'prop-types';

import {
  BANNER_TYPE_LOADING,
  BANNER_TYPE_ALERT,
  BANNER_TYPE_SUCCESS,
  BANNER_TYPE_INFO_CIRCLE
} from './Banner';
import Banner from './Banner';

function ValidationMessage({
  prefixMsg, infoMsg, validMsg, invalidMsg,
  inProgressMsg, values, className, valid=null, inProgress=null
}) {
  let [bannerType, msg] = [null, null];

  if (inProgress) {
    [bannerType, msg] = [BANNER_TYPE_LOADING, inProgressMsg];
  }
  else if (inProgress === false) {
    [bannerType, msg] = valid ?
      [BANNER_TYPE_SUCCESS, validMsg] :
      [BANNER_TYPE_ALERT, invalidMsg];
  }
  else {
    [bannerType, msg] = [BANNER_TYPE_INFO_CIRCLE, infoMsg];
  }

  if (!bannerType || !msg) {
    return false;
  }

  return (
    <Banner 
      type={bannerType} 
      displayId={`${prefixMsg}.${msg}`} 
      className={className}
      values={values} 
    />
  );
}

ValidationMessage.propTypes = {
  className: T.string,
  prefixMsg: T.string,
  infoMsg: T.string,
  validMsg: T.string,
  invalidMsg: T.string,
  inProgressMsg: T.string,
  valid: T.bool,
  inProgress: T.bool
};

export default ValidationMessage;

