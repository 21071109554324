import { policyType, knownPolicyProps, SERVICE_NAMES } from '../../../constants';
import { cleanupPolicy } from '../../../utils/policies';
import { statementIdSerializer, statementIdDeserializer } from '../../../utils/serializers';
import DefaultEdit from '../Edit';

export default class StatementEdit extends DefaultEdit {

  entityProcessors = [
    data => {
      const policy = {
        type: policyType.SUBJECT,
        subjectAui: data.subjectAui,
        description: data.description,
        statements: [ data ]
      };
      return cleanupPolicy(policy, [...knownPolicyProps, 'subjectAui', 'resourceAui' ]);
    }
  ]

  getEntityProcessors = this.getEntityProcessors.bind(this);
  getEntityProcessors() {
    const processors = super.getEntityProcessors();
    return [...processors, ...this.entityProcessors];
  }

  loadData() {
    const { match } = this.props;
    const entityId  = decodeURIComponent(match.params.entityId);

    if (entityId) {
      const { subjectAui, resourceAui } = statementIdDeserializer(entityId);
      if (subjectAui && resourceAui) {
        const { entity, entityDef, endpoint, match, actions } = this.props;
        const queryParams = { subjectAui, resourceAui };

        if (!(entity.size && entity.has(entityDef.pkField))) {
          const { entityAlias } = match.params;

          return endpoint.inspect(queryParams).then(resp => {
            actions.listEntitiesSuccess({
              path: ['services', SERVICE_NAMES.IAM2, 'entities', entityAlias],
              data: resp.data.statements.map(entry => {
                entry._id = statementIdSerializer(entry);
                return entry;
              }),
              pkField: entityDef.pkField
            });
          }, this.onAfterFetch).catch(this.genericErrorHandler);
        }
      }
    }
  }

}
