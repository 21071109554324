import React from 'react';
import IPT from 'react-immutable-proptypes';

import EntityLink from './EntityLink';
import { formatMessage } from '../utils/reactIntl';
import SimpleTable, { Row, Cell, HeaderCell } from '../components/SimpleTable';
import { SERVICE_NAMES } from "../constants";

import styles from '../css/components/vehicle_bindings.module.scss';

export default class VehicleBindings extends React.Component {

  static propTypes = {
    bindings: IPT.list
  }

  render() {
    const { bindings } = this.props;

    if (!bindings.size) {
      return false;
    }

    // some devices are repeatedly bound to the same vehicle but since the data
    // is identical for each binding remove duplicates
    const uniqueBindings = new Map();
    for (let b of bindings.toJS()) {
      uniqueBindings.set(b.id, b);
    }

    const rows = [...uniqueBindings.values()].map(binding =>
      <Row key={binding.id} className={styles.row}>
        <Cell className={styles.type}>{binding.type}</Cell>
        <Cell className={styles.device}>
          <EntityLink
            url={`/services/${SERVICE_NAMES.INVENTORY}/devices`}
            entityId={binding.id}
            tracking={{
              action: 'device',
              page: 'VehicleView'
            }}
          >{(binding.properties || {}).serial || binding.id}</EntityLink>
        </Cell>
      </Row>
    );

    return (
      <div className={styles.container}>
        <SimpleTable className={styles.table}>
          <Row className={styles.header}>
            <HeaderCell className={styles.type}>
              { formatMessage({ id: 'au.vehicle.bindings.deviceType' }) }
            </HeaderCell>
            <HeaderCell className={styles.device}>
              { formatMessage({ id: 'au.vehicle.bindings.device' }) }
              <span className={styles.hint}>
                ({ formatMessage({ id: 'au.vehicle.bindings.device.hint' })})
              </span>
            </HeaderCell>
          </Row>
          { rows }
        </SimpleTable>
      </div>
    );
  }

}
