import { getParentEntityAwarePath } from '../../../utils/entity';
import CommandView from './CommandView';

export default class VehicleCommandView extends CommandView {

  // In this case we want to store `commands` under the parent `vehicle`
  onAfterFetch = this.onAfterFetch.bind(this);
  onAfterFetch({ data }) {
    const { parentEntity, entityDef, actions, match } = this.props;
    const path = [
      ...getParentEntityAwarePath(parentEntity, (match?.params?.entityAlias || `${entityDef.type}s`)),
      data[entityDef.pkField]
    ];

    actions.getEntitySuccess({ path, data, pkField: entityDef.pkField });
  }

  loadData = this.loadData.bind(this);
  loadData() {
    const { parentEntity, entityDef, match } = this.props;
    return this.loadCommandData(getParentEntityAwarePath(parentEntity, (match?.params?.entityAlias || `${entityDef.type}s`))).catch(this.genericErrorHandler);
  }

}
