import TMC from '@autonomic/browser-sdk';
import React from 'react';
import { fromJS } from 'immutable';

import AuDropDown from "@au/core/lib/components/elements/AuDropDown";
import AuButton, { BUTTON_TYPE_SECONDARY} from "@au/core/lib/components/elements/AuButton";
import ProcessingButton from '@au/core/lib/components/elements/ProcessingButton';
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import { createResponseAlertMessage } from "@au/core/lib/components/objects/AlertMessage";

import DefaultList from '../List';
import loadingIcon from '../../../images/spinner_white.gif';

import styles from "../../../css/components/permits_lookup.module.scss";

export default class PermittedFieldsList extends DefaultList {

  messagesEndpoint = new TMC.services[`${'Processing-crud'}`]({ apiVersion: '1'}).mappedmessages;
  versionsEndpoint = new TMC.services[`${'Processing-crud'}`]({ apiVersion: '1'}).mappedftcpversions;
  permitsEndpoint = new TMC.services[`${'Processing-crud'}`]({ apiVersion: '1'}).permits

  constructor(props) {
    super(props);

    const hasData = props.entities.size > 0;
    const { searchText } = this.props;

    const isMobile = props.screenWidth !== 'desktop';

    this.state = {
      errorStatusCode: null,
      errorStatusMessage: null,
      entity: {},
      searchText: searchText,
      timezone: props.timezone,
      confirmEntityIdDelete: null,
      fetching: !hasData,
      updating: false,
      ready: false,
      allDataFetched: false,
      disabled: false,
      filterQueryParams: {},
      filtersCount: 0,
      sidebarExpanded: this.props.sidebarExpanded,
      subviewSidebarOpen: !isMobile,
      cancel: false,
      showReplicateDialog: false,
      showDeleteSuccessDialog: false,
      columns: [],
      columnDefs: [],
      test: false,
      changing: false,
      namespace: "",
      topLevelMessageName: "",
      versions: [],
      topLevelMessageNameOptions: [],
      entities: null,
      loading: false
    };

    this.baseUrl = props.match.url.split('/').slice(0, -1).join('/');

    if (hasData) {
      this.tableDef = this.generateTableDef();
    }

    if (props.parentEntity) {
      this.parentUrl = this.baseUrl.split('/').slice(0, -1).join('/');
    }
  }

  componentDidMount() {
    super.componentDidMount();

    this.versionsEndpoint.mappedftcpversions({"ruleIds": [parseInt(this.props.parentEntity.entityId, 10)]}).then((resp) => {
      let versionOptions = resp.data.map(version => {
        return { displayString: version, val: version };
      });
      this.setState({ versions: versionOptions });
    }).catch(createResponseAlertMessage);

    this.messagesEndpoint.mappedmessages({"ftcpVersions": [], "ruleIds": [parseInt(this.props.parentEntity.entityId, 10)]}).then((resp => {
      let messageOptions = resp.data.map(message => {
        return { displayString: message, val: message };
      });
      this.setState({ topLevelMessageNameOptions: messageOptions })
    })).catch(createResponseAlertMessage);
  }

  getDropDownOptions(options, field) {
    let unselectedOptions = [];

    if (field.length == 0) {
      return options;
    } else {
      options.map(option => {
        if (field !== option.val) {
          unselectedOptions.push({ displayString: option.displayString, val: option.val });
        }
      });
      return unselectedOptions;
    }
  }

  lookupPermits() {
    const { topLevelMessageName, namespace } = this.state;
    this.setState({loading: true});

    this.permitsEndpoint.permits({
        "topLevelMessageName": topLevelMessageName,
        "namespace": namespace,
        "applicableRuleIds": [parseInt(this.props.parentEntity.entityId, 10)]
    }).then((resp) => {
        resp.data.map(result => result.fieldId = result.fieldId.toString());
        this.setState({ entities: fromJS(resp.data)});
      }).catch(createResponseAlertMessage);

    setTimeout(() => {this.setState({loading: false});}, 1000);
  }

  getFilteredEntities() {
    return this.state.entities || this.props.entities;
  }

  handleInputChange = this.handleInputChange.bind(this);
  handleInputChange(field, value) {
    this.setState({ [field]: value }, this.updateLookupObject(field, value));
  }

  updateLookupObject(field, value) {
    this.setState(prevState => ({
      lookup: {
        ...prevState.lookup,
        [field]: value
      }
    }));
  }

  renderExtras() {
    const { topLevelMessageName, namespace, topLevelMessageNameOptions, versions, loading } = this.state;
    const getFtcpVersionOptions = this.getDropDownOptions(versions, namespace);
    const getTopLevelMessageNameOptions = this.getDropDownOptions(topLevelMessageNameOptions, topLevelMessageName);
    const disabledMessageName = getTopLevelMessageNameOptions.length === 0;

    return (
      <div className={styles.permitted_fields_container}>
        <div className={styles.hint_container}><AutoIntl className={styles.hint} displayId='au.permitsLookup.chooseMessage'/></div>
        <div className={styles.inputs_container}>
          <div className={styles.labels}>
            <AutoIntl displayId="au.permitsLookup.topLevelMessageName" className={styles.message_label} />
            <div className={styles.name_label_container}>
              <AutoIntl displayId="au.permitsLookup.namespaceFtcpVersion" />
              <AutoIntl displayId="au.permitsLookup.optional" className={styles.optional} />
            </div>
          </div>
          <div>
            <AuDropDown
              options={getTopLevelMessageNameOptions}
              placeholderId="au.permitsLookup.placeholder.typeOrChoose"
              className={styles.name_dropdown}
              selectOption={val => this.handleInputChange('topLevelMessageName', val)}
              allowTyping={true}
              showClearBtn={false}
              createMode={true}
              disabled={disabledMessageName}
            />
            <AuDropDown
              options={getFtcpVersionOptions.toReversed()}
              placeholderId="au.permitsLookup.placeholder.choose"
              className={styles.version_dropdown}
              selectOption={val => this.handleInputChange('namespace', val)}
              optionsClassName={styles.namespace_options}
              createMode={true}
              disableSort={true}
            />
          </div>
        </div>
        <div className={styles.permitted_fields_button}>
          {!loading &&
            <AuButton
              className={styles.lookup_button}
              type={BUTTON_TYPE_SECONDARY}
              displayId="au.permitsLookup.lookupPermits"
              onClick={() => this.lookupPermits()}
              disabled={!topLevelMessageName }
            />
          }
          {loading &&
            <ProcessingButton type="primary" disabled={true}>
              <img src={loadingIcon} />
              <AutoIntl displayId={'au.processing'}/>
            </ProcessingButton>
          }
        </div>
      </div>
    )
  }
}