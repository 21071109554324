const filters = {
  regex(obj, filterDef) {
    let res = true;
    for (let [field, re] of Object.entries(filterDef.properties)) {
      res = res && RegExp(re).test(obj.get(field));
      if (!res) break;
    }
    return res;
  },

  falsy(obj, filterDef) {
    return obj.get(filterDef.property);
  }
};

export default filters;
