import React from 'react';

import styles from '../css/components/styled_list.module.scss';

export default function(props) {
  return (
    <ul className={styles.list}>
      { props.children.map((child, idx) =>
        <li key={`list_item_${idx}`} className={styles.item}>{child}</li>
      )}
    </ul>
  );
}
