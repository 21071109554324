// NOTE: cannot extend builtins until we upgrade babel
//  https://github.com/babel/babel/issues/4480
//  https://github.com/babel/babel/pull/7020/files
// A Set that contains members that can be compared via custom comparison
//  function. O(n) instead of O(1) for most methods.
export default class ComparisonSet {
  constructor(iterable, comparator) {
    this._set = new Set();
    this._cmp = comparator;
    for (let x of iterable) {
      this.add(x);
    }
  }

  __get(value) {
    // First check if value really is a member
    if (this._set.has(value)) return value;

    for (let member of this) {
      if (this._cmp(value, member)) {
        return [member];
      }
    }
  }

  has(value) {
    if (this.__get(value)) return true;
    return false;
  }

  clear() {
    this._set.clear();
  }

  add(value) {
    if (!this.__get(value)) {
      this._set.add(value);
    }
    return this;
  }

  delete(value) {
    const ours = this.__get(value);
    if (ours) {
      return this._set.delete(ours[0]);
    }
    return false;
  }

  entries() {
    return this._set.entries();
  }

  forEach(...args) {
    return this._set.forEach(...args);
  }

  keys() {
    return this._set.keys();
  }

  values() {
    return this._set.values();
  }

  *[Symbol.iterator]() {
    yield *this._set[Symbol.iterator]();
  }

  get size() {
    return this._set.size;
  }
}
