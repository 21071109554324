import EntityLink from './EntityLink';

export default class DeviceEntityLink extends EntityLink {

  resolveEntityId() {
    const { lazyLoad } = this.props;

      return this.endpoint.getByUniqueProperty({
        uniquePropertyValue: this.props.entityId,
        includeRelations: 'binding',
        expandRelations: 'binding'
      })
      .then(resp =>
        resp.data ? resp.data[lazyLoad.targetProp] : Promise.reject(resp)
      );
  }

}
