import React from "react";
import styles from '../css/components/entity_link_container.module.scss';
export default class EntityLinkContainer extends React.Component{
  render() {
    const {children, ...rest} = this.props;

    return (
      <div {...rest} className={styles.container}>
        {children}
      </div>
    );
  }
}