import React from 'react';
import { fromJS } from 'immutable';

import AuComponent from '@au/core/lib/components/elements/AuComponent';

import { required, minLength, maxLength } from '../utils/validationRules';
import { EntityEdit } from './entity/Forms';
import { SimpleLayout } from './entity/Layouts';
import { TextInput, EmailInput } from './entity/Inputs';

export default class MockEdit extends AuComponent {
  /* Proposed template for end state of Update entitiy */

  render() {
    const entity = fromJS({
      id: 'vivaldi-001',
      firstName: 'Antonio',
      lastName: 'Vivaldi',
      email: 'vivaldi@musician.io'
    });

    return (
      <EntityEdit
        entity={entity}
        entityType="mock"
        entityExists={true}
        saveSuccessDestination="/geofences"
        cancelDestination="/geofences"
        actions={this.props.actions}
      >
        <SimpleLayout>
          <TextInput field="firstName" labelId="au.users.firstName" validationRules={[required, minLength(6), maxLength(8)]}/>
          <TextInput field="lastName" labelId="au.users.lastName" validationRules={[required, minLength(7), maxLength(7)]}/>
          <EmailInput field="email" labelId="au.users.email"/>
        </SimpleLayout>
      </EntityEdit>
    );
  }

}
