import React from 'react';
import { FormattedMessage } from 'react-intl';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import LoadingIndicator from '@au/core/lib/components/elements/LoadingIndicator';

import auFormatters from '../../../utils/formatters';
import DefaultView from '../View';

import styles from '../../../css/components/entity_view.module.scss';

const KNOWN_COUNTERS = Object.freeze(
  ['deployCount', 'successCount', 'failureCount']
);

export default class DeploymentView extends DefaultView {

  componentDidMount() {
    super.componentDidMount();
    this.fetchDeploymentStatus();
  }

  fetchDeploymentStatus = this.fetchDeploymentStatus.bind(this);
  fetchDeploymentStatus() {
    const { endpoint } = this.props;

    this.setState({ deploymentStatusFetching: true }, () => {
      endpoint.getState(this.entityId).then((resp) => {
        this.setState({
          deploymentStatusFetching: false,
          deploymentStatusFailed: false,
          deploymentStatus: resp.data
        });
      }).catch(() => {
        this.setState({
          deploymentStatusFetching: false,
          deploymentStatusFailed: true
        });
      });
    });
  }

  renderDeploymentStateRows() {
    const {
      deploymentStatus,
      deploymentStatusFailed,
      deploymentStatusFetching
    } = this.state;
    const rows = [];

    if (deploymentStatusFetching || deploymentStatusFailed) {
      return [];
    }

    rows.push(<tr className={styles.spacer} key="row_spacer_1" />);

    if (deploymentStatus) {
      const { deploymentState, ...counters } = deploymentStatus;
        for (let name of KNOWN_COUNTERS) {
          let dataAvailable = Object.prototype.hasOwnProperty.call(counters, name);
          rows.push(
            <tr className={styles.row} key={`row_${name}`}>
              <td className={styles.fieldname}>
                <AutoIntl displayId={`au.entity.attr.${name}`} />
              </td>
              { dataAvailable && <td className={styles.fieldvalue}>{counters[name]}</td> }
              { !dataAvailable &&
                <td className={styles.fieldvalue}>
                  <AutoIntl className={styles.placeholder} displayId="au.noDataAvailable" />
                </td>
              }
            </tr>
          );
        }

        rows.push(
          <tr className={styles.row} key={`row_deployment_state`}>
            <td className={styles.fieldname}>
              <AutoIntl displayId="au.entity.attr.deploymentState" />
            </td>
            { deploymentState &&
              <td className={styles.fieldvalue}>
                { auFormatters.deploymentState({ value: deploymentState }) }
              </td>
            }
            { !deploymentState &&
              <td className={styles.fieldvalue}>
                <AutoIntl className={styles.placeholder} displayId="au.noDataAvailable" />
              </td>
            }
          </tr>
        );
    }

    return rows;
  }

  renderTableRows() {
    const tableRows = super.renderTableRows();
    const extraRows = this.renderDeploymentStateRows();

    return [...tableRows, ...extraRows];
  }

  renderAdditionalContent() {
    const { deploymentStatusFetching, deploymentStatusFailed } = this.state;

    if (deploymentStatusFetching) {
      return (
        <div className={styles.message}>
          <LoadingIndicator displayId={'au.deployments.updatingState'} />
        </div>
      );
    } else if (deploymentStatusFailed) {
      return (
        <div className={styles.error}>
          <AutoIntl displayId="au.deployments.deployStateFetchingError" />
          &nbsp;
          <FormattedMessage
            id="au.pleaseMsg"
            values={{
              msg: (
                <AutoIntl displayId="au.tryAgain" onClick={this.fetchDeploymentStatus} tag="a" />
              )
            }}
          />
        </div>
      );
    }

    return false;
  }

}
