import AuInput from "@au/core/lib/components/elements/AuInput";
import React from "react";

const validTimeInputRegex = /^\d{0,2}$|^\d{2}:\d{0,2}$|^\d{2}:\d{0,2}:\d{0,2}$/;
const validPartialTimeInputRegex = /^\d{0,2}$|^\d{0,2}:\d{0,2}$|^\d{1,2}:\d{0,2}:\d{0,2}$/;
const validDeleteTimeInputRegex = /:\d{0,2}$|^\d{1,2}:\d{0,2}:\d{0,2}$/;

export default function TimeInput(props) {
  const {time, setTime, hasError, ...rest} = props;

  return (
    <AuInput
      {...rest}
      value={time}
      placeholderId="au.timeInput.placeholder"
      onChange={(event) => {
        const isDelete = (event.nativeEvent.inputType === "deleteContentBackward");
        if (event.target.value.match(validTimeInputRegex)) {
          if (event.target.value.length === 5) {
            if (!isDelete) {
              setTime(event.target.value + ":");
            } else {
              setTime(event.target.value.slice(0, -1));
            }
          }
          else if (event.target.value.length === 2) {
            if (!isDelete) {
              setTime(event.target.value + ":");
            } else {
              setTime(event.target.value.slice(0, -1));
            }
          }
          else {
            setTime(event.target.value);
          }
        }
        else if (event.target.value.match(validPartialTimeInputRegex)) {
          setTime(event.target.value);
        }
        else if (event.target.value.match(validDeleteTimeInputRegex) && isDelete){
          setTime(event.target.value);
        }
        else if (isDelete) {
          setTime(event.target.value);
        }
      }}
      showError={hasError}
    />
  );
}