import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import cn from 'classnames';
import moment from 'moment-timezone';
import pick from 'lodash/pick';

import AuButton, {
  BUTTON_TYPE_PRIMARY,
  BUTTON_TYPE_SECONDARY,
  BUTTON_TYPE_TERTIARY
} from '@au/core/lib/components/elements/AuButton';
import AuComponent from '@au/core/lib/components/elements/AuComponent';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import LoadingIndicator from '@au/core/lib/components/elements/LoadingIndicator';
import MultiActionButton from '@au/core/lib/components/elements/MultiActionButton';
import TreeView from '@au/core/lib/components/elements/TreeView';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { SERVICES_PATH } from '../../constants';
import { history as browserHistory } from '../../history';
import auFormatters from '../../utils/formatters';
import auProcessors from '../../utils/processors';
import { parseCallable } from '../../utils/parse';
import { loadJoinedResources } from '../../utils/api';
import {
  getParentEntityAwarePath,
  getEntityReplicateUrl,
  orderByDisplay,
  skipDisplay,
  shouldHideContent,
  hideOptionalEmptyData
} from '../../utils/entity';
import { wrapActionWithTracking } from '../../utils/analyticsHelpers';
import Breadcrumbs from './Breadcrumbs';
import EntityDeleteDialog from './EntityDeleteDialog';
import { PAGE_SCHEMA_BACK, PAGE_SCHEMA_LOGO } from '../MobilePageHeader';
import EntityReplicateDialog from '../../containers/EntityReplicateDialog';
import { setPageTitle } from "../utils/pageTitle";
import SidebarSubviews from "../SidebarSubview";
import DeleteSuccessDialog from './SuccessDialog';
import { formatMessage } from '../../utils/reactIntl';
import defer from '../../utils/defer';

import styles from '../../css/components/entity_view.module.scss';

export default class View extends AuComponent {

  sessionTimezone = localStorage.getItem('timezone');

  static propTypes = {
    entity: IPT.map.isRequired,
    endpoint: T.object.isRequired,
    entityDef: T.object.isRequired,
    timezone: T.string,
    match: T.shape({
      params: T.shape({
        entityId: T.string.isRequired
      }).isRequired
    }).isRequired,
    popoutContained: T.bool
  }

  static defaultProps = {
    timezone: this.sessionTimezone || moment.tz.guess(),
    location: {}
  }

  /**
   * Allow for overrides for custom views
   * Should have key as property to hide and a fn()/bool to check whether it should be hidden
   * ex: { "id": () => this.props.id != null }
   */
  hiddenFields = {}

  /**
   * Allow for overrides for custom views
   */
  queryParams = {}

  _leftSidebarRef = React.createRef();
  _initParentEntity = defer();

  constructor(props) {
    super(props);

    const { entity, entityDef, location, match, screenWidth } = props;
    const { prevUrl } = location.state || {};
    const hasData = entity?.size && entity?.has(entityDef.pkField);

    // must be set in contructor so initial state can be extended
    const isMobile = screenWidth !== "desktop";
    this.state = {
      fetching: !hasData,
      ready: hasData,
      showReplicateDialog: false,
      subviewSidebarOpen: !isMobile,
      showDeleteSuccessDialog: false
    };

    this.entityId  = decodeURIComponent(match.params.entityId);
    this.baseUrl   = match.url.split('/').slice(0, -2).join('/');
    this.parentUrl = prevUrl || this.baseUrl + '/' + (entityDef.landingPage || 'list');
  }

  componentDidMount() {
    const { entityDef, resources, actions, breadcrumbs } = this.props;
    this.genericErrorHandler('clearEvents');

    setPageTitle(actions.setPageTitle, breadcrumbs);
    actions.setPageSchema(PAGE_SCHEMA_BACK);

    // `source` and `join` attributes are treated in the same way
    const sources = (
      Object.values(entityDef.attributes)
        .filter(attr => attr?.source || attr?.sources || attr?.join)
        .reduce((acc, attr) => {
          attr.source && acc.push(attr.source);
          attr.join && acc.push(attr.join);
          attr.sources && attr.sources.forEach(source => acc.push(source));
          return acc;
        }, [])
    );
    /*
      sources - contains service/entity definitions from where data needs to be loaded
      resources - contains actual data that was already loaded (based on `sources`)
     */
    loadJoinedResources(sources, resources, actions)
      .then(this.fetchParentEntity)
      .catch(this.genericErrorHandler)
      .then(() => {
        // Need to wait until parentEntity.entity appears in props
        return this._initParentEntity.promise;
      })
      .then(this.loadData).then(() => {
        if (!this.state.ready) {
          this.setState({ ready: true });
        }
      });
  }

  fetchParentEntity = this.fetchParentEntity.bind(this);
  fetchParentEntity() {
    const { parentEntity } = this.props;
    // If we don't have our parent entity, fetch first for breadcrumb data and similar
    if (parentEntity && !parentEntity.entity && parentEntity.endpoint.actions.includes('get')) {
      return parentEntity.endpoint.get(parentEntity.entityId, parentEntity.entityDef.queryParams);
    }

    this._initParentEntity.resolve();
    return Promise.resolve();
  }

  componentDidUpdate(prevProps) {
    const { parentEntity, actions, screenWidth, breadcrumbs} = this.props;
    const isMobile = screenWidth !== "desktop";

    if (parentEntity?.entity && this._initParentEntity.isPending()) {
      this._initParentEntity.resolve();
    }

    if (prevProps && prevProps.screenWidth === undefined) {
      this.setState({ subviewSidebarOpen: !isMobile });
    }

    if (prevProps && prevProps.breadcrumbs !== breadcrumbs) {
      setPageTitle(actions.setPageTitle, breadcrumbs);
    }

    if (screenWidth === 'tabletPortrait') {
      actions.setPageSchema(PAGE_SCHEMA_LOGO);
    } else {
      actions.setPageSchema(PAGE_SCHEMA_BACK);
    }
  }

  loadData = this.loadData.bind(this);
  loadData() {
    const { entityDef, parentEntity, endpoint, match, actions } = this.props;

    if (this.entityId) {
      // fallback for endpoint that doesn't support .get() action
      if (endpoint.actions.includes('get')) {
        return endpoint.get(this.entityId, this.queryParams).then(this.onAfterFetch, this.genericErrorHandler);
      } else {
        // NOTE we should consider dropping this, since it's not reliable and
        // will work only if the requested object is returned within the first page
        if (parentEntity && entityDef.type === 'permission') {
          const { entityAlias } = match.params;
          return endpoint.inspect({
            objectAui: (parentEntity.entityDef.arn ? parentEntity.entityDef.arn + '/' : '') + parentEntity.entityId
          }).then(resp => {
            actions.listEntitiesSuccess({
              path: getParentEntityAwarePath(parentEntity, entityAlias),
              data: resp.data.items,
              pkField: entityDef.pkField
            });
            return { data: {}, ...resp };
          }).then(this.onAfterFetch, this.genericErrorHandler);
        } else {
          return endpoint.list().then(this.onAfterFetch, this.genericErrorHandler);
        }
      }
    }
  }

  onAfterFetch = this.onAfterFetch.bind(this);
  onAfterFetch({ data }) {
    const { parentEntity, entityDef, actions, match } = this.props;
    // In case we need to nest the entity under a parent entity
    if (parentEntity) {
      const path = [
        ...getParentEntityAwarePath(parentEntity, (match?.params?.entityAlias || `${entityDef.type}s`)),
        data[entityDef.pkField]
      ];
      actions.getEntitySuccess({ path, data, pkField: entityDef.pkField });
    }
    this.setState({ fetching: false });
  }

  onBackBtnClick = this.onBackBtnClick.bind(this);
  onBackBtnClick() {
    const { prevUrl } = this.props.location.state || {};
    browserHistory.push(prevUrl || this.parentUrl);
  }

  onViewBtnClick = this.onViewBtnClick.bind(this);
  onViewBtnClick() {
    //FIXME - MOVE TO linkHelper
    browserHistory.push({
      pathname: this.baseUrl + `/${this.entityId}/view`,
      state: { prevUrl: this.props.match.url }
    });
  }

  onEditBtnClick = this.onEditBtnClick.bind(this);
  onEditBtnClick() {
    //FIXME - MOVE TO linkHelper
    browserHistory.push({
      pathname: this.baseUrl + `/${this.entityId}/edit`,
      state: { prevUrl: this.props.match.url }
    });
  }

  onDeleteBtnClick = this.onDeleteBtnClick.bind(this);
  onDeleteBtnClick() {
    if (this.props.entity) {
      // show the dialog
      this.setState({ showDeleteDialog: true });
    }
  }

  toggleReplicateDialog = this.toggleReplicateDialog.bind(this);
  toggleReplicateDialog() {
    this.setState(prevState => ({ showReplicateDialog: !prevState.showReplicateDialog } ));
  }

  toggleDeleteDialog = this.toggleDeleteDialog.bind(this);
  toggleDeleteDialog() {
    this.setState(prevState => ({ showDeleteDialog: !prevState.showDeleteDialog }));
  }

  toggleDeleteSuccessDialog = () => {
    browserHistory.push(this.parentUrl);
  }

  deleteEntity = this.deleteEntity.bind(this);
  deleteEntity() {
    if (this.entityId) {
      const { endpoint, entityDef, parentEntity, match, actions, entity } = this.props;

      return endpoint.delete(this.entityId).then(() => {
        if (parentEntity && entityDef.type === 'permission') {
          const { entityAlias } = match.params;
          const path = getParentEntityAwarePath(parentEntity, entityAlias);
          actions.deleteEntitySuccess({ path: [...path, this.entityId.toString()] });
        }
        this.setState({ showDeleteSuccessDialog: true, entity });
        // hide the dialog
        this.toggleDeleteDialog();
      }, this.genericErrorHandler);
    } else {
      // hide the dialog
      this.toggleDeleteDialog();
    }
  }

  genericErrorHandler = this.genericErrorHandler.bind(this);
  genericErrorHandler(error) {
    if (error !== 'clearEvents' && error?.data?.status === 403 || error?.data?.status === 404 || error?.data?.error === "forbidden") {
      this.setState({ hideContent: true, showError: true });
    }
    createResponseAlertMessage(error);
  }

  isHiddenField(property) {
    return (Object.keys(this.hiddenFields).length && (property in this.hiddenFields));
  }

  shouldHideField(property) {
    const hide = this.hiddenFields[property];
    return typeof hide === 'function' ? hide() : hide;
  }

  getPropertyValue(property) {
    return this.props.entity.get(property, '');
  }

  renderFormattedValue = this.renderFormattedValue.bind(this);
  renderFormattedValue(property, attr) {
    const { entity, resources, popoutContained } = this.props;
    const { entityAlias, action } = this.props.match.params;
    let formatters = [];
    let processors = [];

    if (attr?.processors) {
      processors = parseCallable(attr.processors);
    }

    let value = this.getPropertyValue(property);
    if (!attr?.formatters) {
      if (attr && 'ref' in attr && typeof value !== "object") {
        // avoid circular links
        if ('entity' in attr.ref && 'service' in attr.ref) {
          formatters = [{
            func: 'entityLink',
            args: {
              idProperty: attr.ref.idProperty || property,
              url: `${SERVICES_PATH}/${attr.ref.service}/${attr.ref.entity}`,
              popoutContained
            }
          }];
        }
      }
    } else {
      formatters = parseCallable(attr.formatters);
    }

    for (let { func, args } of processors) {
      if (typeof value !== 'undefined' && typeof value[func] === 'function') {
        value = value[func]();
      } else if (typeof auProcessors[func] === 'function') {
        value = auProcessors[func]({ ...args, rowData: entity, property, value });
      }
    }

    if (formatters.length) {
      for (let { func, args } of formatters) {
        // copy args, add tracking
        args = {
          action,
          tracking: {
            page: `${entityAlias}Detail`
          },
          ...args,
          popoutContained // formatters can handle rendering differently when in Popout
        };
        if (typeof value !== 'undefined' && value !== null && typeof value[func] === 'function') {
          value = value[func]();
        } else if (typeof auFormatters[func] === 'function') {
          if (func === 'date' && !args.timezone) {
            args.timezone = this.sessionTimezone ? this.sessionTimezone : this.props.timezone;
          }
          value = auFormatters[func]({ ...args, rowData: entity, property, value, resources, popoutContained });
        }
      }
    } else if (typeof value === 'object') {
      // Fallback in case of an undeclared formatter.
      if (attr?.multiRow) {
        value = value.map((v, index) => <TreeView key={index} data={v} />);
      } else {
        value = <TreeView data={value} />;
      }
    }

    return value;
  }

  getBanner() {
    return false;
  }

  getActions() {
    const { entityDef, permissions, popoutContained } = this.props;
    const { entityAlias } = this.props.match.params;
    const actions  = [];

    if (!entityDef.readonly && permissions.canEdit) {
      actions.push({
        key: 'entity_edit_btn',
        type: BUTTON_TYPE_PRIMARY,
        className: styles.edit,
        displayId: 'au.entity.edit',
        onClick: this.onEditBtnClick,
      });
    }

    if (popoutContained) {
      if (entityDef.type == "tap") {
        actions.splice(0, 0, {key: 'entity_view_details_btn',
        type: BUTTON_TYPE_PRIMARY,
        className: styles.details,
        displayId: 'au.entity.viewDetails',
        onClick: this.onViewBtnClick,});
      }
      else {
        actions.push({
          key: 'entity_view_details_btn',
          type: BUTTON_TYPE_PRIMARY,
          className: styles.details,
          displayId: 'au.entity.viewDetails',
          onClick: this.onViewBtnClick,
        });
      }
    }
    if (!entityDef.readonly && permissions.canDelete) {
      actions.push({
        key: 'entity_delete_btn',
        type: BUTTON_TYPE_TERTIARY,
        className: styles.delete,
        displayId: 'au.entity.delete',
        onClick: this.onDeleteBtnClick,
      });
    }

    if (!entityDef.readonly && entityDef.allowReplicate && permissions.canCreate) {
      actions.push({
        key: 'entity_replicate_btn',
        type: BUTTON_TYPE_SECONDARY,
        className: styles.button,
        displayId: 'au.entity.replicate',
        onClick: this.toggleReplicateDialog
      });
    }

    return actions.map((mappedAction) =>
      wrapActionWithTracking(mappedAction, entityAlias, 'View')
    );
  }

  getNavLinks() {
    const { entityDef } = this.props;
    const navLinks =[];

    if (entityDef.subviews) {
      for (let [subViewAlias, subViewDef] of Object.entries(entityDef.subviews)) {
        if (skipDisplay(subViewDef, 'view')) continue;
        if (shouldHideContent(subViewDef)) continue;

        //Is this a non-List custom View? Construct a custom action link
        if (subViewDef.action) {
          navLinks.push({
            labelId: subViewDef.labelId || `au.entity.title.${entityDef.type}.${subViewDef.action}`,
            destination: `${this.baseUrl}/${encodeURIComponent(this.entityId)}/${subViewDef.action}`,
            isEndOfSection: subViewDef.action === "view"
          });
        }
        else { //assume is a sub-entity list
          navLinks.push({
            labelId: `au.entity.title.${subViewDef.type}`,
            destination: `${this.baseUrl}/${encodeURIComponent(this.entityId)}/${subViewAlias}/list`
          });
        }
      }
    }

    return navLinks;
  }

  getCrumbs() {
    return [...this.props.breadcrumbs];
  }

  // Allow overrides
  renderExtraButtons() {
    return false;
  }

  renderActions(actionsDisabled=false) {
    const { popoutProps, screenWidth, popoutContained } = this.props;
    const actions = this.getActions();

    if (actions?.length === 1) {
      return (
        <div key={'actions'} className={cn(styles.actions, {[styles.bottom_gap_24]: popoutContained})}>
          { this.renderExtraButtons() }
          <AuButton {...actions[0]}
            disabled={actionsDisabled || popoutProps && popoutProps.inferredEntity}
          />
        </div>
      );
    } else if (actions?.length > 1) {
      return (
        <div key={'actions'} className={cn(styles.actions, {[styles.bottom_gap_24]: popoutContained})}>
          { this.renderExtraButtons() }
          <MultiActionButton
            className={styles.multi_action}
            actions={actions.map(a => pick(a, ['displayId', 'disabled', 'onClick']))}
            screenWidth={screenWidth === 'mobile' ? 'mobile' : 'desktop'}
            disabled={actionsDisabled || popoutProps && popoutProps.inferredEntity}
          />
        </div>
      );
    }

    return false;
  }

  /*
   * An over-ride / interception method for the express purpose of allowing
   * a subclass to add additional content to the View Details page for an entity.
   * This is particularly useful when the additional content requires subsequent
   * API calls to the platform in order to populate and may have a variety of
   * possible view states (for example: loading, no data, error, populated). For
   * data which is to be represented as just another property of the entity, then
   * the `loadData` technique can be used.
   */
  renderAdditionalContent() {
    return [];
  }

  renderContentAbove() {
    return [this.renderActions()];
  }

  setSubviewSidebar = this.setSubviewSidebar.bind(this);
  setSubviewSidebar(value) {
    this.setState({ subviewSidebarOpen: value });
  }

  // Similar to `renderAdditionalContent` allows for rendering of components above
  // the normal table view
  renderContentAboveTable() {
    return [];
  }

  renderSection() {
    return (
      <table className={styles.rows} ref={ref => this.tableRef = ref}>
        <tbody>
          {this.renderTableRows()}
        </tbody>
      </table>
    );
  }

  renderContent() {
    const { screenWidth } = this.props;
    const isMobile = screenWidth !== "desktop";
    const subviewVal = this.state.subviewSidebarOpen === undefined && !isMobile;

    return (
      <div className={styles.content} ref={ref => this.containerRef = ref}>
        <div className={cn(styles.content_flex, {[styles.no_margin_top]: this.props.popoutContained} )}>
          {!this.props.popoutContained && <SidebarSubviews
            navLinks={this.getNavLinks()}
            portalRef={this._leftSidebarRef}
            open={subviewVal || this.state.subviewSidebarOpen}
            setOpen={this.setSubviewSidebar}
            isMobile={isMobile}
          />}
          <div className={styles.table}>
            <div className={styles.section}>
              {this.props.popoutContained && this.renderContentAbove()}
              {this.renderContentAboveTable()}
              {this.renderSection()}
            </div>
            {this.renderAdditionalContent()}
            {this.renderDialogs()}
          </div>
        </div>
      </div>
    );
  }

  renderRow(fieldId, val, position) {
    return (
      <tr key={position ? `field_${fieldId}_${position}` : `field_${fieldId}`} className={styles.row} ref={ref => this.rowRef = ref} >
        <td className={styles.fieldname}>
          <AutoIntl displayId={fieldId} values={{position}}/>
        </td>
        <td className={styles.fieldvalue}>
          {val}
        </td>
      </tr>
    );
  }

  renderTableRows(customAttributes) {
    const { entityDef, entity } = this.props;
    const rows = [];

    if (entity.size) {
      const orderedAttrs = orderByDisplay(customAttributes || entityDef.attributes, 'view');
      for (let [property, attr] of orderedAttrs) {
        if (hideOptionalEmptyData(attr, property, entity)) continue;
        if (skipDisplay(attr, 'view')) continue;
        if (shouldHideContent(attr)) continue;
        if (this.isHiddenField(property) && this.shouldHideField(property)) {
          continue;
        }
        if (attr?.multiRow) {
          const values = this.renderFormattedValue(property, attr);
          values && values.forEach((value, index) => {
            rows.push(this.renderRow(attr?.labelId || `au.entity.attr.${property}`, value, index + 1));
          });
        } else if (!['filters', 'inputView', 'outputView'].includes(property)) {
          rows.push(
            this.renderRow(attr?.labelId || `au.entity.attr.${property}`, this.renderFormattedValue(property, attr))
          );
        } else {
          rows.push(
            <tr key={`field_${property}`} className={styles.filters_row} ref={ref => this.rowRef = ref} >
              <td className={styles.label}>
                <AutoIntl displayId={attr?.labelId || `au.entity.attr.${property}`} />
              </td>
              <td className={styles.fieldvalue}>
                {this.renderFormattedValue(property, attr)}
              </td>
            </tr>
          );
        }
      }
    }

    return rows;
  }

  renderBreadcrumbs() {
    return (
      <div className={styles.header}>
        <Breadcrumbs className={styles.breadcrumbs} crumbs={this.getCrumbs()} />
        <div>{this.renderContentAbove()}</div>
      </div>
    );
  }

  renderDialogs() {
    const { serviceAlias, entityDef, parentEntity, match } = this.props;
    const { entityAlias } = match.params;
    const { showDeleteSuccessDialog, entity } = this.state;
    const dialogs = [];

    dialogs.push(
      <EntityDeleteDialog
        key="entity_delete_dialog"
        show={this.state.showDeleteDialog}
        entityType={this.props.entityDef.type}
        onConfirm={this.deleteEntity}
        onCancel={this.toggleDeleteDialog}
      />
    );

    if (entityDef.allowReplicate && this.state.showReplicateDialog) {
      dialogs.push(
        <EntityReplicateDialog
          key="entity_replicate_dialog"
          nextUrl={getEntityReplicateUrl(serviceAlias, entityAlias, parentEntity?.entityAlias)}
          prevUrl={match.url}
          entity={this.props.entity}
          noticeDisplayId={entityDef.replicateNoticeDisplayId}
          onBeforeReplicate={this.onBeforeReplicate}
          onCancel={this.toggleReplicateDialog}
        />
      );
    }
    if (showDeleteSuccessDialog) {
      dialogs.push(
        <DeleteSuccessDialog
          nameDisplayId="au.entity.delete.success.name"
          nameValues={{
            entity: formatMessage({ id: `au.entity.name.${entityDef.type}` }),
            b: chunks => <strong>{chunks}</strong>,
            entityName: entity.get('displayName') || this.entityId
          }}
          messageId="au.entity.delete.success.message"
          messageValues={{entity: <span className={styles.message}> {formatMessage({ id: `au.entity.name.${entityDef.type}` })}</span>}}
          onClose={this.toggleDeleteSuccessDialog}
        />
      );
    }

    return dialogs;
  }

  containerSubviewStyle(isOpen){
    if(isOpen){
      return styles.subview_open;
    }
    return styles.subview_closed;
  }

  renderPopout() {
    return false;
  }

  renderAddons() {
    return false;
  }

  render() {
    const { entity, entityDef, screenWidth } = this.props;
    const { ready, subviewSidebarOpen, showError } = this.state;
    const isMobile = screenWidth !== "desktop";
    const subviewVal = this.state.subviewSidebarOpen === undefined && !isMobile;
    const found = this.state.found || Boolean(entity.size && entity.has(entityDef.pkField));

    return (
      <>
        <div ref={this._leftSidebarRef} />
        <div className={cn("o-wrapper", styles.container, this.containerSubviewStyle(subviewVal || subviewSidebarOpen))}>
          { this.renderBreadcrumbs() }
          { this.renderAddons() }
          { this.getBanner() }
          {!showError && <LoadingIndicator className={styles.loader} display={!ready} /> }
          { !!ready && found && !showError && this.renderContent() }
          { this.renderDialogs() }
        </div>
        { this.renderPopout() }
      </>
    );
  }
}
