import React from "react";
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import moment from 'moment-timezone';

import AutoIntl from "@au/core/lib/components/elements/AutoIntl";

import viewStyles from "../css/components/entity_view.module.scss";
import styles from "../css/components/sub_table.module.scss";

export default class SubTable extends React.Component {

  static propTypes = {
    attributes: T.object,
    value: IPT.map.isRequired,
    valueProp: T.string,
    keyProp: T.string,
    popoutContained: T.bool,
    resources: T.instanceOf(Map),
    applyFormatters: T.func
  }

  static defaultProps = {
    attributes: {}
  }

  render() {
    const {attributes, value, valueProp, keyProp, popoutContained, resources, applyFormatters} = this.props;

    const rows = Object.entries(attributes).map(([k, v]) => {
      let displayValue = "";
      if (v.formatters === 'date') {
        const sessionTimezone = localStorage.getItem('timezone');
        const TZ = moment.tz.guess();

        const timezoneOption = sessionTimezone ? sessionTimezone : TZ;
        displayValue = moment.tz(value?.get?.(k), timezoneOption).format("MMM DD YYYY hh:mm a z");
      }
      else {
        displayValue = v.formatters ?
        applyFormatters(v.formatters, value, valueProp, keyProp, popoutContained, resources) :
        value?.get?.(k) || "";
      }

      return <tr key={`field_${k}`} className={viewStyles.row}>
        <td className={viewStyles.fieldname}>
          <AutoIntl displayId={`au.entity.attr.${k}`}/>
        </td>
        <td className={viewStyles.fieldvalue}>
          {displayValue}
        </td>
      </tr>;
    });

    return <table className={styles.subtable}>
      <tbody>{rows}</tbody>
    </table>;
  }
}
