import React from 'react';
import upperFirst from 'lodash/upperFirst';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import TapValidationIcon from '../TapValidationIcon';
import FancyKeyValue from '../FancyKeyValue';
import { tapFilterType } from '../../constants';
import { formatMessage } from '../../utils/reactIntl';
import EntityLink from '../../containers/EntityLink';
import { getEntityUrlByAui } from '../../utils/entity';
import HintTooltip from '../HintTooltip';

const _validationStatusMapper = {
  PERMIT_PERMITTED: "valid",
  DENY_PERMITTED: "valid",
  PERMIT_DEFAULT_DENY: "invalid",
  PERMIT_EXPLICIT_DENY: "invalid",
  DENY_DEFAULT_DENY: "warning",
  DENY_EXPLICIT_DENY: "warning",
  PERMIT_REGEX_PARTIAL_PERMIT: "valid",
  PERMIT_REGEX_DEFAULT_DENY: "warning",
  DENY_REGEX_DEFAULT_DENY: "warning",
  PERMIT_PARTIAL_DENY: "invalid",
  DENY_PARTIAL_DENY: "warning",
  PERMIT_PARTIAL_PERMIT: "invalid",
  DENY_PARTIAL_PERMIT: "warning",
  PERMIT_PARTIAL_PERMIT_DENY: "invalid",
  DENY_PARTIAL_PERMIT_DENY: "valid",
  IAM_MISMATCH_WARNING: "warning",
  PERMIT_EMPTY_GROUP: "valid",
  DENY_EMPTY_GROUP: "valid",
};

const FILTER_DETAILS_FIELDS = ['id', 'aui'];

const _renderTitle = (title, entityType) => <AutoIntl
  displayId={`au.entity.taps.validation.topology.filterDetails.titles.${entityType ? 'entityDetails' : title}`}
  values={entityType ? {
    entityName: formatMessage({ id: `au.entity.name.${entityType}` })
  } : {}}
/>;

const _mapDetails = (details={}, filterNames=[], linkIdProp=true) => Object.entries(details)
  .filter(([name, value]) => typeof value !== "object" && (filterNames.length ? filterNames.includes(name) : true))
  .map(([name, value]) => ({
    name: upperFirst(name), 
    value: name === 'id' && linkIdProp
      ? (
        <EntityLink
          url={getEntityUrlByAui(details?.aui, details?.feedEntityType?.toLowerCase())}
          entityId={value}
        >{ value }</EntityLink>
      )
      : value
  }));

const _permitSection = data => ({
  title: _renderTitle("permit"),
  details: [{
    name: "",
    value: data?.filter?.type && formatMessage({
      id: `au.taps.filter.types.${data?.filter?.type}`
    })
  }]
});

const _validationStatusSection = data => ({
  title: _renderTitle("validationStatus"),
  details: [
    {
      name: '', 
      value: (
        <HintTooltip
          id={data?.id}
          tooltipTitle={data?.validationStatus}
          tooltipContent={formatMessage({ 
            id: `au.topology.validationStatuses.${data?.validationStatus}.description` 
          })}
        >
          {data?.validationStatus}
        </HintTooltip>
      )
    }
  ]
});

const _tapsForksSection = data => data?.details?.length ? [{
  title: _renderTitle('tapsForks')
}] : [];

const _renderTestSequence = data => ({
  name: formatMessage({ id: 'au.entity.taps.validation.topology.filterDetails.titles.testSequence' }),
  value: data?.filterSequenceNumber
});

const _filterTypeMapper = {
  attribute: (data) => [
    {
      title: formatMessage({ id: 'au.taps.validation.filterDetails' }),
      details: [{
        name: data?.filter?.attributeFilter?.name,
        value: data?.filter?.attributeFilter?.value,
      }]
    },
    _permitSection(data),
    ..._tapsForksSection(data)
  ],
  attributeTag: (data) => [
    {
      title: _renderTitle("filterDetails"),
      details: [{
        name: data?.filter?.attributeTagFilter?.tagKey,
        value: data?.filter?.attributeTagFilter?.tagValue,
      }]
    },
    _permitSection(data),
    ..._tapsForksSection(data)
  ],
  device: (data) => [
    {
      title: _renderTitle("filterDetails"),
      details: _mapDetails(data?.filter?.deviceFilter)
    },
    _permitSection(data),
    ..._tapsForksSection(data)
  ],
  attribute_tag: (data) => [
    {
      title: "Filter Defails",
      details: [{
        name: data?.filter.attributeTagFilter.tagKey,
        value: data?.filter.attributeTagFilter.tagValue
      }]
    }
  ],
  group: (data) => [
    {
      title: _renderTitle("filterDetails"),
      details: _mapDetails(data?.filter?.groupFilter)
    },
    _permitSection(data),
    ..._tapsForksSection(data)
  ],
  signalPassThrough: (data) => [
    {
      title: _renderTitle("filterDetails"),
      details: _mapDetails(data?.filter?.signalPassThroughFilter)
    },
    _permitSection(data),
    ..._tapsForksSection(data)
  ],
  memberPassThrough: (data) => [
    {
      title: _renderTitle("filterDetails"),
      details: _mapDetails(data?.filter?.memberPassThroughFilter)
    },
    _permitSection(data),
    ..._tapsForksSection(data)
  ],
  iam: (data) => [
    {
      title: _renderTitle("filterDetails"),
      details: _mapDetails(data?.filter?.iamFilter)
    },
    _permitSection(data),
    ..._tapsForksSection(data)
  ],
  iamDetails: (data) => [
    {
      title: _renderTitle("filterDetails", data?.feedEntityType?.toLowerCase()),
      details: _mapDetails(data, FILTER_DETAILS_FIELDS)
    },
    {
      title: _renderTitle("filterDetails"),
      details: [
        _renderTestSequence(data)
      ]
    },
    _validationStatusSection(data)
  ],
  membershipTag: (data) => [
    {
      title: _renderTitle("filterDetails"),
      details: [{
        name: data?.filter?.membershipTagFilter?.tagKey,
        value: data?.filter?.membershipTagFilter?.tagValue,
      }]
    },
    _permitSection(data),
    ..._tapsForksSection(data)
  ],
  vin: (data) => [
    {
      title: _renderTitle("filterDetails"),
      details: [{
        name: formatMessage({ id: 'au.entity.taps.validation.topology.filterDetails.titles.vins' }),
        value: <FancyKeyValue limit={5} hideKey={true} value={{ '': data?.filter?.vinFilter?.vins }} />
      }]
    },
    _permitSection(data),
    ..._tapsForksSection(data),
  ],
  defaultDetails: (data) => {
    let sections = [_validationStatusSection(data)];

    sections.unshift({
      title: _renderTitle("filterDetails"),
      details: [
        _renderTestSequence(data),
        ...(data?.vinInfo ? [{
          name: formatMessage({ id: 'au.entity.taps.validation.topology.filterDetails.titles.vin' }),
          value: <FancyKeyValue limit={4} value={data?.vinInfo} />
        }] : [])
      ]
    });

    if (data?.feedEntityType === 'FORK_OUTPUT') {
      sections.unshift({
        title: _renderTitle("filterDetails", 'fork_output'),
        details: _mapDetails({ ...data.output, id: data.id }, FILTER_DETAILS_FIELDS, false)
      });
      sections.unshift({
        title: _renderTitle("filterDetails", 'fork'),
        details: _mapDetails(data.fork, FILTER_DETAILS_FIELDS)
      });
    }
    else {
      sections.unshift({
        title: _renderTitle("filterDetails", data?.feedEntityType?.toLowerCase()),
        details: _mapDetails(data, FILTER_DETAILS_FIELDS)
      });
    }

    return sections;
  }
};

const _headersOptions = {
  sequence: { width: 54 },
  filterType: { width: '' },
  validity: { width: 150 },
  name: { width: '' },
  validationStatus: { width: 200 }
};

const _headerMapper = {
  sequence: (data) => data?.filter?.sequence,
  filterType: (data) => data?.filterType ? tapFilterType[data.filterType] : '',
  validity: (data) => (data?.state && <TapValidationIcon
    icon={data.state && data.state.toLowerCase()}
    displayId={`au.entity.taps.validation.topology.filterState.${data.state}`}
  />),
  name: (data) => data?.feedEntityType === 'FORK_OUTPUT' ? data?.fork?.name : data?.name || data?.id,
  validationStatus: (data) => (<TapValidationIcon
    icon={data.validationStatus && _validationStatusMapper[data.validationStatus] || 'unknown'}
    displayId={`au.entity.taps.validation.topology.filterDetails.headers.validationStatus.${
      data.validationStatus && _validationStatusMapper[data.validationStatus] || 'unknown'
    }`}
  />),
  _identity: () => ""
};

const formatSections = (data, filterType, level) => {
  const mapper = filterTypeMapper[`${filterType.toLowerCase()}_${level}`];
  return mapper ? mapper(data) : null;
};

function formatHeaderData(item, headers) {
  return Object.entries(headers).reduce(
    (total, [header]) => ({
      ...total, 
      [header]: ( _headerMapper[header] ?? _headerMapper['_identity'] )(item)
    }),
    {}
  );
}

export function formatHeaders(headers, prefix) {
  return Object.entries(headers).map(([header, props]) => {
    return {
      property: header, 
      labelId: `${prefix}.${header}`,
      options: { ...props, ...(_headersOptions[header] ?? {}) }
    };
  });
}

export const filterTypeMapper = {
  attribute_0: _filterTypeMapper.attribute,
  attribute_tag_0: _filterTypeMapper.attributeTag,
  device_0: _filterTypeMapper.device,
  group_0: _filterTypeMapper.group,
  iam_0: _filterTypeMapper.iam,
  membership_tag_0: _filterTypeMapper.membershipTag,
  member_pass_through_0: _filterTypeMapper.memberPassThrough,
  signal_pass_through_0: _filterTypeMapper.signalPassThrough,
  vin_0: _filterTypeMapper.vin,
  attribute_1: _filterTypeMapper.defaultDetails,
  attribute_tag_1: _filterTypeMapper.defaultDetails,
  device_1: _filterTypeMapper.defaultDetails,
  group_1: _filterTypeMapper.defaultDetails,
  iam_1: _filterTypeMapper.iamDetails,
  membership_tag_1: _filterTypeMapper.defaultDetails,
  member_pass_through_1: _filterTypeMapper.defaultDetails,
  signal_pass_through_1: _filterTypeMapper.defaultDetails,
  vin_1: _filterTypeMapper.defaultDetails
};


export const formatDetails = (data, [currentLevelHeaders, ...nextLevelHeaders], level = 0) => data?.map((item) => ({
  rawData: item,
  headerData: currentLevelHeaders ? formatHeaderData(item, currentLevelHeaders) : undefined,
  sections: formatSections(item, item.filterType, level),
  details: item.details ? formatDetails(item.details, nextLevelHeaders || [currentLevelHeaders], level + 1) : undefined
}));
