import React from "react";
import { SelectableListProps } from "../../AuPropTypes";
import { commandDeviceType as deviceTypeList } from "../../constants";
import FilterSelectableList from "./FilterSelectableList";

const deviceTypes = Object.values(deviceTypeList).map(value => ({displayString: value, value}));

export default class CommandDeviceType extends React.Component {
  static propTypes = SelectableListProps;

  static defaultProps = {
    selection: []
  };

  handleChange = this.handleChange.bind(this);
  handleChange(data) {
    this.props.onChange(data);
  }

  render() {
    return (<FilterSelectableList
      {...this.props}
      items={deviceTypes}
      selection={this.props.selection}
      onChange={this.handleChange}
      onInit={this.props.onInit}
    />);
  }
}
