import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import AuButton, { BUTTON_TYPE_PLAIN } from '@au/core/lib/components/elements/AuButton';
import CollapsiblePanel from '@au/core/lib/components/elements/CollapsiblePanel';

import { STATEMENT_HISTORY_ENDPOINT_URI } from '../../../constants';
import DefaultView from './StatementView';
import SidebarSubviews from "../../SidebarSubview";
import { StatementTable } from '../Statements';
import { formatMessage } from '../../../utils/reactIntl';
import auFormatters from '../../../utils/formatters';
import { get } from '../../../utils/api';
import Banner from "../../Banner";

import styles from '../../../css/components/entity_policy_history.module.scss';

export class StatementDiffTable extends React.Component {
  static propTypes = {
    statement: T.object.isRequired,
    prevStatement: T.object.isRequired,
    actor: T.object.isRequired,
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { statement, prevStatement, actor } = this.props;

    const authorAui = actor.username ? `aui:iam:${actor.username}` : '';
    const entitlements = statement.entitlements.map(entitlement =>
      <div key={`entitlement_${entitlement}`}>{ entitlement }</div>
    );
    const prevEntitlements = prevStatement.entitlements.map(entitlement =>
      <div key={`prev_entitlement_${entitlement}`}>{ entitlement }</div>
    );

    return (
      <table className={styles.table}>
        <thead>
          <tr className={styles.row}>
            <td className={styles.headername}>
              { formatMessage({ id: 'au.policies.history.changes' })}
            </td>
            <td className={styles.headername}>
              { formatMessage({ id: 'au.policies.history.previous' })}
            </td>
            <td className={styles.headername}>
              { formatMessage({ id: 'au.policies.history.update' })}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.row}>
            <td className={styles.fieldname}>
              { formatMessage({ id: 'au.entity.attr.entitlements' })}
            </td>
            <td className={cn(styles.fieldvalue, styles.statements)}>
              { prevEntitlements }
            </td>
            <td className={cn(styles.fieldvalue, styles.statements)}>
              { entitlements }
            </td>
          </tr>
          <tr>
            <td className={styles.fieldname}>
              { formatMessage({ id: 'au.entity.attr.condition' })}
            </td>
            <td className={cn(styles.fieldvalue, styles.condition)}>
              { prevStatement.condition }
            </td>
            <td className={cn(styles.fieldvalue, styles.condition)}>
              { statement.condition }
            </td>
          </tr>
          <tr>
            <td className={styles.fieldname}>
              { formatMessage({ id: 'au.policies.author' })}
            </td>
            <td className={cn(styles.fieldvalue, styles.statements)}>
              { '' }
            </td>
            <td className={cn(styles.fieldvalue, styles.statements)}>
              { authorAui }
            </td>
          </tr>
          <tr>
            <td className={styles.fieldname}>
              { formatMessage({ id: 'au.entity.attr.description' })}
            </td>
            <td className={cn(styles.fieldvalue, styles.statements)}>
              { prevStatement.description }
            </td>
            <td className={cn(styles.fieldvalue, styles.statements)}>
              { statement.description }
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default class StatementHistory extends DefaultView {

  loadData = this.loadData.bind(this);
  async loadData() {
    await super.loadData();
    const { entity } = this.props;

    //NOTE: initializing state here, because defining state in this class overrides parent class states
    this.setState({ expanded: true });

    if (entity.size) {
      const resourceAui = encodeURIComponent(entity.get('resourceAui'));
      const subjectAui = encodeURIComponent(entity.get('subjectAui'));
      const effect = entity.get('effect');
      
      const resp = await get(`${STATEMENT_HISTORY_ENDPOINT_URI}?resourceAui=${resourceAui}&subjectAui=${subjectAui}&effect=${effect}`)
        .catch(r => r);
      if (resp?.data?.items) {
        this.setState({historyItems: resp.data.items});
        this.setState({ found: true });
      }
    } else {
      this.setState({ found: false });
    }
  }

  setRef(name, el) {
    if (el) {
      this[name] = el;
    }
  }

  onExpandAllClick = this.onExpandAllClick.bind(this);
  onExpandAllClick() {
    this.setState({ expanded: true });
  }

  onCollapseAllClick = this.onCollapseAllClick.bind(this);
  onCollapseAllClick() {
    this.setState({ expanded: false });
  }

  renderContent() {
    const { expanded, historyItems } = this.state;
    const { entity, screenWidth } = this.props;
    const isMobile = screenWidth !== "desktop";

    return (
      <div className={styles.content_flex}>
        <SidebarSubviews
          navLinks={this.getNavLinks()}
          portalRef={this._leftSidebarRef}
          open={this.state.subviewSidebarOpen}
          setOpen={this.setSubviewSidebar}
          isMobile={isMobile}
        />
        <div className={styles.content} ref={ref => this.containerRef = ref}>
          <Banner type='info-circle' displayId='au.policies.retentionMessage' className={styles.banner} />
          <div className={cn(styles.section, styles.appliedPolicy)}>
            <CollapsiblePanel
              key={`applied_policy`}
              ref={el => this.setRef('applied_policy', el)}
              displayId={`au.policies.history.appliedPolicyDetails`}
              className={styles.panel}
              collapsedClassName={styles.collapsed}
            >
              <StatementTable
                statement={entity}
                showResourseAui={true}
                showSubjectAui={true}
              />
            </CollapsiblePanel>
          </div>
          <div className={styles.section}>
            <div className={styles.history}>
              <div className={styles.title}>
                {formatMessage({ id: 'au.policies.history.title' })}
              </div>
              {Boolean(historyItems?.length) && (
                <div className={styles.actions}>
                  <AuButton
                    type={BUTTON_TYPE_PLAIN}
                    className={styles.action}
                    displayId="au.entity.taps.validation.collapseAll"
                    onClick={this.onCollapseAllClick}
                  />
                  <div className={styles.pipe} />
                  <AuButton
                    type={BUTTON_TYPE_PLAIN}
                    className={styles.action}
                    displayId="au.entity.taps.validation.expandAll"
                    onClick={this.onExpandAllClick}
                  />
                </div>
              )}
            </div>
            <div className={styles.items}>
              {historyItems?.length ? historyItems.map((item) => (
                <div key={item.id} className={styles.statement}>
                  <CollapsiblePanel
                    key={item.id}
                    ref={el => this.setRef(item.id, el)}
                    displayString={auFormatters.date({ value: item.timestamp }) || ''}
                    className={styles.panel}
                    collapsedClassName={styles.collapsed}
                    expanded={expanded}
                  >
                    <StatementDiffTable statement={item.event?.statement} prevStatement={item.event?.previousStatement} actor={item?.actor} />
                  </CollapsiblePanel>
                </div>
              ))
                : <div className={styles.noitems}>{formatMessage({ id: 'au.policies.history.noItems' })}</div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

}
