import { redirectOnSaveSuccess } from "../../../utils/linkHelper";
import DefaultEdit from '../Edit';

export default class ClientEdit extends DefaultEdit {

  getEntityProcessors() {
    const processors = super.getEntityProcessors();
    processors.push((entity) => {

      if (!entity.tags) {
        entity.tags = {};
      }
      if (entity.region) {
        entity.tags.Region = entity.region;
      }
      if (entity.businessUnit) {
        entity.tags.BusinessUnit = entity.businessUnit;
      }
      delete entity.region;
      delete entity.businessUnit;
      return entity;
    });
    return processors;
  }

  getEntitiesToCreate() {
    const { entityDef } = this.props;
    const { modifiedEntity } = this.state;
    const editedClient = {
      id: modifiedEntity.id,
      displayName: modifiedEntity.displayName,
      inputFlowId: modifiedEntity.inputFlowId,
      businessUnit: modifiedEntity.businessUnit,
      region: modifiedEntity.region,
      description: modifiedEntity.description,
      tags: modifiedEntity.tags,
      email: modifiedEntity.email,
      enabled: modifiedEntity.enabled
    }
    let entitiesToCreate = super.getEntitiesToCreate();

    //All of our client edits
    entitiesToCreate.set(`accounts-service-updateTap`,
      {
        entities: [{
          key: `accounts-service-updateClient`,
          idProp: entityDef.pkField,
          data: { ...editedClient },
          saveFn: (data) => {
            const modifiedData = { ...data };
            return this.updateClientEntity(modifiedData);
          },
          halt: true,
          displayFields: ['displayName', 'id']
        }],
        serviceAlias: 'accounts-service',
        entityType: 'client',
        entityAlias: 'client',
        verbRoot: "edit",
      });

    return entitiesToCreate;
  }
  updateClientEntity = this.updateClientEntity.bind(this)
  updateClientEntity(entity) {
    const { endpoint } = this.props;
    const editedFields = {};
    const newTags = this.state.entity.get('tags').toJS();
    let tags = entity.tags;

    Object.keys(newTags).forEach(tag => {
      if (!Object.keys(entity.tags).includes(tag)) {
        tags[tag] = null;
      }
    })
    editedFields.displayName = entity.displayName;
    editedFields.region = entity.region;
    editedFields.businessUnit = entity.businessUnit;
    editedFields.tags = tags;
    editedFields.description = entity.description;
    editedFields.email = entity.email;
    editedFields.enabled = entity.enabled
    return endpoint.patch(entity.id, editedFields)
  }

  onStatusDialogClose = this.onStatusDialogClose.bind(this)
  onStatusDialogClose(edited, statuses) {
    if (edited) {
      const { serviceAlias, match } = this.props;
      const status = statuses.get(`${serviceAlias}-updateClient`);
      redirectOnSaveSuccess(status.resp, this.props.endpoint, match.url);
    }
    else {
      super.onStatusDialogClose();
    }
  }

  handleOnSave = this.handleOnSave.bind(this);
  handleOnSave(modifiedEntity) {
    this.setState({
      showStatusDialog: true,
      modifiedEntity
    });
    return Promise.resolve();
  }
}