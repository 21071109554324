import DefaultView from "../View";
export default class RawMessageView extends DefaultView {

    loadData = this.loadData.bind(this);
    loadData() {
        const { endpoint, parentEntity } = this.props;
        const messagesEndpoint = endpoint.getMessagesEndpoint(parentEntity.entity.getIn(["properties", "vin"]));

        return messagesEndpoint.get(this.entityId, this.queryParams).then(this.onAfterFetch, this.genericErrorHandler);
    }
}