import { Map as imMap } from 'immutable';

import TMC from '@autonomic/browser-sdk';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import apiFactory from '../../../utils/api';
import { enhanceSdkEndpoint } from '../../../utils/api';
import { redirectOnSaveSuccess } from '../../../utils/linkHelper';
import { formatMessage } from '../../../utils/reactIntl';
import DefaultCreate from '../Edit';
export default class ProcessorCreate extends DefaultCreate {

  entityProcessors = [
    data => {
      // Need to convert flows to their resourceAui as API expects ONLY that
      return {
        ...data,
        inputFlow: `aui:flow:feed/${data.inputFlow}`,
        outputFlow: `aui:flow:feed/${Object.values(data.outputFlowCreate)[0].aui}`
      };
    }
  ]

  getEntityProcessors = this.getEntityProcessors.bind(this);
  getEntityProcessors() {
    const processors = super.getEntityProcessors();
    return [...processors, ...this.entityProcessors];
  }

  getEntitiesToCreate() {
    const { serviceAlias, entityDef, resources, match, actions } = this.props;
    const { modifiedEntity } = this.state;
    const { entityAlias } = match.params;

    this.endpoint = enhanceSdkEndpoint(
      new TMC.services.Feed(),
      'flows',
      actions
    );

    let modifiedEntityCopy;

    const entitiesToCreate = new Map(super.getEntitiesToCreate());

    // add flow(s)
    for (let [attr, { inlineCreate, source }] of Object.entries(entityDef.attributes)) {
      if (inlineCreate && source) {
        const { service: serviceAlias, entity: entityAlias, textProperty } = source;
        const resource = resources.get(`${serviceAlias}-${entityAlias}`, imMap());
        if (resource.size && Object.values(modifiedEntity.outputFlowCreate)[0].displayName && !Object.values(modifiedEntity.outputFlowCreate)[0].foundSelection) {
          const groupKey = `${serviceAlias}-${entityAlias}`;
          const group = entitiesToCreate.get(groupKey) ?? { ...inlineCreate, entities: [] };
          const endpoint = apiFactory(serviceAlias, entityAlias, actions);

          group.entities.push({
            /* key is needed to differentiate between entities */
            key: `${groupKey}-${attr}`,
            idProp: endpoint.idProp,
            data: {
              [textProperty]: Object.values(modifiedEntity.outputFlowCreate)[0].displayName,
              shardCount: Object.values(modifiedEntity.outputFlowCreate)[0].shardCount
            },
            saveFn: data => {
              return endpoint.create(data).then(resp => {
                modifiedEntityCopy.outputFlow =  `aui:flow:feed/${resp.data[endpoint.idProp]}`;
                return resp;
              });
            },
            halt: true,
            displayFields: ['displayName', 'id']
          });

          entitiesToCreate.set(groupKey, group);
        }
      }
    }

    if (modifiedEntity.outputFlowCreate) {
      modifiedEntityCopy = Object.assign({}, modifiedEntity);
      delete modifiedEntityCopy.outputFlowCreate;
    }

    // add our main entity
    entitiesToCreate.set(
      `${serviceAlias}-${entityAlias}`,
      {
        entityType: entityDef.type,
        entities: [{
          key: `${serviceAlias}-${entityAlias}-new`,
          idProp: entityDef.pkField,
          data: {...modifiedEntityCopy},
          saveFn: (data) => {
            const modifiedData = { ...data, ...modifiedEntityCopy };
            // update groups
            return this._saveEntity(
              modifiedData,
              resp => resp,
              error => Promise.reject(error)
            );
          },
          halt: true,
          displayFields: ['displayName', 'id']
        }],
        serviceAlias,
        entityAlias
      }
    );
    return entitiesToCreate;
  }

  onStatusDialogClose = this.onStatusDialogClose.bind(this)
  onStatusDialogClose(created, statuses) {
    if (created) {
      const { serviceAlias, match } = this.props;
      const { entityAlias } = match.params;
      const status = statuses.get(`${serviceAlias}-${entityAlias}-new`);
      redirectOnSaveSuccess(status.resp, this.props.endpoint, match.url);
    }
    else {
      this.getEntitiesToCreate();
      sessionStorage.setItem('created', created)
      this.endpoint.list().then(resp => resp.getItems(Infinity).then(items => {
        const flowOptions = items.map((flow) => ({
          val: flow.id,
          displayString: flow.displayName
        }));
        sessionStorage.setItem('updatedFlows', JSON.stringify(flowOptions));
      }));
      this.setState({ showStatusDialog: false })
    }
  }

  genericErrorHandler = this.genericErrorHandler.bind(this);
  genericErrorHandler(error) {
    let titleId = 'au.errors.400.title';
    let titleString = '';
    let messageId = 'au.error.generic';
    let messageString = '';

    /**
     * Common error case assumes response structure is { data: { error: 'error title', message: 'error message' }}
     * In the case of permissions issues on flows, we get back an error object instead
     * ex: {"error":{"code":403,"message":"No permission for input flow","status":"Forbidden"}}
     */
    if (error.data) {
      const { data } = error;
      if (data.error && typeof data.error === 'object') {
        titleId = data.error.code && `au.errors.${data.error.code}.title`;
      } else if (data.error && typeof data.error === 'string') {
        titleId = undefined;
        titleString = data.error;
      }

      if (data.message || (data.error && data.error.message)) {
        // `messageId` takes priority over `messageString` so mark undefined if we have a message
        messageId = undefined;
        messageString = data.message || data.error.message;
      }
    }

    // Pass error off to genericErrorHandler util as it expects
    createResponseAlertMessage({
      data: {
        error: titleId ? formatMessage({ id: titleId }) : titleString,
        message: messageId ? formatMessage({ id: messageId }) : messageString
      }
    });
  }

  disableSaveBtn = this.disableSaveBtn.bind(this);
  disableSaveBtn(entity) {
    const attributes = this.props.entityDef.attributes;
    let requiredFields = [];
    let requiredEntityFieldsObject = {};
    let hasOutputFlow;
    const nameRule = RegExp("^[a-z0-9][a-z0-9\\-\\/]*[a-z0-9]$").test(entity.displayName);

    Object.entries(attributes).forEach(attribute => {
      if (attribute[1].rules && attribute[1].rules.includes('required') && attribute[1].display?.create !== false) {
        requiredFields.push(attribute[0])
        requiredEntityFieldsObject[attribute[0]] = entity[attribute[0]];
      }
    });

    hasOutputFlow = requiredEntityFieldsObject?.outputFlowCreate && requiredEntityFieldsObject?.outputFlowCreate[0].aui !== undefined && requiredEntityFieldsObject?.outputFlowCreate[0].aui.length !== 0;

    return Object.values(requiredEntityFieldsObject).includes(undefined) ||
      Object.values(requiredEntityFieldsObject).includes('') ||
      !hasOutputFlow ||
      !nameRule
  }

  handleOnSave = this.handleOnSave.bind(this);
  handleOnSave(modifiedEntity, doSaveFn) {
    this._saveEntity = doSaveFn;

    this.setState({
      showStatusDialog: true,
      modifiedEntity
    });

    return Promise.resolve();
  }

}
