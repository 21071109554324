import {history} from '../history';
import {environmentQueryParams} from '../constants';

export function getPartition() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(environmentQueryParams.partition);
}

export function setPartition(partitionKey) {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set(environmentQueryParams.partition, partitionKey);
  const search = urlParams.toString();
  history.replace({ search });
}

export function removePartition() {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete(environmentQueryParams.partition);
  const search = urlParams.toString();
  history.replace({ search });
}

export function generateUrl(domain, path = "", queryParams = []) {
  const url = new URL(path, domain);
  url.search = new URLSearchParams(queryParams).toString();
  return url.href;
}

export function redirectOnSaveSuccess(response, endpoint, successDestination) {
  const prevUrl = window.location.pathname;

  if (response.data && response.data[endpoint.idProp]) {
    // If we know new entityId - redirect to entity details page
    const urlChunks = successDestination.split('/');
    const action    = urlChunks.pop(); // remove last element aka `:action`

    // Create, List, Replicate pages don't contain UUID in their path, so we need to
    // add one. All the other pages *will have* UUID already in their paths.
    if (['create', 'list', 'replicate'].includes(action)) {
      urlChunks.push(response.data[endpoint.idProp], 'view');
    } else {
      urlChunks.push('view');
    }

    history.replace(`${urlChunks.join('/')}`, { prevUrl });
  } else {
    history.replace(successDestination, { prevUrl });
  }
}
