
export function guaranteeUndefinedOrArray(data) {
  if (data === null || data === undefined) {
    return undefined;
  }
  if (typeof data === 'string') {
    return [data];
  }
  if (!Array.isArray(data)) {
    return Object.values(data);
  }
  return data;
}
