import React from 'react';

import MultiActionButton from '@au/core/lib/components/elements/MultiActionButton';

import { history as browserHistory } from '../../../history';
import { generateEntityPath } from '../../../utils/entity';
import DefaultList from '../List';

import styles from '../../../css/components/entity_list.module.scss';

export default class MemberList extends DefaultList {

  membersActions = [
    { displayId: 'au.group.addMembers', onClick: this.navigateTo.bind(this, 'add') },
    { displayId: 'au.group.removeMembers', onClick: this.navigateTo.bind(this, 'remove') }
  ]

  navigateTo(path) {
    //FIXME - MOVE TO linkHelper
    browserHistory.push({
      pathname: this.baseUrl + '/' + path,
      state: { prevUrl: this.props.match.url }
    });
  }

  // NOTE: endpoint is not enhanced
  onListSuccess = this.onListSuccess.bind(this);
  onListSuccess(resp) {
    const { endpoint, serviceAlias, actions } = this.props;

    // members.list endpoint wraps all the entries with an additional object,
    // using entity type as a key. Here, we're moving the type inside the item:
    // { items: [{ _TYPE_: { _ITEM_ } }] }
    // TODO: move this to common file to share with other group components
    actions.listEntitiesSuccess({
      path: generateEntityPath(endpoint, serviceAlias),
      data: resp.items.map(entry => {
        const [kind, obj] = Object.entries(entry)[0];
        obj.kind = kind;
        return obj;
      }),
      pkField: 'id',
      replace: resp.isFirstPage
    });
  }

  getAddons() {
    const { screenWidth } = this.props;
    let addons = super.getAddons();
    addons.push(
      <div className={styles.buttons} key="members-actions">
        <MultiActionButton
          actions={this.membersActions}
          screenWidth={screenWidth}
          className={styles.actions_btn}
        />
      </div>
    );

    return addons;
  }

  getRowActions() {
    return [];
  }
}
