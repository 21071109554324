import React from "react";
import { SelectableListProps } from "../../AuPropTypes";
import { commandDefinitionStatus as commandDefinitionStatusList } from "../../constants";
import FilterSelectableList from "./FilterSelectableList";

const commandDefinitionStatuses = Object.values(commandDefinitionStatusList).map(type => ({ displayId: `au.customcommands.status.${type}`, value: type }));

export default class CustomCommandStatus extends React.Component {
  static propTypes = SelectableListProps;

  static defaultProps = {
    selection: []
  };

  render() {
    return (<FilterSelectableList
      {...this.props}
      items={commandDefinitionStatuses}
      selection={this.props.selection}
      onChange={this.props.onChange}
      onInit={this.props.onInit}
    />);
  }
}