import React from 'react';
import * as T from 'prop-types';
import { map, chunk } from 'lodash';
import cn from 'classnames';

import styles from '../css/components/au_stat_grid.module.scss';

export default class AuStatGrid extends React.PureComponent {
  static propTypes = {
    className: T.string,
    numColumns: T.number,
    type: T.oneOf(['flex', 'table'])
  }

  static defaultProps = {
    className: '',
    numColumns: 3,
    type: 'flex'
  }

  render() {
    const { className, numColumns, type, children } = this.props;
    let rows = chunk(children, numColumns);

    return (
      <div className={cn(className, styles[type])}>
        {map(rows, (row, i) => <div key={i} className={styles.row}>
          {map(row, (stat) => stat)}
        </div>)}
      </div>
    );
  }
}
