import { validEmail, validUuid } from '../../../utils/validationRules';
import { generateEntityPath } from '../../../utils/entity';
import shared from '../../../shared';
import DefaultList from '../List';

export function disableSelfDelete(permissions, entityDef, entity) {
  const permissionsCopy = {...permissions};
  if (!entityDef.readonly && permissions.canDelete && shared.authClient._userInfo.preferred_username === entity.get('username')) {
    permissionsCopy.canDelete = false;
  }
  return permissionsCopy;
}
export default class UserList extends DefaultList {

  filterOptions = ['id', 'username', 'email', 'fullName'];

  async list() {
    const { searchText } = this.state;
    if (searchText) {
      const searchResponses = await Promise.all(this.getSearchPromises());

      // Since UUID and email can only match exactly we will assume there will
      // not be multiple pages of users with the same email. We will merge the
      // first pages together into the last wildcard response, which can have
      // multiple pages.

      // ugly hack:
      const items = new Set(searchResponses.map(r => r.items).flat());
      const wildcardResp = searchResponses.pop();
      wildcardResp._data = { ...wildcardResp._data, items: [...items] };
      wildcardResp._itemsMap = undefined; // reset getter
      return wildcardResp;
    }
    else {
      return super.list();
    }
  }

  onSearchTextChange = this.onSearchTextChange.bind(this);
  async onSearchTextChange(searchText) {
    const changed = await super.onSearchTextChange(searchText);
    if (changed) {
      this.resetPages();
      this.fetchInitialPages();
    }
  }

  getSearchPromises() {
    const { endpoint } = this.props;
    const { searchText } = this.state;
    // Filter out `id` if searchText is not a UUID
    // the server throws an error in that case
    // TODO: Do not use a regex here if we can help it
    return this.filterOptions
      .filter(param => {
        if (param === 'id' && validUuid(searchText) !== null) {
          return false;
        }
        if (param === 'email' && validEmail(searchText) !== null) {
          return false;
        }

        return true;
      })
      .map(param => {
        // Only `username`, 'fullName' supports `like`
        const query = (param === 'username' || param === 'fullName')
          ? `${param} like '*${searchText}*'`
          : `${param}='${searchText}'`;

        return endpoint.list({ query });
      });
  }

  // NOTE: endpoint is not enhanced
  onListSuccess = this.onListSuccess.bind(this);
  onListSuccess(resp) {
    const { actions, serviceAlias, entityDef, endpoint } = this.props;

    let path = generateEntityPath(endpoint, serviceAlias);
    actions.listEntitiesSuccess({
      path,
      data: resp.items,
      pkField: entityDef.pkField,
      replace: resp.isFirstPage
    });
  }

  getRowActions({id, canWrite, permissions, ...otherProps}) {
    const { entityDef } = this.props;
    const entity = this.props.entities.get(decodeURIComponent(id));
    if(!entity){
      return super.getRowActions({id, canWrite, permissions, ...otherProps});
    }

    const permissionsCopy = disableSelfDelete(permissions, entityDef, entity);
    return super.getRowActions({id, canWrite, permissions: permissionsCopy, ...otherProps});
  }
}
