import React from 'react';
import * as T from 'prop-types';
import debounce from 'lodash/debounce';

import TMC from '@autonomic/browser-sdk';
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import AuDropDown from '@au/core/lib/components/elements/AuDropDown';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import styles from '../../css/components/filter_authorization_action.module.scss';

export default class AuthorizationEntitlement extends React.Component {

  static propTypes = {
    selection: T.arrayOf(T.object),
    defaultValue: T.string,
    onChange: T.func,
    onInit: T.func
  }

  static defaultProps = {
    selection: []
  }
  state = { authzEntitlement: [], counter: 0 }
  iamServiceV2 = new TMC.services.Iam({ apiVersion: 2 });

  componentDidMount() {
    this.loadActions(this.props.resourceAui);
    this.props.onInit(this.props.selection);
  }

  loadActions(resourceAui) {
    // default suggest all actions
    resourceAui = resourceAui ? resourceAui : 'aui:*:*/*';

    this.iamServiceV2.roles.suggest({ resourceAui }).then(resp => {
      let entitlements = (resp.data.actions || []).map(({ name }) => ({
        displayString: name,
        val: name
      }));
      resp.data.roles.forEach(( role ) => (entitlements.push({
        displayString: role.name,
        val: role.aui
      })));
      entitlements.sort();
      this.setState({ authzEntitlement: [
          ...entitlements
        ]});
    }, createResponseAlertMessage);
  }

  handleOptionSelect = debounce(this.handleOptionSelect, 150).bind(this);
  handleOptionSelect(option) {
    if (!option || !this.state.authzEntitlement.find(o => o.val === option)) {
      return;
    }

    this.setState(prevState => ({ counter: prevState.counter + 1 }));
    const selectedItems = [...this.props.selection, {urlValue: option}];
    this.props.onChange(selectedItems);
  }

  render() {
    const { authzEntitlement, counter } = this.state;
    return (
      <div className={styles.container}>
        <AutoIntl className={styles.value_text} displayId="au.filters.conditionAnd"/>
        <AuDropDown
          key={`authz_entitlement_${counter}`}
          options={authzEntitlement}
          selectOption={this.handleOptionSelect}
          createMode={false}
          placeholderId="au.auditActionSelector.placeholder.auditAction"
          allowTyping={this.props.allowTyping}
        />
      </div>
    );
  }
}