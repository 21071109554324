import { persistState } from '@au/core/lib/utils/stateDelegators';

export default {
  SHOW_ACCOUNT_DIALOG: (state) => {
    return state.set('showAccountDialog', true);
  },
  HIDE_ACCOUNT_DIALOG: (state) => {
    return state.set('showAccountDialog', false);
  },
  SET_ACCOUNT_ID: (state, action) => {
    state = persistState(state)
      .call('setIn', ['settings', 'partitions', state.getIn(['settings', 'partition']), 'accountId'], action.accountId)
      .state;
    return state;
  }

};
