import React from 'react';
import * as T from 'prop-types';
import moment from 'moment-timezone';
import cn from 'classnames';

import MiniDropDown from '@au/core/lib/components/elements/MiniDropDown';

import { NOOP } from '../constants';

import styles from '../css/components/timezone_selector.module.scss';

const LOCAL_TIMEZONE = moment.tz.guess();
const UTC_TIMEZONE   = 'UTC';

export default class TimezoneSelector extends React.PureComponent {

  sessionTimezone = localStorage.getItem('timezone');

  static propTypes = {
    className: T.string,
    onChange: T.func
  }

  static defaultProps = {
    onChange: NOOP
  }

  state = {
    selection: this.sessionTimezone === 'UTC' ? 'utc' : 'local'
  }

  componentDidMount() {
    this.props.onChange(this.sessionTimezone !== 'undefined' ? this.sessionTimezone : this.props.timezone);
  }

  onTimeZoneChange = this.onTimeZoneChange.bind(this);
  onTimeZoneChange(selection, timezone) {
    this.setState({ selection });
    this.props.onChange(timezone);
    localStorage.setItem('timezone', timezone);
  }

  render() {
    const { className } = this.props;
    const { selection } = this.state;
    const utcSelection = selection === 'utc';

    return (
      <div className={cn(styles.container, className)}>
        <MiniDropDown
          type="clock"
          align="left"
          displayId={`au.timezone.${selection}`}
          options={[
            {
              displayId: selection === 'utc' ? 'au.timezone.local' : 'au.timezone.utc',
              onClick: () => this.onTimeZoneChange(selection === 'utc' ? 'local' : 'utc', utcSelection ? LOCAL_TIMEZONE : UTC_TIMEZONE)
            }
          ]}
        />
      </div>
    );
  }

}
