import React from 'react';
import * as T from 'prop-types';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import styles from '../css/components/last_ftcp_message_state.module.scss';

export default class FtcpMessageState extends React.PureComponent {
  static propTypes = {
    result: T.string.isRequired
  }

  render() {
    const { result } = this.props;

    return (
      <AutoIntl
        displayId={`au.entity.state.${result.toUpperCase()}`}
        className={result === 'success' ? styles.success : styles.failure}
        tag="div" />
    );
  }
}