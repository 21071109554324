import React from 'react';
import * as T from 'prop-types';
import { NOOP } from '../../constants';
import debounce from 'lodash/debounce';

import TMC from "@autonomic/browser-sdk";
import AuDropDown from "@au/core/lib/components/elements/AuDropDown";
import LoadingIndicator from "@au/core/lib/components/elements/LoadingIndicator";
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';
import { COMMAND_TYPES_TO_HIDE } from '../../constants';
import { SelectableListProps } from '../../AuPropTypes';
import { formatMessage } from '../../utils/reactIntl';

import styles from "../../css/components/filter_segment.module.scss";

export default class CommandType extends React.Component {

  static propTypes = {
    defaultValue: SelectableListProps.defaultValue,
    containerRef: SelectableListProps.containerRef,
    selection: SelectableListProps.selection,
    sort: SelectableListProps.sort,
    multiSelect: T.bool,
    allowTyping: T.bool,
    onChange: T.func,
    onInit: T.func
  };

  static defaultProps = {
    allowTyping: true,
    onChange: NOOP,
    onInit: NOOP,
    selection: []
  };

  state = {commandTypeItems: [], hasCommandTypesLoaded: false};
  metaDataEndpoint = new TMC.services.Command({ apiVersion: '1-beta' }).metadata;
  typeRef = React.createRef();

  componentDidMount() {
    this.metaDataEndpoint.list()
      .then(resp => {
        const commandTypesToHide = Object.values(COMMAND_TYPES_TO_HIDE);
        let commandTypeItems = [];
        for (let item in resp.items) {
          if (commandTypesToHide.includes(resp.items[item].name)) {
            continue;
          }
          else {
            commandTypeItems.push({displayId: `au.commands.types.${ resp.items[item].name }`, val: resp.items[item].name})
          }
        }
        this.setState({
          commandTypeItems: commandTypeItems
        });
      }, createResponseAlertMessage)
      .finally(() => {
        this.setState({hasCommandTypesLoaded: true});
        this.props.onInit(this.props.selection.map(sel => {
          let displayId = `au.commands.types.${ sel.urlValue }`;
          let text = formatMessage({ id: displayId });
          if (displayId !== text) {
            sel.bubbleText = text;
          }
          return sel;
        }));
      });
  }

  getTypeOptions = this.getTypeOptions.bind(this);
  getTypeOptions() {
    const { selection } = this.props;
    const { commandTypeItems } = this.state;
    const urlValues = selection.map(sel => sel.urlValue);

    return commandTypeItems.filter(item => !urlValues.includes(item.val));
  }

  handleOptionSelect = debounce(this.handleOptionSelect, 150).bind(this);
  handleOptionSelect(option) {
    const { selection } = this.props;

    if (option?.trim() && !selection.some(entry => entry.urlValue === option)) {
      let displayId = `au.commands.types.${ option }`;
      let text = formatMessage({ id: displayId });
      this.props.onChange([...selection, {
        urlValue: option,
        bubbleText: text === displayId ? option : text
      }]);
      this.typeRef.current.reset();
      setTimeout(this.typeRef.current.focus);
    }
  }

  render() {
    const typeOptions = this.getTypeOptions();

    return (
      <>
        <LoadingIndicator
          className={ styles.loader }
          displayId="au.commands.typesWait"
          display={!this.state.hasCommandTypesLoaded}
        />
        {typeOptions.length > 0 &&
          <div className={styles.dd_wrapper}>
            <AuDropDown
              ref={this.typeRef}
              className={styles.dropdown}
              options={typeOptions}
              selection={''}
              selectOption={this.handleOptionSelect}
              placeholderId="au.commands.placeholder.type"
              allowTyping={this.props.allowTyping}
              createMode={true}
            />
          </div>
        }
      </>
    );
  }
}
