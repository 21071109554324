import React from 'react';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';

import { formatMessage } from '../utils/reactIntl';
import SimpleTable, { Row, Cell, HeaderCell } from '../components/SimpleTable';

import styles from '../css/components/simple_key_value.module.scss';

export const orderTypes = {
  Ascending: 'ascending',
  Descending: 'descending'
};
const swap = 1;
const keep = -1;

function orderByLocalizedKeys(localePrefix, order) {
  return ([keyA,], [keyB,]) => {
    const localizedKeyA = localePrefix ? formatMessage({id: `${localePrefix}.${keyA}`}) : keyA;
    const localizedKeyB = localePrefix ? formatMessage({id: `${localePrefix}.${keyB}`}) : keyB;

    if (order === orderTypes.Ascending) {
      return localizedKeyA >= localizedKeyB ? swap : keep;
    } else if (order === orderTypes.Descending) {
      return localizedKeyA >= localizedKeyB ? keep : swap;
    }

    return 0;
  };
}

export default class SimpleKeyValue extends React.PureComponent {

  static propTypes = {
    data: IPT.map,
    localePrefix: T.string,
    nameLocaleId: T.string,
    valueLocale: T.string,
    order: T.oneOf([orderTypes.Ascending, orderTypes.Descending])
  }

  createRows = this.createRows.bind(this);
  createRows(displayData){
    const { localePrefix } = this.props;

    return displayData.toJS().map(([key, val]) =>
      <Row key={key} className={styles.row}>
        <Cell className={styles.key}>{localePrefix ? formatMessage({id: `${localePrefix}.${key}`}) : key}</Cell>
        <Cell className={styles.value}>{val}</Cell>
      </Row>
    );
  }

  renderRows = this.renderRows.bind(this);
  renderRows(){
    const { data, localePrefix, order } = this.props;

    if(order){
      const sortedData = data.entrySeq().sort(orderByLocalizedKeys(localePrefix, order));
      return this.createRows(sortedData);
    }

    return this.createRows(data.entrySeq());
  }

  render() {
    const { nameLocaleId, valueLocaleId } = this.props;
    return (
      <div className={styles.container}>
        <SimpleTable className={styles.table}>
          <Row className={styles.header}>
            <HeaderCell className={styles.key}>{formatMessage({ id: nameLocaleId || 'au.entity.name' })}</HeaderCell>
            <HeaderCell className={styles.value}>{formatMessage({ id: valueLocaleId ||'au.entity.value' })}</HeaderCell>
          </Row>
          { this.renderRows() }
        </SimpleTable>
      </div>
    );
  }
}
