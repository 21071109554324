import React from 'react';

import * as inputs from './Inputs';

import fieldset from '../../css/fieldset.module.scss';

import cn from 'classnames';

const inputsArr = Object.values(inputs);

export class SimpleLayout extends React.Component {

  renderChildren() {
    const { children, entity, showErrors, validationErrors, fieldChangeCallback, registerValidation, intl } = this.props;
    const customProps = { intl, entity, showErrors, validationErrors, fieldChangeCallback, registerValidation };

    return React.Children.map(children, child => child && (
      React.cloneElement(
        child,
        inputsArr.some(inputClass => child.type.prototype === inputClass.prototype)
          ? customProps
          : undefined
      )
    ));
  }

  render() {
    return (
      <div className={cn(fieldset.container, this.props?.className)}>
        { this.renderChildren() }
      </div>
    );
  }
}
