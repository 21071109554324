import React from 'react';
import cn from 'classnames';

import TMC from '@autonomic/browser-sdk';

import AuInput from '@au/core/lib/components/elements/AuInput';

import { history as browserHistory } from '../history';
import { trackEvent } from '../utils/analyticsHelpers';
import { SERVICE_NAMES } from "../constants";
import VehicleLookup from './entity/custom/VehicleLookup';

import styles from '../css/components/vin_lookup.module.scss';

export default class VinLookup extends VehicleLookup {

  static propTypes = {
    // no props expected
  }

  endpoint = new TMC.services.Inventory({ apiVersion: '1-beta' }).vehicles;

  entityType = 'vehicle'

  attrName = 'vin'

  attrDisplayId = 'au.vehicles.vehicleVin'

  attrPlaceholderId = 'au.vehicles.lookupVinPlaceholder'

  init() {
    // NOOP - Overriding Lookup, which sets this.parentURL
  }

  componentDidMount() {
    // NOOP - Overriding VehicleLookup, which sets the page title
  }

  lookup() {
    const { isVin, inputStr, isUuid } = this.state;

    if (isVin) {
      return this.endpoint.getByVin({
        vin: inputStr
      });
    } else if (isUuid) {
      return this.endpoint.get(inputStr);
    }
  }

  onSuccess = this.onSuccess.bind(this);
  onSuccess(resp) {
    //FIXME - MOVE TO linkHelper
    browserHistory.push(`/services/${SERVICE_NAMES.INVENTORY}/vehicles/${resp.data.id}/view`);
  }

  handleIconClick = this.handleIconClick.bind(this);
  handleIconClick() {
    trackEvent({
      element: 'Button',
      action: 'searchVIN',
      page: 'HomePage'
    });
    this.handleButtonClick();
  }

  handleInputChange(e) {
    let {value} = e.target;
    if (typeof value === "string") {
      value = value.toUpperCase();
    }
    this.onChange(value);
  }

  render() {
    const { inputStr, error, showError } = this.state;

    return (
      <div className={cn(styles.search, { [styles.empty]: inputStr === '' })}>
        <AuInput
          autoFocus={true}
          name="input"
          value={inputStr}
          error={error}
          showError={showError}
          placeholderId={this.attrPlaceholderId}
          onChange={this.handleInputChange}
          onKeyDown={this.onKeyDown}
          onClear={() => this.onChange('')}
          className={styles.input}
        />
        <span className={cn(styles.icon, { [styles.icon_error]: showError })} onClick={this.handleIconClick}/>
      </div>
    );
  }
}
