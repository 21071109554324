import React from 'react';
import * as T from 'prop-types';
import debounce from 'lodash/debounce';

import TMC from '@autonomic/browser-sdk';
import AuDropDown from '@au/core/lib/components/elements/AuDropDown';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import styles from '../../css/components/filter_authorization_action.module.scss';

export default class AuthorizationAction extends React.Component {

  static propTypes = {
    selection: T.arrayOf(T.object),
    defaultValue: T.string,
    onChange: T.func,
    onInit: T.func,
    allowTyping: T.bool,
    resourceAui: T.string
  }

  static defaultProps = {
    allowTyping: true,
    selection: []
  }

  state = { authzActionItems: [], counter: 0 }
  iamServiceV2 = new TMC.services.Iam({ apiVersion: 2 });

  componentDidMount() {
    this.loadActions(this.props.resourceAui);
    this.props.onInit(this.props.selection);
  }

  loadActions(resourceAui) {
    // default suggest all actions
    resourceAui = resourceAui ? resourceAui : 'aui:*:*/*';

    this.iamServiceV2.roles.suggest({ resourceAui }).then(resp => {
      this.setState({ authzActionItems: [
        ...(resp.data.actions || []).map(({ name }) => ({
            displayString: name,
            val: name
          }))
      ]});
    }, createResponseAlertMessage);
  }

  handleActionSelect = debounce(this.handleActionSelect, 150).bind(this);
  handleActionSelect(action) {
    let selectAction = action ? {
      urlValue: action
    } : [];
    this.setState(prevState => ({ counter: prevState.counter + 1 }));
    this.props.onChange(selectAction);
  }

  render() {
    const { authzActionItems, counter } = this.state;
    return (
      <div className={styles.container}>
        <AuDropDown
          key={`audit_action_${counter}`}
          options={authzActionItems}
          selectOption={this.handleActionSelect}
          createMode={false}
          placeholderId="au.auditActionSelector.placeholder.auditAction"
          allowTyping={this.props.allowTyping}
        />
      </div>
    );
  }
}