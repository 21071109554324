import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuButton, { BUTTON_SIZE_MEDIUM, BUTTON_TYPE_TERTIARY } from '@au/core/lib/components/elements/AuButton';

import {
  BANNER_TYPE_INFO_SQUARE,
  BANNER_TYPE_WARNING,
  BANNER_TYPE_SUCCESS
} from './Banner';
import Banner from './Banner';
import {
  isValidTap,
  isValidOutput,
  hasAttributeFilter,
  hasPermittedFilter,
  hasMembershipFilter,
  hasSignalFilter,
  hasAttributeWildcardUri,
  hasValidAttributeWildcardUri
} from './utils/taps';
import { TAP_VALIDATION_MODES, TAP_FILTERS_MODES } from '../constants';
import { history as browserHistory } from '../history';

import styles from '../css/components/tap_validation_message.module.scss';

const ValidationMessage = (props) => {
  if (props.mode === TAP_FILTERS_MODES.CREATE) {
    if (props.entityType === "fork") {
      return (
        <AutoIntl 
          displayId="au.forks.validation.info"
          className={props.className}
        />
      )
    }
    return (
      <AutoIntl
        displayId="au.taps.validation.info"
        className={props.className}
      />
    );
  }

  return (
    <AutoIntl
      displayId={`au.taps.validation.${props.valid ? 'success' : 'warning'}`}
      className={props.className}
      values={{
        b: chunks => <b>{chunks}</b>
      }}
      tag="div"
    />
  );
};

const ValidationRecord = (props) => {
  const { valid, className, displayId, displayString } = props;

  return (
    <AutoIntl
      displayId={displayId}
      displayString={displayString}
      className={cn(styles.record, className, {
        [styles.success]: valid === true,
        [styles.warning]: valid === false || valid === undefined,
        [styles.info]: typeof valid === 'undefined'
      })}
      tag="li"
    />
  );
};

export default class TapValidationMessage extends React.PureComponent {

  static propTypes = {
    filters: T.array,
    denyAttributeTags: T.bool,
    mode: T.oneOf([TAP_FILTERS_MODES.EDIT, TAP_FILTERS_MODES.CREATE, TAP_FILTERS_MODES.VIEW]).isRequired,
    validationModes: T.array.isRequired
  }

  static defaultProps = {
    validationModes: Object.values(TAP_VALIDATION_MODES)
  }

  onManageFiltersClick = this.onManageFiltersClick.bind(this);
  onManageFiltersClick() {
    const { location } = window;
    const path = location.pathname.split('/').slice(0, -1).join('/') + '/manage-filters';
    browserHistory.push(path);
  }

  render() {
    const { mode, filters, denyAttributeTags, validationModes, entityType } = this.props;
    const isValid = validationModes.includes(TAP_VALIDATION_MODES.PERMIT) ? isValidOutput(filters) : isValidTap(filters, denyAttributeTags);
    const hasAttribute = hasAttributeFilter(filters);
    const hasMembership = hasMembershipFilter(filters);
    const hasSignal = hasSignalFilter(filters);
    const hasPermitted = hasPermittedFilter(filters);
    const hasAttributeUri = hasAttribute && hasAttributeWildcardUri(filters);
    const hasValidAttributeUri = hasAttributeUri && hasValidAttributeWildcardUri(filters);

    let bannerType;
    let showManageFiltersBtn = false;
    let iconClassName;
    let showCheckList;

    if (mode === TAP_FILTERS_MODES.CREATE) {
      bannerType = BANNER_TYPE_INFO_SQUARE;
      iconClassName = styles.banner_create;
      showCheckList = true;
    } else {
      bannerType = isValid ? BANNER_TYPE_SUCCESS : BANNER_TYPE_WARNING;
      showManageFiltersBtn = window.location.pathname.split('/').splice(-1, 1).join('') !== 'manage-filters';
    }

    return (
      <div className={cn(styles.container, iconClassName)}>
        <Banner type={bannerType} className={cn({ [styles.valid_banner]: isValid && !showCheckList })}>
          <ValidationMessage mode={mode} entityType={entityType} valid={isValid} className={styles.message} />
          {
            (!isValid || showCheckList) &&
            <div>
              <ul className={styles.list}>
                { entityType === "fork" && <ValidationRecord 
                  displayId="au.taps.filters.permit"
                  valid={hasPermitted}
                />}
                { validationModes.includes(TAP_VALIDATION_MODES.PERMIT) && entityType !== "fork" && <ValidationRecord
                  displayId="au.taps.filters.permit"
                  valid={mode === TAP_FILTERS_MODES.CREATE ? isValid || undefined : isValid}
                />}
                { validationModes.includes(TAP_VALIDATION_MODES.MEMBERSHIP) && <ValidationRecord
                  displayId="au.taps.filters.member"
                  valid={mode === TAP_FILTERS_MODES.CREATE ? hasMembership || undefined : hasMembership}
                />}
                { validationModes.includes(TAP_VALIDATION_MODES.SIGNAL) && <ValidationRecord
                  displayId="au.taps.filters.signal"
                  valid={mode === TAP_FILTERS_MODES.CREATE ? hasSignal || undefined : hasSignal}
                />}
                { validationModes.includes(TAP_VALIDATION_MODES.WILDCARD) && hasAttributeUri &&
                  <ValidationRecord
                    displayId={`au.taps.filters.wildCard.${hasValidAttributeUri ? 'valid' : 'invalid'}`}
                    valid={hasValidAttributeUri}
                  />
                }
              </ul>
              { showManageFiltersBtn &&
                <AuButton
                  type={BUTTON_TYPE_TERTIARY}
                  size={BUTTON_SIZE_MEDIUM}
                  className={styles.manage_filters}
                  displayId="au.taps.manageFilters"
                  onClick={this.onManageFiltersClick}
                />
              }
            </div>
          }
        </Banner>
      </div>
    );
  }
}
