import React from 'react';
import * as T from 'prop-types';

import AuInput from '@au/core/lib/components/elements/AuInput';
import AuDropDown from '@au/core/lib/components/elements/AuDropDown';

import { NOOP } from '../constants';

const reportType = Object.freeze([
  'ANTI_THEFT',
  'DEVICE_PROPERTIES',
  'VEHICLE_HEALTH',
  'VEHICLE_STATUS'
]);

export default class MembershipTagFilterValue extends React.PureComponent {

  // May contain other properties defined in AuDropDown.propTypes
  static propTypes = {
    tagKey: T.string,
    value: T.string,
    onChange: T.func
  }

  static defaultProps = {
    onChange: NOOP,
    value: "",
  }

  reportTypeOptions = reportType.map(val => ({
    displayString: val,
    val
  }));

  componentDidUpdate(prevProps) {
    const { tagKey, onChange } = this.props;

    if (tagKey !== prevProps.tagKey) {
      // reset value
      onChange("");
    }
  }

  render() {
    const { tagKey, value, onChange, ...otherProps } = this.props;

    if (tagKey === "REPORT_TYPE") {
      return (
        <AuDropDown
          {...otherProps}
          key={`membershipTag_${tagKey}`}
          selection={value}
          options={this.reportTypeOptions}
          selectOption={onChange}
        />
      );
    }
    return <AuInput
      className={otherProps.className}
      value={value}
      onChange={(event) => onChange(event.target.value)}
    />;
  }

}
