import React from 'react';
import * as T from 'prop-types';
import { Link } from 'react-router-dom';

import { NOOP, SERVICES_PATH } from '../constants';
import { history as browserHistory } from '../history';
import apiFactory from '../utils/api';
import { trackEvent, trackableElements } from '../utils/analyticsHelpers';

export default class EntityLink extends React.Component {

  static propTypes = {
    url: T.string,
    entityId: T.oneOfType([T.number, T.string]),
    serviceAlias: T.string,
    entityType: T.string,
    entityAlias: T.string,
    className: T.string,
    action: T.string,
    actions: T.shape({
      openEntityPage: T.func
    }),
    tracking: T.shape({
      action: T.string,
      page: T.string
    }),
    lazyLoad: T.shape({
      targetProp: T.string
    }),
    popoutContained: T.bool
  }

  static defaultProps = {
    action: 'view',
    lazyLoad: undefined,
    popoutContained: false
  }

  componentDidMount() {
    const { lazyLoad, serviceAlias, entityAlias, actions } = this.props;

    if (lazyLoad && serviceAlias && entityAlias) {
      this.endpoint = apiFactory(serviceAlias, entityAlias, actions);
    }
  }

  trackLink() {
    const { tracking } = this.props;

    if (tracking) {
      trackEvent({
        element: trackableElements.ENTITY_LINK,
        action: tracking.action,
        page: tracking.page
      });
    }
  }

  handleOnClick = this.handleOnClick.bind(this);
  handleOnClick(e) {
    this.trackLink();

    // Cleanup popout if we're navigating away from one
    if (this.props.popoutContained) {
      this.props.actions.closePopout();
    }

    // noop here, using handleOnClick for tracking
    if (this.props.url && !this.props.viewInPopout) return;

    const { entityType, entityAlias, action, viewInPopout, serviceAlias } = this.props;
    const entityId = this.props.entityId.toString();
    const props = {
      viewInPopout
    };
    // defined to make `openEntityPage` params work with `props`
    const entryId = null;

    e.preventDefault();

    this.props.actions.openEntityPage(entityType, entityAlias, entityId, action, entryId, serviceAlias, props);
  }

  handleOnLazyClick = this.handleOnLazyClick.bind(this);
  handleOnLazyClick(e) {
    const metaKeyPressed = e.metaKey;

    e.preventDefault();

    if (this.linkRef && this.linkRef.fetchEntityId) {
      this.linkRef.fetchEntityId.then(entityId => {
        this.trackLink();

        //Restore the Command-Click behavior that opens links in a new tab.
        const url = this.generateTo(entityId);
        if (metaKeyPressed) {
          window.open(url, '_blank');
        }
        else {
          browserHistory.push(url);
        }
      });
    }
  }

  resolveEntityId = this.resolveEntityId.bind(this);
  resolveEntityId() {
    const { lazyLoad } = this.props;

    return this.endpoint.get(this.props.entityId)
      .then(resp =>
        resp.data ? resp.data[lazyLoad.targetProp] : Promise.reject(resp)
      );
  }

  handleOnMouseOver = this.handleOnMouseOver.bind(this);
  handleOnMouseOver(e) {
    if (this.endpoint && this.linkRef && !this.linkRef.fetchEntityId) {
      e.persist();

      this.linkRef.fetchEntityId = this.resolveEntityId().then(entityId => {
        // `linkRef` element may no longer be in the DOM
        if (this.linkRef) {
          this.linkRef.setAttribute('href', this.generateTo(entityId));
          return entityId;
        }
      }, () => this.props.entityId); // resolve the original entityId value
    }
  }

  //FIXME - MOVE TO linkHelper
  generateTo(entityId) {
    const { serviceAlias, entityAlias, action } = this.props;
    let { url } = this.props;

    if (!url) {
      // use default path
      url = `${SERVICES_PATH}/${serviceAlias}/${entityAlias}`;
    }

    return url + `/${encodeURIComponent(entityId)}/${action}`;
  }

  render() {
    const { entity, entityId, className, lazyLoad, children } = this.props;

    if (typeof entity == 'string' || entityId == undefined || entityId == false && entityId !== 0) {
      return children
    }
    return (
      <Link
        key={`entityLink_${entityId}`}
        innerRef={el => this.linkRef = el}
        className={className}
        onClick={lazyLoad ? this.handleOnLazyClick : this.handleOnClick}
        onMouseOver={lazyLoad ? this.handleOnMouseOver : NOOP}
        to={this.generateTo(entityId)}
      >{children}</Link>
    );
  }

}
