import DevFlags from '../DevFlags';

class Nothing {
  valid() {
    return !this.explain();
  }
  name() {
    return "nothing";
  }
  explain(obj, path) {
    return '' + path + " => Unexpected(" + obj + ")";
  }
  required() {
    let req = new Nothing();
    req.isRequired = true;
    return req;
  }
}
class Anything {
  valid() {
    return true;
  }
  name() {
    return "anything";
  }
  explain() {
    //nothing cause it never fails
  }
  required() {
    let req = new Anything();
    req.isRequired = true;
    return req;
  }
}
class AuString {
  valid(obj) {
    return !(this.explain(obj));
  }
  name() {
    return "string";
  }
  explain(obj, path) {
    path = path || '';
    if (!(typeof obj === 'string')) {
      return '' + path + " => Not a string(" + obj + ")";
    }
  }
  required() {
    let req = new AuString();
    req.isRequired = true;
    return req;
  }
}

function isNumeric( obj ) {
  return !isNaN(parseFloat(obj)) && isFinite(obj);
}

class AuNumber {
  valid(obj) {
    return !this.explain(obj);
  }
  name() {
    return "number";
  }
  explain(obj, path) {
    path = path || '';
    if (!isNumeric(obj)) {
      return '' + path + " => Not a number(" + obj + ", " + typeof obj + ")";
    }
  }
  required() {
    let req = new AuNumber();
    req.isRequired = true;
    return req;
  }
}
class AuBool {
  valid(obj) {
    return obj == true || obj == false;
  }
  name() {
    return "bool";
  }
  explain(obj, path) {
    path = path || '';
    if (!this.valid(obj)) {
      return '' + path + " => Not a bool(" + obj + ")";
    }
  }
  required() {
    let req = new AuBool();
    req.isRequired = true;
    return req;
  }
}
class Map {
  constructor(specs, otherSpec, name) {
    this.specs = specs;
    this._name = name;
    this.otherSpec = otherSpec;
  }
  valid(obj) {
    return !(this.explain(obj));
  }
  required() {
    let req = new Map(this.specs, this.otherSpec, this._name);
    req.isRequired = true;
    return req;
  }
  name() {
    if (this._name) {
      return "map(" + this._name + ")";
    } else {
      return "map";
    }
  }
  explain(obj, path) {
    path = path || '';
    if (!(Object.prototype.toString.call( obj ) === '[object Object]')) {
      return '' + path + ' => Not an object(' + obj + ')';
    }

    let examinedKeys = [];
    for (let key in obj) {
      let valueSpec = this.specs[key];
      if (!valueSpec) {
        valueSpec = this.otherSpec;
      }

      if ((!(obj[key] == null && !valueSpec.isRequired)) && !valueSpec.valid(obj[key])) {
        return '' + valueSpec.explain(obj[key], '' + path + '(' + key + ')');
      }
      examinedKeys.push(key);
    }
    let unexaminedKeys = Object.keys(this.specs).filter(x => examinedKeys.indexOf(x) < 0);
    for (var i in unexaminedKeys) {
      var ekey = unexaminedKeys[i];
      var spec = this.specs[ekey];
      if (spec.isRequired) {
        return '' + path + '(' + ekey + ') => Required but missing';
      }
    }
  }
}
class Collection {
  constructor(spec) {
    this.spec = spec;
  }
  required() {
    let req = new Collection(this.spec);
    req.isRequired = true;
    return req;
  }
  valid(obj) {
    return !(this.explain(obj));
  }
  name() {
    return "collection";
  }
  explain(obj, path) {
    path = path || '';
    if (!(Object.prototype.toString.call( obj ) === '[object Array]')) {
      return '' + path + ' => Not an array(' + obj + ')';
    }
    for (var e in obj) {
      if (!this.spec.valid(obj[e])) {
        return '' + this.spec.explain(obj[e], '' + path + '[' + e + ']');
      }
    }
  }
}
export default {
  builder: (options) => {
    options = options || {};
    const strict = options.strict;
    return {
      bool: () => new AuBool(),
      number: () => new AuNumber(),
      anything: () => new Anything(),
      nothing: () => new Nothing(),
      collection: (spec) => new Collection(spec),
      map: (specs, name) => new Map(specs, strict && (new Nothing()) || (new Anything()), name),
      mapOf: (otherSpec, name) => new Map({}, otherSpec, name),
      required: (spec) => spec.required(),
      string: () => new AuString(),
    };
  },
  assert: (assertionContextFn) => {
    if (DevFlags.pauseSpec) { return; }

    let report = (spec, explanation) => {
      if (window._deployment_mode_ === 'staging') {
        /* eslint-disable no-console */
        console.error('' + spec.name() + ' schema failed: ', explanation);
        /* eslint-enable no-console */
      } else if (window._deployment_mode_ === 'test') {
        /* eslint-disable no-console */
        console.error('' + spec.name() + ' schema failed: ', explanation);
        /* eslint-enable no-console */
        throw new Error('' + spec.name() + ' schema failed: ', explanation);
      } else if (window._deployment_mode_ === 'development') {
        /* eslint-disable no-console */
        console.error('' + spec.name() + ' schema failed: ', explanation);
        /* eslint-enable no-console */
      } else {
        //noop
      }
    };
    let check = ({spec, data}) => {
      if (!spec.valid(data)) {
        report(spec, spec.explain(data));
      }
    };
    if (window._deployment_mode_ === 'staging') {
      check(assertionContextFn());
    } else if (window._deployment_mode_ === 'test') {
      check(assertionContextFn());
    } else if (window._deployment_mode_ === 'development') {
      check(assertionContextFn());
    } else {
      //noop
    }
  }
};
