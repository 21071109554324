import isEqual from "lodash/isEqual";

import { redirectOnSaveSuccess } from "../../../utils/linkHelper";
import { getNewFlowEntities } from "../../../utils/flowCreate";
import { FLOW_ARN, UUID_RE } from "../../../constants";
import {
  hasPermittedFilter,
  isValidVin
} from '../../utils/taps';
import Edit from "../Edit";

export default class ForkEdit extends Edit {
  queryParams = this.props.entityDef.queryParams;

  getEntitiesToCreate() {
    const { actions, entityDef } = this.props;
    const { modifiedEntity } = this.state;
    const editedFork = {
      id: modifiedEntity.id,
      displayName: modifiedEntity.displayName,
      inputFlowId: modifiedEntity.inputFlowId,
      businessUnit: modifiedEntity.businessUnit,
      region: modifiedEntity.region,
      outputs: Object.values(modifiedEntity.outputCreate ?? {}).map(output => ({
        outputId: output.outputId,
        aui: output.aui,
        displayName: output.displayName,
        filters: output.filters,
        businessUnit: output.businessUnit,
        region: output.region
      }))
    };

    let entitiesToCreate = super.getEntitiesToCreate();

    if (modifiedEntity.outputCreate){
      const { entitiesToCreate: newFlowsGroups } = getNewFlowEntities(Object.values(modifiedEntity.outputCreate), actions);
      entitiesToCreate = new Map([...entitiesToCreate, ...newFlowsGroups]);
    }

    //All of our fork edits
    entitiesToCreate.set(`feed-service-updateFork`,
      {
        entities: [{
          key: `feed-service-updateFork`,
          idProp: entityDef.pkField,
          data: { ...editedFork },
          saveFn: (data) => {
            const modifiedData = { ...data };

            //Apply the ids from the newly created groups
            modifiedData.outputs = modifiedData.outputs.map(output => {
              const filters = output.filters.map(filter => {
                return filter;
              });
              return {...output, filters};
            });

            return this.updateEntity(modifiedData);
          },
          halt: true,
          displayFields: ['displayName', 'id']
        }],
        serviceAlias: 'feed-service',
        entityType: 'fork',
        entityAlias: 'forks',
        verbRoot: "edit",
      });

    return entitiesToCreate;
  }

  updateEntity = this.updateEntity.bind(this);
  updateEntity(entity) {
    const { endpoint } = this.props;
    const origEntity = this.state.entity.toJS();

    const forkPromises = [];

    const forkOutputEndpoint = endpoint.getOutputsEndpoint(origEntity.id);
    this._addNewOutputs(forkOutputEndpoint, entity, origEntity, forkPromises);
    this._updateOutputs(forkOutputEndpoint, entity, origEntity, forkPromises);
    this._deleteOldOutputs(forkOutputEndpoint, entity, origEntity, forkPromises);

    const updateForkPromise = this._updateFork(endpoint, entity, origEntity);
    forkPromises.push(updateForkPromise);

    return Promise.all(forkPromises).then(() => updateForkPromise).catch(this.genericErrorHandler);
  }

  _addNewOutputs(endpoint, entity, origEntity, forkPromises) {
    const newOutputs = entity.outputs.reduce((outputsToBeCreated, output) => {
      if (!output.outputId || !origEntity.outputs.some(originalOutput => originalOutput.outputId === output.outputId)) {
        return outputsToBeCreated.concat({displayName: output.displayName, businessUnit: output.businessUnit, region: output.region, filters: output.filters});
      }
      return outputsToBeCreated;
    }, []);

    if (newOutputs.length > 0){
      forkPromises.push(endpoint.create({outputs: newOutputs}));
    }
  }

  _updateOutputs(forkOutputEndpoint, entity, origEntity, forkPromises) {
    for (const output of entity.outputs) {
      if (output.outputId) {
        const origOutput = origEntity.outputs.find(originalOutput => originalOutput.outputId === output.outputId);
        if (origOutput) {
          if (!this.areFiltersEqual(output.filters, origOutput.filters)) {
            this._updateFilters(forkOutputEndpoint, output.outputId, output.filters, forkPromises);
          }

          const editedFields = {};
          if (!isEqual(origOutput.displayName, output.displayName)) {
            editedFields.displayName = output.displayName;
          }
          if (!isEqual(origOutput.region, output.region)) {
            editedFields.region = output.region;
          }
          if (!isEqual(origOutput.businessUnit, output.businessUnit)) {
            editedFields.businessUnit = output.businessUnit;
          }

          if (Object.keys(editedFields).length) {
            forkPromises.push(forkOutputEndpoint.patch(output.outputId, editedFields));
          }
        }
      }
    }
  }

  _updateFilters(forkOutputEndpoint, outputId, filters, forkPromises){
    forkPromises.push(forkOutputEndpoint.updateFilters(outputId, {filters}));
  }

  _deleteOldOutputs(forkOutputEndpoint, entity, origEntity, forkPromises) {
    origEntity.outputs.forEach(output => {
      const outputShouldExist = entity.outputs.some(newOutput => newOutput?.outputId === output.outputId);
      if (!outputShouldExist) {
        forkPromises.push(forkOutputEndpoint.delete(output.outputId));
      }
    });
  }

  _updateFork(endpoint, entity, origEntity) {
    const editedFields = {};
    if (!isEqual(origEntity.displayName, entity.displayName)) {
      editedFields.displayName = entity.displayName;
    }
    if (!isEqual(origEntity.region, entity.region)) {
      editedFields.region = entity.region;
    }
    if (!isEqual(origEntity.businessUnit, entity.businessUnit)) {
      editedFields.businessUnit = entity.businessUnit;
    }
    if (!isEqual(origEntity.inputFlowId, entity.inputFlowId)) {
      editedFields.input = {aui: `${FLOW_ARN}/${entity.inputFlowId}`};
    }

    if (Object.keys(editedFields).length) {
      // if any field were modified - do patch
      return endpoint.patch(entity[endpoint.idProp], editedFields);
    }

    // nothing changed - skip the request, execute success handler right away
    return Promise.resolve({data: entity});
  }

  areFiltersEqual(a=[], b=[]){
    if (a.length !== b.length) {
      return false;
    }

    return a.reduce((areFiltersEqual, filter, idx) => {
      if(!areFiltersEqual) return false;

      if (filter?.groupFilter) {
        return filter.groupFilter?.groupId === b[idx]?.groupFilter?.groupId;
      }
      return isEqual(filter, b[idx]);
    }, true);
  }

  onStatusDialogClose = this.onStatusDialogClose.bind(this)
  onStatusDialogClose(edited, statuses) {
    if (edited) {
      const { serviceAlias, match } = this.props;
      const status = statuses.get(`${serviceAlias}-updateFork`);

      redirectOnSaveSuccess(status.resp, this.props.endpoint, match.url);
    }
    else {
      super.onStatusDialogClose();
    }
  }

  handleOnSave = this.handleOnSave.bind(this);
  handleOnSave(modifiedEntity) {
    this.setState({
      showStatusDialog: true,
      modifiedEntity
    });

    return Promise.resolve();
  }

  disableSaveBtn = this.disableSaveBtn.bind(this);
  disableSaveBtn(entity) {
    const attributes = this.props.entityDef.attributes;
    let requiredFields = [];
    let requiredEntityFieldsObject = {};
    let emptyValue;
    let hasOutputFlow;
    let hasPermitted;
    let numFiltersWithDetails = 0;
    let numFilters = 0;
    let validGroupFilter;
    let validVinFilter;

    Object.entries(attributes).forEach(attribute => {
      if (attribute[1].rules && attribute[1].rules.includes('required') && attribute[1].display?.create !== false) {
        requiredFields.push(attribute[0])
        requiredEntityFieldsObject[attribute[0]] = entity[attribute[0]];
      }
    });

    Object.values(requiredEntityFieldsObject).forEach(field => {
      if (typeof field === 'object') {
        emptyValue = field.length === 0
      }
    });

    if (requiredEntityFieldsObject.outputCreate !== undefined && requiredEntityFieldsObject.outputCreate[0] !== undefined) {
      hasPermitted = hasPermittedFilter(Object.values(requiredEntityFieldsObject.outputCreate)[0]?.filters);
      hasOutputFlow = Object.values(requiredEntityFieldsObject.outputCreate)[0].aui !== '';
      numFilters = Object.values(requiredEntityFieldsObject?.outputCreate)[0].filters.length;
      Object.values(requiredEntityFieldsObject.outputCreate)[0].filters.forEach(filter => {
        if (filter.groupFilter) {
          validGroupFilter = UUID_RE.test(filter.groupFilter.groupId);
          if (filter.groupFilter.groupId !== '' && filter.groupFilter.groupId !== '') {
            numFiltersWithDetails ++;
          }
        }
        if (filter.vinFilter) {
          const checkIfDuplicateExists = (arr=[]) => {
            return new Set(arr).size !== arr.length;
          }
          validVinFilter = !checkIfDuplicateExists(filter.vinFilter.vins) && filter.vinFilter.vins?.every(isValidVin)
          if (filter.vinFilter.vins?.length > 0) {
            numFiltersWithDetails++;
          }
        }
        if (filter.memberPassThroughFilter) {
          numFiltersWithDetails ++;
        }
      })
    }

    return Object.values(requiredEntityFieldsObject).includes(undefined) ||
      Object.values(requiredEntityFieldsObject).includes('') ||
      !hasPermitted ||
      !hasOutputFlow ||
      emptyValue === undefined ||
      numFilters !== numFiltersWithDetails ||
      validGroupFilter === false ||
      validVinFilter === false
  }

}
