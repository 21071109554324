import React from 'react';
import * as T from 'prop-types';

import { SelectableListProps } from '../../AuPropTypes';
import { NOOP, statementEffect } from '../../constants';
import FilterSelectableList from "./FilterSelectableList";

export default class AuthorizationStatus extends React.Component {

  static propTypes = {
    defaultValue: SelectableListProps.defaultValue,
    containerRef: SelectableListProps.containerRef,
    selection: SelectableListProps.selection,
    sort: SelectableListProps.sort,
    multiSelect: T.bool,
    onChange: T.func,
    onInit: T.func
  }

  static defaultProps = {
    onChange: NOOP,
    onInit: NOOP,
    selection: []
  }

  authzStatusItems = Object.keys(statementEffect).map(value => ({
    displayId: `au.entity.state.${value}`, value
  }));

  render() {
    return (
      <FilterSelectableList
        containerRef={this.props.containerRef}
        items={this.authzStatusItems}
        sort={this.props.sort}
        defaultValue={this.props.defaultValue}
        selection={this.props.selection}
        multiSelect={this.props.multiSelect}
        onChange={this.props.onChange}
        onInit={this.props.onInit}
      />
    );
  }

}
