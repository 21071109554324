import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import EntityLink from './EntityLink';

import styles from '../css/components/state_icon_entity_link.module.scss';

export default class StateEntityLink extends React.Component {
  static propTypes = {
    url: T.string,
    entityId: T.oneOfType([T.number, T.string]),
    entityType: T.string,
    entityAlias: T.string,
    className: T.string,
    status: T.string
  }

  render() {
    const {
      url,
      entityId,
      entityType,
      entityAlias,
      className,
      children,
      popoutContained,
      status
    } = this.props;

    return (
      <div className={cn(styles.container, status !== undefined ? styles[`${className}-${status}`] : styles[className])}>
        <EntityLink
          entityAlias={entityAlias}
          entityType={entityType}
          url={url}
          entityId={entityId}
          popoutContained={popoutContained}
        >
          {children}
        </EntityLink>
      </div>
    );
  }
}
