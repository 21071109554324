import React from "react";
import * as T from "prop-types";
import cn from "classnames";
import transit from 'transit-immutable-js';
import cloneDeep from 'lodash.clonedeep';

import TMC from "@autonomic/browser-sdk";
import { createResponseAlertMessage } from "@au/core/lib/components/objects/AlertMessage";
import AuButton, { BUTTON_TYPE_PRIMARY, BUTTON_TYPE_PLAIN, BUTTON_TYPE_ALERT } from "@au/core/lib/components/elements/AuButton";
import JsonEditor from "@au/core/lib/components/elements/JsonEditor";
import AuDropDown from "@au/core/lib/components/elements/AuDropDown";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import ResultsDrawer from "@au/core/lib/components/elements/ResultsDrawer";
import ConfirmationDialog from "@au/core/lib/components/objects/ConfirmationDialog";
import AuInput from "@au/core/lib/components/elements/AuInput";

import { history as browserHistory } from "../../../history";
import { formatMessage } from "../../../utils/reactIntl";
import Breadcrumbs from "../Breadcrumbs";
import TagEditor from "../../TagEditor";
import SimpleTable, { Cell, DragHandle, DraggableRow, HeaderCell, Row } from "../../SimpleTable";
import DefaultView from '../View';

import styles from "../../../css/components/command_management_define.module.scss";

const detailNames = ["type", "version", "fsmName", "description"];
const parsableFields = ["maxExpirationSeconds", "deliveryPriority", "deviceTypePriorityList", "description", "propertiesSchema", "cloudToDeviceConversionDefinitions", "deviceToCloudConversionDefinitions", "tags"];

const SECONDS_IN_DAY = 86400;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;

function secondsToDays(seconds) {
  return Math.floor(seconds / SECONDS_IN_DAY);
}

function secondsToTimePeriod(seconds) {
  return {
    days: secondsToDays(seconds),
    hours: Math.floor(seconds / SECONDS_IN_HOUR) % HOURS_IN_DAY,
    minutes: Math.floor(seconds / SECONDS_IN_MINUTE) % MINUTES_IN_HOUR,
    seconds: seconds % SECONDS_IN_MINUTE
  };
}

function convertTimePeriodToSeconds(days, hours, minutes, seconds) {
  return days * SECONDS_IN_DAY + hours * SECONDS_IN_HOUR + minutes * SECONDS_IN_MINUTE + seconds;
}

const getNewDefinition = () => ({protocolType: "FTCP", protocolVersion: "", protocolSubtype: "", protocolConversionRule: {}});

const getJsonContent = (value) => {
  if (value) {
    return typeof value === 'string' ? { text: value } : { json: value };
  } else { 
    return { text: "" };
  }
}

const initialDefinition = {
  define: {
    deliveryPriority: "1",
    deviceTypes: [],
    description: "",
    ...secondsToTimePeriod(0),
  },
  propertiesSchema: "",
  cloudToDeviceConversionDefinitions: [getNewDefinition()],
  deviceToCloudConversionDefinitions: [getNewDefinition()],
}

const initialErrors = {
  define: false,
  propertiesSchema: false,
  cloudToDeviceConversionDefinitions: false,
  deviceToCloudConversionDefinitions: false,
}

export default class CommandManagementDefine extends DefaultView {

  caasGlobalMetaData = JSON.parse(sessionStorage.getItem('caasGlobalMetaData'));
  SwaggerDocsEndpoint = new TMC.services.Command({ apiVersion: '1-beta' }).apiDocs;
  DefinitionsEndpoint = new TMC.services.Command({ apiVersion: '1-beta' }).definitions;
  definitionJSON;
  validationError;
  dupDefinition;

  state = {
    showImportDialog: false,
    hasImportError: false,
    showSaveDialog: false,
    showExitDialog: false,
    hasFormChanged: false,
    defineLimits: {},
    
    expandDefine: false,
    expandPropertySchema: false,
    expandCloudToDeviceConversionDefinitions: false,    
    expandDeviceToCloudConversionDefinitions: false,

    importText: "",
    isDrawerOpen: false,
    isLoading: false,
    isImportJsonMaximized: false,
    isPropertiesSchemaJsonMaximized: false,
    commandDefinition: initialDefinition,
    sectionErrors: initialErrors,
    ftcpOptions: {cloudToDevice: [], deviceToCloud: []},
    results: [],
    validationSucceed: false,
    hideContent: false
  }

  componentDidMount() {
    super.componentDidMount();

    if (!this.caasGlobalMetaData) {
      this.SwaggerDocsEndpoint.getSwaggerDocs().then((resp) => {
        const apiDefinition = resp.data.CommandDefinitionRequest?.properties || resp.data.components.schemas.CommandDefinitionRequest.properties;
        this.setState({defineLimits: {
            deliveryPriority: Array(apiDefinition.deliveryPriority.maximum - apiDefinition.deliveryPriority.minimum + 1).fill("").map((_v, i) => String(apiDefinition.deliveryPriority.minimum + i)),
            deviceTypes: apiDefinition.deviceTypePriorityList.enum,
            maxExpirationSeconds: apiDefinition.maxExpirationSeconds.maximum,
            minExpirationSeconds: apiDefinition.maxExpirationSeconds.minimum,
        }});
      }).catch(this.genericErrorHandler);
    }

    this.DefinitionsEndpoint.ftcpMessageOptions().then((resp) => {
      this.setState({ftcpOptions: resp.data});
    }).catch(this.genericErrorHandler);

    const dupEntity = sessionStorage.getItem('entityToReplicate');
    if (dupEntity) {
      // get duplicate definition
      const entity = transit.fromJSON(dupEntity);
      this.DefinitionsEndpoint.get(entity.get('id'), this.queryParams)
      .then(resp => {
        this.dupDefinition = resp.data;
        this.setState(prevState => ({commandDefinition: {...prevState.commandDefinition, ...this.parseInputData(this.dupDefinition)}}));
      }).catch(this.genericErrorHandler)
    }
    else if (this.props.entity?.size > 0) {
      this.setState(prevState => ({commandDefinition: {...prevState.commandDefinition, ...this.parseInputData(this.props.entity.toJS())}}));
    }
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    if (this.props.entity !== prevProps.entity) {
      this.setState(prevState => ({commandDefinition: {...prevState.commandDefinition, ...this.parseInputData(this.dupDefinition ?? this.props.entity.toJS())}}));
    }
  }

  collapseAllSections() {
    this.setState({
      expandDefine: false,
      expandPropertySchema: false,
      expandCloudToDeviceConversionDefinitions: false,    
      expandDeviceToCloudConversionDefinitions: false,
    });
  }

  renderDialogs() {
    const dialogs = super.renderDialogs();
    if (this.state.showImportDialog) {
      this.collapseAllSections();
      dialogs.push(this.renderImportDialog());
    }
    if (this.state.showSaveDialog) {
      dialogs.push(this.renderForceSaveDialog());
    }
    if (this.state.showExitDialog) {
      dialogs.push(this.renderExitDialog());
    }
    return dialogs;
  }

  renderImportDialog() {
    return (
      <ConfirmationDialog
        key="import"
        titleId="au.entity.commandManagement.importDefinition"
        confirmDisplayId="au.entity.commandManagement.import"
        confirmBtnDisabled={!this.state.importText || this.state.hasImportError}
        onConfirm={() => this.confirmImport()}
        onCancel={() => this.setState({showImportDialog: false})}
        headerClassName={styles.import_header}
      >
        <AutoIntl className={styles.import_notice} displayId="au.entity.commandManagement.importNotice" tag="div"/>
        <JsonEditor
          onMaximize={() => this.setState({isImportJsonMaximized: true})}
          onMinimize={() => this.setState({isImportJsonMaximized: false})}
          className={cn(styles.import_json, {[styles.maximized]: this.state.isImportJsonMaximized})}
          content={{text: this.state.importText}}
          onChange={(content, _prevContent, e) => {
            this.setState({importText: content.json ? JSON.stringify(content.json) : content.text, hasImportError: Boolean(e.contentErrors)})
          }}
        />
      </ConfirmationDialog>
    );
  }

  renderForceSaveDialog() {
    return (
      <ConfirmationDialog
        className={styles.dialog_container}
        key="save"
        titleId="au.entity.commandManagement.validationError"
        confirmDisplayId="au.entity.commandManagement.proceed"
        onConfirm={() => this.handleForceSave()}
        onCancel={() => this.handleSaveCancel()}
        headerClassName={styles.force_save_header}
        buttonType={BUTTON_TYPE_ALERT}
        cancelDisplayId="au.entity.commandManagement.viewValidation"
      >
        <div className={styles.dialog_subtitle}>
          <AutoIntl className={styles.dialog_type} displayId="au.entity.commandManagement.type"/>
          <span>{this.props.entity.get("type")}</span>
        </div>
        <AutoIntl displayId="au.entity.commandManagement.saveWarning"/>
      </ConfirmationDialog>
    );
  }

  renderExitDialog() {
    return (
      <ConfirmationDialog
        className={styles.dialog_container}
        buttonType={BUTTON_TYPE_ALERT}
        key="exit"
        titleId="au.entity.commandManagement.exitWithoutSaving"
        confirmDisplayId="au.entity.commandManagement.proceed"
        onConfirm={() => this.redirectToList()}
        onCancel={() => this.setState({showExitDialog: false})}
        headerClassName={styles.exit_header}
      >
        <div className={styles.dialog_subtitle}>
          <AutoIntl className={styles.dialog_type} displayId="au.entity.commandManagement.type"/>
          <span>{this.props.entity.get("type")}</span>
        </div>
        <AutoIntl displayId="au.entity.commandManagement.errorWarning"/>
      </ConfirmationDialog>
    );
  }

  confirmImport() {
    try {
      const importData = JSON.parse(this.state.importText);
      const parsedData = this.parseInputData(importData);
      this.setState(prevState => {
        return ({
          showImportDialog: false,
          hasFormChanged: true,
          commandDefinition: {...prevState.commandDefinition, ...(parsedData)},
          expandDefine: Boolean(parsedData.define),
          expandPropertySchema: Boolean(parsedData.propertiesSchema),
          expandCloudToDeviceConversionDefinitions: Boolean(parsedData.cloudToDeviceConversionDefinitions),
          expandDeviceToCloudConversionDefinitions: Boolean(parsedData.deviceToCloudConversionDefinitions)
        });
      });
    }
    catch (e) {
      createResponseAlertMessage(e);
    }
  }

  parseInputData(data) {
    if (this.state.importText && Object.keys(data).every(dataKey => !parsableFields.includes(dataKey))) { // from import
      return createResponseAlertMessage({ data: { message: formatMessage({ id: "au.entity.commandManagement.importError" }) } })
    }
    const parsed = {define: this.state.commandDefinition.define};
    if (data.maxExpirationSeconds) {
      parsed.define = {...parsed.define, ...secondsToTimePeriod(data.maxExpirationSeconds)};
    }
    if (data.deliveryPriority) {
      parsed.define.deliveryPriority = String(data.deliveryPriority)
    }
    if (data.deviceTypePriorityList) {
      parsed.define.deviceTypes = data.deviceTypePriorityList;
    }
    const description = this.props.entity.get("description"); // always from current draft
    if (description) {
      parsed.define.description = description;
    }
    if (data.userTags) {
      parsed.define.userTags = data.userTags;
    }

    if (data.propertiesSchema) {
      parsed.propertiesSchema = data.propertiesSchema;
    }

    ["cloudToDeviceConversionDefinitions", "deviceToCloudConversionDefinitions"].forEach(property => {
      if (data[property] && Array.isArray(data[property])) {
        if (data[property].length > 0) {
          parsed[property] = data[property];
        } else {
          parsed[property] = [getNewDefinition()];
        }
      }
    });
    return parsed;
  }

  openJsonViewer(validationSucceed) {
    const results = [];
    if (validationSucceed === false) {
      results.push({"headerId": "au.entity.validation", "data": this.validationError, "defaultExpanded": true});
    }
    results.push({"headerId": "au.entity.commandManagement.commandDefinition", "data": this.definitionJSON});

    this.setState({isDrawerOpen: true, results: results});
  }

  updateSection(propertyName, content, hasError = false) {
    this.setState(prevState => ({
      commandDefinition: {...prevState.commandDefinition, [propertyName]: content},
      sectionErrors: {...prevState.sectionErrors, [propertyName]: hasError},
      hasFormChanged: true
    }));
  }

  reconcileEntity() {
    const {define, propertiesSchema, cloudToDeviceConversionDefinitions, deviceToCloudConversionDefinitions} = this.state.commandDefinition;
    const entityCopy = this.props.entity.toJS();

    entityCopy.deliveryPriority = define.deliveryPriority;
    entityCopy.maxExpirationSeconds = convertTimePeriodToSeconds(define.days, define.hours, define.minutes, define.seconds);
    entityCopy.deviceTypePriorityList = define.deviceTypes;
    entityCopy.description = define.description;
    if(define.userTags) {
      entityCopy.userTags = define.userTags;
    }
    
    if(propertiesSchema) {
      entityCopy.propertiesSchema = typeof propertiesSchema !== 'string' ? propertiesSchema : JSON.parse(propertiesSchema);
    }

    const c2d = this.getConversionDefinitions(cloudToDeviceConversionDefinitions);
    if (c2d.length) {
      entityCopy.cloudToDeviceConversionDefinitions = c2d;
    }
    const d2c = this.getConversionDefinitions(deviceToCloudConversionDefinitions)
    if (d2c.length) {
      entityCopy.deviceToCloudConversionDefinitions = d2c;
    }

    return entityCopy;
  }

  getConversionDefinitions(definitions) {
    definitions = definitions?.map(definition => {
      const conversionRule = definition.protocolConversionRule ? (typeof definition.protocolConversionRule !== 'string' ? definition.protocolConversionRule : JSON.parse(definition.protocolConversionRule)) : {};
      let protocolVersion = definition.protocolVersion;
      if (definition.protocolVersion === "" || undefined) {
        protocolVersion = conversionRule.protocolVersion;
      }

      return ({
        ...definition,
        protocolVersion,
        protocolConversionRule: conversionRule
      })
    });

    return definitions;
  }

  handleUpdate() {
    this.setState({isDrawerOpen: true, isLoading: true});
    this.definitionJSON = this.reconcileEntity();
    this.DefinitionsEndpoint.validate(this.definitionJSON)
    .then(() => {
    })
    .catch((err) => {
      let validationSucceed = false;
      if (err.status < 400) {
        validationSucceed = true;
      }
      this.setState({isLoading: false, validationSucceed: validationSucceed});
      this.validationError = err._data;
      this.openJsonViewer(validationSucceed);
    });
  }

  handleSave() {
    this.definitionJSON = this.reconcileEntity();
    this.DefinitionsEndpoint.replace(this.definitionJSON)
      .then(() => {
        this.redirectToDetails();
      }).catch((e) => {
        if(e.status === 400 || e.status === 409) {
          this.validationError = e._data;
          this.setState({showSaveDialog: true})
        } else {
          createResponseAlertMessage(e);
        }
      });
  }

  handleExit() {
    if (this.state.hasFormChanged) {
      this.setState({showExitDialog: true});
    } else {
      browserHistory.push({
        pathname: this.parentUrl
      });
    }
  }

  handleForceSave() {
    this.DefinitionsEndpoint.forceUpdate(this.definitionJSON.id, this.definitionJSON)
      .then(() => {
        this.setState({hasFormChanged: false});
        this.redirectToDetails();
      })
      .catch((e) => {
        createResponseAlertMessage(e);
      })
      this.setState({showSaveDialog: false})
  }

  handleSaveCancel() {
    this.setState({showSaveDialog: false, validationSucceed: false});
    this.openJsonViewer(false);
  }

  redirectToDetails() {
    sessionStorage.removeItem('entityToReplicate');
    browserHistory.push({
      pathname: this.baseUrl + `/${this.entityId}/view`,
      state: {
        prevUrl: this.props.match.url
      }
    });
  }

  redirectToList() {
    sessionStorage.removeItem('entityToReplicate');
    browserHistory.push({
      pathname: this.baseUrl + `/list`,
      state: {
        prevUrl: this.props.match.url
      }
    });
  }

  render() {
    const { commandDefinition, ftcpOptions, isDrawerOpen, isLoading, validationSucceed, results, isPropertiesSchemaJsonMaximized,
      expandDefine, expandPropertySchema, expandCloudToDeviceConversionDefinitions, expandDeviceToCloudConversionDefinitions, hideContent } = this.state;

    const validationMessage = isLoading ? "" : validationSucceed ? 'au.entity.commandManagement.validation.success' : 'au.entity.commandManagement.validation.failed';
    const messageCSS = validationSucceed ? styles.validate_success_message : styles.validate_failure_message;

    return (
      <div className={styles.container}>
        <div className={styles.form_container}>
          <Breadcrumbs className={styles.breadcrumbs} crumbs={[{ key: 'command-definition', displayId: 'au.entity.commandManagement.customCommandDefinition' }]} />
          <div className={styles.details_box}>
            {detailNames.map(detail => <React.Fragment key={detail}>
              <AutoIntl displayId={`au.entity.commandManagement.${detail}`} className={styles.detail_name} />
              <div className={styles.detail_value}>{this.props.entity.get(detail)}</div>
            </React.Fragment>)}
          </div>
          {!hideContent && <div className={styles.import}>
            <AutoIntl className={styles.import_text} displayId="au.entity.commandManagement.importJson" />
            <AuButton displayId="au.entity.commandManagement.import" onClick={() => this.setState({ showImportDialog: true })} />
          </div>}
          {!hideContent && <CollapsibleSection expandSection={expandDefine} headerId="au.entity.commandManagement.define">
            <Define
              data={commandDefinition?.define}
              limits={this.caasGlobalMetaData || this.state.defineLimits}
              onChange={(defineData) => this.updateSection("define", defineData)}
            />
          </CollapsibleSection>}
          {!hideContent && <CollapsibleSection expandSection={expandPropertySchema} headerId="au.entity.commandManagement.propertySchema">
            <JsonEditor
              onMaximize={() => this.setState({ isPropertiesSchemaJsonMaximized: true })}
              onMinimize={() => this.setState({ isPropertiesSchemaJsonMaximized: false })}
              className={cn(styles.json, { [styles.maximized]: isPropertiesSchemaJsonMaximized })}
              content={getJsonContent(commandDefinition.propertiesSchema)}
              onChange={(content, _prevContent, e) => {
                this.updateSection("propertiesSchema", content.text || content.json, Boolean(e.contentErrors))
              }}
              defaultExpand={false}
            />
          </CollapsibleSection>}
          {!hideContent && <CollapsibleSection expandSection={expandCloudToDeviceConversionDefinitions} headerId="au.entity.commandManagement.cloudToDeviceDefs">
            <DefinitionsEditor
              definitions={commandDefinition.cloudToDeviceConversionDefinitions}
              ftcpOptions={ftcpOptions.cloudToDevice}
              onChange={(value, hasError) => this.updateSection("cloudToDeviceConversionDefinitions", value, hasError)}
              filterVersions={true}
              importDefinition={expandCloudToDeviceConversionDefinitions}
            />
          </CollapsibleSection>}
          {!hideContent && <CollapsibleSection expandSection={expandDeviceToCloudConversionDefinitions} headerId="au.entity.commandManagement.deviceToCloudDefs">
            <DefinitionsEditor
              definitions={commandDefinition.deviceToCloudConversionDefinitions}
              ftcpOptions={ftcpOptions.deviceToCloud}
              onChange={(value, hasError) => this.updateSection("deviceToCloudConversionDefinitions", value, hasError)}
              filterNamesByVersion={true}
              importDefinition={expandDeviceToCloudConversionDefinitions}
            />
          </CollapsibleSection>}
          {!hideContent && <div className={styles.controls}>
            <AuButton className={styles.save_button} displayId="au.entity.commandManagement.saveChanges" onClick={() => this.handleSave()} type={BUTTON_TYPE_PRIMARY} disabled={isLoading || Object.values(this.state.sectionErrors).some(hasError => hasError)} />
            <AuButton displayId="au.entity.commandManagement.cancel" onClick={() => this.handleExit()} type={BUTTON_TYPE_PLAIN} />
            <AuButton className={isLoading ? cn(styles.update_button, styles.inactive) : styles.update_button} displayId="au.entity.commandManagement.updateJson" onClick={() => this.handleUpdate()} disabled={isLoading} />
          </div>}
        </div>
        <ResultsDrawer isOpen={isDrawerOpen} message={validationMessage} messageClassName={messageCSS} loading={isLoading} loadingDisplayId="au.updatingWait"
          onClose={() => this.setState({ isDrawerOpen: false })} results={results} />
        {this.renderDialogs()}
      </div>
    );
  }
}

class CollapsibleSection extends React.Component {

  static propTypes = {
    headerId: T.string.isRequired,
    openJsonViewer: T.func,
    children: T.oneOfType([T.arrayOf(T.node), T.node]),
    expandSection: T.bool
  }

  state = {
    isOpen: false,
  }

  componentDidUpdate(prevProp) {
    if(prevProp.expandSection !== this.props.expandSection && this.props.expandSection) {
      this.setState({isOpen: this.props.expandSection});
    }
  }

  render() {
    const {headerId, children} = this.props;

    return (
      <div className={cn(styles.section_container, {[styles.expanded]: this.state.isOpen})}>
        <div className={styles.section_header_container}>
          <AutoIntl className={cn(styles.section_header, {[styles.expanded]: this.state.isOpen})} displayId={headerId} onClick={() => this.setState(prevState => ({isOpen: !prevState.isOpen}))}/>
        </div>
        {this.state.isOpen &&
          <div className={styles.section_content}>
            {children}
          </div>
        }
      </div>
    );
  }
}

class Define extends React.Component {

  static propTypes = {
    data: T.shape({
      deliveryPriority: T.string,
      deviceTypes: T.arrayOf(T.string),
      description: T.string,
      days: T.number,
      hours: T.number,
      minutes: T.number,
      seconds: T.number,
      userTags: T.oneOfType([T.object, T.arrayOf(T.string)]),
    }),
    limits: T.shape({
      deliveryPriority: T.arrayOf(T.string),
      deviceTypes: T.arrayOf(T.string),
      maxExpirationSeconds: T.number,
      minExpirationSeconds: T.number,
    }),
    onChange: T.func,
  }

  state = { hasMaxExpirationSecondsChanged: false }

  onChange(newProperties) {
    this.props.onChange({...this.props.data, ...newProperties});
    if (Object.keys(newProperties).indexOf('hasMaxExpirationSecondsChanged') > -1) {
      this.setState({hasMaxExpirationSecondsChanged: true})
    }
  }

  onChangeTags = this.onChangeTags.bind(this);
  onChangeTags(tags) {
    const filteredTag = {};
    for (let name of Object.keys(tags)) {
      if (tags[name] !== '') {
        filteredTag[name] = tags[name];
      }
    }
    this.onChange({userTags: filteredTag});
  }

  onDragEnd = this.onDragEnd.bind(this);
  onDragEnd(result) {
    if (result.destination) {
      const { destination, source } = result;

      // trackEvent({element: trackableElements.FILTER, action: destination.index < source.index ? 'dragUp' : 'dragDown', page: 'ManageFilters'});

      const deviceTypes = [...this.props.data.deviceTypes];
      const dragRow = deviceTypes.splice(source.index, 1)[0];
      deviceTypes.splice(destination.index, 0, dragRow);
      this.onChange({deviceTypes: deviceTypes});
    }
  }

  onDeviceRemove = this.onDeviceRemove.bind(this);
  onDeviceRemove(index) {
    const deviceTypes = [...this.props.data.deviceTypes];
    deviceTypes.splice(index, 1);
    this.onChange({deviceTypes: deviceTypes});
  }

  onDeviceSelect = this.onDeviceSelect.bind(this);
  onDeviceSelect(deviceType) {
    this.onChange({deviceTypes: this.props.data.deviceTypes.concat(deviceType)});
  }

  getMaxAndMinTimes() {
    const {days, hours, minutes, seconds} = this.props.data;
    const {maxExpirationSeconds/*, minExpirationSeconds*/} = this.props.limits;

    const maxDays = secondsToDays(maxExpirationSeconds);
    // const minMinutes = Math.floor(minExpirationSeconds / 60) % 60;
    // const minSeconds = minExpirationSeconds % 60;
    // const minutesPlusSeconds = minutes * 60 + seconds;

    return {
      maxDays: hours || minutes || seconds ? maxDays - 1 : maxDays,
      maxHours: days >= maxDays ? 0 : 23,
      maxMinutes: days >= maxDays ? 0 : 59,
      maxSeconds: days >= maxDays ? 0 : 59,
      // minDays: hours || minutesPlusSeconds >= minExpirationSeconds ? 0: 1,
      // minHours: days || minutesPlusSeconds >= minExpirationSeconds ? 0 : 1,
      // minMinutes: hours || days || minutesPlusSeconds - minExpirationSeconds >= 60 ? 0 : seconds >= minSeconds ? minMinutes : minMinutes + 1,
      // minSeconds: days || hours || minutesPlusSeconds - minExpirationSeconds >= 1 ? 0 : minSeconds,
    };
  }

  render() {
    const {data, limits} = this.props;
    const result = this.getMaxAndMinTimes();
    const {maxDays, maxHours, maxMinutes, maxSeconds} = result;

    return (
      <>
        <AutoIntl className={styles.field_label} displayId="au.entity.commandManagement.deliveryPriority" tag="div"/>
        <div className={styles.radio_container}>
          {limits.deliveryPriority?.map(value => <AuInput
            key={value} name="radio" type="radio" caption={value}
            checked={value === data.deliveryPriority}
            onChange={() => this.onChange({deliveryPriority: value})}
          />)}
        </div>
        <AutoIntl className={styles.field_label} displayId="au.entity.attr.maxExpirationSeconds" tag="div"/>
        <div className={styles.info}>
          <AutoIntl
            className={styles.field_sub_label}
            displayId="au.entity.commandManagement.maxExpiration"
            values={{maxDays: secondsToDays(limits.maxExpirationSeconds), minSeconds: limits.minExpirationSeconds}}
          />
        </div>
        <div className={styles.expiration_container}>
          <AuInput min={0} max={maxDays} className={styles.time_field} type="number" value={data.days} onChange={(event) => this.onChange({days: parseInt(event.target?.value || event), hasMaxExpirationSecondsChanged: true})} createMode={true}/>
          <AutoIntl className={styles.time_label} displayId="au.entity.days"/>
          <AuInput min={0} max={maxHours} className={styles.time_field} type="number" value={data.hours} onChange={(event) => this.onChange({hours: parseInt(event.target?.value || event), hasMaxExpirationSecondsChanged: true})} createMode={true}/>
          <AutoIntl className={styles.time_label} displayId="au.entity.hours"/>
          <AuInput min={0} max={maxMinutes} className={styles.time_field} type="number" value={data.minutes} onChange={(event) => this.onChange({minutes: parseInt(event.target?.value || event), hasMaxExpirationSecondsChanged: true})} createMode={true}/>
          <AutoIntl className={styles.time_label} displayId="au.entity.minutes"/>
          <AuInput min={0} max={maxSeconds} className={styles.time_field} type="number" value={data.seconds} onChange={(event) => this.onChange({seconds: parseInt(event.target?.value || event), hasMaxExpirationSecondsChanged: true})} createMode={true}/>
          <AutoIntl className={styles.time_label} displayId="au.entity.seconds"/>
          {convertTimePeriodToSeconds(data.days, data.hours, data.minutes, data.seconds) > limits.maxExpirationSeconds && <AutoIntl className={styles.time_error} displayId="au.entity.commandManagement.timeTooHigh" values={{maxExpirationDays: secondsToDays(limits.maxExpirationSeconds)}}/>}
          {this.state.hasMaxExpirationSecondsChanged && convertTimePeriodToSeconds(data.days, data.hours, data.minutes, data.seconds) < limits.minExpirationSeconds && <AutoIntl className={styles.time_error} displayId="au.entity.commandManagement.timeTooLow" values={{minExpirationSeconds: limits.minExpirationSeconds}}/>}
        </div>
        <AutoIntl className={styles.field_label} displayId="au.entity.commandManagement.deviceTypes" tag="div"/>
        <DeviceTypeTable options={limits.deviceTypes} selectedDeviceTypes={data.deviceTypes} onDragEnd={this.onDragEnd} onDeviceRemove={this.onDeviceRemove} onDeviceSelect={this.onDeviceSelect}/>
        <AutoIntl className={styles.field_label} displayId="au.entity.attr.description" tag="div"/>
        <AuInput type="textarea" value={data.description} className={styles.description_field} onChange={(event) => this.onChange({description: event.target.value})}/>
        <AutoIntl className={styles.field_label} displayId="au.entity.attr.tags" tag="div"/>
        <TagEditor
          tags={data.userTags}
          onChange={this.onChangeTags}
          namePlaceholder={formatMessage({ id: 'au.entity.commandManagement.tagEditor.placeholder.tagName' })}
          valuePlaceholder={formatMessage({ id: 'au.entity.commandManagement.tagEditor.placeholder.tagValue' })}
        />
      </>
    );
  }
}

const deviceTypeColumns = [
  { property: 'priority', labelId: 'au.entity.commandManagement.devicePriority', width: '50px', align: "center" },
  { property: 'deviceType', labelId: 'au.entity.commandManagement.deviceType', width: 'auto' },
  { property: 'drag', labelId: 'au.entity.commandManagement.drag', width: '120px', align: "center" },
  { property: 'remove', labelId: 'au.entity.commandManagement.remove', width: '120px', align: "center" },
];

function createDeviceTypeRow(priority, deviceType, onDeviceRemove) {
  return [
    <Cell key="priority" className={cn(styles.priority_cell, styles.centered_cell)}>{priority}</Cell>,
    <Cell key="deviceType" className={styles.white_cell}>{deviceType}</Cell>,
    <Cell key="drag" className={styles.white_cell}><DragHandle className={styles.drag_cell}></DragHandle></Cell>,
    <Cell key="remove" className={styles.remove_cell}><div onClick={() => onDeviceRemove(priority - 1)}/></Cell>
  ];
}

function createNewRow(options, selectedDeviceTypes, onDeviceSelect) {
  const deviceOptions = options
    .filter(type => !selectedDeviceTypes.includes(type))
    .map(type => ({val: type, displayString: type}));

  return [
    <Cell className={styles.priority_cell} key="priority">{selectedDeviceTypes.length + 1}</Cell>,
    <Cell className={styles.white_cell} key="deviceType"><AuDropDown key={selectedDeviceTypes.length} createMode={true} selectOption={onDeviceSelect} options={deviceOptions} placeholderId="au.entity.attr.choosePlaceholder"/></Cell>,
    <Cell className={styles.white_cell} key="drag"/>,
    <Cell className={styles.white_cell} key="remove"/>
  ];
}

class DeviceTypeTable extends React.Component {

  static propTypes = {
    options: T.arrayOf(T.string).isRequired,
    selectedDeviceTypes: T.arrayOf(T.string).isRequired,
    onDragEnd: T.func.isRequired,
    onDeviceRemove: T.func.isRequired,
    onDeviceSelect: T.func.isRequired,
  };

  render() {
    const {options, selectedDeviceTypes, onDragEnd, onDeviceRemove, onDeviceSelect} = this.props;

    return (
      <SimpleTable className={styles.table_container} onDragEnd={onDragEnd}>
        <Row>
          {deviceTypeColumns.map(col => (
            <HeaderCell
              key={`header_${col.property}`}
              className={cn(styles.header_cell, {[styles.centered_cell]: col.align === "center"})}
              width={col.width}
            >{formatMessage({ id: col.labelId })}</HeaderCell>
          ))}
        </Row>
        {selectedDeviceTypes.map((deviceType, index) => <DraggableRow key={deviceType} id={deviceType} index={index}>{createDeviceTypeRow(index + 1, deviceType, onDeviceRemove)}</DraggableRow>)}
        {selectedDeviceTypes.length < options.length && <Row id="new-device" index={selectedDeviceTypes.length}>{createNewRow(options, selectedDeviceTypes, onDeviceSelect)}</Row>}
      </SimpleTable>
    );
  }
}

class DefinitionsEditor extends React.Component {

  static propTypes = {
    definitions: T.arrayOf(T.shape({
      protocolType: T.string,
      protocolVersion: T.string,
      protocolSubtype: T.string,
      protocolConversionRule: T.object,
    })),
    onChange: T.func,
    ftcpOptions: T.arrayOf(T.shape({
      messageVersion: T.string,
      messageNames: T.arrayOf(T.string),
    })),
    filterVersions: T.bool,
    filterNamesByVersion: T.bool
  }

  constructor(props) {
    super(props);

    this.state = {
      errorMap: Array(this.props.definitions.length).fill(false),
      isJsonMaximized: -1,
      definitions: [{
        protocolType: '',
        protocolVersion: '',
        protocolSubtype: '',
        protocolConversionRule: {},
        idx: 0,
        showError: false,
        imported: false
      }],
      lastKnownIdx: 0
    };

    if (this.props.definitions && Array.isArray(this.props.definitions)) {
      this.state.definitions = this.props.definitions.map((definition, index) => ({
        protocolSubtype: definition.protocolSubtype,
        protocolType: definition.protocolType,
        protocolVersion: definition.protocolVersion,
        protocolConversionRule: definition.protocolConversionRule,
        idx: index,
        disableResetBtn: definition.protocolVersion.length === 0,
        imported: false
      }));

      this.commitChanges();
    }
  }

  componentDidUpdate() {
    const { definitions, importDefinition } = this.props;

    Object.values(definitions).map((definition, index) => {
      if (importDefinition && !definition.imported) {
        this.setState(prevState => {
          const definitions = cloneDeep(prevState.definitions);
          definitions[index] = definition
          definitions[index].imported = true;
          return { definitions };
        })
      }
    })
  }

  onVersionChange(index, value) {
    this.setState(prevState => {
      const definitions = cloneDeep(prevState.definitions);
      definitions[index].protocolVersion = value;
      definitions[index].disableResetBtn = false;
      definitions[index].imported = false;
      if (this.state.definitions[index].protocolSubtype && definitions[index].protocolVersion !== this.state.definitions[index].version) {
        const potentialOptions = this.props.ftcpOptions.find(option => option.messageVersion === definitions[index].protocolVersion)?.messageNames.map(option => ({ val: option, displayString: option }));
        if (!potentialOptions.some(protocolSubtype => protocolSubtype.val === definitions[index].protocolSubtype)) {
          definitions[index].showError = true;
        } else {
          definitions[index].showError = false;
        }
      }
      return { definitions };
    }, this.commitChanges);
  }

  onNameChange(index, value) {
    this.setState(prevState => {
      const definitions = cloneDeep(prevState.definitions);
      definitions[index].protocolSubtype = value;
      definitions[index].disableResetBtn = false;
      definitions[index].showError = false;
      definitions[index].imported = false;
      if (definitions[index].protocolConversionRule.ftcpMessageClassname) {
        definitions[index].protocolConversionRule.ftcpMessageClassname = value;
      }
      return { definitions };
    }, this.commitChanges);
  }

  onJsonChange(index, value, hasErrors) {
    this.setState(prevState => {
      const definitions = cloneDeep(prevState.definitions);
      definitions[index].protocolConversionRule = value;
      definitions[index].disableResetBtn = false;
      definitions[index].imported = false;
      const errorMapCopy = [...prevState.errorMap];
      errorMapCopy[index] = hasErrors;
      return {definitions, errorMap: errorMapCopy};
    }, this.commitChanges);
  }

  getVersionOptions(selectedVersion) {
    if(!this.props.ftcpOptions) {
      return []
    }
    const potentialOptions = this.props.ftcpOptions.map(option => ({val: option.messageVersion, displayString: option.messageVersion}));

    if (this.props.filterVersions) {
      const usedOptions = this.state.definitions.map(definition => definition.protocolVersion);
      return potentialOptions.filter(option => selectedVersion === option.val || !usedOptions.includes(option.val));
    }
    return potentialOptions;
  }

  getNameOptions(selectedVersion, selectedName) {
    if (!selectedVersion) {
      return []
    }

    const potentialOptions = this.props.ftcpOptions.find(option => option.messageVersion === selectedVersion)?.messageNames.map(option => ({val: option, displayString: option}));

    if (potentialOptions && this.props.filterNamesByVersion) {
      const usedOptions = this.state.definitions.filter(definition => definition.protocolVersion === selectedVersion).map(definition => definition.protocolSubtype);
      return potentialOptions.filter(option => selectedName === option.val || !usedOptions.includes(option.val));
    }
    return potentialOptions;
  }

  disableAddButton() {
    return this.getVersionOptions().length === 0;
  }

  addNewDefinition() {
    if (!this.disableAddButton()) {
      this.setState(prevState => {
        const definitions = cloneDeep(prevState.definitions);
        definitions.push({protocolType: "FTCP", protocolVersion: "", protocolSubtype: "", protocolConversionRule: {}, idx: prevState.lastKnownIdx + 1, disableResetBtn: true});
        return { errorMap: prevState.errorMap.concat(false), definitions, lastKnownIdx: prevState.lastKnownIdx + 1 };
      }, this.commitChanges);
    }
  }

  removeDefinition(index) {
    this.setState(prevState => {
      const definitions = cloneDeep(prevState.definitions);
      definitions.splice(index, 1);
      return { definitions };
    }, this.commitChanges);
  }

  resetDefinition(index) {
    this.setState(prevState => {
      const definitions = cloneDeep(prevState.definitions);
      definitions[index].protocolVersion = "";
      definitions[index].protocolSubtype = "";
      definitions[index].protocolConversionRule = {};
      definitions[index].disableResetBtn = true;
      definitions[index].showError = false;
      return { definitions };
    }, this.commitChanges);
  }

  isDisabled(index) {
    return this.state.definitions[index].disableResetBtn;
  }

  commitChanges() {
    const definitions = this.state.definitions.reduce((acc, definition) => {
      acc.push(definition);
      return acc;
    }, []);

    this.props.onChange(definitions, this.state.errorMap.some(hasErrors => hasErrors));
  }

  render() {
    const { definitions } = this.state;

    return (
      <div className={styles.definitions_container}>
        {definitions.map((definition, index) => {
          if (!definition.protocolSubtype) {
            definition.protocolSubtype = definition.protocolConversionRule?.ftcpMessageClassname;
          }
          return (
            <div key={index} className={styles.definition}>
              <div className={cn(styles.definition_inputs, {[styles.error_margin]: definition.showError})}>
                <AuInput
                  value={definition.protocolType}
                  readOnly={true}
                  createMode={true}
                  className={styles.protocol_type_input}
                />
                <AuDropDown
                  key={definition.protocolVersion}
                  options={this.getVersionOptions(definition.protocolVersion)}
                  selection={definition.protocolVersion}
                  selectOption={(value) => this.onVersionChange(index, value)}
                  placeholderId="au.entity.commandManagement.definition.version"
                  createMode={true}
                  className={styles.version_dropdown}
                  showError={definition.showError}
                  error={{errDisplayId: "au.entity.commandManagement.prototypeError", fieldDisplayId: "au.entity.commandManagement.prototypeError"}}
                  errorClassName={styles.error}
                />
                <AuDropDown
                  key={definition.protocolSubtype}
                  options={this.getNameOptions(definition.protocolVersion, definition.protocolSubtype)}
                  selection={definition.protocolSubtype}
                  selectOption={(value) => this.onNameChange(index, value)}
                  placeholderId="au.entity.commandManagement.ftcpMessageName"
                  disabled={!definition.protocolVersion}
                  allowTyping={true}
                  createMode={true}
                  maxOptionsHeight={7}
                  showError={definition.showError}
                  className={styles.protocol_subtype_input}
                  optionsClassName={styles.options}
                />
                <AutoIntl className={cn(styles.definition_reset, {[styles.disabled]: this.isDisabled(index) })} displayId="au.entity.reset" onClick={() => this.resetDefinition(index)}/>
                <AutoIntl className={cn(styles.definition_remove, {[styles.disabled]: definitions.length === 1})} displayId="au.entity.remove" onClick={() => this.removeDefinition(index)}/>
              </div>
              <JsonEditor
                onMaximize={() => this.setState({isJsonMaximized: index})}
                onMinimize={() => this.setState({isJsonMaximized: -1})}
                title="Conversion Rule"
                className={cn(styles.json, {[styles.maximized]: this.state.isJsonMaximized === index})}
                content={getJsonContent(definition.protocolConversionRule)}
                onChange={(content, _prevContent, e) => {
                  this.onJsonChange(index, content.json || content.text, Boolean(e.contentErrors));
                }}
              />
            </div>
          );
        })}
        <AutoIntl className={cn(styles.definition_add, {[styles.disabled]: this.disableAddButton()})} displayId="au.entity.commandManagement.addConversionRule" onClick={() => this.addNewDefinition()} tag="div"/>
      </div>
    );
  }
}