import React from 'react';
import * as T from 'prop-types';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import styles from '../css/components/device_bound.module.scss';

function DeviceBound({ isBound }) {
  if (isBound) {
    return (
      <AutoIntl
        displayId={`au.yes`}
        className={styles.bound}
        tag="div" />
    );
  }

  return null;
}

DeviceBound.propTypes = {
  isBound: T.bool.isRequired
};

export default DeviceBound;