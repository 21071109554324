/**
 * Formatters
 *
 */

import React from 'react';
import moment from 'moment-timezone';
import get from 'lodash/get';
import map from 'lodash/map';

import Immutable, { fromJS, List, Map as imMap } from 'immutable';
import { FormattedDate, FormattedMessage } from 'react-intl';
import uuidv4 from 'uuid/v4';

import AuTags from '@au/core/lib/components/elements/AuTags';
import TreeView from '@au/core/lib/components/elements/TreeView';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import {
  processorState,
  tapState,
  commandState,
  commandDefinitionState,
  commandDefinitionValidity,
  deploymentStateIcon,
  forkState,
  SERVICE_NAMES,
  FEED_TAPS,
  FEED_FLOWS,
  FEED_PROCESSORS,
  FEED_FORKS,
  FORK_ARN,
  FLOW_ARN,
  TAP_ARN,
  PROCESSOR_ARN,
  VEHICLE_ARN,
  DEVICE_ARN,
  DIRECT_ROUTE_ARN,
  TAP_VALIDATION_MODES
} from '../constants';
import EntityLink from '../containers/EntityLink';
import DeviceEntityLink from '../containers/DeviceEntityLink';
import UsernameEntityLink from '../containers/UsernameEntityLink';
import AuFormatRelative from '../components/AuFormatRelative';
import FancyKeyValue from '../components/FancyKeyValue';
import FormattedTable from '../components/FormattedTable';
import TapFilters from '../containers/TapFilters';
import InputOutputTable from '../containers/InputOutputTable';
import BoundState from '../components/BoundState';
import ConnectivityStateBadge from '../components/ConnectivityStateBadge';
import StateIcon from '../components/StateIcon';
import SimpleKeyValue from '../components/SimpleKeyValue';
import FormattedKeyValue from '../components/FormattedKeyValue';
import StyledList from '../components/StyledList';
import StateIconEntityLink from '../components/StateIconEntityLink';
import EntityLinkContainer from '../components/EntityLinkContainer';
import VehicleBindings from '../components/VehicleBindings';
import BindingEvent from '../components/BindingEvent';
import DeviceBound from '../components/DeviceBound';
import VehicleTapFilterDetail from '../components/VehicleTapFilterDetail';
import TapState from '../components/TapState';
import StatusBadge from '../components/StatusBadge';
import EmptyValueHint from '../components/EmptyValueHint';
import PublishingDestination from '../components/PublishingDestination';
import SubTable from '../components/SubTable';
import SubEntityLink from '../containers/SubEntityLink';
import TruncatedTagsWithPopup from '../components/TruncatedTagsWithPopup';
import TruncatedListWithPopup from "../components/TruncatedListWithPopup";
import ForkGroupFilterDetails from '../containers/ForkGroupFilterDetails';
import AppliedPolicyTable from '../components/AppliedPolicyTable';
import { getEntityUrlByArn } from './entity';
import { formatMessage } from './reactIntl';
import { isValidTap } from '../components/utils/taps';
import { validUuid } from './validationRules';
import { parseArn } from './parse';
import { statementIdSerializer } from '../utils/serializers';

require("moment-duration-format");

const TZ = moment.tz.guess();

function removeArn(maybeAui) {
  const arn = maybeAui && parseArn(maybeAui);
  if (arn?.length) {
    return maybeAui.slice(arn.length + 1); // remove aui prefix + /
  }
  return maybeAui;
}

export const defaultDateOptions = {
  //FIXME - LOCALIZATION ISSUE
  year: 'numeric', month: 'numeric', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  momentFormat: 'MMM DD YYYY hh:mm a z'
};

function getDateOptions(options) {
  const customDateOptions = {};

  for (let [key, value] of Object.entries(options)) {
    if (typeof value !== 'undefined') {
      customDateOptions[key] = value;
    }
  }

  if (options.milliseconds === true) {
    defaultDateOptions.momentFormat = 'MMM DD YYYY hh:mm:ss.SSS a z';
  }

  return { ...defaultDateOptions, ...customDateOptions };
}

export function applyFormatters(formatters, value, valueProp, keyProp, popoutContained, resources) {
  return formatters.reduce((prevValue, formatter) => {
    const [fnName, props] = Object.entries(formatter)[0];
    return applyFormatter(fnName, props, prevValue, valueProp, keyProp, popoutContained, resources);
  }, value);
}

function applyFormatter(fnName, props, value, valueProp, keyProp, popoutContained, resources) {
  let rowData = imMap.isMap(value) ? value : imMap();
  return formatters[fnName]({
    ...props,
    rowData,
    value: valueProp ? rowData.get(valueProp) : value,
    key: keyProp ? `${keyProp}_${rowData.get(keyProp)}` : undefined,
    popoutContained,
    resources
  });
}

const formatters = {
  date({ value, momentFormat, milliseconds }) {
    const sessionTimezone = localStorage.getItem('timezone');
    if (!value) {
      return false;
    }

    const dateOptions = getDateOptions({ timezone: sessionTimezone ? sessionTimezone: TZ, momentFormat, milliseconds });

    if (dateOptions.timezone) {
      // This is needed because FF and IE do not appear to support IANA timezone formats
      return moment.tz(value, dateOptions.timezone).format(dateOptions.momentFormat);
    }

    return <FormattedDate {...dateOptions} value={value} />;
  },

  relativeDate({ value, momentFormat, timezone = TZ }) {
    return (
      <AuFormatRelative date={value} timezone={timezone} momentFormat={momentFormat} />
    );
  },

  dateWithRelative(args) {
    return <React.Fragment>
      {this.date(args)} ({this.relativeDate(args)})
    </React.Fragment>;
  },

  publishingDestination({value}) {
    return <PublishingDestination value={value}/>
  },

  tapFilters({ value, popoutContained, rowData }) {
    if (value instanceof List) {
      return (
        <TapFilters
          filters={value.toJS()}
          denyAttributeTags={rowData.get('denyAttributeTags')}
          formatters={formatters}
          popoutContained={popoutContained}
          /* TODO this may be different for outputs (which also use this formatter) */
          validationModes={[
            TAP_VALIDATION_MODES.MEMBERSHIP,
            TAP_VALIDATION_MODES.ATTRIBUTE,
            TAP_VALIDATION_MODES.WILDCARD,
            TAP_VALIDATION_MODES.ATTRIBUTE_TAGS
          ]}
        />
      );
    }

    return value;
  },

  inputOutputTable({ value, resources, displayField, idField }) {
    const flows = resources.get(FEED_FLOWS) || imMap();
    const forks = resources.get(FEED_FORKS) || imMap();
    const taps = resources.get(FEED_TAPS) || imMap();
    const processors = resources.get(FEED_PROCESSORS) || imMap();

    if (value.size === 1) {
      return false;
    } else {
      let valueMap = {};
      value.toJS().reverse().forEach(item => {
        if (!Array.isArray(item)) {
          valueMap[removeArn(item)] = item;
        }
        else if (Array.isArray(item)) {
          let id = item[0]?.[idField];
          valueMap[id] = item
        }
      })
      // merge all IDs, AUIs, including ID-based and name based since they are all unique
      let linkedEntities = flows.mergeDeep(taps, processors, forks);
      linkedEntities = linkedEntities.mergeDeep(linkedEntities.mapKeys((_, v) => v.get('aui')));
      const entityList = [];
      Object.values(valueMap).flat().map(linkerOrAui => { // flatten arrayMap(ed) arrays (currently just processors)
        let entity;

        if (linkerOrAui[idField]) {
          entity = linkedEntities.get(linkerOrAui[idField]);

        } else if (typeof linkerOrAui === 'string' && !linkerOrAui.includes('processor') && !linkerOrAui.includes('direct-route')) {
          entity = linkedEntities.get(linkerOrAui) || linkerOrAui;
        } else {
          entity = linkerOrAui
          console.warn(`unexpected flow linked entity ${linkerOrAui}`); // eslint-disable-line no-console
        }

        let shorthand;
        if (linkerOrAui[displayField]) {
          shorthand = linkerOrAui[displayField];
        }
        else if (entity && typeof entity !== 'string') {
          shorthand = entity.get('displayName');
        } else if (typeof linkerOrAui === 'string') {
          shorthand = removeArn(linkerOrAui);
        } else {
          shorthand = linkerOrAui
          console.warn(`unable to determine shorthand for ${linkerOrAui}`); // eslint-disable-line no-console
        }

        if (entity) {
          let url, action, status, validProps, statusProps, displayValid = true;
          const aui = typeof entity === 'string' ? entity : entity.get('aui');
          if (aui.startsWith(`${FLOW_ARN}/`)) {
            [url, action] = ['/services/feed-service/flows', 'flow'];
          } else if (aui.startsWith(`${TAP_ARN}/`)) {
            [url, action, status] = ['/services/feed-service/taps', 'tap', typeof entity !== 'string' ? entity.get('state') : undefined];
            const filters = typeof entity !== 'string' ? entity.getIn(['filters']) : List();
            const valid = isValidTap(filters.toJS(), typeof entity !== 'string' ? entity.get('denyAttributeTags') : undefined);

            if (status === tapState.PAUSED) {
              statusProps = { icon: status.toLowerCase(), value: value, displayId: `au.entity.state.${status}` };
            } else if (status) {
              statusProps = { icon: status.toLowerCase(), value: value, displayId: `au.entity.state.${status}` };
            }

            if (valid) {
              displayValid = false;
            } else if (!valid) {
              validProps = { icon: "incomplete", displayId: `au.taps.incomplete` };
            } else {
              validProps = { icon: "incomplete", displayId: `au.taps.incomplete` };
            }
          } else if (aui.startsWith(`${PROCESSOR_ARN}/`)) {
            [url, action, status] = ['/services/feed-service/processors', 'processor', typeof entity !== 'string' ? entity?.get('state') : undefined];
          } else if (aui.startsWith(`${FORK_ARN}/`)) {
            [url, action, status] = ['/services/feed-service/forks', 'fork', typeof entity !== 'string' ? entity?.get('state') : undefined];
          } else if (aui.startsWith(`${DIRECT_ROUTE_ARN}/`)) {
            [url, action, status] = ['/services/feed-service/direct-routes', 'direct-route', 'INFERRED'];
          } else {
            console.warn(`cannot infer entity of unknown type from ${aui}`); // eslint-disable-line no-console
          }

          const lowercaseStatus = status?.toLowerCase()
          const capitalizedType =
            action?.charAt(0).toUpperCase()
            + action?.slice(1)

          entityList.push({
            type: capitalizedType,
            status: action !== 'tap' ?
              <StatusBadge
                icon={lowercaseStatus}
                displayId={`au.entity.state.${status}`}
                displayString={status} value={status}
              />
              :
              <TapState
                displayValid={displayValid}
                validProps={validProps}
                statusProps={statusProps}
                isView={false}
              />,
            name: <EntityLink entity={entity} entityId={typeof entity !== 'string' ? entity.get('id') : entity} url={url}>{shorthand}</EntityLink>
          })
        }
      });

      return (
        <InputOutputTable
          values={entityList}
        />
      );
    }
  },

  fancyKeyValue({ value }) {
    if (value) {
      if (typeof value === 'object') {
        return <FancyKeyValue value={value} />;
      }

      return value;
    }
  },

  localize({ value, prefix }) {
    return (
      prefix && (typeof value !== undefined && value !== '')
        ? formatMessage({ id: `${prefix}.${value}` })
        : value
    );
  },

  defaultValue({ value, localeId }) {
    if (value === undefined || value.length === 0) {
      return formatMessage({ id: localeId });
    } else {
      return value;
    }
  },

  localizeKeys({ value, prefix }) {
    if (value instanceof imMap) {
      return value.mapKeys(k => `${prefix}.${k}`);
    } else if (typeof value === 'object') {
      return Object.entries(value).reduce((acc, [k, v]) => {
        acc[`${prefix}.${k}`] = v;
        return acc;
      }, {});
    }

    return value;
  },

  duration({ value }) {
    if (!value) {
      return false;
    }
    return moment.duration(value, 'ms').format("d[d] h[h] m[m]");
  },

  formattedAuthor({ value }) {
    return map(value, 'name').join(", ");
  },

  formattedBool({ value, options, checkType }) {
    if (!checkType || checkType && typeof value === 'boolean') {
      return <FormattedMessage id={value ? options.true : options.false} />;
    }
    return value;
  },

  formattedEnum({ value, props }) {
    return <FormattedMessage id={props[value.toLowerCase()]} />;
  },

  formattedTable({ value, columns, popoutContained, key, hideEmptyTable }) {
    if (hideEmptyTable && !value?.size) {
      return false;
    }

    return (
      <FormattedTable
        data={value}
        columnDefs={columns}
        formatters={formatters}
        popoutContained={popoutContained}
        key={key}
      />
    );
  },

  checkmarkBool({ value }) {
    return value ? <i className="icon-check-mark" aria-hidden="true" /> : '';
  },

  className({ value }) {
    return <div className={value} />;
  },

  subEntityLink(props) {
    const { url, entityType, value, rowData, action } = props;
    return <SubEntityLink
      url={url}
      entityType={entityType}
      entityId={value}
      popout={true}
      viewInPopout={true}
      data={rowData}
      action={action}
    >
      {value}
    </SubEntityLink>;
  },

  entityLink(props) {
    const {
      value, rowData, idProperty, entityType, serviceAlias, entityAlias,
      lazyLoad, url, action, popout, tracking, popoutContained, key, viewInPopout
    } = props;

    let id;
    if (idProperty) {
      id = 'getIn' in rowData ? rowData.getIn(idProperty.split('.')) : get(rowData, idProperty);
    }

    if ((value || value == 0) && (url || entityType && entityAlias || lazyLoad && serviceAlias && entityAlias)) {
      let entityId;

      if (id !== undefined) {
        entityId = id;
      }
      else if (['string', 'number'].includes(typeof value)) {
        entityId = value;
      }
      else if (typeof value === 'object' && (value.props || {}).textToHighlight) {
        // `value` is a Highlighter element
        entityId = value.props.textToHighlight;
      }

      if (entityId || entityId == 0) {
        return (
          <EntityLink
            key={key}
            url={url}
            serviceAlias={serviceAlias}
            entityType={entityType}
            entityAlias={entityAlias}
            entityId={entityId}
            lazyLoad={lazyLoad}
            action={action}
            popout={popout}
            tracking={{
              action: entityType,
              page: tracking && tracking.page || 'UnknownPage'
            }}
            popoutContained={popoutContained}
            viewInPopout={viewInPopout}
          >
            {value}
          </EntityLink>
        );
      }
    }
    return value;
  },

  entityLinkArray(props) {
    const { value, valueProperty } = props;

    if (!Array.isArray(value) && !List.isList(value)) {
      return value;
    }

    return (
      <EntityLinkContainer>
        {value?.map(item => {
          let val;
          if (valueProperty) {
            val = 'getIn' in item ? item.getIn(valueProperty.split('.')) : get(item, valueProperty);
          }

          return <div key={val}>{formatters.entityLink({ ...props, value: val, rowData: item })}</div>;
        })
        }
      </EntityLinkContainer>
    );
  },

  simpleKeyValue({ value, localePrefix, nameLocaleId, valueLocaleId, order }) {
    if (!value.size) return false;
    return <SimpleKeyValue data={value} localePrefix={localePrefix} nameLocaleId={nameLocaleId} valueLocaleId={valueLocaleId} order={order} />;
  },

  formattedKeyValue({ value, rows, localePrefix, order }) {
    if (!value.size) return false;
    return <FormattedKeyValue data={value} rowDefs={rows} formatters={formatters} localePrefix={localePrefix} order={order} />;
  },

  styledList({ value }) {
    return <StyledList>{value}</StyledList>;
  },

  vehicleBindings({ value }) {
    const bindings = value ? value : List();
    return <VehicleBindings bindings={bindings} />;
  },

  boundState({ value }) {
    const bindings = value ? value : List();
    return <BoundState bindings={bindings} />;
  },

  connectivityStateBadge({ value }) {
    const connectionStatus = value ? value : 'unknown';
    return <ConnectivityStateBadge connectionStatus={connectionStatus} />
  },

  stateIcon({ value, icon, displayId, displayString, iconsMap = {} }) {
    let props;

    if (icon) {
      props = { icon, displayId, displayString };
    } else if (Object.prototype.hasOwnProperty.call(iconsMap, value)) {
      props = Object.assign({}, iconsMap[value]);
    } else {
      props = { icon: value, displayString: value };
    }

    return <StateIcon {...props} />;
  },

  forkState({ value }) {
    const statusProps = {
      icon: forkState[value],
      displayId: `au.entity.state.${value}`,
      value
    };

    return <StatusBadge {...statusProps} />;
  },

  tapState({ value, rowData, action }) {
    const filters = rowData.getIn(['filters']) || List();
    const state = tapState[value];
    const valid = isValidTap(filters.toJS(), rowData.get('denyAttributeTags'));

    let displayValid = true;
    let validDetails;
    let pausedDetails;
    let showValidDetails;
    let showPausedDetails;
    let validProps;
    let statusProps;
    let isView = action === 'view';

    if (state === tapState.PAUSED && isView) {
      const pausedAt = rowData.get('pausedAt');
      showPausedDetails = true;
      pausedDetails = pausedAt
        ? `au.entity.state.paused.${pausedAt}`
        : 'au.entity.state.paused.default';
      statusProps = { icon: state, value: value, displayId: `au.entity.state.${value}` };
    } else if (state) {
      statusProps = { icon: state, value: value, displayId: `au.entity.state.${value}` };
    }

    if (valid) {
      displayValid = false;
    } else if (isView && !valid) {
      showValidDetails = true;
      validDetails = `au.taps.invalidDetails`;
      validProps = { icon: "incomplete", displayId: `au.taps.incomplete` };
    } else {
      validProps = { icon: "incomplete", displayId: `au.taps.incomplete` };
    }

    return (
      <TapState
        displayValid={displayValid}
        validProps={validProps}
        pausedDetails={pausedDetails}
        validDetails={validDetails}
        showValidDetails={showValidDetails}
        showPausedDetails={showPausedDetails}
        statusProps={statusProps}
        isView={isView}
      />
    );
  },

  tapForkState({ auiProp, ...rest }) {
    if (rest.rowData?.get(auiProp).startsWith(FORK_ARN)) {
      return this.forkState(rest);
    }
    return this.tapState(rest);
  },

  processorState({ value }) {
    const statusProps = {
      icon: processorState[value],
      displayId: `au.entity.state.${value}`,
      displayString: value,
      value
    };

    return <StatusBadge {...statusProps} />;
  },

  commandState({ value }) {
    const statusProps = {};

    if (value.includes('IN_PROGRESS') || value.includes('QUEUED')) {
      statusProps.icon = 'in_flight';
      statusProps.displayId = 'au.entity.state.IN_FLIGHT';
      statusProps.displayString = 'IN_FLIGHT';
      statusProps.value = value;
    } else {
      statusProps.icon = commandState[value];
      statusProps.displayId = `au.entity.state.${value}`;
      statusProps.displayString = value;
      statusProps.value = value;
    }

    return (
      <StatusBadge {...statusProps} />
    );
  },

  commandDefinitionState({ value }) {
    const statusProps = {};

    statusProps.icon = commandDefinitionState[value];
    statusProps.displayId = `au.entity.commandManagement.commandDefinition.state.${value}`;
    statusProps.displayString = value;
    return (
      <StatusBadge {...statusProps} />
    );
  },

  commandDefinitionValidity({ value }) {
    const statusProps = {};

    statusProps.icon = commandDefinitionValidity[value.toString().toUpperCase()];
    statusProps.displayId = `au.entity.commandManagement.commandDefinition.validity.${value}`;
    statusProps.displayString = value?.toString();
    return (
      <StatusBadge {...statusProps} />
    );
  },

  deploymentState({ value }) {
    const statusProps = {
      icon: deploymentStateIcon[value],
      displayId: `au.entity.state.${value}`,
      displayString: value,
      value
    };

    return <StatusBadge {...statusProps} />;
  },

  bindingEvent({ value }) {
    return <BindingEvent event={value} />;
  },

  deviceBound({ value }) {
    return <DeviceBound isBound={value} />;
  },

  eventHistoryId({ value }) {
    const string = value.toString();
    return string;
  },

  lowerCaseText({ value }) {
    return value.toLowerCase();
  },

  flowEntityLink({ value, resources, url }) {
    const flows = resources.get(`feed-service-flows`) || imMap();
    const flow = flows.find(flow => flow.get('displayName') === value);

    if (flow) {
      const displayName = flow.get('displayName');
      return this.entityLink({
        key: displayName + uuidv4(), // probably over kill but Forks same flow linked multiple times
        value: displayName,
        rowData: flow,
        idProperty: 'id',
        url: url
      }
      );
    }

    return value;
  },

  truncatedListWithPopup({ value, maxEl, type }) {
    let items = [];
    value.forEach(val => {
      if (val !== undefined) {
        items.push(val)
      }
    })
    return <TruncatedListWithPopup items={items} elementsToShow={maxEl} type={type} />;
  },

  tapForkEntityLink({ value, prop, resources, tracking, popoutContained }) {
    const taps = resources.get(FEED_TAPS) || imMap();
    const forks = resources.get(FEED_FORKS) || imMap();

    const matchingTap = taps.find(tap => tap.get(prop) === value);
    const matchingFork = forks.find(fork => fork.get(prop) === value);

    if (matchingTap || matchingFork) {
      return (
        <EntityLink
          key={value}
          entityId={matchingTap ? matchingTap.get('id') : matchingFork.get('id')}
          url={matchingTap ? `/services/${SERVICE_NAMES.FEED}/taps` : `/services/${SERVICE_NAMES.FEED}/forks`}
          tracking={{
            action: matchingTap ? 'tap' : 'fork',
            page: tracking.page || 'UnknownPage'
          }}
          popoutContained={popoutContained}
        >
          {value}
        </EntityLink>
      );
    }
    return value;
  },

  feedEntityLinks({ value, displayField, idField, resources, tracking, popoutContained }) {
    const flows = resources.get(FEED_FLOWS) || imMap();
    let taps = resources.get(FEED_TAPS) || imMap();
    taps = taps.mergeDeep(taps.mapKeys((_, v) => v.get('tapAui')));
    const processors = resources.get(FEED_PROCESSORS) || imMap();
    const forks = resources.get(FEED_FORKS) || imMap();

    let valueMap = {};
    value.toJS().reverse().forEach(item => {
      if (!Array.isArray(item)) {
        valueMap[removeArn(item)] = item;
      } else if (Array.isArray(item)) {
        let id = item[0]?.[idField];
        valueMap[id] = item
      }
    })

    // merge all IDs, AUIs, including ID-based and name based since they are all unique
    let linkedEntities = flows.mergeDeep(taps, processors, forks);
    linkedEntities = linkedEntities.mergeDeep(linkedEntities.mapKeys((_, v) => v.get('aui')));

    let keyIdx = 0;
    const links = Object.values(valueMap).flat().map(linkerOrAui => { // flatten arrayMap(ed) arrays (currently just processors)
      let entity;
      if (linkerOrAui[idField]) {
        entity = linkedEntities.get(linkerOrAui[idField]);
      } else if (typeof linkerOrAui === 'string' && !linkerOrAui.includes('processor') && !linkerOrAui.includes('direct-route')) {
        entity = linkedEntities.get(linkerOrAui) || linkerOrAui;
      } else {
        entity = linkerOrAui
        console.warn(`unexpected flow linked entity ${linkerOrAui}`); // eslint-disable-line no-console
      }
      let shorthand;
      if (linkerOrAui[displayField]) {
        shorthand = linkerOrAui[displayField];
      } else if (entity && typeof entity !== 'string') {
        shorthand = entity.get('displayName');
      } else if (typeof linkerOrAui === 'string') {
        shorthand = removeArn(linkerOrAui);
      } else {
        shorthand = linkerOrAui
        console.warn(`unable to determine shorthand for ${linkerOrAui}`); // eslint-disable-line no-console
      }

      if (entity) {
        let url, action, icon, status;
        const aui = typeof entity === 'string' ? entity : entity.get('aui');
        if (aui.startsWith(`${FLOW_ARN}/`)) {
          [url, action] = ['/services/feed-service/flows', 'flow'];
        } else if (aui.startsWith(`${TAP_ARN}/`)) {
          [url, action, icon, status] = ['/services/feed-service/taps', 'tap', 'tap', typeof entity !== 'string' ? entity.get('state') : 'INFERRED'];
        } else if (aui.startsWith(`${PROCESSOR_ARN}/`)) {
          [url, action, icon, status] = ['/services/feed-service/processors', 'processor', 'processor', typeof entity !== 'string' ? entity.get('state') : 'INFERRED'];
        } else if (aui.startsWith(`${FORK_ARN}/`)) {
          [url, action, icon, status] = ['/services/feed-service/forks', 'fork', 'fork', typeof entity !== 'string' ? entity.get('state') : 'INFERRED'];
        } else if (aui.startsWith(`${DIRECT_ROUTE_ARN}/`)) {
          [url, action, icon, status] = ['/services/feed-service/direct-routes', 'direct-route', 'direct-route', 'INFERRED'];
        } else {
          console.warn(`cannot infer entity of unknown type from ${aui}`); // eslint-disable-line no-console
        }

        return (
          <StateIconEntityLink
            key={keyIdx++}
            url={url}
            entityId={typeof entity !== 'string' ? entity.get('id') : undefined}
            tracking={{
              action,
              page: tracking.page || 'UnknownPage'
            }}
            popoutContained={popoutContained}
            className={icon}
            status={status}
          >
            {shorthand}
          </StateIconEntityLink>
        );
      }
    });

    return links;
  },

  deviceEntityLink(props) {
    const { value, popout, tracking, popoutContained, action } = props;
    let entityId;

    if (['string', 'number'].includes(typeof value)) {
      entityId = value;
    }
    else if (typeof value === 'object' && (value.props || {}).textToHighlight) {
      // `value` is a Highlighter element
      entityId = value.props.textToHighlight;
    }

    return (
      <DeviceEntityLink
        serviceAlias={SERVICE_NAMES.INVENTORY}
        entityAlias="devices"
        entityType="device"
        entityId={entityId}
        lazyLoad={{
          targetProp: 'id'
        }}
        action={action}
        popout={popout}
        tracking={{
          action: 'device',
          page: tracking && tracking.page || 'UnknownPage'
        }}
        popoutContained={popoutContained}
      >{value}</DeviceEntityLink>
    );
  },

  usernameEntityLink(props) {
    const { value, entityAlias, serviceAlias, popout, popoutContained } = props;

    let entityId;

    if (typeof value === 'string') {
      entityId = value;
    } else if (typeof value === 'object' && (value.props || {}).textToHighlight) {
      entityId = value.props.textToHighlight;
    }

    return (
      <UsernameEntityLink
        serviceAlias={serviceAlias}
        entityAlias={entityAlias}
        entityId={entityId}
        lazyLoad={{
          targetProp: 'id'
        }}
        popout={popout}
        popoutContained={popoutContained}
      >{value}</UsernameEntityLink>
    );
  },

  resourceAuiEntityLink(props) {
    const { value } = props;

    if (!value) {
      return value;
    }

    let aui = value;
    if (typeof value === 'object' && (value.props || {}).textToHighlight) {
      aui = value.props.textToHighlight;
    }

    let [arn, id] = aui.split(/\/(.+)/);
    let url;

    if (id && validUuid(id) === null) {
      url = getEntityUrlByArn(arn);
    }

    if (!url) {
      return aui;
    }

    return <EntityLink entityId={id} url={url}>{value}</EntityLink>;
  },

  policyEntityLink({ rowData, value }) {
    if (!value || !rowData) {
      return value;
    }

    let id = statementIdSerializer({
      'subjectAui': rowData.get('subjectAui', ''),
      'resourceAui': rowData.get('resourceAui', ''),
      'effect': rowData.get('effect', '')
    });

    return <EntityLink entityId={id} url={`/services/iam2-service/statements`}>{value}</EntityLink>;
  },

  permissionEntityLink({ rowData, value }) {
    if (!value || !rowData) {
      return value;
    }

    const aui = rowData.get('objectAui', '');
    if (![`${VEHICLE_ARN}/`, `${DEVICE_ARN}`, `${TAP_ARN}`, `${FLOW_ARN}`].some(arn => aui.startsWith(arn))) {
      // v1 permissions are only limited to a few type of entities
      return value;
    }
    let [arn, id] = aui.split(/\/(.+)/);
    let url;

    if (id && validUuid(id) === null) {
      url = getEntityUrlByArn(arn);
    } else {
      return value;
    }

    return <EntityLink entityId={rowData.get('id')} url={`${url}/${id}/permissions`}>{value}</EntityLink>;
  },

  statementEntitlements({ value }) {
    const entitlements = value.toJS().flat().map((entitlement, index) => {
      return (
        <div key={index}>{entitlement}</div>
      );
    });
    return entitlements;
  },

  tags({ value }) {
    return value ? <AuTags tags={value.toJS()} /> : value;
  },

  tableCellTags({ value }) {
    return value ? <TruncatedTagsWithPopup tags={value.toJS()} /> : value;
  },

  mergeProps({ rowData, props }) {
    return List(props.flatMap((propDefinition) => {
      let [propName, propConfig] = Object.entries(propDefinition)[0];
      let propValues = rowData.get(propName);
      if (typeof propValues === 'string') {
        propValues = [propValues];
      } else {
        propValues = List.isList(propValues) ? propValues.toJS() : [];
      } if (propConfig?.formatters) {
        return this.arrayMap({ value: fromJS(propValues), ...propConfig });
      }
      return propValues;
    }));
  },

  getProperty({ value, rowData, prop }) {
    const displayProps = prop.split('|');
    let propValue;
    for (let propName of displayProps) {
      propValue = rowData.getIn(propName.split('.'));
      if (typeof propValue !== 'undefined') {
        return propValue;
      }
    }
    return rowData.getIn(prop.split('.'), value);
  },

  getPropertyValues({ value, props }) {
    const displayProps = props.split('|');
    return value.entrySeq().toJS()
      .filter(([key]) => displayProps.includes(key))
      .map(([key, val]) =>
        <div key={key}>{val}</div>
      );
  },

  getKey({ value, rowData, prop }) {
    const displayProps = prop.split('|');
    for (let propName of displayProps) {
      if (rowData.has(propName)) {
        return propName;
      }
    }
    return value;
  },

  propertyFromArray({ value, prop }) {
    if (!Array.isArray(value) && !List.isList(value)) {
      return value;
    }

    return (
      <EntityLinkContainer>
        { value.map(link => {
          const text = link.getIn(prop.split('.'));
          return <div key={text}>{ text }</div>;
        }) }
      </EntityLinkContainer>
    );
  },

  assetLink({ value, rowData, idProperty, entityType, url }) {
    // Handling for entities that might have more than one TYPE of entity
    // e.g. Group -> Assets can be vehicles OR devices denoted with '|' in field
    if (url.split('|').length > 0) {
      // The aui property contains the type, after the processor has altered the data.
      const type = rowData.get('aui').toLowerCase();
      if (type) {
        entityType = type;
        url = url.split('|').find(url => url.includes(type)) || url;
      }
    }

    return this.entityLink({ value, rowData, idProperty, entityType, url });
  },

  vehicleTapFilterDetail({ rowData }) {
    return <VehicleTapFilterDetail tapFilter={rowData} />;
  },

  forkFilterDetails({ value }) {
    const vins = value?.get?.("vins");
    if (vins instanceof Immutable.List) {
      return <FancyKeyValue value={{ vins: vins.toJS() }} hideKey />;
    }

    const groupId = value?.get?.("groupId");
    if (groupId) {
      return <><ForkGroupFilterDetails groupId={groupId}/></>
    }
    return value;
  },

  removeProperty({ value, key }) {
    if (imMap.isMap(value)) {
      return value.delete(key);
    }
    return value;
  },

  emptyValueHint({ value, displayId }) {
    if (!value) {
      return <EmptyValueHint displayId={displayId} />;
    }

    return value;
  },

  arrayMap({ value: items, formatters, valueProp, keyProp, popoutContained, resources }) {
    return items && items.map(value => {
      return applyFormatters(formatters, value, valueProp, keyProp, popoutContained, resources);
    });
  },

  treeView({value, expanded, expandOn}) {
    const displayProps = expandOn?.split('|');
    if (!value || !value.size) return false;
    return <TreeView data={fromJS(value)} expanded={expanded} expandOn={displayProps}/>;
  },

  subtable(props) {
    if (!props.attributes) {
      throw new Error("subtable formatter expected attributes, but received none");
    }
    return <SubTable {...props} applyFormatters={applyFormatters} />;
  },

  toSentenceCase({ value }) {
    let sentenceCaseStrings = [];
    value.toLowerCase().split('_').forEach(val => {
      val.replace(
        /\w\S*/g,
        function (txt) {
          sentenceCaseStrings.push(txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
        }
      );
    });
    return sentenceCaseStrings.join(' ');
  },

  appliedPolicyTable(props) {
    const rowData = props?.rowData?.toJS();
    const aui = rowData?.aui;
    const actor = rowData?.actor;
    return <AppliedPolicyTable aui={aui} actor={actor}/>
  },

  notAvailable({ value }) {
    if (value) {
      return value;
    }
    else {
      return (
        <AutoIntl style={{'color': '#D0D0D0'}} displayId={'au.notAvailable'}/>
      )
    }
  }
};

export default formatters;
