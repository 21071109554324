import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import debounce from 'lodash/debounce';

import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { shouldHideContent } from '../utils/entity';
import { NOOP } from '../constants';
import { history as browserHistory } from '../history';
import { convertToFilters } from "./utils/filters";
import FilterSegment from './FilterSegment';
import * as knownFilters from './filters';

import styles from '../css/components/sidebar_filters.module.scss';
export default class SidebarFilters extends React.Component {

  static propTypes = {
    filtersDef: T.array.isRequired,
    className: T.string,
    showCloseBtn: T.bool,
    onInit: T.func,
    onClose: T.func,
    onChange: T.func,
    registerResetHandler: T.func
  }

  static defaultProps = {
    showCloseBtn: true,
    onInit: NOOP,
    onToggle: NOOP,
    onChange: NOOP,
    registerResetHandler: NOOP
  }

  defaultFilters = {}

  _contentRef = React.createRef();

  // stores filter promises
  _initializedFilters = []

  // stores filter promise resolvers
  filterResolvers = {}

  constructor(props) {
    super(props);

    const {filtersDef} = props;

    this.state = {idx: 0, filters: {}, ready: false, showResetBtn: false};

    this.defaultFilters = filtersDef.reduce((acc, filter) => {
      const {name, defaultValue} = Object.values(filter)[0];
      if (props.queryParamsPath?.includes('commands') && name === "dateRange" && defaultValue === "30m" && !this.shouldSkipFilter(filter)) {
        acc[name] = convertToFilters({urlValue: undefined}, name);
      }
      else if (defaultValue && !this.shouldSkipFilter(filter)) {
        acc[name] = convertToFilters({urlValue: defaultValue}, name);
      }
      return acc;
    }, {});
  }

  componentDidMount() {
    const {filtersDef, queryParams, registerResetHandler} = this.props;

    registerResetHandler(this.handleFiltersReset);

    if (queryParams) {
      // restore previously applied query params
      browserHistory.replace({search: queryParams});
    }

    // add search params to filters
    const filters = filtersDef.reduce((acc, filter) => {
      const {name, properties} = Object.values(filter)[0];

      if (this.shouldSkipFilter(filter)) {
        return acc;
      }

      const windowParams = new URLSearchParams(window.location.search);

      acc[name] = this.createFiltersFromWindowParams(windowParams, name, name);
      if(properties){
        Object.values(properties).forEach(property => {
          acc[name].push(...this.createFiltersFromWindowParams(windowParams, property, name));
        });
      }

      this._initializedFilters.push(new Promise(resolve => {
        this.filterResolvers[name] = resolve;
      }));

      return acc;
    }, {});

    // notify when all the filters got initialized
    Promise.all(this._initializedFilters).then(() =>
      this.props.onInit(this.state.filters, this.filtersCount)
    );

    this.setState({filters, ready: true});
  }

  createFiltersFromWindowParams(windowParams, paramKey, filterName) {
    const filters = [];

    const params = windowParams.getAll(paramKey);
    params.forEach(param => {
      const decodedParam = decodeURIComponent(param);
      filters.push(convertToFilters({urlKey: paramKey, urlValue: decodedParam}, filterName)[0]);
    });

    return filters;
  }

  componentWillUnmount() {
    const {queryParamsPath, actions} = this.props;
    const {search} = this.state;
    // save query params
    actions.saveUserData(search, ['query', queryParamsPath]);
  }

  get filtersCount() {
    return Object.values(this.state.filters).reduce((acc, filterArr) => {
      return acc + filterArr.filter(item => item.shouldDisplayBubble).length;
    }, 0);
  }

  shouldSkipFilter(filter) {
    const filterDef = Object.values(filter)[0];
    return shouldHideContent(filterDef);
  }

  handleFilterInit = this.handleFilterInit.bind(this);
  handleFilterInit(filterName, selectedItems) {
    this.handleFilterChange(filterName, selectedItems, false);
    if(this.filterResolvers[filterName]){
      this.filterResolvers[filterName]();
    }
  }

  handleFilterChange = this.handleFilterChange.bind(this);
  handleFilterChange(filterName, selectedItems, commitChanges = true) {
    this.setState(prevState => {
      // update filters and search params
      const updatedFilters = convertToFilters(selectedItems, filterName);
      const filters = {...prevState.filters, [filterName]: updatedFilters};

      const searchParams = new URLSearchParams();
      Object.values(filters).forEach(filterArr => {
        filterArr.forEach(filter => {
          if(filter.shouldUseInUrl){
            searchParams.append(filter.urlKey, filter.urlValue);
          }
        });
      });

      // hide the Reset button when `sidebarFilters` match `defaultFilters`
      const showResetBtn = this.areFiltersDefault(filters);

      const search = searchParams.toString();
      browserHistory.replace({search});

      return {filters, showResetBtn, search};
    }, commitChanges ? this.commitChanges : NOOP);
  }

  areFiltersDefault(filters) {
    return Object.keys(filters).some(filterKey => {
      const defaultFilter = this.defaultFilters[filterKey];
      if (defaultFilter && defaultFilter.length > 0) {
        return filters[filterKey].some(filter => {
          return filter.shouldUseInUrl && filter.urlValue !== defaultFilter[0].urlValue;
        });
      }
      return filters[filterKey].length !== 0;
    });
  }

  handleFiltersReset = this.handleFiltersReset.bind(this);
  handleFiltersReset() {
    AuAnalytics.trackEvent({
      category: 'SidebarFilters',
      action: 'Reset: AllFilters',
    });

    this.setState(prevState => ({
      filters: {...this.defaultFilters},
      showResetBtn: false,
      idx: prevState.idx + 1 // force re-render
    }), this.commitChanges);
  }

  handleClearForm = this.handleClearForm.bind(this);
  handleClearForm() {
    this.setState(prevState => ({
      filters: {...prevState.filters, ...{dateRange: this.defaultFilters.dateRange}},
      idx: prevState.idx + 1
    }), this.commitChanges);
  }

  commitChanges = debounce(this.commitChanges, 100).bind(this);
  commitChanges() {
    this.props.onChange(this.state.filters, this.filtersCount);
  }

  renderFilterSegments() {
    const {filtersDef, timezone} = this.props;
    const {filters} = this.state;

    return filtersDef.map(filter => {
      let [componentName, filterDef] = Object.entries(filter)[0];
      let Filter = knownFilters[componentName];
      if (!Filter || this.shouldSkipFilter(filter)) {
        return false;
      }

      const selection = filters[filterDef.name] || [];

      return (
        <FilterSegment
          {...filterDef}
          key={`filter_segment-${filterDef.name}`}
          Filter={Filter}
          selection={selection}
          customProperties={filterDef.properties}
          contentRef={this._contentRef}
          className={styles.segment}
          onFilterInit={this.handleFilterInit}
          onFilterChange={this.handleFilterChange}
          visibleClassName={styles.visible}
          expandedClassName={styles.expanded}
          headerClassName={styles.filter_header}
          filterClassName={styles.filter_content}
          timezone={timezone}
          handleClearForm={this.handleClearForm}
        />
      );
    });
  }

  render() {
    const {className, showCloseBtn, onClose} = this.props;
    const {idx, ready, showResetBtn} = this.state;

    if (!ready) {
      return false;
    }

    return (
      <div className={cn(styles.container, className)} id="sidebarFilters">
        <div className={styles.header}>
          <AutoIntl
            data-count={this.filtersCount}
            className={styles.label}
            displayId="au.entity.attr.filters"
          />
          {showResetBtn &&
          <AutoIntl
            displayId="au.entity.reset"
            className={styles.reset}
            onClick={this.handleFiltersReset}
            tag="a"
          />
          }
          {showCloseBtn &&
          <a className={styles.close} onClick={onClose}/>
          }
        </div>
        <div className={styles.content} ref={this._contentRef} key={`filters_${idx}`}>
          {this.renderFilterSegments()}
        </div>
      </div>
    );
  }
}
