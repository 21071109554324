import { connect } from 'react-redux';

import PropProvider from '../PropProvider';
import SidebarFilters from '../components/SidebarFilters';
import { defaultMapDispatchToProps } from '../utils/ConnectDefaults';

export default connect(
  state => PropProvider(state).SidebarFilters(),
  defaultMapDispatchToProps,
)(SidebarFilters);
