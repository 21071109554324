import React from 'react';
import { FormattedMessage } from 'react-intl';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import EntityLookup from '../../LandingPageLookup';
import { custOnClick } from '../../GlobalNavigation';

import styles from '../../../css/components/entity_lookup.module.scss';

export default class CommonDeploymentEntityLookup extends EntityLookup {

  renderAdditionalContent() {
    return (
      <span className={styles.info}>
        <FormattedMessage
          id="au.deployments.useConfidentialClient"
          values={{
            loginLink: (
              <AutoIntl
                displayId="au.deployments.confidentialClientLogin"
                onClick={custOnClick['client']}
                tag="a"
              />
            )
          }}
        />
      </span>
    );
  }

}
