import React from 'react';
import { fromJS } from 'immutable';

import AuAnalytics from '@au/core/lib/utils/AuAnalytics';
import AuButton, {
  BUTTON_TYPE_PRIMARY, BUTTON_TYPE_SECONDARY, BUTTON_TYPE_PLAIN,
} from '@au/core/lib/components/elements/AuButton';

import { history as browserHistory } from '../../../history';
import { policyType, policyGroup } from '../../../constants';
import { getPolicies, cleanupPolicy } from '../../../utils/policies';
import { getParentEntityAwarePath } from '../../../utils/entity';
import { wrapActionWithTracking } from '../../../utils/analyticsHelpers';
import DefaultView from '../View';
import Policies from '../Policies';

import styles from '../../../css/components/entity_view.module.scss';

export default class PolicyView extends DefaultView {

  loadData() {
    const { entity, entityDef, parentEntity, actions } = this.props;

    if (entity.size) {
      this.setState({ found: true });
      return Promise.resolve();
    }

    let props;
    let policyPkField;

    if (entityDef.pkField === 'resourceAui') {
      policyPkField = 'resourceAui';
      props = {
        type: policyType.RESOURCE,
        resourceAui: this.getResourceAui()
      }
    } else {
      policyPkField = 'subjectAui';
      props = {
        type: policyType.SUBJECT,
        subjectAui: this.getSubjectAui()
      }
    }

    return getPolicies({
      ...props, 
      pkField: policyPkField,
      group: policyGroup.DIRECT, 
      includePermissions: true,
    }).then(policies => {
      const data = Object.values(policies);
      actions.listEntitiesSuccess({
        path: getParentEntityAwarePath(parentEntity, 'policies'),
        pkField: policyPkField,
        data
      });

      this.setState({ found: data.length > 0 });
    });
  }

  getResourceAui() {
    const { parentEntity } = this.props;

    if (!parentEntity) {
      return '';
    }

    return (
      parentEntity.entityDef.arn ? parentEntity.entityDef.arn + '/' : ''
    ) + parentEntity.entityId;
  }

  onActionBtnClick(action) {
    if (action === 'replicate') {
        this.toggleReplicateDialog();
    } else {
      //FIXME - MOVE TO linkHelper
      browserHistory.push(`${this.baseUrl}/${encodeURIComponent(this.entityId)}/${action}`);
    }
  }

  onViewJsonBtnClick = this.onViewJsonBtnClick.bind(this);
  onViewJsonBtnClick() {
    AuAnalytics.trackEvent({
      category: 'Button',
      action: 'ViewJson',
      label: 'PolicyView'
    });

    const { actions } = this.props;

    actions.openEntityJsonViewer(
      this.entityId,
      cleanupPolicy(this.props.entity).toJS(),
      {
        tabsClassName: styles.json_viewer_tabs,
        panelClassName: styles.json_viewer_panel
      }
    );
  }

  getActions() {
    const { permissions } = this.props;
    const actions = [];

    if(permissions.canEdit){
      actions.push({
        key: 'policy_edit',
        type: BUTTON_TYPE_PRIMARY,
        displayId: 'au.entity.edit',
        onClick: this.onActionBtnClick.bind(this, 'edit')
      });
    }
  
    if (this.props.entityDef.allowReplicate) {
      actions.push({
        key: 'policy_replicate',
        type: BUTTON_TYPE_SECONDARY,
        displayId: 'au.entity.replicate',
        onClick: this.onActionBtnClick.bind(this, 'replicate')
      });
    }


    return actions.map(mappedAction =>
      wrapActionWithTracking(mappedAction, 'Policy', 'View')
    );
  }

  getPolicyProps() {
    const { entityDef } = this.props;

    return entityDef.pkField === 'resourceAui'
           ? { type: policyType.RESOURCE, resourceAui: this.getResourceAui() }
           : { type: policyType.SUBJECT, subjectAui: this.getSubjectAui() };
  }

  getResourceAui() {
    const { parentEntity } = this.props;

    if (!parentEntity) {
      return '';
    }

    return (
      parentEntity.entityDef.arn ? parentEntity.entityDef.arn + '/' : ''
    ) + parentEntity.entityId;
  }

  getSubjectAui() {
    const { parentEntity } = this.props;

    if (!parentEntity) {
      return '';
    }

    return parentEntity.entity.get('subjectAui') ?? parentEntity.entity.get('aui');
  }

  renderExtraButtons() {
    return (
      <AuButton
        type={BUTTON_TYPE_PLAIN}
        displayId="au.policies.jsonViewer"
        className={styles.view_json}
        onClick={this.onViewJsonBtnClick}
      />
    );
  }

  renderContent() {
    const { entity, parentEntity, actions } = this.props;
    const policyProps = this.getPolicyProps();
    const aui = policyProps.resourceAui || policyProps.subjectAui;
    return (
      <div className={styles.content} ref={ref => this.containerRef = ref}>
        { this.renderActions() }
        <div className={styles.section}>
          <Policies {...policyProps}
            group={entity.get('group') || policyGroup.DIRECT}
            data={fromJS({ [aui]: entity })}
            parentEntity={parentEntity}
            statementsOnly={true}
            onReplicatePolicy={this.toggleReplicateDialog}
            actions={actions}
          />
        </div>
        { this.renderDialogs() }
      </div>
    );
  }

}
