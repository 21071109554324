import React from 'react';

import { history as browserHistory } from "../../../history";
import { wrapActionWithTracking } from "../../../utils/analyticsHelpers";
import DefaultList from "../List";
import EmbeddedVehicleLookup from "./EmbeddedVehicleLookup";

export default class VehicleList extends DefaultList {

  renderContent() {
    return (
      <>
        <EmbeddedVehicleLookup
          endpoint={this.props.endpoint}
          entityDef={this.props.entityDef}
          match={this.props.match}
        />
        {super.renderContent()}
      </>
    );
  }

  getRowActions({id, canWrite, permissions, ...otherProps}) {
    const actions = super.getRowActions({id, canWrite, permissions, ...otherProps});

    const manageBindingAction = {
      key: 'manage_binding_btn',
      displayId: 'au.entity.manageBindings',
      onClick: () => browserHistory.push(this.baseUrl + `/${id}/manage-bindings`)
    };
    actions.splice(2, 0, wrapActionWithTracking(manageBindingAction, 'Vehicle', 'List'));

    return actions;
  }
}
