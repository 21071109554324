import pick from 'lodash/pick';

import { getLoggers } from '../logger';
import iMap from '../dataStructures/indexedMap';
import { updateRefs, flipDirection, forEach, ensureFields } from './common';

const { info, warn, exception } = getLoggers('FeedTree');

function applyPullConfig(node, affinity) {
  const nodePower = node.pull && node.pull.power;
  if (nodePower === undefined || nodePower <= affinity.power) {
    node.pull = affinity;
  }
}

function compareNodes(a, b) {
  // Sort by pull and power
  const [aPull, bPull] = [a.pull || {}, b.pull || {}];
  if (aPull.direction && bPull.direction) {
    // Contested in the same direction
    if (aPull.direction === bPull.direction) {
      if (aPull.power > bPull.power) {
        return aPull.direction === 'up' ? -1 : 1;
      }
      else if (aPull.power < bPull.power) {
        return bPull.direction === 'up' ? 1: -1;
      }
      // else: same direction and power - falls to aui
    }
    else {
      // Pulling in different directions
      return aPull.direction === 'up' ? -1 : 1;
    }
  }
  else if (aPull.direction) {
    // Uncontested a pull
    return aPull.direction === 'up' ? -1 : 1;
  }
  else if (bPull.direction) {
    // Uncontested b pull
    return bPull.direction === 'up' ? 1 : -1;
  }
  // No pull

  // Sort by aui
  if (a.displayName < b.displayName) {
    return -1;
  }
  if (a.displayName > b.displayName) {
    return 1;
  }

  return 0;
}

// Takes into account bundles and assumes nodes have been annotated with pull
// and bundle information.
function sortNodes(nodesIterable) {
  const sortedNoBundle = [...nodesIterable]
    .filter(n => !n._bundle || n._bundleDomNode)
    .sort(compareNodes);

  // replace dominate nodes with their bundle
  const sorted = [];
  for (let node of sortedNoBundle) {
    if (node._bundleDomNode) {
      for (let bNode of [...node._bundle].sort(compareNodes)) {
        sorted.push(bNode);
      }
    }
    else {
      sorted.push(node);
    }
  }

  return sorted;
}

function getCycles(start, direction, visited) {
  if (!visited) visited = new Set([start]);
  for (let kin of start[direction].values()) {
    if (visited.has(kin)) {
      // return the ordered set of cyclic nodes
      for (let node of [...visited]) {
        if (node !== kin) {
          visited.delete(node);
        } else {
          // returned set started with cycled node
          return visited;
        }
      }
      return visited;
    }
    visited.add(kin);

    const cycles = getCycles(kin, direction, new Set(visited));
    if (cycles) return cycles;

    visited.delete(kin);
  }

  return false;
}


// strictly not a CS tree because nodes have multiple parents, but otherwise is
export default class FeedTree {
  static reqFields = ['aui', 'type', 'input', 'output'];

  constructor(allFlows, allTaps, allProcessors, allForks, rootNode, direction = 'output', excludeAuis = new Set(), skipInferredNodes =false) {
    ensureFields(allFlows.values(), this.constructor.reqFields);
    ensureFields(allTaps.values(), this.constructor.reqFields);
    ensureFields(allProcessors.values(), this.constructor.reqFields);
    ensureFields(allForks.values(), this.constructor.reqFields);
    ensureFields([rootNode], this.constructor.reqFields);

    this._flows = new iMap();
    this._taps = new iMap();
    this._processors = new iMap();
    this._forks = new iMap();
    this._routes = new iMap();
    this._direction = direction;
    this._oppDirection = flipDirection(direction);
    this._nodes = new iMap();
    this._leafNodes = new iMap();
    this._root = this._addNode(rootNode, 0);
    this._sorted = false;
    this._maxDepth = 0;

    if (!(excludeAuis instanceof Set)) excludeAuis = new Set(...excludeAuis);
    if (excludeAuis.has(rootNode.aui)) {
      exception(`[${this.id}] cannot exclude root node`);
    }

    let depth = -1;
    let nodes = new Set([this.root]);
    while (nodes.size) {
      depth++;
      const nextNodes = new Set();
      for (let node of nodes) {
        if (excludeAuis.has(node.aui) || skipInferredNodes && node.inferred && node.aui !== rootNode.aui) {
          info(`[${this.id}] skipping ${node.aui} and all its decendents`);
          continue;
        }
        this._addNode(node, depth);
        [...node[direction].values()]
          .forEach(n => nextNodes.add(n));
      }
      nodes = nextNodes;
    }
    this._maxDepth = depth;

    // TODO: make this one function call so we don't have to know any more details about the direction
    updateRefs(this, 'input');
    updateRefs(this, 'output');

    if (this.root[this._oppDirection].size) {
      exception(`[${this.id}] cannot have a root with ${this._oppDirection}`);
    }

    // Annotate node's maximum depth
    for (let node of this.nodes.values()) {
      node.maxDepth = Math.max(...node.depth);
    }

    // Annotate if node is a leaf
    for (let node of this.nodes.values()) {
      if (!node[this.direction].size) {
        node.isLeaf = true;
        this._leafNodes.set(node.aui, node);
      }
      else {
        node.isLeaf = false;
      }
    }

    this._buildAtomicBundles();


    // // DEBUG: sanity checks
    // for (let node of this.nodes.values()) {
    //   if (['tap', 'processor', 'fork'].includes(node.type)) {
    //     for (let kin of [...node.input.values(), ...node.output.values()]) {
    //       if (kin.type !== 'flow') {
    //         console.warn('🤯', node, kin);
    //       }
    //     }
    //   }
    // }
    // // DEBUG
    // for (let node of this.nodes.values()) {
    //   for (let oNode of node.output.values()) {
    //     if (oNode.maxDepth <= node.maxDepth) {
    //       console.log('bad output, mkay', node);
    //     }
    //   }
    //   for (let iNode of node.input.values()) {
    //     if (iNode.maxDepth >= node.maxDepth) {
    //       console.log('bad input, mkay', node);
    //     }
    //   }
    // }
    // // DEBUG
    // for (let node of this.nodes.values()) {
    //   for (let oNode of node.output.values()) {
    //     if (!oNode.input.has(node.aui)) {
    //       console.log('output node reverse missing ref', node);
    //     }
    //   }
    //   for (let iNode of node.input.values()) {
    //     if (!iNode.output.has(node.aui)) {
    //       console.log('input node reverse missing ref', node);
    //     }
    //   }
    // }

    // detect cycles
    const outputCycle = getCycles(this.root, 'output');
    if (outputCycle) {
      exception(`cycle detected starting with node ${[...outputCycle].map(n => n.aui).join(', ')}`);
    }
    // not sure how only input cycles can happen since edges are mutually directional
    // for (let leaf of this._leafNodes.values()) {
    //   const inputCycle = getCycles(leaf, 'input');
    //   if (inputCycle) {
    //     exception(`reverse cycle detected starting with node ${[...inputCycle].map(n => n.aui).join(', ')}`);
    //   }
    // }


    this._expandedHeight = this._annotateExpandedHeight(this.root);

    info(`[${this.id}] constructed`);
  }

  _addNode(node, depth) {
    if (this.nodes.has(node.aui)) {
      node = this.nodes.get(node.aui);
      node.depth.add(depth);
    }
    else {
      // Prevent contamination of props added by previous generation's tree
      node = pick(node, [...this.constructor.reqFields, 'inferred', 'state', 'displayName']);
      node = {...node, depth: new Set([depth]) };
    }

    if (node.type === 'flow') {
      this.flows.set(node.aui, node);
    }
    else if (node.type === 'tap') {
      this.taps.set(node.aui, node);
    }
    else if (node.type === 'processor') {
      this.processors.set(node.aui, node);
    }
    else if (node.type === 'fork') {
      this.forks.set(node.aui, node);
    }
    else if (node.type === 'route') {
      this.routes.set(node.aui, node);
    }
    else {
      exception(`[${this.id}] ${node.aui} type cannot be determined`);
    }
    this.nodes.set(node.aui, node);
    return node;
  }

  // Gets the total/expanded height units from a starting node for an expanded
  // tree. Annotates all children recursively. Returns total height.
  _annotateExpandedHeight(start) {
    let height = 0;
    for (let child of start.output.values()) {
      // Do not count children that are not prime for this node
      if (child._primeInput && !child._primeInput.has(start.aui)) {
        continue;
      }

      height += this._annotateExpandedHeight(child);
    }
    height = height || 1;

    // Take into account number of shared children when calculating height
    let heightFactor = 0;
    if (start._primeOutput) {
      for (let child of start._primeOutput.values()) {
        heightFactor += child._primeInput.size; // if prime must be pointing at this node
      }
    }
    heightFactor = heightFactor || 1;

    start.expandedHeight = Math.max(height / heightFactor, 1);
    return start.expandedHeight;
  }

  // Builds bundles of nodes that are to be treated as one
  _buildAtomicBundles() {
    this._atomicBundles = [];
    // build bundles from deepest (right) to shallowest (left)
    for (let nodes of [...this.nodesByMaxDepth.values()].reverse()) {
      for (let node of nodes.values()) {
        if (node.input.size <= 1) continue; // more than one needed to bundle

        // Determine the primary parents of the bundle
        // The primary parents are the ones that converge first to the same ancestor
        const convergingParentsAncestors = new Map(); // parent node -> converging ancestor
        for (let parent of node.input.values()) {
          // Ignore parents already in a bundle
          if (parent._bundle) continue;

          // HACK
          // This fixes a lot of assumptions around building bundles of only
          // single in/out nodes (taps and processors) and all the calculations
          // associated with that, like expandedHeight
          if (parent.type === 'fork') continue;

          // Parents not directly before the node cannot be part of the bundle
          if (parent.maxDepth !== node.maxDepth - 1) continue;


          // TODO: revisit why the first ancestor and how this works in general
          // IDEA: could use the largest output of first convergence or the one that has the largest converging ancestors
          convergingParentsAncestors.set(parent, this._getFirstConvergence(parent, 'input')[0]); // will always exist
        }

        let commonAncestor;
        for (let [parent, ancestor] of convergingParentsAncestors) {
          if (commonAncestor === undefined) commonAncestor = ancestor;
          if (ancestor !== commonAncestor) {
            convergingParentsAncestors.delete(parent);
          }
        }

        // build bundle
        const bundle = [];
        for (let parent of convergingParentsAncestors.keys()) {
          parent._primeOutput = new Map([[node.aui, node]]);
          bundle.push(parent);
          parent._bundle = bundle;
        }
        if (bundle.length) {
          node._primeInput = new Map(bundle.map(n => [n.aui, n]));
          this._atomicBundles.push(bundle);
        }

        info('created bundle: %l for %s', bundle.map(n => n.aui), node.aui);
      }
    }
  }

  // Builds a lookup with the primary node being the dominate node that
  // represents the bundle
  _buildAtomicBundlesLookup() {
    // Build atomic bundles lookup so we can sort as one
    const domLookup = new Map(); // dominate node -> [node,...]
    const nodeLookup = new Map(); // bundle node -> [node,...]
    for (let bundle of this._atomicBundles) {
      // Determine the dominate node by max power
      let domNode;
      for (let node of bundle) {
        if (!domNode) {
          domNode = node;
          continue;
        }
        if ((domNode.power || -Infinity) < (node.power || -Infinity)) {
          domNode = node;
        }
      }

      domLookup.set(domNode, bundle);

      // Create lookup for any bundle node to its dominate node
      // And annotate the dominate node
      for (let node of bundle) {
        nodeLookup.set(node, domNode);
        node._bundleDomNode = domNode;
      }
    }

    return [domLookup, nodeLookup];
  }

  get nodesByMaxDepth() {
    if (this._nodesByMaxDepth) return this._nodesByMaxDepth;

    // group by depth
    this._nodesByMaxDepth = new iMap();
    for (let d=0; d<=this.maxDepth; ++d) {
      // to keep byDepth sorted asc
      this._nodesByMaxDepth.set(d, new Map());
    }
    for (let node of this.nodes.values()) {
      this._nodesByMaxDepth.get(node.maxDepth).set(node.aui, node);
    }

    return this._nodesByMaxDepth;
  }

  // expects tree to be sorted
  _sortNodesByMaxDepth(start=this.root, sorted) {
    if (!sorted) {
      sorted = new Map();
      for (let d=0; d<=this.maxDepth; ++d) {
        sorted.set(d, []); // to ensure map order is ascending depth
      }
    }
    sorted.get(start.maxDepth).push(start);
    for (let child of start.sortedOutput.values()) {
      this._sortNodesByMaxDepth(child, sorted);
    }
    return sorted;
  }

  get sortedNodesByMaxDepth() {
    if (!this.sorted) this.sort();
    return this._sortedNodesByMaxDepth;
  }

  get maxHeight() {
    if (this._maxHeight) return this._maxHeight;
    this._maxHeight = Math.max(...this.heightByDepth.values());
    return this._maxHeight;
  }

  _getFirstConvergence(start, direction) {
    const oppDirection = flipDirection(direction);
    const next = [];
    const converges = [];
    for (let kin of start[direction].values()) {
      if (kin[oppDirection].size > 1) {
        converges.push(kin);
      }
      else {
        next.push(kin);
      }
    }
    if (converges.length) return converges;
    for (let kin of next) {
      return this._getFirstConvergence(kin, direction);
    }
  }

  // TODO: could make generic by adding direction (_commonKin)
  _getCommonAncestors(nodeA, nodeB) {
    // Align depths
    if (nodeA.maxDepth !== nodeB.maxDepth) {
      const [lesserNode, greaterNode] = nodeA.maxDepth > nodeB.maxDepth ? [nodeB, nodeA] : [nodeA, nodeB];
      for (let greaterParent of greaterNode.input.values()) {
        return this._getCommonAncestors(greaterParent, lesserNode);
      }
    }

    const commonParents = new Set();
    for (let parentAui of nodeA.input.keys()) {
      if (nodeB.input.has(parentAui)) {
        commonParents.add(nodeB.input.get(parentAui));
      }
    }
    if (commonParents.size) {
      return commonParents;
    }

    // No luck, check next level
    for (let parentA of nodeA.input.values()) {
      for (let parentB of nodeB.input.values()) {
        return this._getCommonAncestors(parentA, parentB);
      }
    }
  }

  _getKinAtMaxDepth(node, maxDepth, direction, kin=[]) {
    if (!direction) direction = node.maxDepth > maxDepth ? 'input' : 'output';
    for (let k of node[direction].values()) {
      // TODO: exit early and stop traversing when a match is impossible
      if (k.maxDepth === maxDepth) {
        kin.push(k);
      }
      else {
        this._getKinAtMaxDepth(k, maxDepth, direction, kin);
      }
    }
    return new Set(kin);
  }

  sort(pullNodes=new Map()) { // TODO: memoize and do not sort again with the same pulls
    // Clear previously applied affinities
    if (this.sorted) {
      info(`[${this.id}] resorting`);
      for (let node of this.nodes.values()) {
        delete node.pull;
        delete node.power;
        delete node.sortedOutput;
        delete node.sortedInput;
      }
    }
    else {
      info(`[${this.id}] sorting`);
    }

    // Mark node and all ancestors of their pull affinity
    // Pull maps map nodeAui to the pull direction and power
    for (let [nodeAui, affinity] of pullNodes) {
      let node = this.nodes.get(nodeAui);
      forEach(node, n => applyPullConfig(n, affinity), flipDirection(this.direction));
    }

    // Sort atomic bundles lookup
    const [domAtomicBundlesLookup, atomicBundlesLookup] = this._buildAtomicBundlesLookup(); // utilizes applied power
    for (let bundle of domAtomicBundlesLookup.values()) {
      bundle.sort(compareNodes);
    }

    // Added sorted refs
    for (let node of this.nodes.values()) {
      if (node._primeInput) {
        node.sortedInput = new Map(sortNodes(node._primeInput.values()).map(n => [n.aui, n]));
      }
      if (node._primeOutput) {
        node.sortedOutput = new Map(sortNodes(node._primeOutput.values()).map(n => [n.aui, n]));
      }
    }

    const nonDomAtomicBundleNodes = new Set(); // quick lookup for non-dominate nodes in the bundles
    for (let [domNode, bundle] of domAtomicBundlesLookup) {
      for (let node of bundle) {
        if (node !== domNode) {
          nonDomAtomicBundleNodes.add(node);
        }
      }
    }

    // Set remaining node's sorted values that have a single input and possibly
    // many output
    for (let node of this.nodes.values()) {
      if (!node.sortedOutput) {
        // Build output that fills in bundles with their dominate node
        const sortedOutput = [];
        for (let childNode of [...node.output.values()].sort(compareNodes)) {
          if (nonDomAtomicBundleNodes.has(childNode)) continue;
          if (domAtomicBundlesLookup.has(childNode)) {
            for (let bundledNode of domAtomicBundlesLookup.get(childNode)) {
              sortedOutput.push(bundledNode);
            }
          }
          else {
            sortedOutput.push(childNode);
          }
        }

        node.sortedOutput = new Map(sortedOutput.map(n => [n.aui, n]));
      }
    }

    // For all nodes in a atomic bundle that have children's parent outside the
    // bundle place the parents outside the bundle next to it
    for (let domNode of domAtomicBundlesLookup.keys()) {
      // Outside parents are parents of the bundle's children but not parents of
      // of the bundle
      const outsideParents = new Set();
      for (let sortedOutputNode of domNode.sortedOutput.values()) { // generally just one
        [...sortedOutputNode.input.values()]
          .filter(n => !sortedOutputNode.sortedInput.has(n.aui))
          .forEach(n => outsideParents.add(n));
      }

      if (!outsideParents.size) continue;

      for (let oParent of outsideParents) {
        // Get the depth of their peer ancestors
        const commonAncestor = [...this._getCommonAncestors(oParent, domNode)][0];
        const peerAncestorsDepth = commonAncestor.maxDepth + 1;
        const domPeerAncesstors = this._getKinAtMaxDepth(domNode, peerAncestorsDepth);
        const oParentPeerAncesstors = this._getKinAtMaxDepth(oParent, peerAncestorsDepth);

        // Must assume the dominate node's peer ancestor is just one otherwise this get very complicated
        let domPeerAncesstor = [...domPeerAncesstors][0];

        // Check if domPeerAncesstor is part of a bundle. If so pick the dominate
        // node
        if (atomicBundlesLookup.has(domPeerAncesstor)) {
          domPeerAncesstor = atomicBundlesLookup.get(domPeerAncesstor);
        }

        if (domPeerAncesstors.size > 1) {
          warn(`${domNode.aui} has multiple peer ancestors with ${oParent.aui}, using the first: ${domPeerAncesstor.aui}`);
        }

        // Determine which position to place outsiders
        // Place them on the same side
        const orderLookup = new Map([...commonAncestor.sortedOutput.values()]
          .map((n,i) => [n, i]));
        let onTop = [], onBottom = [];
        for (let oPAncestor of oParentPeerAncesstors) {
          if (orderLookup.get(oPAncestor) < orderLookup.get(domPeerAncesstor)) {
            onTop.push(oPAncestor);
          }
          else {
            onBottom.push(oPAncestor);
          }
        }
        // Sort so they stay in the correct order
        onTop = sortNodes(onTop);
        onBottom = sortNodes(onBottom);

        // Stitch together the new placement
        let peers = new Map(commonAncestor.sortedOutput);
        for (let node of [...onTop, ...onBottom]) {
          peers.delete(node.aui);
        }
        peers = [...peers.values()];
        const peersIdxLookup = new Map(peers
          .map((n, i) => [n, i]));
        const domPeerIdx = peersIdxLookup.get(domPeerAncesstor);
        peers.splice(domPeerIdx, 0, ...onTop);
        peers.splice(domPeerIdx + 1, 0, ...onBottom);

        // Map back
        commonAncestor.sortedOutput = new Map(peers.map(n => [n.aui, n]));
      }
    }

    const sortedNodes = this._sortNodesByMaxDepth();

    // Convert array values to maps for quick lookup
    for (let [depth, nodes] of sortedNodes) {
      sortedNodes.set(depth, new Map([...nodes].map(n => ([n.aui, n]))));
    }

    // Mark tree nodes order
    for (let nodes of sortedNodes.values()) {
      let order = 0;
      for (let node of nodes.values()) {
        node.order = order++;
      }
    }

    // Mark expanded position; helps calculate node distances; not totally accurate
    for (let nodes of sortedNodes.values()) {
      let position = 0;
      for (let node of nodes.values()) {
        position += node.expandedHeight;
        node.expandedPosition = position - node.expandedHeight/2; // position in the middle of node's "box"
      }
    }

    this._sorted = true;
    this._sortedNodesByMaxDepth = sortedNodes;
    info(`[${this.id}] done sorting`);
    return sortedNodes;
  }

  // BFS order, lowest depth first
  get flows() {
    return this._flows;
  }

  // BFS order, lowest depth first
  get taps() {
    return this._taps;
  }

  // BFS order, lowest depth first
  get processors() {
    return this._processors;
  }

  // BFS order, lowest depth first
  get forks() {
    return this._forks;
  }

  get routes() {
    return this._routes;
  }

  // BFS order, lowest depth first
  get nodes() {
    return this._nodes;
  }

  get size() {
    return this._nodes.size;
  }

  get root() {
    return this._root;
  }

  get maxDepth() {
    return this._maxDepth;
  }

  get numEdges() {
    if (this._numEdges !== undefined) return this._numEdges;

    // keep track of unique edges
    const edges = new Set();
    for (let node of this.nodes.values()) {
      for (let nextNode of node[this.direction].values()) {
        edges.add(`${node.aui}-${nextNode.aui}`);
      }
    }
    this._numEdges = edges.size;

    return this._numEdges;
  }

  get direction() {
    return this._direction;
  }

  get expandedHeight() {
    return this._expandedHeight;
  }

  get id() {
    return this.root.aui;
  }

  get sorted() {
    return this._sorted;
  }
}
