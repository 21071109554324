import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import AlertMessage from '@au/core/lib/components/objects/AlertMessage';
import GenericError from '@au/core/lib/components/elements/GenericError';
import SecurityPage from '@au/core/lib/components/objects/SecurityPage';

import IntlProvider from './containers/IntlProvider';
import UnitProvider from './containers/UnitProvider';
import GlobalNavigation from './containers/GlobalNavigation';

import {
  HOME_PATH,
  SERVICES_PATH,
  TOPOLOGY_PATH, COMPONENT_GALLERY_PATH, COMPONENT_GALLERY_PAGES
} from './constants';
import serviceDefs from './services/serviceDefs';
import { shouldHideContent } from './utils/entity';

import ComponentGallery from './components/ComponentGallery';

import App from './containers/App';
import ConnectedService from './containers/Service';
import ConnectedLandingPage from './containers/LandingPage';
import ConnectedFeedTopology from './containers/FeedTopology';

import MockCreate from './containers/MockCreate';
import MockEdit from './containers/MockEdit';

import styles from './css/components/root.module.scss';

const HomeRoutes = ({ match, history }) => (
  <div className={styles.page}>
    <Switch>
      <Route path={match.url} component={ConnectedLandingPage} />
      <Route render={() => <GenericError history={history}  code={404} messageDisplay={'Sorry, we could not find the page you requested.'} />} />
    </Switch>
  </div>
);

const ServiceRoutes = () => (
  <div className={styles.page}>
    <Switch>
      <Route component={ConnectedService} />
    </Switch>
  </div>
);

const Routes = (props) => {
  const isDevEnv = process.env.NODE_ENV === 'development';
  const knownServices = Object.keys(serviceDefs)
    .filter(serviceAlias => serviceDefs[serviceAlias].display !== false)
    .filter(serviceAlias => !shouldHideContent(serviceDefs[serviceAlias]));
  const servicesPath  = `${SERVICES_PATH}/:serviceAlias(${knownServices.join('|')})`;
  const componentGalleryPath = `${COMPONENT_GALLERY_PATH}/:pageAlias(${Object.values(COMPONENT_GALLERY_PAGES).join('|')})`;

  return (
    <GlobalNavigation {...props}>
      <AlertMessage />
      <Switch>
        <Redirect exact from="/"             to={HOME_PATH} />
        <Redirect exact from="/dashboard"    to={HOME_PATH} />
        <Redirect exact from='/status'       to={HOME_PATH} />
        <Route path={HOME_PATH}                                   component={HomeRoutes} />
        <Route path={servicesPath}                                component={ServiceRoutes} />
        <Route path={`${TOPOLOGY_PATH}/:id?`}                     component={ConnectedFeedTopology} />
        <Route path={'/security'}                                 component={SecurityPage}/>
        { isDevEnv && <Route path="/mocks/new"                    component={MockCreate} /> }
        { isDevEnv && <Route path="/mocks/edit"                   component={MockEdit} /> }
        { isDevEnv && <Route path={componentGalleryPath}          component={ComponentGallery} /> }
        <Route render={() => <GenericError history={props.history} code={404} messageDisplay={'Sorry, we could not find the page you requested.'}/>} />
      </Switch>
    </GlobalNavigation>
  );
};

export default class Root extends Component {
  render() {
    const { store, history } = this.props;

    return (
      <Provider store={store}>
        <IntlProvider>
          <UnitProvider>
            <Router history={history}>
              <App>
                <Switch>
                  <Route path={`${HOME_PATH}/:page`}             component={Routes} />
                  <Route path={`${SERVICES_PATH}/:serviceAlias`} component={Routes} />
                  <Route                                         component={Routes} />
                </Switch>
              </App>
            </Router>
          </UnitProvider>
        </IntlProvider>
      </Provider>
    );
  }
}
