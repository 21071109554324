import React from 'react';
import cn from 'classnames';
import * as T from 'prop-types';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import styles from '../css/components/widget_text.module.scss';

export default class WidgetText extends React.PureComponent {

  static propTypes = {
    titleDisplayId: T.string,
    titleDisplayString: T.string,
    titleTag: T.string
  }

  static defaultProps = {
    titleTag: 'h2',
  }

  render() {
    const { titleDisplayId, titleDisplayString, titleTag } = this.props;

    return (
      <div className={cn(styles.container)}>
        <AutoIntl displayId={titleDisplayId} displayString={titleDisplayString} className={styles.title} tag={titleTag}/>
        <div className={styles.value}>{this.props.children}</div>
      </div>
    );
  }
}