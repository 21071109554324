import React from 'react';
import { injectIntl } from "react-intl";
import * as T from 'prop-types';
import moment from 'moment-timezone';
import cn from "classnames";

import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import { BUTTON_TYPE_TERTIARY } from "@au/core/lib/components/elements/AuButton";
import ProcessingButton from '@au/core/lib/components/elements/ProcessingButton';
import SelectableList from '@au/core/lib/components/elements/SelectableList';

import { Filter, SelectableListProps } from '../../AuPropTypes';
import { parseRanges } from '../../utils/parse';
import { NOOP } from '../../constants';
import { formatMessage } from '../../utils/reactIntl';
import { DATE_FORMAT, generateCalendarBoundsFromStateDate, isoDateToState } from "../utils/filterDates";
import TimePeriod from "./TimePeriod";

import styles from '../../css/components/filter_daterange.module.scss';


const DEFAULT_RANGES = "7d,month_to_date,3M";
const DEFAULT_LEGEND = Object.freeze({
  today: { displayId: 'au.calendar.today'
}});

const MIN_DATE = moment("01/01/1970", DATE_FORMAT);

const CUSTOM_RANGE = "custom";
const UNUSED_KEY = "unused";

class DateRange extends React.Component {

  static propTypes = {
    defaultValue: SelectableListProps.defaultValue,
    containerRef: SelectableListProps.containerRef,
    ranges: T.string.isRequired,
    legend: T.object,
    modifiers: T.object,
    customProperties: T.shape({
      to: T.string.isRequired,
      from: T.string.isRequired
    }).isRequired,
    selection: T.arrayOf(Filter),
    multiSelect: T.bool,
    excludeToday: T.bool,
    showCustom: T.bool,
    onChange: T.func,
    onInit: T.func
  }

  static defaultProps = {
    ranges: DEFAULT_RANGES,
    legend: DEFAULT_LEGEND,
    modifiers: {},
    excludeToday: false,
    showCustom: false,
    selection: [],
    onChange: NOOP,
    onInit: NOOP
  }

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      presetValue: formatMessage({id: 'au.filters.dateRange.select'}),
      start: '',
      end: '',
      removed: false
    }
  }

  dateRanges = parseRanges(this.props.ranges, this.props.excludeToday)

  get calendarItems() {
    let calendarItems = [...this.dateRanges];

    if (!this.props.showCustom) {
      const customIndex = calendarItems.findIndex(i => i.value === CUSTOM_RANGE);
      if (customIndex > -1) {
        calendarItems.splice(customIndex, 1);
      }
    }

    return calendarItems;
  }

  handleCustomDateRange = this.handleCustomDateRange.bind(this)
  handleCustomDateRange(filters) {
    this.props.onChange([{urlValue: CUSTOM_RANGE, shouldUseToQuery: false, shouldDisplayBubble: false}, ...filters]);
  }

  timePeriodInit = this.timePeriodInit.bind(this);
  timePeriodInit(filters) {
    if (filters.length === 0) {
      return this.props.onInit([]);
    }
    return this.props.onInit([{urlValue: CUSTOM_RANGE, shouldUseToQuery: false, shouldDisplayBubble: false}, ...filters]);
  }

  onChange = this.onChange.bind(this);
  onChange(item) {
    this.setState(prevState => ({expanded: !prevState.expanded, presetValue: item.displayString}));
    const start = {urlValue: item.value, bubbleText: item.displayString, queryKey: this.props.customProperties.from, queryValue: item.from.toISOString()};
    // We need UNUSED_KEY below in order to search for the appropriate urlKey down below,
    // so that we can determine whether or not we are currently handling a custom range.
    const end = {urlKey: UNUSED_KEY, queryKey: this.props.customProperties.to, queryValue: item.to.toISOString(), shouldUseInUrl: false, shouldDisplayBubble: false};
    this.setState({start: start, end: end});
  }

  handleClick = this.handleClick.bind(this);
  handleClick() {
    const { start, end } = this.state;

    this.setState({removed: false});

    if (start != '' && end != '') {
      this.props.onChange([start, end]);
    }
  }

  onInit = this.onInit.bind(this);
  onInit(item) {
    const end = {urlKey: UNUSED_KEY, queryKey: this.props.customProperties.to, queryValue: item.to.toISOString(), shouldUseInUrl: false, shouldDisplayBubble: false};
    this.props.onInit([end]);
  }

  onChangeCalendar = this.onChangeCalendar.bind(this);
  onChangeCalendar(startDate, endDate) {
    const { timezone } = this.props;
    const isUtcTimezone = timezone === 'UTC';
    const timezoneStartDate = isUtcTimezone ? moment.utc(startDate) : startDate;
    const timezoneEndDate = isUtcTimezone ? moment.utc(endDate) : endDate;
    const startBubble = this.props.intl.formatMessage({id: "au.filters.timePeriod.startSymbol"}) + " " + `${timezoneStartDate.format('MM/DD/YYYY hh:mm a')}`;
    const start = {urlValue: startDate.toISOString(), urlKey: this.props.customProperties.from, bubbleText: startBubble};
    const endBubble = this.props.intl.formatMessage({id: "au.filters.timePeriod.endSymbol"}) + " " + `${timezoneEndDate.format('MM/DD/YYYY hh:mm a')}`;
    const end = {urlValue: endDate.toISOString(), urlKey: this.props.customProperties.to, bubbleText: endBubble};
    const customDateRange = {urlValue: CUSTOM_RANGE, shouldUseToQuery: false, shouldDisplayBubble: false};
    this.props.onChange([customDateRange, start, end]);
  }

  getCalendarBounds(isCustom){
    const { selection, customProperties } = this.props;
    const maxBound = moment().toDate();

    if(isCustom){
      const startDateISO = selection.find(i => i.urlKey === customProperties.from)?.urlValue;
      const endDateISO = selection.find(i => i.urlKey === customProperties.to)?.urlValue;

      const startDate = isoDateToState(startDateISO);
      const endDate = isoDateToState(endDateISO);

      return generateCalendarBoundsFromStateDate(startDate, endDate, MIN_DATE.toDate());
    }

    const {from, to} = this.calendarItems.find(i => selection.find(s => s.urlValue === i.value)) || {};
    return { from, to, maxBound: to > maxBound ? to : maxBound };
  }

  expandDropdown() {
    this.setState(prevState => ({expanded: !prevState.expanded}));
  }

  closeDropdown = this.closeDropdown.bind(this);
  closeDropdown() {
    this.setState({expanded: false});
  }

  resetPresetValue = this.resetPresetValue.bind(this);
  resetPresetValue() {
    this.setState({presetValue: formatMessage({id: 'au.filters.dateRange.select'}), removed: true});
  }

  renderPresetRange() {
    const { name, containerRef, selection, defaultValue, removedSelection } = this.props;
    const isCustom = selection.find(i => i.urlKey === name)?.urlValue === CUSTOM_RANGE;

    if (removedSelection === 'dateRange' && !this.state.removed) {
      this.resetPresetValue();
    }

    return (
      <div>
        <div className={styles.subtitle}><AutoIntl displayId='au.filter.dateRange.preset'/></div>
        <div className={styles.selection} onClick={() => this.expandDropdown()} onBlur ={()=> this.closeDropdown() } tabIndex={0}>
          <input 
            value={this.state.presetValue}
            className={this.state.presetValue === 'Select Range' ? cn(styles.input, styles.placeholder_input) : styles.input}
            type="text"
            readOnly
          />
          <span
            className={styles.toggle}
          />
        </div>
        <div className={this.state.expanded ? styles.visible : styles.hidden} onMouseDown={(e) => e.preventDefault()}>
          <SelectableList
            containerRef={containerRef}
            multiSelect={false}
            canDeselect={false}
            items={this.calendarItems}
            defaultValue={defaultValue}
            selection={selection.map(item => item.urlValue)}
            onChange={this.onChange}
            onInit={isCustom ? NOOP : this.onInit}
            dropdown={true}
          />
        </div>
        <div className={styles.btn_container}>
          <ProcessingButton
            type={BUTTON_TYPE_TERTIARY}
            onClick={this.handleClick}
            displayId={"au.filters.apply"}
          />
        </div>
      </div> 
    )
  }

  render() {
    const { name, containerRef, selection, customProperties, timezone, intl, onChange, removedSelection } = this.props;
    const isCustom = selection.find(i => i.urlKey === name)?.urlValue === CUSTOM_RANGE;
    const {from, to, maxBound} = this.getCalendarBounds(isCustom);
    const {start, end} = this.state;

    return (
      <div className={styles.container}>
        {this.renderPresetRange()}
        <div className={styles.break}></div>
        <div>
          <div className={styles.subtitle}><AutoIntl displayId='au.filters.dateRange.custom'/></div>
          <TimePeriod
            className={styles.time_period_container}
            hideCalendar={true}
            customProperties={{
              start: customProperties.from,
              end: customProperties.to
            }}
            selection={selection}
            onChange={this.handleCustomDateRange}
            onInit={isCustom ? this.timePeriodInit : NOOP}
            timezone={timezone}
            sidebar={true}
            from={from}
            to={to}
            toMonth={maxBound}
            legend={this.props.legend}
            modifiers={this.props.modifiers}
            containerRef={containerRef}
            intl={intl}
            propsOnChange={onChange}
            presetStart={start}
            presetEnd={end}
            presetOnChange={onChange}
            presetValue={this.state.presetValue}
            dateRange={true}
            presetFunc={this.resetPresetValue}
            removedSelection={removedSelection}
          />
          {
            /*
              NOTE if `containerRef` is specified, Calendar will be rendered
              outside of .container element. This is helpful when you have a
              complex UI and you need to render Calendar separately from the
              selectable list component.
            */
          }
        </div>
      </div>
    );
  }
}

export default injectIntl(DateRange);