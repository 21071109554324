import React from 'react';
import * as T from 'prop-types';
import { Map as imMap } from 'immutable';
import cn from 'classnames';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { formatMessage } from '../utils/reactIntl';
import { parseAui } from '../utils/parse';
import { NOOP, FLOW_ARN, FORK_ARN, PROCESSOR_ARN, TAP_ARN, DIRECT_ROUTE_ARN  } from '../constants';
import FeedTopologySearch from './FeedTopologySearch';
import { FeedTopologyNetworkLoader as FeedTopology } from './FeedTopology';

import entityViewStyles from '../css/components/entity_view.module.scss';
import styles from '../css/components/feed_topology_fragment.module.scss';

const VIEW_MODE_HALF = 'half_topology';
const VIEW_MODE_FULL = 'full_topology';
const VIEW_MODE_NONE = 'no_topology';

function getInputAuis(entity) {
  return entity.has('inputFlow')
    ? [entity.get('inputFlow')] : entity.has('inputAuis')
      ? entity.get('inputAuis') : entity.has('input')
        ? [entity.getIn(['input', 'aui'])] : [];
}

function getOutputAuis(entity) {
  return entity.has('outputFlow')
    ? [entity.get('outputFlow')] : entity.has('outputAuis')
      ? entity.get('outputAuis') : entity.has('outputs')
        ? entity.get('outputs').map(o => o.get('aui')) : [];
}

export default class FeedTopologyFragment extends React.Component {

  static viewModes = [VIEW_MODE_HALF, VIEW_MODE_FULL, VIEW_MODE_NONE];

  static propTypes = {
    defaultViewMode: T.oneOf(FeedTopologyFragment.viewModes),
    hideDetailsOnFullRender: T.func
  }

  static defaultProps = {
    defaultViewMode: VIEW_MODE_HALF
  }

  state = {
    selectedTopologyView: this.props.defaultViewMode
  };

  setTopologyView = this.setTopologyView.bind(this);
  setTopologyView(viewType) {
    const { hideDetailsOnFullRender } = this.props;

    this.setState({
      selectedTopologyView: viewType
    });

    hideDetailsOnFullRender(viewType === VIEW_MODE_FULL);
  }

  renderControlPanel() {
    const { renderedExtras } = this.props;
    const { selectedTopologyView } = this.state;

    return (
      <div className={entityViewStyles.section}>
        <div className={styles.fragment_top_bar}>
          <div className={styles.view_control_container}>
            <AutoIntl className={styles.title} displayId="au.topology.fragment.topologyView" />
            {/* Topology view controls */}
            <div
              title={formatMessage({ id: 'au.topology.fragment.fullTopologyView' })}
              className={cn(
                styles.icon_container,
                styles.full_topology_icon,
                {[styles.activeTopologyView]: selectedTopologyView === VIEW_MODE_FULL}
              )}
              onClick={this.setTopologyView.bind(this, VIEW_MODE_FULL)}
            />
            <div
              title={formatMessage({ id: 'au.topology.fragment.halfTopologyView' })}
              className={cn(
                styles.icon_container,
                styles.half_topology_icon,
                {[styles.activeTopologyView]: selectedTopologyView === VIEW_MODE_HALF}
              )}
              onClick={this.setTopologyView.bind(this,  VIEW_MODE_HALF)}
            />
            <div
              title={formatMessage({ id: 'au.topology.fragment.hideTopologyView' })}
              className={cn(
                styles.icon_container,
                styles.no_topology_icon,
                {[styles.activeTopologyView]: selectedTopologyView === VIEW_MODE_NONE}
              )}
              onClick={this.setTopologyView.bind(this,VIEW_MODE_NONE)} 
            />
          </div>
          <div className={styles.search_container}>
            <AutoIntl className={styles.title} displayId="au.topology.fragment.search" />
            <FeedTopologySearch onSearch={() => {}} options={[]} searchBoxOnly={true} />
          </div>
          <div className={styles.extras_container}>
            { renderedExtras }
          </div>
        </div>
      </div>
    );
  }

  addInputs(entity, auiLookup, flows, taps, processors, forks, routes) {
    let auis = getInputAuis(entity);
    for (let aui of auis) {
      let input = auiLookup.get(aui);
      if (input) {
        if (aui.startsWith(`${FLOW_ARN}/`)) {
          flows.set(input.get('id'), input);
        }
        else if (aui.startsWith(`${TAP_ARN}/`)) {
          taps.set(input.get('id'), input);
        }
        else if (aui.startsWith(`${PROCESSOR_ARN}/`)) {
          processors.set(input?.get('id') || input, input);
        }
        else if (aui.startsWith(`${FORK_ARN}/`)) {
          forks.set(input.get('id'), input);
        }
        else if (aui.startsWith(`${DIRECT_ROUTE_ARN}/`)) {
          const { uuid } = parseAui(aui);
          routes.set(uuid, input);
        }

        this.addInputs(input, auiLookup, flows, taps, processors, forks, routes);
      }
    }
  }

  addOutputs(entity, auiLookup, flows, taps, processors, forks, routes) {
    let auis = getOutputAuis(entity);

    for (let aui of auis) {
      let output = auiLookup.get(aui);
      if (output) {
        if (aui.startsWith(`${FLOW_ARN}/`)) {
          flows.set(output.get('id'), output);
        }
        else if (aui.startsWith(`${TAP_ARN}/`)) {
          taps.set(output.get('id'), output);
          if (output.get('state') !== 'RUNNING') continue;
        }
        else if (aui.startsWith(`${PROCESSOR_ARN}/`)) {
          processors.set(output.get('id'), output);
        }
        else if (aui.startsWith(`${FORK_ARN}/`)) {
          forks.set(output.get('id'), output);
          if (output.get('state') !== 'RUNNING') continue;
        }
        else if (aui.startsWith(`${DIRECT_ROUTE_ARN}/`)) {
          routes.set(output.get('id'), output);
        }
      
        this.addOutputs(output, auiLookup, flows, taps, processors, forks, routes);
      }
    }
  }

  render() {
    if (!this.props.entity.size) {
      return false;
    }
    // How do we get existing flow/taps/processors as props here
    // Once we have flows/taps loop through inputFlow/taps up and down to build tree
    // Do we have this already?
    const { entity, flows, taps, processors, forks, match, actions } = this.props;
    const { selectedTopologyView } = this.state;

    const isFetching = Boolean([flows, taps, processors, forks]
      .filter(ft => !ft)
      .length);

    let fragmentFlows = new Map();
    let fragmentTaps = new Map();
    let fragmentProcessors = new Map();
    let fragmentForks = new Map();
    let fragmentRoutes = new Map();

    const aui = entity.get('aui');
    if (aui.startsWith(`${FLOW_ARN}/`)) {
      fragmentFlows.set(entity.get('id'), entity);
    }
    else if (aui.startsWith(`${TAP_ARN}/`)) {
      fragmentTaps.set(entity.get('id'), entity);
    }
    else if (aui.startsWith(`${PROCESSOR_ARN}/`)) {
      fragmentProcessors.set(entity.get('id'), entity);
    }
    else if (aui.startsWith(`${FORK_ARN}/`)) {
      fragmentForks.set(entity.get('id'), entity);
    }

    // detecting direct routes by flows outputAui (while waiting on list endpoint)
    flows.valueSeq().forEach(flow => {
      [...flow.get('outputAuis').toJS()].filter(aui => aui && aui.startsWith(`${DIRECT_ROUTE_ARN}/`)).forEach(aui => {
        let { uuid } = parseAui(aui);
        fragmentRoutes.set(uuid, imMap({ input: flow, aui }));
      });
    });

    const auiLookup = new Map(
      [
        ...[...flows.values()].map(e => [e.get('aui'), e]),
        ...[...flows.values()].map(e => [e.get('flowAui') ?? e.get('aui'), e]),
        ...[...taps.values()].map(e => [e.get('aui'), e]),
        ...[...taps.values()].map(e => [e.get('tapAui') ?? e.get('aui'), e]),
        // ...[...(processors?.values() || [])].map(e => [e.get('aui'), e]),
        ...[...processors.values()].map(e => [e.get('aui'), e]),
        ...[...forks.values()].map(e => [e.get('aui'), e]),
        ...[...fragmentRoutes.values()].map(e => [e.get('aui'), e])
      ]
    );

    this.addInputs(entity, auiLookup, fragmentFlows, fragmentTaps, fragmentProcessors, fragmentForks, fragmentRoutes);
    this.addOutputs(entity, auiLookup, fragmentFlows, fragmentTaps, fragmentProcessors, fragmentForks, fragmentRoutes);

    processors.forEach(processor => {
      if ([...fragmentFlows.values()].some(flow => 
        processor.get('inputFlow') === flow.get('aui') || processor.get('outputFlow') === flow.get('aui')
      )) {
        fragmentProcessors.set(processor.get('id'), processor);
        this.addInputs(processor, auiLookup, fragmentFlows, fragmentTaps, fragmentProcessors, fragmentForks, fragmentRoutes);
      }
    });

    return (
      <>
        { this.renderControlPanel() }
        <div
          className={cn(styles.topology_container, {
            [styles.topology_container_full]: selectedTopologyView === VIEW_MODE_FULL,
            [styles.topology_container_half]: selectedTopologyView === VIEW_MODE_HALF,
            [styles.topology_container_none]: selectedTopologyView === VIEW_MODE_NONE,
          })}
        >
          <FeedTopology
            key={entity.get('id')}
            flows={imMap(fragmentFlows)}
            taps={imMap(fragmentTaps)}
            processors={imMap(fragmentProcessors)}
            forks={imMap(fragmentForks)}
            routes={imMap(fragmentRoutes)}
            isFetching={isFetching}
            selected={entity.get('id')}
            actions={actions}
            popoutEnabled={false}
            hideDownload={false}
            hideSearch={true}
            smallLoader={true}
            skipInferredNodes={true}
            onSvgClick={NOOP}
            match={match}
            collapsed={selectedTopologyView === VIEW_MODE_NONE}
          />
        </div>
      </>
    );
  }
}
