import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';

import AuChips from '@au/core/lib/components/elements/AuChips';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { NOOP, memberType } from '../constants';
import { validUuid, validVinOrUuid, noListDuplicates } from '../utils/validationRules';
import { run } from '../utils/validationRuleRunner';
import { formatMessage } from '../utils/reactIntl';

import fieldset from '../css/fieldset.module.scss';

// currenly supported separators are: comma, semicolon and white space
const SEPARATOR_RE = /[,;\s]/;

const memberValidationRules = {
  [memberType.DEVICE]: [validUuid],
  [memberType.VEHICLE]: [validVinOrUuid]
};

export default class GroupMembersEditor extends React.Component {

  static propTypes = {
    type: T.oneOf(Object.values(memberType)).isRequired,
    onChange: T.func,
    uniqueChips: T.bool,
    validationRules: T.array,
    createChipKeys: T.arrayOf(T.string)
  }

  static defaultProps = {
    handleOnPaste: true,
    createChipKeys: ['Enter', ' ', ';', ','],
    validationRules: [noListDuplicates('')],
    onChange: NOOP,
  }

  state = {
    members: [],
    validationErrors: []
  };

  handleChipsChange = this.handleChipsChange.bind(this);
  handleChipsChange(assets) {
    const { type, onChange, validationRules } = this.props;
    // Clean up pasted in items
    const nextMembers = [
      ...assets.join(' ').split(SEPARATOR_RE).filter(text => text.trim()),
    ];
    const validationErrors = run(nextMembers, validationRules);
    // Validate our inputs are valid uuids
    const fieldLabel = formatMessage({ id: `au.groupMembersEditor.input.${type}.placeholderId` });
    let errors = (validationErrors || {}).errors || [];
    for (let i=0; i < nextMembers.length; i++) {
      let vErr = run(nextMembers[i], memberValidationRules[type]);
      if (vErr.errDisplayId) {
        if (!errors[i]) {
          errors[i] = { ...vErr, values: { field: fieldLabel } };
        }
      }
    }

    this.setState({
      members: nextMembers,
      validationErrors: errors
    });

    if (onChange) {
      onChange(nextMembers, errors);
    }
  }

  render() {
    const { members, validationErrors } = this.state;
    const {
      type,
      createChipKeys,
      uniqueChips,
      handleOnPaste,
      className
    } = this.props;

    const errors = validationErrors || [];

    return (
      <div className={cn(fieldset.container, className)}>
        <AuChips
          chips={members}
          errors={errors}
          onChange={this.handleChipsChange}
          placeholderId={`au.groupMembersEditor.${type}.placeholderId`}
          createChipKeys={createChipKeys}
          uniqueChips={uniqueChips}
          handleOnPaste={handleOnPaste}
        />
        <AutoIntl
          className={fieldset.note}
          displayId={`au.groupMembersEditor.${type}.separationNote`}
          tag="div"
        />
      </div>
    );
  }
}
