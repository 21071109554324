import { EMAIL_RE, UUID_RE, USERNAME_RE, VIN_RE } from '../constants';

/**
 * Checks whether provided value is empty.
 * @param {string} text Value to validate
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const required = (text) => {
  return text ? null : { errDisplayId: 'au.validation.isRequired' };
};

/**
 * Checks whether provided value is empty if dependent field value is not empty
 * @param  {string} field Dependent field name
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const requiredWith = (field) => {
  /**
   * @param  {string} text  Current field value
   * @param  {object} state Current state
   */
  return (text, state) => {
    return typeof state[field] === 'undefined' || state[field] === '' ? null : required(text);
  };
};

/**
 * Checks whether two fields are equal.
 * @param {string} field Field name to be compared with
 * @param {string} errDisplayId Localized error message displayId
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const mustMatch = (field, errDisplayId) => {
  /**
   * @param {string} text Current field value
   * @param {object} state Current state
   */
  return (text, state) => {
    return state[field] === text ? null : { errDisplayId };
  };
};

/**
 * Checks whether two fields are equal.
 * @param  {string} field Field name to be compared with
 * @param  {string} errDisplayId Localized error message displayId
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const cantMatch = (field, errDisplayId) => {
  /**
   * @param {string} text Current field value
   * @param {object} state Current state
   */
  return (text, state) => {
    const splitField = field.split(',')

    if (state[splitField[0]] && splitField[0] && splitField[0].length !== field.length) {
      return state[splitField[0]][splitField[1]][splitField[2]] !== text ? null : { errDisplayId }
    }
    return state[field] !== text ? null : { errDisplayId };
  };
};

/**
 * Checks whether provided value is less (or equal) than the value of specified field.
 * @param  {mixed}   constant Value to be compared with
 * @param  {boolean} inclusive True if comparison is inclusive, False otherwise
 * @param  {object}  args Error object spec args
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const lessThan = (constant, inclusive = false, args = {}) => {
  /**
   * @param {string} text Current field value
   * @param {object} state Current state
   */
  return value => inclusive
                  ? value <= constant ? null : { ...args }
                  : value < constant ? null : { ...args };
};

/**
 * Checks whether provided value is greater (or equal) than the value of specified field.
 * @param  {mixed}   constant Value to be compared with
 * @param  {boolean} inclusive True if comparison is inclusive, False otherwise
 * @param  {object}  args Error object spec args
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const greaterThan = (constant, inclusive = false, args = {}) => {
  /**
   * @param {string} text Current field value
   * @param {object} state Current state
   */
  return value => inclusive
                  ? value >= constant ? null : { ...args }
                  : value > constant ? null : { ...args };
};

/**
 * Checks whether provided date is before (or same) as the date in specified field.
 * @param  {string}  field Field name to be compared with
 * @param  {string}  fieldLabel Target field displayId
 * @param  {boolean} inclusive True if comparison is inclusive, False otherwise
 * @param  {string}  errDisplayId Localized error message displayId
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const beforeDate = (field, fieldLabel, inclusive = false, errDisplayId = 'au.validation.beforeDate') => {
  /**
   * @param {string} text Current field value
   * @param {object} state Current state
   */
  return (value, state) => {
    return lessThan(state[field], inclusive, { errDisplayId, values: { target: fieldLabel, inclusive: inclusive ? 1 : 0 } })(value, state);
  };
};

/**
 * Checks whether provided date is after (or same) as the date in specified field.
 * @param  {string}  field Field name to be compared with
 * @param  {string}  fieldLabel Target field displayId
 * @param  {boolean} inclusive True if comparison is inclusive, False otherwise
 * @param  {string}  errDisplayId Localized error message displayId
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const afterDate = (field, fieldLabel, inclusive = false, errDisplayId = 'au.validation.afterDate') => {
  /**
   * @param {string} text Current field value
   * @param {object} state Current state
   */
  return (value, state) => {
    return greaterThan(state[field], inclusive, { errDisplayId, values: { target: fieldLabel, inclusive: inclusive ? 1: 0 } })(value, state);
  };
};

/**
 * Checks whether value satisfy minimum length requirement.
 * @param {number} length Minimum length
 * @param {string} errDisplayId Localized error message displayId
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const minLength = (length, errDisplayId = 'au.validation.minLength') => {
  /**
   * @param {string} text Current field value
   */
  return (text) => {
    return text.length >= length ? null : { errDisplayId: errDisplayId, values: { length: length } };
  };
};

/**
 * Checks whether value satisfy maximum length requirement.
 * @param {number} length Maximum length
 * @param {string} errDisplayId Localized error message displayId
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const maxLength = (length, errDisplayId = 'au.validation.maxLength') => {
  /**
   * @param {string} text Current field value
   */
  return (text) => {
    return text.length <= length ? null : { errDisplayId: errDisplayId, values: { length: length } };
  };
};

/**
 * Checks whether value satisfy minimum number requirement.
 * @param {number} num Minimum number
 * @param {string} errDisplayId Localized error message displayId
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const minNumber = (num, errDisplayId = 'au.validation.minNumber') => {
  /**
   * @param {number} number Current field value
   */
  return (number) => {
    return number >= num ? null : { errDisplayId: errDisplayId, values: { num: num } };
  };
};

/**
 * Checks whether value satisfy maximum number requirement.
 * @param {number} num Maximum number
 * @param {string} errDisplayId Localized error message displayId
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const maxNumber = (num, errDisplayId = 'au.validation.maxNumber') => {
  /**
   * @param {string} number Current field value
   */
  return (number) => {
    return number <= num ? null : { errDisplayId: errDisplayId, values: { num: num } };
  };
};

/**
 * Checks whether provided value is a valid email address.
 * @param {string} email Value to validate
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const validEmail = (email) => {
  return EMAIL_RE.test(email) ? null : { errDisplayId: 'au.validation.notValid' };
};

/**
 * Checks whether provided value is a valid UUID
 * @param  {uuid} uuid Value to validate
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const validUuid = (uuid) => {
  return UUID_RE.test(uuid) ? null : { errDisplayId: 'au.validation.notValid' };
};

/**
 * Checks whether provided value is a valid username
 * @param  {username} username Value to validate
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const validUsername = (username) => {
  return USERNAME_RE.test(username) ? null : { errDisplayId: 'au.validation.notValidUsername' };
};

/**
 * Checks whether provided value is a valid username or UUID.
 * @param  {string} usernameOrUuid Value to validate
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
 export const validUsernameOrUuid = (usernameOrUuid) => {
  const usernameError = validUsername(usernameOrUuid);
  return usernameError === null ? null : validUuid(usernameOrUuid);
};

/**
 * Checks whether provided value is a valid VIN.
 * @param  {string} vin Value to validate
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const validVin = (vin) => {
  return VIN_RE.test(vin) ? null : { errDisplayId: 'au.validation.notValid' };
};

/**
 * Checks whether provided value is a valid VIN or UUID.
 * @param  {string} vinOrUuid Value to validate
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const validVinOrUuid = (vinOrUuid) => {
  const vinError = validVin(vinOrUuid);
  return vinError === null ? null : validUuid(vinOrUuid);
};

/**
 * Checks whether provided value matches specified regular expression.
 * @param  {string} re           Pattern of the regular expression
 * @param  {string} errDisplayId Localized error message displayId
 * @return {object or null}      Will return error spec object if value is invalid, otherwise NULL
 */
export const regexp = (re, errDisplayId) => {
  /**
   * @param {string} text Current field value
   */
  return (text) => {
    return RegExp(re).test(text) ? null : { errDisplayId, values: { re } };
  };
};

/**
 * Checks whether provided list has duplicates.
 * @param  {string} errDisplayId Localized error message displayId
 * @return {object or null} Will return error spec object if value is invalid, otherwise NULL
 */
export const noListDuplicates = (errDisplayId = 'au.validation.duplicateEntryWontBeAdded') => {
  return (list) => {
    const errors = new Array(list.length);
    const indexed = {};
    let hasDuplicates = false;

    for (let i=0, len=list.length; i<len; i++) {
      let isDuplicate = list[i] in indexed;
      indexed[list[i]] = true;
      errors[i] = isDuplicate ? { errTitleId: 'au.validation.duplicateEntry', errDisplayId } : null;
      hasDuplicates = hasDuplicates || isDuplicate;
    }

    return hasDuplicates ? { errors } : null;
  };
};

/**
 * Checks whether provided text is contained in a list.
 * @param  {Array<string>} list List of strings to check against
 * @param  {string} errDisplayId Localized error message displayId
 * @return {(string) => {errDisplayId: string} | null}  Will return error spec object if value is invalid, otherwise NULL
 */
export const shouldBeInList = (list, errDisplayId= "au.validation.invalidProperty") => {
  return (text) => {
    if (list.some(item => item === text)) {
      return null;
    } else {
      return {errDisplayId};
    }
  };
};

/**
 * Checks whether provided text is not contained in a list.
 * @param  {Array<string>} list List of strings to check against
 * @param  {string} errDisplayId Localized error message displayId
 * @return {(string) => {errDisplayId: string} | null}  Will return error spec object if value is invalid, otherwise NULL
 */
export const shouldNotBeInList = (list, errDisplayId= "au.validation.duplicateProperty") => {
  return (text) => {
    if (list.every(item => item !== text)) {
      return null;
    } else {
      return {errDisplayId};
    }
  };
};
