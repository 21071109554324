import { persistState } from '@au/core/lib/utils/stateDelegators';

export default {
  SET_SETTING: (state, action) => {
    state = persistState(state)
      .call('setIn', ['settings', action.name], action.value)
      .state;
    return state;
  }
};
