import { generateEntityPath } from '../../../utils/entity';
import DefaultEdit from '../Edit';

export default class VehicleEdit extends DefaultEdit {

  queryParams = { includeRelations: 'bindings', expandRelations: 'bindings' }

  componentDidMount() {
    super.componentDidMount();
    this.parentUrl = this.props.match.url.split('/').slice(0, -1).join('/') + `/view`;
  }

  findUpdatedTags(tags) {
    const { entity } = this.props;
    const currentTags = entity.get('tags').toJS();

    Object.keys(currentTags).forEach(tag => {
      if (!Object.keys(tags).includes(tag)) {
        tags[tag] = null;
      }
    })
    return tags;
  }

  findUpdatedProperties(properties) {
      const { entity } = this.props;
      const currentProperties = entity.get('properties').toJS();
      // find new properties or empty (aka flagged to be removed)

      const filteredProperties = {...properties};
      delete filteredProperties[''];
      return Object.keys(filteredProperties)
        .filter(key => !currentProperties[key] || filteredProperties[key] === '' || currentProperties[key] != filteredProperties[key])
        .reduce((acc, key) => ((acc[key] = filteredProperties[key]), acc), {});
  }

  disableSaveBtn() {
    return;
  }

  handleOnSave = this.handleOnSave.bind(this);
  async handleOnSave(entity) {
    const { endpoint, serviceAlias, match, actions, history } = this.props;
    const { entityId } = match.params;
    const tagsToUpdate = this.findUpdatedTags(entity.tags);
    const propertiesToUpdate = this.findUpdatedProperties(entity.properties);
    const propertiesResp = await endpoint.updateProperties(entityId, propertiesToUpdate).catch(this.genericErrorHandler);
    const tagResp = await endpoint.updateTags(entityId, tagsToUpdate).catch(this.genericErrorHandler);
    const path = [...generateEntityPath(endpoint, serviceAlias), entityId];
    actions.patchEntitySuccess({ replace: true, path, data: Object.assign({}, propertiesResp.data, tagResp.data) });
    history.push(this.parentUrl);
  }
}
