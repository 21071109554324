import TMC from "@autonomic/browser-sdk";
import { fromJS } from "immutable";
import { feedFiltersSelector } from "../../../utils/feedFilters";
import DefaultList from "../List";

export default class GroupFeedList extends DefaultList {
  constructor(props) {
    super(props);

    this.state.feedFetched = false;
  }

  componentDidMount() {
    super.componentDidMount();

    if (!this.state.feedFetched) {
      this.getGroupTaps()
        .then((taps) => this.getGroupForks().then(forks => this.setState({feedFetched: true, taps, forks})))
        .catch(this.onError).then(() => this.setState({feedFetched: true}));
    }
  }

  async getGroupTaps() {
    return (await new TMC.services.Feed({apiVersion: 2}).taps.list())._data.items;
  }

  async getGroupForks() {
    return (await new TMC.services.Feed({apiVersion: 2}).forks.list({detailed: true})).data.items;
  }

  getFilteredEntities() {
    const {parentEntity: {entity: group}} = this.props;
    const {taps = [], forks = []} = this.state;

    if (!group || !group.get("id")) {
      return fromJS([]);
    }

    const groupMap = fromJS({[group.get("id")]: group});
    return fromJS(feedFiltersSelector({taps, forks, groups: groupMap}));
  }
}
