import React from 'react';
import transit from 'transit-immutable-js';
import TMC from "@autonomic/browser-sdk";

import DefaultView from '../View';
import AuButton from '@au/core/lib/components/elements/AuButton';
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import ResultsDrawer from '@au/core/lib/components/elements/ResultsDrawer';
import ConfirmationDialog from "@au/core/lib/components/objects/ConfirmationDialog";
import { BUTTON_TYPE_PLAIN, BUTTON_TYPE_SECONDARY } from '@au/core/lib/components/elements/AuButton';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import StatusBadge from '../../StatusBadge';
import { ICON_ENABLED, ICON_COMMAND_DEFINITION_ENABLED, ICON_COMMAND_DEFINITION_DISABLED } from '../../StateIcon'; 
import { COMMAND_MANAGEMENT_DEFINITION } from '../../../constants';
import { history as browserHistory } from '../../../history';
import { generateEntityPath } from '../../../utils/entity';

import styles from '../../../css/components/command_management_view.module.scss';

export default class CommandManagementView extends DefaultView {
  definitionsEndpoint = new TMC.services.Command({ apiVersion: '1-beta' }).definitions;

  constructor(props) {
    super(props);

    this.state = {
      ...super.state,
      isDrawerOpen: false,
      showPublishDialog: false,
      showChangeStatusDialog: false
    };
  }

  getPropertyValue(property) {
    let prop = property;

    switch (property) {
      case 'commandType':
        prop = 'type';
        break;
      case 'customCommandVersion':
        prop = 'version';
        break;
      case 'permissions': {
        return this.props.entity.getIn(['propertiesSchema', 'required']);
      }
      case 'properties': {
        return this.props.entity.getIn(['propertiesSchema', 'properties']);
      }
      default:
        break;
    }

    return super.getPropertyValue(prop);
  }

  onViewJsonBtnClick = this.onViewJsonBtnClick.bind(this);
  onViewJsonBtnClick() {
    this.setState({ isDrawerOpen: true });
  }

  onReplicateBtnClick(entity) {
    // save entity to session storage for UI to pickup
    sessionStorage.setItem('entityToReplicate', transit.toJSON(entity));

    browserHistory.push({
      pathname: `${this.baseUrl}/replicate`,
      state: { prevUrl: this.props.match.url }
    });
  }

  onEditBtnClick = this.onEditBtnClick.bind(this);
  onEditBtnClick() {
    browserHistory.push({
      pathname: `${this.baseUrl}/${encodeURIComponent(`${this.props.entity.toJS().id}`)}/edit`,
      state: { prevUrl: this.props.match.url }
    });
  }

  onUpdateBtnClick = this.onUpdateBtnClick.bind(this);
  onUpdateBtnClick() {
    browserHistory.push({
      pathname: `${this.baseUrl}/${encodeURIComponent(`${this.props.entity.toJS().id}`)}/define`,
      state: { prevUrl: this.props.match.url }
    });
  }

  handlePublish() {
    const { entity, endpoint, serviceAlias, actions } = this.props;
    const entityId = entity.toJS().id;
    
    this.definitionsEndpoint.publish(entityId)
      .then((resp) => {
        const path = [...generateEntityPath(endpoint, serviceAlias), entityId];
        actions.patchEntitySuccess({ replace: true, path, data: resp._data });
      })
      .catch((e) => {
        createResponseAlertMessage(e);
      })
      .finally(() => this.setState({showPublishDialog: false}));
  }

  handleProceed() {
    const { entity, endpoint, serviceAlias, actions } = this.props;
    const entityId = entity.toJS().id;

    if (entity.get('commandDefinitionStatus')?.toLowerCase() === ICON_ENABLED) {
      this.definitionsEndpoint.disable(entityId)
        .then((resp) => {
          const path = [...generateEntityPath(endpoint, serviceAlias), entityId];
          actions.patchEntitySuccess({ replace: true, path, data: resp._data });
        })
        .catch((e) => {
          createResponseAlertMessage(e);
        })
        .finally(() => this.setState({ showChangeStatusDialog: false }));
    } else {
      this.definitionsEndpoint.enable(entityId)
        .then((resp) => {
          const path = [...generateEntityPath(endpoint, serviceAlias), entityId];
          actions.patchEntitySuccess({ replace: true, path, data: resp._data });
        })
        .catch((e) => {
          createResponseAlertMessage(e);
        })
        .finally(() => this.setState({ showChangeStatusDialog: false }));
    }
  }

  renderPublishConfirmDialog = () => {
    return (
      <ConfirmationDialog
        key="publish_confirm_dialog"
        titleId="au.entity.commandManagement.publishConfirm.title"
        confirmDisplayId="au.entity.commandManagement.publishConfirm.proceed"
        onConfirm={() => this.handlePublish()}
        onCancel={() => this.setState({showPublishDialog: false})}
        headerClassName={styles.dialog_header}
      >
        <div className={styles.dialog_subtitle}>
          <AutoIntl className={styles.dialog_type} displayId="au.entity.commandManagement.name"/>
          <span>{this.props.entity.get("type")}</span>
        </div>
        <div className={styles.dialog_content}>
          <AutoIntl displayId="au.entity.commandManagement.publishConfirm.message1" tag="div"/>
          <AutoIntl displayId="au.entity.commandManagement.publishConfirm.message2" tag="div"/>
          <AutoIntl displayId="au.entity.commandManagement.publishConfirm.message3" tag="div"/>
        </div>
      </ConfirmationDialog>
    );
  }

  renderChangeStatusDialog = () => {
  let changeToStatus;
  if (this.props.entity.get('commandDefinitionStatus')?.toLowerCase() === ICON_ENABLED) {
    changeToStatus = <StatusBadge icon={ICON_COMMAND_DEFINITION_DISABLED} displayId={'au.customcommands.status.DISABLED'}/>
  } else {
    changeToStatus = <StatusBadge icon={ICON_COMMAND_DEFINITION_ENABLED} displayId={'au.customcommands.status.ENABLED'}/>
  }
    return (
      <ConfirmationDialog
        key="change_status_confirm_dialog"
        titleId="au.entity.commandManagement.changeStatus.title"
        confirmDisplayId="au.entity.commandManagement.proceed"
        onConfirm={() => this.handleProceed()}
        onCancel={() => this.setState({showChangeStatusDialog: false})}
        headerClassName={styles.change_status_dialog_header}
        className={styles.change_status_dialog_container}
      >
        <div className={styles.dialog_subtitle}>
          <AutoIntl className={styles.dialog_type} displayId="au.entity.commandManagement.type" />
          <span className={styles.type}>{this.props.entity.get("type")}</span>
        </div>
        <div className={styles.dialog_content}>
          <AutoIntl displayId="au.entity.commandManagement.changeStatus.message1" tag="div" />
          <div className={styles.row_content}>
            <div className={styles.dialog_row}>
              <AutoIntl displayId="au.entity.commandManagement.changeStatus.currentStatus" className={styles.key} tag="div" />
              <StatusBadge icon={`command_definition_${this.props.entity.get('commandDefinitionStatus')?.toLowerCase()}`} displayId={`au.customcommands.status.${this.props.entity.get('commandDefinitionStatus')}`} />
            </div>
            <div className={styles.dialog_row}>
              <AutoIntl displayId="au.entity.commandManagement.changeStatus.changeTo" className={styles.key} tag="div" />
              {changeToStatus}
            </div>
          </div>
        </div>
      </ConfirmationDialog>
    );
  }

  renderDialogs() {
    const dialogs = super.renderDialogs();

    if (this.state.showPublishDialog) {
      dialogs.push(this.renderPublishConfirmDialog());
    }
    if (this.state.showChangeStatusDialog) {
      dialogs.push(this.renderChangeStatusDialog());
    }

    return dialogs;
  }

  onPublishBtnClick = this.onPublishBtnClick.bind(this);
  onPublishBtnClick() {
    this.setState({ showPublishDialog: true });
  }

  onChangeStatusBtnClick = this.onChangeStatusBtnClick.bind(this);
  onChangeStatusBtnClick() {
    this.setState({ showChangeStatusDialog: true });
  }

  getActions() {
    const actions = [
      {
        key: 'entity_replicate_btn',
        type: BUTTON_TYPE_SECONDARY,
        className: styles.button,
        displayId: 'au.entity.commandManagement.actions.duplicate',
        onClick: this.onReplicateBtnClick.bind(this, this.props.entity)
      }
    ];

    const entityJS = this.props.entity.toJS();
    switch (entityJS[COMMAND_MANAGEMENT_DEFINITION.COMMAND_DEFINITION_STATUS]) {
      case "ENABLED":
      case "DISABLED":
        actions.push(
          {
            key: 'entity_edit_btn',
            type: BUTTON_TYPE_SECONDARY,
            className: styles.button,
            displayId: 'au.entity.edit',
            onClick: this.onEditBtnClick
          },
          {
            key: 'entity_change_status_btn',
            type: BUTTON_TYPE_SECONDARY,
            className: styles.button,
            displayId: 'au.entity.commandManagement.actions.changeStatus',
            onClick: this.onChangeStatusBtnClick
          }
        );
        return actions;
      case "DRAFT":
        actions.unshift(
          {
            key: 'entity_update_btn',
            type: BUTTON_TYPE_SECONDARY,
            className: styles.button,
            displayId: 'au.entity.edit',
            onClick: this.onUpdateBtnClick
          },
        )
        if (entityJS[COMMAND_MANAGEMENT_DEFINITION.VALID] === true) {
          actions.push(
            {
              key: 'entity_publish_btn',
              type: BUTTON_TYPE_SECONDARY,
              className: styles.button,
              displayId: 'au.entity.commandManagement.actions.publish',
              onClick: this.onPublishBtnClick
            },
          )  
        }
        actions.push(
          {
            key: 'entity_delete_btn',
            type: BUTTON_TYPE_SECONDARY,
            className: styles.button,
            displayId: 'au.entity.commandManagement.actions.delete',
            onClick: this.onDeleteBtnClick
          }
        )
        return actions;
      default:
        [];
    }
  }

  renderExtraButtons() {
    return (
      <AuButton
        type={BUTTON_TYPE_PLAIN}
        displayId="au.entity.commandManagement.viewjson"
        className={styles.view_json}
        onClick={this.onViewJsonBtnClick}
      />
    );
  }

  renderPopout() {
    const { isDrawerOpen } = this.state;
    const entityJS = this.props.entity.toJS();
    const results = [
      {
        "headerId": "au.entity.commandManagement.commandDefinition",
        "content": entityJS,
        data: entityJS
      },
      {
        "headerId": "au.entity.commandManagement.metadata",
        data: {
            ...entityJS,
            cloudToDeviceConversionDefinitions: undefined,
            deviceToCloudConversionDefinitions: undefined,
            propertiesSchema: undefined,

          }
      },
      {
        "headerId": "au.entity.commandManagement.propertySchema",
        "content": entityJS?.propertiesSchema,
        data: entityJS?.propertiesSchema || {}
      },
      {
        "headerId": "au.entity.commandManagement.cloudToDeviceDefs",
        "content": entityJS?.cloudToDeviceConversionDefinitions,
        data: entityJS?.cloudToDeviceConversionDefinitions || {}
      },
      {
        "headerId": "au.entity.commandManagement.deviceToCloudDefs",
        "content": entityJS?.deviceToCloudConversionDefinitions,
        data: entityJS?.deviceToCloudConversionDefinitions || {}
      }
    ];

    return (
      <ResultsDrawer
        isOpen={isDrawerOpen}
        onClose={() => this.setState({ isDrawerOpen: false })}
        results={results}
      />
    );
  }
}
