import React from 'react';
import * as T from 'prop-types';
import { connect } from 'react-redux';
import * as ActionCreators from '../ActionCreators';
import { bindActionCreators } from 'redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import AuComponent from '@au/core/lib/components/elements/AuComponent';
import { PAGE_SCHEMA_BACK } from '../components/MobilePageHeader';

import Entity from './Entity';
import serviceDefs from '../services/serviceDefs';

class Service extends AuComponent {

  static propTypes = {
    match: T.shape({
      params: T.shape({
        serviceAlias: T.string.isRequired
      }).isRequired,
      path: T.string.isRequired
    }).isRequired,
  };

  componentDidMount() {
    this.props.actions.setPageSchema(PAGE_SCHEMA_BACK);
  }

  render() {
    const { match } = this.props;
    const { serviceAlias } = match.params;
    const entities = serviceDefs[serviceAlias].entities || {};
    const serviceEntities = Object.keys(entities);

    return (
      <Switch>
        <Redirect exact from={match.path} to={match.path + `/${serviceEntities[0]}/list`} />
        <Route component={Entity} />
      </Switch>
    );
  }

}

export default connect(
  null,
  (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
)(Service);
